<template>
  <cc-wrapper
    align="start"
    justify="between"
    direction="row"
    class="bg-blue-light rounded-16 mt-5 p-3"
    >
    <cc-image
      :alt="companyName"
      style="min-width: 24px; width: 24px; height: 24px;"
      class="border-radius-full mr-3 align-self-start bg-white object-fit-cover"
      :src="image"
      :has-placeholder="true"
      placeholder-class="bg-blue-darkest"
      />
    <div class="pr-2 justify-self-start flex-1">
      <cc-text
        variant="body-xs"
        class=""
        >
        {{ title }}
      </cc-text>

      <cc-text
        variant="body-xs"
        color="muted"
        >
        {{ companyName }}
      </cc-text>
    </div>

    <cc-button
      :block="false"
      variant="small"
      :text="$t('walletReceipt')"
      @click="$emit('open-transaction')"
      />
  </cc-wrapper>
</template>

<script>
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccImage from '@/components/primitives/Image.vue';

export default {
  components: {
    ccWrapper,
    ccText,
    ccButton,
    ccImage,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    companyName: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style>

</style>
