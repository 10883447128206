// import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  computed: {
    // ...mapState({
    //   appState: state => state.common.appState,
    //   user: state => state.user.user,
    //   userState: state => state.user,
    // }),
    // ...mapGetters({
    //   hasGroupLeaderboard: 'social/hasGroupLeaderboard',
    //   isCompanyUser: 'user/isCompanyUser',
    // }),
  },
  methods: {
    // ...mapMutations({
    //   setAppState: 'common/setAppState',
    // }),
  },
  created() {
    // window.addEventListener('load', () => {
    // });
  },
};
