<template>
  <cc-view
    :id="id"
    class="bg-blue-dark"
    >
    <div class="d-flex flex-column justify-content-center text-center pb-3">
      <cc-heading>
        {{ $t('cantonSelectionTitle') }}
      </cc-heading>

      <cc-text class="py-5">
        {{ $t('cantonSelectionContent') }}
      </cc-text>

      <cc-canton-list-selector
        @selected="errorMessage = ''"
        @selected-canton="$event => selectedCanton = $event"
        />
    </div>

    <div>
      <cc-error-message
        class="pb-2"
        :message="errorMessage"
        alignment="center"
        />

      <cc-button
        class="mt-3"
        :disabled="isAwaiting"
        :text="$t('cantonSelectionButton')"
        @click="primaryActionHandler"
        />
    </div>

    <cc-canton-confirmation-modal
      v-if="isCantonConfirmationModalDisplayed"
      :data="{ selectedCanton }"
      @close-modal="isCantonConfirmationModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';
import ccCantonListSelector from '@/components/social/CantonListSelector.vue';
import ccCantonConfirmationModal from '@/components/modals/CantonConfirmationModal.vue';

export default {
  components: {
    ccView,
    ccHeading,
    ccText,
    ccButton,
    ccErrorMessage,
    ccCantonListSelector,
    ccCantonConfirmationModal,
  },
  data() {
    return {
      id: 'canton-selection',
      isCantonConfirmationModalDisplayed: false,
      selectedCanton: null,
      errorMessage: null,
      isAwaiting: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    primaryActionHandler() {
      const { routingFrom } = this.appState;

      if (routingFrom.name === 'settings') {
        this.isCantonConfirmationModalDisplayed = true;
      } else {
        this.setCantonPreference();
      }
    },
    setCantonPreference() {
      this.errorMessage = null;

      if (this.selectedCanton) {
        this.isAwaiting = true;


        this.$store
          .dispatch('user/setCantonPreference', this.selectedCanton)
          .then(() => {
            this.$router.push({ name: 'canton-leaderboard' });
          })
          .catch(() => {
            this.errorMessage = this.$t('generalErrorMessage');
          })
          .finally(() => {
            this.isAwaiting = false;
          });
      } else {
        this.errorMessage = this.$t('noSelectionError');
      }
    },
  },
};
</script>
