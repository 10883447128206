import Vue from 'vue';

import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { combineURLs, getEndpoint, getAllowedParameters } from '@/mixins/utils';

const apiUrl = process.env.VUE_APP_API_URL;

const axiosClient = axios.create({
  baseURL: apiUrl,
  timeout: 30000,
});

axiosClient.interceptors.response.use(
  response => response.data,
  (error) => {
    const { method } = error?.config || false;
    const requestPayload = error?.config?.data;

    /**
     * Since Axios v0.19.1 config.url does not include the baseURL
     * thus we need to combine those for error reporting purposes
     * https://github.com/axios/axios/releases/tag/0.19.1
     * https://github.com/axios/axios/pull/2391
     * https://github.com/axios/axios/issues/1628
     */

    const combinedURL = combineURLs(error?.config?.baseURL, error?.config?.url);

    const endpoint = getEndpoint(combinedURL);

    const allowedParams = getAllowedParameters(combinedURL);

    if (typeof error.response !== 'undefined' && method) {
      const { data, status } = error.response;
      const sentryEventMessage = `${method.toUpperCase()}: ${status} @ ${endpoint}`;

      // Vue.$log.warn('safe-params', getSafeParameters(combinedURL));
      // Vue.$log.warn('endpoint', endpoint);
      // Vue.$log.warn('method', method);
      // Vue.$log.warn('status', status);
      // Vue.$log.warn('data', data);
      // Vue.$log.warn('message', error.message);
      // Vue.$log.warn('errorEvent', errorEvent);


      Sentry.withScope((scope) => {
        // Use setTag for some data points to be indexed on Sentry
        scope.setTag('endpoint', endpoint);
        scope.setTag('message', error.message);

        scope.setExtra('endpoint', endpoint);
        scope.setExtra('method', method);
        scope.setExtra('status', status);
        scope.setExtra('data', data);
        scope.setExtra('message', error.message);

        Object.entries(allowedParams).forEach(([key, value]) => {
          scope.setExtra(key, value);
          // Vue.$log.warn('allowed-params', `${key}: ${value}`);
        });

        Vue.$log.warn('sentryEventMessage', sentryEventMessage);

        Sentry.captureMessage(sentryEventMessage, 'error');
      });
    }

    if (typeof error.response === 'undefined' && error.message && method) {
      const sentryEventMessage = `${method.toUpperCase()}: ${error.message} @ ${endpoint}`;

      Sentry.withScope((scope) => {
        // Use setTag for some data points to be indexed on Sentry
        scope.setTag('endpoint', endpoint);
        scope.setTag('message', error.message);

        scope.setExtra('endpoint', endpoint);
        scope.setExtra('method', method);
        scope.setExtra('message', error.message);
        scope.setExtra('data', requestPayload ? JSON.parse(requestPayload) : null);

        Object.entries(allowedParams).forEach(([key, value]) => {
          scope.setExtra(key, value);
          // Vue.$log.warn('allowed-params', `${key}: ${value}`);
        });

        // Vue.$log.warn('sentryEventMessage', sentryEventMessage);

        Sentry.captureMessage(sentryEventMessage, 'error');
      });
    }

    // eslint-disable-next-line no-param-reassign
    // error.response.status = 500;
    throw error;
  },
);

export default axiosClient;
