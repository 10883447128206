var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-view',{staticClass:"pb-6",attrs:{"id":_vm.id}},[(!_vm.getAddresses.length)?_c('cc-wrapper',{staticClass:"h-100 px-4"},[_c('cc-section-header',{attrs:{"title":_vm.$t('deliveryAddressesEmptyStateTitle'),"svg":"illustrations/address-book"}}),_c('cc-text',{attrs:{"color":"muted","align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('deliveryAddressesEmptyStateBody'))+" ")]),_c('cc-button',{staticClass:"mt-5",attrs:{"variant":"small","text":_vm.$t('addDeliveryAddress'),"block":false},on:{"click":function($event){_vm.$router.push({ name: 'delivery-address', params: { type: 'new' } }).catch(() => {})}}})],1):_c('cc-wrapper',{attrs:{"align":"between"}},[_vm._l((_vm.getAddresses.sort((a,b) => b.isDefault - a.isDefault)),function(address){return _c('div',{key:address.id},[_c('cc-wrapper',{attrs:{"direction":"column","align":"between","justify":"start"},nativeOn:{"click":function($event){_vm.$router
          .push({
            name: 'delivery-address',
            params: { type: 'address', addressId: address.id },
          })
          .catch(() => {})}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('cc-heading',{staticClass:"mr-3",attrs:{"tag":"h3","variant":"h4"}},[_vm._v(" "+_vm._s(address.firstName)+" "+_vm._s(address.lastName)+" ")]),(address.isDefault)?_c('cc-text',{staticClass:"bg-cc-yellow border-radius-2 px-2",attrs:{"color":"cc-yellow-darker","variant":"body-sm-bold"}},[_vm._v(" "+_vm._s(_vm.$t('default'))+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between pt-2"},[_c('div',[_c('cc-text',[_vm._v(" "+_vm._s(address.streetName)+" "+_vm._s(address.houseNumber)+" ")]),_c('cc-text',[_vm._v(_vm._s(address.postalCode)+" "+_vm._s(address.city))])],1),_c('div',[_c('cc-button',{attrs:{"variant":"small-muted","block":false,"text":_vm.$t('txtEdit')}})],1)])]),_c('cc-divider',{staticClass:"my-4",attrs:{"color":"blue-lighter"}})],1)}),_c('cc-button',{staticClass:"mt-5 mx-10",attrs:{"variant":"small","text":_vm.$t('addDeliveryAddress'),"block":false},on:{"click":function($event){_vm.$router
          .push({
            name: 'delivery-address',
            params: {
              type: 'new',
            },
          })
          .catch(() => {})}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }