<template>
  <div class="barchart-size align-self-center">
    <div class="linechart-text">
      <h3 class="font-weight-bold pt-2 text-cc-soft-blue">
        {{ noDataText }}
      </h3>
    </div>
    <line-chart
      :chart-data="datacollection"
      :options="options"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LineChart from '../charts/LineChart';

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: {},
      options: {},
    };
  },
  computed: {
    ...mapState({
      chartData: state => state.trips.chartData,
      appState: state => state.common.appState,
    }),
    noDataText() {
      if (this.appState.tripsProcessing === false) {
        if (this.chartData.emission.length > 0) {
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          const emission = this.chartData.emission.reduce(reducer);
          if (emission === 0) {
            return this.$t('txtNoData');
          }
          return '';
        }
        return this.$t('txtNoData');
      }
      return '';
    },
  },
  mounted() {
    this.fillData();
    this.$watch(
      'chartData',
      () => {
        if (this.appState.tripsProcessing === false) {
          this.fillData();
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );
  },
  methods: {
    fillData() {
      this.datacollection = {
        datasets: [
          {
            label: 'Emission',
            borderColor: '#ff8484',
            pointRadius: 0,
            data: this.chartData.emission,
          },
        ],
        labels: this.chartData.labels,
      };
      this.options = {
        animation: {
          duration: 700,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: false,
              ticks: {
                beginAtZero: false,
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                padding: 16,
                maxTicksLimit: 6,
                maxRotation: 0,
              },
              scaleLabel: {
                display: true,
                labelString:
                  this.appState.filter === 'month' ? this.$t('txtDays') : this.$t('txtMonths'),
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                fontSize: 13,
                xOffset: 70,
                yOffset: 280,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: false,
              ticks: {
                beginAtZero: true,
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                padding: 16,
                suggestedMax: 500,
                maxTicksLimit: 6,
                callback(value) {
                  return value / 1000;
                },
              },
              scaleLabel: {
                display: true,
                labelString: 'kg CO₂',
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                fontSize: 13,
              },
            },
          ],
        },
      };
    },
  },
};
</script>
