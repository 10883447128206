<template>
  <div>
    <cc-card
      v-for="(month, name) in limitedNewsFeed || processedNewsFeed"
      :key="name"
      :class="cardClass"
      :outer-title="hasDate
        ? dateFormatting($moment().subtract(name, 'month').endOf('month'))
        : null"
      :variant="cardVariant"
      :card-background="cardBackground"
      :has-shadow="hasShadow"
      >
      <template #body>
        <div
          v-for="(news, i) in month"
          :key="i"
          >
          <cc-list-item
            :date="$moment(news.pubDate.text).format('L')"
            :image="handleImagePrefix(news)"
            :title="news.title.text"
            :description="news.description.text"
            :description-truncate-amount="50"
            :border-bottom="hasBorderBottom(month, i, news)"
            @click.native="clickHandler(news)"
            />
        </div>
      </template>
    </cc-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

// import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccListItem from '@/components/constructs/ListItem.vue';
import { handleImagePrefix } from '@/mixins/utils';

export default {
  components: {
    // ccWrapper,
    ccCard,
    ccListItem,
  },
  props: {
    limit: {
      type: Number,
      default: -1,
      required: false,
      validator: value => value > 0 || value === -1,
    },
    hasDate: {
      type: Boolean,
      default: true,
      required: false,
    },
    hasShadow: {
      type: Boolean,
      default: true,
      required: false,
    },
    cardVariant: {
      type: String,
      default: 'default',
      required: false,
    },
    cardBackground: {
      type: String,
      default: 'blue-dark',
      required: false,
    },
    cardClass: {
      type: String,
      default: 'pb-5',
      required: false,
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      processedNewsFeed: state => state.social.processedNewsFeed,
    }),
    ...mapGetters({
      getNewsFeed: 'social/getNewsFeed',
      getLimitedNewsFeed: 'social/getLimitedNewsFeed',
    }),
    getNewsFeedSize() {
      return Object.keys(this.processedNewsFeed).length;
    },
    limitedNewsFeed() {
      return this.getLimitedNewsFeed(this.limit);
    },
  },
  methods: {
    handleImagePrefix,
    hasBorderBottom(month, i, news) {
      /**
       * @lastNewsOfLastMonth became quite complicatated compared to what it does.
       * But here's the deal; data structure used in news feed, achievements as well as in wallet
       * uses each day relative to today as the key of an array inside an object
       * Each day is an array containing one or many achievement objects
       * In order to find the last item to disable the bottom border, we find the last day of the
       * limited recent achievements list and from that last day we pick last achievement.
       */
      if (this.limit > 0) {
        const lastNewsOfLastMonth = this.limitedNewsFeed?.[
          Object.keys(this.limitedNewsFeed)[Object.keys(this.limitedNewsFeed).length - 1]
        ]?.slice(-1)[0];

        if (news.pubDate.text === lastNewsOfLastMonth?.pubDate.text) {
          return false;
        }
        return true;
      }
      return i !== month.length - 1;
    },
    // TODO: Outsource to Store
    dateFormatting(momentObejct) {
      // console.log('momentObejct', momentObejct);
      return momentObejct.calendar(null, {
        // sameDay: `[${this.$t('Today')}]`,
        // nextDay: `[${this.$t('Tomorrow')}]`,
        nextWeek: 'MMMM',
        // lastDay: `[${this.$t('Yesterday')}]`,
        // lastWeek: `${this.$t('LastDDDD')}`,
        sameElse: () => {
          if (momentObejct.year() === this.$moment().year()) {
            return 'MMMM';
          }
          return 'MMMM, YYYY';
        },
      });
    },
    clickHandler(e) {
      this.$log.info('handleClick', e);

      if (e.link.text) {
        this.$store.dispatch('common/openUrlInternal', `${e.link.text}/?internalBrowser=true`);
      }
    },
  },
};
</script>
