<template>
  <cc-view
    v-if="!isLoading"
    :id="id"
    :has-overflow-scroll="true"
    :has-full-width="false"
    :has-body-scroll="true"
    >
    <cc-empty-state
      v-if="!hasAchievements"
      :variant="id"
      />

    <cc-section-header
      v-if="hasAchievements"
      :title="$t('challengesAchievementsCardTitle')"
      svg="illustrations/achievements-small-medal"
      />

    <cc-card
      v-if="hasAchievements"
      class="pb-6"
      >
      <template #body>
        <cc-achievements-timeline :limit="-1" />
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccAchievementsTimeline from '@/components/achievements/AchievementsTimeline.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccEmptyState from '@/components/EmptyState.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccAchievementsTimeline,
    ccSectionHeader,
    ccEmptyState,
  },
  mixins: [viewScrollMemorizer],
    props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      id: 'achievements',
    };
  },
  computed: {
    ...mapState({
      companyState: state => state.company,
    }),
    ...mapGetters({
      hasAchievements: 'achievements/hasAchievements',
    }),
  },
  async mounted() {
    this.$log.info('Mounted:', this.id);
  },
  beforeDestroy() {
    this.$store.dispatch('user/requestStoreReview');
  },
};
</script>
