<template>
  <div class="d-flex flex-column pb-5">
    <cc-text
      variant="body-xs"
      >
      {{ $t('TcDescriptionOne') }} {{ buttonName }}, {{ $t('TcDescriptionTwo') }}
      <u
        data-ruid="button__open-terms-of-service"
        @click="openUrlInternal(`https://www.swissclimatechallenge.ch/${appState.userLang}/terms-and-conditions`)"
        >
        {{ $t('termsRouteTitle') }}
      </u>

      {{ $t('txtAnd') }}

      <u
        data-ruid="button__open-privacy-policy"
        @click="openUrlInternal(`https://www.swissclimatechallenge.ch/${appState.userLang}/privacy-policy`)"
        >
        {{ $t('privacyRouteTitle') }}
      </u>
    </cc-text>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccText,
  },
  props: {
    buttonName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    openUrlInternal(url) {
      const { OPEN_INTERNAL } = this.actions;

      this.callSdk(`action=${OPEN_INTERNAL}&url=${encodeURI(`${url}?internalBrowser=true`)}`);
    },
  },
};
</script>
