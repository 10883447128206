<template>
  <div
    id="view__onboarding"
    ref="onboardingRef"
    :style="`backgroundColor: ${$route.meta.color};`"
    class="h-100 position-absolute w-100 text-center
    d-flex flex-column justify-content-between"
    >
    <div class="carousel-onboarding">
      <div
        v-for="(slide, index) in visibleSlides"
        :key="index"
        class="carousel-cell"
        >
        <div class="d-flex flex-column h-100 text-center">
          <div class="flex-grow-1 p-5">
            <h3 class="font-weight-bold">
              {{ $t(slide.title) }}
            </h3>
            <p class="m-0 pt-5">
              {{ $t(slide.content) }}
            </p>
          </div>

          <ccLottie
            ref="animation"
            :options="slide.lottieOptions"
            class="d-flex flex-column pb-3"
            />

          <p class="px-6 pb-4 m-0">
            {{ $t(slide.extra) }}
          </p>
        </div>
      </div>
    </div>

    <cc-button
      button-class="btn-block"
      text-classes="text-cc-sapphire"
      :text="$t('onboardingNextButton')"
      class="px-5 pb-6 pt-3"
      @click="primaryActionHandler"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Flickity from 'flickity';

import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';
import AnimOne from '@/components/lottie/OnboardingTwoSlideOne.json';
import AnimTwo from '@/components/lottie/OnboardingTwoSlideTwo.json';

export default {
  components: {
    ccButton,
    ccLottie,
  },
  data() {
    return {
      routingFrom: '',
      carouselIndex: 0,
      visibleSlides: [
        {
          name: 'onboardingTwoSlideOne',
          title: 'onboardingTwoSlideOneTitle',
          content: 'onboardingSlideOneBody',
          extra: 'onboardingTwoSlideOneExtra',
          lottieOptions: {
            animationData: AnimOne,
          },
        },
        {
          name: 'onboardingTwoSlideTwo',
          title: 'onboardingTwoSlideTwoTitle',
          content: 'onboardingSlideTwoBody',
          extra: 'onboardingTwoSlideTwoExtra',
          lottieOptions: {
            animationData: AnimTwo,
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  created() {
    this.$store.dispatch('user/getUser');

    const self = this;
    this.routingFrom = this.appState.routingFrom;
    this.$nextTick(() => {
      const carousel = document.querySelector('.carousel-onboarding');
      if (carousel) {
        this.flkty = new Flickity(carousel, {
          dragThreshold: 1,
          initialIndex: 0,
          percentPosition: false,
          prevNextButtons: false,
          pageDots: true,
          on: {
            change(index) {
              self.carouselIndex = index;
            },
          },
        });

        this.flkty.on('change', (index) => {
          this.sentryCaptureMessage(`Onboarding:Slide ${index} shown`, 'debug');
        });

        // Flickity helper to prevent vertical scroll while swiping left to right.
        this.flkty.on('pointerDown', (e) => {
          e.preventDefault();
        });

        this.flkty.on('dragStart', () => {
          document.ontouchmove = (e) => {
            e.preventDefault();
          };
        });
        this.flkty.on('dragEnd', () => {
          document.ontouchmove = (e) => {
            this.$log.info('dragEnd:ontouchmove', e);
            return true;
          };
        });
      }
    });

    // IOS 13 fix
    let touchingCarousel = false;
    let touchStartCoords;

    document.body.addEventListener('touchstart', (e) => {
      if (e.target.closest('.flickity-slider')) {
        touchingCarousel = true;
      } else {
        touchingCarousel = false;
        return;
      }

      touchStartCoords = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      };
    });

    document.body.addEventListener('touchmove', (e) => {
      if (!(touchingCarousel && e.cancelable)) {
        return;
      }

      const moveVector = {
        x: e.touches[0].pageX - touchStartCoords.x,
        y: e.touches[0].pageY - touchStartCoords.y,
      };

      if (Math.abs(moveVector.x) > 7) {
        e.preventDefault();
      }
    }, { passive: false });
  },
  mounted() {
    const onboardingContainer = document.getElementById('view__onboarding');
    const { onboardingRef } = this.$refs;

    // Listeners  prevent vertical scroll while swiping left to right.
    onboardingContainer.addEventListener('touchmove', (e) => {
      // this.$log.info('onboardingContainer:touchmove', e);
      e.preventDefault();
    }, { passive: false });

    onboardingRef.addEventListener('touchmove', (e) => {
      // this.$log.info('onboardingRef:touchmove', e);
      e.preventDefault();
    }, { passive: false });
  },
  methods: {
    primaryActionHandler() {
      const { carouselIndex } = this;
      const { routingFrom } = this.appState;

      if (carouselIndex > 0) {
        if (routingFrom.name !== 'settings') {
          // this.$router.push({ name: 'footprint' });
        } else {
          this.$router.push({ name: 'settings' });
        }
      } else {
        this.flkty.next();
      }
    },
  },
};
</script>
<style lang="scss">
// Custom Flickity CSS
.flickity-enabled {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.flickity-viewport {
  height: 100% !important;
  display: flex;
  flex-grow: 1;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
}

.carousel-cell {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.flickity-page-dots {
  bottom: 0;
  position: relative;
}

</style>
