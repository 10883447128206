<template>
  <component
    :is="getTag"
    :class="[getClass, getTextAlign, getFontWeight]"
    >
    <slot />
  </component>
</template>

<script>
// TODO: Outsource all primitive props as single file

// Valid variants
const variantProps = [
  'h1', //
  'h2',
  'h3',
  'h4',
  'h5',
];

const weightProps = [
  'slight', //
  'normal',
  'sbold',
  'bold',
  'xbold',
  'black',
];

const colorProps = [
  'primary', //
  'white',
  'warning',
  'danger',
  'cc-yellow-darkest',
  'cc-yellow',
  'muted',
  'blue-lighter',
  'grey-dark',
  'grey-light',
];

const alignProps = [
  'left', //
  'center',
  'right',
];

export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: 'h3',
      validator: value => variantProps.includes(value),
    },
    color: {
      type: String,
      required: false,
      default: null,
      validator: value => colorProps.includes(value),
    },
    align: {
      type: String,
      required: false,
      default: null,
      validator: value => alignProps.includes(value),
    },
    weight: {
      type: String,
      required: false,
      default: 'bold',
      validator: value => weightProps.includes(value),
    },
    tag: {
      type: String,
      required: false,
      default: 'h3',
    },
    noUpper: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getFontWeight() {
      return [
        `font-weight-${this.weight}`,
      ];
    },
    getTextAlign() {
      return [
        // Left alignment is default, declared here only to override if needed
        this.align === 'left' ? 'text-left' : null,
        this.align === 'center' ? 'text-center' : null,
        this.align === 'right' ? 'text-right' : null,
      ];
    },
    getClass() {
      const textTransform = this.noUpper ? 'text-lower' : 'text-upper';
      return [
        this.variant === 'h1' ? `h1 ${textTransform}` : null,
        this.variant === 'h2' ? `h2 ${textTransform}` : null,
        this.variant === 'h3' ? `h3 ${textTransform}` : null,
        this.variant === 'h4' ? `h4 ${textTransform}` : null,
        this.variant === 'h5' ? `h5 ${textTransform}` : null,
        this.color ? `text-${this.color}` : null,
      ];
    },
    getTag() {
      return this.tag;
    },
  },
};
</script>
