<template>
  <cc-view :id="id">
    <cc-charts-loading
      v-if="isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <div
      v-else
      class="d-flex flex-column justify-content-start h-100"
      >
      <!-- Current balance -->
      <cc-balance-card
        :amount="getPlayerScore"
        variant="points"
        />

      <!-- Empty state -->
      <div
        v-if="isEmpty(achievementsState.processedAchievements)"
        class="d-flex flex-column flex-grow-1 justify-content-center align-items-center"
        >
        <cc-svg-base svg="illustrations/wallet-empty-state" />

        <cc-text
          color="muted"
          align="center"
          class="pt-4 w-70"
          >
          {{ $t('walletNoPointsTransactionsYet') }}
        </cc-text>
      </div>

      <!-- Achievements -->
      <cc-card
        v-for="(day, name) in achievementsState.processedAchievements"
        v-else
        :key="name"
        class="py-5"
        :outer-title="dateFormatting($moment().subtract(name, 'day').endOf('day'))"
        variant="wallet"
        >
        <template #body>
          <cc-wrapper
            v-for="(achievement, index) in day"
            :key="`${achievement.id}-${achievement.grantedAt}-${index}`"
            class="flex-wrap"
            :class="index !== 0 ? 'pt-4' : ''"
            align="between"
            direction="row"
            >
            <cc-image
              class="border-radius-full mr-4 align-self-start"
              style="width: 38px; height: 38px;"
              alt="image"
              :src="getTransactionResource(achievement.achievement).imageUrl"
              :has-placeholder="true"
              placeholder-class="bg-blue-light"
              />

            <cc-wrapper
              align="between"
              class="flex-1"
              >
              <cc-wrapper
                align="start"
                direction="row"
                justify="between"
                >
                <cc-wrapper align="start">
                  <cc-text
                    variant="body-bold"
                    class="mr-4"
                    >
                    {{ getTransactionResource(achievement.achievement).title }}
                  </cc-text>

                  <cc-text
                    class="text-capitalize"
                    color="muted"
                    variant="body-xs"
                    >
                    {{ $t('txtAt') }}
                    {{ $moment(achievement.grantedAt).format(`${appState.isProd ? 'LT' : 'LTS'}`) }}
                  </cc-text>
                </cc-wrapper>

                <cc-text
                  variant="body-bold"
                  color="primary"
                  >
                  {{ `+${achievement.achievement.reward.points}` }}
                </cc-text>
              </cc-wrapper>

              <cc-tiny-receipt-card
                v-if="hasTinyReceipt(achievement.achievement)"
                :title="getVirtualGoodResource(achievement.achievement).title"
                :company-name="getCompanyResourceFromTransaction(achievement).name"
                :image="getReceiptImage(achievement.achievement)"
                @open-transaction="openTransaction(achievement.id)"
                />
            </cc-wrapper>

            <cc-divider
              v-if="index !== day.length - 1"
              class="pb-4"
              />
          </cc-wrapper>
        </template>
      </cc-card>
    </div>

    <cc-good-receipt-modal
      v-if="isGoodReceiptModalDisplayed"
      :data="goodReceiptModalData"
      @close-modal="isGoodReceiptModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccBalanceCard from '@/components/achievements/BalanceCard.vue';
import ccTinyReceiptCard from '@/components/achievements/TinyReceiptCard.vue';
import ccGoodReceiptModal from '@/components/modals/GoodReceiptModal.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccCard,
    ccText,
    ccSvgBase,
    ccDivider,
    ccChartsLoading,
    ccImage,
    ccBalanceCard,
    ccTinyReceiptCard,
    ccGoodReceiptModal,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'points',
      isLoading: true,
      isGoodReceiptModalDisplayed: false,
      goodReceiptModalData: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      companyState: state => state.company,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      getPlayerScore: 'company/getPlayerScore',
      getLocalizedResource: 'company/getLocalizedResource',
      getCompanyMetaById: 'company/getCompanyMetaById',
      getVirtualGoodResource: 'company/getVirtualGoodResource',
      getInventoryVoucher: 'company/getInventoryVoucher',
      dateFormatting: 'common/dateFormatting',
      getReceiptImage: 'company/getReceiptImage',
      getCompanyResourceFromTransaction: 'company/getCompanyResourceFromTransaction',
      getTransactionResource: 'company/getTransactionResource',
      hasTinyReceipt: 'company/hasTinyReceipt',
    }),
  },
  async created() {
    try {
      await this.$store.dispatch('company/getPlayer');
      await this.$store.dispatch('achievements/getPlayerAchievements');
    } catch (error) {
      this.$log.info('Points Error:', error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
      setAppState: 'common/setAppState',
    }),
    isEmpty,
    openTransaction(achievementId) {
      this.goodReceiptModalData = { achievementId };
      this.isGoodReceiptModalDisplayed = true;
    },
  },
};
</script>
