<template>
  <cc-view :id="id">
    <cc-card
      v-for="(platform, key, i) in platforms"
      :key="i"
      class="mb-6"
      :outer-title="`${key} credits`"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          v-for="(dependency, name, index) in platform"
          :key="`${name}__index`"
          :label="name"
          :label-data="dependency.licenses || '-'"
          :last="Object.keys(platform).length === index + 1"
          @click.native="$store.dispatch('common/openUrlInternal', dependency.licenseUrl)"
          />
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';

import webDependencies from '@/assets/dependencies.json';
import androidDependencies from '@/assets/androidDependencies.json';
import iosDependencies from '@/assets/iosDependencies.json';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccSettingsListItem,
  },
  data() {
    return {
      id: 'credits',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    platforms() {
      const { os } = this.appState;
      const platforms = {};

      platforms.web = webDependencies;

      if (os === 'android' || os === 'web') {
        platforms.android = androidDependencies;
      }

      if (os === 'ios' || os === 'web') {
        platforms.ios = iosDependencies;
      }

      return platforms;
    },
  },
};
</script>
