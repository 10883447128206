<template>
  <cc-view :id="id">
    <cc-notification-reminder
      v-if="hasNotificationReminder"
      class="mb-4"
      />
    <div
      v-for="(category, index) in uniqueCategories"
      :key="index"
      class="mb-6"
      >
      <cc-heading
        tag="h3"
        variant="h3"
        class="mb-3"
        >
        {{ $t(`${camelize(category)}MessageCategoryTitle`) }}
      </cc-heading>

      <div
        v-for="(tag, key, i) in messages.delivery.tags"
        :key="i"
        >
        <div
          v-if="getTagClass(key) === category"
          class="mt-4"
          >
          <div class="d-flex pb-2">
            <cc-text
              variant="body-bold"
              class="flex-grow-1"
              >
              {{ $t(`${camelize(category)}${sentenceCase(camelize(getTagId(key)))}MessageTitle`) }}
            </cc-text>

            <toggle-button
              class="pl-1"
              :value="messages.delivery.tags[key].push"
              :width="48"
              :height="24"
              :margin="2"
              :disabled="canToggle"
              :sync="true"
              :color="{ unchecked: '#4B97FF', checked: '#25C98B' }"
              @change="handleToggle(key, 'push')"
              />
          </div>

          <cc-text
            variant="body"
            class="pb-4 pr-10"
            color="muted"
            >
            {{ $t(`${camelize(category)}${sentenceCase(camelize(getTagId(key)))}MessageBody`) }}
          </cc-text>

          <cc-divider
            color="blue-light"
            />
        </div>
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { sentenceCase, camelize } from '@/mixins/utils';
import { cloneDeep } from 'lodash';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccNotificationReminder from '@/components/NotificationReminder.vue';

export default {
  components: {
    ccView,
    ccText,
    ccHeading,
    ccDivider,
    ccNotificationReminder,
  },
  data() {
    return {
      id: 'message-delivery-preferences',
      messages: null,
    };
  },
  computed: {
    ...mapState({
      appState: st => st.common.appState,
      user: st => st.user.user,
    }),
    ...mapGetters({
      getUserMessagesPreferences: 'user/getUserMessagesPreferences',
      hasNotificationReminder: 'common/hasNotificationReminder',
    }),
    uniqueCategories() {
      const duplicateCategories = Object.keys(this.getUserMessagesPreferences.delivery.tags).map(
        el => el.split(':')[0],
      );
      return [...new Set(duplicateCategories)];
    },
    canToggle() {
      if (this.appState.appId === 'web') {
        return false;
      }
      return this.hasNotificationReminder;
    },
  },
  created() {
    this.messages = cloneDeep(this.getUserMessagesPreferences);
  },
  methods: {
    sentenceCase,
    camelize,
    handleToggle(tag, deliveryMethod) {
      const { delivery } = this.messages;

      delivery.tags[tag][deliveryMethod] = !delivery.tags[tag][deliveryMethod];

      const user = {
        ...this.user,
        preferences: {
          ...this.user.preferences,
          messages: {
            ...this.messages,
          },
        },
      };

      try {
        this.$store.dispatch('user/setUserProfile', user);
      } catch (error) {
        this.$log.info('Message Delivery Preferences error:', error);
      }
    },
    getTagId(tag) {
      return tag.split(':')[1];
    },
    getTagClass(tag) {
      return tag.split(':')[0];
    },
  },
};
</script>

<style>
</style>
