<template>
  <cc-wrapper
    direction="row"
    justify="start"
    align="center"
    class="w-100 pb-4 flex-wrap"
    >
    <cc-wrapper
      direction="row"
      justify="start"
      align="center"
      class="w-100 flex-no-wrap"
      >
      <cc-text class="flex-grow-1 text-truncate pr-4">
        {{ userIdentifier.split(':')[1] }}
      </cc-text>

      <cc-button
        :variant="getCurrentButton.type"
        :block="false"
        :text="$t(getCurrentButton.text)"
        :icon="getCurrentButton.icon"
        :icon-color="getCurrentButton.iconColor"
        icon-side="left"
        icon-class="mr-2"
        :has-spinner-feedback="hasSpinnerFeedback"
        :spinner-bg-color="getCurrentButton.spinnerBgcolor"
        @click="!isInvited ? clickHandler() : undefined"
        />
    </cc-wrapper>
    <cc-divider
      v-if="hasDivider"
      style="flex-basis: 100%;"
      class="pt-4"
      />
  </cc-wrapper>
</template>

<script>
import { mapMutations } from 'vuex';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccWrapper,
    ccDivider,
    ccButton,
    ccText,
  },
  props: {
    userIdentifier: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    hasDivider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isInvited: false,
      currentButton: 'inviteButton',
      inviteButton: {
        type: 'small-muted',
        text: 'txtInvite',
        icon: 'icons/icon-add-user',
        iconColor: 'svg-fill-white',
        spinnerBgcolor: 'bg-blue-lightest',
      },
      invitedButton: {
        type: 'small',
        text: 'txtInvited',
        icon: 'icons/icon-check',
        iconColor: 'svg-fill-blue-dark',
        spinnerBgcolor: 'bg-blue-dark',
      },
      retryButton: {
        type: 'small-danger',
        text: 'txtRetry',
        icon: 'icons/icon-retry',
        iconColor: 'svg-fill-white',
        spinnerBgcolor: 'bg-white',
      },
      hasSpinnerFeedback: false,
    };
  },
  computed: {
    getCurrentButton() {
      return this[this.currentButton];
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    clickHandler() {
      const { group } = this.kibanaEvents;

      this.hasSpinnerFeedback = true;

      this.$store
        .dispatch('social/addExternalMembers', {
          groupId: this.groupId,
          userIdentifier: this.userIdentifier,
          role: 'participant',
        })
        .then(() => {
          this.currentButton = 'invitedButton';
          this.isInvited = true;

          this.$store.dispatch('common/insertEvent', {
            category: group.category,
            action: group.invitationSent.action,
            type: 'sms',
          });
        })
        .catch((error) => {
          this.currentButton = 'retryButton';
          this.$log.info('External invite', error);
          this.sentryCaptureException(error);
        })
        .finally(() => {
          this.hasSpinnerFeedback = false;
        });
    },
  },
};
</script>
