<template>
  <div class="public-goal-chart-container barchart-size align-self-center">
    <bar-chart
      ref="chart"
      :chart-data="datacollection"
      :options="options"
      />
  </div>
</template>

<script>
import BarChart from '@/charts/CustomBarChart';
import { getEmissionFromAvgAccumulators, getLabelFromLastSevenday } from '@/mixins/utils';

export default {
  name: 'PublicGoalEmissionBarChart',
  components: {
    BarChart,
  },
  props: {
    avgAccumulators: {
      type: Object,
      required: false,
      default: null,
    },
    lastSevenday: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      emission: [0, 0, 0, 0, 0, 0, 0],
      userEmission: [0, 0, 0, 0, 0, 0, 0],
      zeroPoint: 600,
      datacollection: {},
      options: {},
    };
  },
  computed: {
    barThickness() {
      return 12;
    },
  },
  watch: {
    avgAccumulators() {
      this.initializeData();
      this.fillData();
    },
  },
  mounted() {
    this.initializeData();
    this.fillData();
  },
  methods: {
    initializeData() {
      this.emission = getEmissionFromAvgAccumulators(
        this.avgAccumulators,
        'avgAccumulatorAllUsers',
      );
      this.userEmission = getEmissionFromAvgAccumulators(this.avgAccumulators, 'userAccumulator');
      this.labels = getLabelFromLastSevenday(this.lastSevenday);
    },
    fillData() {
      this.datacollection = {
        datasets: [
          {
            type: 'line',
            borderColor: '#ffffff',
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            data: [9500, 9500, 9500, 9500, 9500, 9500, 9500],
            borderDashOffset: -1,
            pointRadius: 0,
            beginAtZero: true,
          },
          {
            label: 'kg of CO₂',
            backgroundColor: '#FF8484',
            zeroColor: '#ffcc00',
            data: this.userEmission,
            barThickness: this.barThickness,
            minBarLength: 0,
          },
          {
            label: 'kg of CO₂',
            backgroundColor: '#ffda53',
            zeroColor: '#ffcc00',
            data: this.emission,
            barThickness: this.barThickness,
            minBarLength: 0,
          },
        ],
        labels: this.labels,
      };

      this.options = {
        animation: {
          duration: 700,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scaleLineColor: 'rgba(0, 0, 0, 0)',
        scales: {
          offset: false,
          xAxes: [
            {
              display: true,
              stacked: false,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: false,
                fontColor: '#B8D4FF',
                fontFamily: 'TheSans',
                fontStyle: 'normal',
                fontWeigth: 700,
                maxTicksLimit: 9,
                maxRotation: 0,
                fontSize: 10,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: false,
              gridLines: {
                offsetGridLines: false,
                color: '#0A6EFA80',
                zeroLineColor: '#0A6EFA80',
                drawBorder: false,
              },
              ticks: {
                min: 0,
                beginAtZero: true,
                fontColor: '#B8D4FF',
                fontFamily: 'TheSans',
                fontSize: 9,
                fontWeigth: 700,
                fontStyle: 'normal',
                suggestedMax: 5,
                maxTicksLimit: 6,
                callback(value) {
                  return value / 1000;
                },
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
/*  Barchart  */
.public-goal-chart-container.barchart-size {
  max-width: 100%;
  position: relative;
  margin: auto;
  min-height: 122px !important;
}

.public-goal-chart-container #rounded-bar {
  height: 122px !important;
}
</style>
