<template>
  <cc-view
    :id="id"
    :is-modal="isModal"
    >
    <div
      class="d-flex flex-column justify-content-around
      pb-6 h-100 flex-grow-1 safe-area-inset-bottom"
      >
      <cc-heading
        align="center"
        class="px-5"
        >
        {{ $t('joinTheChallengeTitle') }}
      </cc-heading>

      <div>
        <ccLottie
          width="90px"
          height="80px"
          class="trophy px-2"
          :options="lottieOptions"
          />
      </div>

      <div class="px-6">
        <cc-friends-leaderboard-card-svg class="pb-2 px-6" />
      </div>

      <cc-text
        color="muted"
        align="center"
        class="pb-2"
        >
        {{ $t('joinTheChallengeDescription') }}
      </cc-text>

      <div>
        <cc-button
          data-ruid="global__button-confirm"
          class="mb-3"
          :text="$t('txtVerifyPhoneNumber')"
          @click="primaryActionHandler"
          />
        <cc-button
          data-ruid="global__button-later"
          variant="secondary"
          :text="$t('txtDismiss')"
          @click="secondaryActionHandler"
          />
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';
import ccFriendsLeaderboardCardSvg from '@/components/FriendsLeaderboardCardSvg.vue';
import { getBackRoute } from '@/mixins/utils';

import trophy from '@/components/lottie/trophy.json';

export default {
  components: {
    ccView,
    ccHeading,
    ccText,
    ccButton,
    ccLottie,
    ccFriendsLeaderboardCardSvg,
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'join-the-challenge',
      lottieOptions: {
        autoplay: true,
        animationData: trophy,
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      appState: state => state.common.appState,
      historyState: state => state.common.historyState,
    }),
    ...mapGetters({
      isUserAuthenticated: 'user/isUserAuthenticated',
    }),
  },
  destroyed() {
    this.$log.warn('Destroyed: join-the-challenge');
  },

  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    primaryActionHandler() {
      if (this.isModal) {
        this.$emit('route-object', { name: 'SignUp' });
      } else {
        this.$router.push({ name: 'signup' });
      }
    },
    secondaryActionHandler() {
      if (this.isModal) {
        this.$emit('close-modal');
      } else {
          this.$router
            .push({
              name: getBackRoute(
                this.$route,
                this.appState.routingFrom.name,
                this.isUserAuthenticated,
                this.historyState,
              ),
            })
            .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 320px) {
  .trophy {
    display: none;
  }
}
</style>
