const truncate = {
  bind(el, binding) {
    const { length } = el.textContent;
    // const { $log } = vnode.context;
    const maxCharLength = binding.arg;

    const truncatedText = el.textContent.substring(0, maxCharLength);

    if (length > maxCharLength) {
      // $log.info(`Will truncate with ${maxCharLength}:`, el.textContent);
      // eslint-disable-next-line no-param-reassign
      el.textContent = `${truncatedText}...`;
    }
  },
};

export default {
  install(Vue) {
    Vue.directive('truncate', truncate);
  },
};
