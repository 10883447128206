<template>
  <div>
    <transition
      name="view-fade"
      mode="out-in"
      >
      <div
        key="1"
        class="w-100 mx-auto"
        :class="appState.displayAlert ? 'mt-4':''"
        >
        <div class="text-bubble d-flex flex-row justify-content-start  mw-100 ">
          <div class="text-bubble d-flex flex-column  mw-100 flex-fill">
            <div
              id="earth-mood-feedback"
              :class="`bg-${textBubbleColor} text-${textBubbleTextColor}`"
              class="p-3 font-size-14 line-height rounded text-left preline-formatted mw-100
              transition-max-height overflow-hidden"
              >
              <!-- https://wiki.swisscom.com/display/SCCD/SCC+-+PRD+-+Earth+Mood+and+Improvement+Tips -->
              <div v-if="totalKm > 0">
                <div>
                  <span v-if="positiveFeedback">
                    {{ $t('wellDone') }}
                  </span>
                  <!-- 2. If good transportation modes total distance is equal
                    or less than 50% -->
                  <span v-else>{{ $t('feedback2') }}</span>
                  <ul class="pl-0 mb-0">
                    <!-- 1. If good transportation modes total distance more than 50%  -->
                    <li v-if="isHappyEarth">
                      <span>{{ $t('feedback1') }}</span>
                    </li>


                    <!-- 3. If good transportation modes total distance is equal
                    or less than 50% -->
                    <li v-if="isZeroCarTrip">
                      <span>{{ $t('feedback3') }}</span>
                    </li>

                    <!-- 4. If fossil equal 0 AND distance more than 0 km -->
                    <li v-if="isZeroFossilTrip">
                      <span>{{ $t('feedback4') }}</span>
                    </li>

                    <!-- 5. If human-powered more than 50% of total distance -->
                    <li v-if="isHumanPoweredMoreThan50percentage">
                      <span>{{ $t('feedback5') }}</span>
                    </li>
                  </ul>

                  <!-- Earth has two states:
                    Happy when >50% of distance is made using 'good' transportation modes.
                    Sad when >=50% of distance is made using 'bad' transportation modes.
                    -->

                  <div>
                    <span
                      v-if="hasTips"
                      class="d-flex align-items-center mt-1 font-weight-bold"
                      @click="isEarthMoodTipModalOpen = true"
                      >
                      <cc-svg-base
                        style="min-width: 18px;"
                        :svg="isHappyEarth
                          ? 'icons/icon-circle-info-green':'icons/icon-circle-info-yellow'"
                        />
                      <span class="d-flex flex-wrap align-items-center rounded pl-2">
                        {{ $t('feedback6_1') }}
                        <span
                          class="ml-1"
                          :style="
                            `border-bottom: 2px solid ${
                              isHappyEarth ? 'var(--cc-green-darker)' : 'var(--cc-yellow-darkest)'
                            };
                          line-height:1rem`"
                          >
                          {{ $t('feedback6_2') }}
                        </span>
                      </span>
                    </span>
                    <span
                      v-else
                      class="d-flex align-items-center mt-1 font-weight-bold"
                      >
                      <cc-svg-base
                        style="min-width: 18px;"
                        :svg="isHappyEarth
                          ? 'icons/icon-circle-info-green':'icons/icon-circle-info-yellow'"
                        />
                      <span class=" pl-2">{{ $t('feedback6_3') }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="appState.tripsProcessing === true">
                <cc-lottie
                  id="feedback-loading"
                  key="feedback-loading"
                  style="max-width:60px;"
                  :options="animDataLoading.currentAnim"
                  />
              </div>
              <span
                v-else-if="isNotrips"
                style="line-height:43.75px"
                >
                {{ $t('feedback7') }}
              </span>
              <span
                v-if="getRelativeChange && totalKm > 0"
                class="d-flex align-items-center mt-1 font-weight-bold"
                >
                <cc-svg-base
                  style="min-width: 18px;"
                  svg="icons/icon-circle-arrow"
                  :style="`transform: rotate(${getRelativeChange > 0 ? '180deg' : '0deg'})`"
                  :class="getPercentageIconColors"
                  />
                <span
                  class="d-flex align-items-center rounded pl-2"
                  >
                  {{ Math.abs(getRelativeChange) }}%
                  {{
                    getRelativeChange > 0
                      ? $t('comparativeMoreEmissionThan')
                      : $t('comparativeLessEmissionThan')
                  }}
                  {{ comparativeMessagePreviousTimeFrame }}
                </span>
              </span>
            </div>

            <cc-svg-base
              class="earth-triangle"
              svg="earth-text-triangle"
              :class="`svg-fill-${textBubbleColor}`"
              />
          </div>
        </div>
        <div>
          <cc-earth-mood-chart
            :emission-text="emissionText(totalCo2)"
            :is-happy="isHappyEarth"
            />
        </div>
      </div>
    </transition>

    <cc-earth-mood-tip-modal
      v-if="isEarthMoodTipModalOpen"
      :tips-data="tipsData"
      @close-modal="isEarthMoodTipModalOpen = false"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getPercentageChange } from '@/mixins/utils';

import ccLottie from '@/components/Lottie.vue';
// import Loading from '@/components/lottie/loading.json';
import FeedbackLoading from '@/components/lottie/feedback-loading.json';
import ccSvgBase from '@/components/SvgBase.vue';
import ccEarthMoodChart from '@/components/footprint/EarthMoodChart.vue';
import ccEarthMoodTipModal from '@/components/modals/EarthMoodTipModal.vue';

export default {
  components: {
    ccLottie,
    ccSvgBase,
    ccEarthMoodChart,
    ccEarthMoodTipModal,

  },
  data() {
    return {
      totalKm: 0,
      totalCo2: 0,
      textBubbleColor: 'blue-lighter',
      textBubbleTextColor: 'white',
      isEarthMoodTipModalOpen: false,
      animDataLoading: {
        currentAnim: {
          animationData: FeedbackLoading,
        },
      },
    };
  },
  computed: {
    ...mapState({
      // temperature: state => state.trips.temperature,
      chartData: state => state.trips.chartData,
      previousChartData: state => state.trips.previousChartData,
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      badTransport: state => state.trips.badTransport,
    }),
    prefix() {
      const queryDate = new Date(this.appState.queryDate);

      if (queryDate.setDate(queryDate.getDate() + 1) > new Date()) {
        return this.$t('selectorBarToday');
      }

      return this.$t('thisDay');
    },
    earthMessageStart() {
      switch (this.appState.filter) {
        case 'today':
          return `${this.prefix} ${this.$t('earthMessageToday')} `;
        case 'week':
          return `${this.$t('earthMessageWeek')}`;
        case 'month':
          return `${this.$t('earthMessageMonth')}`;
        case 'year':
          return `${this.$t('earthMessageYear')}`;
        default:
          return '';
      }
    },
    comparativeMessagePreviousTimeFrame() {
      switch (this.appState.filter) {
        case 'today':
          return this.$t('comparativeYesterday');
        case 'week':
          return this.$t('comparativeLastWeek');
        case 'month':
          return this.$t('comparativeLastMonth');
        case 'year':
          return this.$t('comparativeLastYear');
        default:
          return '';
      }
    },
    getPercentageIconColors() {
      const { getColorState } = this;

      switch (getColorState) {
        case 'happy':
          return 'svg-fill-cc-green svg-fill-circle-cc-green-darker';
        case 'sad':
          return 'svg-fill-cc-yellow svg-fill-circle-cc-yellow-darkest';
        default:
          return 'svg-fill-blue-dark svg-fill-circle-white';
      }
    },
    getPercentageColor() {
      const { getColorState } = this;

      switch (getColorState) {
        case 'happy':
          return 'bg-cc-green-darkest text-cc-green';
        case 'sad':
          return 'bg-cc-yellow-darkest text-cc-yellow';
        default:
          return 'bg-white text-blue-lighter';
      }
    },
    getColorState() {
      if (this.totalKm === 0) {
        return 'default';
      }
      if (this.isHappyEarth) {
        return 'happy';
      }
      return 'sad';
    },
    getRelativeChange() {
      const { chartData, previousChartData } = this;

      // this.$log.info('Will getRelativeChange');

      const percentageChange = parseInt(
        getPercentageChange(
          chartData.summary.totalCo2, //
          previousChartData?.summary?.totalCo2,
        ).toFixed(0),
        10,
      );
      if (Number.isFinite(percentageChange)) {
        return percentageChange;
      }
      return false;
    },
    isNotrips() {
      return this.totalKm === 0;
    },
    // Trip Feedback Conditions
    isHappyEarth() {
      return this.getBadTransportPercentage() < 50;
    },
    isZeroCarTrip() {
      return this.totalKm > 0 && this.getCarDistance() === 0;
    },
    isZeroFossilTrip() {
      return this.totalKm > 0 && this.getFossilDistance() === 0;
    },
    isHumanPoweredMoreThan50percentage() {
      return (this.getHumanPoweredDistance() / this.totalKm) * 100 > 50;
    },
    positiveFeedback() {
      return this.isHappyEarth || this.isZeroCarTrip || this.isZeroFossilTrip
      || this.isHumanPoweredMoreThan50percentage;
    },

    // Trip Tip Conditions
    isBusFossilAndLessOrEqualThan15Km() {
      return this.getBusFossilDistance() <= 15000 && this.getBusFossilDistance() > 0;
    },
    isBusFossilAndMoreThan15Km() {
      return this.getBusFossilDistance() > 15000;
    },
    isElectricCarAndLessOrEqualThan15Km() {
      return this.getElectricCarDistance() <= 15000 && this.getElectricCarDistance() > 0;
    },
    isElectricCarAndMoreThan15Km() {
      return this.getElectricCarDistance() > 15000;
    },
    isHybridCarAndLessOrEqualThan15Km() {
      return this.getHybridCarDistance() <= 15000 && this.getHybridCarDistance() > 0;
    },
    isHybridCarAndMoreThan15Km() {
      return this.getHybridCarDistance() > 15000;
    },
    isFossilCarAndLessOrEqualThan15Km() {
      return this.getFossilCarDistance() <= 15000 && this.getFossilCarDistance() > 0;
    },
    isFossilCarAndMoreThan15Km() {
      return this.getFossilCarDistance() > 15000;
    },
    isAirplane() {
      return this.getAirplaneDistance() > 0;
    },
    isPeoqpleEqual1CarTrip() {
      return this.getCarWithPeopleEqual1Distance() > 0;
    },

    hasTips() {
      if (this.isBusFossilAndLessOrEqualThan15Km || this.isBusFossilAndMoreThan15Km
        || this.isElectricCarAndLessOrEqualThan15Km || this.isElectricCarAndMoreThan15Km
        || this.isHybridCarAndLessOrEqualThan15Km || this.isHybridCarAndMoreThan15Km
        || this.isFossilCarAndLessOrEqualThan15Km || this.isFossilCarAndMoreThan15Km
        || this.isAirplane || this.isPeoqpleEqual1CarTrip) {
        return true;
      }
      return false;
    },
    listTips() {
      const listTips = [];
      if (this.isAirplane) listTips.push(10);
      if (this.isFossilCarAndMoreThan15Km) listTips.push(9);
      if (this.isFossilCarAndLessOrEqualThan15Km) listTips.push(8);
      if (this.isHybridCarAndMoreThan15Km) listTips.push(7);
      if (this.isHybridCarAndLessOrEqualThan15Km) listTips.push(6);
      if (this.isElectricCarAndMoreThan15Km) listTips.push(5);
      if (this.isElectricCarAndLessOrEqualThan15Km) listTips.push(4);
      if (this.isPeoqpleEqual1CarTrip) listTips.push(3);
      if (this.isBusFossilAndMoreThan15Km) listTips.push(2);
      if (this.isBusFossilAndLessOrEqualThan15Km) listTips.push(1);
      return listTips;
    },
    tipsData() {
      const tipsData = this.listTips.map((tip) => {
        if (tip === 1) {
          return { tip, co2: this.busFossilCo2, distance: this.busFossilDistance };
        }
        if (tip === 2) {
          return { tip, co2: this.busFossilCo2, distance: this.busFossilDistance };
        }
        if (tip === 3) {
          return {
            tip,
            co2: this.getCarWithPeopleEqual1Co2(),
            distance: this.getCarWithPeopleEqual1Distance(),
          };
        }
        if (tip === 4) {
          return { tip, co2: this.electricCarCo2, distance: this.electricCarDistance };
        }
        if (tip === 5) {
          return { tip, co2: this.electricCarCo2, distance: this.electricCarDistance };
        }
        if (tip === 6) {
          return {
            tip,
            co2: this.hybridCarCo2,
            distance: this.hybridCarDistance,
            details: this.getHybridCarCo2Details(),
          };
        }
        if (tip === 7) {
          return {
            tip,
            co2: this.hybridCarCo2,
            distance: this.hybridCarDistance,
            details: this.getHybridCarCo2Details(),
          };
        }
        if (tip === 8) {
          return {
            tip,
            co2: this.fossilCarCo2,
            distance: this.fossilCarDistance,
            details: this.getFossilCarCo2Details(),
          };
        }
        if (tip === 9) {
          return {
            tip,
            co2: this.fossilCarCo2,
            distance: this.fossilCarDistance,
            details: this.getFossilCarCo2Details(),
          };
        }
        if (tip === 10) {
          return { tip, co2: this.airplaneCo2, distance: this.airplaneDistance };
        }
        return { tip, co2: 0, distance: 0 };
      });
      return tipsData;
    },

    // Distance
    busFossilDistance() {
      return this.getBusFossilDistance();
    },
    electricCarDistance() {
      return this.getElectricCarDistance();
    },
    hybridCarDistance() {
      return this.getHybridCarDistance();
    },
    fossilCarDistance() {
      return this.getFossilCarDistance();
    },
    airplaneDistance() {
      return this.getAirplaneDistance();
    },
    peopleEqual1CarDistance() {
      return this.getCarWithPeopleEqual1Distance();
    },
    // Co2
    busFossilCo2() {
      return this.getBusFossilCo2();
    },
    electricCarCo2() {
      return this.getElectricCarCo2();
    },
    hybridCarCo2() {
      return this.getHybridCarCo2();
    },
    fossilCarCo2() {
      return this.getFossilCarCo2();
    },
    airplaneCo2() {
      return this.getAirplaneCo2();
    },
    peopleEqual1CarCo2() {
      return this.getCarWithPeopleEqual1Co2();
    },

  },
  mounted() {
    this.$watch(
      'chartData.summary',
      (val) => {
        if (val !== undefined) {
          this.totalKm = val.totalDistance;
          this.totalCo2 = val.totalCo2;
        } else {
          this.totalKm = 0;
          this.totalCo2 = 0;
        }
        this.setEarthState();
      },
      { immediate: true },
    );
  },
  methods: {
    getPercentageChange,
    setEarthState() {
      setTimeout(() => {
        const feedBackContainer = document.getElementById('earth-mood-feedback');
      if (feedBackContainer) {
        feedBackContainer.style.maxHeight = `${feedBackContainer.scrollHeight + 18}px`;
      }
      }, 20);
      // let anim;
      if (this.totalKm === 0) {
        // anim = GlobeHappy;
        this.textBubbleColor = 'blue-light';
        this.textBubbleTextColor = 'white';
      } else if (this.isHappyEarth) {
        // anim = GlobeHappy;
        this.textBubbleColor = 'cc-green';
        this.textBubbleTextColor = 'cc-green-darker';
      } else {
        // anim = GlobeSad;
        this.textBubbleColor = 'cc-yellow';
        this.textBubbleTextColor = 'cc-yellow-darkest';
      }

      // this.animData.currentAnim = { animationData: anim };
    },
    distanceText(distance) {
      if (distance >= 1000) {
        const kilometers = (distance / 1000).toFixed(1);
        return `${kilometers} km`;
      }
      return `${distance.toFixed(1)} m`;
    },
    emissionText(emission) {
      if (emission >= 1000) {
        const co2 = (emission / 1000).toFixed(1);
        return `${co2} kg`;
      }
      return `${emission.toFixed(0)} g`;
    },
    getBadTransportDistance() {
      if (!this.timeline?.currentPeriod) return 0;
      const badTransportData = this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => this.badTransport
          .includes(
            `${trip.transitMode}-${trip.meta.emissionType.includes('fossil') ? 'fossil' : ''}`,
          ),
      );
      if (badTransportData.length === 0) return 0;
      return badTransportData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getBadTransportPercentage() {
      if (!this.getBadTransportDistance()) return 0;
      const badTransportDistance = this.getBadTransportDistance();
      return (badTransportDistance / this.chartData.summary.totalDistance) * 100;
    },
    getFossilDistance() {
      if (!this.timeline?.currentPeriod) return 0;
      const fossilData = this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.meta.emissionType.includes('fossil'),
      );
      if (fossilData.length === 0) return 0;
      return fossilData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getHumanPoweredDistance() {
      if (!this.timeline?.currentPeriod) return 0;
      const humanPoweredData = this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.displayType?.emissionType.includes('human-powered'),
      );
      if (humanPoweredData.length === 0) return 0;
      return humanPoweredData.reduce((acc, trip) => acc + trip.distance, 0);
    },

    getCarTrips() {
      if (!this.timeline?.currentPeriod) return 0;
      return this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.transitMode === 'car',
      );
    },
    getCarDistance() {
      const carData = this.getCarTrips();
      if (carData.length === 0) return 0;
      return carData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getCarCo2() {
      const carData = this.getCarTrips();
      if (carData.length === 0) return 0;
      return carData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },
    getCarWithPeopleEqual1Trips() {
      return this.getCarTrips().filter(trip => trip.numberOfPeople === 1);
    },
    getCarWithPeopleEqual1Distance() {
      const carData = this.getCarWithPeopleEqual1Trips();
      if (carData.length === 0) return 0;
      return carData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getCarWithPeopleEqual1Co2() {
      const carData = this.getCarWithPeopleEqual1Trips();
      if (carData.length === 0) return 0;
      return carData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },

    getFossilCarTrips() {
      if (!this.timeline?.currentPeriod) return 0;
      return this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.transitMode === 'car' && trip.meta.emissionType.includes('fossil'),
      );
    },
    getFossilCarDistance() {
      const fossilCarData = this.getFossilCarTrips();
      if (fossilCarData.length === 0) return 0;
      return fossilCarData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getFossilCarCo2() {
      const fossilCarData = this.getFossilCarTrips();
      if (fossilCarData.length === 0) return 0;
      return fossilCarData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },
    checkAllTheFossilCarHaveOnlyOnePerson() {
      const fossilCarData = this.getFossilCarTrips();
      if (fossilCarData.length === 0) return false;
      return fossilCarData.every(trip => trip.numberOfPeople === 1);
    },
    getFossilCarCo2Details() {
      if (this.checkAllTheFossilCarHaveOnlyOnePerson()) {
        return null;
      }
      const fossilCarData = this.getFossilCarTrips();
      if (fossilCarData.length === 0) return 0;
      // return an array of distance, co2 and numberOfPeople
      return fossilCarData.map(trip => ({
          distance: trip.distance,
          co2: trip.meta.emissions.co2,
          numberOfPeople: trip.numberOfPeople,
        }));
    },

    getBusFossilTrips() {
      if (!this.timeline?.currentPeriod) return 0;
      return this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.transitMode === 'bus' && trip.meta.emissionType.includes('fossil'),
      );
    },
    getBusFossilDistance() {
      const busFossilData = this.getBusFossilTrips();
      if (busFossilData.length === 0) return 0;
      return busFossilData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getBusFossilCo2() {
      const busFossilData = this.getBusFossilTrips();
      if (busFossilData.length === 0) return 0;
      return busFossilData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },

    getElectricCarTrips() {
      if (!this.timeline?.currentPeriod) return 0;
      return this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.transitMode === 'car' && trip.meta.emissionType.includes('electric'),
      );
    },
    getElectricCarDistance() {
      const electricCarData = this.getElectricCarTrips();
      if (electricCarData.length === 0) return 0;
      return electricCarData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getElectricCarCo2() {
      const electricCarData = this.getElectricCarTrips();
      if (electricCarData.length === 0) return 0;
      return electricCarData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },

    getHybridCarTrips() {
      if (!this.timeline?.currentPeriod) return 0;
      return this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.transitMode === 'car' && trip.meta.emissionType.includes('hybrid'),
      );
    },
    getHybridCarDistance() {
      const hybridCarData = this.getHybridCarTrips();
      if (hybridCarData.length === 0) return 0;
      return hybridCarData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getHybridCarCo2() {
      const hybridCarData = this.getHybridCarTrips();
      if (hybridCarData.length === 0) return 0;
      return hybridCarData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },
    checkAllTheHybridCarHaveOnlyOnePerson() {
      const hybridCarData = this.getHybridCarTrips();
      if (hybridCarData.length === 0) return false;
      return hybridCarData.every(trip => trip.numberOfPeople === 1);
    },
    getHybridCarCo2Details() {
      if (this.checkAllTheHybridCarHaveOnlyOnePerson()) {
        return null;
      }
      const hybridCarData = this.getHybridCarTrips();
      if (hybridCarData.length === 0) return 0;
      // return an array of distance, co2 and numberOfPeople
      return hybridCarData.map(trip => ({
          distance: trip.distance,
          co2: trip.meta.emissions.co2,
          numberOfPeople: trip.numberOfPeople,
        }));
    },

    getAirplaneTrips() {
      if (!this.timeline?.currentPeriod) return 0;
      return this.timeline[this.timeline.currentPeriod].trips.filter(
        trip => trip.transitMode === 'airplane',
      );
    },
    getAirplaneDistance() {
      const airplaneData = this.getAirplaneTrips();
      if (airplaneData.length === 0) return 0;
      return airplaneData.reduce((acc, trip) => acc + trip.distance, 0);
    },
    getAirplaneCo2() {
      const airplaneData = this.getAirplaneTrips();
      if (airplaneData.length === 0) return 0;
      return airplaneData.reduce((acc, trip) => acc + trip.meta.emissions.co2, 0);
    },
    handleAnim(anim) {
      this.anim = anim;
    },
  },
};
</script>

<!-- .text-bubble {
  top: 17px !important;
  position: relative;
} -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.loading-anim {
  padding-top: 55px !important;
}

.earth-triangle {
  margin-left: 3%;
}

li span {
  margin-left: -0.3rem;
}

ul {
  list-style-position: inside;
}

li {
  list-style-type: none !important;
}

li:before {
      content: '\2022 \00a0 \00a0 \00a0';
}
</style>
