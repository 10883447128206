<template>
  <cc-view :id="id">
    <div class="d-flex flex-column justify-content-start h-100">
      <cc-notification-reminder v-if="hasNotificationReminder" />

      <div v-if="false">
        os:{{ appState.os }}
        <br>
        osVersion: {{ appState.osVersion }}
        <br>
        sdkVersion: {{ appState.sdkVersion }}
        <br>
        notificationPermission: {{ appState.notificationPermission }}
      </div>
      <div
        v-for="message in sortedMessages"
        :key="message.id"
        >
        <cc-io-observer
          :id="message.id"
          :item="message"
          @io-intersecting-callback="$store.dispatch('social/markMessage', $event)"
          @io-callback="scrollOptimizeHandler"
          >
          <cc-card
            class="mb-4"
            variant="notification"
            :pressable="!!routeName(message)"
            @click.native="onMessageClick(message)"
            >
            <template #body>
              <cc-message-list-item
                :icon="getLocalizedMessage(message).imageUrl"
                :date="dateFormatting($moment(message.createdAt))"
                :text="getLocalizedMessage(message).text"
                :has-arrow="!!routeName(message)"
                :was-read="message.wasRead"
                />
            </template>
          </cc-card>
        </cc-io-observer>
      </div>

      <cc-charts-loading
        v-if="isLoading"
        class="py-6 bg-blue-darkest position-absolute top-left-0 h-100"
        />

      <!-- Empty state -->
      <div
        v-if="!isLoading && isEmpty(sortedMessages)"
        class="d-flex flex-column flex-grow-1 justify-content-center align-items-center"
        >
        <cc-svg-base svg="icons/icon-bell-empty-state" />

        <cc-text
          color="muted"
          align="center"
          class="pt-4 w-70"
          style="padding-bottom: 128px"
          >
          {{ $t('messagesEmptyState') }}
        </cc-text>
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { isEmpty } from 'lodash';

import ccIoObserver from '@/components/constructs/IoObserver.vue';
import ccView from '@/components/constructs/View.vue';
import ccMessageListItem from '@/components/constructs/MessageListItem.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccNotificationReminder from '@/components/NotificationReminder.vue';
import { parseHrefInfo } from '../mixins/utils';

export default {
  components: {
    ccIoObserver,
    ccView,
    ccMessageListItem,
    ccCard,
    ccChartsLoading,
    ccSvgBase,
    ccText,
    ccNotificationReminder,
  },
  data() {
    return {
      id: 'messages',
      isLoading: true,
      messages: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      messagesState: state => state.social.messages,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
      hasAchievements: 'achievements/hasAchievements',
      hasNotificationReminder: 'common/hasNotificationReminder',
      getUnreadMessages: 'social/getUnreadMessages',
      dateFormatting: 'common/dateFormatting',
    }),
    flatAchievements() {
      const { processedAchievements } = this.achievementsState;
      const flatList = [];

      Object.keys(processedAchievements).forEach((el) => {
        processedAchievements[el].forEach(item => flatList.push(item));
      });

      return flatList;
    },
    sortedMessages() {
      const sortedMessages = this.messages?.length ? this.messages : this.messagesState;
      return sortedMessages
        .slice(0)
        .sort((a, b) => this.$moment(b.createdAt) - this.$moment(a.createdAt));
    },
  },
  async created() {
    this.messages = await this.$store.dispatch('social/getMessages');
    this.isLoading = false;

    if (!this.hasAchievements) {
      await this.$store.dispatch('achievements/getPlayerAchievements');
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    isEmpty,
    routeName(message) {
      /**
       * TODO: Check the route name and make necessary calls
       * for example if the route name is 'achievements' then call the getPlayerAchievements action?
       * await this.$store.dispatch('achievements/getPlayerAchievements');
       */
      return this.getMessageHrefPayload(message)?.route?.name;
    },
    routeParams(message) {
      return this.getMessageHrefPayload(message)?.route?.params;
    },
    routeId(message) {
      return this.getMessageHrefPayload(message)?.route?.id;
    },
    getMessageHrefPayload(message) {
      const { href } = message?.meta || {};
      if (!href) return {};
      const {
        name, id, protocal, params,
      } = parseHrefInfo(href);
      const isInternal = protocal === 'internal';

      const route = {
        name,
        id,
        params,
        isInternal,
      };

      return { route };
    },
    getLocalizedMessage(message) {
      const { getLocalizedResource } = this;
      const { contentType } = message;

      if (contentType === 'text/plain') {
        return message.body;
      }

      if (contentType === 'application/json' || contentType === 'application/vnd.ileti+json') {
        const resources = JSON.parse(message.body);
        return getLocalizedResource(resources);
      }

      return '';
    },
    scrollOptimizeHandler(entry) {
      const newEntry = entry;
      if (newEntry.isIntersecting) newEntry.target.style.opacity = 1;
      else newEntry.target.style.opacity = 0;
    },
    onMessageClick(message) {
      const params = this.routeParams(message);
      const challengeId = this.routeId(message);
      this.$router
        .push({
          name: this.routeName(message),
          params: { ...(challengeId ? { challengeId } : {}) },
          query: params,
        })
        .catch((e) => {
          this.$log.error('Error while navigating to the route', e);
        });
    },
  },
};
</script>

<style></style>
