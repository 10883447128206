import { mapState } from 'vuex';

export default {
  data() {
    return {
      timeout: 2000,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      shop: state => state.shop,
    }),
  },
  created() {
    const { navigation, permission } = this.kibanaEvents;


    /*
     * Google Analytics only comment:
     * Because SDK informs the web regarding permission quite often
     * we must compare old and new value, if different only then emit an event.
     * Otherwise we pollute GA. This acts as per session user permission state.
     */

    /**
     * Kibana only comment:
     * Native SDK sends permission information to the web before the web receives the access token
     * Since this is a user specific event Kibana event dispatch with an access token
     * We use a timeout to wait for the token to arrive within timeout duration hopefully
     */

    this.$store.watch(state => state.common.appState.locationPermission, (val, oldVal) => {
      if (val !== oldVal) {
        setTimeout(() => {
          this.$store.dispatch('common/insertEvent', {
            category: permission.category,
            action: permission.location.action,
            type: val,
          });

          this.$log.info('Kibana Event:', permission.location.action, val);
        }, this.timeout);
      }
    });


    this.$store.watch(state => state.common.appState.gps, (val, oldVal) => {
      if (val !== oldVal) {
        setTimeout(() => {
          this.$store.dispatch('common/insertEvent', {
            category: permission.category,
            action: permission.gps.action,
            type: val,
          });

          this.$log.info('Kibana Event:', permission.gps.action, val);
        }, this.timeout);
      }
    });


    this.$store.watch(state => state.common.appState.motionPermission, (val, oldVal) => {
      if (val !== oldVal) {
        setTimeout(() => {
          this.$store.dispatch('common/insertEvent', {
            category: permission.category,
            action: permission.motion.action,
            type: val,
          });

          this.$log.info('Kibana Event:', permission.motion.action, val);
        }, this.timeout);
      }
    });


    this.$store.watch(state => state.common.appState.notificationPermission, (val, oldVal) => {
      if (val !== oldVal) {
        setTimeout(() => {
          this.$store.dispatch('common/insertEvent', {
            category: permission.category,
            action: permission.notification.action,
            type: val,
          });

          this.$log.info('Kibana Event:', permission.notification.action, val);
        }, this.timeout);
      }
    });


    this.$store.watch(state => state.common.appState.contactsPermission, (val, oldVal) => {
      if (val !== oldVal) {
        setTimeout(() => {
          this.$store.dispatch('common/insertEvent', {
            category: permission.category,
            action: permission.contacts.action,
            type: val,
          });

          this.$log.info('Kibana Event:', permission.contacts.action, val);
        }, this.timeout);
      }
    });


    this.$store.watch(state => state.common.appState.viewWillBeShown, (val) => {
      if (val) {
        setTimeout(() => {
          this.$store.dispatch('common/insertEvent', {
            category: navigation.category,
            action: navigation.appOpen.action,
            type: this.appState.appId,
          });
          this.$log.info('Kibana Event:', navigation.appOpen.action, val);
        }, this.timeout);
      }
    });
  },
};
