<template>
  <div>
    <cc-section-header
      v-if="hasActiveChallenges"
      :title="$t('availableChallenges')"
      align="start"
      />


    <cc-card
      v-for="(challenge, i) in activeChallenges"
      :key="i"
      class="mb-4"
      variant="challenge"
      :pressable="true"
      @click.native="$router.push({ name: 'challenge', params: { challengeId: challenge.id}})"
      >
      <template #body>
        <div>
          <div class="d-flex justify-content-between align-items-start">
            <cc-image
              class="w-20"
              :src="getChallengeProperty(challenge.achievement, 'imageUrl')"
              />
            <cc-button
              variant="small"
              :block="false"
              text="View"
              style="min-width: 100px;"
              />
          </div>

          <div class="pt-4">
            <cc-heading variant="h4">
              {{ getChallengeProperty(challenge.achievement, 'title') }}
            </cc-heading>

            <cc-text
              variant="body-sm"
              color="blue-lightest"
              class="text-ellipsis"
              >
              {{ getChallengeProperty(challenge.achievement, 'description') }}
            </cc-text>
            <cc-text
              class="mt-3 px-2 bg-blue-light d-table rounded-6"
              variant="body-xs-bold"
              >
              {{ $moment.duration(challenge.durationInMillis).humanize() }}
            </cc-text>
          </div>
        </div>
      </template>
    </cc-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccText from '@/components/primitives/Text.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccCard from '@/components/constructs/Card.vue';

export default {
  components: {
    ccSectionHeader,
    ccText,
    ccImage,
    ccCard,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'join-challenges',
    };
  },
  computed: {
    ...mapState({
      companyState: state => state.company,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
      activePlayerChallenges: 'achievements/activePlayerChallenges',
      hasPlayerChallenges: 'achievements/hasPlayerChallenges',
      activeChallenges: 'achievements/activeChallenges',
      hasActiveChallenges: 'achievements/hasActiveChallenges',
      isCompanyUser: 'user/isCompanyUser',
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getChallengeProperty(achievement, property) {
      const { getLocalizedResource } = this;
      const response = getLocalizedResource(achievement.resources, achievement.defaultLang)[
        property
      ];

      return response || '–';
    },
  },
};
</script>
