<template>
  <div
    :class="styles.background"
    class="rounded-16 mx-3 p-3 shadow"
    :style="styles.border"
    @click="$router.push(getNotificationHref(payload)).catch(() => {})"
    >
    <div class="d-flex align-items-center">
      <div
        style="flex: 0 0 50px;"
        class="mr-4"
        >
        <cc-image
          class="border-radius-full align-self-start"
          style="width: 50px; height: 50px;"
          :src="payload.meta.imageUrl"
          :has-placeholder="true"
          placeholder-class="bg-blue-light"
          />
      </div>

      <div>
        <cc-text
          v-truncate:35
          variant="body-sm-bold"
          :color="styles.text"
          >
          {{ payload.notification.title }}
        </cc-text>
        <cc-text
          v-truncate:70
          variant="body-sm"
          :color="styles.text"
          >
          {{ payload.notification.body }}
        </cc-text>
        <!-- <cc-text
          variant="body-sm"
          color="white"
          >
          {{ payload.meta.messageId }}
        </cc-text> -->
      </div>
    </div>
  </div>
</template>

<script>
import ccText from '@/components/primitives/Text.vue';
import ccImage from '@/components/primitives/Image.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ccText,
    ccImage,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({
        background: 'bg-blue-darkest',
        text: 'white',
        border: 'border: #4b97fe7d 1px solid;',
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getNotificationHref: 'social/getNotificationHref',
    }),
  },
  mounted() {
    this.$log.info('Mounted notification', this.payload.meta.messageId);
  },
  methods: {},
};
</script>

<style>
</style>
