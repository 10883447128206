import axiosClient from '@/services/axiosClient';
import { retry } from '@/mixins/utils';

const version = '/v1';
const resource = `${version}`;
const resourceJuoco = `${resource}/juoco`;
const resourceWallet = `${resource}/wallet`;
const resourceCompany = `${resource}/company`;

export default {
  async getPlayer() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resourceJuoco}/getPlayer`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getProducts() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resourceJuoco}/getAvailableOffersForPurchase`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getCompaniesWithOffers() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resourceCompany}/getWithActiveOffers`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getCompanies() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resourceCompany}`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getTransactions(walletId) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resourceWallet}/getTransactions`))
        .then((response) => {
          resolve(response?.[walletId] || response);
        }).catch(error => reject(error));
    });
  },

  async buyOffer(offerId, payload) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resourceJuoco}/buyOffer/${offerId}`, payload)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  // async generateConsumeLink(productId) {
  //   return new Promise((resolve, reject) => {
  //     const errorUrl = 'https://test.error';
  //     const successUrl = 'https://test.success';
  //     axiosClient.get(`/generateConsumeLink?errorUrl=${errorUrl}&successUrl=${successUrl}&virtualGoodId=${productId}`)
  //       .then((response) => {
  //         resolve(response);
  //       }).catch(error => reject(error));
  //   });
  // },

  // async consumeGood(productId, userId, errorUrl, successUrl) {
  //   return new Promise((resolve, reject) => {
  //     axiosClient.get(`/public/consumeVirtualGood?errorUrl=${errorUrl}&successUrl=${successUrl}&virtualGoodId=${productId}&userId=${userId}`)
  //       .then((response) => {
  //         resolve(response);
  //       }).catch(error => reject(error));
  //   });
  // },
};
