<template>
  <div>
    <cc-text
      variant="body-bold"
      color="muted"
      class="pb-6"
      >
      {{ $t('climateChangeCO2Emission',
            { totalCo2: `${formatCo2(totalCo2)} ${co2Prefix(totalCo2)}` }) }}
    </cc-text>

    <div class="d-flex flex-column">
      <div
        v-for="(item, i) in compensations"
        :key="i"
        class="pb-6 pr-3"
        style="flex: 1 1 50%;"
        >
        <div class="d-flex align-items-end pb-3">
          <cc-svg-base :svg="item.icon" />

          <cc-heading
            variant="h2"
            class="pl-2 line-height-reset"
            >
            {{ item.value }}
          </cc-heading>

          <cc-text
            variant="body-sm-bold"
            color="muted"
            class="pl-2"
            >
            {{ item.unit }}
          </cc-text>
        </div>

        <cc-text color="muted">
          {{ item.description }}
        </cc-text>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { co2Prefix, formatCo2 } from '@/mixins/utils';

import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccHeading,
    ccText,
    ccSvgBase,
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      chartData: state => state.trips.chartData,
    }),
    ...mapGetters({
      totalCo2: 'trips/totalCo2',
    }),
    compensations() {
      return [
        {
          icon: 'icons/icon-tree-line',
          value: this.amountOfTreesCompensatingEmission(this.totalCo2).toFixed(2),
          unit: this.$t('climateChangeTrees'),
          description: this.$t('climateChangeTreesDescription'),
        },
        {
          icon: 'icons/icon-landpiece',
          value: this.squareMeterOfForest(this.totalCo2).toFixed(2),
          unit: 'm²',
          description: this.$t('climateChangeForest'),
        },
        {
          icon: 'icons/icon-energy',
          value: this.amountOfKWhCompensatingEmission.toFixed(0),
          unit: 'kWh',
          description: this.$t('electricCompensationDescription', {
            co2Delta: `${
              this.amountOfCo2SavedIfAllElectric >= 1000
                ? (this.amountOfCo2SavedIfAllElectric / 1000).toFixed(1)
                : this.amountOfCo2SavedIfAllElectric
            }
              ${co2Prefix(this.amountOfCo2SavedIfAllElectric)}`,
          }),
        },
      ];
    },
    filter() {
      return this.appState.filter;
    },
    amountOfKWhCompensatingEmission() {
      if (this.timeline?.[this.timeline.currentPeriod]?.trips !== null) {
        return this.chartData.summary.totalKwhIfElectric;
      }
      return 0;
    },
    amountOfCo2SavedIfAllElectric() {
      if (this.timeline?.[this.timeline.currentPeriod]?.trips !== null) {
        const result = this.chartData.summary.totalCo2 - this.chartData.summary.totalCo2IfElectric;
        return result >= 1000 ? result : result.toFixed(0);
      }
      return 0;
    },
  },
  methods: {
    co2Prefix,
    formatCo2,
    getMultiplier(filter) {
      switch (filter) {
        case 'today':
          return 365;
        case 'week':
          return 56;
        case 'month':
          return 12;
        case 'year':
          return 1;
        default:
          return 1;
      }
    },
    amountOfTreesCompensatingEmission(co2) {
      const multiplier = this.getMultiplier(this.appState.filter);
      const x = 6000;
      const totalCo2InKg = co2 / 1000;

      return (1 / x) * (totalCo2InKg * multiplier);
    },
    squareMeterOfForest(co2) {
      const multiplier = this.getMultiplier(this.appState.filter);
      const x = 267.2;
      const totalCo2InKg = co2 / 1000;

      return (totalCo2InKg * multiplier) / x;
    },
  },
};
</script>
