import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    handleVisibility() {
      if (document.visibilityState === 'visible') {
        this.setAppState(['isDocumentVisible', true]);
        this.$log.info('Handling visibility', document.visibilityState, this.appState.isDocumentVisible);
      } else {
        this.setAppState(['isDocumentVisible', false]);
        this.$log.info('Handling visibility', document.visibilityState, this.appState.isDocumentVisible);
      }
    },
  },
  created() {
    /**
     * Uses Page Visibility API to determine if app is visible or not.
     * The result is then used (currently only) on when posting Kibana events.
     *
     * Problem is that,  a user interaction on the page generates Kibana events and
     * while the events are being posted to the backend, if user puts the app to the background
     * the webview operations are mostly frozen. So the requests that are in progress are bound
     * to timeout.
     *
     * By listening Page Visibility API result, we set a flag `isDocumentVisible` in app state
     * then read this flag to break the while loop that is responsible for processing and posting of
     * Kibana events. (Look for `insertEvent` action in Common module of Vuex Store)
     *
     * This is mostly a theory at the time of writing this and we will observe the behaviour on prod
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
     */
    document.addEventListener('visibilitychange', this.handleVisibility);
  },
};
