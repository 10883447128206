<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="$t('cantonLeaderboardTitle')"
    :button-variant="getButtonProps.variant"
    :button-label="getButtonProps.label"
    :padded="false"
    :pressable="true"
    class="pb-4"
    @click.native="clickHandler"
    >
    <template #body>
      <cc-wrapper
        v-if="!getCantonPreference"
        direction="row"
        align="start"
        >
        <div class="pr-5">
          <cc-svg-base
            svg="canton-leaderboard-widget"
            />
        </div>

        <cc-text color="muted">
          {{ $t('cantonLeaderboardWidgetDescription') }}
        </cc-text>
      </cc-wrapper>

      <div
        v-else-if="!leaderboardObject"
        class="d-flex justify-content-center align-items-center"
        >
        <ccLottie
          id="activityIndicator"
          class="w-25"
          :options="lottieOptions"
          wrapper-class="p-5"
          />
      </div>

      <cc-wrapper
        v-else-if="allDidNotTravel"
        direction="row"
        align="center"
        >
        <div class="pr-5">
          <cc-svg-base svg="illustrations/hero-groups" />
        </div>

        <cc-text color="muted">
          {{ allDidNotTravelText }}
        </cc-text>
      </cc-wrapper>

      <div v-else>
        <cc-leaderboard-list-item
          v-for="(cantonObject, index) in leaderboardObject"
          :key="index"
          type="canton"
          :rank="cantonObject.curPosition"
          :prev-rank="cantonObject.prevPosition"
          :icon="`canton-icons/${cantonObject.name}`"
          :self-rank="usersCantonIndex"
          :name="cantonObject.name"
          :score="getEmissionText(cantonObject.score, true)"
          :widget="true"
          />
      </div>
    </template>
  </cc-card>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { toOrdinalNumber } from '@/mixins/utils';
import { cantons } from '@/mixins/cantons';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccLottie from '@/components/Lottie.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccText,
    ccSvgBase,
    ccLottie,
    ccLeaderboardListItem,
  },
  data() {
    return {
      id: 'canton-leaderboard-card',
      lottieOptions: {
        animationData: LoadingLeaderboard,
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      leaderboardState: state => state.social.leaderboardState,
      user: state => state.user.user,
    }),
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
      getCantonPreference: 'user/getCantonPreference',
      getEmissionText: 'common/getEmissionText',
    }),
    allDidNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelYetLeaderboards');
      }

      return this.$t('didNotTravelLeaderboards');
    },
    allDidNotTravel() {
      const didNotTravelLeaderboard = this.leaderboardState?.cities?.currentData?.didNotTravel;
      const didTravelLeaderboard = this.leaderboardState?.cities?.currentData?.didTravel;
      if (
        didNotTravelLeaderboard?.length > 0
        && (!didTravelLeaderboard || didTravelLeaderboard?.length === 0)
      ) {
        return true;
      }
      return false;
    },
    // TODO: Double check button props
    getButtonProps() {
      const { userIsVerified, getCantonPreference } = this;

      if (!userIsVerified) {
        return {
          label: this.$t('txtVerify'),
          variant: 'small',
        };
      }

      if (!getCantonPreference) {
        return {
          label: this.$t('txtJoin'),
          variant: 'small',
        };
      }

      return {
        label: this.$t('txtDetails'),
        variant: 'small-muted',
      };
    },
    queryDate() {
      return this.appState.queryDate;
    },
    usersCantonIndex() {
      let result = null;
      const userCanton = this.user && this.user.city;
      this.leaderboardObject.forEach((cantonRank, index) => {
        if (cantonRank.name === cantons[userCanton].name) {
          result = index + 1;
        }
      });
      return result;
    },
    leaderboardObject() {
      if (this.leaderboardState.cities) {
        if (this.leaderboardState.cities[this.appState.leaderboardType]) {
          return this.leaderboardState.cities[this.appState.leaderboardType].slice(0, 3);
        }
      }

      return null;
    },
  },
  watch: {
    async queryDate() {
      this.$store.dispatch('social/getCantonLeaderboard');
    },
  },
  async mounted() {
    const refreshTime = 900000;

    if (
      !this.leaderboardState.cities
      || Date.now() - refreshTime > this.leaderboardState.cities.timestamp
    ) {
      await this.$store
        .dispatch('social/getCantonLeaderboard')
        .catch(() => this.$log.warn('getCantonLeaderboard'));
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    toOrdinalNumberWrapper(value) {
      return toOrdinalNumber(value, this.appState.userLang);
    },
    clickHandler() {
      if (this.userIsVerified) {
        if (this.getCantonPreference) {
          this.$router.push({ name: 'canton-leaderboard' }).catch(() => {});
        } else {
          this.$router.push({ name: 'canton-selection' }).catch(() => {});
        }
      } else {
        this.$router.push({ name: 'join-the-challenge' }).catch(() => {});
      }
    },
  },
};
</script>
