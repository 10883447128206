<template>
  <cc-navigation-bar
    :id="id"
    :title="navigationBarTitle"
    :has-nav-shadow="hasNavShadow($route)"
    >
    <template #left>
      <cc-button
        v-if="$route.meta.hasAccountIcon"
        key="settings"
        class="position-absolute"
        data-ruid="global__button-settings"
        variant="icon"
        :block="false"
        icon="icons/icon-account"
        @click="$router.push({ name: 'settings' }).catch(() => {})"
        />
      <cc-button
        v-else-if="$route.meta.hasBackIcon"
        key="back"
        class="position-absolute"
        variant="icon"
        :block="false"
        icon="icons/icon-back"
        data-ruid="global__button-back"
        @click="
          $router
            .push({
              name: getBackRoute(
                $route,
                appState.routingFrom.name,
                isUserAuthenticated,
                historyState,
              ),
            })
            .catch(() => {})
        "
        />
    </template>

    <template #middle>
      <!-- <code class="font-size-12">
        {{ appState.sessionId }}
      </code> -->
      <!-- Tabbar toggler to help on debugging -->
      <div
        v-if="appState.hasTabBarToggler"
        class="text-center"
        style="z-index: 1000"
        >
        <button
          class="bg-primary"
          @click="$store.dispatch('common/showTabBar', { route: $route })"
          >
          showTabBar
        </button>
        <button
          class="bg-black text-white"
          style="z-index: 1000"
          @click="$store.dispatch('common/hideTabBar', { route: $route })"
          >
          hideTabBar
        </button>
      </div>
    </template>

    <template #right>
      <span
        v-if="hasMessagesButton"
        key="messages"
        class="position-relative"
        :class="[hasCloseButton ? 'mr-4' : null, getUnreadMessages.length ? 'bell-shake' : null]"
        >
        <span
          v-if="getUnreadMessages.length"
          class="bg-danger position-absolute rounded bell-active"
          style="width: 10px; height: 10px; right: -2px"
          />
        <cc-button
          variant="icon"
          class=""
          data-ruid="global__button__messages"
          icon="icons/icon-bell"
          :block="false"
          @click="$router.push({ name: 'messages' })"
          />
      </span>
      <cc-button
        v-if="hasCloseButton"
        key="close"
        variant="icon"
        class=""
        data-ruid="global__button__close"
        icon-color="svg-fill-white"
        icon="icons/icon-close"
        :block="false"
        @click.native="callSdk(`action=${actions.CLOSE}`)"
        />
      <cc-button
        v-else-if="hasGroupSettingsButton"
        key="settings"
        variant="icon"
        class=""
        data-ruid="global__button__group-settings"
        icon-color="svg-fill-white"
        icon="icons/icon-settings"
        :block="false"
        @click="$router.push({ name: 'group-settings', params: { groupInfo } })"
        />
      <cc-button
        v-if="hasPublicGoalButton"
        key="info"
        variant="icon"
        :block="false"
        icon="icons/icon-info"
        @click="
          $router
            .push({
              name: 'public-goal-welcome',
              params: {
                backTo: 'public-goal-progress',
              },
            })
            .catch(() => {})
        "
        />
    </template>
  </cc-navigation-bar>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import anime from 'animejs/lib/anime.min';
import { getBackRoute } from '@/mixins/utils';

import ccNavigationBar from '@/components/constructs/NavigationBar.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccNavigationBar,
    ccButton,
  },
  data() {
    return {
      id: 'app__navigation-bar',
    };
  },
  /**
   *
   * NOTE: Some of the configuration in the computeds here are being moved to route configs
   *
   */
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
      historyState: state => state.common.historyState,
      userState: state => state.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      getUserRoleByGroupId: 'social/getUserRoleByGroupId',
      isUserAuthenticated: 'user/isUserAuthenticated',
      getUnreadMessages: 'social/getUnreadMessages',
    }),
    navigationBarTitle() {
      if (typeof this.$route.meta.title === 'object') {
        return this.$route.meta.title.title;
      }
      return this.$t(this.$route.meta.title);
    },
    hasMessagesButton() {
      const { name } = this.$route;
      const routes = [
        'footprint',
        'challenges',
        'achievements',
        'leaderboards',
        'shop',
        'wallet',
        'points',
      ];

      if (routes.includes(name)) {
        return true;
      }
      return false;
    },
    hasCloseButton() {
      const { name } = this.$route;
      const { routingFrom, appId } = this.appState;
      const { isLoggedIn } = this.userState;

      const routes = [
        // 'home',
        'terms-overview',
        'start',
        'onboarding',
        'footprint',
        'leaderboards',
        'shop',
        'wallet',
        'points',
        'achievements',
        'challenges',
      ];

      const nativeApps = ['nlCC', 'chCC', 'chCC-stg', 'chCC-dev'];

      if (!nativeApps.includes(appId)) {
        if (routingFrom.name === 'settings' && !isLoggedIn) {
          return true;
        }

        if (name === 'onboarding' && routingFrom.name === 'settings') {
          return false;
        }

        if (name === 'start' && routingFrom.name === 'settings') {
          return false;
        }

        if (name === 'onboarding' && routingFrom.name === 'phone-verification') {
          return true;
        }

        if (routes.includes(name)) {
          return true;
        }
      }
      return false;
    },
    hasPublicGoalButton() {
      const { name } = this.$route;
      return name === 'public-goal-progress';
    },
    hasGroupSettingsButton() {
      const { name } = this.$route;
      const isOwner = this.getUserRoleByGroupId(this.$route.params.id) === 'owner';
      const isAdmin = this.getUserRoleByGroupId(this.$route.params.id) === 'admin';
      const isParticipant = this.getUserRoleByGroupId(this.$route.params.id) === 'participant';

      if (name === 'group' && (isOwner || isAdmin || isParticipant)) {
        return true;
      }

      return false;
    },
    hasBackButton() {
      const { meta } = this.$route;

      // TODO: will it hold?
      if (meta.depth > 0 && meta.hasBackIcon) {
        return true;
      }
      return false;
    },
    groupInfo() {
      return this.leaderboardState?.group?.currentData?.data?.[0]?.group;
    },
  },
  watch: {
    $route(to) {
      this.hasNavShadow(to);
    },
  },
  mounted() {
    this.$store.dispatch('common/setUiElementHeight', {
      id: this.id,
      name: 'navigationBarHeight',
      route: this.$route,
    });
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUiState: 'common/setUiState',
    }),
    getBackRoute,
    toggleSelectorBars() {
      const { selectorBarEl, easing, duration } = this;
      const { routingTo } = this.appState;

      const routes = ['leaderboards', 'shop'];

      // this.$log.info('routingTo', routingTo.name);
      this.$log.info('Will call anime', selectorBarEl.offsetHeight);

      if (routes.includes(routingTo.name)) {
        this.$log.info('in');
        anime({
          targets: selectorBarEl,
          marginTop: 0,
          duration,
          easing,
          update: () => {
            this.$store.dispatch('common/setUiElementHeight', {
              id: this.id,
              name: 'navigationBarHeight',
            });
          },
        });
      } else {
        this.$log.info('out');
        // TODO: Consider hiding element when hidden?
        anime({
          targets: selectorBarEl,
          marginTop: -selectorBarEl.offsetHeight,
          duration,
          easing,
          update: () => {
            this.$store.dispatch('common/setUiElementHeight', {
              id: this.id,
              name: 'navigationBarHeight',
            });
          },
        });
      }
    },
    hasNavShadow(to) {
      const { meta } = to;
      const { isBlocker, appState } = this;

      if (isBlocker || !appState.isOnline) {
        return false;
      }
      if (meta.hasNavShadow) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.bell-active {
  border: 2.5px solid $blue-dark;
}

.bell-shake {
  animation: bell-shake 1000ms ease;
  transform-origin: 50% 0%;
}

@keyframes bell-shake {
  0% {
    transform: translateX(6px);
  }
  15% {
    transform: translateX(-5px) rotate(6deg);
  }
  30% {
    transform: translateX(4px) rotate(-6deg);
  }
  45% {
    transform: translateX(-3px) rotate(3.6deg);
  }
  60% {
    transform: translateX(2px) rotate(-2.4deg);
  }
  75% {
    transform: translateX(-1px) rotate(1.2deg);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
