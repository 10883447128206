<template>
  <cc-card
    v-if="appState.appId === 'blick' || appState.appId === 'bluewin'"
    :id="id"
    :ruid="id"
    :inner-title="$t('hostAppEOLReminderTitle')"
    button-variant="small"
    :pressable="true"
    :button-label="$t('hostAppEOLReminderButton')"
    class="pb-4"
    @click.native="clickHandler"
    >
    <template #body>
      <cc-wrapper
        direction="row"
        justify="start"
        >
        <div
          class="mr-4"
          style="flex: 0 0 60px;"
          >
          <cc-svg-base svg="illustrations/surprised-earth" />
        </div>

        <cc-text variant="body">
          {{ $t('hostAppEOLReminderDescription', { appId }) }}
        </cc-text>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapState } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';

import { capitalizeFirstLetter } from '@/mixins/utils';

export default {
  components: {
    ccCard,
    ccSvgBase,
    ccWrapper,
    ccText,
  },
  data() {
    return {
     id: 'host-app-eol-reminder-card',
     iOSLink: 'https://apps.apple.com/ch/app/swiss-climate-challenge/id1534158906',
     androidLink: 'https://play.google.com/store/apps/details?id=com.swisscom.swissclimatechallenge',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    appId() {
      return capitalizeFirstLetter(this.appState.appId);
    },
  },
  methods: {
    clickHandler() {
      const { androidLink, iOSLink } = this;
      const { os } = this.appState;

      try {
        if (os === 'ios') {
          return this.callSdk(`action=${this.actions.OPEN_EXTERNAL}&url=${encodeURI(iOSLink)}`);
        }

        if (os === 'android') {
          return this.callSdk(`action=${this.actions.OPEN_EXTERNAL}&url=${encodeURI(androidLink)}`);
        }
      } catch (error) {
        this.$log.info('HostAppEOLReminder error:', error);
      }
      return false;
    },
  },
};
</script>

<style>

</style>
