import axios from 'axios';
import axiosClient from '@/services/axiosClient';
import { retry } from '@/mixins/utils';

const version = '/v1';
// const resource = `${version}`;
const resourceLeaderboard = `${version}/leaderboard`;
const resourcePhonebook = `${version}/phonebook`;
const resourceMessage = `${version}/message`;


export default {
  async getLeaderboard(type, week, forceUpdate, groupId) {
    return new Promise((resolve, reject) => {
      const groupIdQueryParam = groupId ? `&groupId=${groupId}` : '';
      const forceUpdateQueryParam = forceUpdate ? '&cache=false' : '';
      retry(() => axiosClient.get(`${resourceLeaderboard}/scoreboard?windowsAgo=${week}&boardType=${type}${groupIdQueryParam}${forceUpdateQueryParam}`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getContacts() {
    return new Promise((resolve, reject) => {
      axiosClient.get(`${resourcePhonebook}/getContacts`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getMessages() {
    return new Promise((resolve, reject) => {
      axiosClient.get(`${resourceMessage}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async markMessage(action, messageId) {
    return new Promise((resolve, reject) => {
      axiosClient.patch(`${resourceMessage}/${action}?messageId=${messageId}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getNewsFeed(url) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        transformRequest: (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.common.Authorization;
        },
      })
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },
};
