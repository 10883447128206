<template>
  <div>
    <div class="w-100 h-100 d-flex flex-row justify-content-between">
      <div>
        <cc-button
          variant="icon"
          icon="icons/icon-info-2"
          @click="openEarthMothInfoModal"
          />
      </div>
      <div>
        <cc-button
          :text="$t('txtDetails')"
          variant="medium-muted"
          @click="$router
            .push({
              name: 'journeys',
            })
            .catch(() => {})"
          />
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center mx-5">
      <div
        class="position-absolute overflow-hidden w-60 mx-auto"
        style="height: 180px;left: 0px;right: 0px;"
        >
        <!-- top bar -->

        <transition name="slide-left">
          <div
            id="doughnut-data"
            :key="appState.queryDate.stringify"
            class="d-flex flex-column justify-content-center align-items-center
            position-absolute w-100 h-100"
            style="left:0;right:0;"
            >
            <div
              class="d-block relative h-100 w-100 py-3"
              >
              <cc-svg-base
                v-if="isHappy"
                class="h-100 w-100"
                svg="illustrations/happy-earth"
                />
              <cc-svg-base
                v-if="!isHappy"
                class="h-100 w-100"
                svg="illustrations/sad-earth"
                />
              <div
                class="bg-blue-lightest"
                style="width: fit-content;
                height: fit-content;
                padding: 2px 6px;
                bottom: 24px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 16px;
                z-index: 99 !important;"
                >
                <cc-text
                  variant="body-md-bold"
                  color="blue-darkest"
                  style="white-space: nowrap;"
                  >
                  {{ totalSavedCo2 }}
                </cc-text>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <doughnut-chart
        :chart-data="datacollection"
        :options="options"
        class="position-relative"
        />
    </div>
    <div class="w-100 h-100 d-flex flex-row justify-content-between">
      <div>
        <cc-button
          :text="totalKm"
          :variant="totalKm === '0 km' ? 'medium-dark-muted' : 'medium-dark'"
          />
      </div>
      <div>
        <cc-button
          :text="totalEmission"
          :variant="totalEmission === '0 g CO₂' ? 'medium-dark-muted' : 'medium-dark'"
          />
      </div>
    </div>
    <div
      class="pt-5 w-100"
      >
      <cc-chart-legend
        justify-content="center"
        style="position: absolute; transform:translateX(50%); left: -50%"
        class="absolute w-100 px-4"
        />
    </div>
    <div class="pt-7">
      <cc-transport-cards
        :is-padded="false"
        :is-earth="true"
        />
    </div>
    <cc-earth-mood-info-modal
      v-if="isEarthMoodInfoModalOpen"
      @close-modal="isEarthMoodInfoModalOpen = false"
      />
  </div>
</template>
<script>
import { mapState } from 'vuex';

// import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccChartLegend from '@/components/ChartLegend.vue';
import ccTransportCards from '@/components/TransportCards.vue';
import ccEarthMoodInfoModal from '@/components/modals/EarthMoodInfoModal.vue';

import ccButton from '@/components/primitives/Button.vue';
import DoughnutChart from '../../charts/DoughnutChart';
import ccSvgBase from '../SvgBase.vue';

export default {
  name: 'EarthMoodChart',
  components: {
    // ccHeading,
    ccText,
    ccButton,
    ccSvgBase,
    ccChartLegend,
    ccTransportCards,
    DoughnutChart,
    ccEarthMoodInfoModal,
  },
  props: {
    isHappy: {
      type: Boolean,
      required: false,
      default: true,
    },
    emissionText: {
      type: String,
      required: false,
      default: '0 g',
    },
  },
  data() {
    return {
      datacollection: {},
      options: {},
      data: [],
      isEarthMoodInfoModalOpen: false,
      // 5825.500106811523 / 25000
      // (5825.500106811523 is co2 and 25000 is distance based on an known data)
    };
  },
  computed: {
    ...mapState({
      timeline: state => state.trips.timeline,
      appState: state => state.common.appState,
      chartData: state => state.trips.chartData,
      badTransport: state => state.trips.badTransport,
      emissionByCarfossilPerMetter: state => state.trips.emissionByCarfossilPerMetter,
    }),
    totalKm() {
      const { appState, distanceText, chartData } = this;
      let result = null;
      if (chartData !== null) {
        result += chartData.summary.totalDistance;
      }
      if (appState.tripsProcessing === false && chartData === null) {
        const queryDate = new Date(this.appState.queryDate);
        if (queryDate.getDate() === new Date().getDate()) {
          return 0;
        }
        return 0;
      }
      return distanceText(result);
    },
    totalEmission() {
      return `${this.emissionText} CO₂`;
    },
    noDataColor() {
      return '#4B97FF';
    },
    totalSavedCo2() {
      return `${this.convertToEmissionText(this.getSavedEmission())} ${this.$t('co2Saved')}`;
    },
  },
  mounted() {
    this.fillData();
    this.$watch('chartData', () => {
      if (this.appState.tripsProcessing === false) {
        this.fillData();
      }
    }, { deep: true });
  },
  methods: {
    convertToEmissionText(emission) {
      if (emission >= 1000) {
        const co2 = (emission / 1000).toFixed(1);
        return `${co2} kg`;
      }
      return `${emission.toFixed(0)} g`;
    },
    calendarDay(queryDate) {
      return this.$moment(queryDate).calendar(null, {
        sameDay: `[${this.$t('Today')}]`,
        nextDay: `[${this.$t('Tomorrow')}]`,
        nextWeek: 'dddd',
        lastDay: `[${this.$t('Yesterday')}]`,
        lastWeek: `${this.$t('LastDDDD')}`,
        sameElse: 'DD/MM/YYYY',
      });
    },
    distanceText(distance) {
      if (!distance) {
        const queryDate = new Date(this.appState.queryDate);
        if (queryDate.getDate() === new Date().getDate()) {
          return '0 km';
        }
        return '0 km';
      }
      if (distance >= 1000) {
        const kilometers = (distance / 1000).toFixed(1);
        return `${kilometers} km`;
      }
      return `${distance} m`;
    },
    getSavedEmission() {
      const goodTransportData = this.timeline?.[this.timeline.currentPeriod]?.trips.filter(
        trip => !this.badTransport
          .includes(`${trip.transitMode}-${trip.meta.emissionType
          .includes('fossil') ? 'fossil' : ''}`),
      ) || [];
      const totalSavedCo2 = goodTransportData
      .reduce((acc, trip) => acc
      + (this.emissionByCarfossilPerMetter * trip.distance) - trip.meta.emissions.co2, 0);
      return totalSavedCo2;
    },
    fillData() {
      this.getSavedEmission();
      const chartDataProc = { ...this.chartData };
      chartDataProc.humanPowered = [chartDataProc.humanPowered.reduce((acc, val) => acc + val, 0)];
      chartDataProc.electric = [chartDataProc.electric.reduce((acc, val) => acc + val, 0)];
      chartDataProc.fossil = [chartDataProc.fossil.reduce((acc, val) => acc + val, 0)];
      chartDataProc.noData = [0];

      this.datacollection = {
        labels: ['Human-powered', 'Electric', 'Fossil', 'noData'],
        datasets: [
          {
            backgroundColor: [
              '#7bff8e',
              '#ffda53',
              '#ff8484',
              this.noDataColor,
            ],
            data: chartDataProc.humanPowered
              .concat(chartDataProc.electric, chartDataProc.fossil, chartDataProc.noData),
            borderWidth: 0,
          },
        ],
        responsive: true,
        distanceText: true,
      };
      this.options = {
        donutPlaceHolder: {
          color: this.noDataColor,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enalbed: false,
        },
        cutoutPercentage: 70,
        rotation: 4.55,
        animation: {
          duration: 700,
        },
      };
    },
    openEarthMothInfoModal() {
      this.isEarthMoodInfoModalOpen = true;
    },
  },
};
</script>
