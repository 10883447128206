<template>
  <cc-view :id="id">
    <cc-card
      class="mb-6"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          v-for="(language, index) in supportedLangs"
          :key="index"
          input-type="radio"
          :checked="appState.userLang === language"
          :label="$t(`${language}Language`)"
          :last="index === 3"
          @click.native="updateLanguagePreference(language)"
          />
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccSettingsListItem,
  },
  data() {
    return {
      id: 'language-settings',
    };
  },
  computed: {
    ...mapState({
      supportedLangs: state => state.common.supportedLangs,
      appState: state => state.common.appState,
      user: state => state.user.user,
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setLanguagePreference: 'user/setLanguagePreference',
    }),
    async updateLanguagePreference(language) {
      await this.$store.dispatch('user/setLanguagePreference', { language });
    },
  },
};
</script>
