<template>
  <div class="overflow-hidden mb-4">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <cc-image
        class="position-absolute w-20"
        :src="imageUrl"
        />
      <cc-image
        style="max-height: inherit;"
        src="illustrations/challenge-header-bg.png"
        placeholder-class="bg-blue-darkest"
        />
    </div>

    <div class="d-flex flex-column w-100 align-items-center">
      <cc-heading
        variant="h4"
        class="pb-1 text-center w-80"
        >
        {{ title }}
      </cc-heading>

      <cc-text
        v-if="duration"
        variant="body-sm-bold"
        color="muted"
        class="text-center pb-1"
        >
        {{ duration }}
      </cc-text>
    </div>

    <div
      v-if="['delayed', 'active', 'upcoming'].includes(status)"
      class="bg-blue-dark shadow rounded-16
      d-flex flex-column justify-content-center align-items-center p-4 mt-5"
      >
      <div class="d-flex flex-column w-100 justify-content-center align-items-center">
        <div v-if="status === 'upcoming'">
          <cc-text
            variant="body-bold"
            align="center"
            >
            {{ $t('challengeYouAreIn') }}
          </cc-text>

          <cc-text
            variant="body-sm"
            color="muted"
            align="center"
            >
            {{ $t('challengeWillStartIn') }}
          </cc-text>

          <cc-flip-counter
            class="mt-3"
            :target="startsDate"
            />
        </div>

        <div
          v-if="status === 'active'"
          class="d-flex flex-column w-100"
          >
          <div class="d-flex justify-content-between w-100">
            <cc-text variant="body-sm-bold">
              {{ $t('challengeProgress') }}
            </cc-text>

            <cc-text variant="body-sm-bold">
              {{ timeLeft }}
            </cc-text>
          </div>

          <cc-progress-bar
            :background-opacity="70"
            width="100%"
            class="mt-2"
            :progress-value="progressValue"
            :saturation-value="saturationValue"
            />

          <cc-text
            v-if="progressMessage"
            class="mt-3"
            variant="body-sm"
            color="muted"
            >
            {{ progressMessage }}
          </cc-text>
        </div>


        <div
          v-if="status === 'delayed'"
          class="d-flex p-2 rounded-8
          align-items-center"
          >
          <cc-svg-base
            svg="illustrations/earth-loading"
            color-class="flex-shrink-0"
            class="mr-4"
            />

          <span>
            <cc-text
              variant="body-bold"
              >
              {{ $t('challengeTimesUp') }}
            </cc-text>

            <cc-text
              variant="body-sm"
              color="muted"
              >
              {{ $t('delayedChallengeDescription') }}
            </cc-text>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ccProgressBar from '@/components/achievements/ProgressBar.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccFlipCounter from '@/components/FlipCounter.vue';

export default {
  components: {
    ccProgressBar,
    ccSvgBase,
    ccFlipCounter,
  },
  props: {
    imageUrl: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    duration: {
      type: String,
      required: false,
      default: null,
    },
    // expiresDate: {
    //   type: String,
    //   required: false,
    //   default: null,
    // },
    startsDate: {
      type: String,
      required: false,
      default: null,
    },
    timeLeft: {
      type: String,
      required: false,
      default: null,
    },
    status: {
      type: String,
      required: false,
      default: null,
    },
    progressMessage: {
      type: String,
      required: false,
      default: null,
    },
    progressValue: {
      type: Number,
      required: false,
      default: 0,
    },
    saturationValue: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
