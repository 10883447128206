<template>
  <cc-view :id="id">
    <cc-wrapper class="h-100">
      <div class="text-center pb-6">
        <div class="py-4">
          <cc-svg-base svg="illustrations/hero-groups" />
        </div>

        <cc-heading class="py-4 px-6">
          {{ $t('groupLeaderboardJoinGroupTitle') }}
        </cc-heading>

        <cc-text class="p-4">
          {{ $t('groupLeaderboardJoinGroupDescription') }}
        </cc-text>
      </div>

      <div class="w-100">
        <cc-button
          data-ruid="button__create-group"
          type="secondary"
          :text="hasGroupLeaderboard ? $t('txtContinue') : $t('groupLeaderboardCreateGroup')"
          @click="primaryActionHandler"
          />
      </div>

      <cc-add-display-name-modal
        v-if="isAddDisplayNameModalDisplayed"
        :data="{ nextCreateGroupModal: true }"
        @after-leave="isCreateGroupModalDisplayed = $event === 'create-group-modal'"
        @close-modal="isAddDisplayNameModalDisplayed = false"
        />

      <cc-create-group-modal
        v-if="isCreateGroupModalDisplayed"
        @close-modal="isCreateGroupModalDisplayed = false"
        />

      <cc-group-deleted-confirmation-modal
        v-if="isGroupDeletedConfirmationModalDisplayed"
        :data="groupDeletedConfirmationModalData"
        @close-modal="
          (isGroupDeletedConfirmationModalDisplayed = false),
          delete $route.params.groupDeletedConfirmationModal
        "
        />

      <cc-group-exit-confirmation-modal
        v-if="isGroupExitConfirmationModalDisplayed"
        @close-modal="
          (isGroupExitConfirmationModalDisplayed = false),
          delete $route.params.groupExitConfirmation
        "
        />
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import clearErrorMessages from '@/mixins/clearErrorMessages';
import trophy from '@/components/lottie/trophy.json';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';

import ccAddDisplayNameModal from '@/components/modals/AddDisplayNameModal.vue';
import ccCreateGroupModal from '@/components/modals/CreateGroupModal.vue';
import ccGroupDeletedConfirmationModal from '@/components/modals/GroupDeletedConfirmationModal.vue';
import ccGroupExitConfirmationModal from '@/components/modals/GroupExitConfirmationModal.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccText,
    ccButton,
    ccSvgBase,
    ccAddDisplayNameModal,
    ccCreateGroupModal,
    ccGroupDeletedConfirmationModal,
    ccGroupExitConfirmationModal,
  },
  mixins: [clearErrorMessages],
  props: {
    createGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'group-leaderboard-empty-state',
      name: null,
      isAddDisplayNameModalDisplayed: false,
      isCreateGroupModalDisplayed: false,
      isGroupDeletedConfirmationModalDisplayed: false,
      groupDeletedConfirmationModalData: null,
      isGroupExitConfirmationModalDisplayed: false,
      lottieOptions: {
        autoplay: true,
        animationData: trophy,
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    ...mapGetters({
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
    }),
  },
  mounted() {
    this.name = this.user.name;

    // Expect modal components to pass params to launch the next modal

    if (this.createGroup) {
      this.primaryActionHandler();
    }

    if (this.$route.params?.groupExitConfirmation) {
      this.isGroupExitConfirmationModalDisplayed = true;
    }

    if (this.$route.params?.groupDeletedConfirmationModal) {
      this.isGroupDeletedConfirmationModalDisplayed = true;

      this.groupDeletedConfirmationModalData = {
        name: this.$route.params?.groupDeletedConfirmationModal?.name,
      };
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async primaryActionHandler() {
      if (!this.user.name) {
        this.isAddDisplayNameModalDisplayed = true;
      } else {
        this.isCreateGroupModalDisplayed = true;
      }
    },
  },
};
</script>
