<template>
  <cc-card
    v-if="isAllowCompanyLeaderboard"
    :id="id"
    :ruid="id"
    :inner-title="$t('companyLeaderBoardCardTitle')"
    :button-variant="getButtonProps.variant"
    :button-label="getButtonProps.label"
    :has-button="hasCompanyLeaderboard"
    :padded="false"
    :pressable="false"
    class="pb-4"
    @click.native="clickHandler"
    >
    <template
      #body
      >
      <div
        v-if="!hasCompanyLeaderboard && isLoading"
        class="d-flex justify-content-center align-items-center"
        >
        <ccLottie
          id="activityIndicator"
          class="w-25"
          :options="lottieOptions"
          wrapper-class="p-5"
          />
      </div>

      <cc-wrapper
        v-else-if="hasCompanyLeaderboard && (companies && companies.length)"
        direction="column"
        align="start"
        >
        <cc-leaderboard-list-item
          v-for="(company, index) in companies"
          :key="index"
          type="widget"
          :rank="company.curPosition"
          :rank-class="getRankClass(company.curPosition)"
          :prev-rank="company.prevPosition"
          :icon="getCupIcon(company.curPosition)"
          :name="company.name"
          :score="getEmissionText(company.score, true)"
          :widget="true"
          />
      </cc-wrapper>

      <cc-wrapper
        v-else-if="allDidNotTravel"
        direction="row"
        align="center"
        class="flex-wrap"
        >
        <cc-wrapper
          direction="row"
          class="width-100"
          >
          <div class="pr-5">
            <cc-svg-base svg="illustrations/hero-groups" />
          </div>
          <div class="width-100">
            <cc-text color="muted">
              {{ allDidNotTravelText }}
            </cc-text>
          </div>
        </cc-wrapper>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccLottie from '@/components/Lottie.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccText,
    ccSvgBase,
    ccLottie,
    ccLeaderboardListItem,
  },
  data() {
    return {
      id: 'company-leaderboard-card',
      companies: null,
      lottieOptions: {
        animationData: LoadingLeaderboard,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasCompanyLeaderboard: 'social/hasCompanyLeaderboard',
      getCompanies: 'social/getCompaniesLeaderboard',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
      getCompanyTagId: 'user/getUserCompanyTagId',
      getAllowCompanyLeaderboardByAlias: 'company/getAllowCompanyLeaderboardByAlias',
    }),
    allDidNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelYetLeaderboards');
      }

      return this.$t('didNotTravelLeaderboards');
    },
    allDidNotTravel() {
      const didNotTravelLeaderboard = this.leaderboardState?.company?.currentData?.didNotTravel;
      const didTravelLeaderboard = this.leaderboardState?.company?.currentData?.didTravel;

      if (
        didNotTravelLeaderboard?.length > 0
        && (!didTravelLeaderboard || didTravelLeaderboard?.length === 0)
      ) {
        return true;
      }

      return false;
    },
    getButtonProps() {
      const { hasCompanyLeaderboard } = this;

      if (hasCompanyLeaderboard) {
        return {
          label: this.$t('txtView'),
          variant: 'small-muted',
        };
      }

      return {
        label: this.$t('txtStart'),
        variant: 'small',
      };
    },
    isAllowCompanyLeaderboard() {
      return this.getAllowCompanyLeaderboardByAlias(this.getCompanyTagId);
    },
  },
  async mounted() {
    const { company } = this.leaderboardState;
    const refreshTime = 900000;
    const shouldDispatch = !company || Date.now() - refreshTime > company.timestamp;

    if (shouldDispatch) {
      await this.$store.dispatch('social/getCompanyLeaderboard');
    }
    this.companies = this.getCompanies().slice(0, 3);
    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    clickHandler() {
      if (!this.hasCompanyLeaderboard) return;
      this.$router.push({ name: 'company-leaderboard' }).catch(() => {});
    },
  },

};
</script>
