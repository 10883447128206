import axiosClient from '@/services/axiosClient';

const version = '/v1';
// const resource = `${version}`;
const resource = `${version}/trip`;


export default {
  async findTrips(from, to, accessToken, cancelToken) {
    return new Promise((resolve, reject) => {
      const paramString = new URLSearchParams({
        from,
        to,
      }).toString();

      axiosClient.get(`${resource}/findByStartTimeBetween?${paramString.toString()}`, {
        cancelToken: cancelToken.token,
      })
        .then((response) => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  async updateTrip(tripId, transitMode, emissionType, numberOfPeople = 1) {
    return new Promise((resolve, reject) => {
      axiosClient.patch(`${resource}/${tripId}/updateTransit`, {
        transitMode,
        emissionType,
        numberOfPeople,
      })
        .then((response) => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  async updateTripPurpose(tripId, purpose) {
    return new Promise((resolve, reject) => {
      axiosClient.patch(`${resource}/${tripId}`, {
        purpose,
      })
        .then((response) => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
};
