<template>
  <cc-modal
    data-ruid="modal__confirm-member-removal"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('groupLeaderboardRemoveMember')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column"
        >
        <cc-text>
          {{ $t('groupLeaderboardRemoveMemberDescription',
                { displayName: member.name, groupName: group.name })
          }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex w-100 px-5 py-5">
        <cc-button
          :is-modal-button="true"
          data-ruid="global__button-remove"
          class="mr-1"
          variant="danger"
          :text="$t('txtRemove')"
          @click="removeMember(close)"
          />
        <cc-button
          :is-modal-button="true"
          class="ml-1"
          variant="secondary"
          data-ruid="global__button-confirm"
          :text="$t('txtCancel')"
          @click="close(() => {
            $emit('after-leave', { group, member });
          });"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccText,
    ccModal,
    ccButton,
  },
  props: {
    data: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      selectedRole: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
    }),
    group() {
      return { ...this.data.group };
    },
    member() {
      return { ...this.data.member };
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async removeMember(closeModal) {
      // TODO: Add error message component to ui and bind here
      try {
        await this.$store.dispatch('social/removeMember', {
          groupId: this.group.id,
          memberId: this.member.id,
        });
        await this.$store.dispatch('social/getGroupLeaderboard', {
          groupId: this.group.id,
          forceUpdate: true,
        });

        closeModal();
      } catch (error) {
        this.$log.info('getGroupLeaderboard', error);
      }
    },
  },
};
</script>
