// eslint-disable-next-line import/prefer-default-export
export const ActionEnum = {
  CLOSE: 'close',
  GO_TO_APP_SETTINGS: 'goToAppSettings',
  GO_TO_POWER_SAVE_SETTINGS: 'goToPowerSaveSettings',
  GO_TO_BATTERY_OPTIMIZATION_SETTINGS: 'goToBatteryOptimizationSettings',
  GO_TO_NOTIFICATIONS_SETTINGS: 'goToNotificationsSettings',
  SIGN_OUT: 'signOut',
  REQUEST_USER_LOCATION: 'requestUserLocation',
  REQUEST_LOCATION_PERMISSION: 'requestLocationPermission',
  REQUEST_MOTION_PERMISSION: 'requestMotionPermission',
  REQUEST_CONTACTS_PERMISSION: 'requestContactsPermission',
  REQUEST_CONTACTS_UPLOAD: 'requestContactsUpload',
  REQUEST_CONTACT_NAMES: 'requestContactNames',
  REQUEST_CONTACTS: 'requestContacts',
  AUTHENTICATION: 'authentication',
  APP_IS_LOADED: 'appIsLoaded',
  SHARE: 'share',
  GO_TO_GPS_SETTINGS: 'goToGpsSettings',
  SET_CELLULAR_TRIP_DATA_UPLOAD: 'setCellularTripDataUpload',
  OPEN_EXTERNAL: 'openExternal',
  OPEN_INTERNAL: 'openInternal',
  SET_BACKGROUND_COLOR: 'setBackgroundColor',
  DESTROY: 'destroy',
  REQUEST_ACCESS_TOKEN: 'requestAccessToken',
  REQUEST_NOTIFICATION_PERMISSION: 'requestNotificationPermission',
  REQUEST_STORE_REVIEW: 'requestStoreReview',
  REQUEST_IGNORE_BATTERY_OPTIMIZATION: 'requestIgnoreBatteryOptimization',
};
