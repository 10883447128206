<template>
  <cc-modal
    data-ruid="modal__motion-permission"
    group-class="bg-blue-dark"
    header-title=""
    :after-close="afterClose"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <cc-lottie
        id="primerModalAnim"
        class="w-100"
        :options="currentAnim"
        />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 w-100"
        >
        <cc-heading
          variant="h5"
          class="pb-2"
          weight="xbold"
          >
          {{ $t(`${getOS}${data.permissionModalType}Title`) }}
        </cc-heading>

        <cc-text variant="body-sm">
          {{ $t(`${getOS}${data.permissionModalType}Description`) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex flex-row flex-wrap-reverse justify-content-around w-100 py-5 px-5">
        <cc-button
          data-ruid="global__button-permission-later"
          :is-modal-button="true"
          variant="transparent"
          :block="false"
          :text="$t('txtAskLater')"
          class="flex-grow-1 font-weight-normal"
          @click="close"
          />

        <cc-button
          data-ruid="global__button-permission-accept"
          :is-modal-button="true"
          variant="secondary"
          :block="false"
          :text="$t(`${getOS}btn${data.permissionModalType}`)"
          class="flex-grow-1"
          @click="allow(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import ccModal from '@/components/modals/Modal.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';

import primerAnimMotion from '@/components/lottie/primerAnimMotion.json';

export default {
  name: 'MotionPermissionModal',
  components: {
    ccButton,
    ccHeading,
    ccText,
    ccModal,
    ccLottie,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
    afterClose: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      currentAnim: {
        animationData: primerAnimMotion,
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      getOS: 'common/getOS',
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    allow(closeModal) {
      const { REQUEST_MOTION_PERMISSION, GO_TO_APP_SETTINGS } = this.actions;

      switch (this.data.permissionModalType) {
        case 'AllowMotion':
          this.callSdk(`action=${REQUEST_MOTION_PERMISSION}`);
          break;
        case 'MotionSettings':
          this.callSdk(`action=${GO_TO_APP_SETTINGS}`);
          break;
        default:
          this.callSdk(`action=${REQUEST_MOTION_PERMISSION}`);
      }

      closeModal();
    },
  },
};
</script>
