<template>
  <div>
    <cc-text
      v-if="activePlayerChallenges.length"
      variant="body-bold"
      color="muted"
      class="pb-2"
      >
      {{ $t('txtActiveChallenges') }}
    </cc-text>

    <cc-card
      v-if="activePlayerChallenges.length"
      :id="`${id}-active`"
      :ruid="`${id}-active`"
      :padded="false"
      :pressable="true"
      variant="list"
      class="pb-5 w-100"
      >
      <template #body>
        <div
          v-for="(challenge, index) in activePlayerChallenges"
          :key="`${index}-challenge-list-item`"
          >
          <cc-challenges-list-item
            :challenge="challenge"
            @click="$router.push({ name: 'challenge', params: { challengeId: challenge.id } })"
            />

          <cc-divider
            v-if="index !== activePlayerChallenges.length - 1"
            :key="index"
            style="flex-basis: 100%"
            />
        </div>
      </template>
    </cc-card>

    <!-- Delayed Challenges  -->
    <cc-text
      v-if="delayedPlayerChallenges.length"
      variant="body-bold"
      color="muted"
      class="pb-2"
      >
      {{ $t('txtDelayedChallenges') }}
    </cc-text>

    <cc-card
      v-if="delayedPlayerChallenges.length"
      :id="`${id}-delayed`"
      :ruid="`${id}-delayed`"
      :padded="false"
      :pressable="true"
      variant="list"
      class="pb-5 w-100"
      >
      <template #body>
        <div
          v-for="(challenge, index) in delayedPlayerChallenges"
          :key="`${index}-challenge-list-item`"
          >
          <cc-challenges-list-item
            :has-progress-bar="false"
            :is-delayed-challenge="true"
            :challenge="challenge"
            @click="$router.push({ name: 'challenge', params: { challengeId: challenge.id } })"
            />

          <cc-divider
            v-if="index !== delayedPlayerChallenges.length - 1"
            :key="index"
            style="flex-basis: 100%"
            />
        </div>
      </template>
    </cc-card>

    <!-- Upcoming Challenges  -->
    <cc-text
      v-if="upcomingPlayerChallenges.length"
      variant="body-bold"
      color="muted"
      class="pb-2"
      >
      {{ $t('txtUpcomingChallenges') }}
    </cc-text>

    <cc-card
      v-if="upcomingPlayerChallenges.length"
      :id="`${id}-upcoming`"
      :ruid="`${id}-upcoming`"
      :padded="false"
      :pressable="true"
      variant="list"
      class="pb-5 w-100"
      >
      <template #body>
        <div
          v-for="(challenge, index) in upcomingPlayerChallenges"
          :key="`${index}-challenge-list-item`"
          >
          <cc-challenges-list-item
            :has-progress-bar="false"
            :is-upcoming-challenge="true"
            :challenge="challenge"
            @click="$router.push({ name: 'challenge', params: { challengeId: challenge.id } })"
            />

          <cc-divider
            v-if="index !== upcomingPlayerChallenges.length - 1"
            :key="index"
            style="flex-basis: 100%"
            />
        </div>
      </template>
    </cc-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccChallengesListItem from '@/components/achievements/ChallengesListItem.vue';

export default {
  components: {
    ccCard,
    ccText,
    ccDivider,
    ccChallengesListItem,
  },
  data() {
    return {
      id: 'challenge-cards',
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      activePlayerChallenges: 'achievements/activePlayerChallenges',
      delayedPlayerChallenges: 'achievements/delayedPlayerChallenges',
      upcomingPlayerChallenges: 'achievements/upcomingPlayerChallenges',
      getLocalizedResource: 'company/getLocalizedResource',
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
  },
};
</script>
