<template>
  <cc-modal
    data-ruid="modal__product-details"
    position="bottom"
    group-wrapper-class="mh-100 pt-6"
    group-class="bg-blue-dark w-100"
    :header-title="headerTitle"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column"
        >
        <cc-editor-renderer
          id="good-detail-modal"
          :contents="[data.detail]"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          variant="secondary"
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('txtClose')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccEditorRenderer from '@/components/constructs/EditorRenderer.vue';

export default {
  components: {
    ccWrapper,
    ccButton,
    ccModal,
    ccEditorRenderer,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'good-detail-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    headerTitle() {
      return this.data?.detail?.label;
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
  },
};
</script>
