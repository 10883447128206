<template>
  <transition-group
    id="transport-cards"
    ref="transportCards"
    name="transport-card-slide-left"
    tag="div"
    class="w-100 overflow-x-scroll flex-horizontal-scroll position-relative transition-height"
    style="left: 0px;height:0px;"
    :style="isEarth ?
      'left: -1rem; width: calc(100% + 2rem) !important;  !important'
      :''"
    @enter="enter"
    @beforeLeave="beforeLeave"
    >
    <div
      v-for="(summary, i) in sortedTransitModeSummaries"
      :key="summary.transitMode + '_' + summary.totalDistance + Date.now()"
      class="single-card position-absolute"
      :class="isPadded ? 'px-5' : null"
      :style="`left: ${122 * i + (isEarth ? 16 : 0)}px;
      ${(isEarth && i === (sortedTransitModeSummaries.length-1))
      ? 'padding-right:1rem !important;':''}`"
      >
      <div
        class="transport-card p-3 text-black"
        :class="backgroundColor(summary.fuelType)"

        :data-ruid="`transport-card__${summary.transitMode}`"
        >
        <cc-svg-base
          :key="summary.transitMode"
          color-class="svg-fill-black svg-fill-opacity-40"
          :svg="`transport-icons/${clearOptionTransitMode(summary.displayType)}`"
          />

        <div
          class="font-weight-xbold font-size-20 opacity-30"
          data-ruid="transport-card__distance"
          >
          {{ distanceText(summary.totalDistance) }}
        </div>

        <div
          v-if="summary.fuelType !== 'HUMAN_POWERED'"
          data-ruid="transport-card__emission"
          class="font-weight-bold font-size-14 opacity-30"
          >
          {{ emissionText(summary.totalCo2) }}
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import { distanceText } from '@/mixins/utils';

import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccSvgBase,
  },
  props: {
    isPadded: {
      type: Boolean,
      required: false,
      default: true,
    },
    isEarth: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      transitModeSummaries: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      chartData: state => state.trips.chartData,
    }),
    sortedTransitModeSummaries() {
      const sortedArray = cloneDeep(this.transitModeSummaries);
      sortedArray.sort((a, b) => {
        const fuelTypeOrder = ['HUMAN_POWERED', 'ELECTRIC', 'FOSSIL'];
        const aFuelTypeIndex = fuelTypeOrder.indexOf(a.fuelType);
        const bFuelTypeIndex = fuelTypeOrder.indexOf(b.fuelType);
        if (aFuelTypeIndex === bFuelTypeIndex) {
          return a.totalDistance - b.totalDistance;
        }
        return aFuelTypeIndex - bFuelTypeIndex;
      });
      for (let i = 0; i < sortedArray.length; i += 1) {
        if (sortedArray[i].transitMode === 'unknown_human-powered') {
          sortedArray.splice(i, 1);
        }
      }
      return sortedArray;
    },
  },
  watch: {
    transitModeSummaries(val) {
      this.$log.info('TransportCards: transitModeSummaries changed', val);
      if (val.length !== 0) {
        this.$refs.transportCards.$el.style.height = '138px';
      }
    },
    appState: {
      handler() {
        if (this.appState.tripsProcessing === false) {
          this.transitModeSummaries = this.chartData.transportModeSummaries;
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.appState.tripsProcessing === false) {
          this.transitModeSummaries = this.chartData.transportModeSummaries;
        }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    distanceText,
    beforeLeave() {
      const transportCards = document.getElementById('transport-cards');
      if (transportCards && !this.transitModeSummaries.length) {
        // this.$log.info('TransportCards: will reset Height');
        transportCards.style.height = '0px';
      }
      this.$emit('beforeLeave');
    },
    enter() {
      const transportCards = document.getElementById('transport-cards');
      const card = document.querySelector('.single-card');

      if (card) {
        // this.$log.info(
        //   'TransportCards > card height',
        //   `${card.offsetHeight}px`,
        // );
        transportCards.style.height = '138px';
      }

      this.$emit('enter');
    },
    emissionText(emission) {
      if (emission === 0) {
        return ' ‏‏‎';
      }
      if (emission >= 1000) {
        const co2 = (emission / 1000).toFixed(1);
        return `${co2} kg CO₂`;
      }
      return `${emission.toFixed(0)} g CO₂`;
    },
    backgroundColor(fuelType) {
      if (fuelType === 'FOSSIL') {
        return 'bg-cc-red';
      }
      if (fuelType === 'ELECTRIC') {
        return 'bg-cc-yellow';
      }
      if (fuelType === 'HUMAN_POWERED') {
        return 'bg-primary';
      }
      return '';
    },
    clearOptionTransitMode(text) {
      return text.split('Medium').join('').split('Compact').join('');
    },
  },
};
</script>
