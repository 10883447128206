<template>
  <cc-wrapper
    direction="row"
    align="start"
    :class="`${noPadding ? '':'py-2'} flex-wrap`"
    >
    <div
      style="flex: 0 0 50px;"
      class="mr-4"
      >
      <cc-image
        v-if="image && false"
        :src="image"
        />
      <div
        v-if="image && !squareImage"
        class="bg-cover bg-center border-radius-full overflow-hidden"
        style="height:50px;width:50px;background-size: cover;background-position: center;"
        :class="!image ? 'bg-primary-dark' : ''"
        :style="image ? `background-image: url(${image})` : ''"
        />
      <div
        v-if="image && squareImage"
        class="bg-cover bg-center rounded-6"
        style="height:64px;width:64px;background-size: cover;background-position: center;"
        :class="!image ? 'bg-primary-dark' : ''"
        :style="image ? `background-image: url(${image})` : ''"
        />
    </div>

    <div
      class="flex-grow-1"
      style="flex-basis: 50%;"
      >
      <cc-text
        v-if="date"
        variant="body-xs"
        color="muted"
        >
        {{ date }}
      </cc-text>

      <cc-text
        variant="body-bold"
        class="flex-grow-1"
        >
        {{ title }}
      </cc-text>

      <cc-text
        v-truncate:[descriptionTruncateAmount]
        variant="body"
        class="flex-grow-1"
        color="muted"
        >
        {{ description }}
      </cc-text>
    </div>

    <div class="m-auto pl-4">
      <cc-svg-base
        svg="icons/icon-arrow-right"
        color-class="svg-fill-cc-green"
        />
    </div>

    <cc-divider
      v-if="borderBottom"
      class="pt-4"
      style="flex-basis: 100%;"
      />
  </cc-wrapper>
</template>

<script>
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccWrapper,
    ccText,
    ccDivider,
    ccImage,
    ccSvgBase,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    descriptionTruncateAmount: {
      type: Number,
      required: false,
      default: 100,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    borderBottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    squareImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // test: '10',
    };
  },

};
</script>
