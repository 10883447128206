<template>
  <cc-card
    v-if="['today', 'week'].includes(appState.filter) && isCurrentWeekOrDay"
    :inner-title="$t('recentTrips')"
    :has-button="hasRecentTrips"
    button-variant="small-muted"
    :pressable="hasRecentTrips"
    class="pb-4"
    @click.native="
      hasRecentTrips &&
        $router
          .push({
            name: 'journeys',
            params: {
              timeframe: 'today',
              queryDate: recentTrips[0]['startTime'],
            },
          })
          .catch(() => {})
    "
    >
    <template #body>
      <div>
        <cc-recent-trips-disclosure v-if="!isFetchingRecentTrips && hasRecentTrips" />

        <cc-loading v-if="isFetchingRecentTrips" />

        <cc-day-timeline
          v-else-if="hasRecentTrips"
          :key="recentTrips[0] && recentTrips[0].startTime"
          :trips="recentTrips"
          :is-journey="false"
          :has-trip-correction-action="false"
          />
        <span
          v-else
          class="d-flex align-items-center"
          >
          <div class="pr-4">
            <cc-svg-base
              class="px-1"
              svg="illustrations/no-recent-trips"
              />
          </div>

          <span class="d-flex flex-column">
            <cc-heading variant="h5">
              {{ isUserCreatedDaysAgo(7) ? $t('noRecentTrips') : $t('awaitingRecentTrips') }}
            </cc-heading>
            <cc-text>
              {{
                isUserCreatedDaysAgo(7) ? $t('noRecentTripsBody') : $t('awaitingRecentTripsBody')
              }}
            </cc-text>
          </span>
        </span>
      </div>
    </template>
  </cc-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isEqual } from 'lodash';
import { generateFromToPeriods } from '@/mixins/utils';

import ccDayTimeline from '@/components/DayTimeline.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccLoading from '@/components/Loading.vue';
import ccRecentTripsDisclosure from '@/components/constructs/RecentTripsDisclosure.vue';

export default {
  components: {
    ccDayTimeline,
    ccText,
    ccHeading,
    ccSvgBase,
    ccLoading,
    ccRecentTripsDisclosure,
  },
  data() {
    return {
      isFetchingRecentTrips: true,
      timeline: {},
      recentTrips: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      isUserCreatedDaysAgo: 'user/isUserCreatedDaysAgo',
    }),
    hasRecentTrips() {
      return !!this.recentTrips.length;
    },
    isCurrentWeekOrDay() {
      const { appState } = this;

      return isEqual(
        generateFromToPeriods(appState.queryDate, appState.filter).currentPeriod,
        generateFromToPeriods(new Date().getTime(), appState.filter).currentPeriod,
      );
    },
  },
  watch: {
    'appState.isDocumentVisible': {
      handler: 'fetchRecentTrips',
      immediate: false,
    },
  },
  async mounted() {
    await this.fetchRecentTrips(true);
  },
  methods: {
    isEqual,
    generateFromToPeriods,
    async fetchRecentTrips(isDocumentVisible) {
      this.recentTrips = await this.$store.dispatch('trips/fetchRecentTrips', isDocumentVisible);
      this.isFetchingRecentTrips = false;
    },
  },
};
</script>
