<template>
  <div class="mia-distance-chart-container barchart-size align-self-center">
    <cc-charts-loading
      v-if="!avgAccumulators"
      class="chart-loading-container my-4"
      />
    <bar-chart
      v-else
      ref="chart"
      :chart-data="datacollection"
      :options="options"
      />
  </div>
</template>

<script>
import { getEmissionFromAvgAccumulators, getLabelFromLastSevenday } from '@/mixins/utils';
import BarChart from '@/charts/StackedBarChart';
import ccChartsLoading from '@/components/ChartsLoading.vue';

export default {
  name: 'EmployeeDistanceByCategoryChart',
  components: {
    BarChart,
    ccChartsLoading,
  },
  props: {
    avgAccumulators: {
      type: Object,
      required: false,
      default: null,
    },
    lastSevenday: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      noDataValue: 100,
      humanPowered: [0, 0, 0, 0, 0, 0, 0],
      electric: [0, 0, 0, 0, 0, 0, 0],
      fossil: [0, 0, 0, 0, 0, 0, 0],
      noData: [2000, 2000, 2000, 2000, 2000, 2000, 2000],
      datacollection: {},
      options: {},
      isFetching: false,
    };
  },
  computed: {
    barThickness() {
      return 15.8;
    },
  },
  watch: {
    avgAccumulators() {
      this.initializeData();
      this.fillData();
    },
  },
  methods: {
    getSumVals(values) {
      return values.reduce((a, b) => {
        const sum = a || 0 + b || 0;
        return sum;
      }, 0);
    },
    initializeData() {
      this.humanPowered = Object.keys(this.avgAccumulators).map((date) => {
        const distanceBy = this.avgAccumulators[date]?.avgAccumulatorAllUsers;
        return this.getSumVals([distanceBy?.walk, distanceBy?.bike]);
      });

      this.electric = Object.keys(this.avgAccumulators).map((date) => {
        const distanceBy = this.avgAccumulators[date]?.avgAccumulatorAllUsers;
        return this.getSumVals([distanceBy?.tram, distanceBy?.train]);
      });

      this.fossil = Object.keys(this.avgAccumulators).map((date) => {
        const distanceBy = this.avgAccumulators[date]?.avgAccumulatorAllUsers;
        return this.getSumVals([distanceBy?.bus, distanceBy?.car, distanceBy?.airplane]);
      });

      this.emission = getEmissionFromAvgAccumulators(this.avgAccumulators, 'avgAccumulatorAllUsers');
      this.noData = this.humanPowered.map(
        (val, index) => (this.getSumVals(
          [this.humanPowered[index], this.electric[index], this.fossil[index]],
          ) === 0
          ? this.noDataValue
          : 0),
      );

      if (this.emission.length < 7) {
        const fillData = 7 - this.emission.length;
        for (let i = 0; i < fillData; i += 1) {
          this.noData.unshift(25);
        }
      }
      this.labels = getLabelFromLastSevenday(this.lastSevenday);

      this.$log.debug('this.labels', this.labels);
      this.$log.debug('this.emission', this.emission);
      this.$log.debug('this.humanPowered', this.humanPowered);
      this.$log.debug('this.electric', this.electric);
      this.$log.debug('this.fossil', this.fossil);
    },
    fillData() {
      this.datacollection = {
        datasets: [
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: 'Human powered',
            backgroundColor: '#7bff8e',
            data: this.humanPowered,
            barThickness: this.barThickness,
          },
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: 'Electric',
            backgroundColor: '#ffda53',
            data: this.electric,
            barThickness: this.barThickness,
          },
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: 'Fossil',
            backgroundColor: '#ff8484',
            data: this.fossil,
            barThickness: this.barThickness,
          },
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: this.$t('txtNoData'),
            backgroundColor: '#004fbe',
            data: this.noData,
            barThickness: this.barThickness,
          },
        ],
        labels: this.labels,
      };

      this.options = {
        animation: {
          duration: 700,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: false,
                fontColor: 'rgba(184, 212, 255, 1)',
                padding: 16,
                fontFamily: 'TheSans',
                fontStyle: 'normal',
                maxTicksLimit: 9,
                maxRotation: 0,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: false,
              ticks: {
                beginAtZero: true,
                fontColor: 'rgba(184, 212, 255, 1)',
                fontFamily: 'TheSans',
                fontStyle: 'normal',
                padding: 10,
                maxTicksLimit: 6,
                callback(value) {
                  return value / 1000;
                },
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('txtKilometers'),
                fontColor: 'rgba(184, 212, 255, 1)',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                fontSize: 8.05,
                xOffset: 35,
                yOffset: 9,
              },
            },
          ],
        },
      };
    },
  },
};
</script>


<style lang="scss">
/*  Barchart  */
.mia-distance-chart-container.barchart-size {
  max-width: 100%;
  position: relative;
  margin: auto;
  min-height: 122px !important;
}

.mia-distance-chart-container #rounded-bar {
  height: 122px !important;
}

.mia-distance-chart-container .chart-loading-container {
  height: 100%;
}
</style>
