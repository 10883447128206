<template>
  <div class="pt-5 px-4">
    <div class="w-100 d-flex flex-row justify-content-between">
      <cc-section-header
        :title="$t('madePossibleBy')"
        align="start"
        heading-align="left"
        />
    </div>
    <div
      style="display: grid; width: 100%; column-gap: 16px;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);"
      >
      <cc-card
        v-for="companyLogo in companyLogos"
        :key="companyLogo"
        :variant="null"
        class="pb-4"
        :has-button="false"
        radius="rounded-8"
        >
        <template #body>
          <div
            style="height: 64px;"
            class="d-flex justify-content-center align-items-center"
            :class="`${paddingBaseOnLogo[companyLogo]}`"
            >
            <ccSvgBase
              class="w-100 h-100"
              :svg="`partner-logo/${companyLogo}`"
              />
          </div>
        </template>
      </cc-card>
    </div>
  </div>
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';

export default {
  name: 'PublicGoalPossibleBy',
  components: {
    ccCard,
    ccSvgBase,
    ccSectionHeader,
  },
  data() {
    return {
      // TODO: missing vcs logo
      companyLogos: ['swisscom', 'die-post', 'sbb',
      'die-mobiliar', 'migros', 'vcs', 'swiss-energy', 'sustainable-ch'],
      paddingBaseOnLogo: {
        swisscom: 'px-5',
        'die-post': 'px-6 py-4',
        sbb: 'px-5',
        'die-mobiliar': 'px-6',
        migros: 'px-7',
        vcs: 'px-8',
        'swiss-energy': 'px-4',
        'sustainable-ch': 'px-5',
      },
    };
  },
};
</script>
