<template>
  <cc-wrapper
    direction="row"
    align="start"
    class="flex-wrap"
    >
    <div
      style="flex: 0 0 50px;"
      class="mr-4"
      >
      <div
        v-if="svg"
        style="height:64px;width:64px"
        :class="!svg ? 'bg-primary-dark' : ''"
        >
        <cc-svg-base
          :svg="svg"
          />
      </div>
    </div>

    <div
      class="flex-grow-1"
      style="flex-basis: 50%;"
      >
      <cc-heading
        variant="h4"
        class="flex-grow-1"
        >
        {{ title }}
      </cc-heading>

      <cc-text
        variant="body-sm"
        color="muted"
        >
        {{ description }}
      </cc-text>
    </div>
  </cc-wrapper>
</template>

<script>
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccHeading from '@/components/primitives/Heading.vue';

export default {
  components: {
    ccWrapper,
    ccText,
    ccSvgBase,
    ccHeading,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    svg: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
