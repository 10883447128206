const visibilityActions = (store) => {
  store.subscribe((mutation) => {
    const mutationName = mutation.payload?.[0];
    const mutationValue = mutation.payload?.[1];
    const { accessToken } = store.state.user;

    if (mutationName === 'isDocumentVisible' && mutationValue === true && accessToken) {
      store.dispatch('trips/getTimeline');
      store.dispatch('user/fetchUserLocation');
    }
  });
};

export default visibilityActions;
