import Vue from 'vue';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/vue';
import { isEmpty } from 'lodash';

import service from '@/services/index';
import kibanaEvents from '@/mixins/kibanaEvents';
import i18n from '@/i18n';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// State object
const initialState = () => ({
  player: null,
  availableProducts: [],
  availableProductCategories: [],
  companiesWithOffers: [],
  offersByCategory: {},
  companies: [],
  receipts: [],
  processedReceipts: {},
  transactions: [],
  processedTransactions: {},
  isLoading: false,
  productCategoryFilter: null,
});

const state = initialState();

// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

// Actions
const actions = {
  getPlayer({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.$log.info('Starting getPlayer');


      commit('setState', ['isLoading', true]);

      service.company
        .getPlayer()
        .then(async (response) => {
          commit('setState', ['player', response]);
          commit('setState', ['isLoading', false]);

          Vue.$log.info('company/getPlayer OK', response);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getPlayer error: ${err}`);
          commit('setState', ['isLoading', false]);
          reject(err);
        });
    });
  },
  async getCompaniesWithOffers({ commit }, sandboxPayload) {
    return new Promise((resolve, reject) => {
      commit('setState', ['isLoading', true]);

      service.company
        .getCompaniesWithOffers()
        .then(async (response) => {
          const companiesWithOffers = sandboxPayload || response;
          // Vue.$log.info('sandboxPayload: response companies', response);
          // Vue.$log.info('sandboxPayload: available companies', sandboxPayload);

          commit('setState', ['companiesWithOffers', companiesWithOffers]);

          const offersByCategory = {};
          const offers = [];

          // Prepare a list of offers
          companiesWithOffers.forEach((company) => {
            company.offers.flat().forEach((offer) => {
              offers.push(offer);
            });
          });

          // Vue.$log.info('offers', offers);


          // Prepare a list of categories
          const availableProductCategories = [...new Set((offers)
            .map(p => p.virtualGood?.metaPublic?.categories).flat().filter(e => e))];

          commit('setState', ['availableProductCategories', availableProductCategories]);


          offers.flat().forEach((offer) => {
            const { categories } = offer.virtualGood.metaPublic;

            // This loop is not necessary as long as we do not support multi category per product
            // just in anticipation, better to have it already perhaps.
            categories.forEach((cat) => {
              if (!offersByCategory[cat]) {
                offersByCategory[cat] = [];
              }

              offersByCategory[cat].push(offer);
            });
          });

          commit('setState', ['offersByCategory', offersByCategory]);
          commit('setState', ['availableProducts', offers]);
          commit('setState', ['isLoading', false]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getCompaniesWithOffers error: ${err}`);

          commit('setState', ['isLoading', false]);

          reject(err);
        });
    });
  },


  async getCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      service.company
        .getCompanies()
        .then(async (response) => {
          commit('setState', ['companies', response]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getCompanies error: ${err}`);

          reject(err);
        });
    });
  },

  getTransactions({ commit, dispatch }, walletId) {
    // Vue.$log.info('Starting getTransactions');

    return new Promise((resolve, reject) => {
      commit('setState', ['isLoading', true]);

      service.company
        .getTransactions(walletId)
        .then(async (response) => {
          Vue.$log.info('getTransactions', response);
          // eslint-disable-next-line no-underscore-dangle

          commit('setState', ['transactions', response.extracts]);

          await dispatch('processTransactions', response.extracts);

          commit('setState', ['isLoading', false]);

          // Vue.$log.info('getTransactions OK', response.extracts);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getTransactions error: ${err}`);
          commit('setState', ['isLoading', false]);

          reject(err);
        });
    });
  },

  processTransactions({ commit, rootGetters }, transactions) {
    // Vue.$log.info('Starting processTransactions', transactions);

    // const start = Date.now();

    const processedTransactions = {};

    commit('setState', ['isLoading', true]);


    const sortedTransactions = transactions
      .slice()
      .filter((transaction) => {
        // We want to filter out achievements that don't grant any coin value
        // Achievements that grants only points are displayed in Achievements timeline
        if (rootGetters['user/isCompanyUser']) {
          return !(!transaction.value && transaction.meta.type === 'achievement');
        }
        return transaction;
      })
      .sort((a, b) => moment(new Date(b.createdAt).toISOString())
        - moment(new Date(a.createdAt).toISOString()));


    sortedTransactions.forEach((transaction) => {
      const endOfCurrentday = moment().endOf('day');
      const transactionDate = moment(new Date(transaction.createdAt).toISOString());
      const daysAgo = endOfCurrentday.diff(transactionDate, 'days');

      if (processedTransactions?.[daysAgo]) {
        processedTransactions[daysAgo] = [...processedTransactions[daysAgo], transaction];
      } else {
        processedTransactions[daysAgo] = [transaction];
      }
    });

    commit('setState', ['isLoading', false]);
    // const end = Date.now();

    // Vue.$log.warn('processedTransactions OK / took', `${end - start} ms`);
    commit('setState', ['processedTransactions', processedTransactions]);
    // Vue.$log.warn('processedTransactions OK / took', `${end - start} ms`, processedTransactions);
  },

  buyOffer(
    {
      dispatch, //
      rootGetters,
      rootState,
    },
    {
      offer, //
      address,
      buyerEmailAddress,
      isInventoryVoucher,
    },
  ) {
    // commit('common/setAppState', ['isAwaiting', true], { root: true });

    const { offer: offerEvent } = kibanaEvents;

    const lang = rootState.user.metaPublic?.language || rootState.common.appState.userLang || 'de';


    let deliveryAddress = i18n.t('purchaseEmailDeliveryFallback');
    let deliveryInstruction = '–';

    if (address) {
      const {
        organization,
        firstName,
        lastName,
        streetName,
        houseNumber,
        postalCode,
        city,
        phoneNumber,
        note,
      } = address;

      const addrOrganization = organization ? `${organization}<br/>` : '';
      const addrFullName = `${firstName} ${lastName}<br/>`;
      const addrLine1 = `${streetName} ${houseNumber}<br/>`;
      const addrLine2 = `${postalCode} ${city}<br/>`;
      const addrPhoneNumber = `${phoneNumber}`;

      deliveryAddress = `${addrOrganization}${addrFullName}${addrLine1}${addrLine2}${addrPhoneNumber}`;

      deliveryInstruction = note || deliveryInstruction;
    }

    const payload = {
      meta: {
        deliveryAddress,
        buyerEmailAddress,
        deliveryInstruction,
        isInventoryVoucher,
        offerPrice: offer.price.toString(),
      },
      lang,
    };

    return new Promise((resolve, reject) => {
      service.company
        .buyOffer(offer.id, payload)
        .then(async (response) => {
          await dispatch('getCompaniesWithOffers');

          await dispatch('getTransactions', rootGetters['user/getWalletId']);

          await dispatch('getPlayer');

          await dispatch('user/getWallets', null, { root: true });

          Vue.$log.info('buyOffer response', response);

          dispatch('common/insertEvent', {
            category: offerEvent.category,
            action: offerEvent.purchased.action,
            type: offer.id,
            metaPublic: {
              offerID: offer?.id,
              storeId: offer?.storeId,
              virtualGoodId: offer?.virtualGood?.id,
              unitPrice: offer?.price,
              amount: 1,
            },
          }, { root: true });

          resolve(response);
        })
        .catch(async (err) => {
          // Get the latest stock status of the products
          await dispatch('getCompaniesWithOffers');

          dispatch('common/insertEvent', {
            category: offerEvent.category,
            action: offerEvent.purchaseFailed.action,
            type: offer.id,
            metaPublic: {
              offerID: offer.id,
              storeId: offer?.storeId,
              virtualGoodId: offer.virtualGood.id,
              unitPrice: offer.price,
              amount: 1,
            },
          }, { root: true });

          reject(err);
        });
      // .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
    });
  },

  // generateConsumeLink(context, productId) {
  //   return new Promise((resolve, reject) => {
  //     service.company
  //       .generateConsumeLink(productId)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  // consumeGood(context, {
  //   productId,
  //   userId,
  //   errorUrl,
  //   successUrl,
  // }) {
  //   return new Promise((resolve, reject) => {
  //     service.company
  //       .consumeGood(productId, userId, errorUrl, successUrl)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
};

// Getter functions
const getters = {
  filterAvailableProductByCategory: (state) => {
    if (state.productCategoryFilter) {
      return state.availableProducts
        .filter((product) => {
          const { metaPublic } = product?.virtualGood || {};

          if (metaPublic?.categories) {
            return metaPublic?.categories?.includes(state.productCategoryFilter);
          }
          return false;
        });
    }

    return state.availableProducts;
  },


  // availableProductCategories: state => [...new Set(state.availableProducts
  //   .map(p => p.virtualGood?.metaPublic?.categories).flat().filter(e => e))],


  getProductById: state => productId => state.availableProducts
    .find(product => product.id === productId),


  /**
 * TODO: We might as well just search the id in the processed receipts if makes sense.
 * Currently it feels like we only keep the original array just to get by id
 */
  getTransactionById: state => transactionId => state.transactions
    .find(transaction => transaction?.id === transactionId),


  getLocalizedResource: (state, getters, rootState) => (resources, defaultLang) => {
    const { userLang } = rootState.common.appState;

    if (!resources) {
      // Vue.$log.warn('getLocalizedResource: resources or defaultLang are null');
      return false;
    }

    const fallbackResources = resources[defaultLang]
      || resources[process.env.VUE_APP_I18N_LOCALE]
      || resources[Object.keys(resources)[0]];

    return resources[userLang] ? resources[userLang] : fallbackResources;
  },


  getCompanyById: state => companyId => state.companiesWithOffers
    .find(company => company.company.id === companyId) || {},

  getCompanyMetaById: state => companyId => state.companies
    .find(company => company?.id === companyId) || {},

  hasCompanies: state => !!state.companies?.length,

  getCompanyByAlias: state => companyAlias => state.companies?.find(
    c => (c.alias || c.tag) === companyAlias,
  ),

  getCompanyResourceByCompanyAlias: (state, getters) => (companyAlias) => {
    const company = getters.getCompanyByAlias(companyAlias);

    const resources = company?.metaPublic?.resources;

    return getters.getLocalizedResource(resources) || {};
  },

  getAllowCompanyLeaderboardByAlias: (state, getters) => (companyAlias) => {
    const company = getters.getCompanyByAlias(companyAlias);

    return company?.allowLeaderboard || false;
  },

  getCompanyTagsByCompanyAlias: (state, getters) => (companyAlias) => {
    const company = getters.getCompanyByAlias(companyAlias);

    return company?.tags || [];
  },

  getPlayerScore: state => state?.player?.score,

  // getAchievementPoints: () => transaction => `+${transaction?.meta?.achievement?.reward?.points}`,

  getTransactionSign: () => (transaction) => {
    if (transaction.type === 'CREDIT') {
      return '+';
    }
    return '';
  },

  hasTinyReceipt: (state, getters) => (transaction) => {
    const hasVirtualGoodTransaction = !isEmpty(getters.getInventoryVoucher(transaction));
    return transaction?.meta?.offer || hasVirtualGoodTransaction;
  },

  getTransactionResource: (state, getters) => (transaction) => {
    const { getLocalizedResource } = getters;
    // Vue.$log.info('transaction', transaction);

    const resources = transaction?.resources
      || transaction?.meta?.achievement?.resources
      || transaction?.meta?.offer?.resources
      || transaction?.achievement?.resources;

    return getLocalizedResource(resources) || {};
  },

  getVirtualGoodResource: (state, getters) => (transaction) => {
    const { getLocalizedResource } = getters;

    const inventoryVoucherVirtualGood = getters.getInventoryVoucher(transaction);

    const resources = transaction?.meta?.offer?.virtualGood.resources
      || inventoryVoucherVirtualGood.resources;

    return getLocalizedResource(resources) || {};
  },

  getReceiptResource: (state, getters) => (transaction) => {
    const { getTransactionResource, getVirtualGoodResource } = getters;
    return getVirtualGoodResource(transaction) || getTransactionResource(transaction) || {};
  },

  getInventoryVoucher: () => (transaction) => {
    const virtualGoods = transaction?.meta?.achievement?.reward?.virtualGoods
      || transaction?.achievement?.reward?.virtualGoods
      || transaction?.reward?.virtualGoods;

    const virtualGood = transaction?.meta?.offer?.virtualGood?.type === 'INVENTORY_VOUCHER'
      && transaction?.meta?.offer?.virtualGood;

    /**
     * Currently we find only the first VG of certain type, when we start giving multiple
     * VG items, we will need some improvements.
     */
    return virtualGood || virtualGoods?.find(vg => vg.type === 'INVENTORY_VOUCHER');
  },

  // Not sure if this is necessary anymore after `getVirtualGoodResource`
  getInventoryVoucherResource: (state, getters, rootState, rootGetters) => (payload) => {
    const resources = rootGetters['company/getInventoryVoucher'](payload)?.resources;

    return rootGetters['company/getLocalizedResource'](resources) || {};
  },

  getInventoryVoucherContent: (state, getters) => (transaction) => {
    const { getInventoryVoucher, getLocalizedResource } = getters;
    return getLocalizedResource(getInventoryVoucher(transaction)?.content) || {};
  },

  isInventoryVoucher: (state, getters) => (transaction) => {
    const { getInventoryVoucher, getInventoryVoucherContent } = getters;

    return !isEmpty(getInventoryVoucher(transaction))
      && !isEmpty(getInventoryVoucherContent(transaction));
  },


  /**
   * Transaction can be either an extract of an offer or achievement or pure achievement object
   */
  getCompanyResourceFromTransaction: (state, getters, rootState, rootGetters) => (transaction) => {
    const { offer, achievement } = transaction?.meta || transaction || {};

    const companyId = offer?.storeId || achievement?.sponsorId;

    const companyResources = rootGetters['company/getCompanyMetaById'](companyId)?.metaPublic?.resources;

    return rootGetters['company/getLocalizedResource'](companyResources) || {};
  },

  getTransactionMoment: () => (transaction, format) => moment(transaction?.createdAt
    || transaction?.grantedAt)?.format(format || 'LL'),

  getReceiptImage: (state, getters) => (transaction) => {
    const offerResources = getters.getLocalizedResource(
      transaction?.meta?.offer?.virtualGood?.resources,
    );

    return (
      getters.getInventoryVoucherResource(transaction).images?.[0]
      || offerResources?.images?.[0]
      || getters.getCompanyResourceFromTransaction(transaction)?.logo
      || getters.getCompanyResourceFromTransaction(transaction)?.imageUrl
    );
  },
};

export default {
  namespaced: true,
  state: () => (state),
  getters,
  actions,
  mutations,
};
