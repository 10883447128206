<template>
  <cc-modal
    data-ruid="modal__earth-mood-tip"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('tipModalTitle')"
    :no-margin="true"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div class="px-5 pb-5 d-flex flex-column gap-3">
        <div
          v-for="(tip,i) in tipsData"
          :key="`Tip-${i}`"
          class="d-flex flex-column gap-3"
          >
          <div class="preline-formatted">
            <h4 class="h4">
              {{ tipTitle(tip.tip, i+1) }}
            </h4>
            <div v-if="tip.tip!==3">
              {{ $t('whatCouldWeSave1') }} <strong>{{ distanceText(tip.distance) }}</strong>
              {{ $t('whatCouldWeSave2') }} <strong>{{ emissionText(tip.co2) }}</strong>
              {{ $t('whatCouldWeSave3') }}
              <strong>{{ tipTransportMode(tip.tip) }}</strong>.
              {{ $t('whatCouldWeSave4') }}
            </div>
            <div v-else>
              {{ $t('whatCouldWeSave1') }} <strong>{{ distanceText(tip.distance) }}</strong>
              {{ $t('whatCouldWeSave2') }} <strong>{{ emissionText(tip.co2) }}</strong>
              {{ $t('aloneCar_fossilSingularTxt') }}. {{ $t('whatCouldWeSave5') }}
            </div>
          </div>
          <div class="d-flex flex-column gap-3">
            <div
              v-for="(transport, index) in transportList(tip)"
              :key="`Transport-Tip-${index}`"
              >
              <div
                class="d-flex align-items-center mt-1 gap-3"
                >
                <cc-svg-base
                  style="min-width: 40px;"
                  :svg="`transport-icons/${transport.icon}`"
                  />
                <div class="d-flex flex-column">
                  <span class="font-weight-bold ">{{ transport.title }}:</span>
                  <span>
                    {{ $t('youSaveTxt') }} {{ emissionText(transport.saved) }}
                    {{ $t('ofCO2Txt') }}
                  </span>
                </div>
              </div>
            </div>
          <!-- <div>
              Next time try to:<br>
              1. Walk or ride a bike and do something good for your health.<br>
              2. Travel by public transports if you have the option on that trip.<br>
              3. Choose for a full electric or hybrid car.
            </div> -->
          </div>
          <div class="pt-2">
            <span>
              <span class="font-weight-bold">{{ $t('tipNxtTimeTitle') }}</span>
              <span v-if="tipNextTime(tip.tip).length ===1">
                {{ tipNextTime(tip.tip)[0] }}
              </span>
            </span>
            <ul
              v-if="tipNextTime(tip.tip).length >1"
              class="pl-0 pt-1"
              >
              <li
                v-for="(tipNext, indexTipNext) in tipNextTime(tip.tip)"
                :key="`${tip.tip}: ${indexTipNext}`"
                >
                <span>{{ tipNext }}</span>
              </li>
            </ul>
          </div>
          <cc-divider
            v-if="i !== tipsData?.length-1"
            class="mb-4"
            style="flex-basis: 100%;"
            />
        </div>
      </div>
    </template>
  </cc-modal>
</template>

<script>

import ccModal from '@/components/modals/Modal.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import { mapState } from 'vuex';

export default {
  components: {
    ccModal,
    ccSvgBase,
  },
  props: {
    tipsData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      emissionByCarfossilPerMetter: state => state.trips.emissionByCarfossilPerMetter,
      emissionByCarHybridPerMetter: state => state.trips.emissionByCarHybridPerMetter,
      emissionByCarElectricPerMetter: state => state.trips.emissionByCarElectricPerMetter,
      emissionByBusFossilPerMetter: state => state.trips.emissionByBusFossilPerMetter,
      emissionByCoachPerMetter: state => state.trips.emissionByCoachPerMetter,
      emissionByBikeElectricPerMetter: state => state.trips.emissionByBikeElectricPerMetter,
      emissionByTrainPerMetter: state => state.trips.emissionByTrainPerMetter,
      emissionByTramPerMetter: state => state.trips.emissionByTramPerMetter,
    }),


  },
  methods: {
    emissionText(emission) {
      if (emission >= 1000) {
        const co2 = (emission / 1000).toFixed(1);
        return `${co2} kg`;
      }
      return `${emission.toFixed(0)} g`;
    },
    distanceText(distance) {
      if (distance >= 1000) {
        const kilometers = (distance / 1000).toFixed(1);
        return `${kilometers} km`;
      }
      return `${distance.toFixed(0)} m`;
    },
    tipTransportMode(tipType) {
    if (tipType === 1) {
      return this.$t('bus_fossilSingularTxt');
    }
    if (tipType === 2) {
      return this.$t('bus_fossilSingularTxt');
    }
    if (tipType === 3) {
      return this.$t('aloneCar_fossilSingularTxt');
    }
    if (tipType === 4) {
      return this.$t('car_electricSaveTxt');
    }
    if (tipType === 5) {
      return this.$t('car_electricSaveTxt');
    }
    if (tipType === 6) {
      return this.$t('car_hybridSaveTxt');
    }
    if (tipType === 7) {
      return this.$t('car_hybridSaveTxt');
    }
    if (tipType === 8) {
      return this.$t('car_fossilSaveTxt');
    }
    if (tipType === 9) {
      return this.$t('car_fossilSaveTxt');
    }
    if (tipType === 10) {
      return this.$t('airplane_fossilSaveTxt');
    }
    return '';
    },
    tipTitle(tipType, index) {
      if (tipType === 1) {
        return `${index}. ${this.$t('bus_fossilTitle')}`;
      }
      if (tipType === 2) {
        return `${index}. ${this.$t('bus_fossilTitle')}`;
      }
      if (tipType === 3) {
        return `${index}. ${this.$t('car_sharingTitle')}`;
      }
      if (tipType === 4) {
        return `${index}. ${this.$t('car_electricTitle')}`;
      }
      if (tipType === 5) {
        return `${index}. ${this.$t('car_electricTitle')}`;
      }
      if (tipType === 6) {
        return `${index}. ${this.$t('car_hybridTitle')}`;
      }
      if (tipType === 7) {
        return `${index}. ${this.$t('car_hybridTitle')}`;
      }
      if (tipType === 8) {
        return `${index}. ${this.$t('car_fossilTitle')}`;
      }
      if (tipType === 9) {
        return `${index}. ${this.$t('car_fossilTitle')}`;
      }
      if (tipType === 10) {
        return `${index}. ${this.$t('airplane_fossilTxt')}`;
      }
      return '';
    },
    tipNextTime(tipType) {
      if (tipType === 1) {
        return [
          this.$t('tip1_1'),
          this.$t('tip1_2'),
        ];
      }
      if (tipType === 2) {
        return [this.$t('tip2_1')];
      }
      if (tipType === 3) {
        return [this.$t('tip3_1')];
      }
      if (tipType === 4) {
        return [
          this.$t('tip4_1'),
          this.$t('tip4_2'),
        ];
      }
      if (tipType === 5) {
        return [this.$t('tip5_1')];
      }
      if (tipType === 6) {
        return [
          this.$t('tip6_1'),
          this.$t('tip6_2'),
          this.$t('tip6_3'),
        ];
      }
      if (tipType === 7) {
        return [
          this.$t('tip7_1'),
          this.$t('tip7_2'),
        ];
      }
      if (tipType === 8) {
        return [
          this.$t('tip8_1'),
          this.$t('tip8_2'),
          this.$t('tip8_3'),
        ];
      }
      if (tipType === 9) {
        return [
          this.$t('tip9_1'),
          this.$t('tip9_2'),
        ];
      }
      if (tipType === 10) {
        return [
          this.$t('tip10_1'),
          this.$t('tip10_2'),
          this.$t('tip10_3'),
          this.$t('tip10_4'),
        ];
      }
      return [];
    },
    calculateComplexCarSharing(sourceDetails, compareCO2) {
      return sourceDetails.reduce(
      (acc, source) => acc + source.co2 - ((compareCO2 / source.numberOfPeople) * source.distance),
      0,
      );
    },
    transportList(tip) {
      if (tip.tip === 3) {
      return [
        {
          icon: 'car_fossil',
          title: `2 ${this.$t('carSharing_people')}`,
          saved: tip.co2 / 2,
        },
        {
          icon: 'car_fossil',
          title: `3 ${this.$t('carSharing_people')}`,
          saved: tip.co2 - (tip.co2 / 3),
        },
        {
          icon: 'car_fossil',
          title: `4 ${this.$t('carSharing_people')}`,
          saved: tip.co2 - (tip.co2 / 4),
        },
        {
          icon: 'car_fossil',
          title: `5 ${this.$t('carSharing_people')}`,
          saved: tip.co2 - (tip.co2 / 5),
        },
      ];
      }
      const transportList = [
        ...[tip.tip === 1 || tip.tip === 4 || tip.tip === 8 ? {
          icon: 'walk_human-powered',
          title: `${this.$t('walk_human-poweredTxt')}/${this.$t('bicycle_human-poweredTxt')}`,
          saved: tip.co2,
        } : {}],
        ...[tip.tip === 6 ? {
          icon: 'bicycle_human-powered',
          title: this.$t('bicycle_human-poweredTxt'),
          saved: tip.co2,
        } : {}],
        ...[tip.tip === 1 || tip.tip === 4 || tip.tip === 6 || tip.tip === 8 ? {
          icon: 'bicycle_electric',
          title: this.$t('bicycle_electricTxt'),
          saved: tip.co2 - (this.emissionByBikeElectricPerMetter * tip.distance),
        } : {}],
        ...[tip.tip !== 3 ? {
          icon: 'train_electric',
          title: this.$t('train_electricAlt'),
          saved: tip.co2 - (this.emissionByTrainPerMetter * tip.distance),
        } : {}],
        ...[tip.tip !== 10 && tip.tip !== 3 ? {
          icon: 'tram_electric',
          title: this.$t('tram_electricAlt'),
          saved: tip.co2 - (this.emissionByTramPerMetter * tip.distance),
        } : {}],
        ...[tip.tip !== 1
            && tip.tip !== 2
            && tip.tip !== 10
            && tip.tip !== 3 ? {
          icon: 'bus_fossil',
          title: this.$t('bus_fossilAlt'),
          saved: tip.co2 - (this.emissionByBusFossilPerMetter * tip.distance),
        } : {}],
        ...[tip.tip === 6
            || tip.tip === 7
            || tip.tip === 8
            || tip.tip === 9
            || tip.tip === 10 ? {
          icon: 'car_electric',
          title: this.$t('car_electricTitle'),
          saved: tip.details
          ? this.calculateComplexCarSharing(tip.details, this.emissionByCarElectricPerMetter)
          : tip.co2 - (this.emissionByCarElectricPerMetter * tip.distance),
        } : {}],
        ...[tip.tip === 8 || tip.tip === 9 || tip.tip === 10 ? {
          icon: 'car_hybrid',
          title: this.$t('car_hybridTitle'),
          saved: tip.details
          ? this.calculateComplexCarSharing(tip.details, this.emissionByCarHybridPerMetter)
          : tip.co2 - (this.emissionByCarHybridPerMetter * tip.distance),
        } : {}],
      ].filter(item => !!item?.title);
      return transportList.filter(item => item.saved > 0);
    },
  },
};
</script>

<style scoped>
li span {
  margin-left: -0.3rem;
}

ul {
  list-style-position: inside;
}

li {
  list-style-type: none !important;
}

li:before {
      content: '\2022 \00a0 \00a0 \00a0';
}
</style>
