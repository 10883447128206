<template>
  <cc-modal
    data-ruid="modal__social-feature"
    group-class="bg-blue-dark"
    header-title=""
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <ccLottie
        id="globeAnim"
        class="w-100"
        :options="currentAnim"
        />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 w-100"
        >
        <cc-heading
          variant="h5"
          class="pb-2"
          weight="xbold"
          >
          {{ $t(`socialFeatureModalTitle`) }}
        </cc-heading>

        <cc-text variant="body-sm">
          {{ $t(`socialFeatureModalDescription`) }}
        </cc-text>

        <cc-text variant="body-sm">
          {{ $t(`socialFeatureModalDescriptionTwo`) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex flex-row flex-wrap-reverse justify-content-around w-100 py-5 px-5">
        <cc-button
          :is-modal-button="true"
          variant="transparent"
          :block="false"
          :text="$t('txtAskLater')"
          class="flex-grow-1"
          @click="close"
          />
        <cc-button
          :is-modal-button="true"
          variant="secondary"
          :block="false"
          :text="$t(`btnSocialFeatureModal`)"
          class="flex-grow-1"
          @click="getStarted(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import ccModal from '@/components/modals/Modal.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';

import modalAnimSocialFeature from '@/components/lottie/primerAnimSocialFeature.json';

export default {
  components: {
    ccButton,
    ccHeading,
    ccText,
    ccModal,
    ccLottie,
  },
  data() {
    return {
      animData: {
        currentAnim: {
          animationData: modalAnimSocialFeature,
        },
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
      timeline: state => state.trips.timeline,
    }),
    ...mapGetters({
    }),
    newFeatureText() {
      return this.$t('txtNewFeature');
    },
    currentAnim() {
      return { animationData: modalAnimSocialFeature };
    },
  },
  mounted() {
    modalAnimSocialFeature.assets[0].layers[0].t.d.k[0].s.t = this.newFeatureText || '-';
    modalAnimSocialFeature.assets[0].layers[2].t.d.k[0].s.t = this.newFeatureText || '-';
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getStarted(closeModal) {
      this.$store.dispatch('user/setSocialFeatureModalShown');

      closeModal(() => {
        this.$router.push({ name: 'leaderboards' });
      });
    },
  },
};
</script>
