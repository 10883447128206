<template>
  <div
    ref="tick"
    class="tick"
    >
    <div
      data-repeat="true"
      data-layout="horizontal fit"
      data-value-mapping="indexes"
      data-transform="preset(d, h, m, s) -> delay"
      >
      <div class="tick-group">
        <div
          data-key="value"
          data-repeat="true"
          aria-hidden="true"
          data-transform="pad(00) -> split -> delay"
          >
          <span data-view="flip" />
        </div>
        <span
          data-key="label"
          data-view="text"
          class="tick-label"
          />
      </div>
    </div>
  </div>
</template>

<script>
import Tick from '@pqina/flip';
import '@pqina/flip/dist/flip.min.css';

export default {
  name: 'Flip',
  props: {
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // value: '2023-03-05T00:03:00.000+00:00',
      tick: null,
    };
  },
  watch: {
    value(newValue) {
      this.tick.value = newValue;
    },
  },
  mounted() {
    this.tick = Tick.DOM.create(this.$refs.tick, {
      value: null,
    });

    Tick.count.down(this.target).onupdate = (value) => {
      this.tick.value = value;
    };
  },
  destroyed() {
    Tick.DOM.destroy(this.$refs.tick);
  },
};
</script>

<style>
.tick-flip-panel-back:after {
  background-image: linear-gradient(
    180deg,
    rgba(8, 27, 89, 0.3) 1px,
    rgba(12, 8, 115, 0.15) 0,
    transparent 30%
  );
}

.tick-flip-card-shadow,
.tick-flip-panel-back,
.tick-flip-shadow-bottom .tick-flip-back {
  box-shadow: none !important;
}

.tick-flip-shadow-top,
.tick-flip-shadow,
.tick-flip-front {
  box-shadow: none;
}
.tick {
  font-size: 0.5rem;
  white-space: nowrap;
}

.tick-flip,
.tick-text-inline {
  font-size: 2.5em;
}

.tick-label {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.tick-char {
  width: 1.5em;
}

.tick-text-inline {
  display: inline-block;
  text-align: center;
  min-width: 1em;
}

.tick-text-inline + .tick-text-inline {
  margin-left: -0.325em;
}

.tick-group {
  margin: 0 0.5em;
  text-align: center;
}

.tick-text-inline {
  color: #595d63 !important;
}

.tick-label {
  color: #b8d4ff !important;
}

.tick-flip-panel {
  color: #fff !important;
}

.tick-flip-panel-text-wrapper {
  line-height: 1.45 !important;
}

.tick-flip-panel {
  background-color: #003fc4 !important;
}

.tick-flip {
  border-radius: 0.12em !important;
}
</style>
