export default {
  props: {
    block: {
      type: Object,
      required: false,
    },
  },
  methods: {
    getContent(block) {
      return block.data.text || block;
    },
  },
};
