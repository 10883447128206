export default {
  mounted() {
    // this.$log.info('Mounted Sandbox Mixin');
    window.addEventListener('message', this.receiveMessage);
  },
  destroyed() {
    // this.$log.info('Destroyed Sandbox Mixin');
    window.removeEventListener('message', this.receiveMessage);
  },
  methods: {
    sendMessageToHost(message) {
      window.parent.postMessage({ message }, '*');
    },
    async receiveMessage(event) {
      if (event.origin.startsWith(process.env.VUE_APP_DASHBOARD)) {
        this.$log.warn('Client received:', event.data);

        if (event.data.message === 'auth') {
          this.$log.info('Client received: auth', event.data.payload);

          await this.$store.dispatch('user/authenticate', event.data.payload);
        }

        if (event.data.message === 'company') {
          this.$log.info('Client received: company', [event.data.payload]);

          if (event.data.payload) {
            await this.$store.dispatch('company/getCompaniesWithOffers', [event.data.payload]);
          }
        }
      }
    },
  },
  created() {
    // this.$log.warn('SANDBOX');
  },
};
