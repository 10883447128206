<template>
  <cc-modal
    data-ruid="modal__confirm-company-removal"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('removeFromCompanyConfirmationModalTitle', { groupName: getUserCompanyTagId })"
          svg="illustrations/company-deletion"
          />

        <cc-text
          align="center"
          class="py-4"
          >
          {{ $t('removeFromCompanyConfirmationModalText' ) }}
        </cc-text>
        <cc-text
          align="center"
          class="py-4"
          >
          {{ $t('removeFromCompanyConfirmationModalTextDetails' ) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="column"
        class="px-5 py-5"
        >
        <cc-button
          :is-modal-button="true"
          class="mb-4"
          data-ruid="global__button-remove"
          variant="danger"
          :text="$t('confirmRemoveFromCompany')"
          @click="deleteGroup(close)"
          />
        <cc-button
          :is-modal-button="true"
          variant="secondary"
          data-ruid="global__button-confirm"
          :text="$t('txtCancel')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'confirm-company-removal-modal',
      group: null,
    };
  },
  computed: {
    ...mapGetters({
      getUserCompanyTagId: 'user/getUserCompanyTagId',
      getUserCompanyDepartment: 'user/getUserCompanyDepartment',
    }),
  },
  methods: {
    async deleteGroup(closeModal) {
      // TODO: Add error message component to ui and bind here
      try {
        await this.$store.dispatch('user/removeCompany');
        await this.$store.dispatch('user/getUserTags');

        closeModal(() => {
            this.$router.push({ name: 'settings' }).catch(() => {});
        });
      } catch (error) {
        this.$log.warn('removeCompany error', error);
      }
    },
  },
};
</script>
