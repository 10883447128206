<template>
  <div
    class="d-flex py-2 flex-wrap"
    >
    <div
      v-if="true"
      style="flex: 0 0 48px;"
      class="mr-4 d-flex align-items-center"
      >
      <cc-image
        class="border-radius-full"
        style="width: 48px; height: 48px;"
        :src="icon"
        :has-placeholder="true"
        placeholder-class="bg-blue-light"
        />
    </div>

    <div
      v-if="true"
      class="flex-grow-1"
      style="flex-basis: 50%;"
      >
      <cc-text
        variant="body-xs"
        color="muted"
        >
        {{ date }}
      </cc-text>

      <cc-text
        variant="body"
        class="flex-grow-1 mr-2"
        >
        {{ description }}
      </cc-text>

      <cc-text
        v-if="hasFeatureTagWallet && points"
        variant="body-bold"
        color="primary"
        class="d-flex align-items-center py-1"
        >
        +{{ points }}
        <cc-svg-base
          tag="i"
          color-class="svg-fill-primary"
          svg="icons/icon-green-points"
          class="mx-1"
          />
        {{ $t('txtGreenPoints') }}
      </cc-text>

      <cc-text
        v-if="hasFeatureTagWallet && credits"
        variant="body-bold"
        color="primary"
        class="d-flex align-items-center"
        >
        +{{ credits }}
        <cc-svg-base
          tag="i"
          color-class="svg-fill-primary"
          svg="icons/icon-green-coins-12"
          class="mx-1"
          />

        {{ $t('txtGreenCoins') }}
      </cc-text>
    </div>


    <cc-divider
      v-if="borderBottom"
      class="pt-4"
      style="flex-basis: 100%;"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccImage from '@/components/primitives/Image.vue';

export default {
  components: {
    // ccWrapper,
    ccSvgBase,
    ccText,
    ccDivider,
    ccImage,
  },
  props: {
    description: {
      type: String,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    points: {
      type: Number,
      required: false,
      default: null,
    },
    credits: {
      type: Number,
      required: false,
      default: null,
    },
    borderBottom: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      hasFeatureTagWallet: 'user/hasFeatureTagWallet',
    }),
  },
};
</script>
