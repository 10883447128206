<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <!-- Mind that in order ui control height to be taken into account when calculating
    subview height, `data-ui-control` attribute as below must be set on the containing div
    Also notice the hasShadow flag which toggles the shadow, see  details in mounted hook -->
    <div
      :id="`${id}__time-frame-group`"
      class="bg-blue-dark px-3"
      :class="hasShadow ? 'ui-control-shadow' : null"
      :data-ui-control="true"
      >
      <cc-time-frame-segment :id="`${id}__time-frame-segment`" />
      <cc-time-frame-stepper :id="`${id}__time-frame-stepper`" />
    </div>

    <!-- Children of a parent route would be rendered in the view below  -->
    <cc-view
      :id="`${id}`"
      :has-full-width="true"
      :is-sub-view="true"
      :has-overflow-scroll="false"
      :has-router-view="true"
      :has-ui-controls="true"
      />
  </cc-view>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccTimeFrameSegment from '@/components/controllers/TimeFrameSegmentController.vue';
import ccTimeFrameStepper from '@/components/controllers/TimeFrameStepperController.vue';

export default {
  name: 'FootprintTab',
  components: {
    ccView,
    ccTimeFrameSegment,
    ccTimeFrameStepper,
  },
  data() {
    return {
      id: 'footprint-tab',
      hasShadow: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
    }),
  },
  mounted() {
    /**
     * Segmented control group uses transform to transition
     * and since z-index doesn't work together with transform, we must render the shadow
     * after transition has been completed.
     * Duration can be adjusted as needed.
     */
    setTimeout(() => {
      this.hasShadow = true;
    }, 500);
  },
  destroyed() {
    this.$log.info('Destroyed:', this.id);
  },
  methods: {
    ...mapMutations({
      setRoutePosition: 'common/setRoutePosition',
    }),
  },
};
</script>

<style>
/* #app__view__footprint-tab__sub-view > :nth-child(1) {
  z-index: 1;
} */
</style>
