<template>
  <cc-view
    :id="id"
    >
    <div class="d-flex flex-column justify-content-between h-100 text-center">
      <cc-heading variant="h3">
        {{ $t('startPageSubtitle') }}
      </cc-heading>

      <div class="start-globe-svg px-7 pt-3">
        <cc-svg-base svg="globe" />
      </div>

      <div class="start-globe-lottie">
        <cc-lottie
          class="px-6"
          :options="slide.lottieOptions"
          />
      </div>

      <div class="pb-3 mx-5">
        <cc-svg-base
          color-class="svg-fill-white"
          :svg="`partner-logo/main-partners-${appState.userLang}`"
          />
      </div>

      <cc-text
        v-if="validationMessage"
        color="danger"
        class="start-terms-container"
        >
        {{ validationMessage }}
      </cc-text>

      <div class="d-flex flex-column">
        <cc-button
          button-class="mb-2"
          :text="$t('txtNewUser')"
          data-ruid="button-new-user"
          :has-loading-feedback="true"
          @click="createGuestAccount"
          />

        <cc-button
          v-if="true"
          button-class="mb-2 mt-2"
          :text="$t('joinCompanyChallengeButton')"
          data-ruid="button-new-user"
          @click="$router.push({ name: 'add-company-email' }).catch(() => {})"
          />

        <cc-button
          button-class="start-page-button-existing"
          variant="transparent"
          data-ruid="button-existing-user"
          :text="`${$t('txtExistingUser')}  >`"
          @click="$router.push({ name: 'signup' }).catch(() => {})"
          />
      </div>

      <cc-legal-component
        class="safe-area-inset-bottom-mb"
        :button-name="`&quot;${$t('txtNewUser')}&quot; ${$t('txtOr')}
            &quot;${$t('txtExistingUser')}&quot;`"
        />
    </div>
  </cc-view>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';

import ccSvgBase from '@/components/SvgBase.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';
import ccLegalComponent from '@/components/LegalComponent.vue';

import startAnimOne from '@/components/lottie/globe_happy_start.json';

export default {
  components: {
    ccView,
    ccHeading,
    ccText,
    ccButton,
    ccLottie,
    ccSvgBase,
    ccLegalComponent,
  },
  data() {
    return {
      id: 'start',
      // routingFrom: '',
      creatingAccount: false,
      slide: {
        name: 'globeHappy',
        lottieOptions: {
          autoplay: true,
          animationData: startAnimOne,
        },
      },
      validationMessage: '',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      user: state => state.user.user,
    }),
  },
  created() {
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUserState: 'user/setState',
    }),
    createGuestAccount() {
      this.setUserState(['isNewUser', true]);

      this.creatingAccount = true;

      this.$store
        .dispatch('user/createGuestAccount')
        .then(() => {
          this.$router.push({ name: 'account-created' }).catch(() => {});
        })
        .catch(() => {
          this.validationMessage = this.$t('generalErrorMessage');
        });
    },
  },
};
</script>

<style lang="scss">
.font-start-title {
  font-size: $font-size-26;
}
.font-start-subtitle {
  font-size: $font-size-22;
}
.start-globe-lottie {
  display: block;
}
.start-globe-svg {
  display: none;
}
.start-terms-container {
  font-size: $font-size-sm;
}
@media (max-width: 320px) {
  .start-globe-lottie {
    display: none;
  }
  .start-globe-svg {
    display: block;
  }
  .start-page-button {
    font-size: $font-size-sm;
    padding-top: 0.55rem;
    padding-right: 1.125rem;
    padding-bottom: 0.55rem;
    padding-left: 1.125rem;
  }
  .start-page-button-existing {
    font-size: $font-size-sm;
    padding-top: 0rem;
    padding-right: 1.125rem;
    padding-bottom: 0.55rem;
    padding-left: 1.125rem;
  }
  .font-start-title {
    font-size: $font-size-22;
  }
  .font-start-subtitle {
    font-size: $font-size-18;
  }
  .start-terms-container {
    font-size: $font-size-xsm;
  }
}
</style>
