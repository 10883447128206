<template>
  <cc-view
    :id="selectorId"
    class="pb-6"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-charts-loading
      v-if="isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <template v-else>
      <div class="p-4">
        <cc-wrapper
          direction="row"
          justify="between"
          class="pb-2"
          >
          <cc-heading>
            {{ $t('groupLeaderboardGroupViewParticipantsTitle') }} ({{ getGroupSize }})
          </cc-heading>

          <cc-button
            v-if="isAdminOrOwner"
            variant="small"
            :block="false"
            icon="icons/icon-add-user"
            icon-color="svg-fill-blue-dark"
            @click.native="addParticipantsHandler"
            />
        </cc-wrapper>

        <cc-text
          v-if="isAdminOrOwner"
          color="muted"
          variant="body-sm"
          class="pb-2"
          >
          {{ $t('groupLeaderboardGroupViewParticipantsDescription') }}
        </cc-text>

        <cc-text
          v-if="!hasDidTravelLeaderboard"
          variant="body-sm"
          color="muted"
          >
          {{ participantsHaveNoTripsYetText }}
        </cc-text>

        <cc-text
          v-if="hasDidTravelLeaderboard"
          variant="body-sm"
          color="muted"
          >
          {{
            appState.leaderboardType === 'averageCo2' ? $t('leaderboardsDescriptionSingular') : null
          }}
          {{
            appState.leaderboardType === 'co2PerKm'
              ? $t('leaderboardsDescriptionSingularCo2PerKm')
              : null
          }}
        </cc-text>

        <code
          v-if="false"
          class="font-size-12 mt-4"
          >
          contactsPermission: {{ appState.contactsPermission }}
          <br>
          permissionGiven: {{ permissionGiven }}
          <br>
          hasPermission: {{ hasPermission }}
        </code>
      </div>

      <!-- Regular Group leaderboard -->
      <template v-if="hasDidTravelLeaderboard">
        <cc-leaderboard-list-item
          v-for="(member, index) in groupLeaderboardData"
          :key="index"
          type="group"
          :has-row-title="index === 0"
          :rank="member.curPosition"
          :rank-class="getRankClass(member.curPosition)"
          :prev-rank="member.prevPosition"
          :icon="getCupIcon(member.curPosition)"
          :name="getDisplayName(member)"
          :self-rank="(isSelfInGroup(member.id) && member.curPosition) || undefined"
          :score="getEmissionText(member.score)"
          @click.native="clickHandler(member)"
          />
      </template>

      <!-- Did not travel leaderboard -->
      <div
        v-if="hasDidNotTravelLeaderboard"
        class="pt-6"
        >
        <h4 class="font-weight-bold text-white text-left px-4">
          {{ didNotTravelText }}
        </h4>

        <cc-text
          class="px-4 pb-4 pt-2"
          variant="body-sm"
          color="muted"
          >
          {{ $t('didNotTravelDescription') }}
        </cc-text>

        <cc-leaderboard-list-item
          v-for="(member, index) in sortedDidNotTravelList"
          :key="`did-not-travel-${index}`"
          type="group"
          :did-not-travel="true"
          :rank="member.curPosition || 0"
          :show-rank="false"
          :name="getDisplayName(member)"
          score=""
          @click.native="clickHandler(member)"
          />
      </div>
    </template>

    <cc-add-member-modal
      v-if="isAddMemberModalDisplayed"
      :data="{ ...group, ...groupLeaderboard }"
      @close-modal="isAddMemberModalDisplayed = false"
      />

    <cc-group-member-modal
      v-if="isGroupMemberModalDisplayed"
      :data="groupMemberModalData"
      @after-leave="
        ($event) => {
          (confirmMemberRemovalModalData = $event), (isConfirmMemberRemovalModalDisplayed = true);
        }
      "
      @close-modal="isGroupMemberModalDisplayed = false"
      />

    <cc-confirm-member-removal-modal
      v-if="isConfirmMemberRemovalModalDisplayed"
      :data="confirmMemberRemovalModalData"
      @after-leave="
        ($event) => {
          (groupMemberModalData = $event), (isGroupMemberModalDisplayed = true);
        }
      "
      @close-modal="isConfirmMemberRemovalModalDisplayed = false"
      />

    <cc-phone-verification-modal
      v-if="isPhoneVerificationModalDisplayed"
      @after-leave="addParticipantsHandler"
      @close-modal="isPhoneVerificationModalDisplayed = false"
      />

    <cc-contacts-permission-modal
      v-if="isContactsPermissionModalDisplayed"
      :data="{ permissionModalType }"
      @close-modal="isContactsPermissionModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';

import ccAddMemberModal from '@/components/modals/AddMemberModal.vue';
import ccGroupMemberModal from '@/components/modals/GroupMemberModal.vue';
import ccConfirmMemberRemovalModal from '@/components/modals/ConfirmMemberRemovalModal.vue';
import ccPhoneVerificationModal from '@/components/modals/PhoneVerificationModal.vue';
import ccContactsPermissionModal from '@/components/modals/ContactsPermissionModal.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccText,
    ccButton,
    ccHeading,
    ccLeaderboardListItem,
    ccChartsLoading,
    ccAddMemberModal,
    ccGroupMemberModal,
    ccConfirmMemberRemovalModal,
    ccPhoneVerificationModal,
    ccContactsPermissionModal,
  },
  beforeRouteEnter(to, from, next) {
    // TODO: Do same when coming back here from Settings?
    if (to?.params?.group?.group?.name) {
      // eslint-disable-next-line no-param-reassign
      to.meta.title = { title: to?.params?.group?.group?.name };
    }
    next();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectorId: 'group-view',
      group: null,
      lastClicked: null,
      isLoading: true,
      isAddMemberModalDisplayed: false,
      isGroupMemberModalDisplayed: false,
      groupMemberModalData: null,
      confirmMemberRemovalModalData: null,
      isConfirmMemberRemovalModalDisplayed: false,
      isPhoneVerificationModalDisplayed: false,
      isContactsPermissionModalDisplayed: false,
      permissionModalType: 'AllowContacts',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      getGroupById: 'social/getGroupById',
      isSelfInGroup: 'social/isSelfInGroup',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    isAdminOrOwner() {
      return this.userRole === 'owner' || this.userRole === 'admin';
    },
    getCardText() {
      if (this.userRole === 'admin' || this.userRole === 'owner') {
        return this.$t('groupLeaderboardGroupSettings');
      }
      return this.$t('groupLeaderboardGroupInfo');
    },
    getGroupSize() {
      return this.leaderboardState?.group?.currentData?.data?.[0]?.members?.length;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    userRole() {
      return this.leaderboardState?.group?.currentData?.data?.[0]?.userRole;
    },
    groupInfo() {
      return this.leaderboardState?.group?.currentData?.data?.[0]?.group;
    },
    groupLeaderboardData() {
      return this.leaderboardState?.group?.[this.appState?.leaderboardType];
    },
    hasDidTravelLeaderboard() {
      return this.leaderboardState?.group?.currentData?.didTravel?.length > 0;
    },
    hasDidNotTravelLeaderboard() {
      return this.leaderboardState?.group?.currentData?.didNotTravel?.length > 0;
    },
    didTravelLeaderboard() {
      return this.leaderboardState?.group?.currentData?.didTravel;
    },
    didNotTravelLeaderboard() {
      return this.leaderboardState?.group?.currentData?.didNotTravel;
    },
    sortedDidNotTravelList() {
      return this.leaderboardState?.group?.currentData?.didNotTravel
        ?.slice(0)
        ?.sort((a, b) => a?.name?.localeCompare(b?.name));
    },
    didNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelParticipantsYet');
      }

      return this.$t('didNotTravelParticipants');
    },
    participantsHaveNoTripsYetText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('leaderboardsParticipantsHaveNoTripsYet');
      }

      return this.$t('leaderboardsParticipantsHaveNoTrips');
    },
    groupLeaderboard() {
      return this.leaderboardState?.group?.currentData?.data?.[0];
    },
    permissionGiven() {
      if (this.appState.contactsPermission === 'undefined') {
        return false;
      }
      return true;
    },
    hasPermission() {
      if (this.appState.contactsPermission === 'false') {
        return false;
      }
      return true;
    },
  },
  watch: {
    async queryDate() {
      await this.$store.dispatch('social/getGroupLeaderboard', {
        groupId: this.$route.params.id,
      });
    },
  },
  async mounted() {
    const refreshTime = 900000;

    const weeksAgo = this.$moment().diff(this.appState.queryDate, 'weeks');

    const shouldDispatch = !this.leaderboardState.group
      || weeksAgo !== this.leaderboardState.group.windowsAgo
      || this.leaderboardState.group.currentData.data[0].id !== this.$route.params.id
      || Date.now() - refreshTime > this.leaderboardState.group.timestamp;


    if (shouldDispatch) {
      this.$log.info('social/getGroupLeaderboard');
      this.setLeaderboardState(['group', null]);

      await this.$store.dispatch('social/getGroupsLeaderboard');
      await this.$store.dispatch('social/getGroupLeaderboard', {
        groupId: this.$route.params.id,
      });
    }

    this.group = cloneDeep(this.getGroupById(this.$route.params.id));

    /**
     * Following ensures that when add participant/member modal is opened
     * contacts to be displayed are already present in the state.
     * Otherwise we see the jump on the modal as it starts empty and then gets filled
     */
    this.$log.info('Will check contacts permission', this.appState.contactsPermission);

    // if (this.appState.contactsPermission === 'true') {
    //   this.$log.info('Will call social/getContacts');
    //   await this.$store.dispatch('social/getContacts');
    // }

    /**
     * When Add participants button is clicked we check if contacts permission is true
     * if not we display the permission primary then request SDK to show the native dialog
     * then watch for contacts permission value and if becomes true then display the
     * relevant modal as user initially intended to add participants.
     */
    this.$watch('appState.contactsPermission', (val) => {
      if (val === 'true' && this.lastClicked === 'addParticipants') {
        this.isAddMemberModalDisplayed = true;
      }
    });
    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setLeaderboardState: 'social/setLeaderboardState',
    }),
    getDisplayName(member) {
      if (this.isSelfInGroup(member.id)) {
        return `${this.$t('txtSelf')} (${this.$t(`${this.userRole}Role`)})`;
      }

      if (!member.name) {
        return this.$t('missingContactName');
      }

      return member.name || undefined;
    },
    clickHandler(member) {
      this.$log.info('role', this.userRole);
      this.$log.info('member', member);
      this.$log.info('groupInfo', this.groupInfo);

      if (this.isSelfInGroup(member.id)) {
        return;
      }

      if (member.role === 'owner' && this.userRole === 'admin') {
        return;
      }

      if (this.userRole === 'admin' || this.userRole === 'owner') {
        this.$log.info('memberItem');

        this.isGroupMemberModalDisplayed = true;
        this.groupMemberModalData = { member, group: this.groupInfo };
      }
    },
    async addParticipantsHandler() {
      const { number, verified } = this.user.phone;

      this.lastClicked = 'addParticipants';

      if (!number && !verified) {
        this.$log.info('1');
        this.isPhoneVerificationModalDisplayed = true;
      } else if (!this.permissionGiven) {
        this.$log.info('2');
        this.$log.info('showContactsPermissionModal AllowContacts');

        this.permissionModalType = 'AllowContacts';
        this.isContactsPermissionModalDisplayed = true;
      } else if (!this.hasPermission) {
        this.$log.info('3');
        this.$log.info('showContactsPermissionModal ContactsSettings');

        this.permissionModalType = 'ContactsSettings';
        this.isContactsPermissionModalDisplayed = true;
      } else if (this.permissionGiven && this.hasPermission) {
        this.$log.info('4');
        this.isAddMemberModalDisplayed = true;
      }
    },
  },
};
</script>
