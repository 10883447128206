<template>
  <span
    class="d-flex align-items-start mb-4 bg-blue-lighter-30 p-3 rounded-16"
    >
    <div class="pr-3">
      <cc-svg-base svg="icons/icon-exclamation" />
    </div>
    <cc-text
      color="blue-lightest"
      >
      {{ $t(bodyTextKey) }}
    </cc-text>
  </span>
</template>

<script>
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccSvgBase,
  },
  props: {
    bodyTextKey: {
      type: String,
      default: 'recentTripsDisclosure',
    },
  },
};
</script>
