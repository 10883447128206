<template>
  <transition
    name="slide-up"
    mode="out-in"
    >
    <cc-wrapper
      id="fatal-error__header"
      class="w-100 vh-100 position-fixed bg-blue-darkest px-4 z-index-5"
      align="between"
      >
      <cc-wrapper
        class="w-100 py-2"
        direction="column"
        align="end"
        >
        <div style="flex: 0 0 24px;">
          <cc-button
            v-if="false"
            variant="icon"
            data-ruid="global__button-close"
            icon-color="svg-fill-white"
            icon="icons/icon-close"
            :block="false"
            on-close="closeWebView"
            />
        </div>
      </cc-wrapper>

      <cc-wrapper
        id="fatal-error__body"
        class="w-100 h-100"
        >
        <div>
          <ccLottie
            id="fatal-error"
            :options="animData.currentAnim"
            />
        </div>

        <cc-heading
          class="py-3"
          >
          {{ $t('fatalErrorTitle') }}
        </cc-heading>

        <cc-text align="center">
          {{ $t('fatalErrorParagraph') }}
        </cc-text>
      </cc-wrapper>
    </cc-wrapper>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import ccErrorAnimation from '@/components/lottie/error.json';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccLottie from '@/components/Lottie.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccWrapper,
    ccHeading,
    ccText,
    ccLottie,
    ccButton,
  },
  data() {
    return {
      path: null,
      animData: {
        currentAnim: {
          animationData: ccErrorAnimation,
        },
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    hasCloseAction() {
      const { appId } = this.appState;

      this.$log.info('appId', appId);

      if (appId === 'web') {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const { navigation } = this.kibanaEvents;

    this.sentryCaptureMessage('Fallback: Fatal Error is shown', 'error');

    try {
      this.$store.dispatch('common/insertEvent', {
        category: navigation.category,
        action: navigation.pageView.action,
        type: 'error-fatal-error',
      });
    } catch (error) {
      this.$log.info('common/insertEvent NOK');
    }
  },
  methods: { },
};
</script>
