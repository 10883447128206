<template>
  <cc-view
    :id="id"
    >
    <cc-card
      class="mb-6"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__terms-and-conditions`"
          :label="$t('txtTermsAndConditions')"
          arrow
          @click.native="$store.dispatch('common/openUrlInternal', termsAndConditionsURL)"
          />
        <cc-settings-list-item
          :ruid="`${id}__privacy-policy`"
          :label="$t('privacyPolicy')"
          arrow
          @click.native="
            $store.dispatch(
              'common/openUrlInternal',
              `${appState.webSiteURL}/${appState.userLang}/privacy-policy?internalBrowser=true`
            )"
          />

        <cc-settings-list-item
          :ruid="`${id}__faq`"
          :label="$t('txtFAQ')"
          arrow
          @click.native="
            $store.dispatch(
              'common/openUrlInternal',
              `${appState.webSiteURL}/${appState.userLang}/faq?internalBrowser=true`
            )"
          />

        <cc-settings-list-item
          :ruid="`${id}__credits`"
          :label="$t('txtCredits')"
          arrow
          last
          @click.native="$router.push({ name: 'credits' })"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__delete-account`"
          :label="$t('deleteAccountSectionTitle')"
          arrow
          last
          @click.native="$router.push({ name: 'delete-account' })"
          />
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccSettingsListItem,
  },
  data() {
    return {
      id: 'support',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    termsAndConditionsURL() {
      const { appState } = this;
      return `${appState.webSiteURL}/${appState.userLang}/terms-and-conditions?internalBrowser=true`;
    },
  },
};
</script>
