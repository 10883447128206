<template>
  <img
    v-if="isImageLoaded"
    :src="path"
    class="img-max"
    importance="high"
    alt="image"
    rel="preload"
    >
  <div
    v-else-if="hasPlaceholder"
    :class="placeholderClass"
    />
</template>

<script>
export default {
  props: {
    filePath: {
      type: String,
      required: false,
      default: undefined,
    },
    src: {
      type: String,
      required: false,
      default: undefined,
    },
    hasPlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholderClass: {
      type: String,
      default: 'bg-grey-lighter',
    },
  },
  data() {
    return {
      isImageLoaded: false,
    };
  },
  computed: {
    isExternalLink() {
      return this?.src?.startsWith('http');
    },

    path() {
      if (this.isExternalLink) {
        return this.src;
      }
      if (this.src) {
        const images = require.context('@/assets/', true, /\.png|.jpg$/);
        return images(`./${this.src}`);
      }
      return false;
    },
    getFilePath() {
      if (this.filePath) {
        const images = require.context('@/assets/', true, /\.png|.jpg$/);
        return images(`./${this.filePath}`);
      }
      return false;
    },
  },
  mounted() {
    const img = new Image();
    img.onload = this.onImageLoaded;
    img.src = this.path || '';
  },
  methods: {
    onImageLoaded() {
      this.isImageLoaded = true;
    },
  },
};
</script>
