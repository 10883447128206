<template>
  <cc-view
    :id="id"
    has-full-width
    class="container"
    >
    <div class="pb-8 d-flex flex-column h-100">
      <div class="pt-7 px-6 text-center">
        <cc-svg-base
          svg="illustrations/public-challenge-logo-2024"
          />

        <cc-text
          variant="body"
          class="flex-grow-1 pt-2 text-center"
          >
          {{ $t('publicGoalDate') }}
        </cc-text>
      </div>
      {{ /*  Progress Card */ }}
      <cc-public-goal-progress-card
        :is-progress-page="true"
        @loaded="loadedProgressCard=false"
        />

      {{ /* Co2 emission chart */ }}
      <cc-public-goal-progress-co2-emission @loaded="loadedProgressChart=false" />

      {{ /* Challenges */ }}
      <cc-public-goal-progress-challenges id="progress-challenge" />


      {{ /* Rewards */ }}
      <cc-public-goal-progress-rewards id="progress-rewards" />

      {{ /* Learn more */ }}
      <cc-public-goal-progress-news id="progress-news" />

      {{ /* Made possible by */ }}
      <cc-public-goal-possible-by v-if="!isLoading" />

      <div>
        <cc-svg-base
          class="w-100 h-100"
          style="left: 0; z-index: 10;"
          svg="illustrations/public-challenge-footer"
          />
      </div>
    </div>
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccPublicGoalProgressCard from '@/components/public-goal/PublicGoalProgressCard.vue';
import ccPublicGoalProgressCo2Emission from '@/components/public-goal/PublicGoalProgressCo2Emission.vue';
import ccPublicGoalProgressChallenges from '@/components/public-goal/PublicGoalProgressChallenges.vue';
import ccPublicGoalProgressRewards from '@/components/public-goal/PublicGoalProgressRewards.vue';
import ccPublicGoalProgressNews from '@/components/public-goal/PublicGoalProgressNews.vue';
import ccPublicGoalPossibleBy from '@/components/public-goal/PublicGoalPossibleBy.vue';

export default {
  components: {
    ccView,
    ccSvgBase,
    ccPublicGoalProgressCard,
    ccPublicGoalProgressCo2Emission,
    ccPublicGoalProgressChallenges,
    ccPublicGoalProgressRewards,
    ccPublicGoalProgressNews,
    ccPublicGoalPossibleBy,
  },
  data() {
    return {
      id: 'public-goal-progress',
      loadedProgressCard: true,
      loadedProgressChart: true,
    };
  },
  computed: {
    isLoading() {
      if (!this.loadedProgressCard && !this.loadedProgressChart) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$store.dispatch('user/setHasOpenedPublicGoal');
  },
};
</script>

<style scoped>
.container {
  overscroll-behavior: none;
}
</style>
