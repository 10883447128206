var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-navigation-bar',{attrs:{"id":_vm.id,"title":_vm.navigationBarTitle,"has-nav-shadow":_vm.hasNavShadow(_vm.$route)},scopedSlots:_vm._u([{key:"left",fn:function(){return [(_vm.$route.meta.hasAccountIcon)?_c('cc-button',{key:"settings",staticClass:"position-absolute",attrs:{"data-ruid":"global__button-settings","variant":"icon","block":false,"icon":"icons/icon-account"},on:{"click":function($event){_vm.$router.push({ name: 'settings' }).catch(() => {})}}}):(_vm.$route.meta.hasBackIcon)?_c('cc-button',{key:"back",staticClass:"position-absolute",attrs:{"variant":"icon","block":false,"icon":"icons/icon-back","data-ruid":"global__button-back"},on:{"click":function($event){_vm.$router
          .push({
            name: _vm.getBackRoute(
              _vm.$route,
              _vm.appState.routingFrom.name,
              _vm.isUserAuthenticated,
              _vm.historyState,
            ),
          })
          .catch(() => {})}}}):_vm._e()]},proxy:true},{key:"middle",fn:function(){return [(_vm.appState.hasTabBarToggler)?_c('div',{staticClass:"text-center",staticStyle:{"z-index":"1000"}},[_c('button',{staticClass:"bg-primary",on:{"click":function($event){return _vm.$store.dispatch('common/showTabBar', { route: _vm.$route })}}},[_vm._v(" showTabBar ")]),_c('button',{staticClass:"bg-black text-white",staticStyle:{"z-index":"1000"},on:{"click":function($event){return _vm.$store.dispatch('common/hideTabBar', { route: _vm.$route })}}},[_vm._v(" hideTabBar ")])]):_vm._e()]},proxy:true},{key:"right",fn:function(){return [(_vm.hasMessagesButton)?_c('span',{key:"messages",staticClass:"position-relative",class:[_vm.hasCloseButton ? 'mr-4' : null, _vm.getUnreadMessages.length ? 'bell-shake' : null]},[(_vm.getUnreadMessages.length)?_c('span',{staticClass:"bg-danger position-absolute rounded bell-active",staticStyle:{"width":"10px","height":"10px","right":"-2px"}}):_vm._e(),_c('cc-button',{attrs:{"variant":"icon","data-ruid":"global__button__messages","icon":"icons/icon-bell","block":false},on:{"click":function($event){return _vm.$router.push({ name: 'messages' })}}})],1):_vm._e(),(_vm.hasCloseButton)?_c('cc-button',{key:"close",attrs:{"variant":"icon","data-ruid":"global__button__close","icon-color":"svg-fill-white","icon":"icons/icon-close","block":false},nativeOn:{"click":function($event){return _vm.callSdk(`action=${_vm.actions.CLOSE}`)}}}):(_vm.hasGroupSettingsButton)?_c('cc-button',{key:"settings",attrs:{"variant":"icon","data-ruid":"global__button__group-settings","icon-color":"svg-fill-white","icon":"icons/icon-settings","block":false},on:{"click":function($event){return _vm.$router.push({ name: 'group-settings', params: { groupInfo: _vm.groupInfo } })}}}):_vm._e(),(_vm.hasPublicGoalButton)?_c('cc-button',{key:"info",attrs:{"variant":"icon","block":false,"icon":"icons/icon-info"},on:{"click":function($event){_vm.$router
          .push({
            name: 'public-goal-welcome',
            params: {
              backTo: 'public-goal-progress',
            },
          })
          .catch(() => {})}}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }