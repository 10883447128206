<template>
  <cc-modal
    data-ruid="modal__edit-trip"
    group-wrapper-class="h-75"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('editTripTitle')"
    :header-description="$t('editTripDescription')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div class="mt-3 px-5">
        <div
          v-for="transitMode in transitModes"
          :key="transitMode"
          :data-ruid="`modal__button-${transitMode}`"
          class="d-flex flex-row justify-content-around align-items-center pl-1 pr-5 py-4"
          :class="selectedTransitmode === transitMode ? 'bg-blue-darkest rounded-16' : ''"
          @click="selectTransitmode(transitMode)"
          >
          <div
            style="flex: 0 0 56px"
            class="d-flex justify-content-center mr-3"
            >
            <cc-svg-base
              :key="transitMode"
              :svg="`transport-icons/${transitModeIcon(transitMode)}`"
              />
          </div>

          <cc-text class="flex-grow-1 font-weight-sbold text-capitalize text-left">
            {{ $t(`${transitMode}Txt`) }}
          </cc-text>

          <div>
            <cc-svg-base
              v-if="selectedTransitmode === transitMode"
              color-class="svg-fill-primary"
              svg="icons/icon-check"
              />
          </div>
        </div>
      </div>
    </template>

    <!-- TODO: mobile scroll didn't work? -->
    <template #footer="{ close }">
      <div
        class="d-flex flex-column align-items-center justify-content-center w-100 pt-2 pb-5 px-5"
        >
        <div
          v-if="isSelectedTransitModeAllowedForCarSharing"
          class="mb-2 pb-3 pt-2 d-flex align-items-center justify-content-center gap-3
           rounded-16 w-100 px-3"
          >
          <cc-svg-base
            color-class="svg-fill-blue-white"
            svg="icons/icon-sdx-minus-box"
            class="flex-shrink-0"
            style="width:28px; height:28px;"
            @click.native="
              numberOfPeople > 1 ? (numberOfPeople -= 1) : numberOfPeople
            "
            />

          <cc-text
            class="d-inline text-center bg-white rounded-16 px-5 py-1"
            color="blue-darker"
            >
            {{ $t('numberOfPeopleInCar') }}
            {{ numberOfPeople }}
          </cc-text>

          <cc-svg-base
            color-class="svg-fill-blue-white"
            svg="icons/icon-sdx-plus-box"
            class="flex-shrink-0"
            style="width:28px; height:28px;"
            @click.native="
              numberOfPeople < 7 ? (numberOfPeople += 1) : numberOfPeople
            "
            />
        </div>

        <div
          v-if="errorMessage"
          data-ruid="global__validation-message"
          class="is-invalid pb-3"
          >
          {{ errorMessage }}
        </div>

        <cc-button
          data-ruid="global__button-confirm"
          :is-modal-button="true"
          :disabled="awaiting ||
            (selectedTransitmode === initialTransitmode && numberOfPeople === data.numberOfPeople )"
          text="Save"
          @click="reportTimelineError(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { filterDisplayTypes, displayTransportTypes } from '@/mixins/transportTypes';
import { seperateTransportTypeAndFuelType } from '@/mixins/utils';
import trips from '@/services/trips';

import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccModal from '@/components/modals/Modal.vue';

export default {
  components: {
    ccText,
    ccButton,
    ccSvgBase,
    ccModal,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      initialTransitmode: null,
      selectedTransitmode: null,
      errorMessage: '',
      numberOfPeople: 0,
      awaiting: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      tripCorrections: state => state.trips.tripCorrections,
      user: state => state.user.user,
    }),
    transitModes() {
      // const array = [];
      // array = array.concat(this.transportTypes.humanPowered);
      // array = array.concat(this.transportTypes.electric);
      // array = array.concat(this.transportTypes.fossil);
      const displayTypesFiltered = filterDisplayTypes(displayTransportTypes);

      return displayTypesFiltered;
    },
    transitModesAllowedForCarSharing() {
      return this.transitModes.filter((transitMode) => {
        const transitObject = seperateTransportTypeAndFuelType(transitMode);
        return transitObject.transportType === 'car' && transitObject.fuelType !== 'motorbike';
      });
    },
    isSelectedTransitModeAllowedForCarSharing() {
      return this.transitModesAllowedForCarSharing.includes(
        this.selectedTransitmode || this.initialTransitmode,
      );
    },
  },
  mounted() {
    this.selectedTransitmode = this.displayString(this.data.displayType);
    this.initialTransitmode = this.displayString(this.data.displayType);
    this.numberOfPeople = this.data.numberOfPeople || 1;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setTripsState: 'trips/setState',
    }),
    transitModeIcon(transitMode) {
      return transitMode.split('Medium').join('').split('Compact').join('');
    },
    selectTransitmode(transitMode) {
      this.selectedTransitmode = transitMode;
    },
    displayString(displayType) {
      return `${displayType.transitMode}_${displayType.emissionType}`;
    },
    reportTimelineError(closeModal) {
      if (this.selectedTransitmode !== null) {
        this.errorMessage = '';

        this.awaiting = true;

        const transitObject = seperateTransportTypeAndFuelType(this.selectedTransitmode);

        /**
         * Save counts of trip corrections per transport type
         * For now we are only interested in of type car
         */

        this.setTripsState([
          'tripCorrections',
          {
            ...this.tripCorrections,
            [transitObject.transportType]: {
              ...this.tripCorrections[transitObject.transportType],
              count: (this.tripCorrections[transitObject.transportType]?.count || 0) + 1 || 1,
            },
          },
        ]);

        trips
          .updateTrip(
            this.data.id,
            transitObject.transportType,
            transitObject.fuelType,
            this.numberOfPeople,
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch('trips/getTimeline', { forceUpdate: true });

              this.awaiting = false;

              closeModal(() => {
                if (
                  this.tripCorrections?.car?.count >= 2
                  && !this.tripCorrections?.car?.isVehiclePreferenceModalDisplayed
                ) {
                  this.$emit('after-leave', 'vehicle-preference-modal');

                  this.setTripsState([
                    'tripCorrections',
                    {
                      ...this.tripCorrections,
                      [transitObject.transportType]: {
                        ...this.tripCorrections[transitObject.transportType],
                        isVehiclePreferenceModalDisplayed: true,
                      },
                    },
                  ]);
                }
              });
            }, 3000);
          })
          .catch((err) => {
            if (err?.response?.status === 429) {
              this.errorMessage = this.$t('tooManyRequestsErrorMessage');
            } else {
              this.errorMessage = this.$t('generalErrorMessage');
            }
            this.sentryCaptureMessage(`reportTimelineError: ${err}`, 'error');
            this.awaiting = false;
          });
      }
    },
  },
};
</script>
