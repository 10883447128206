<template>
  <cc-modal
    data-ruid="modal__change-delivery-address"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('changeDeliveryAddress')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column"
        >
        <div
          v-for="address in getAddresses.sort((a,b) => b.isDefault - a.isDefault)"
          :key="address.id"
          >
          <cc-wrapper
            direction="column"
            align="between"
            justify="start"
            @click.native="$emit('update-selected-address', address)"
            >
            <div class="d-flex align-items-center">
              <cc-heading
                tag="h3"
                variant="h4"
                class="mr-3"
                >
                {{ address.firstName }} {{ address.lastName }}
              </cc-heading>
            </div>

            <div class="d-flex justify-content-between pt-2">
              <div>
                <cc-text>
                  {{ address.streetName }} {{ address.houseNumber }}
                </cc-text>
                <cc-text>{{ address.postalCode }} {{ address.city }}</cc-text>
              </div>
              <label
                class="radio-container"
                for="radio"
                >
                <input
                  type="radio"
                  :checked="data.selectedAddress.id === address.id"
                  disabled
                  >
                <span class="radio-checkmark" />
              </label>
            </div>
          </cc-wrapper>
          <cc-divider
            color="blue-light"
            class="my-4"
            />
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex flex-column w-100 px-5 py-2">
        <cc-button
          v-if="hasAddress"
          data-ruid="global__button__confirm-delivery-address"
          class=""
          :text="$t('selectAddress')"
          @click="close(() => {
            $emit('after-leave', 'confirm-delivery-address-modal')
          })"
          />
        <cc-button
          data-ruid="global__button__add-delivery-address"
          :variant="hasAddress ? 'transparent' : null"
          :class="hasAddress ? 'mt-2' : null"
          :text="$t('addDeliveryAddress')"
          @click="close(() => {
            $emit('after-leave', 'add-delivery-address-modal')
          })"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccHeading,
    ccText,
    ccButton,
    ccModal,
    ccWrapper,
    ccDivider,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'change-delivery-address-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      user: state => state.user.user,
    }),
    ...mapGetters({
      hasAddress: 'user/hasAddress',
      getAddresses: 'user/getAddresses',
    }),
  },
  created() {},
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
  },
};
</script>
