<template>
  <cc-view
    :id="id"
    >
    <cc-text variant="body-sm">
      {{ $t('WebAppVersion') }}: {{ webAppVersion }}
    </cc-text>

    <cc-text variant="body-sm">
      {{ $t('nativeAppVersion') }}: {{ appState.nativeAppVersion || '-' }}
    </cc-text>

    <cc-text variant="body-sm">
      {{ $t('SDKVersion') }}: {{ appState.sdkVersion || '-' }}
    </cc-text>

    <cc-text variant="body-sm">
      {{ $t('tripDataSdkVersion') }}: {{ appState.tripDataSdkVersion || '-' }}
    </cc-text>

    <cc-text
      variant="body-sm"
      @click.native.prevent="copyUid(userState.user.id)"
      >
      {{ 'UserID' }}: {{ showCopySuccess ? 'Copied user id to clipboard' : userState.user.id }}
    </cc-text>


    <cc-divider class="my-6" />

    <cc-heading class="pb-4">
      {{ $t('SccTitle') }}:
    </cc-heading>

    <cc-text variant="body-sm">
      {{ $t('SwisscomAddress') }}
      <br>
      {{ $t('txtSupportQuestion') }}
    </cc-text>

    <cc-button
      variant="link"
      justify="start"
      @click="openEmailClient"
      >
      info@swissclimatechallenge.ch
    </cc-button>
  </cc-view>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccView,
    ccText,
    ccHeading,
    ccButton,
    ccDivider,
  },
  data() {
    return {
      id: 'about-scc',
      showCopySuccess: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
    ...mapGetters({
      webAppVersion: 'common/webAppVersion',
    }),
  },
  methods: {
    openEmailClient() {
      this.callSdk(
        `action=${this.actions.OPEN_EXTERNAL}&url=${encodeURI(
          'mailto:info@swissclimatechallenge.ch',
        )}`,
      );
    },
    copyUid(uid) {
      const textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = uid;
      document.body.appendChild(textArea);

      if (this.appState.os === 'ios') {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 100);
      } else {
        textArea.select();
      }

      try {
        const success = document.execCommand('copy');
        if (success) {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        }
      } catch (err) {
        this.$log.info('Something went wrong copying the UserID');
      }

      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
  },
};
</script>
