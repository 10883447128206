<template>
  <transition
    name="slide-up"
    mode="out-in"
    >
    <cc-wrapper
      id="no-connection__header"
      class="w-100 vh-100 position-fixed left-0 bg-blue-darkest px-4 z-index-5"
      align="between"
      >
      <cc-wrapper
        id="no-connection__body"
        class="w-100 h-100"
        >
        <cc-svg-base
          tag="i"
          class="mb-5"
          :class="appState.screenOrientation === '90' ? 'horizontal-flip' : ''"
          svg="phone-icon"
          />

        <cc-text align="center">
          {{ $t('landscapeOrientationError') }}
        </cc-text>
      </cc-wrapper>
    </cc-wrapper>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

import ccSvgBase from '@/components/SvgBase.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccSvgBase,
    ccWrapper,
    ccText,
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    hasCloseAction() {
      const { appId } = this.appState;
      this.$log.info('appId', appId);
      if (appId === 'web') {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const { navigation } = this.kibanaEvents;

    this.sentryCaptureMessage('Fallback: Landscape not supported is shown', 'info');

    try {
      this.$store.dispatch('common/insertEvent', {
        category: navigation.category,
        action: navigation.pageView.action,
        type: 'error-landscape-not-supported',
      });
    } catch (error) {
      this.$log.info('common/insertEvent NOK');
    }
  },
};
</script>
