<template>
  <p
    class="m-0"
    :class="[getClass, getTextAlign]"
    >
    <slot />
  </p>
</template>

<script>
// Valid variants
const variantProps = [
  'body',
  'body-muted',
  'body-bold',
  'body-sm',
  'body-sm-bold',
  'body-md-bold',
  'body-xs',
  'body-xs-bold',
  'body-xl',
];

const colorProps = [
  'white',
  'warning',
  'primary',
  'danger',
  'cc-yellow-darkest',
  'cc-yellow-darker',
  'cc-yellow',
  'cc-light-silver',
  'cc-light-bronze',
  'blue-lightest',
  'blue-darker',
  'muted',
  'grey-dark',
  'grey-light',
  'grey-lighter',
  'blue-darkest',
];

const alignProps = [
  'left', //
  'center',
  'right',
];

export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: 'body',
      validator: value => variantProps.includes(value),
    },
    color: {
      type: String,
      required: false,
      default: 'white',
      validator: value => colorProps.includes(value),
    },
    align: {
      type: String,
      required: false,
      default: null,
      validator: value => alignProps.includes(value),
    },
  },
  computed: {
    getTextAlign() {
      return [
        // Left alignment is default, declared here only to override if needed
        this.align === 'left' ? 'text-left' : null,
        this.align === 'center' ? 'text-center' : null,
        this.align === 'right' ? 'text-right' : null,
      ];
    },
    getClass() {
      return [
        this.variant === 'body-muted' ? 'text-blue-lightest' : null,
        this.variant === 'body-bold' ? 'font-weight-bold' : null,
        this.variant === 'body-sm' ? 'font-size-14' : null,
        this.variant === 'body-sm-bold' ? 'font-size-14 font-weight-bold' : null,
        this.variant === 'body-md-bold' ? 'font-size-16 font-weight-bold' : null,
        this.variant === 'body-xs' ? 'font-size-12' : null,
        this.variant === 'body-xl' ? 'font-size-24' : null,
        this.variant === 'body-xs-bold' ? 'font-size-12 font-weight-bold' : null,
        this.color ? `text-${this.color}` : null,
      ];
    },
  },
};
</script>
