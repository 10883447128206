<template>
  <cc-modal
    data-ruid="modal__confirm-account-deletion"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-4 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('deleteAccountTitle')"
          svg="illustrations/group-deletion"
          />

        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ $t('groupLeaderboardDeleteGroupDescription' ) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="px-5 d-flex justify-content-center">
        <cc-error-message
          v-if="errorMsg"
          :message="errorMsg"
          />
      </div>
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :is-modal-button="true"
          class="mr-1"
          data-ruid="global__button-remove"
          variant="danger"
          :text="$t('txtDelete')"
          @click="deleteUserAccount(close)"
          />
        <cc-button
          :is-modal-button="true"
          variant="secondary"
          class="ml-1"
          data-ruid="global__button-confirm"
          :text="$t('txtCancel')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
    ccErrorMessage,
  },
  data() {
    return {
      id: 'confirm-account-deletion-modal',
      errorMsg: null,
    };
  },
  methods: {
    async deleteUserAccount(closeModal) {
      this.errorMsg = null;
      try {
        await this.$store.dispatch('user/deleteUserAccount');
        await this.$store.dispatch('user/signOut');
        closeModal(() => {
          this.$router.push({ name: 'start' });
        });
      } catch (error) {
        this.errorMsg = error.message;
        this.sentryCaptureMessage(`User account deletion error: ${error.message}`);
      }
    },
  },
};
</script>
