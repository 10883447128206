<template>
  <cc-card
    :id="id"
    :ruid="id"
    variant="plain"
    button-variant="small-muted"
    :inner-title="$t('yourJourneysSectionTitle')"
    :padded="false"
    :pressable="true"
    class="pb-4"
    @click.native="$router.push({ name: 'journeys' }).catch(() => {})"
    >
    <template #body>
      <cc-charts-loading
        v-if="areTripsFetching && (appState.filter !== 'today')"
        class="chart-loading-container"
        />

      <cc-doughnut-chart
        v-if="appState.filter === 'today'"
        key="1"
        class="py-6 chart-loading-opacity"
        />

      <cc-stacked-bar-chart
        v-else-if="appState.filter === 'week'"
        key="2"
        class="py-6 chart-loading-opacity"
        :style="`opacity: ${chartsOpacity};max-width: 90%;`"
        />

      <cc-line-chart
        v-else-if="appState.filter === 'year' || appState.filter === 'month'"
        key="3"
        class="py-6 chart-loading-opacity"
        :style="`opacity: ${chartsOpacity};max-width: 90%;`"
        />

      <cc-chart-legend class="mx-5 pb-5" />

      <cc-transport-cards />
    </template>
  </cc-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';

import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccDoughnutChart from '@/components/DoughnutChart.vue';
import ccStackedBarChart from '@/components/StackedBarChart.vue';
import ccLineChart from '@/components/LineChart.vue';
import ccChartLegend from '@/components/ChartLegend.vue';
import ccTransportCards from '@/components/TransportCards.vue';

export default {
  components: {
    ccCard,
    ccChartsLoading,
    ccDoughnutChart,
    ccStackedBarChart,
    ccLineChart,
    ccChartLegend,
    ccTransportCards,
  },
  data() {
    return {
      id: 'journeys-card',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      areTripsFetching: 'common/areTripsFetching',
    }),
    chartsOpacity() {
      if (this.areTripsFetching) {
        return '40%';
      }
      return '100%';
    },
  },
};
</script>
