<template>
  <cc-view
    :id="id"
    :has-overflow-scroll="true"
    :has-full-width="false"
    :has-body-scroll="true"
    >
    <cc-charts-loading
      v-if="isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <template v-else>
      <cc-news-feed-timeline :limit="-1" />
    </template>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ccView from '@/components/constructs/View.vue';
// import ccCard from '@/components/constructs/Card.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccNewsFeedTimeline from '@/components/footprint/NewsFeedTimeline.vue';

export default {
  components: {
    ccView,
    // ccCard,
    ccChartsLoading,
    ccNewsFeedTimeline,
  },
  data() {
    return {
      id: 'news-feed',
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      hasNewsFeed: 'social/hasNewsFeed',
    }),
  },
  async mounted() {
    if (!this.hasNewsFeed) {
      await this.$store.dispatch('social/getNewsFeed');
    }

    if (this.appState.userLang !== this.appState.newsFeedLang) {
      await this.$store.dispatch('social/getNewsFeed');
    }
    this.isLoading = false;
  },
};
</script>

<style>

</style>
