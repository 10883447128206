<template>
  <div>
    <cc-svg-base
      v-if="earthState === 'happy'"
      :key="'happy'"
      svg="icons/icon-happy-earth-indicator"
      />
    <cc-svg-base
      v-else-if="earthState === 'sad'"
      :key="'sad'"
      svg="icons/icon-sad-earth-indicator"
      />
    <cc-svg-base
      v-else-if="earthState === 'lifeless'"
      :key="'lifeless'"
      svg="icons/icon-lifeless-earth-indicator"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccSvgBase,
  },
  computed: {
    ...mapState({
      temperature: state => state.trips.temperature,
    }),
    earthState() {
      if (this.temperature <= 2) {
        return 'happy';
      }
      if (this.temperature > 2 && this.temperature <= 3) {
        return 'sad';
      }
      return 'lifeless';
    },
  },
};
</script>
