<template>
  <cc-card
    :id="id"
    :ruid="id"
    :variant="variant"
    button-variant="small-muted"
    :inner-title="innerTitle || innerText"
    :padded="false"
    :pressable="pressable"
    :has-card-background="hasCardBackground"
    :has-shadow="hasShadow"
    @click.native="$router.push({ name: 'climate-change' }).catch(() => {})"
    >
    <template #body>
      <div>
        <!-- Outsource this as component -->
        <div class="d-flex justify-content-between">
          <cc-heading
            variant="h4"
            class="pb-2"
            >
            {{ $t('climateChangeCO2Title') }}
          </cc-heading>

          <cc-heading
            variant="h4"
            class="pb-2"
            >
            {{ formatting }}
          </cc-heading>
        </div>

        <div class="d-flex justify-content-between">
          <cc-text
            variant="body-sm-bold"
            color="muted"
            class="w-50"
            >
            {{ $t('climateChangeCO2Description') }}
          </cc-text>

          <div class="d-flex flex-column align-items-end">
            <cc-heading
              tag="h3"
              variant="h2"
              color="danger"
              class="line-height-reset"
              >
              {{ formatCo2(totalCo2) }}
            </cc-heading>

            <cc-text
              variant="body-sm-bold"
              color="muted"
              >
              {{ co2Prefix(totalCo2) }} {{ $t('climateChangeCO2Amount') }}
            </cc-text>
          </div>
        </div>

        <cc-charts-loading
          v-if="areTripsFetching"
          class="chart-loading-container my-4"
          />

        <cc-emission-bar-chart
          v-if="appState.filter === 'today' || appState.filter === 'week'"
          class="pt-6"
          :style="`opacity: ${chartsOpacity}`"
          />

        <cc-emission-line-chart
          v-else-if="appState.filter === 'month' || appState.filter === 'year'"
          class="pt-6"
          :style="`opacity: ${chartsOpacity}`"
          />
      </div>
    </template>
  </cc-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { co2Prefix, formatCo2 } from '@/mixins/utils';

import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccCard from '@/components/constructs/Card.vue';

import ccEmissionBarChart from '@/components/EmissionBarChart.vue';
import ccEmissionLineChart from '@/components/EmissionLineChart.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';

export default {
  components: {
    ccHeading,
    ccText,
    ccCard,
    ccEmissionBarChart,
    ccEmissionLineChart,
    ccChartsLoading,
  },
  props: {
    innerTitle: {
      type: String,
      required: false,
      default: '',
    },
    innerText: {
      type: String,
      required: false,
      default: '',
    },
    pressable: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasCardBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      id: 'co2-overview',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      chartData: state => state.trips.chartData,
    }),
    ...mapGetters({
      areTripsFetching: 'common/areTripsFetching',
      totalCo2: 'trips/totalCo2',
    }),
    chartsOpacity() {
      if (this.areTripsFetching) {
        return '40%';
      }
      return '100%';
    },
    formatting() {
      switch (this.appState.filter) {
        case 'today':
          return this.$moment(this.appState.queryDate).calendar(null, {
            sameDay: `[${this.$t('Today')}]`,
            nextDay: `[${this.$t('Tomorrow')}]`,
            nextWeek: 'dddd',
            lastDay: `[${this.$t('Yesterday')}]`,
            lastWeek: `${this.$t('LastDDDD')}`,
            sameElse: 'DD/MM/YYYY',
          });
        case 'week':
          return `${this.$moment(this.appState.queryDate)
            .startOf('isoWeek')
            .format('D')} - ${this.$moment(this.appState.queryDate)
            .endOf('isoWeek')
            .format('D MMM')}`;
        case 'month':
          return this.$moment(this.appState.queryDate).format('MMMM YYYY');
        case 'year':
          return this.$moment(this.appState.queryDate).format('YYYY');
        default:
          return '';
      }
    },
  },
  methods: {
    formatCo2,
    co2Prefix,
  },
};
</script>
