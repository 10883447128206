<template>
  <cc-modal
    data-ruid="modal__confirm-group-deletion"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('groupLeaderboardDeleteGroupTitle', { groupName: data.group.name })"
          svg="illustrations/group-deletion"
          />

        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ $t('groupLeaderboardDeleteGroupDescription' ) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :is-modal-button="true"
          class="mr-1"
          data-ruid="global__button-remove"
          variant="danger"
          :text="$t('txtDelete')"
          @click="deleteGroup(close)"
          />
        <cc-button
          :is-modal-button="true"
          variant="secondary"
          class="ml-1"
          data-ruid="global__button-confirm"
          :text="$t('txtCancel')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'confirm-group-deletion-modal',
      group: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
    }),
    ...mapGetters({
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async deleteGroup(closeModal) {
      // TODO: Add error message component to ui and bind here
      try {
        await this.$store.dispatch('social/deleteGroup', this.$route.params.id);
        await this.$store.dispatch('social/getGroupsLeaderboard', true);

        this.$log.info('hasGroupLeaderboard', this.hasGroupLeaderboard);

        closeModal(() => {
          const groupDeletedConfirmationModal = {
            event: 'group-deleted-confirmation-modal',
            name: this.data.group.name,
          };

          if (this.hasGroupLeaderboard) {
            this.$router.push({
              name: 'group-leaderboard',
              params: {
                groupDeletedConfirmationModal,
              },
            });
          } else {
            this.$router
              .push({
                name: 'join-group-leaderboard',
                params: {
                  groupDeletedConfirmationModal,
                },
              })
              .catch(() => {});
          }
        });
      } catch (error) {
        this.$log.warn('deleteGroup error', error);
      }
    },
  },
};
</script>
