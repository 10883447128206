<template>
  <div class="d-flex flex-column justify-content-center mx-5 px-4">
    <div
      class="position-absolute overflow-hidden w-60 mx-auto"
      style="height: 180px;left: 0px;right: 0px;"
      >
      <transition name="slide-left">
        <div
          id="doughnut-data"
          :key="appState.queryDate.stringify"
          class="d-flex flex-column justify-content-center align-items-center
            position-absolute w-100 h-100"
          style="left:0;right:0;"
          >
          <cc-text
            variant="body-sm-bold"
            color="muted"
            class="text-center font-weight-sbold"
            >
            {{ calendarDay(appState.queryDate) }}
          </cc-text>

          <cc-heading
            color="blue-lighter"
            class="text-center"
            >
            {{ totalKm }}
          </cc-heading>
        </div>
      </transition>
    </div>

    <doughnut-chart
      :chart-data="datacollection"
      :options="options"
      class="position-relative"
      />
  </div>
</template>
<script>
import { mapState } from 'vuex';

import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';

import DoughnutChart from '../charts/DoughnutChart';

export default {
  components: {
    ccHeading,
    ccText,
    DoughnutChart,
  },
  data() {
    return {
      datacollection: {},
      options: {},
      data: [],
    };
  },
  computed: {
    ...mapState({
      timeline: state => state.trips.timeline,
      appState: state => state.common.appState,
      chartData: state => state.trips.chartData,
    }),
    totalKm() {
      const { appState, distanceText, chartData } = this;
      let result = null;
      if (chartData !== null) {
        result += chartData.summary.totalDistance;
      }
      if (appState.tripsProcessing === false && chartData === null) {
        const queryDate = new Date(this.appState.queryDate);
        if (queryDate.getDate() === new Date().getDate()) {
          return this.$t('txtNoDataYet');
        }
        return this.$t('txtNoData');
      }
      return distanceText(result);
    },
    noDataColor() {
      if (this.$route.name === 'journeys') {
        return '#0057DF';
      }
      return '#003FC4';
    },
  },
  mounted() {
    this.fillData();
    this.$watch('chartData', () => {
      if (this.appState.tripsProcessing === false) {
        this.fillData();
      }
    }, { deep: true });
  },
  methods: {
    calendarDay(queryDate) {
      return this.$moment(queryDate).calendar(null, {
        sameDay: `[${this.$t('Today')}]`,
        nextDay: `[${this.$t('Tomorrow')}]`,
        nextWeek: 'dddd',
        lastDay: `[${this.$t('Yesterday')}]`,
        lastWeek: `${this.$t('LastDDDD')}`,
        sameElse: 'DD/MM/YYYY',
      });
    },
    distanceText(distance) {
      if (!distance) {
        const queryDate = new Date(this.appState.queryDate);
        if (queryDate.getDate() === new Date().getDate()) {
          return this.$t('txtNoDataYet');
        }
        return this.$t('txtNoData');
      }
      if (distance >= 1000) {
        const kilometers = (distance / 1000).toFixed(1);
        return `${kilometers} km`;
      }
      return `${distance} m`;
    },
    fillData() {
      this.datacollection = {
        labels: ['Human-powered', 'Electric', 'Fossil', 'noData'],
        datasets: [
          {
            backgroundColor: [
              '#7bff8e',
              '#ffda53',
              '#ff8484',
              this.noDataColor,
            ],
            data: this.chartData.humanPowered
              .concat(this.chartData.electric, this.chartData.fossil, this.chartData.noData),
            borderWidth: 0,
          },
        ],
        responsive: true,
        distanceText: true,
      };
      this.options = {
        donutPlaceHolder: {
          color: this.noDataColor,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enalbed: false,
        },
        cutoutPercentage: 70,
        rotation: 4.55,
        animation: {
          duration: 700,
        },
      };
    },
  },
};
</script>
