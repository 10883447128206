<template>
  <cc-card
    :id="id"
    :ruid="id"
    button-variant="small-muted"
    :inner-title="$t('climateChangeSectionTitle')"
    :padded="false"
    :pressable="true"
    class="pb-4"
    @click.native="$router.push({ name: 'climate-change' }).catch(() => {})"
    >
    <template #body>
      <cc-horizontal-thermometer class="pt-4 pb-3" />
    </template>
  </cc-card>
</template>

<script>
import ccHorizontalThermometer from '@/components/HorizontalThermometer.vue';
import ccCard from '@/components/constructs/Card.vue';

export default {
  components: {
    ccHorizontalThermometer,
    ccCard,
  },
  data() {
    return {
      id: 'climate-change-card',
    };
  },
};
</script>
