<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="$t('notificationReminderTitle')"
    :pressable="true"
    :button-label="getButtonLabel"
    class="pb-4"
    inner-title-color="cc-yellow-darkest"
    button-variant="small-warning"
    card-background="cc-yellow"
    @click.native="handlePermission"
    >
    <template #body>
      <cc-wrapper direction="row">
        <div
          v-if="false"
          class="pr-3"
          >
          <cc-svg-base :svg="getSVGPath" />
        </div>

        <cc-text
          variant="body"
          color="cc-yellow-darker"
          >
          {{ $t('notificationReminderDescription') }}
        </cc-text>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccSvgBase,
    ccText,
  },
  data() {
    return {
      id: 'notification-reminder',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      hasSDKVersion: 'common/hasSDKVersion',
      hasAndroidAPIVersion: 'common/hasAndroidAPIVersion',
      activePlayerChallenges: 'achievements/activePlayerChallenges',
    }),
    getSVGPath() {
      const { notificationPermission, os } = this.appState;

      if (notificationPermission === 'false') {
        return 'illustrations/notification-reminder-toggle';
      }

      if (os === 'ios' && notificationPermission === 'undefined') {
        return 'illustrations/notification-reminder';
      }

      if (os === 'android' && notificationPermission === 'undefined') {
        return 'illustrations/notification-reminder-toggle';
      }

      return 'illustrations/notification-reminder';
    },
    getButtonLabel() {
      const { notificationPermission, os } = this.appState;
      const { hasAndroidAPIVersion, hasSDKVersion } = this;

      if (os === 'ios') {
        if (notificationPermission === 'false') {
          return this.$t('txtOpenSettings');
        }

        if (notificationPermission === 'undefined') {
          return this.$t('txtAllow');
        }
      }

      /**
       * Starting from Android API 33 && SDK version 1.12.0
       * we need to request a push notification permission
       */
      if (os === 'android') {
        if (hasAndroidAPIVersion('33') && hasSDKVersion('1.12.0')) {
          if (notificationPermission === 'undefined') {
            return this.$t('txtAllow');
          }

          if (notificationPermission === 'false') {
            return this.$t('txtOpenSettings');
          }
        }

        if (notificationPermission === 'undefined') {
          return this.$t('txtOpenSettings');
        }
      }

      return this.$t('txtAllow');
    },
  },
  mounted() {
    const { navigation } = this.kibanaEvents;

    try {
      this.$store.dispatch('common/insertEvent', {
        category: navigation.category,
        action: navigation.conditionalContentDisplayed.action,
        type: 'notification-hint',
      });
    } catch (error) {
      this.$log.info('common/insertEvent NOK');
    }
  },
  methods: {
    handlePermission() {
      const {
        REQUEST_NOTIFICATION_PERMISSION,
        GO_TO_APP_SETTINGS,
        GO_TO_NOTIFICATIONS_SETTINGS,
      } = this.actions;

      const { hasAndroidAPIVersion, hasSDKVersion } = this;

      const { notificationPermission, os } = this.appState;

      /**
       * Mind that if notifications toggled off by the user or the permission request rejected
       * iOS returns `false`
       * Android returns `undefined`
       */

      if (os === 'ios') {
        if (notificationPermission === 'false') {
          return this.callSdk(`action=${GO_TO_APP_SETTINGS}`);
        }

        if (notificationPermission === 'undefined') {
          return this.callSdk(`action=${REQUEST_NOTIFICATION_PERMISSION}`);
        }
      }

      /**
       * Starting from Android API 33 && SDK version 1.12.0
       * we need to request a push notification permission
       */
      if (os === 'android') {
        if (hasAndroidAPIVersion('33') && hasSDKVersion('1.12.0')) {
          if (notificationPermission === 'undefined') {
            return this.callSdk(`action=${REQUEST_NOTIFICATION_PERMISSION}`);
          }

          if (notificationPermission === 'false') {
            return this.callSdk(`action=${GO_TO_NOTIFICATIONS_SETTINGS}`);
          }
        }

        // We might still need to handle users in api 33 but without sdk 1.12.0
        // They will possibly see the notification card but no action will take place upon tapping

        if (notificationPermission === 'undefined') {
          return this.callSdk(`action=${GO_TO_NOTIFICATIONS_SETTINGS}`);
        }
      }

      return false;
    },
  },
};
</script>
