<template>
  <portal to="modals">
    <div
      :class="getPosition"
      :data-ruid="dataRuid"
      class="flex-column d-flex modal z-index-5 w-100 h-100 align-items-center"
      >
      <transition
        v-if="showContent"
        :name="transitionName"
        appear
        class=""
        @before-leave="enableScrollWhenClosed()"
        >
        <div
          :class="[getDialogPadding, groupWrapperClass]"
          class="overflow-hidden d-flex flex-column w-100 position-relative safe-area-inset-top"
          >
          <!-- Safari fix for radius clipping -->
          <!-- REMARK: Not sure if the inherited flex alignments below are really necessary -->
          <div
            class="overflow-hidden position-relative z-index-1 w-100 modal-width
            d-flex flex-column align-self-center justify-content-between align-items-center"
            :class="[groupClass, getCornerRadius]"
            >
            <div
              class="d-flex flex-row justify-content-between align-items-center
              flex-shrink-0 w-100"
              >
              <slot
                name="header"
                :close="close"
                >
                <!-- <cc-heading
                variant="h4"
                >
                {{ heading }}
              </cc-heading> -->

                <div
                  v-if="!$slots.header"
                  class="d-flex flex-column w-100 px-5"
                  >
                  <div class="d-flex flex-row justify-content-between pt-5 pb-3">
                    <cc-heading
                      v-if="headerTitle"
                      variant="h4"
                      >
                      {{ headerTitle }}
                    </cc-heading>

                    <div
                      class="flex-grow-1 d-flex align-self-start justify-content-end"
                      >
                      <cc-button
                        data-ruid="global__button-close"
                        :is-modal-button="true"
                        variant="icon"
                        :block="false"
                        icon-color="svg-fill-white"
                        icon="icons/icon-close"
                        @click="close(null)"
                        />
                    </div>
                  </div>

                  <cc-text v-if="headerDescription">
                    {{ headerDescription }}
                  </cc-text>
                </div>
              </slot>
            </div>

            <div
              id="scrollableComponent"
              class="w-100 overflow-scroll-y flex-grow-1 d-flex flex-column"
              :class="noMargin ? 'mt-0' : 'mt-4'"
              >
              <slot
                name="body"
                :close="close"
                >
                {{ body }}
              </slot>
            </div>

            <div
              v-if="hasFooter"
              class="w-100"
              :class="position === 'bottom' ? 'safe-area-inset-bottom-half' : null"
              >
              <!-- <div
                v-if="!$slots.footer && hasDefaultFooter"
                id="slot__footer-default"
                class="w-100 px-5 py-5"
                >
                <cc-button
                  variant="secondary"
                  text="Close"
                  @click.native="close(null)"
                  />
              </div> -->

              <slot
                name="footer"
                :close="close"
                />
            </div>
          </div>
        </div>
      </transition>

      <transition
        v-if="showBackdrop"
        name="modal-fade"
        appear
        @after-enter="disableScrollWhenDisplayed()"
        @after-leave="afterLeave"
        >
        <div
          id="modal-backdrop"
          class="position-fixed w-100 h-100 overflow-y-auto"
          >
          <div
            class="bg-black opacity-50 h-100"
            @click="close(null)"
            />
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
import { mapState } from 'vuex';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
// import { kebabize } from '@/mixins/utils';

const positionVariants = ['center', 'bottom'];

export default {
  components: {
    ccHeading,
    ccText,
    ccButton,
  },
  props: {
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataRuid: {
      type: String,
      default: null,
      required: false,
    },
    modalName: {
      type: String,
      default: null,
      required: false,
    },
    headerTitle: {
      type: String,
      default: 'Modal title',
      required: false,
    },
    headerDescription: {
      type: String,
      required: false,
      default: null,
    },
    body: {
      type: String,
      default: 'Simple body text',
      required: false,
    },
    hasDefaultFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    position: {
      type: String,
      required: false,
      default: 'center',
      validator: value => positionVariants.includes(value),
    },
    transitionName: {
      type: String,
      default: 'slide-up',
      required: false,
    },
    groupClass: {
      type: [String, Array],
      required: false,
      default: 'bg-white',
    },
    groupWrapperClass: {
      type: [String, Array],
      required: false,
      default: null,
    },
    afterClose: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      showContent: true,
      showBackdrop: true,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    getCornerRadius() {
      return [
        this.position === 'center' ? 'rounded-16' : null,
        this.position === 'bottom' ? 'top-rounded-16' : null,
      ];
    },
    getPosition() {
      return [
        this.position === 'center' ? 'justify-content-center' : null,
        this.position === 'bottom' ? 'justify-content-end' : null,
      ];
    },
    getDialogPadding() {
      return [
        this.position === 'center' ? 'px-4' : null,
        // this.position === 'bottom' ? null : null,
      ];
    },
  },
  created() {
    this.$log.info(`Created: ${this.dataRuid}`);

    this.$root.$once('on-back-pressed', () => {
      // this.$log.info('on-back-pressed received. Closing modal');
      this.close();
    });

    this.$root.$once('close-all-modals', () => {
      // this.$log.info('close-all-modals received. Closing modal');
      this.close();
    });
  },
  mounted() {
    const { navigation } = this.kibanaEvents;

    this.$store.dispatch('common/insertEvent', {
      category: navigation.category,
      action: navigation.modalDisplayed.action,
      type: this.dataRuid?.replace('modal__', '') || 'modal',
    });
  },
  destroyed() {
    this.$log.info(`Modal destroyed: ${this.dataRuid}`);
  },
  methods: {
    disableScrollWhenDisplayed() {
      this.$nextTick(() => {
        const modal = document.querySelector('#modal');
        const scrollableComponent = document.querySelector('#scrollableComponent');

        // this.$log.info('disableScrollWhenDisplayed modal', modal);
        // this.$log.info('disableScrollWhenDisplayed scrollableComponent', scrollableComponent);

        if (modal) {
          disableBodyScroll(modal);
        }

        if (scrollableComponent) {
          disableBodyScroll(scrollableComponent);
        }
      });
    },
    enableScrollWhenClosed() {
      const modal = document.querySelector('#modal');
      const scrollableComponent = document.querySelector('#scrollableComponent');

      // this.$log.info('enableScrollWhenClosed modal', document.querySelector('#modal'));
      // this.$log.info(
      //   'enableScrollWhenClosed scrollableComponent',
      //   document.querySelector('#scrollableComponent'),
      // );

      if (modal) {
        enableBodyScroll(modal);
      }

      // TODO: Some modals have double #scrollableComponent
      if (scrollableComponent) {
        enableBodyScroll(scrollableComponent);
      }
    },
    afterLeave() {
      /**
       * Let the parent know modal will be closed
       * so consumer/controller of the modal can toggle its internal state
       */
      // this.$log.info('afterLeave: Will emit close-modal');
      this.$emit('close-modal');
    },
    /**
     * Following close method is exposed to modal consumer/controller via slots
     * When modal consumer calls `close` we toggle content/backdrop flags to trigger
     * Vue's <transition> tag to start the exit animation and also register a one time event
     * listener for `close-modal` to execute the callback if any available.
     * Once the exit transition is finished, we use above `afterLeave` hook of the <transition>
     * to emit a `close-modal` event to be picked up by the event listener registered by close()
     * `afterClose` is an alternative way for when we do not have access to close in grand parent
     *
     * Reason for all this dance is a orderly/timely execution of operations.
     */
    close(cb) {
      if (!this.appState.isAwaiting) {
        this.showContent = false;
        this.showBackdrop = false;

        // TODO: Maybe consider converting this into a Promise?
        this.$once('close-modal', () => {
          // TODO: Add function check `cb === 'function' && cb`
          if (cb) {
            this.$log.info('Modal: close-modal with cb');
            cb();
          } else if (this.afterClose);
            // this.$log.info('Modal: afterClose cb', this.afterClose);
            this.afterClose();
        });
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* z-index: 10000; */
}

@media screen and (min-width: 420px) {
  .modal-width {
    max-width: 600px;
  }
}
</style>
