<template>
  <cc-view :id="`${id}-product`">
    <cc-charts-loading
      v-if="loading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <template v-else>
      <!-- Product detail -->
      <cc-card
        ruid="product-detail"
        card-background="white"
        class="pb-5"
        variant="plain"
        >
        <template #body>
          <div class="d-flex p-4 align-items-start">
            <span>
              <cc-image
                :alt="localizedCompany.name"
                style="width: 24px; height: 24px"
                class="border-radius-full mr-2 bg-white object-fit-cover"
                :src="localizedCompany.logo"
                :has-placeholder="true"
                />
            </span>

            <cc-text
              variant="body-bold"
              color="grey-dark"
              class="preline-formatted w-75"
              >
              {{ localizedCompany.name }}
            </cc-text>
          </div>

          <cc-image
            alt="Product image"
            style="width: 100%; height: auto; min-height: 150px;
             max-height: 20rem; object-fit: cover;"
            :src="productImage"
            :has-placeholder="true"
            />

          <div class="px-4 pt-4">
            <cc-heading
              v-if="localizedProduct && localizedProduct.title"
              color="grey-dark"
              >
              {{ localizedProduct.title }}
            </cc-heading>

            <div
              v-if="productInfo.stock !== -1"
              class="d-flex align-items-center justify-content-between py-4"
              >
              <cc-svg-base
                svg="icons/icon-green-coin"
                color-class="svg-fill-grey-dark"
                />

              <cc-text
                variant="body-bold"
                color="grey-dark"
                class="flex-grow-1 pl-2"
                >
                {{ productInfo.price }} {{ $t('shopGreenCoins') }}
              </cc-text>
            </div>

            <cc-button
              v-if="productInfo.stock > 0"
              variant="shop"
              :text="$t('shopBuy')"
              @click="purchaseHandler"
              />

            <cc-button
              v-if="productInfo.stock === 0"
              variant="danger"
              :text="$t('shopOutOfStock')"
              />

            <cc-text
              v-if="localizedProduct && localizedProduct.description"
              color="grey-light"
              class="py-4 preline-formatted"
              >
              {{ localizedProduct.description }}
            </cc-text>

            <div
              v-for="detail in localizedProduct && localizedProduct.details"
              :key="detail.label"
              >
              <cc-settings-list-item
                v-if="detail.label"
                :ruid="`${id}__${detail.label}`"
                :label="detail.label"
                label-color="grey-light"
                arrow-color="grey-light"
                divider-color="grey-lighter"
                arrow
                first
                last
                @click.native="openGoodDetailModal(detail)"
                />
            </div>
          </div>
        </template>
      </cc-card>

      <!-- Shop card -->
      <cc-card
        ruid="product-detail-shop"
        card-background="white"
        class="pb-5"
        variant="product"
        >
        <template #body>
          <div class="d-flex align-items-center py-4">
            <cc-image
              :alt="localizedCompany.name"
              style="width: 66px; height: 66px"
              class="border-radius-full mr-2 bg-white object-fit-cover"
              :src="localizedCompany.logo"
              :has-placeholder="true"
              />
            <cc-heading color="grey-dark">
              {{ localizedCompany.name }}
            </cc-heading>
          </div>

          <cc-settings-list-item
            v-if="localizedCompany.name"
            :ruid="`${id}__store-name`"
            :label="`${$t('shopStoreAbout')} ${localizedCompany.name}`"
            label-color="grey-light"
            arrow-color="grey-light"
            divider-color="grey-lighter"
            arrow
            first
            last
            @click.native="displayCompany(productInfo)"
            />
        </template>
      </cc-card>
    </template>

    <cc-add-email-address-modal
      v-if="isEmailModalDisplayed"
      :body-text="$t('addMandatoryEmailAddressDescription')"
      @after-leave="handleEmailModalLeave($event)"
      @close-modal="isEmailModalDisplayed = false"
      />

    <cc-verify-email-modal
      v-if="isVerifyEmailModalDisplayed"
      @close-modal="isVerifyEmailModalDisplayed = false"
      />

    <cc-reverify-email-modal
      v-if="isReverifyEmailModalDisplayed"
      @close-modal="isReverifyEmailModalDisplayed = false"
      />

    <cc-confirm-good-purchase-modal
      v-if="isConfirmGoodPurchaseModalDisplayed"
      :data="confirmGoodPurchaseModalData"
      @after-leave="
        ($event) => {
          (goodReceiptModalData = $event), (isGoodReceiptModalDisplayed = true);
        }
      "
      @close-modal="isConfirmGoodPurchaseModalDisplayed = false"
      />

    <cc-confirm-delivery-address-modal
      v-if="isConfirmDeliveryAddressModalDisplayed"
      :data="confirmDeliveryAddressModalData"
      @after-leave="confirmDeliveryAddressHandler"
      @close-modal="isConfirmDeliveryAddressModalDisplayed = false"
      />

    <cc-add-delivery-address-modal
      v-if="isAddDeliveryAddressModalDisplayed"
      :data="addDeliveryAddressModalData"
      @after-leave="addDeliveryAddressHandler"
      @close-modal="isAddDeliveryAddressModalDisplayed = false"
      />

    <cc-change-delivery-address-modal
      v-if="isChangeDeliveryAddressModalDisplayed"
      :data="{ selectedAddress }"
      @after-leave="changeDeliveryAddressHandler"
      @update-selected-address="selectedAddress = $event"
      @close-modal="isChangeDeliveryAddressModalDisplayed = false"
      />

    <cc-good-receipt-modal
      v-if="isGoodReceiptModalDisplayed"
      :data="goodReceiptModalData"
      @close-modal="isGoodReceiptModalDisplayed = false"
      />

    <cc-insufficient-coins-modal
      v-if="isInsufficientCoinsModalDisplayed"
      @close-modal="isInsufficientCoinsModalDisplayed = false"
      />

    <cc-good-detail-modal
      v-if="isGoodDetailModalDisplayed"
      :data="goodDetailModalData"
      @close-modal="isGoodDetailModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import sandbox from '@/mixins/sandbox';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';
import ccImage from '@/components/primitives/Image.vue';

import ccAddEmailAddressModal from '@/components/modals/AddEmailAddressModal.vue';
import ccVerifyEmailModal from '@/components/modals/VerifyEmailModal.vue';
import ccReverifyEmailModal from '@/components/modals/ReverifyEmailModal.vue';

import ccConfirmGoodPurchaseModal from '@/components/modals/ConfirmGoodPurchaseModal.vue';
import ccConfirmDeliveryAddressModal from '@/components/modals/ConfirmDeliveryAddressModal.vue';
import ccAddDeliveryAddressModal from '@/components/modals/AddDeliveryAddressModal.vue';
import ccChangeDeliveryAddressModal from '@/components/modals/ChangeDeliveryAddressModal.vue';
import ccGoodReceiptModal from '@/components/modals/GoodReceiptModal.vue';
import ccInsufficientCoinsModal from '@/components/modals/InsufficientCoinsModal.vue';
import ccGoodDetailModal from '@/components/modals/GoodDetailModal.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccHeading,
    ccText,
    ccButton,
    ccChartsLoading,
    ccSvgBase,
    ccSettingsListItem,
    ccImage,
    ccAddEmailAddressModal,
    ccVerifyEmailModal,
    ccReverifyEmailModal,
    ccConfirmGoodPurchaseModal,
    ccConfirmDeliveryAddressModal,
    ccAddDeliveryAddressModal,
    ccChangeDeliveryAddressModal,
    ccGoodReceiptModal,
    ccInsufficientCoinsModal,
    ccGoodDetailModal,
  },
  mixins: [viewScrollMemorizer, sandbox],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,

      isEmailModalDisplayed: false,
      isVerifyEmailModalDisplayed: false,
      isReverifyEmailModalDisplayed: false,

      isConfirmGoodPurchaseModalDisplayed: false,
      confirmGoodPurchaseModalData: null,

      isConfirmDeliveryAddressModalDisplayed: false,
      confirmDeliveryAddressModalData: null,

      isGoodReceiptModalDisplayed: false,
      goodReceiptModalData: null,

      isGoodDetailModalDisplayed: false,
      goodDetailModalData: null,

      isAddDeliveryAddressModalDisplayed: false,
      addDeliveryAddressModalData: null,

      isChangeDeliveryAddressModalDisplayed: false,
      changeDeliveryAddressModalData: null,

      isInsufficientCoinsModalDisplayed: false,

      selectedAddress: null,
    };
  },
  computed: {
    ...mapState({
      companyState: state => state.company,
      appState: state => state.common.appState,
      wallets: state => state.user.wallets,
      userProfile: state => state.user.user,
    }),
    ...mapGetters({
      getProductById: 'company/getProductById',
      getCompanyById: 'company/getCompanyById',
      getLocalizedResource: 'company/getLocalizedResource',
      getWalletBalance: 'user/getWalletBalance',
      getDefaultAddress: 'user/getDefaultAddress',
    }),
    isDeliverableOffer() {
      return this.productInfo?.virtualGood?.metaPublic?.deliverableOffer;
    },
    localizedCompany() {
      const { getLocalizedResource, getCompanyById, productInfo } = this;
      return getLocalizedResource(
        getCompanyById(productInfo?.storeId)?.company?.metaPublic?.resources,
      );
    },
    localizedProduct() {
      const { getLocalizedResource, productInfo } = this;
      if (productInfo?.virtualGood?.resources) {
        return getLocalizedResource(productInfo.virtualGood.resources);
      }
      return {};
    },
    productImage() {
      return this.localizedProduct?.images?.[0];
    },
    productInfo() {
      return this.getProductById(this.id) || {};
    },
  },
  async created() {
    const { offer } = this.kibanaEvents;

    this.selectedAddress = this.getDefaultAddress;

    // TODO: Move this to store with the intention of init so that it can be called else where easily
    if (!this.companyState.player) {
      await this.$store.dispatch('company/getPlayer');
    }

    if (!this.wallets?.length) {
      await this.$store.dispatch('user/getWallets');
    }

    this.$store.dispatch('common/insertEvent', {
      category: offer.category,
      action: offer.offerViewed.action,
      type: this.id,
      metaPublic: {
        offerID: this.id,
        storeId: this.productInfo?.storeId,
      },
    });

    this.loading = false;
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
      setAppState: 'common/setAppState',
    }),
    handleEmailModalLeave(message) {
      if (message === 'verify') {
        this.isVerifyEmailModalDisplayed = true;
      }

      if (message === 'reverify') {
        this.isReverifyEmailModalDisplayed = true;
      }
    },
    confirmDeliveryAddressHandler(event) {
      if (event === 'add-delivery-address-modal') {
        this.isAddDeliveryAddressModalDisplayed = true;
      }

      if (event.name === 'change-delivery-address-modal') {
        this.selectedAddress = event.selectedAddress;
        this.isChangeDeliveryAddressModalDisplayed = true;
      }

      if (event.name === 'good-receipt') {
        this.goodReceiptModalData = { transactionId: event.transactionId };
        this.isGoodReceiptModalDisplayed = true;
      }
    },
    addDeliveryAddressHandler(event) {
      this.selectedAddress = event.selectedAddress;

      if (event.name === 'change-delivery-address-modal') {
        this.isChangeDeliveryAddressModalDisplayed = true;
      }

      if (event.name === 'confirm-delivery-address-modal') {
        this.confirmDeliveryAddress(this.productInfo);
      }
    },
    changeDeliveryAddressHandler(event) {
      if (event === 'confirm-delivery-address-modal') {
        this.confirmDeliveryAddress(this.productInfo);
      }

      if (event === 'add-delivery-address-modal') {
        this.isAddDeliveryAddressModalDisplayed = true;
      }
    },
    async checkMandatoryEmailAddress() {
      try {
        if (!this.userProfile.email.address && this.userProfile.email.pendingAddress) {
          await this.$store.dispatch('user/getUser');
        }

        if (!this.userProfile.email.address) {
          this.isEmailModalDisplayed = true;
          return false;
        }

        return true;
      } catch (error) {
        this.$log.info('checkMandatoryEmailAddress', error);
        return false;
      }
    },
    async confirmDeliveryAddress(product) {
      const { companyName, selectedAddress } = this;
      this.$log.info('getWalletBalance', this.getWalletBalance);

      if (this.getWalletBalance < product.price) {
        this.isInsufficientCoinsModalDisplayed = true;
        return false;
      }

      const hasMandatoryEmail = await this.checkMandatoryEmailAddress();

      if (!hasMandatoryEmail) {
        return false;
      }

      this.confirmDeliveryAddressModalData = {
        product,
        companyName,
        selectedAddress,
      };
      this.isConfirmDeliveryAddressModalDisplayed = true;
      return true;
    },
    async confirmOffer(product) {
      const { companyName, selectedAddress } = this;
      this.$log.info('getWalletBalance', this.getWalletBalance);

      if (this.getWalletBalance < product.price) {
        this.isInsufficientCoinsModalDisplayed = true;
        return false;
      }

      const hasMandatoryEmail = await this.checkMandatoryEmailAddress();

      if (!hasMandatoryEmail) {
        return false;
      }

      this.confirmGoodPurchaseModalData = { product, companyName, selectedAddress };
      this.isConfirmGoodPurchaseModalDisplayed = true;
      return true;
    },
    async purchaseHandler() {
      if (this.isDeliverableOffer) {
        await this.confirmDeliveryAddress(this.productInfo);
      } else {
        await this.confirmOffer(this.productInfo);
      }
    },
    openGoodDetailModal(detail) {
      this.goodDetailModalData = { detail };
      this.isGoodDetailModalDisplayed = true;
    },
    displayCompany(product) {
      const { storeId } = product;

      this.$log.info('product', product);

      const isProductViaCompany = this.$route.matched.some(
        record => record.name === 'product-via-company',
      );
      const isProductViaCategory = this.$route.matched.some(
        record => record.name === 'product-via-category',
      );

      if (isProductViaCompany) {
        return this.$router
          .push({ name: 'company-profile-via-product', params: { companyId: storeId } })
          .catch(() => {});
      }

      if (isProductViaCategory) {
        return this.$router
          .push({ name: 'company-profile-via-product-category', params: { companyId: storeId } })
          .catch(() => {});
      }

      return this.$router
        .push({ name: 'company-profile', params: { companyId: storeId } })
        .catch(() => {});
    },
  },
};
</script>
