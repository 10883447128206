import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      // isLeaving: false,
    };
  },
  computed: {
    ...mapState({
      uiState: state => state.common.uiState,
    }),
    el() {
      // this.$log.info('viewScrollMemorizer id', this.id);
      return document.getElementById(`app__view__${this.id}`);
    },
  },
  methods: {
    ...mapMutations({
      setRoutePosition: 'common/setRoutePosition',
      setExitRouteOfTab: 'common/setExitRouteOfTab',
    }),
  },
  mounted() {
    // this.$log.info('el', this.el);
    // let lastScrollPos = 0;
    // let ticking = false;

    // const print = (scrollPos) => {
    //   this.$log.info('scrollPos', scrollPos);
    // };

    // this.el.addEventListener('scroll', (e) => {
    //   lastScrollPos = e.target.scrollTop;

    //   if (!ticking) {
    //     window.requestAnimationFrame(() => {
    //       print(lastScrollPos);
    //       ticking = false;
    //     });

    //     ticking = true;
    //   }
    // });
  },
  created() {
    // this.$log.info('Created:viewScrollMemorizer');
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        // vm.$log.info('beforeRouteEnter', vm.id, vm);

        const viewLevel1 = document.getElementById(`app__view__${vm.id}`);
        const viewLevel2 = document.getElementById(`app__view__${vm.id}__sub-view`);
        const viewLevel3 = document.getElementById(`app__view__${to.name}`);

        // vm.$log.info('to', to.name);
        // vm.$log.warn('beforeRouteEnter 1', viewLevel1);
        // vm.$log.warn('beforeRouteEnter 2', viewLevel2);
        // vm.$log.warn('beforeRouteEnter 3', viewLevel3);

        const currentRoutePos = vm.uiState.routePositions[vm.id];
        // vm.$log.info('currentRoutePos', vm.id, currentRoutePos);

        if (to.meta.depth < from.meta.depth) {
          if (currentRoutePos) {
            if (viewLevel3) {
              // vm.$log.info('3', currentRoutePos);
              viewLevel3.scrollTop = currentRoutePos;
              // vm.$log.info('after 3', viewLevel3.scrollTop);
            } else if (viewLevel2) {
              vm.$log.info('2');
              // viewLevel2.scrollTop = currentRoutePos;
            } else {
              vm.$log.info('1');
              // viewLevel1.scrollTop = currentRoutePos;
            }
            // vm.$log.warn('beforeRouteEnter: routePositions', currentRoutePos);
          }
        } else if (to.meta.depth === from.meta.depth && to.meta.hasTabBar) {
          // vm.$log.info('hasTabBar 1');
          viewLevel1.scrollTop = currentRoutePos;
        }
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    const viewLevel1 = document.getElementById(`app__view__${this.id}`);
    const viewLevel2 = document.getElementById(`app__view__${this.id}__sub-view`);
    const viewLevel3 = document.getElementById(`app__view__${from.name}`);

    const scrollTop = viewLevel1?.scrollTop || viewLevel2?.scrollTop || viewLevel3?.scrollTop;
    // const scrollTop = viewLevel1?.scrollTop;

    this.setRoutePosition([from.name, scrollTop]);
    // this.$log.warn(`
    //   beforeRouteLeave View: ${this.id},
    //   beforeRouteLeave From: ${from.name}, ${scrollTop}
    // `);

    // this.$log.warn('scrollPos 1', viewLevel1?.scrollTop);
    // this.$log.warn('scrollPos 2', viewLevel2?.scrollTop);
    // this.$log.warn('scrollPos 3', viewLevel3?.scrollTop);

    next();
  },
};
