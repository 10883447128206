<template>
  <cc-modal
    data-ruid="modal__edit-trip-purpose"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('editTripPurposeTitle')"
    :header-description="$t('editTripPurposeDescription')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div class="mt-3 px-5">
        <div
          v-for="tripPurpose in tripPurposes"
          :key="tripPurpose"
          :data-ruid="`modal__button-${tripPurpose}`"
          class="d-flex flex-row justify-content-around align-items-center pl-1 pr-5 py-4"
          :class="selectedTripPurpose === tripPurpose ? 'bg-blue-darkest rounded-16' : ''"
          @click="selectTripPurpose(tripPurpose)"
          >
          <div
            style="flex: 0 0 56px;"
            class="d-flex justify-content-center mr-3"
            >
            <cc-svg-base
              :key="tripPurpose"
              :svg="`trip-purpose/${tripPurposeNameHandler(tripPurpose)}`"
              style="color:transparent;"
              />
          </div>

          <cc-text class="flex-grow-1 font-weight-sbold text-left">
            {{ $t(`${tripPurpose}Txt`) }}
          </cc-text>

          <div>
            <cc-svg-base
              v-if="selectedTripPurpose === tripPurpose"
              color-class="svg-fill-primary"
              svg="icons/icon-check"
              />
          </div>
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <div
        class="d-flex flex-column align-items-center justify-content-center w-100 pt-3 pb-5 px-5"
        >
        <div
          v-if="errorMessage"
          data-ruid="global__validation-message"
          class="is-invalid pb-3"
          >
          {{ errorMessage }}
        </div>

        <cc-button
          data-ruid="global__button-confirm"
          :is-modal-button="true"
          :disabled="awaiting || selectedTripPurpose === initialTripPurpose"
          text="Save"
          @click="reportTimelineError(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import ccButton from '@/components/primitives/Button.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import trips from '@/services/trips';
import { tripPurposeNameHandler } from '@/mixins/utils';

export default {
  components: {
    ccButton,
    ccModal,
    ccSvgBase,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      initialTripPurpose: null,
      selectedTripPurpose: null,
      errorMessage: '',
      awaiting: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      user: state => state.user.user,
    }),
    tripPurposes() {
      return [
        'private',
        'commute',
        'business',
    ];
    },
  },
  mounted() {
    this.initialTripPurpose = tripPurposeNameHandler(this.data.purpose);
    this.selectedTripPurpose = tripPurposeNameHandler(this.data.purpose);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setTripsState: 'trips/setState',
    }),
    tripPurposeNameHandler,
    selectTripPurpose(tripPurpose) {
      this.selectedTripPurpose = tripPurpose;
    },
    reportTimelineError(closeModal) {
      if (this.selectedTripPurpose !== null) {
        this.errorMessage = '';

        this.awaiting = true;

        trips
          .updateTripPurpose(this.data.id, this.selectedTripPurpose)
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch('trips/getTimeline', { forceUpdate: true });

              this.awaiting = false;

              closeModal();
            }, 3000);
          })
          .catch((err) => {
             this.errorMessage = this.$t('generalErrorMessage');
            this.sentryCaptureMessage(`reportTimelineError: ${err}`, 'error');
            this.awaiting = false;
          });
      }
    },
  },
};
</script>
