<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-leaderboards-control-group
      id="canton-leaderboard"
      :has-type-segment="true"
      />

    <cc-view
      :id="id"
      :has-full-width="true"
      :is-sub-view="true"
      :has-ui-controls="true"
      class="pb-6"
      >
      <ccLottie
        v-if="appState.cantonLeaderboardLoading"
        id="activityIndicator"
        class="w-15 h-15"
        :options="animData"
        wrapper-class="p-5"
        />

      <div
        v-else
        class="pb-6 mb-6"
        >
        <cc-text
          v-if="hasLeaderboard"
          color="muted"
          variant="body-sm"
          class="p-4"
          >
          {{ appState.leaderboardType === 'averageCo2'
            ? $t('leaderboardsCantonDescriptionSingular')
            : null }}
          {{ appState.leaderboardType === 'co2PerKm'
            ? $t('leaderboardsDescriptionSingularCo2PerKm')
            : null }}
        </cc-text>

        <!-- Regular Canton leaderboard -->
        <div v-if="hasLeaderboard">
          <cc-leaderboard-list-item
            v-for="(cantonObject, index) in leaderboardObject"
            :key="index"
            :has-row-title="index === 0"
            type="canton"
            :rank="cantonObject.curPosition"
            :prev-rank="cantonObject.prevPosition"
            :icon="`canton-icons/${cantonObject.name}`"
            :self-rank="usersCantonIndex"
            :name="cantonObject.name"
            :score="getEmissionText(cantonObject.score)"
            :last="index === leaderboardObject.length"
            />
        </div>


        <!-- Did not travel leaderboard -->
        <div
          v-if="hasDidNotTravelLeaderboard"
          class="pt-6"
          >
          <h4 class="font-weight-bold text-white text-left px-4">
            {{ didNotTravelText }}
          </h4>

          <cc-text
            class="px-4 pb-4 pt-2"
            variant="body-sm"
            color="muted"
            >
            {{ $t('didNotTravelDescription') }}
          </cc-text>
          <cc-leaderboard-list-item
            v-for="(cantonObject, index) in sortedDidNotTravelList"
            :key="leaderboardState.cities[appState.leaderboardType].length + index"
            :did-not-travel="true"
            type="canton"
            :rank="cantonObject.curPosition"
            :self-rank="dntSelfRank"
            :icon="`canton-icons/${cantonObject.name}`"
            :name="cantonObject.name"
            score=" "
            :show-rank="false"
            />
        </div>


        <!-- Regular Canton self card -->
        <div
          id="selfCard"
          class="flex-column d-flex align-items-center fixed-bottom w-100"
          :style="`bottom: calc(${uiState.tabBarHeight}px)`"
          >
          <transition name="self-card-slide-up">
            <div
              v-if="showHoverCard && dntSelfRank === 0"
              class="d-flex flex-column justify-content-end h-100 w-100 px-2 pb-3 transition-all"
              >
              <div
                data-ruid="card__self"
                class="d-flex w-100 bg-blue-light align-self-center
                rounded-8 overflow-hidden shadow"
                @click="scrollToSelf()"
                >
                <cc-leaderboard-list-item
                  type="canton"
                  :rank="leaderboardObject[usersCantonIndex-1].curPosition"
                  :prev-rank="leaderboardObject[usersCantonIndex-1].prevPosition"
                  :icon="`canton-icons/${leaderboardObject[usersCantonIndex-1].name}`"
                  :self-rank="usersCantonIndex-1"
                  :name="leaderboardObject[usersCantonIndex-1].name"
                  :score="getEmissionText(leaderboardObject[usersCantonIndex-1].score)"
                  :hover-card="true"
                  />
              </div>
            </div>
          </transition>
        </div>


        <!-- Did not travel self card -->
        <div
          id="selfCardDNT"
          class="flex-column d-flex align-items-center fixed-bottom w-100"
          :style="`bottom: calc(${uiState.tabBarHeight}px)`"
          >
          <transition name="self-card-slide-up">
            <div
              v-if="showHoverCard && dntSelfRank !== 0"
              class="d-flex flex-column justify-content-end h-100 w-100 px-2 pb-3 transition-all"
              >
              <div
                data-ruid="card__self"
                class="d-flex w-100 bg-blue-light align-self-center
                rounded-8 overflow-hidden shadow"
                @click="scrollToSelf()"
                >
                <cc-leaderboard-list-item
                  :key="selfObjectDnt.name"
                  type="canton"
                  :rank="selfObjectDnt.curPosition"
                  :prev-rank="selfObjectDnt.prevPosition"
                  :icon="`canton-icons/${selfObjectDnt.name}`"
                  :show-rank="false"
                  :self-rank="selfObjectDnt.curPosition"
                  :name="selfObjectDnt.name"
                  score=" "
                  :hover-card="true"
                  :did-not-travel="true"
                  />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </cc-view>
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccLeaderboardsControlGroup from '@/components/social/LeaderboardsControlGroup.vue';
import ccLottie from '@/components/Lottie.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';
import { cantons } from '@/mixins/cantons';

export default {
  components: {
    ccView,
    ccText,
    ccLeaderboardListItem,
    ccLeaderboardsControlGroup,
    ccLottie,
  },
  data() {
    return {
      id: 'canton-leaderboard',
      showHoverCard: false,
      observer: null,
      userCanton: null,
      animData: {
        animationData: LoadingLeaderboard,
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      getEmissionText: 'common/getEmissionText',
    }),
    hasLeaderboard() {
      return this.leaderboardState?.cities?.[this.appState?.leaderboardType]?.length > 0;
    },
    hasDidNotTravelLeaderboard() {
      return this.leaderboardState?.cities?.currentData?.didNotTravel?.length > 0;
    },
    selfObjectDnt() {
      const userCanton = this.user && this.user.city;
      if (this.leaderboardState.cities[this.appState.leaderboardType] && this.dntSelfRank !== 0) {
        for (let i = 0; i < this.leaderboardState.cities.currentData.didNotTravel.length; i += 1) {
          if (
            this.leaderboardState.cities.currentData.didNotTravel[i].name
            === cantons[userCanton].name
          ) {
            return this.leaderboardState.cities.currentData.didNotTravel[i];
          }
        }
      }
      return null;
    },
    selfObject() {
      if (this.leaderboardState.cities[this.appState.leaderboardType]) {
        const userCanton = this.user && this.user.city;

        let result = null;

        this.leaderboardObject.forEach((cantonRank, index) => {
          if (cantons[userCanton] && cantonRank.name === cantons[userCanton].name) {
            result = this.leaderboardObject[index];
          }
          return result;
        });
      }
      return null;
    },
    didNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelCantonsYet');
      }

      return this.$t('didNotTravelCantons');
    },
    dntSelfRank() {
      const userCanton = this.user && this.user.city;
      const { cities } = this.leaderboardState;

      for (let i = 0; i < cities.currentData.didNotTravel.length; i += 1) {
        if (cities.currentData.didNotTravel[i].name === cantons?.[userCanton]?.name) {
          return i + 1;
        }
      }
      return 0;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    usersCantonIndex() {
      let result = null;

      if (this.leaderboardState.cities[this.appState.leaderboardType]) {
        const userCanton = this.user && this.user.city;

        this.leaderboardObject.forEach((cantonRank, index) => {
          if (cantons[userCanton] && cantonRank.name === cantons[userCanton].name) {
            result = index + 1;
          }
        });
      }
      return result;
    },
    leaderboardObject() {
      return this.leaderboardState?.cities?.[this.appState.leaderboardType];
    },
    sortedDidNotTravelList() {
      return this.leaderboardState.cities.currentData.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    queryDate() {
      this.$store.dispatch('social/getCantonLeaderboard');
      // On query date change, disconnect observer and recreate so that if selected item
      // rank has changed recreation of the observer will pick up the correct element.
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }

      this.createObserver();
    },
  },
  async created() {
    await this.$store.dispatch('user/getUser');

    this.setAppState(['leaderboardType', 'averageCo2']);

    const refreshTime = 900000;
    const weeksAgo = this.$moment().diff(this.appState.queryDate, 'weeks');

    if (
      !this.leaderboardState.cities
      || weeksAgo !== this.leaderboardState.cities.windowsAgo
      || Date.now() - refreshTime > this.leaderboardState.cities.timestamp
    ) {
      await this.$store.dispatch('social/getCantonLeaderboard');
    }

    this.setHoverCard();
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    setHoverCard() {
      // Once disconnectHoverCard event received from LeaderboardSelector
      // check the observer, if exists, disconnect and null it
      this.$root.$on('disconnectHoverCard', () => {
        this.$log.info('Received disconnectHoverCard', this.observer);

        if (this.observer) {
          this.observer.disconnect();
          this.observer = null;
        }
      });

      // Once createHoverCardObserver event received from sdkListener > contactNames
      // create an IntersectionObserver for HoverCard
      this.$root.$on('createHoverCardObserver', () => {
        this.$log.info('Received createHoverCardObserver');
        this.$log.info('createHoverCardObserver > this.observer', this.observer);

        this.showHoverCard = false;
        this.createObserver();
      });

      this.createObserver();
    },
    scrollToSelf() {
      this.$log.info('clicked');
      const el = document.querySelector('#selected');
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    createObserver() {
      this.$nextTick(() => {
        // Give a little time for Hover Card to appear
        // So user doesn't see a lot of things happening when mounted
        setTimeout(() => {
          // rootMargin is for the IntersectionObservers margin area.
          // Here we include iOS safe area inset top to consider this in calculations
          const safeArea = parseInt(
              getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top'),
              10,
            ) + 125;
          const rootMargin = `-${safeArea}px 0px 0px 0px`;

          const el = document.querySelector('#selected');
          const self = this.rank === this.selfRank && !this.hoverCard;

          // this.$log.info('El', !!el);
          // this.$log.info('Self', !!self);

          if (el && self) {
            this.$log.info('will createObserver');

            this.observer = new IntersectionObserver(
              (entries) => {
                // this.$log.info('IntOberserver', entries);
                if (entries[0].isIntersecting === true) {
                  if (entries[0].intersectionRatio === 1) {
                    this.$log.info('will hide hover card');
                    this.showHoverCard = false;
                  }
                } else {
                  this.$log.info('will show hover card');
                  this.showHoverCard = true;
                }
              },
              {
                rootMargin,
                threshold: [0, 0.5, 1],
              },
            );
            this.observer.observe(el);
          }
        }, 800);
      });
    },
  },
};
</script>
