<template>
  <cc-card
    :id="id"
    :ruid="id"
    button-variant="small-muted"
    :padded="false"
    >
    <template #body>
      <div>
        <div class="d-flex flex-row flex-wrap pb-4 justify-content-start">
          <cc-heading
            variant="h5"
            class="pb-2 mr-1"
            >
            {{ $t('miaEnergyBreakdown') }}:
          </cc-heading>
        </div>


        <div class="pb-5">
          <cc-employee-distance-by-categories-chart
            :avg-accumulators="avgAccumulators"
            :last-sevenday="lastSevenday"
            />
          <div class="text-center">
            <cc-text
              color="muted"
              variant="body-xs"
              >
              {{ bottomTitle }}
            </cc-text>
          </div>
          <cc-chart-legend class="pt-2" />
        </div>

        <div>
          <cc-happy-earth-placeholder />
        </div>
      </div>
    </template>
  </cc-card>
</template>

<script>
import { co2Prefix, formatCo2 } from '@/mixins/utils';

import ccHeading from '@/components/primitives/Heading.vue';
import ccCard from '@/components/constructs/Card.vue';

import ccEmployeeDistanceByCategoriesChart from '@/components/mia/EmployeeDistanceByCategoriesChart.vue';
import ccChartLegend from '@/components/ChartLegend.vue';

import ccHappyEarthPlaceholder from './HappyEarthPlaceholder.vue';

export default {
  name: 'EmployeeDistanceByCategory',
  components: {
    ccHeading,
    ccCard,
    ccHappyEarthPlaceholder,
    ccEmployeeDistanceByCategoriesChart,
    ccChartLegend,
  },
  props: {
    avgAccumulators: {
      type: Object,
      required: false,
      default: null,
    },
    lastSevenday: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      id: 'employee-distance-by-category',
    };
  },
  computed: {
    bottomTitle() {
      return this.lastSevenday && this.lastSevenday.length
        ? `${this.$moment(this.lastSevenday[this.lastSevenday.length - 1], 'YYYY-MM-DD').subtract(6, 'days').format('DD MMM')} - ${this.$moment(
            this.lastSevenday[this.lastSevenday.length - 1],
            'YYYY-MM-DD',
          ).format('DD MMM YYYY')}`
        : '';
    },
  },
  methods: {
    formatCo2,
    co2Prefix,
  },
};
</script>
