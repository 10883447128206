<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <button
    :id="id"
    :data-ruid="dataRuid"
    :class="buttonClassGroup"
    :disabled="disabled || appState.isAwaiting"
    @click="$emit('click')"
    >
    <cc-svg-base
      v-if="icon && iconSide === 'left'"
      :color-class="iconColor"
      :class="iconClass"
      :svg="icon"
      />

    <template
      v-if="text"
      :class="textClasses"
      >
      <template v-if="appState.isAwaiting && hasLoadingFeedback">
        Loading
      </template>

      <div
        v-else-if="hasSpinnerFeedback"
        class="spinner"
        >
        <div
          class="circle1"
          :class="spinnerBgColor"
          />
        <div
          class="circle2"
          :class="spinnerBgColor"
          />
        <div
          class="circle3"
          :class="spinnerBgColor"
          />
      </div>

      <template v-else>
        {{ text }}
      </template>
    </template>

    <slot
      v-if="!text"
      />

    <cc-svg-base
      v-if="icon && iconSide === 'right'"
      :color-class="iconColor"
      :class="iconClass"
      :svg="icon"
      />
  </button>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ccSvgBase from '@/components/SvgBase.vue';

// Valid supported button types
const variant = [
  'primary',
  'secondary',
  'transparent',
  'transparent-muted',
  'shop',
  'shop-small',
  'light',
  'small',
  'small-muted',
  'small-dark',
  'small-dark-muted',
  'small-danger',
  'small-warning',
  'medium-muted',
  'medium-dark',
  'medium-dark-muted',
  'warning',
  'danger',
  'text',
  'segmented-control',
  'bar',
  'icon',
  'link',
  'link-normal',
  'heading',
];

const alignProps = ['start', 'center', 'between', 'end', 'reset', 'baseline'];

export default {
  components: {
    ccSvgBase,
  },
  props: {
    align: {
      type: String,
      required: false,
      default: 'center',
      validator: value => alignProps.includes(value),
    },
    justify: {
      type: String,
      required: false,
      default: 'center',
      validator: value => alignProps.includes(value),
    },
    dataRuid: {
      type: String,
      default: null,
      required: false,
    },
    isModalButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    textClasses: {
      type: [String, Array],
      required: false,
      default: '',
    },
    buttonClass: {
      type: [String, Array],
      required: false,
      default: '',
    },
    block: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasLoadingFeedback: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasSpinnerFeedback: {
      type: Boolean,
      default: false,
      required: false,
    },
    spinnerBgColor: {
      type: String,
      default: 'bg-blue-lightest',
      required: false,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
    iconColor: {
      type: [String, Array],
      default: null,
      required: false,
    },
    iconSide: {
      type: String,
      default: 'right',
      required: false,
    },
    iconClass: {
      type: String,
      default: null,
      required: false,
    },
    text: {
      type: [String, Function, Array],
      default: null,
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: value => variant.includes(value),
    },
    to: {
      type: [String, Object],
      default: null,
    },
    onClose: {
      type: [Function, String],
      default: null,
      required: false,
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    buttonClassGroup() {
      const { justify } = this;

      return [
        'd-flex',
        `justify-content-${justify}`,
        this.block ? 'btn-block' : '',
        this.icon ? 'align-items-center' : '',
        this.buttonClass,
        this.variant === 'primary' ? ['btn', 'btn-primary', 'text-blue-darkest'] : '',
        this.variant === 'secondary' ? ['btn', 'btn-secondary text-white'] : '',
        this.variant === 'transparent' ? ['btn', 'text-white'] : '',
        this.variant === 'transparent-muted' ? ['btn', 'text-blue-lightest', 'font-weight-normal'] : '',
        this.variant === 'shop' ? ['btn', 'btn-cc-green-dark text-white px-6'] : '',
        this.variant === 'shop-small'
          ? [
              'btn',
              'btn-cc-green-dark',
              'text-white',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-5',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
        this.variant === 'small'
          ? [
              'btn',
              'btn-primary',
              'text-blue-dark',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
        this.variant === 'small-muted'
          ? [
              'btn',
              'btn-blue-light',
              'text-white',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
          this.variant === 'medium-muted'
          ? [
              'btn',
              'btn-blue-light',
              'text-white',
              'font-size-16',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
          this.variant === 'small-dark'
          ? [
              'btn',
              'btn-blue-dark',
              'text-white',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
          this.variant === 'medium-dark'
          ? [
              'btn',
              'btn-blue-dark',
              'text-white',
              'font-size-16',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
          this.variant === 'small-dark-muted'
          ? [
              'btn',
              'btn-blue-dark',
              'text-blue-lightest',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
          this.variant === 'medium-dark-muted'
          ? [
              'btn',
              'btn-blue-dark',
              'text-blue-lightest',
              'font-size-16',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
        this.variant === 'small-danger'
          ? [
              'btn',
              'btn-danger',
              'text-white',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
        this.variant === 'small-warning'
          ? [
              'btn',
              'btn-warning',
              'text-cc-yellow-darkest',
              'font-size-14',
              'font-weight-bold',
              'py-1 px-4',
              'border-radius-2',
              'line-height-md',
            ]
          : '',
        this.variant === 'segmented-control' ? ['py-3 segmented-control'] : '',
        this.variant === 'warning' ? ['btn btn-warning text-cc-yellow-darkest py-2'] : '',
        this.variant === 'danger' ? ['btn btn-danger text-white'] : '',
        this.variant === 'text' ? ['btn p-0 text-uppercase'] : '',
        this.variant === 'light' ? ['btn btn-gray-eb text-uppercase'] : '',
        this.variant === 'link' ? ['btn btn-link text-left m-0 p-0'] : '',
        this.variant === 'link-normal' ? ['btn btn-link btn-normal text-left m-0 p-0'] : '',
        this.variant === 'icon' ? ['line-height p-0 border-0 border-radius-0'] : '',
        this.variant === 'heading' ? ['h3 text-upper line-height p-0 border-0 border-radius-0'] : '',
        // this.variant === 'bar'
        //   ? ['p-0 d-flex justify-content-between align-items-center']
        //   : '',
      ];
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
  },
};
</script>

<style lang="scss">
.segmented-control {
  color: $blue-lightest !important;
  background-color: $blue-light !important;
  font-weight: $font-weight-bold;
  border-radius: $border-radius-2 !important;
  font-size: $font-size-sm;
}
.segmented-control.active {
  background-color: $blue-lighter !important;
  color: $white !important;
}

.spinner {
  text-align: center;
}

.spinner > div {
  width: 8px;
  height: 8px;
  // background-color: var(--blue-lightest);
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .circle1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .circle2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
