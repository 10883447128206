<template>
  <cc-card
    v-if="isAllowCompanyLeaderboard"
    :id="id"
    :ruid="id"
    :inner-title="$t('companyGroupsLeaderboardCardTitle')"
    :button-variant="getButtonProps.variant"
    :button-label="getButtonProps.label"
    :has-button="hasCompanyGroupsLeaderboard"
    :padded="false"
    :pressable="true"
    class="pb-4"
    @click.native="clickHandler"
    >
    <template
      #body
      >
      <div
        v-if="!hasCompanyGroupsLeaderboard && isLoading"
        class="d-flex justify-content-center align-items-center"
        >
        <ccLottie
          id="activityIndicator"
          class="w-25"
          :options="lottieOptions"
          wrapper-class="p-5"
          />
      </div>

      <cc-wrapper
        v-else-if="hasCompanyGroupsLeaderboard && (companyGroups && companyGroups.length)"
        direction="column"
        align="start"
        >
        <cc-leaderboard-list-item
          v-for="(group, index) in companyGroups"
          :key="index"
          type="widget"
          :rank="group.curPosition"
          :rank-class="getRankClass(group.curPosition)"
          :prev-rank="group.prevPosition"
          :icon="getCupIcon(group.curPosition)"
          :name="group.name"
          :score="getEmissionText(group.score, true)"
          :widget="true"
          @click.native="clickHandler(group)"
          />
      </cc-wrapper>

      <cc-wrapper
        v-else-if="allDidNotTravel"
        direction="row"
        align="center"
        class="flex-wrap"
        >
        <cc-wrapper direction="row">
          <div class="pr-5">
            <cc-svg-base svg="illustrations/hero-groups" />
          </div>

          <cc-text color="muted">
            {{ allDidNotTravelText }}
          </cc-text>
        </cc-wrapper>
      </cc-wrapper>

      <cc-wrapper
        v-else
        direction="row"
        align="start"
        class="flex-wrap"
        >
        <cc-wrapper direction="row">
          <div class="pr-5">
            <cc-svg-base svg="illustrations/hero-groups" />
          </div>

          <cc-text color="muted">
            {{ $t('groupLeaderboardCardDescription') }}
          </cc-text>
        </cc-wrapper>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccLottie from '@/components/Lottie.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccText,
    ccSvgBase,
    ccLottie,
    ccLeaderboardListItem,
  },
  data() {
    return {
      id: 'group-leaderboard-card',
      companyGroups: null,
      lottieOptions: {
        animationData: LoadingLeaderboard,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasCompanyGroupsLeaderboard: 'social/hasCompanyGroupsLeaderboard',
      getCompanyGroups: 'social/getCompanyGroups',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
      getCompanyTagId: 'user/getUserCompanyTagId',
      getAllowCompanyLeaderboardByAlias: 'company/getAllowCompanyLeaderboardByAlias',
    }),
    allDidNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelYetLeaderboards');
      }

      return this.$t('didNotTravelLeaderboards');
    },
    allDidNotTravel() {
      const didNotTravelLeaderboard = this.leaderboardState?.groups?.currentData?.didNotTravel;
      const didTravelLeaderboard = this.leaderboardState?.groups?.currentData?.didTravel;

      if (
        didNotTravelLeaderboard?.length > 0
        && (!didTravelLeaderboard || didTravelLeaderboard?.length === 0)
      ) {
        return true;
      }

      return false;
    },
    getButtonProps() {
      const { hasCompanyGroupsLeaderboard } = this;

      if (hasCompanyGroupsLeaderboard) {
        return {
          label: this.$t('txtView'),
          variant: 'small-muted',
        };
      }

      return {
        label: this.$t('txtStart'),
        variant: 'small',
      };
    },
    isAllowCompanyLeaderboard() {
      return this.getAllowCompanyLeaderboardByAlias(this.getCompanyTagId);
    },
  },
  async mounted() {
    const groups = this.leaderboardState.company_groups;
    const refreshTime = 900000;
    const shouldDispatch = !groups || Date.now() - refreshTime > groups.timestamp;

    if (shouldDispatch) {
      await this.$store.dispatch('social/getCompanyGroupsLeaderboard');
    }

    this.companyGroups = this.getCompanyGroups().slice(0, 3);

    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async clickHandler() {
      if (!this.appState.isLoading) {
        if (!this.hasCompanyGroupsLeaderboard) return;
        this.$router.push({ name: 'company-group-leaderboard' }).catch(() => {});
      }
    },
  },
};
</script>
