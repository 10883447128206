<template>
  <div
    id="chart-legend"
    class="d-flex flex-row flex-wrap"
    >
    <div
      v-for="(label, i) in legend"
      :key="i"
      class="d-flex px-2"
      >
      <span
        :class="`${label.colorClass}`"
        class="d-inline-block legend-dot border-radius-full my-auto"
        />

      <cc-text
        variant="body-sm"
        color="muted"
        class="ml-2"
        >
        {{ $t(`legend${label.name}`) }}
      </cc-text>
    </div>
    <div
      class="d-flex px-2"
      >
      <div class="h3 mb-auto">
        <span>---</span>
      </div>

      <cc-text
        variant="body-sm"
        color="muted"
        class="ml-2"
        >
        {{ $t('legendAvgOfSwissPeople') }}
      </cc-text>
    </div>
  </div>
</template>

<script>
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccText,
  },
  data() {
    return {
      legend: [
        {
          name: 'YourEmission',
          colorClass: 'bg-cc-red',
        },
        {
          name: 'AvgOfAllParticipants',
          colorClass: 'bg-cc-yellow',
        },
      ],
    };
  },
};
</script>

<style>
.legend-dot {
  width: 11px;
  height: 11px;
}
</style>
