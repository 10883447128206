<template>
  <div
    class="wrapper"
    :class="['d-flex', getAlignment, getDirection]"
    :style="{ gap: `${gap}px` }"
    >
    <slot />
  </div>
</template>

<script>

/**
 * This primitive component is still WIP
 * Use it with caution, accepted props will be extented if make sense
 */
const directionProps = ['column', 'row'];
const alignProps = ['start', 'center', 'between', 'end', 'reset', 'baseline'];

export default {
  props: {
    direction: {
      type: String,
      required: false,
      default: 'column',
      validator: value => directionProps.includes(value),
    },
    align: {
      type: String,
      required: false,
      default: 'center',
      validator: value => alignProps.includes(value),
    },
    justify: {
      type: String,
      required: false,
      default: 'center',
      validator: value => alignProps.includes(value),
    },
    gap: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    getAlignment() {
      const { align, justify } = this;
      // TODO: refactor here, it got too specific when rushing things
      return [
        this.align !== 'reset' ? `align-items-${align}` : null,
        `justify-content-${justify}`,
        // align === 'start' && direction === 'row' ? 'justify-content-start' : null,
        // align === 'center' && direction === 'row' ? 'justify-content-center' : null,
        // align === 'end' && direction === 'row' ? 'justify-content-end' : null,
        // align === 'between' && direction === 'row' ? 'justify-content-between' : null,
        // align === 'start' && direction === 'column' ? 'justify-content-start' : null,
        // align === 'center' && direction === 'column' ? 'justify-content-center' : null,
        // align === 'end' && direction === 'column' ? 'justify-content-end' : null,
        // align === 'between' && direction === 'column' ? 'justify-content-between' : null,
      ];
    },
    getDirection() {
      return [
        this.direction === 'column' ? 'flex-column' : '',
        this.direction === 'row' ? 'flex-row' : '',
      ];
    },
  },
};
</script>
