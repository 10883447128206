<template>
  <div
    class="transition-max-height w-100"
    :style="`max-height: ${appState.dialogHeight}px;`"
    >
    <slot name="dialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
};
</script>
