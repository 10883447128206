<template>
  <cc-modal
    data-ruid="modal__shop-category-filter"
    group-wrapper-class="mh-100 pt-6"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('shopFilterModalTitle')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-4 d-flex flex-column w-100"
        >
        <div class="d-flex flex-wrap">
          <cc-card
            v-for="(category, index) in companyState.availableProductCategories"
            :key="index"
            :pressable="false"
            :ruid="`category-${index}`"
            class="d-flex p-1"
            variant="plain"
            style="flex: 0 33.33%;"
            :has-shadow="filter === category"
            card-background="blue-light"
            radius="rounded-8"
            @click.native="setProductFilter(category)"
            >
            <template #body>
              <div class="d-flex flex-column align-items-center justify-content-center py-2">
                <cc-svg-base
                  :svg="`category-${category}`"
                  :color-class="filter === category ? 'svg-fill-white' : 'svg-fill-blue-lighter'"
                  />

                <cc-text
                  variant="body-xs-bold"
                  :color="filter === category ? 'white' : 'muted'"
                  class="text-capitalize pt-2"
                  >
                  {{ $t(`category${sentenceCase(category)}`) }}
                </cc-text>
              </div>
            </template>
          </cc-card>
        </div>
      </div>
    </template>

    <template #footer>
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :is-modal-button="true"
          variant="secondary"
          data-ruid="shop-category-filter__clear-filter"
          :text="$t('shopClearFilter')"
          :disabled="!filter"
          @click="
            setCompanyState(['productCategoryFilter', null]),
            $router.replace({ name: 'product-category', params: { category: 'all' } })
          "
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { sentenceCase } from '@/mixins/utils';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccCard,
    ccButton,
    ccSvgBase,
    ccText,
  },
  data() {
    return {
      id: 'shop-category-filter',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      companyState: state => state.company,
    }),
    filter() {
      return this.companyState.productCategoryFilter;
    },
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
      setAppState: 'common/setAppState',
    }),
    sentenceCase,
    setProductFilter(category) {
      if (this.companyState.productCategoryFilter === category) {
        this.setCompanyState(['productCategoryFilter', null]);
        this.$router.replace({ name: 'product-category', params: { category: 'all' } });
      } else {
        this.setCompanyState(['productCategoryFilter', category]);
        this.$router.replace({ name: 'product-category', params: { category } });
      }
    },
  },
};
</script>
