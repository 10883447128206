<template>
  <cc-view
    :id="selectorId"
    >
    <cc-charts-loading
      v-if="appState.isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <template v-else>
      <validation-observer ref="form">
        <validation-provider
          v-slot="{
            invalid,
            failed,
          }"
          rules="required|minmax:3,30|alphaNumericWithSpaces"
          :name="$t('groupLeaderboardGroupNameInputLabel')"
          >
          <cc-input
            v-model.trim="groupName"
            data-ruid="input-group-name"
            :label="$t('groupLeaderboardGroupNameInputLabel')"
            :state="failed && invalid ? 'is-invalid' : null"
            :has-visible-label="1"
            :placeholder="$t('groupLeaderboardGroupNameInputPlaceholder')"
            :disabled="!isAdminOrOwner"
            class="pb-4"
            />
        </validation-provider>

        <validation-provider
          v-slot="{
            invalid,
            failed,
          }"
          rules="minmax:3,60|alphaNumericWithSpaces"
          :name="$t('groupLeaderboardGroupDescriptionInputLabel')"
          >
          <!-- TODO: Placeholder has hardcoded string, replace with translation key -->
          <cc-input
            v-model.trim="groupDescription"
            data-ruid="input-group-description"
            :label="$t('groupLeaderboardGroupDescriptionInputLabel')"
            :state="failed && invalid ? 'is-invalid' : null"
            :has-visible-label="1"
            placeholder="Enter group description"
            :disabled="!isAdminOrOwner"
            class="pb-6"
            />
        </validation-provider>
      </validation-observer>

      <cc-error-message
        :message="errors[0]"
        class="pb-6"
        />

      <cc-card
        class="mb-6"
        variant="list"
        :pressable="true"
        >
        <template #body>
          <cc-settings-list-item
            v-if="hasExitGroupButton"
            data-ruid="button__group-settings__leave-group"
            icon="icons/icon-exit"
            :label="$t('groupLeaderboardLeaveGroup')"
            arrow
            :last="userRole !== 'owner'"
            @click.native="isConfirmGroupExitModalDisplayed = true"
            />
          <cc-settings-list-item
            v-if="hasDeleteGroupButton"
            data-ruid="button__group-settings__delete-group"
            icon="icons/icon-close"
            :label="$t('groupLeaderboardDeleteGroup')"
            arrow
            :last="userRole === 'owner'"
            @click.native="isConfirmGroupDeletionModalDisplayed = true"
            />
        </template>
      </cc-card>

      <cc-button
        v-if="isAdminOrOwner"
        data-ruid="button__group-settings__save"
        class="mb-4"
        :text="$t('txtSave')"
        :has-loading-feedback="true"
        @click="updateGroupProfile"
        />
    </template>

    <cc-confirm-group-deletion-modal
      v-if="isConfirmGroupDeletionModalDisplayed"
      :data="group"
      @close-modal="isConfirmGroupDeletionModalDisplayed = false"
      />

    <cc-confirm-group-exit-modal
      v-if="isConfirmGroupExitModalDisplayed"
      :data="group"
      @close-modal="isConfirmGroupExitModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { validate } from 'vee-validate';
import validator from '@/mixins/validator';

import ccView from '@/components/constructs/View.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

import ccConfirmGroupDeletionModal from '@/components/modals/ConfirmGroupDeletionModal.vue';
import ccConfirmGroupExitModal from '@/components/modals/ConfirmGroupExitModal.vue';

export default {
  components: {
    ccView,
    ccButton,
    ccInput,
    ccChartsLoading,
    ccErrorMessage,
    ccCard,
    ccSettingsListItem,
    ccConfirmGroupDeletionModal,
    ccConfirmGroupExitModal,
  },
  mixins: [validator],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectorId: 'group-settings',
      group: null,
      isConfirmGroupDeletionModalDisplayed: false,
      isConfirmGroupExitModalDisplayed: false,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      getGroupById: 'social/getGroupById',
      getUserRoleByGroupId: 'social/getUserRoleByGroupId',
    }),
    hasDeleteGroupButton() {
      return this.userRole === 'owner';
    },
    hasExitGroupButton() {
      return this.userRole !== 'owner';
    },
    isAdminOrOwner() {
      return this.userRole === 'owner' || this.userRole === 'admin';
    },
    userRole() {
      return this.getUserRoleByGroupId(this.$route.params.id);
    },
    groupDescription: {
      get() {
        return this.group?.group?.metaPublic?.description || '';
      },
      set(val) {
        this.group.group.metaPublic = { description: val };
      },
    },
    groupName: {
      get() {
        return this.group?.group?.name || '';
      },
      set(val) {
        this.group.group.name = val;
      },
    },
    groupLeaderboard() {
      return this.leaderboardState?.group?.currentData?.data?.[0];
    },
  },
  async created() {
    const refreshTime = 900000;

    const weeksAgo = this.$moment().diff(this.appState.queryDate, 'weeks');

    const shouldDispatchGroups = !this.leaderboardState.groups
      || weeksAgo !== this.leaderboardState.groups.windowsAgo
      || Date.now() - refreshTime > this.leaderboardState.groups.timestamp;

    const shouldDispatchGroup = !this.leaderboardState.group
      || weeksAgo !== this.leaderboardState.group.windowsAgo
      || this.leaderboardState.group.currentData.data[0].id !== this.$route.params.id
      || Date.now() - refreshTime > this.leaderboardState.group.timestamp;


    if (shouldDispatchGroups) {
      this.$log.info('social/getGroupsLeaderboard');
      await this.$store.dispatch('social/getGroupsLeaderboard');
    }

    if (shouldDispatchGroup) {
      this.$log.info('social/getGroupLeaderboard');

      await this.$store.dispatch('social/getGroupLeaderboard', {
        groupId: this.$route.params.id,
      });
    }

    this.group = cloneDeep(this.getGroupById(this.$route.params.id));

    this.$log.info('group', this.group);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    // TODO: Move/merge this into Store action `updateGroupProfile`?
    async updateGroupProfile() {
      try {
        const { groupDescription, groupName } = this;

        this.errors = [];
        this.setAppState(['isAwaiting', true]);

        // const success = await this.$refs.form.validate();
        // if (!success) {
        //   return;
        // }

        const hasGroupName = await validate(
          groupName,
          'required|minmax:3,30|alphaNumericWithSpaces',
          {
            name: this.$t('groupLeaderboardGroupNameInputLabel'),
          },
        );

        if (!hasGroupName.valid) {
          this.$log.info('hasGroupName NOK', hasGroupName);
          throw hasGroupName;
        }

        const hasGroupDescription = await validate(
          groupDescription,
          'minmax:3,30|alphaNumericWithSpaces',
          {
            name: this.$t('groupLeaderboardGroupDescriptionInputLabel'),
          },
        );

        if (!hasGroupDescription.valid) {
          this.$log.info('hasGroupDescription Name NOK', hasGroupDescription);
          throw hasGroupDescription;
        }
        const result = await this.$store.dispatch('social/updateGroupProfile', this.group.group);

        await this.$store.dispatch('social/getGroupsLeaderboard', {
          forceUpdate: true,
        });

        await this.$store.dispatch('social/getGroupLeaderboard', {
          groupId: this.id,
          forceUpdate: true,
        });

        this.$log.info('result', result);

        if (!result.message && !result?.response?.status) {
          this.$router.push({ name: 'group', params: { id: this.id } });
        }
      } catch (error) {
        const validationError = error?.errors?.[0];

        if (validationError) {
          this.errors.push(error.errors[0]);
        }

        this.$log.warn('updateGroupProfile', error);
      } finally {
        this.setAppState(['isAwaiting', false]);
      }
    },
  },
};
</script>
