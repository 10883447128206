/* eslint-disable no-param-reassign */
import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { maskUrlParam } from './utils';

const dsn = process.env.VUE_APP_SENTRY_DSN;
const isDevelopment = process.env.VUE_APP_ENV === 'development';

Sentry.init({
  debug: false,
  Vue,
  /**
   * Turned OFF Sentry for development as we emit too many events during development
   * But it's also important to check final state of our implementation with Sentry turned ON
   * So please, before any PR submission, turn it ON and give it a couple of rounds to make sure
   * no errors are caught by Sentry.
   */
  dsn: isDevelopment ? '' : dsn,
  tracingOptions: {
    trackComponents: true,
  },
  integrations(integrations) {
    /**
     * Since Sentry 6.8.0 Dedupe integration became default which prevents
     * duplicate looking like events from being dispatched
     * e.g. dispatching a static string multiple times
     * As we do not want this previous, filter out Dedupe integration.
     */
    return integrations.filter(integration => integration.name !== 'Dedupe');
  },
  attachProps: true,
  logErrors: true,
  environment: process.env.VUE_APP_ENV,
  release: `climate-challenge@${process.env.VERSION}`,
  ignoreErrors: ['Network Error'],
  beforeBreadcrumb(breadcrumb, hint) {
    const { category, data } = breadcrumb;

    // Vue.$log.info('hint', hint);
    // Vue.$log.info('breadcrumb.data', data);
    // Vue.$log.info('breadcrumb.category', category);

    /**
     * Match with following parameters when category is `navigation`. SD stands for `sensitive data`
     * `phonenumber`
     */
    const hasSDOnFromPath = data?.from?.match(/phonenumber/i);
    const hasSDOnToPath = data?.to?.match(/phonenumber/i);

    if (category === 'navigation' && (hasSDOnFromPath || hasSDOnToPath)) {
      if (hasSDOnFromPath) {
        const url = maskUrlParam(`${window.location.origin}${data.from}`, 'phoneNumber');
        data.from = `${url.pathname}${url.search}`;
      }

      if (hasSDOnToPath) {
        const url = maskUrlParam(`${window.location.origin}${data.to}`, 'phoneNumber');
        data.to = `${url.pathname}${url.search}`;
      }
    }

    /**
     * Match with following url paths or parameters when category is `xhr`.
     * If we add more senstive data to output that Sentry might pick up,
     * we must update this hook in order to comply with privacy regulations.
     *
     * `normalize?number=%2B`
     * `&phoneNumber=`
     * `requestVerificationRecovery/%2`
     * `requestVerification/%2B`
     * `verifyTokenRecovery/%2B`
     * `verifyToken/%2B`
     *
     */
    if (category === 'xhr' && data?.url) {
      if (data?.url?.match(/normalize\?number=%2B/i)) {
        data.url = maskUrlParam(data.url, 'number').href;
      }

      if (data?.url?.match(/&phoneNumber=/i)) {
        data.url = maskUrlParam(data.url, 'phoneNumber').href;
      }

      if (data?.url?.match(/requestVerificationRecovery\/%2B/i)) {
        const url = new URL(breadcrumb.data.url);
        const { origin, pathname } = url;
        data.url = `${origin}${pathname.slice(0, pathname.lastIndexOf('%2B'))}******`;
      }

      if (data?.url?.match(/requestVerification\/%2B/i)) {
        const url = new URL(breadcrumb.data.url);
        const { origin, pathname } = url;
        data.url = `${origin}${pathname.slice(0, pathname.lastIndexOf('%2B'))}******`;
      }

      if (data?.url?.match(/verifyTokenRecovery\/%2B/i)) {
        const url = new URL(breadcrumb.data.url);
        const { origin, pathname } = url;
        data.url = `${origin}${pathname.slice(0, pathname.lastIndexOf('%2B'))}******`;
      }

      if (data?.url?.match(/verifyToken\/%2B/i)) {
        const url = new URL(breadcrumb.data.url);
        const { origin, pathname } = url;
        data.url = `${origin}${pathname.slice(0, pathname.lastIndexOf('%2B'))}******`;
      }
    }
    if (category === 'ui.click') {
      const { dataset } = hint.event;
      if (hint.event && hint.event.composedPath()) {
        hint.event.composedPath().forEach((path) => {
          if (path.dataset && path.dataset.ruid) {
            breadcrumb.message = `${breadcrumb.message} : ${path.dataset.ruid}`;
          }
        });
      }

      if (dataset && dataset.ruid) {
        breadcrumb.message = `${breadcrumb.message} : ${dataset.ruid}`;
      }
    }

    // Vue.$log.info('breadcrumb.data', breadcrumb.data);

    return breadcrumb;
  },
  beforeSend(event) {
    /**
     * beforeSend is the last hook before the event is dispatched to Sentry.
     * Check request url in case it includes certain parameters.
     * Current checks followings:
     * `phoneNumber`
     */
    const hasSDOnUrl = event.request.url.match(/phonenumber/i);
    if (hasSDOnUrl) {
      event.request.url = maskUrlParam(event.request?.url, 'phoneNumber').href;
      // Vue.$log.info('beforeSend.event', event);
    }
    return event;
  },
});

const sentryPlugin = (store) => {
  store.subscribe((mutation, state) => {
    // Passing some properties from appState to Sentry
    if (state.common.appState) {
      Sentry.configureScope((scope) => {
        const {
          appId,
          userLang,
          routingTo,
          sdkVersion,
          tripDataSdkVersion,
          nativeAppVersion,
          isOnline,
          isDocumentVisible,
          viewWillBeShown,
          locationPermission,
          preciseLocation,
          motionPermission,
          notificationPermission,
          contactsPermission,
          cellularTripDataUpload,
        } = state.common.appState || {};
        scope.setTag('appId', appId);
        scope.setTag('user.lang', userLang);
        scope.setTag('route', routingTo.name);
        scope.setTag('sdk.version', sdkVersion);
        scope.setTag('tripDataSDK.version', tripDataSdkVersion);
        scope.setTag('nativeApp.version', nativeAppVersion);
        scope.setTag('isOnline', isOnline);
        scope.setTag('isDocumentVisible', isDocumentVisible);
        scope.setTag('viewWillBeShown', viewWillBeShown);
        scope.setTag('locationPermission', locationPermission);
        scope.setTag('preciseLocation', preciseLocation);
        scope.setTag('motionPermission', motionPermission);
        scope.setTag('notificationPermission', notificationPermission);
        scope.setTag('contactsPermission', contactsPermission);
        scope.setTag('cellularTripDataUpload', cellularTripDataUpload);
      });
    }

    if (state.user) {
      const {
        accessToken, isLoggedIn, isNewUser, isGuestUser, user, userTags,
      } = state.user || {};

      if (user?.id) {
        Sentry.setUser({ id: user?.id });
      }

      Sentry.configureScope((scope) => {
        scope.setTag('accessToken', !!accessToken);
        scope.setTag('isLoggedIn', isLoggedIn);
        scope.setTag('isNewUser', isNewUser);
        scope.setTag('isGuestUser', isGuestUser);
        scope.setTag('hasVerifiedEmail', !!user?.email?.address);
        scope.setTag('hasVerifiedPhoneNumber', !!user?.phone?.verified);
        scope.setTag('userCreatedAt', user?.createdAt);
        scope.setExtras({ userTags: userTags && [...userTags] });
      });
    }
  });
};

export default sentryPlugin;
