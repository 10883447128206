<template>
  <cc-modal
    data-ruid="modal__add-member"
    group-wrapper-class="safe-area-inset-top mh-100"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('groupLeaderboardAddToGroup')"
    @close-modal="$emit('close-modal')"
    >
    <template #body="{ close }">
      <div
        id="scrollableComponent"
        class="px-5"
        >
        <code
          v-if="false"
          id="debugging-info"
          class="font-size-12"
          >
          {{ data.id }}
          <br>
          contactList: {{ contactList }}
          <br>
          preselectedContacts: {{ preselectedContacts }}
          <br>
          selectedContacts: {{ selectedContacts }}
          <br>
          hasSelectedAndPreSelectedEqual: {{ hasSelectedAndPreSelectedEqual }}
          <br>
          hasAllContactsPreSelected: {{ hasAllContactsPreSelected }}
          <br>
          hasSelectedContacts: {{ hasSelectedContacts }}
        </code>


        <cc-section-header
          v-if="true"
          :title="$t('groupLeaderboardAddFriends')"
          svg="illustrations/add-members"
          />

        <div v-if="contactList.length > 0">
          <cc-text
            color="muted"
            class="text-center"
            >
            {{ $t('groupLeaderboardAddToGroupDescription', { groupName: data.group.name }) }}
          </cc-text>
        </div>

        <cc-card
          v-if="true"
          :pressable="true"
          class="py-5"
          variant="list"
          card-background="blue-light"
          :has-shadow="false"
          >
          <template #body>
            <cc-settings-list-item
              v-if="isAdminOrOwner && hasSDKVersion('1.8.0', '1.6.0')"
              data-ruid="button__group-view__add-external-participants"
              icon="icons/icon-invite-by-sms"
              :label="$t('txtInviteContacts')"
              :last="true"
              cl
              arrow
              @click.native="addExternalMembers(close)"
              />
          </template>
        </cc-card>


        <div v-if="contactList.length > 0">
          <cc-text
            color="muted"
            class="pb-4 text-center"
            >
            {{ $t('groupLeaderboardAddToGroupHint') }}
          </cc-text>

          <div
            v-for="(contact, index) in contactList"
            :key="index"
            :class="contact ? 'pt-4' : null"
            >
            <cc-wrapper
              direction="row"
              justify="start"
              align="center"
              class="w-100 pb-4"
              @click.native="isPreselectedContact(index) ? '' : selectContact(index)"
              >
              <div class="flex-grow-1 font-weight-sbold text-capitalize text-left">
                {{ contact }}
              </div>

              <cc-wrapper
                justify="center"
                align="center"
                >
                <div
                  v-if="selectedContacts.includes(index)"
                  class="mr-1"
                  >
                  <cc-svg-base
                    :key="contact"
                    color-class="svg-fill-primary"
                    svg="icons/icon-check"
                    />
                </div>
                <div
                  v-else
                  class="border-radius-full checkbox-unchecked"
                  />
              </cc-wrapper>
            </cc-wrapper>

            <cc-divider
              v-if="index !== contactList.length - 1"
              class=""
              style="flex-basis: 100%;"
              />
          </div>
        </div>

        <div v-else>
          <cc-text
            align="center"
            color="muted"
            class="py-2"
            >
            {{ $t('groupLeaderboardAddFriendsEmptyState') }}
          </cc-text>
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-text v-if="contactList.length > 0 && hasAllContactsPreSelected">
          {{ $t('allContactsArePreSelected') }}
        </cc-text>

        <cc-button
          v-else-if="contactList.length > 0"
          data-ruid="add-member-modal-primary-action"
          :is-modal-button="true"
          :text="
            $t(
              hasSelectedAndPreSelectedEqual || !hasSelectedContacts
                ? 'selectContact'
                : 'groupLeaderboardAddMembers',
            )
          "
          :disabled="hasSelectedAndPreSelectedEqual || !hasSelectedContacts"
          @click="addMembers(close)"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

export default {
  components: {
    ccModal,
    ccCard,
    ccSectionHeader,
    ccWrapper,
    ccText,
    ccButton,
    ccSvgBase,
    ccDivider,
    ccSettingsListItem,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'add-member-modal',
      group: [],
      contactList: [],
      selectedContacts: [],
      preselectedContacts: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasSDKVersion: 'common/hasSDKVersion',
    }),
    permissionGiven() {
      if (this.appState.contactsPermission === 'undefined') {
        return false;
      }
      return true;
    },
    hasPermission() {
      if (this.appState.contactsPermission === 'false') {
        return false;
      }
      return true;
    },
    isAdminOrOwner() {
      return this.userRole === 'owner' || this.userRole === 'admin';
    },
    userRole() {
      return this.leaderboardState?.group?.currentData?.data?.[0].userRole;
    },
    hasSelectedContacts() {
      return this.selectedContacts?.length > 0;
    },
    hasAllContactsPreSelected() {
      return this.contactList?.length === this.preselectedContacts?.length;
    },
    hasSelectedAndPreSelectedEqual() {
      if (this.selectedContacts?.length === 0 || this.preselectedContacts?.length === 0) {
        return false;
      }

      return (
        this.selectedContacts?.length === this.preselectedContacts?.length
        && this.selectedContacts?.every((value, i) => value === this.preselectedContacts?.[i])
      );
    },
  },
  mounted() {
    this.$log.info('Add Member Modal Contacts', this.userState?.contacts?.contactNames);
    this.$log.info('Add Member Modal Local data', this.data);

    // TODO: Remove the watcher, move the logic to getPreselectedContacts maybe?
    this.$watch(
      'userState.contacts.contactNames',
      (val) => {
        this.$log.info('userState.contacts.contactNames', val);
        if (val) {
          this.contactList = [...val];

          const preselectedContacts = this.getPreselectedContacts(
            this.userState.contacts.data,
            this.data.members,
          );

          this.selectedContacts = [...preselectedContacts];
          this.preselectedContacts = [...preselectedContacts];
        }
      },
      {
        immediate: true,
      },
    );
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    addExternalMembers(closeModal) {
      const { id } = this.data;

      this.$log.info('Modal data:', this.data);

      if (this.permissionGiven && this.hasPermission) {
        closeModal(() => {
          this.$router.push({ name: 'group-external-invite', params: { id } }).catch(() => {});
        });
      }
    },
    selectContact(index) {
      const elementIndex = this.selectedContacts.indexOf(index);

      if (elementIndex > -1) {
        this.selectedContacts.splice(elementIndex, 1);
      } else {
        this.selectedContacts.push(index);
      }
    },
    getPreselectedContacts(contactList, groupContacts) {
      const response = [];

      const selfLessContactsList = contactList.filter(contact => !contact.self);

      selfLessContactsList.forEach((contact, index) => {
        if (groupContacts.some(groupContact => groupContact.id === `scc_${contact.userId}`)) {
          response.push(index);
        }
      });

      return response;
    },
    isPreselectedContact(index) {
      return this.preselectedContacts.includes(index);
    },
    async addMembers(closeModal) {
      const groupId = this.data?.id;

      const selfLessContactsList = this.userState.contacts.data.filter(contact => !contact.self);

      const members = this.selectedContacts.map(contact => ({
        // email:
        // phone:
        id: selfLessContactsList[contact].userId,
        role: 'participant',
      }));

      try {
        await this.$store.dispatch('social/addMembers', { groupId, members });
        await this.$store.dispatch('social/getGroupLeaderboard', { groupId, forceUpdate: true });


       closeModal(() => {
          this.$router.push({ name: 'group', params: { id: groupId }, query: { t: Date.now() } });
        });
      } catch (err) {
        this.$log.info('getGroupLeaderboard', err);
      }
    },
  },
};
</script>
