const testing = !!parseInt(process.env.VUE_APP_E2E, 10);

const ruid = {
  bind(el, binding) {
    if (!testing) return;

    if (binding.value) {
      // eslint-disable-next-line
      el.dataset.ruid = binding.value;
    }
  },
};

export default {
  install(Vue) {
    Vue.directive('ruid', ruid);
  },
};
