<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <div
      :id="`${id}__challenges-control-group`"
      class="bg-blue-dark px-3 pb-4"
      :class="hasShadow ? 'ui-control-shadow' : null"
      :data-ui-control="true"
      >
      <cc-challenges-control-group
        id="canton-leaderboard"
        :has-type-segment="true"
        />
    </div>

    <cc-view
      :id="id"
      :has-full-width="true"
      :is-sub-view="true"
      :has-overflow-scroll="false"
      :has-router-view="true"
      :has-ui-controls="true"
      :is-loading="isLoading"
      :has-loading-view="true"
      />
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccChallengesControlGroup from '@/components/achievements/ChallengesControlGroup.vue';

export default {
  components: {
    ccView,
    ccChallengesControlGroup,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      id: 'challenges-parent',
      hasShadow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.hasShadow = true;
    }, 500);
  },
};
</script>
