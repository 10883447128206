import Vue from 'vue';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/vue';
// import uuidv4 from 'uuid/v4';

import service from '@/services/index';
import axiosInstance from '@/services/axiosClient';
import i18n from '@/i18n';
import { ActionEnum } from '@/mixins/enums';
import {
  callSdk, //
  encryptData,
  decryptData,
  isDateGivenDaysAgo,
} from '@/mixins/utils';
import { cantons } from '@/mixins/cantons';
import kibanaEvents from '@/mixins/kibanaEvents';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

// State object
const initialState = () => ({
  isLoggedIn: false,
  isNewUser: false,
  isGuestUser: false,
  accessToken: null,
  contacts: null,
  phonebook: null,
  user: null,
  wallets: null,
  userTags: [],
  userLocation: {},
});

const state = initialState();

// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

// Actions
const actions = {
  authenticate({ commit, dispatch, rootState }, jwt) {
    Vue.$log.info('Starting authenticate', jwt);

    callSdk(`action=${ActionEnum.AUTHENTICATION}&accessToken=${encodeURIComponent(jwt)}`, this);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    commit('setState', ['accessToken', jwt]);

    if (rootState.common.appState.isSandbox) {
      window.localStorage.setItem('accessTokenSandbox', jwt);
    } else {
      window.localStorage.setItem('accessToken', jwt);
    }

    /**
     * Make sure we have a valid token and have received getTimeline
     * and getUser before displaying the app
     * Router beforeEach hook calls `authenticate` action,
     * if this is rejected, hook will push user to start route
     */
    return new Promise((resolve, reject) => {
      dispatch('getUser')
        .then(async () => {
          commit('setState', ['isLoggedIn', true]);

          await dispatch('handleLanguagePreference');

          await dispatch('trips/getTimeline', {}, { root: true });
          await dispatch('company/getCompanies', {}, { root: true });

          Vue.$log.info('trips/getTimeline OK');
          Vue.$log.info('authenticate/getUser OK');
          resolve();
        })
        .catch(async (err) => {
          Vue.$log.warn('authenticate/getUser NOK response:', err);

          if (err?.response?.status === 401) {
            /**
             * Incase of 401 dispatch `signOut` to start fresh
             */
            Sentry.captureMessage(`getUser error: 401 ${err}`, 'error');
            Vue.$log.error('Calling signOut from authenticate');

            await dispatch('signOut', 'authenticate/getUser NOK');
          } else if (err?.response?.status >= 500) {
            Sentry.captureMessage(`getUser error: 5xx ${err}`, 'error');
            Vue.$log.error(`getUser error: 5xx ${err}`);

            commit('common/setAppState', ['hasFatalError', true], { root: true });
          } else if (err?.message?.includes('timeout')) {
            Vue.$log.error(`getUser error: timeout ${err}`, 'error');
            Sentry.captureMessage(`getUser error: timeout ${err}`);

            commit('common/setAppState', ['hasFatalError', true], { root: true });
          } else {
            Vue.$log.error(`getUser error: ${err}`, 'error');
            Sentry.captureMessage(`getUser error: ${err}`, 'error');

            commit('common/setAppState', ['hasFatalError', true], { root: true });
          }

          reject(err);
        });
    });
  },

  getUser({ commit, dispatch }) {
    Vue.$log.info('User: Starting getUser');

    // const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      service.auth
        .getUser()
        .then(async (response) => {
          Vue.$log.info('setState user', response);

          commit('setState', ['user', response]);

          if (!(!isEmpty(response.metaPublic) && !isEmpty(response.preferences.vehicles))) {
            await dispatch('addUserPreferences');
          }

          await dispatch('getUserTags');
          await dispatch('upsertUserMessagePreferences');

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getUser error: ${err}`);

          reject(err);
        });
    });
  },

  login({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      service.auth
        .login(payload)
        .then(async (response) => {
          commit('setState', ['accessToken', response]);
          await dispatch('authenticate', response);
          resolve();
        })
        .catch((error) => {
          Sentry.captureMessage(`login error: ${error}`);
          reject(error);
        });
    });
  },

  setUserProfile({ commit }, userProfile) {
    return new Promise((resolve, reject) => {
      service.auth
        .setUser(userProfile)
        .then((response) => {
          commit('setState', ['user', { ...response }]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`setUser error: ${err}`);
          reject(err);
        });
    });
  },

  addUserPreferences({ commit, state }) {
    Vue.$log.info('User: Starting addUserPreferences');

    return new Promise((resolve, reject) => {
      const user = cloneDeep(state.user);
      service.auth
        .setUser({
          ...user,
          preferences: {
            ...user.preferences,
            timezoneOffset: moment().utcOffset(),
            vehicles: {
              bicycle: {
                emissionType: 'human-powered',
              },
              bus: {
                emissionType: 'fossil',
              },
              car: {
                emissionType: 'fossil',
              },
            },
          },
          metaPublic: {
            ...user.metaPublic,
            gettingStartedCardImpressions: 0,
            vehiclePreferenceReminderShown: false,
            socialFeatureModalShown: false,
            cantonFeatureModalShown: false,
          },
        })
        .then((response) => {
          commit('setState', ['user', { ...response }]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`addUserPreferences error: ${err}`);
          reject(err);
        });
    });
  },

  upsertAddress({ commit, state }, address) {
    const user = cloneDeep(state.user);
    const incomingAddress = address;
    let addresses = [];

    let hasNoAddress;

    if (user?.metaPrivate?.addresses?.length) {
      try {
        hasNoAddress = !decryptData(user?.metaPrivate?.addresses, state.accessToken).length;
      } catch (error) {
        hasNoAddress = true;
      }
    } else {
      hasNoAddress = true;
    }

    Vue.$log.info('hasNoAddress', hasNoAddress);

    try {
      if (hasNoAddress) {
        /**
         * Handle if no address is present
         */
        incomingAddress.isDefault = true;
        addresses.push(incomingAddress);

        Vue.$log.info('First address', addresses);
      } else {
        /**
         * decrypt addresses to operate on
         */
        addresses = decryptData(user.metaPrivate.addresses, state.accessToken);
        const existingAddress = addresses.find(addr => addr.id === incomingAddress.id);

        if (incomingAddress.isDefault) {
          addresses.forEach((addr, i) => {
            addresses[i].isDefault = false;
          });
        }

        if (existingAddress) {
          addresses.forEach((addr, i) => {
            addresses[i] = addresses[i].id === incomingAddress.id ? incomingAddress : addr;
          });
        } else {
          addresses.push(address);
        }
      }

      /**
       * Make sure any property previously available is still intact
       * and encrypt addresses to save in user profile
       */
      addresses = encryptData(addresses, state.accessToken);
      Vue.$log.info('addresses after encryptData', addresses);

      user.metaPrivate = {
        ...user.metaPrivate,
        addresses,
      };
    } catch (error) {
      Vue.$log.info('error: encrypt/decrypt', error);
      throw error;
    }

    commit('common/setAppState', ['isAwaiting', true], { root: true });

    return service.auth
      .setUser(user)
      .then((response) => {
        Vue.$log.info('user/setUserName', response);
        commit('setState', ['user', { ...response }]);

        commit('common/setAppState', ['isAwaiting', false], { root: true });

        return response;
      })
      .catch((error) => {
        commit('common/setAppState', ['errorMessage', i18n.t('generalErrorMessage')], {
          root: true,
        });

        throw error;
      })
      .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
  },

  deleteAddress({ commit, state }, address) {
    const user = cloneDeep(state.user);
    const incomingAddress = address;
    let addresses = [];

    try {
      /**
       * decrypt addresses to operate on
       */
      addresses = decryptData(user.metaPrivate.addresses, state.accessToken);
      // addresses = user.metaPrivate.addresses;
      const addressIdx = addresses.findIndex(addr => addr.id === incomingAddress.id);

      addresses.splice(addressIdx, 1);

      /**
       * We always want to have a default address;
       * If deleted address was the default address and
       * there is still at least one address, make that the default one.
       */
      if (incomingAddress.isDefault && addresses.length) {
        addresses[0].isDefault = true;
      }
    } catch (error) {
      Vue.$log.info('error: encrypt/decrypt', error);
      throw error;
    }

    /**
     * Make sure any property previously available is still intact
     * and encrypt addresses to save in user profile
     */
    addresses = encryptData(addresses, state.accessToken);
    Vue.$log.info('addresses after encryptData', addresses);

    user.metaPrivate = {
      ...user.metaPrivate,
      addresses,
    };

    commit('common/setAppState', ['isAwaiting', true], { root: true });

    return service.auth
      .setUser(user)
      .then((response) => {
        Vue.$log.info('user/setUserName', response);
        commit('setState', ['user', { ...response }]);
        commit('common/setAppState', ['isAwaiting', false], { root: true });

        return response;
      })
      .catch((error) => {
        commit('common/setAppState', ['errorMessage', i18n.t('generalErrorMessage')], {
          root: true,
        });

        throw error;
      })
      .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
  },

  upsertUserMessagePreferences({ commit, getters }) {
    Vue.$log.info('User: Starting upsertUserMessagePreferences');

    const user = cloneDeep(state.user);
    const { preferences } = user;
    const availableMessagePreferences = getters.getAvailableMessagePreferences.messages;

    /**
     * Check if currently available tags on the platform and user profile tags
     * are equal/have same keys, if equal there is nothing to update so return.
     */
    const userTags = Object.keys(preferences?.messages?.delivery?.tags);
    const availableTags = Object.keys(availableMessagePreferences.delivery.tags);

    if (isEqual(availableTags, userTags)) {
      return false;
    }

    /**
     * Check if a tag once was available is no longer supported by the platform
     * if so remove it from user profile as its longer used.
     */
    const diffTags = userTags.filter(tag => !availableTags.includes(tag));

    if (diffTags.length) {
      diffTags.forEach(tag => delete preferences.messages.delivery.tags[tag]);
    }

    /**
     * If user profile is new or does not have any delivery tags,
     * insert the available message delivery tags of the platform
     * otherwise merge the new tags with the existing tags
     * without overriding user set values ie. Object.assign
     */
    if (isEmpty(preferences?.messages?.delivery?.tags)) {
      preferences.messages.delivery.tags = availableMessagePreferences.delivery.tags;
      Vue.$log.info('Has empty tags in profile');
    } else {
      const mergedTags = Object.assign(
        availableMessagePreferences.delivery.tags,
        preferences.messages.delivery.tags,
      );

      preferences.messages.delivery.tags = mergedTags;

      Vue.$log.info('mergedTags', mergedTags, preferences.messages);
    }

    Vue.$log.info('upsertUserMessagePreferences', user);

    return new Promise((resolve, reject) => {
      service.auth
        .setUser(user)
        .then((response) => {
          commit('setState', ['user', { ...response }]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`upsertUserMessagePreferences error: ${err}`);
          reject(err);
        });
    });
  },

  vehiclePreferenceReminderShown({ commit, state }) {
    const user = cloneDeep(state.user);
    user.metaPublic.vehiclePreferenceReminderShown = true;

    return new Promise((resolve, reject) => {
      service.auth
        .setUser(user)
        .then((response) => {
          // Vue.$log.info('vehiclePreferenceReminderShown', response);
          commit('setState', ['user', { ...response }]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`setVehiclePreferenceReminderShown error: ${err}`);
          reject(err);
        });
    });
  },

  setTransitModePreference({ commit, state }, { transitMode, fuelType }) {
    const user = cloneDeep(state.user);
    user.preferences.vehicles[transitMode].emissionType = fuelType;

    return new Promise((resolve, reject) => {
      service.auth
        .setUser(user)
        .then((response) => {
          // Vue.$log.info('user/setTransitModePreference', response);
          commit('setState', ['user', { ...response }]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`setTransitModePreference error: ${err}`);
          reject(err);
        });
    });
  },

  setUserName({ commit, state }, name) {
    const user = cloneDeep(state.user);

    commit('common/setAppState', ['isAwaiting', true], { root: true });
    commit('common/setAppState', ['errorMessage', null], { root: true });

    return service.auth
      .setUser({ ...user, name })
      .then((response) => {
        Vue.$log.info('user/setUserName', response);
        commit('setState', ['user', { ...response }]);

        commit('common/setAppState', ['isAwaiting', false], { root: true });

        return response;
      })
      .catch((error) => {
        commit('common/setAppState', ['errorMessage', i18n.t('generalErrorMessage')], {
          root: true,
        });

        throw error;
      })
      .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
  },

  setUtcOffset({ commit, state }, { utcOffset }) {
    const user = cloneDeep(state.user);
    user.preferences.timezoneOffset = utcOffset;

    service.auth
      .setUser(user)
      .then((response) => {
        // Vue.$log.info('user/setUtcOffset', response);
        commit('setState', ['user', { ...response }]);
      })
      .catch((err) => {
        Sentry.captureMessage(`setUtcOffset error: ${err}`);
      });
  },

  setSocialFeatureModalShown({ commit, state }) {
    const user = cloneDeep(state.user);

    if (user.metaPublic) {
      user.metaPublic.socialFeatureModalShown = true;

      service.auth
        .setUser(user)
        .then((response) => {
          // Vue.$log.info('user/setSocialFeatureModalShown', response);

          commit('setState', ['user', { ...response }]);
        })
        .catch((err) => {
          Sentry.captureMessage(`setSocialFeatureModalShown error: ${err}`);
        });
    }
  },

  setCantonFeatureModalShown({ commit, state }) {
    const user = cloneDeep(state.user);

    user.metaPublic.cantonFeatureModalShown = true;

    service.auth
      .setUser(user)
      .then((response) => {
        // Vue.$log.info('setCantonFeatureModalShown', response);

        commit('setState', ['user', { ...response }]);
      })
      .catch((err) => {
        Sentry.captureMessage(`setCantonFeatureModalShown error: ${err}`);
      });
  },

  setGettingStartedCardImpressions({ commit, state }, impressions) {
    const user = cloneDeep(state.user);
    user.metaPublic.gettingStartedCardImpressions = impressions;

    service.auth
      .setUser(user)
      .then((response) => {
        // Vue.$log.info('setGettingStartedCardImpressions', response);

        commit('setState', ['user', { ...response }]);
      })
      .catch((err) => {
        Sentry.captureMessage(`setGettingStartedCardImpressions error: ${err}`);
      });
  },

  setHasSeenFirstGroup({ commit, state }) {
    const user = cloneDeep(state.user);
    user.metaPublic.hasSeenFirstGroup = true;

    service.auth
      .setUser(user)
      .then((response) => {
        // Vue.$log.info('setGettingStartedCardImpressions', response);

        commit('setState', ['user', { ...response }]);
      })
      .catch((err) => {
        Sentry.captureMessage(`setHasSeenFirstGroup error: ${err}`);
      });
  },

  setHasOpenedPublicGoal({ commit, state }) {
    const user = cloneDeep(state.user);
    user.metaPublic.hasOpenedPublicGoal2024 = true;

    service.auth
      .setUser(user)
      .then((response) => {
        commit('setState', ['user', { ...response }]);
      })
      .catch((err) => {
        Sentry.captureMessage(`setHasOpenedPublicGoal error: ${err}`);
      });
  },

  setLanguagePreference({ commit, dispatch, state }, { language }) {
    return new Promise((resolve, reject) => {
      const user = cloneDeep(state.user);
      user.metaPublic.language = language;

      service.auth
        .setUser(user)
        .then(async (response) => {
          commit('setState', ['user', { ...response }]);

          await dispatch('handleLanguagePreference');

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`setLanguagePreference error: ${err}`);
          reject(err);
        });
    });
  },

  async handleLanguagePreference(
    {
      state, //
      commit,
      dispatch,
      getters,
      rootState,
    },
    language,
  ) {
    // Vue.$log.info('Starting handleLanguagePreference');

    const { user } = state;
    const { getLanguagePreference } = getters;
    const { appState, supportedLangs } = rootState.common;

    const browserLang = window.navigator.language.slice(0, 2);

    let currentLang = getLanguagePreference || language || appState.userLang || browserLang;

    if (!supportedLangs.includes(currentLang)) {
      currentLang = process.env.VUE_APP_I18N_FALLBACK_LOCALE;
    }

    moment.locale(currentLang);
    i18n.locale = currentLang;

    commit('common/setAppState', ['userLang', currentLang], { root: true });

    if (user && getLanguagePreference !== currentLang) {
      Vue.$log.info('Will dispatch setLanguagePreference', currentLang);
      await dispatch('setLanguagePreference', { language: currentLang });
    }
  },

  uploadContacts({ commit, dispatch, state }) {
    const { phonebook } = kibanaEvents;

    return new Promise((resolve) => {
      if (state.user.phone.verified) {
        commit('common/setAppState', ['contactsUploaded', false], { root: true });

        Vue.$log.info('uploadContacts dispatched');

        if (state.user?.metaPublic) {
          const { lastContactUploadTimestamp } = state.user.metaPublic;

          if (!lastContactUploadTimestamp || Date.now() - lastContactUploadTimestamp >= 86400000) {
            Vue.$log.info('Starting uploading contacts');

            callSdk(`action=${ActionEnum.REQUEST_CONTACTS_UPLOAD}`, this);

            dispatch(
              'common/insertEvent',
              {
                category: phonebook.category,
                action: phonebook.uploadIntended.action,
              },
              { root: true },
            );
          } else {
            Vue.$log.info('Last upload was less than 24 hours ago');
            commit('common/setAppState', ['contactsUploaded', true], { root: true });
          }
        } else {
          callSdk(`action=${ActionEnum.REQUEST_CONTACTS_UPLOAD}`, this);

          dispatch(
            'common/insertEvent',
            {
              category: phonebook.category,
              action: phonebook.uploadIntended.action,
            },
            { root: true },
          );

          Vue.$log.info('No lastContactUploadTimestamp found');
        }
        resolve();
      }
      resolve();
    });
  },

  setContactUploadTimestamp({ commit, state }) {
    const user = cloneDeep(state.user);
    user.metaPublic.lastContactUploadTimestamp = Date.now();

    service.auth
      .setUser(user)
      .then((response) => {
        // Vue.$log.info('setContactUploadTimestamp', response);

        commit('setState', ['user', { ...response }]);
      })
      .catch((err) => {
        Sentry.captureMessage(`setContactUploadTimestamp error: ${err}`);
      });
  },

  setCantonPreference({ commit, state }, selectedCanton) {
    const user = cloneDeep(state.user);
    user.city = selectedCanton;

    return new Promise((resolve, reject) => {
      service.auth
        .setUser(user)
        .then((response) => {
          Vue.$log.info('user/setCantonPreference', response);

          commit('setState', ['user', { ...response }]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`setCantonPreference error: ${err}`);

          reject(err);
        });
    });
  },

  requestPhoneVerification({ rootState }, { tel }) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      service.auth
        .requestVerification(tel, appState.context)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          Vue.$log.warn(`signUpWithPhone error: ${err}`);
          reject(err);
        });
    });
  },

  requestPhoneVerificationRecovery({ rootState }, { tel }) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      service.auth
        .requestVerificationRecovery(tel, appState.context)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          Vue.$log.warn(`signUpWithPhone error: ${err}`);
          reject(err);
        });
    });
  },

  createGuestAccount({ commit, dispatch, rootState }) {
    const { appState } = rootState.common;
    commit('common/setAppState', ['isAwaiting', true], { root: true });

    return new Promise((resolve, reject) => {
      service.auth
        .createGuestAccount(appState.context)
        .then(async (response) => {
          commit('setState', ['accessToken', response.accessToken]);

          window.localStorage.setItem('accessToken', response.accessToken);

          axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`;

          await dispatch('authenticate', response.accessToken);

          resolve();
        })
        .catch((err) => {
          Vue.$log.warn(`createGuestAccount error: ${err}`);

          reject(err);
        })
        .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
    });
  },

  verifyPhoneToken({ commit, dispatch, rootState }, { phoneNumber, code }) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      service.auth
        .verifyToken(phoneNumber, code, appState.context)
        .then(async (response) => {
          /**
           * JWT in the response is not necessary if user already has an anonymous account
           * and logged in and linking the phone number to an anonymous account.
           * So skip `authenticate` and just call `getUser` to get an updated profile
           */
          if (!state.accessToken) {
            Vue.$log.info('verifyPhoneNumber will authenticate');

            commit('setState', ['accessToken', response.accessToken]);
            await dispatch('authenticate', response.accessToken);

            Vue.$log.info('verifyPhoneNumber did authenticate');
          } else {
            await dispatch('getUser');
          }
          commit('setState', ['isGuestUser', false]);
          Vue.$log.info('verifyPhoneNumber before resolve');
          resolve();
        })
        .catch((err) => {
          Vue.$log.warn(`verifyPhoneNumber error: ${err}`);
          reject(err);
        });
    });
  },

  verifyPhoneTokenRecovery({ commit, dispatch, rootState }, { phoneNumber, code }) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      service.auth
        .verifyTokenRecovery(phoneNumber, code, appState.context)
        .then(async (response) => {
          /**
           * JWT in the response is not necessary if user already has an anonymous account
           * and logged in and linking the phone number to an anonymous account.
           * So skip `authenticate` and just call `getUser` to get an updated profile
           */
          if (!state.accessToken) {
            Vue.$log.info('verifyPhoneNumber will authenticate');

            commit('setState', ['accessToken', response.accessToken]);
            await dispatch('authenticate', response.accessToken);

            Vue.$log.info('verifyPhoneNumber did authenticate');
          } else {
            await dispatch('getUser');
          }
          commit('setState', ['isGuestUser', false]);
          Vue.$log.info('verifyPhoneNumber before resolve');
          resolve();
        })
        .catch((err) => {
          Vue.$log.warn(`verifyPhoneNumber error: ${err}`);
          reject(err);
        });
    });
  },
  /**
   * Reset user specific data that is in memory
   * Some properties are reset anyway in a new login right after a signout
   * so we do not need to declare everything possible to reset here.
   *
   * @param {String} reason - Optional string to identify the source
   */
  signOut({ commit }, reason) {
    Vue.$log.info('Will signOut');

    return new Promise((resolve, reject) => {
      try {
        if (reason) {
          Sentry.captureMessage(`User is signing out: ${reason}`);
        }

        localStorage.removeItem('accessToken');

        callSdk(`action=${ActionEnum.SIGN_OUT}`, this);

        delete axiosInstance.defaults.headers.common.Authorization;

        // Reset vuex state
        commit('reset', null);
        commit('trips/reset', null, { root: true });
        commit('company/reset', null, { root: true });
        commit('social/reset', null, { root: true });
        commit('achievements/reset', null, { root: true });

        commit('common/setUiState', ['routePositions', {}], { root: true });
        commit('common/setUiState', ['exitRoute', {}], { root: true });
        commit('common/setAppState', ['didFinishFeatureModalChecks', false], { root: true });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },

  checkUserStatus() {
    return new Promise((resolve) => {
      callSdk(`action=${ActionEnum.REQUEST_ACCESS_TOKEN}`, this);
      resolve();
    });
  },

  requestVerifyEmail({ commit, dispatch }, email) {
    commit('common/setAppState', ['isAwaiting', true], { root: true });
    commit('common/setAppState', ['errorMessage', null], { root: true });

    const payload = {
      email,
    };

    // await dispatch('common/setTimeout', 5000, { root: true });

    return service.auth
      .requestVerifyEmail(payload)
      .then(async (response) => {
        Vue.$log.info('requestVerifyEmail', response);

        await dispatch('getUser');
        return response;
      })
      .catch((error) => {
        // Sentry.captureMessage(`requestVerifyEmail error: ${error}`);
        /**
         * TODO: We should move this global error message to inside of the component that makes the request
         * and stop using global error message stuff.
         */
        commit('common/setAppState', ['errorMessage', i18n.t('generalErrorMessage')], {
          root: true,
        });

        throw error;
      })
      .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
  },

  deleteRequestVerifyEmail({ commit, dispatch }) {
    commit('common/setAppState', ['isAwaiting', true], { root: true });
    commit('common/setAppState', ['errorMessage', null], { root: true });

    return service.auth
      .deleteRequestVerifyEmail()
      .then(async (response) => {
        Vue.$log.info('deleteRequestVerifyEmail', response);

        await dispatch('getUser');
        return response;
      })
      .catch((error) => {
        // Sentry.captureMessage(`requestVerifyEmail error: ${error}`);
        /**
         * TODO: We should move this global error message to inside of the component that makes the request
         * and stop using global error message stuff.
         */
        commit('common/setAppState', ['errorMessage', i18n.t('generalErrorMessage')], {
          root: true,
        });

        throw error;
      })
      .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
  },

  async shouldCompanyUserBeLedToGroups({
    dispatch, //
    state,
    getters,
    rootGetters,
  }) {
    try {
      Vue.$log.info('Store: shouldCompanyUserBeLedToGroups');

      await dispatch('getUser');

      if (!getters.isCompanyUser) {
        return false;
      }

      // Vue.$log.info('shouldCompanyUserBeLedToGroups: 1');
      if (state.user?.email?.address && !state.user?.metaPublic?.hasSeenFirstGroup) {
        // Vue.$log.info('shouldCompanyUserBeLedToGroups: 2');
        Vue.$log.info('Store: user email is verified', state.user?.metaPublic?.hasSeenFirstGroup);

        await dispatch('social/getGroupsLeaderboard', null, { root: true });

        if (!rootGetters['social/hasGroupLeaderboard']) {
          // Vue.$log.info('Store: Will try showLeadToGroupsModal and hasGroupLeaderboard');
          return true;
        }
      }
      // Vue.$log.info('shouldCompanyUserBeLedToGroups: 3');

      return false;
    } catch (err) {
      Vue.$log.warn(`shouldCompanyUserBeLedToGroups error: ${err}`);
      return err;
    }
  },

  getWallets({ commit }) {
    Vue.$log.info('User: Starting getWallets');

    return new Promise((resolve, reject) => {
      service.auth
        .getWallets()
        .then(async (response) => {
          Vue.$log.info('setState wallet', response);

          commit('setState', ['wallets', response]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getWallets error: ${err}`);

          reject(err);
        });
    });
  },

  requestStoreReview({
    commit, //
    state,
    dispatch,
    rootState,
    rootGetters,
  }) {
    Vue.$log.info('Will requestStoreReview');

    const { user } = state;
    const { appState } = rootState.common;
    const { appReview } = kibanaEvents;

    if (rootGetters['common/hasSDKVersion']('1.9.3', '1.10.0')) {
      const maxReviewAmount = 3;
      const shortPeriod = 15 * 1000;
      const longPeriod = 30 * 24 * 60 * 60 * 1000;
      const period = appState.isProd || appState.isStaging ? longPeriod : shortPeriod;

      const now = Date.now();
      const lastRequestStoreReviewTimestamp = user?.metaPublic?.lastRequestStoreReviewTimestamp;
      const isTimeToReview = now - lastRequestStoreReviewTimestamp > period;

      const requestStoreReviewCounter = user?.metaPublic?.requestStoreReviewCounter || 0;
      const hasReviewLeft = requestStoreReviewCounter < maxReviewAmount;

      Vue.$log.info('requestStoreReviewCounter', requestStoreReviewCounter);
      Vue.$log.info('hasReviewLeft', hasReviewLeft);

      const updateLastRequestStoreReviewTimestamp = () => {
        const userCopy = cloneDeep(state.user);

        userCopy.metaPublic.lastRequestStoreReviewTimestamp = Date.now();
        userCopy.metaPublic.requestStoreReviewCounter = requestStoreReviewCounter
          ? requestStoreReviewCounter + 1
          : 1;

        service.auth
          .setUser(userCopy)
          .then((response) => {
            Vue.$log.info('requestStoreReview', response);

            commit('setState', ['user', { ...response }]);
          })
          .catch((err) => {
            Sentry.captureMessage(`requestStoreReview error: ${err}`);
          })
          .finally(() => {
            // Dispatch insertEvent in finally regardless setUser request was successful or not
            dispatch(
              'common/insertEvent',
              {
                category: appReview.category,
                action: appReview.requested.action,
              },
              { root: true },
            );
          });
      };

      if (!lastRequestStoreReviewTimestamp) {
        // First time review
        Vue.$log.info('1');
        callSdk(`action=${ActionEnum.REQUEST_STORE_REVIEW}`);
        // Sentry.captureMessage('requestStoreReview: first time', 'info');
        return updateLastRequestStoreReviewTimestamp();
      }

      if (isTimeToReview && hasReviewLeft) {
        // Nth time review
        Vue.$log.info('2');
        callSdk(`action=${ActionEnum.REQUEST_STORE_REVIEW}`);
        // Sentry.captureMessage('requestStoreReview: nth time', 'info');
        return updateLastRequestStoreReviewTimestamp();
      }
    }

    return false;
  },

  getUserTags({ commit }) {
    return new Promise((resolve, reject) => {
      service.auth
        .getUserTags()
        .then(async (response) => {
          Vue.$log.info('setState userTags', response);

          commit('setState', ['userTags', response]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getUserTags error: ${err}`);

          reject(err);
        });
    });
  },

  deleteUserAccount() {
    return new Promise((resolve, reject) => {
      service.auth
        .deleteUserAccount()
        .then(async (response) => {
          Vue.$log.info('deleteUserAccount', response);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`deleteUserAccount error: ${err}`);

          reject(err);
        });
    });
  },

  setOnboardCode({ dispatch, commit }, onboardCode) {
    commit('common/setAppState', ['isAwaiting', true], { root: true });

    return new Promise((resolve, reject) => {
      service.auth
        .setOnboardCode(onboardCode?.toUpperCase())
        .then(async (response) => {
          Vue.$log.info('setOnboardCode', response);
          dispatch('social/getCompanyLeaderboard', null, { root: true });
          dispatch('social/getCompanyGroupsLeaderboard', null, { root: true });
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`setOnboardCode error: ${err}`);

          reject(err);
        })
        .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
    });
  },

  removeCompany({ commit }) {
    commit('common/setAppState', ['isAwaiting', true], { root: true });

    return new Promise((resolve, reject) => {
      service.auth
        .removeCompany()
        .then(async (response) => {
          Vue.$log.info('removeCompany', response);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`removeCompany error: ${err}`);

          reject(err);
        })
        .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
    });
  },
  /**
   * https://wiki.swisscom.com/display/SCCD/Web+-+Native+Communication
   * Look for `requestUserLocation` and `userLocationListener`
   *
   * @param {Object} ctx - Vuex context
   * @param {Object} payload - User Location request properties
   * @param {String|Number} [payload.accuracy = 5] - accuracy in meters, defaults to 5 meters
   * @param {String|Number} [payload.timeout = 5] - timeout in seconds if desired, defaults to 5 seconds
   */
  requestUserLocation(ctx, payload) {
    const { accuracy = 5, timeout = 5 } = payload || {};
    Vue.$log.info('Will request user location from SDK');

    callSdk(
      `action=${ActionEnum.REQUEST_USER_LOCATION}&accuracy=${encodeURI(
        accuracy,
      )}&timeout=${encodeURI(timeout)}`,
    );
  },

  fetchUserLocation({ dispatch, rootState }) {
    const { locationPermission, preciseLocation, os } = rootState.common.appState;

    const payload = {
      timeout: 10,
    };

    switch (os) {
      case 'ios':
        /**
         * Expecting iOS to remove `preciseLocation` requirement from the REQUEST_USER_LOCATION action
         * Until then keeping the condition in place for iOS to prevent errors from being thrown
         */
        if (locationPermission === 'always' && preciseLocation === 'true') {
          Vue.$log.info('Will fetch user location in iOS');
          dispatch('requestUserLocation', payload);
        }
        break;
      case 'android':
        /**
         * Android does not require `preciseLocation`
         * when it is false, the provided location radius is coarse and is about in 2km radius
         */
        if (locationPermission === 'always') {
          Vue.$log.info('Will fetch user location in Android');
          dispatch('requestUserLocation', payload);
        }
        break;
      default:
        Vue.$log.info('Will fetch user location elsewhere');
        dispatch('requestUserLocation', payload);
        break;
    }
  },
};

// Getter functions
const getters = {
  lastTransmission(state, getters, rootState) {
    const { appState } = rootState.common;
    if (appState.lastTransmission !== null) {
      return moment(appState.lastTransmission).calendar(null, {
        sameDay: `[${i18n.t('Today')}] LT`,
        nextDay: `[${i18n.t('Tomorrow')}] LT`,
        nextWeek: 'dddd LT',
        lastDay: `[${i18n.t('Yesterday')}] LT`,
        lastWeek: `${i18n.t('LastDDDD')} LT`,
        sameElse: 'DD/MM/YYYY LT',
      });
    }
    return false;
  },

  phoneNumber({ user }) {
    return user?.phone?.number;
  },

  getUserEmail({ user }) {
    return user?.email?.address;
  },

  getUserPendingEmail({ user }) {
    return user?.email?.pendingAddress;
  },

  getFullUserCity({ user }) {
    if (cantons[user?.city]) {
      return cantons[user.city].name;
    }
    return null;
  },

  getUserDisplayName({ user }) {
    return user?.name;
  },

  getTransitModePreferences({ user }) {
    return user?.preferences?.vehicles;
  },

  getLanguagePreference({ user }) {
    return user?.metaPublic?.language;
  },

  getCantonPreference({ user }) {
    return user && user.city;
  },

  userIsVerified({ user }) {
    return user?.phone?.verified || false;
  },

  isUserAuthenticated(state) {
    return state?.isLoggedIn;
  },

  hasVerifiedEmail({ user }) {
    return user?.email?.address;
  },

  hasOpenedPublicGoal({ user }) {
    return user?.metaPublic?.hasOpenedPublicGoal2024;
  },

  getFeatureTagsOfCompany(state, getters, rootState, rootGetters) {
    const userCompanyTagId = getters.getUserCompanyTagId;
    const companyTags = rootGetters['company/getCompanyTagsByCompanyAlias'](userCompanyTagId);
    const companyFeatureTags = companyTags
      .filter(tag => tag.split(':')[0] === 'feature')
      .map(tag => tag.split(':')[1]);

    return companyFeatureTags;
  },

  getFeatureTagsOfUser(state) {
    const { userTags } = state;
    const userFeatureTags = userTags
      .filter(tag => tag.split(':')[0] === 'feature')
      .map(tag => tag.split(':')[1]);

    return userFeatureTags;
  },

  // hasFeatureTagShop(state, getters, rootState) {
  //   const { shop } = rootState.common.featureFlags;
  //   const { getFeatureTagsOfUser, getFeatureTagsOfCompany } = getters;
  //   return (
  //     (getFeatureTagsOfUser.includes('shop') || getFeatureTagsOfCompany.includes('shop')) && shop
  //   );
  // },

  hasFeatureTagShop() {
    return true;
  },

  hasFeatureChallengeReminderCard(state, getters) {
    const { getFeatureTagsOfCompany } = getters;
    return getFeatureTagsOfCompany.includes('challengeremindercard');
  },

  hasFeatureTagWallet(state, getters, rootState) {
    const { wallet } = rootState.common.featureFlags;
    const { getFeatureTagsOfUser, getFeatureTagsOfCompany } = getters;

    return (
      (getFeatureTagsOfUser.includes('wallet') || getFeatureTagsOfCompany.includes('wallet'))
      && wallet
    );
  },

  hasFeatureTagCommutePurpose(state, getters) {
    const { getFeatureTagsOfUser } = getters;
    return getFeatureTagsOfUser.includes('commutepurpose');
  },

  isCompanyUser({ userTags }) {
    const companyTags = userTags.filter(tag => tag.split(':')[0] === 'company');

    // Vue.$log.info('isCompanyUser: User companyTags', companyTags);
    return companyTags.length > 0;
  },
  getUserCompanyTagId({ userTags }, getters) {
    if (getters.isCompanyUser) {
      const companyTags = userTags.filter(tag => tag.split(':')[0] === 'company');

      const companyTagId = companyTags?.[0].split(':')[1]?.toLowerCase();

      return companyTagId;
    }
    return false;
  },
  getUserCompanyDepartment({ userTags }, getters) {
    if (getters.isCompanyUser) {
      const departmentTag = userTags.filter(tag => tag.split(':')[0] === 'department');
      const companyDepartment = departmentTag?.[0]?.split(':')[1];
      return companyDepartment;
    }
    return null;
  },
  removeCompany() {

  },

  getCompanyUserEmailDomain({ user }, getters) {
    if (getters.isCompanyUser) {
      const userEmailDomainName = user?.email?.address?.split('@')[1];

      return userEmailDomainName;
    }

    return null;
  },

  getWalletBalance({ wallets }) {
    return wallets?.[0]?.balance || 0;
  },

  getWalletId({ wallets }) {
    return wallets?.[0]?.id;
  },

  getAvailableMessagePreferences() {
    return {
      messages: {
        delivery: {
          default: {
            push: false,
            mail: true,
            inbox: true,
            sms: false,
          },
          tags: {
            'general-information:app-updates': {
              push: true,
              mail: false,
              inbox: true,
              sms: false,
            },
            'challenge:new': {
              push: true,
              mail: false,
              inbox: true,
              sms: false,
            },
            'challenge:completion': {
              push: true,
              mail: true,
              inbox: true,
              sms: false,
            },
            'newsfeed:climate-news': {
              push: true,
              mail: false,
              inbox: true,
              sms: false,
            },
          },
        },
      },
    };
  },

  getUserMessagesPreferences({ user }) {
    return user?.preferences?.messages;
  },

  getAddressById:
    ({ user }) => (addressId) => {
      try {
        // const addresses = user.metaPrivate?.addresses;
        const addresses = decryptData(user.metaPrivate?.addresses, state.accessToken);
        return addresses?.find(addr => addr.id === addressId);
      } catch (error) {
        return false;
      }
    },

  getDefaultAddress: ({ user }) => {
    try {
      // const addresses = user.metaPrivate?.addresses;
      const addresses = decryptData(user.metaPrivate?.addresses, state.accessToken);
      return addresses?.find(addr => addr.isDefault);
    } catch (error) {
      Vue.$log.info('Error: getDefaultAddress', error);
      return [];
    }
  },

  hasAddress: ({ user }) => {
    try {
      // const addresses = user.metaPrivate?.addresses;
      const addresses = decryptData(user.metaPrivate?.addresses, state.accessToken);
      return !!addresses?.length;
    } catch (error) {
      Vue.$log.info('Error: hasAddress', error);
      return false;
    }
  },

  getAddresses: ({ user }, getters) => {
    try {
      if (getters.hasAddress) {
        const addresses = decryptData(user.metaPrivate?.addresses, state.accessToken);
        return addresses;
      }
      return [];
    } catch (error) {
      Vue.$log.info('Error: getAddresses', error);
      return [];
    }
  },

  isUserCreatedDaysAgo:
    ({ user }) => (daysAgo) => {
      const isWeekOldUser = isDateGivenDaysAgo(user.createdAt, daysAgo);
      return isWeekOldUser;
    },
};

export default {
  namespaced: true,
  state: () => state,
  getters,
  actions,
  mutations,
};
