<template>
  <cc-modal
    data-ruid="modal__confirm-good-purchase"
    group-class="bg-blue-dark w-100"
    header-title=""
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <cc-wrapper
        direction="column"
        class="px-5"
        >
        <cc-svg-base svg="illustrations/gift-box" />

        <cc-heading
          align="center"
          class="pb-2 pt-6"
          >
          {{ $t('shopConfirmGoodPurchaseModalTitle') }}
        </cc-heading>

        <cc-text align="center">
          {{ $t('shopConfirmGoodPurchaseModalDescription', { price: product && product.price }) }}
        </cc-text>
      </cc-wrapper>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="column"
        class="px-5 py-5"
        >
        <cc-error-message
          :message="errors[0]"
          alignment="center"
          class="pb-3"
          />
        <cc-button
          data-ruid="global__button-confirm"
          variant="primary"
          :is-modal-button="true"
          :text="$t('txtConfirm')"
          :disabled="loading || appState.isSandbox"
          @click="confirmPurchase(close)"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  name: 'ConfirmPurchaseGoodModal',
  components: {
    ccWrapper,
    ccButton,
    ccHeading,
    ccText,
    ccModal,
    ccSvgBase,
    ccErrorMessage,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      companyState: state => state.company,
      user: state => state.user.user,
    }),
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
      getWalletId: 'user/getWalletId',
      getProductById: 'company/getProductById',
    }),
    product() {
      return { ...this.data.product };
    },
  },
  mounted() {
    const { offer } = this.kibanaEvents;

    this.$store.dispatch('common/insertEvent', {
      category: offer.category,
      action: offer.purchaseIntended.action,
      type: this.$route.params.id,
      metaPublic: {
        offerID: this.$route.params.id,
        virtualGoodId: this.product.virtualGood.id,
        unitPrice: this.product.price,
        amount: 1,
      },
    });
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    confirmPurchase(closeModal) {
      this.loading = true;
      this.errors = [];
      const offer = this.product;
      const isInventoryVoucher = this.product.virtualGood.type === 'INVENTORY_VOUCHER';
      const buyerEmailAddress = this.user.email.address;

      this.$store
        .dispatch('company/buyOffer', { offer, buyerEmailAddress, isInventoryVoucher })
        .then((response) => {
          /**
           * After the buyOffer call if current offer is not returned in getProducts
           * Push to shop route to prevent rendering errors as offer payload is not available anymore
           * (For example in case offer was deactivated while a user was browsing it)
           * Mind that an offer that is active: false can still be purchased.
           */
          closeModal(() => {
            if (!this.getProductById(this.$route.params.id)) {
              this.$router.push({ name: 'shop' });
            }
            this.loading = false;

            this.$emit('after-leave', { transactionId: response.extract.id });
          });
        })
        .catch(async (err) => {
          this.$log.info('Error: confirmPurchase', err);
          this.errors.push(this.$t('genericErrorTitle'));

          // Same reason as above call in then().
          if (!this.getProductById(this.$route.params.id)) {
            closeModal();
            this.$router.push({ name: 'shop' });
          }

          this.loading = false;
        });
    },
  },
};
</script>
