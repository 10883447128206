<template>
  <div class="mia-chart-container barchart-size align-self-center">
    <cc-charts-loading
      v-if="!avgAccumulators"
      class="chart-loading-container my-4"
      />
    <bar-chart
      v-else
      ref="chart"
      :chart-data="datacollection"
      :options="options"
      />
  </div>
</template>

<script>
import { getLabelFromLastSevenday, getEmissionFromAvgAccumulators } from '@/mixins/utils';
import BarChart from '@/charts/StackedBarChart';
import ccChartsLoading from '@/components/ChartsLoading.vue';

export default {
  name: 'EmployeeEmissionBarChart',
  components: {
    BarChart,
    ccChartsLoading,
  },
  props: {
    avgAccumulators: {
      type: Object,
      required: false,
      default: null,
    },
    lastSevenday: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      noDataValue: 25,
      emission: [0, 0, 0, 0, 0, 0, 0],
      noData: [100, 100, 100, 100, 100, 100, 100],
      datacollection: {},
      options: {},
      isFetching: false,
    };
  },
  computed: {
    barThickness() {
      return 15.8;
    },
  },
  watch: {
    avgAccumulators() {
      this.initializeData();
      this.fillData();
    },
  },
  methods: {
    initializeData() {
      this.emission = getEmissionFromAvgAccumulators(this.avgAccumulators, 'avgAccumulatorAllUsers');
      this.noData = this.emission && this.emission.length !== 0
          ? this.emission.map(co2 => (co2 ? 0 : 25))
          : [25, 25, 25, 25, 25, 25, 25];

      if (this.emission.length < 7) {
        const fillData = 7 - this.emission.length;
        for (let i = 0; i < fillData; i += 1) {
          this.emission.unshift(0);
          this.noData.unshift(25);
        }
      }
      this.labels = getLabelFromLastSevenday(this.lastSevenday);
    },
    fillData() {
      this.datacollection = {
        datasets: [
          {
            label: 'kg of CO₂',
            backgroundColor: '#ff8484',
            zeroColor: '#ffcc00',
            data: this.emission,
            barThickness: this.barThickness,
            minBarLength: 0,
          },
          {
            label: 'kg of CO₂',
            backgroundColor: '#4B97FF',
            zeroColor: '#ffcc00',
            data: this.noData,
            barThickness: this.barThickness,
            minBarLength: 0,
          },
        ],
        labels: this.labels,
      };

      this.options = {
        animation: {
          duration: 700,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: false,
                fontColor: 'rgba(184, 212, 255, 1)',
                padding: 16,
                fontFamily: 'TheSans',
                fontStyle: 'normal',
                maxTicksLimit: 9,
                maxRotation: 0,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false,
                // color: 'rgba(184, 212, 255, 1)',
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                fontColor: 'rgba(184, 212, 255, 1)',
                fontFamily: 'TheSans',
                fontStyle: 'normal',
                padding: 10,
                suggestedMax: 150,
                maxTicksLimit: 6,
                callback(value) {
                  return value / 1000;
                },
              },
              scaleLabel: {
                display: true,
                labelString: 'kg CO₂',
                fontColor: 'rgba(184, 212, 255, 1)',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                fontSize: 8.05,
                xOffset: 35,
                yOffset: 9,
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
/*  Barchart  */
.mia-chart-container.barchart-size {
  max-width: 100%;
  position: relative;
  margin: auto;
  min-height: 122px !important;
}

.mia-chart-container #rounded-bar {
  height: 122px !important;
}

.mia-chart-container .chart-loading-container {
  height: 100%;
}
</style>
