<template>
  <cc-modal
    data-ruid="modal__earth-mood-info"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('earthMoodTitle')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div class="px-5 pb-5 d-flex flex-column gap-3">
        <div>
          <strong>{{ $t('happyEarthTitle') }}: </strong>
          {{ $t('happyEarthDescriptionTxt') }}<br>
          <strong>{{ $t('sadEarthTitle') }}: </strong> {{ $t('sadEarthDescriptionTxt') }}
        </div>
        <div>
          <strong>{{ $t('donutChartTitleTxt') }}:</strong> {{ $t('donutChartTxt') }}
        </div>
        <div>
          <strong>{{ $t('Co2SavedTitle') }}:</strong> {{ $t('co2SavedTxt') }}
        </div>
        <div>
          <strong>{{ $t('kmTitle') }}:</strong> {{ $t('kmTxt') }}
        </div>
        <div>
          <strong>{{ $t('co2Title') }}:</strong> {{ $t('co2Txt') }}
        </div>
        <div>{{ $t('periodTxt') }}</div>
      </div>
    </template>
  </cc-modal>
</template>

<script>

import ccModal from '@/components/modals/Modal.vue';

export default {
  components: {
    ccModal,
  },
};
</script>
