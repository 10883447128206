<template>
  <cc-view
    :id="id"
    class="pt-2 pb-6 px-4"
    :has-full-width="hasFullWidth"
    :has-background="hasBackground"
    :is-modal="isModal"
    >
    <div v-if="isNewAddress">
      <cc-heading
        v-if="hasNewAddressTitle"
        tag="h3"
        variant="h3"
        class="py-4"
        >
        {{ $t('addDeliveryAddressTitle') }}
      </cc-heading>

      <cc-text class="pb-4">
        {{ $t('addDeliveryAddressBody') }}
      </cc-text>
    </div>

    <div v-else>
      <cc-heading
        tag="h3"
        variant="h3"
        class="py-4"
        >
        {{ $t('editDeliveryAddressTitle') }}
      </cc-heading>

      <cc-text class="pb-4">
        {{ $t('addDeliveryAddressBody') }}
      </cc-text>
    </div>

    <validation-observer ref="form">
      <form @submit.prevent="saveAddress(address)">
        <validation-provider
          v-slot="{ errors }"
          rules="required|minmax:2,30|alphaNumericWithSpaces"
          :name="$t('firstName')"
          >
          <cc-input
            v-model.trim="address.firstName"
            data-ruid="input-first-name"
            :label="$t('firstName')"
            :has-visible-label="address.firstName ? 1 : 0"
            autocomplete="given-name"
            :placeholder="$t('firstName')"
            class="pb-3"
            input-class="text-blue-lightest"
            />
          <cc-error-message :message="errors[0]" />
        </validation-provider>


        <validation-provider
          v-slot="{ errors }"
          rules="required|minmax:2,30|alphaNumericWithSpaces"
          :name="$t('lastName')"
          >
          <cc-input
            v-model.trim="address.lastName"
            data-ruid="input-last-name"
            :label="$t('lastName')"
            :has-visible-label="address.lastName ? 1 : 0"
            autocomplete="family-name"
            :placeholder="$t('lastName')"
            class="pb-3"
            input-class="text-blue-lightest"
            />
          <cc-error-message :message="errors[0]" />
        </validation-provider>


        <cc-input
          v-model.trim="address.organization"
          data-ruid="input-organization"
          :label="$t('organization')"
          :has-visible-label="address.organization ? 1 : 0"
          autocomplete="organization"
          :placeholder="`${$t('organization')} ${$t('optional')}`"
          class="pb-3"
          input-class="text-blue-lightest"
          />


        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('streetName')"
          >
          <cc-input
            v-model.trim="address.streetName"
            data-ruid="input-street-address"
            :label="$t('streetName')"
            :has-visible-label="address.streetName ? 1 : 0"
            autocomplete="address-line1"
            :placeholder="$t('streetName')"
            class="pb-3"
            input-class="text-blue-lightest"
            />
          <cc-error-message :message="errors[0]" />
        </validation-provider>


        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('houseNumber')"
          >
          <cc-input
            v-model.trim="address.houseNumber"
            data-ruid="input-house-number"
            :label="$t('houseNumber')"
            :has-visible-label="address.houseNumber ? 1 : 0"
            autocomplete="address-line2"
            :placeholder="$t('houseNumber')"
            class="pb-3"
            input-class="text-blue-lightest"
            />
          <cc-error-message :message="errors[0]" />
        </validation-provider>


        <validation-provider
          v-slot="{ errors }"
          rules="required|swissPostalCode"
          :name="$t('postalCode')"
          class="w-100"
          >
          <cc-input
            v-model.trim="address.postalCode"
            data-ruid="input-postal-code"
            :label="$t('postalCode')"
            :has-visible-label="address.postalCode ? 1 : 0"
            autocomplete="postal-code"
            maxlength="4"
            :placeholder="$t('postalCode')"
            class="pb-3"
            input-class="text-blue-lightest"
            />
          <cc-error-message :message="errors[0]" />
        </validation-provider>


        <validation-provider
          v-slot="{ errors }"
          rules="required|minChar:3"
          :name="$t('city')"
          class="w-100"
          >
          <cc-input
            v-model.trim="address.city"
            data-ruid="input-city"
            :label="$t('city')"
            :has-visible-label="address.city ? 1 : 0"
            autocomplete="address-level2"
            :placeholder="$t('city')"
            class="pb-3"
            input-class="text-blue-lightest"
            />
          <cc-error-message :message="errors[0]" />
        </validation-provider>


        <cc-input
          v-model.trim="handlePhoneNumber"
          data-ruid="input-phone-number"
          :label="$t('phoneNumber')"
          :has-visible-label="address.phoneNumber ? 1 : 0"
          autocomplete="tel"
          :placeholder="`${$t('phoneNumber')} ${ $t('optional')}`"
          class="pb-3"
          input-class="text-blue-lightest"
          />


        <cc-input
          v-model.trim="address.note"
          data-ruid="input-notes"
          :label="$t('deliveryAddressNotes')"
          :has-visible-label="address.note ? 1 : 0"
          :placeholder="`${$t('deliveryAddressNotes')} ${ $t('optional')}`"
          class="pb-3"
          input-class="text-blue-lightest"
          />


        <div class="d-flex py-6">
          <cc-text
            class="flex-grow-1"
            >
            {{ $t('deliveryAddressDefaultToggle') }}
          </cc-text>

          <toggle-button
            class="pl-1"
            :width="48"
            :height="24"
            :margin="2"
            :sync="true"
            :value="getToggleValue"
            :disabled="isDisabled"
            :color="{ unchecked: '#4B97FF', checked: '#25C98B' }"
            @change="address.isDefault = !address.isDefault"
            />
        </div>


        <cc-confirm-address-deletion-modal
          v-if="isConfirmAddressDeletionModalDisplayed"
          :data="address"
          @close-modal="isConfirmAddressDeletionModalDisplayed = false"
          />

        <cc-error-message
          class="pb-4"
          alignment="center"
          :message="error"
          />

        <cc-button
          :data-ruid="`${id}__primary-action`"
          :text="isNewAddress ? $t('saveAddress') : $t('updateAddress')"
          class="mb-4"
          :has-loading-feedback="true"
          type="submit"
          />
      </form>
    </validation-observer>

    <cc-button
      v-if="!isNewAddress"
      variant="transparent"
      :data-ruid="`${id}__primary-action`"
      :text="$t('deleteAddress')"
      @click="isConfirmAddressDeletionModalDisplayed = true"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import uuidv4 from 'uuid/v4';
import validator from '@/mixins/validator';

import ccHeading from '@/components/primitives/Heading.vue';
import ccView from '@/components/constructs/View.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';
import ccConfirmAddressDeletionModal from '@/components/modals/ConfirmAddressDeletionModal.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccView,
    ccInput,
    ccButton,
    ccText,
    ccHeading,
    ccConfirmAddressDeletionModal,
    ccErrorMessage,
  },
  mixins: [validator],
  beforeRouteEnter(to, from, next) {
    if (to.params.type === 'new') {
      // eslint-disable-next-line no-param-reassign
      to.meta.title = 'addDeliveryAddressTitle';
    }
    if (to.params.type === 'address') {
      // eslint-disable-next-line no-param-reassign
      to.meta.title = 'editDeliveryAddressTitle';
    }
    next();
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
    isNewAddr: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasFullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasNewAddressTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      id: 'delivery-address',
      isConfirmAddressDeletionModalDisplayed: false,
      error: null,
      address: {
        id: uuidv4(),
        firstName: '',
        lastName: '',
        streetName: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        countryCode: 'CH',
        phoneNumber: '',
        organization: '',
        note: '',
        isDefault: false,
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    ...mapGetters({
      hasAddress: 'user/hasAddress',
      getAddressById: 'user/getAddressById',
      getDefaultAddress: 'user/getDefaultAddress',
    }),
    handlePhoneNumber: {
      get() {
        return this.address.phoneNumber;
      },
      set(value) {
        this.address.phoneNumber = value?.replaceAll(' ', '').replace('(', '').replace(')', '');
      },
    },
    getToggleValue() {
      return !this.hasAddress || this.address?.isDefault;
    },
    hasDefaultAddress() {
      return this.getDefaultAddress?.id;
    },
    isDisabled() {
      return !this.hasAddress || this.getDefaultAddress?.id === this.$route?.params?.addressId;
    },
    isNewAddress() {
      return this.$route.params.type === 'new' || this.isNewAddr;
    },
  },
  mounted() {
    if (this.$route.params.addressId) {
      this.address = { ...this.getAddressById(this.$route.params.addressId) } || this.address;
    }

    if (this.isNewAddress && !this.address.phoneNumber && this.user.phone.verified) {
      this.address.phoneNumber = this.user.phone.number;
    }
  },
  methods: {
    async saveAddress(address) {
      try {
        this.$refs.form.reset();
        this.error = null;

        const success = await this.$refs.form.validate();
        if (!success) {
          return this.$log.info('Form !success', success);
        }
        await this.$store.dispatch('user/upsertAddress', address);

        if (this.isModal) {
          return this.$emit('save', address);
        }

        return this.$router.push({ name: 'delivery-addresses' });
      } catch (error) {
        this.error = this.$t('genericErrorTitle');
        return error;
      }
    },
  },
};
</script>

<style>
</style>
