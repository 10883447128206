<template>
  <div
    id="chart-legend"
    class="d-flex justify-content-around flex-wrap"
    >
    <div
      v-for="(label, i) in legend"
      :key="i"
      class="d-flex px-2 align-items-center"
      >
      <span
        :class="`${label.colorClass}`"
        class="d-inline-block legend-dot border-radius-full"
        />

      <cc-text
        variant="body-sm-bold"
        class="ml-2"
        >
        {{ $t(`legend${label.name}`) }}
      </cc-text>
    </div>
  </div>
</template>

<script>
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccText,
  },
  data() {
    return {
      legend: [
        {
          name: 'Human',
          colorClass: 'bg-primary',
        },
        {
          name: 'Electric',
          colorClass: 'bg-cc-yellow',
        },
        {
          name: 'Fossil',
          colorClass: 'bg-danger',
        },
      ],
    };
  },
};
</script>

<style>
.legend-dot {
  width: 11px;
  height: 11px;
}
</style>
