<template>
  <cc-card
    v-if="hasGettingStartedCard"
    :id="id"
    ref="gettingStartedCard"
    :ruid="id"
    :inner-title="$t('getFullStory')"
    :padded="false"
    :pressable="true"
    :button-label="$t('txtRead')"
    svg="illustrations/mountains"
    class="pb-4"
    @click.native="$router.push({ name: 'getting-started' }).catch(() => {})"
    />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ccCard from '@/components/constructs/Card.vue';

export default {
  components: {
    ccCard,
  },
  data() {
    return {
      id: 'getting-started-card',
      hasGettingStartedCard: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
    }),
    ...mapGetters({
      viewWillBeShown: 'common/viewWillBeShown',
    }),
  },
  watch: {
    viewWillBeShown: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch('common/hasGettingStartedCard')
            .then((res) => {
              this.$log.warn('hasGettingStartedCard', res);
              this.hasGettingStartedCard = true;
            })
            .catch((err) => {
              this.$log.warn('hasGettingStartedCard', err);
              this.hasGettingStartedCard = false;
            });
        }
      },
    },
  },
};
</script>
