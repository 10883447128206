<template>
  <cc-modal
    data-ruid="modal__add-delivery-address"
    group-wrapper-class="safe-area-inset-top mh-100"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('addDeliveryAddressTitle')"
    :has-footer="false"
    @close-modal="$emit('close-modal')"
    >
    <template #body="{ close }">
      <div
        id="scrollableComponent"
        class="d-flex flex-column w-100 h-100 overflow-hidden flex-grow-1"
        >
        <cc-delivery-address
          :has-background="false"
          :is-modal="true"
          :is-new-addr="true"
          class="px-5"
          :has-full-width="true"
          :has-new-address-title="false"
          @save="handleSave($event, close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccDeliveryAddress from '@/views/settings/DeliveryAddress.vue';

export default {
  components: {
    ccModal,
    ccDeliveryAddress,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'add-delivery-address-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      getAddresses: 'user/getAddresses',
    }),
  },
  methods: {
    handleSave(event, closeModal) {
      const payload = {
        name:
          this.getAddresses.length > 1
            ? 'change-delivery-address-modal'
            : 'confirm-delivery-address-modal',
        selectedAddress: event,
      };

      closeModal(() => {
        this.$emit('after-leave', payload);
      });
    },
  },
};
</script>
