<template>
  <cc-view :id="`shop__category__${category}`">
    <!-- Floating Filter Button -->
    <div
      v-if="filter"
      class="fixed-bottom mb-10 safe-area-inset-bottom d-flex align-items-center
      justify-content-center button-bottom-slide-in"
      >
      <div
        class="bg-warning rounded-16 px-4 py-1"
        @click="isShopCategoryFilterModalDisplayed = true"
        >
        <cc-text
          color="cc-yellow-darker"
          variant="body-sm-bold"
          >
          {{ $t('shopFilterActive') }}
        </cc-text>
      </div>
    </div>

    <!-- Category display and Filter Button -->
    <cc-wrapper
      direction="row"
      align="center"
      justify="between"
      class="pb-2"
      >
      <cc-heading>
        {{ filter ? $t(`category${sentenceCase(filter)}`)
          : $t('shopAllProducts') }}
      </cc-heading>

      <cc-wrapper
        direction="row"
        align="center"
        justify="center"
        @click.native="isShopCategoryFilterModalDisplayed = true"
        >
        <cc-svg-base svg="icons/icon-category-filter" />

        <cc-text
          class="pl-2"
          variant="body-sm"
          >
          {{ $t('shopFilters') }}
        </cc-text>
      </cc-wrapper>
    </cc-wrapper>

    <cc-divider class="mb-5" />

    <div class="d-flex flex-wrap pb-7">
      <cc-product-card
        v-for="(product, i) in !!filter
          ? shuffle(offersByCategory[filter], appState.sessionId)
          : shuffle(Object.values(offersByCategory).flat(), appState.sessionId)"
        :key="product.id"
        layout="vertical"
        :product="product"
        :index="i"
        />
    </div>

    <cc-shop-category-filter-modal
      v-if="isShopCategoryFilterModalDisplayed"
      @close-modal="isShopCategoryFilterModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { shuffle } from '@sjeffff/shuffle-seed';
import { sentenceCase } from '@/mixins/utils';

import ccView from '@/components/constructs/View.vue';
import ccProductCard from '@/components/shop/ProductCard.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccShopCategoryFilterModal from '@/components/modals/ShopCategoryFilterModal.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccView,
    ccProductCard,
    ccWrapper,
    ccSvgBase,
    ccHeading,
    ccText,
    ccDivider,
    ccShopCategoryFilterModal,
  },
  props: {
    category: {
      type: String,
      required: false,
      default: 'all',
    },
  },
  data() {
    return {
      // id: '',
      isShopCategoryFilterModalDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      offersByCategory: st => st.company.offersByCategory,
      companyState: state => state.company,
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      filterAvailableProductByCategory: 'company/filterAvailableProductByCategory',
    }),
    filter() {
      return this.companyState.productCategoryFilter;
    },
  },
  mounted() {
    const isValidCategory = Object.keys(this.offersByCategory).includes(
      this.$route.params?.category,
    );

    if (isValidCategory) {
      this.setCompanyState(['productCategoryFilter', this.$route.params.category]);
    } else {
      this.setCompanyState(['productCategoryFilter', null]);
    }

    if (!isValidCategory && this.$route.params?.category !== undefined && this.$route.params?.category !== 'all') {
      this.$router.replace({ name: 'product-category', params: { category: 'all' } });
    }
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
    }),
    shuffle,
    sentenceCase,
  },
};
</script>
