var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5 px-4"},[_c('div',{staticClass:"w-100 d-flex flex-row justify-content-between"},[_c('cc-section-header',{attrs:{"title":_vm.$t('publicGoalProgressMyEmission'),"align":"start","heading-align":"left"}}),_c('div',{staticClass:"my-auto pt-2"},[_c('cc-button',{attrs:{"variant":"link-normal"},nativeOn:{"click":function($event){_vm.$router
            .push({
              name: 'climate-change',
              params: {
                backTo: 'public-goal-progress',
              },
            })
            .catch(() => {})}}},[_vm._v(" "+_vm._s(_vm.$t('txtMore'))+" ")])],1)],1),_c('cc-card',{attrs:{"id":_vm.id,"ruid":_vm.id,"button-variant":"small-muted","variant":null,"card-class":"p-4"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('cc-charts-loading',{attrs:{"is-loading":_vm.isLoading}},[_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap pb-4 justify-content-between"},[_c('cc-heading',{staticClass:"pb-2 mr-1",attrs:{"variant":"h5"}},[_vm._v(" "+_vm._s(_vm.$t('publicGoalProgressCo2EmissionTitle'))+" ")]),_c('div',{staticClass:"text-center"},[_c('cc-text',{attrs:{"color":"muted","variant":"body-sm"}},[_vm._v(" "+_vm._s(_vm.dateTitle)+" ")])],1)],1),_c('div',[_c('cc-public-goal-emission-bar-chart',{attrs:{"avg-accumulators":_vm.avgAccumulators,"last-sevenday":_vm.lastSevendays}}),_c('cc-public-goal-emission-legend',{staticClass:"pt-2"})],1)])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }