<template>
  <div class="d-flex flex-column">
    <div class="align-center pt-6">
      <cc-svg-base
        svg="illustrations/public-challenge-logo-2024"
        />
    </div>
    <div class="px-4 pt-4">
      <cc-text
        variant="body-sm"
        align="center"
        >
        {{ $t('publicChallengeEmptyState') }}
      </cc-text>
    </div>
  </div>
</template>

<script>
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  name: 'EmptyStatePublicChallenge',
  components: {
    ccSvgBase,
    ccText,
  },
};
</script>
