import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import sentryPlugin from '@/mixins/sentryPlugin';
import visibilityActions from '@/mixins/visibilityActions';


// import the auto exporter
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [sentryPlugin, visibilityActions],
});
