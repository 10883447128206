<template>
  <cc-view :id="id">
    <cc-notification-toaster
      v-if="false"
      :enabled="false"
      :debug="false"
      />


    <div v-if="true">
      <cc-wrapper

        direction="row"
        align="center"
        class="pt-8 pb-4 "
        >
        <cc-button
          type="secondary"
          class="mr-2"
          @click="setSocialState(['notificationCurrentStyle', changeCurrentStyle()])"
          >
          Change style
        </cc-button>
        <cc-button
          type="secondary"
          class="ml-2"
          @click="$store.dispatch('social/pushNotification')"
          >
          Push
        </cc-button>
      </cc-wrapper>

      <div class="text-monospace font-size-12">
        <div
          v-for="(value, key ,i) in notificationState"
          :key="i"
          class="pb-2"
          >
          {{ key }} : {{ value }}
        </div>

        <div
          v-for="(item, n) in notificationsQueue"
          :key="item.meta.messageId"
          class="pb-2"
          >
          <div
            v-if="n === 0"
            class="pb-2"
            >
            notificationsQueue:
          </div>
          {{ item.meta.messageId }}
        </div>
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccNotificationToaster from '@/components/NotificationToaster.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';

export default {
  components: {
    ccView,
    ccNotificationToaster,
    ccButton,
    ccWrapper,
  },
  data() {
    return {
      id: 'push-notification-debugger',
      notificationState: null,
    };
  },
  computed: {
    ...mapState({
      notificationCurrentStyle: state => state.social.notificationCurrentStyle,
      notificationsQueue: state => state.social.notificationsQueue,
    }),
    notificationsQueueLength() {
      return this.notificationsQueue?.length;
    },
  },
  mounted() {
    this.$root.$on('notificationState', (payload) => {
      // this.$log.info('notificationState', payload);
      this.notificationState = payload;
    });
  },
  methods: {
    ...mapMutations({
      setSocialState: 'social/setState',
    }),
    changeCurrentStyle() {
      switch (this.notificationCurrentStyle) {
        case 0:
          return 1;
        case 1:
          return 2;
        case 2:
          return 3;
        case 3:
          return 0;
        default:
          return 0;
      }
    },
  },
};
</script>

<style>
</style>

