<template>
  <cc-view
    :id="id"
    >
    <template
      v-for="(partner) in partners"
      >
      <div
        :key="partner.svg"
        class="d-flex flex-column mb-6 pb-6"
        >
        <cc-svg-base
          class="mb-4"
          :svg="`partner-logo/${partner.svg}`"
          />

        <cc-text>
          {{ $t(partner.description) }}
        </cc-text>
      </div>
    </template>

    <div class="list-border my-6" />


    <cc-heading>
      {{ $t('technicalPartners') }}
    </cc-heading>

    <div
      v-for="(technicalPartner) in technicalPartners"
      :key="technicalPartner.svg"
      class="d-flex flex-column my-7"
      >
      <cc-svg-base
        class="mb-4"
        :svg="`partner-logo/${technicalPartner.svg}`"
        />

      <ccText>
        {{ $t(technicalPartner.description) }}
      </ccText>
    </div>
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';
import ccView from '@/components/constructs/View.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';

export default {
  components: {
    ccView,
    ccSvgBase,
    ccText,
    ccHeading,
  },
  data() {
    return {
      id: 'partners',
      technicalPartners: [
        {
          svg: 'motiontag-logo',
          description: 'motiontagDescription',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    partners() {
      const { userLang } = this.appState;

      const partners = [
        {
          svg: `swiss-energy-${userLang}`,
          description: 'swissEnergyDescription',
        },
        {
          svg: `swisscom-horizontal-${userLang}`,
          description: 'swisscomDescription',
        },
      ];
      return partners;
    },
  },
};
</script>
