<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="95"
    height="81"
    viewBox="0 0 95 81"
    >
    <g
      fill="none"
      fill-rule="evenodd"
      >
      <g transform="translate(0 3)">
        <circle
          cx="2"
          cy="33"
          r="2"
          fill="#0A6EFA"
          />
        <circle
          cx="90"
          cy="2"
          r="2"
          fill="#4B97FF"
          />
        <circle
          cx="93"
          cy="60"
          r="2"
          fill="#4B97FF"
          />
      </g>
      <g transform="translate(17)">
        <rect
          width="56.862"
          height="52.65"
          y="3.159"
          fill="#4B97FF"
          rx="11.583"
          />
        <circle
          cx="11.448"
          cy="12.232"
          r="4.077"
          fill="#003FC4"
          />
        <rect
          width="6.116"
          height="12.232"
          x="8.39"
          fill="#0A6EFA"
          rx="3.058"
          />
        <circle
          cx="45.144"
          cy="12.232"
          r="4.077"
          fill="#003FC4"
          />
        <rect
          width="6.116"
          height="12.232"
          x="42.086"
          fill="#0A6EFA"
          rx="3.058"
          />
        <path
          fill="#FFDA53"
          d="M64.7 46.23l-5.743-.861-2.586-5.488C56.116 39.338 55.59 39 55 39c-.59
          0-1.116.338-1.372.88l-2.547 5.47-5.78.88c-.566.085-1.033.483-1.217
          1.036-.19.567-.05 1.183.365 1.607l4.18 4.279-.987 6.03c-.098.603.146
          1.194.638 1.54.472.336 1.081.369 1.587.09l5.098-2.847 5.17 2.847c.508.28
          1.114.245 1.587-.09.492-.346.736-.937.638-1.54l-.99-6.039
          4.183-4.271c.415-.422.554-1.038.365-1.606-.185-.553-.65-.95-1.217-1.036z"
          />
      </g>
      <text
        fill="#FFF"
        font-family="TheSansB6-SemiBold, TheSans"
        font-size="14"
        font-weight="500"
        >
        <tspan
          x="20"
          y="77"
          >
          {{ $t('txtDaysLeft') }}
        </tspan>
      </text>
      <text
        fill="#FFF"
        font-family="TheSansB7-Bold, TheSans"
        font-size="30"
        font-weight="bold"
        >
        <tspan
          x="37.25"
          y="41"
          >
          {{ daysToWait }}
        </tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  computed: {
    daysToWait() {
      const x = this.$moment().endOf('isoWeek').tz('Europe/Busingen');
      const y = this.$moment();

      return Math.floor(this.$moment.duration(x.diff(y)).asDays());
    },
  },
};
</script>
