<template>
  <div
    v-if="recentNews.length !== 0"
    class="pt-5 px-4"
    >
    <div class="w-100 my-auto d-flex flex-row justify-content-between">
      <cc-section-header
        :title="$t('learnMore')"
        align="start"
        heading-align="left"
        />
      <div class="my-auto pt-2">
        <cc-button
          variant="link-normal"
          @click.native="$router.push({name:'news-feed',params:{
            backTo:'public-goal-progress'
          }}).catch(() => {})"
          >
          {{ $t('txtMore') }}
        </cc-button>
      </div>
    </div>
    <cc-charts-loading
      :is-loading="isLoading"
      class="bg-blue-darkest"
      >
      <cc-card
        v-for="recentNew in recentNews"
        :key="recentNew.id"
        :inner-title="null"
        :pressable="true"
        class="pb-4"
        :has-button="false"
        variant="address"
        card-class="p-4"
        @click.native="clickHandler(recentNew)"
        >
        <template #body>
          <cc-list-item
            :date="$t('newHighlightItemTitle').toUpperCase()"
            :no-padding="true"
            :image="handleImagePrefix(recentNew)"
            :title="recentNew?.title.text"
            :description-truncate-amount="50"
            :border-bottom="false"
            :square-image="true"
            />
        </template>
      </cc-card>
    </cc-charts-loading>
  </div>
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';
import ccListItem from '@/components/constructs/ListItem.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';

import { handleImagePrefix } from '@/mixins/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PublicGoalProgressNews',
  components: {
    ccListItem,
    ccCard,
    ccSectionHeader,
    ccChartsLoading,
  },
  data() {
    return {
      id: 'public-goal-progress-news',
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      processedNewsFeed: state => state.social.processedNewsFeed,
    }),
    ...mapGetters({
      getNewsFeed: 'social/getNewsFeed',
    }),
    recentNews() {
      if (!this.processedNewsFeed?.[Object.keys(this.processedNewsFeed)[0]]?.[0]) return [];
      return [this.processedNewsFeed[Object.keys(this.processedNewsFeed)[0]][0],
      this.processedNewsFeed[Object.keys(this.processedNewsFeed)[1]][0]];
    },
  },
  async mounted() {
    await this.$store.dispatch('social/getNewsFeed');
    this.isLoading = false;
  },
  methods: {
    handleImagePrefix,
    clickHandler(recentNew) {
      if (recentNew.link.text) {
        this.$store.dispatch(
          'common/openUrlInternal',
          `${recentNew.link.text}/?internalBrowser=true`,
        );
      }
    },
  },
};
</script>
