import Vue from 'vue';
import Router from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import kibanaEvents from '@/mixins/kibanaEvents';

/**
 * Each block has logical separation, please consider when importing new routes
 */


// Footprint
import Home from './views/Home.vue';
import Journeys from './views/Journeys.vue';
import ClimateChange from './views/ClimateChange.vue';
import NewsFeed from './views/NewsFeed.vue';


// Settings routes
import Settings from './views/settings/Settings.vue';
import DeliveryAddresses from './views/settings/DeliveryAddresses.vue';
import DeliveryAddress from './views/settings/DeliveryAddress.vue';
import LanguageSettings from './views/settings/LanguageSettings.vue';
import MessageDeliveryPreferences from './views/settings/MessageDeliveryPreferences.vue';
import VehicleSettings from './views/settings/VehicleSettings.vue';
import DeveloperMenu from './views/settings/DeveloperMenu.vue';
import PushNotificationDebugger from './views/PushNotificationDebugger.vue';

import About from './views/settings/About.vue';
import Partners from './views/settings/Partners.vue';
import AboutSCC from './views/settings/AboutSCC.vue';
import GettingStarted from './views/settings/GettingStarted.vue';

import Support from './views/settings/Support.vue';
import Credits from './views/settings/Credits.vue';
import DeleteAccount from './views/settings/DeleteAccount.vue';


// Onboarding routes
import SignUp from './views/onboarding/SignUp.vue';
import Start from './views/onboarding/Start.vue';
import PhoneVerification from './views/onboarding/PhoneVerification.vue';
import AccountCreated from './views/onboarding/AccountCreated.vue';
import AccountVerified from './views/onboarding/AccountVerified.vue';
import AddCompanyEmail from './views/onboarding/AddCompanyEmail.vue';
import JoinCompanyChallenge from './views/onboarding/JoinCompanyChallenge.vue';
import AddOnboardCode from './views/onboarding/AddOnboardCode.vue';
import OnboardCode from './views/onboarding/OnboardCode.vue';
import LoginByEmail from './views/onboarding/LoginByEmail.vue';


// Leaderboards routes
import FriendsLeaderboard from './views/social/FriendsLeaderboard.vue';
import CantonSelection from './views/social/CantonSelection.vue';
import CantonLeaderboard from './views/social/CantonLeaderboard.vue';
import GroupLeaderboardParent from './views/social/GroupLeaderboardParent.vue';
import GroupLeaderboard from './views/social/GroupLeaderboard.vue';
import GroupView from './views/social/GroupView.vue';
import GroupSettings from './views/social/GroupSettings.vue';
import GroupExternalInvite from './views/social/GroupExternalInvite.vue';
import JoinTheChallenge from './views/onboarding/JoinTheChallenge.vue';
import JoinGroupLeaderboard from './views/onboarding/JoinGroupLeaderboard.vue';
import CompanyLeaderboardParent from './views/social/CompanyLeaderboardParent.vue';
import CompanyLeaderboard from './views/social/CompanyLeaderboard.vue';
import CompanyGroupLeaderboard from './views/social/CompanyGroupLeaderboard.vue';
import ChallengesParent from './views/achievements/ChallengesParent.vue';

// Achievements & Challenges routes
import Achievements from './views/tabs/Achievements.vue';
import Challenge from './views/achievements/Challenge.vue';

// Shop routes
import Company from './views/shop/Company.vue';
import CompanyProfile from './views/shop/CompanyProfile.vue';
import Product from './views/shop/Product.vue';
import ProductCategory from './views/shop/ProductCategory.vue';


// Tabs and parent routes
import Footprint from './views/tabs/Footprint.vue';
import FootprintTab from './views/tabs/FootprintTab.vue';
import Leaderboards from './views/tabs/Leaderboards.vue';
import LeaderboardsTab from './views/tabs/LeaderboardsTab.vue';
import Challenges from './views/tabs/Challenges.vue';
import ChallengesTab from './views/tabs/ChallengesTab.vue';
import Shop from './views/tabs/Shop.vue';
import ShopTab from './views/tabs/ShopTab.vue';
import Wallet from './views/tabs/Wallet.vue';
import Points from './views/tabs/Points.vue';
import Messages from './views/Messages.vue';

// Public goal
import PublicGoalWelcome from './views/public-goal/PublicGoalWelcome.vue';
import PublicGoalProgress from './views/public-goal/PublicGoalProgress.vue';


// Routes available outside of the app
import ConsumeGood from './views/shop/ConsumeGood.vue';
import ConsumptionSuccessful from './views/shop/ConsumptionSuccessful.vue';
import ConsumptionFailed from './views/shop/ConsumptionFailed.vue';

import EmailCheck from './views/email-feedback/EmailCheck.vue';
import EmailSuccess from './views/email-feedback/EmailSuccess.vue';
import EmailExpired from './views/email-feedback/EmailExpired.vue';
import EmailError from './views/email-feedback/EmailError.vue';

import MIA from './views/mia/MIA.vue';

import PageNotFound from './views/PageNotFound.vue';

// import TestPage from './views/TestPage.vue';


// @Willem do we still use onboarding in any form? Can we remove?
import Onboarding from './views/onboarding/Onboarding.vue';


Vue.use(Router);

const bgBlueDarkest = '#003FC4';
const bgBlueDark = '#0057DF';

function setProductMetaTitle(to) {
  const product = store.getters['company/getProductById'](to.params.id);

  if (product?.virtualGood?.resources) {
    const localizedProduct = store.getters['company/getLocalizedResource'](product.virtualGood.resources);
    // eslint-disable-next-line no-param-reassign
    to.meta.title = { title: localizedProduct.title };
    return to;
  }

  return to;
}

function setCompanyMetaTitle(to) {
  const company = store.getters['company/getCompanyById'](to.params.companyId);

  if (company?.company?.metaPublic?.resources) {
    const localizedCompany = store.getters['company/getLocalizedResource'](company.company.metaPublic.resources);
    // eslint-disable-next-line no-param-reassign
    to.meta.title = { title: localizedCompany.name };
    return to;
  }

  return to;
}

const routes = [
  /**
   * Entry route, keep clean so we have a fallback route when needed
   */
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      color: bgBlueDark,
      depth: 0,
      navBackgroundColor: 'bg-blue-dark',
    },
  },

  /**
   * Redirects
   * Following routes are used to redirect old routes to new ones
   */

  { path: '', name: 'join-challenges', redirect: { name: 'challenges' } },


  /**
   * Tab routes
   *
   * Do not use `name` property for parent routes.
   */
  {
    path: '/footprint',
    component: FootprintTab,
    meta: {
      depth: 0,
      requiresAuth: true,
      tabBarPosition: 1,
      hasTabBar: () => true,
      label: 'footprintTabLabel',
    },
    children: [
      /**
       * Each tab bar item has child routes, child with `path: ''` is always root/landing view
       */
      {
        path: '',
        name: 'footprint',
        component: Footprint,
        meta: {
          color: bgBlueDarkest,
          depth: 0,
          hasTabBar: true,
          hasNavShadow: false,
          hasAccountIcon: true,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 1,
          title: 'footprintTabLabel',
        },
      },
      {
        path: 'climate-change',
        name: 'climate-change',
        component: ClimateChange,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasBackIcon: true,
          hasTabBar: true,
          hasNavShadow: false,
          hasAccountIcon: false,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 1,
          title: 'climateChangeSectionTitle',
        },
      },
      {
        path: 'journeys',
        name: 'journeys',
        component: Journeys,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasBackIcon: true,
          hasTabBar: true,
          hasNavShadow: false,
          hasAccountIcon: false,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 1,
          title: 'yourJourneysSectionTitle',
        },
      },
    ],
  },
  {
    path: '/challenges',
    component: ChallengesTab,
    props: true,
    meta: {
      color: bgBlueDarkest,
      depth: 0,
      requiresAuth: true,
      tabBarPosition: 2,
      hasTabBar: () => true,
      label: 'challengesTabLabel',
    },
    children: [
      {
        path: '',
        component: ChallengesParent,
        props: true,
        meta: {
          color: bgBlueDarkest,
          depth: 0,
          hasTabBar: true,
          hasNavShadow: false,
          hasAccountIcon: true,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 2,
          title: 'challengesTabLabel',
        },
        /**
         * These children have `hasLoadingView` false because their Parent(ChallengesParent) handles it
         * Parent handles it because we do not want segmented contol on the parent to be affected by it
         */
        children: [
          {
            path: '',
            name: 'challenges',
            component: Challenges,
            meta: {
              color: bgBlueDarkest,
              depth: 0,
              hasTabBar: true,
              hasLoadingView: false,
              hasNavShadow: false,
              hasBackIcon: false,
              hasAccountIcon: true,
              navBackgroundColor: 'bg-blue-dark',
              requiresAuth: true,
              tabBarPosition: 2,
              title: 'challengesTabLabel',
            },
          },
          {
            path: 'achievements',
            name: 'achievements',
            component: Achievements,
            meta: {
              color: bgBlueDarkest,
              depth: 1,
              hasTabBar: true,
              hasLoadingView: false,
              hasNavShadow: false,
              hasBackIcon: false,
              hasAccountIcon: true,
              navBackgroundColor: 'bg-blue-dark',
              requiresAuth: true,
              tabBarPosition: 2,
              title: 'achievementsTabLabel',
            },
          },
        ],
      },
      {
        path: ':challengeId',
        name: 'challenge',
        props: true,
        component: Challenge,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasTabBar: true,
          hasNavShadow: true,
          hasLoadingView: true,
          hasBackIcon: true,
          hasAccountIcon: false,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 2,
          title: 'challengeLabel',
        },
      },
    ],
  },
  {
    path: '/leaderboards',
    component: LeaderboardsTab,
    meta: {
      depth: 0,
      requiresAuth: true,
      tabBarPosition: 2,
      hasTabBar: () => true,
      label: 'leaderboardsTabLabel',
    },
    children: [
      {
        path: '',
        name: 'leaderboards',
        component: Leaderboards,
        meta: {
          color: bgBlueDarkest,
          depth: 0,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: true,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 3,
          title: 'leaderboardsTabLabel',
        },
      },
      {
        path: 'friends',
        name: 'friends-leaderboard',
        component: FriendsLeaderboard,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasBackIcon: true,
          hasTabBar: true,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          title: 'friendsLeaderboardTitle',
        },
      },
      {
        path: 'canton',
        name: 'canton-leaderboard',
        component: CantonLeaderboard,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasBackIcon: true,
          hasTabBar: true,
          hasNavShadow: false,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          tabBarPosition: 3,
          title: 'cantonLeaderboardTitle',
        },
      },
      {
        path: 'group',
        component: GroupLeaderboardParent,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasTabBar: true,
          requiresAuth: true,
          title: 'groupLeaderboardCardTitle',
        },
        children: [
          {
            path: '',
            name: 'group-leaderboard',
            component: GroupLeaderboard,
            props: true,
            meta: {
              color: bgBlueDarkest,
              depth: 1,
              hasBackIcon: true,
              hasTabBar: true,
              hasUiControls: true,
              hasNavShadow: false,
              navBackgroundColor: 'bg-blue-dark',
              requiresAuth: true,
              title: 'groupLeaderboardCardTitle',
            },
          },
          {
            path: ':id',
            name: 'group',
            props: true,
            component: GroupView,
            meta: {
              color: bgBlueDarkest,
              depth: 2,
              hasBackIcon: true,
              hasUiControls: true,
              hasNavShadow: false,
              hasTabBar: true,
              navBackgroundColor: 'bg-blue-dark',
              requiresAuth: true,
              title: 'groupLeaderboardGroupView',
            },
          },
        ],
      },
      {
        path: 'group/:id/settings',
        name: 'group-settings',
        props: true,
        component: GroupSettings,
        meta: {
          color: bgBlueDarkest,
          depth: 3,
          hasTabBar: true,
          hasBackIcon: true,
          hasNavShadow: true,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          title: 'groupLeaderboardGroupSettings',
        },
      },
      {
        path: 'group/:id/external-invite',
        name: 'group-external-invite',
        props: true,
        component: GroupExternalInvite,
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasTabBar: true,
          hasBackIcon: true,
          hasNavShadow: false,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          title: 'txtInviteContacts',
        },
      },
      {
        path: 'company',
        component: CompanyLeaderboardParent,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasTabBar: true,
          requiresAuth: true,
          title: 'companyLeaderBoardCardTitle',
        },
        children: [
          {
            path: '',
            name: 'company-leaderboard',
            component: CompanyLeaderboard,
            meta: {
              color: bgBlueDarkest,
              depth: 2,
              hasBackIcon: true,
              hasTabBar: true,
              hasUiControls: true,
              hasNavShadow: false,
              navBackgroundColor: 'bg-blue-dark',
              requiresAuth: true,
              title: 'companyLeaderBoardCardTitle',
            },
          },
        ],
      },
      {
        path: 'company-groups',
        component: CompanyLeaderboardParent,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasTabBar: true,
          requiresAuth: true,
          title: 'companyGroupsLeaderboardCardTitle',
        },
        children: [
          {
            path: '',
            name: 'company-group-leaderboard',
            component: CompanyGroupLeaderboard,
            meta: {
              color: bgBlueDarkest,
              depth: 2,
              hasBackIcon: true,
              hasTabBar: true,
              hasUiControls: true,
              hasNavShadow: false,
              navBackgroundColor: 'bg-blue-dark',
              requiresAuth: true,
              title: 'companyGroupsLeaderboardCardTitle',
            },
          },
        ],
      },
      {
        path: 'join-the-challenge',
        name: 'join-the-challenge',
        component: JoinTheChallenge,
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasBackIcon: true,
          hasTabBar: false,
          hasNavShadow: false,
          navBackgroundColor: 'bg-blue-darkest',
          requiresAuth: true,
        },
      },
      {
        path: 'join-group-leaderboard',
        name: 'join-group-leaderboard',
        component: JoinGroupLeaderboard,
        props: true,
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasBackIcon: true,
          hasTabBar: true,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
          title: 'groupLeaderboardCardTitle',
        },
      },
      {
        path: 'canton-selection',
        name: 'canton-selection',
        component: CantonSelection,
        meta: {
          color: bgBlueDark,
          depth: 2,
          hasBackIcon: true,
          hasTabBar: false,
          hasNavShadow: false,
          navBackgroundColor: 'bg-blue-dark',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/shop',
    component: ShopTab,
    beforeEnter: async (to, from, next) => {
      try {
        const { isSandbox } = store.state.common.appState;
        const hasCompaniesWithOffers = store.state.company.companiesWithOffers.length;

        if (store.getters['user/hasFeatureTagShop'] || isSandbox) {
          if (!hasCompaniesWithOffers && !isSandbox) {
            await store.dispatch('company/getCompaniesWithOffers');
          }

          return next();
        }

        return next({ name: 'footprint' });
      } catch (error) {
        Vue.$log.error('Error: will proceed to footprint', error);

        return next({ name: 'footprint' });
      }
    },
    meta: {
      depth: 0,
      requiresAuth: true,
      tabBarPosition: 4,
      hasTabBar: () => store.getters['user/hasFeatureTagShop'] || store.state.common.appState.isSandbox,
      requiresProvision: true,
      label: 'shopTabLabel',
    },
    children: [
      {
        path: '',
        name: 'shop',
        component: Shop,
        meta: {
          color: bgBlueDarkest,
          depth: 0,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'product/:id',
        name: 'product',
        component: Product,
        props: true,
        beforeEnter: (to, from, next) => {
          setProductMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 3,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'company/:companyId/product/:id',
        name: 'product-via-company',
        component: Product,
        props: true,
        beforeEnter: (to, from, next) => {
          setProductMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 3,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'category/:category/product/:id',
        name: 'product-via-category',
        component: Product,
        props: true,
        beforeEnter: (to, from, next) => {
          setProductMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 3,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'product/:id/company-profile/:companyId',
        name: 'company-profile',
        component: CompanyProfile,
        props: route => ({ companyId: route.params.companyId }),
        beforeEnter: (to, from, next) => {
          setCompanyMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'company/:companyId/product/:id/company-profile',
        name: 'company-profile-via-product',
        component: CompanyProfile,
        props: route => ({ companyId: route.params.companyId }),
        beforeEnter: (to, from, next) => {
          setCompanyMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'category/:category/product/:id/company-profile',
        name: 'company-profile-via-product-category',
        component: CompanyProfile,
        props: route => ({ companyId: route.params.companyId }),
        beforeEnter: (to, from, next) => {
          setCompanyMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'company/:companyId/company-profile',
        name: 'company-profile-via-company',
        component: CompanyProfile,
        props: route => ({ companyId: route.params.companyId }),
        beforeEnter: (to, from, next) => {
          setCompanyMetaTitle(to);
          next();
        },
        meta: {
          color: bgBlueDarkest,
          depth: 4,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'category/:category',
        name: 'product-category',
        component: ProductCategory,
        props: true,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
      {
        path: 'company/:companyId',
        name: 'company',
        props: true,
        beforeEnter: (to, from, next) => {
          setCompanyMetaTitle(to);
          next();
        },
        component: Company,
        meta: {
          color: bgBlueDarkest,
          depth: 1,
          hasTabBar: true,
          hasNavShadow: true,
          hasAccountIcon: false,
          hasBackIcon: true,
          requiresAuth: true,
          requiresProvision: true,
          navBackgroundColor: 'bg-blue-dark',
          tabBarPosition: 4,
          label: 'shopTabLabel',
          title: 'shopTabLabel',
        },
      },
    ],
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet,
    beforeEnter: async (to, from, next) => {
      try {
        const { isSandbox } = store.state.common.appState;
        const hasCompaniesWithOffers = store.state.company.companiesWithOffers.length;


        if (store.getters['user/hasFeatureTagWallet'] || isSandbox) {
          if (!hasCompaniesWithOffers && !isSandbox) {
            await store.dispatch('company/getCompaniesWithOffers');
          }

          return next();
        }

        return next({ name: 'footprint' });
      } catch (error) {
        Vue.$log.error('Error: will proceed to footprint', error);

        return next({ name: 'footprint' });
      }
    },
    meta: {
      color: bgBlueDarkest,
      depth: 0,
      hasTabBar: () => store.getters['user/hasFeatureTagWallet'],
      hasNavShadow: true,
      hasAccountIcon: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      tabBarPosition: 5,
      label: 'walletTabLabel',
      title: 'walletTabLabel',
    },
  },
  {
    path: '/points',
    name: 'points',
    component: Points,
    // beforeEnter: async (to, from, next) => {
    //   if (store.getters['user/hasFeatureTagShop']) {
    //     return next({ name: 'footprint' });
    //   }

    //   return next();
    // },
    meta: {
      color: bgBlueDarkest,
      depth: 0,
      hasTabBar: () => !store.getters['user/hasFeatureTagWallet'],
      hasNavShadow: true,
      hasAccountIcon: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      tabBarPosition: 6,
      label: 'pointsTabLabel',
      title: 'pointsTabLabel',
    },
  },


  /**
   * Authentication & Onboarding
   */
  {
    path: '/start',
    name: 'start',
    component: Start,
    meta: {
      color: bgBlueDarkest,
      depth: 4,
      hasTabBar: false,
      hasBackIcon: false,
      navBackgroundColor: 'bg-blue-darkest',
      requiresAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: {
      color: bgBlueDarkest,
      hasBackIcon: true,
      navBackgroundColor: 'bg-blue-darkest',
      depth: 5,
    },
  },
  {
    path: '/login-by-email',
    name: 'login-by-email',
    component: LoginByEmail,
    meta: {
      color: bgBlueDarkest,
      hasBackIcon: true,
      navBackgroundColor: 'bg-blue-darkest',
      depth: 6,
    },
  },
  {
    path: '/phone-verification',
    name: 'phone-verification',
    component: PhoneVerification,
    meta: {
      color: bgBlueDarkest,
      depth: 6,
      hasBackIcon: true,
      navBackgroundColor: 'bg-blue-darkest',
    },
  },
  // @Willem do we still use onboarding in any form? Can we remove?
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
    meta: {
      color: bgBlueDarkest,
      depth: 5,
    },
  },
  {
    path: '/add-company-email',
    name: 'add-company-email',
    component: AddCompanyEmail,
    meta: {
      color: bgBlueDarkest,
      depth: 5,
      hasBackIcon: true,
      navBackgroundColor: 'bg-blue-darkest',
    },
  },
  {
    path: '/account-created',
    name: 'account-created',
    component: AccountCreated,
    props: true,
    meta: {
      color: bgBlueDarkest,
      depth: 6,
      navBackgroundColor: 'bg-blue-darkest',
    },
  },
  {
    path: '/account-verified',
    name: 'account-verified',
    component: AccountVerified,
    meta: {
      color: bgBlueDarkest,
      depth: 7,
      navBackgroundColor: 'bg-blue-darkest',
    },
  },
  // Accessed via footprint
  {
    path: '/add-onboard-code',
    name: 'add-onboard-code',
    component: AddOnboardCode,
    props: route => ({ isInOnboarding: route.params.isInOnboarding }),
    meta: {
      color: bgBlueDarkest,
      depth: 8,
      hasBackIcon: true,
      navBackgroundColor: 'bg-blue-darkest',
      hasNavShadow: false,
      requiresAuth: true,
    },
  },
  // Accessed via onboarding
  {
    path: '/add-onboarding-code',
    name: 'add-onboarding-code',
    component: AddOnboardCode,
    props: { isInOnboarding: true },
    meta: {
      color: bgBlueDarkest,
      depth: 8,
      hasBackIcon: false,
      navBackgroundColor: 'bg-blue-darkest',
      hasNavShadow: false,
      requiresAuth: true,
    },
  },
  // Accessed via footprint & settings
  {
    path: '/onboard-code',
    name: 'onboard-code',
    component: OnboardCode,
    props: route => ({ isInOnboarding: route.params.isInOnboarding }),
    meta: {
      color: bgBlueDark,
      depth: 9,
      hasBackIcon: true,
      requiresAuth: true,
      hasNavShadow: true,
      navBackgroundColor: 'route.bg-blue-dark',
      title: 'companyOrGroupCodeTitle',
    },
  },
  // Accessed via onboarding

  {
    path: '/onboarding-code',
    name: 'onboarding-code',
    component: OnboardCode,
    props: route => ({ isInOnboarding: route.params.isInOnboarding }),
    meta: {
      color: bgBlueDarkest,
      depth: 9,
      navBackgroundColor: 'bg-blue-darkest',
    },
  },
  {
    path: '/join-company-challenge',
    name: 'join-company-challenge',
    component: JoinCompanyChallenge,
    meta: {
      color: bgBlueDarkest,
      depth: 4,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: false,
      navBackgroundColor: 'bg-blue-darkest',
      requiresAuth: true,
    },
  },
  {
    path: '/footprint/public-goal/welcome',
    name: 'public-goal-welcome',
    component: PublicGoalWelcome,
    props: true,
    meta: {
      color: bgBlueDarkest,
      depth: 4,
      hasNavShadow: false,
      requiresAuth: true,
      hasTabBar: false,
      hasBackIcon: true,
    },
  },
  {
    path: '/footprint/public-goal/progress',
    name: 'public-goal-progress',
    component: PublicGoalProgress,
    props: true,
    meta: {
      color: bgBlueDarkest,
      depth: 6,
      hasTabBar: true,
      hasNavShadow: false,
      hasBackIcon: true,
      navBackgroundColor: 'bg-blue-darkest',
      requiresAuth: true,
    },
  },

  /**
   * News Feed
   */
  {
    path: '/footprint/news-feed',
    name: 'news-feed',
    component: NewsFeed,
    meta: {
      color: bgBlueDarkest,
      depth: 7,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'newsFeedSectionTitle',
    },
  },


  /**
   * Messages
   */
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      color: bgBlueDarkest,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'messagesTitle',
    },
  },


  /**
   * Settings routes
   */
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'settingsTitle',
    },
  },
  {
    path: '/settings/delivery-addresses',
    name: 'delivery-addresses',
    component: DeliveryAddresses,
    beforeEnter: (to, from, next) => {
      const { isSandbox } = store.state.common.appState;

      if (store.getters['user/hasFeatureTagShop'] || isSandbox) {
        return next();
      }

      return next({ name: 'settings' });
    },
    meta: {
      color: bgBlueDark,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'deliveryAddressesTitle',
    },
  },
  {
    path: '/settings/delivery-addresses/:type?/:addressId?',
    name: 'delivery-address',
    component: DeliveryAddress,
    beforeEnter: (to, from, next) => {
      const { isSandbox } = store.state.common.appState;

      if (store.getters['user/hasFeatureTagShop'] || isSandbox) {
        return next();
      }

      return next({ name: 'settings' });
    },
    meta: {
      color: bgBlueDark,
      depth: 3,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'deliveryAddressTitle',
    },
  },
  {
    path: '/settings/message-delivery-preferences',
    name: 'message-delivery-preferences',
    component: MessageDeliveryPreferences,
    meta: {
      color: bgBlueDark,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'notificationsTitle',
    },
  },
  {
    path: '/settings/push-notification-debugger',
    name: 'push-notification-debugger',
    component: PushNotificationDebugger,
    meta: {
      color: bgBlueDark,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'notificationsTitle',
    },
  },
  {
    path: '/settings/vehicle-settings',
    name: 'vehicle-settings',
    component: VehicleSettings,
    meta: {
      color: bgBlueDark,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'vehicleSettingsTitle',
    },
  },
  {
    path: '/settings/language-settings',
    name: 'language-settings',
    component: LanguageSettings,
    meta: {
      color: bgBlueDark,
      depth: 2,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'languageSettingsTitle',
    },
  },
  {
    path: '/settings/about',
    name: 'about',
    component: About,
    meta: {
      color: bgBlueDarkest,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'aboutSectionTitle',
    },
  },
  {
    path: '/settings/about/about-scc',
    name: 'about-scc',
    component: AboutSCC,
    meta: {
      color: bgBlueDark,
      depth: 3,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'SccTitle',
    },
  },
  {
    path: '/settings/about/partners',
    name: 'partners',
    component: Partners,
    meta: {
      color: bgBlueDark,
      depth: 3,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'txtPartners',
    },
  },
  {
    path: '/settings/about/getting-started',
    name: 'getting-started',
    component: GettingStarted,
    meta: {
      color: bgBlueDark,
      depth: 3,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      title: 'gettingStartedTitle',
    },
  },
  {
    path: '/settings/support',
    name: 'support',
    component: Support,
    meta: {
      color: bgBlueDarkest,
      depth: 2,
      hasBackIcon: true,
      hasTabBar: false,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'supportSectionTitle',
    },
  },
  {
    path: '/settings/support/credits',
    name: 'credits',
    component: Credits,
    meta: {
      color: bgBlueDarkest,
      depth: 3,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'txtCredits',
    },
  },
  {
    path: '/settings/support/delete-account',
    name: 'delete-account',
    component: DeleteAccount,
    meta: {
      color: bgBlueDarkest,
      depth: 3,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'deleteAccountSectionTitle',
    },
  },
  {
    path: '/settings/developer-menu',
    name: 'developer-menu',
    component: DeveloperMenu,
    meta: {
      color: bgBlueDarkest,
      depth: 2,
      hasBackIcon: true,
      hasNavShadow: true,
      navBackgroundColor: 'bg-blue-dark',
      requiresAuth: true,
      title: 'txtDeveloperMenu',
    },
  },


  /**
   * Routes available outside of the app
   */
  {
    path: '/email-check',
    name: 'email-check',
    component: EmailCheck,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/email-success',
    name: 'email-success',
    component: EmailSuccess,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/email-expired',
    name: 'email-expired',
    component: EmailExpired,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/email-error',
    name: 'email-error',
    component: EmailError,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/consume-good/',
    name: 'consume-good',
    component: ConsumeGood,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/consumption-successful/',
    name: 'consumption-successful',
    component: ConsumptionSuccessful,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/consumption-failed/',
    name: 'consumption-failed',
    component: ConsumptionFailed,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  {
    path: '/mia',
    name: 'mia',
    component: MIA,
    meta: {
      color: bgBlueDarkest,
      depth: 1,
    },
  },
  /**
   * Testing routes
   */
  // {
  //   path: '/test-page',
  //   name: 'test-page',
  //   component: TestPage,
  //   meta: {
  //     color: bgBlueDarkest,
  //   },
  // },


  /**
   * Catch all route must be at the bottom all the time.
   * https://router.vuejs.org/guide/essentials/dynamic-matching.html#matching-priority
   */
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      color: bgBlueDarkest,
      requiresAuth: false,
    },
  },
];


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Vue.$log.info('from name -->', from.name);
  // Vue.$log.info('to name -->', to.name);
  // Vue.$log.info('to query -->', to.query);
  let accessToken;
  const { isSandbox } = store.state.common.appState;

  /**
   * Save
   */
  const parentTab = from.path?.split('/')[1];

  if (from.matched.length && from.meta.hasTabBar) {
    store.commit('common/setExitRouteOfTab', [parentTab, from]);
  }

  store.commit('common/pushPageHistory', from.name);

  store.commit('common/setAppState', ['isRouting', true]);
  store.commit('common/setAppState', ['routingFrom', from]);
  store.commit('common/setAppState', ['routingTo', to]);

  // TODO: Remove the need for these two commits
  store.commit('common/setAppState', ['dialogHeight', 0]);
  store.commit('common/setAppState', ['displayAlert', false]);

  if (!store.state.common.appState.sessionId) {
    await store.dispatch('common/setSessionId');
    // Vue.$log.info('Created: SessionId', store.state.common.appState.sessionId);
  }

  if (to.query.appId) {
    store.commit('common/setAppState', ['appId', to.query.appId]);
    // Vue.$log.info('set appId', to.query.appId);
  }

  if (to.query.userLang || !store.state.common.appState.userLang) {
    await store.dispatch('user/handleLanguagePreference', to?.query?.userLang);
  }


  if (to.query.os) {
    store.commit('common/setAppState', ['os', to.query.os]);
    // Vue.$log.info('os', to.query.os);
  }

  if (to.query.osVersion) {
    store.commit('common/setAppState', ['osVersion', to.query.osVersion]);
    // Vue.$log.info('osVersion', to.query.osVersion);
  }

  if (to.query.isTesting) {
    store.commit('common/setAppState', ['isTesting', !!to.query.isTesting]);
    // Vue.$log.info('set isTesting', to.query.isTesting);
  }

  if (to.query.context) {
    store.commit('common/setAppState', ['context', to.query.context]);
    // Vue.$log.info('set context', to.query.context);
  }

  if (to.query.canSignOut) {
    store.commit('common/setAppState', ['canSignOut', to.query.canSignOut]);
    // Vue.$log.info('set canSignOut', to.query.canSignOut);
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (isSandbox) {
    accessToken = window.localStorage.getItem('accessTokenSandbox') || store?.state?.user?.accessToken;
  } else {
    accessToken = window.localStorage.getItem('accessToken') || store?.state?.user?.accessToken;
  }


  if (!requiresAuth) {
    next();
  } else if (requiresAuth && accessToken && !store.getters['user/isUserAuthenticated']) {
    /**
     * If there is an access token make a call first to validate it,
     * if it resolves we can proceed, if not push to start
     */
    store.dispatch('user/authenticate', accessToken)
      .then(() => {
        Vue.$log.info('Router: user/auth OK');

        next();
      })
      .catch(() => {
        Vue.$log.warn('Router: user/auth NOK');

        next({ name: 'start' });
      });
  } else if (!accessToken) {
    next({ name: 'start' });
  } else {
    next();
  }
});

router.afterEach((to) => {
  const { navigation } = kibanaEvents;

  store.commit('common/setAppState', ['isRouting', false]);


  // Nulls last route of current tab for next cycle
  const parentTab = to.matched[0].path?.split('/')[1];

  // Vue.$log.info('Router:AfterEach: exitRoute of current parent', store.state.common.uiState.exitRoute[parentTab]);

  if (store.state.common.uiState.exitRoute[parentTab]) {
    store.commit('common/setExitRouteOfTab', [parentTab, null]);
  }


  store.dispatch('common/setBackgroundColor', to.meta.color);

  try {
    store.dispatch('common/insertEvent', {
      category: navigation.category,
      action: navigation.pageView.action,
      type: to.name,
    });
  } catch (error) {
    Vue.$log.info('common/insertEvent NOK');
  }

  const body = document.getElementsByTagName('body')[0];

  setTimeout(() => {
    body.style.backgroundColor = to.meta.color;
  }, 800);
});

export default router;
