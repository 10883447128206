<template>
  <cc-view
    v-if="!isLoading"
    :id="id"
    class="d-flex flex-column"
    >
    <cc-balance-card
      v-if="hasFeatureTagWallet"
      :amount="getPlayerScore || ''"
      variant="points"
      class="pb-4"
      />
    <cc-notification-reminder v-if="hasNotificationReminder && hasActivePlayerChallenges" />
    <cc-challenge-cards />
    <cc-empty-state
      v-if="!hasActiveChallenges && !hasActivePlayerChallenges && !isLoading"
      :variant="id"
      />
    <cc-join-challenges />
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccChallengeCards from '@/components/achievements/ChallengeCards.vue';
import ccNotificationReminder from '@/components/NotificationReminder.vue';
import ccBalanceCard from '@/components/achievements/BalanceCard.vue';
import ccJoinChallenges from '@/views/achievements/JoinChallenges.vue';
import ccEmptyState from '@/components/EmptyState.vue';

export default {
  components: {
    ccView,
    ccChallengeCards,
    ccNotificationReminder,
    ccEmptyState,
    ccBalanceCard,
    ccJoinChallenges,
  },
  mixins: [viewScrollMemorizer],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      id: 'challenges',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
      achievementsState: state => state.achievements,
      companyState: state => state.company,
    }),
    ...mapGetters({
      getPlayerScore: 'company/getPlayerScore',
      activePlayerChallenges: 'achievements/activePlayerChallenges',
      hasAchievements: 'achievements/hasAchievements',
      hasActivePlayerChallenges: 'achievements/hasActivePlayerChallenges',
      hasActiveChallenges: 'achievements/hasActiveChallenges',
      hasNotificationReminder: 'common/hasNotificationReminder',
      hasFeatureTagWallet: 'user/hasFeatureTagWallet',
    }),
  },
};
</script>
