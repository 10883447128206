/* eslint-disable camelcase */
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from 'vee-validate';

import {
  email,
  min,
  required,
  regex,
  alpha_num,
} from 'vee-validate/dist/rules';

import i18n from '@/i18n';


extend('atLeastOneFriend', {
  ...required,
  validate(value, { atLeastOneFriend }) {
    return value.length >= atLeastOneFriend;
  },
  params: ['atLeastOneFriend'],
  message: (fieldName, { atLeastOneFriend }) => i18n.t('minimumFriends', { fieldName, number: atLeastOneFriend }),
});


extend('minmax', {
  validate(value, { min: minChar, max: maxChar }) {
    return value.length >= minChar && value.length <= maxChar;
  },
  params: ['min', 'max'],
  message: (fieldName, { min: minChar, max: maxChar }) => i18n.t('minMaxCharacters', { fieldName, min: minChar, max: maxChar }),
});

extend('alphaNumericWithSpaces', {
  validate(value) {
    const regExAlphabetUmlaut = /^[^!_@#$%^&*()+=[\]\\';,./{}|":<>?]+$/;
    return regExAlphabetUmlaut.test(value);
  },
  params: ['min', 'max'],
  message: fieldName => i18n.t('onlyAlphaNumericCharacters', { fieldName }),
});

extend('swissPostalCode', {
  validate(value) {
    const regExPostalCode = /^[1-9]\d{3}$/;
    return regExPostalCode.test(value);
  },
  message: () => i18n.t('invalidSwissPostalCode'),
});

extend('regex', {
  ...regex,
  message: fieldName => i18n.t('onlyAlphaNumericCharacters', { fieldName }),
});

extend('required', {
  ...required,
  message: fieldName => i18n.t('requiredField', { fieldName }),
});

extend('minChar', {
  ...min,
  validate(value, { min: minChar }) {
    return value.length >= minChar;
  },
  params: ['min'],
  message: (fieldName, { min: minChar }) => i18n.t('minCharacters', { fieldName, min: minChar }),
});

extend('email', {
  ...email,
  message: () => i18n.t('invalidEmailAddress'),
});

extend('alpha_num', {
  ...alpha_num,
  message: fieldName => i18n.t('onlyAlphaNumericCharacters', { fieldName }),
});

extend('mOrNDigits', {
  params: ['m', 'n'],
  validate(value, { m, n }) {
    const regexExp = new RegExp(`^(.{${m}}|.{${n}})$`);
    return regexExp.test(value);
  },
  message: (fieldName, { m, n }) => i18n.t('onlyMorNCharacters', { fieldName, m, n }),
});
setInteractionMode('eager');

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
