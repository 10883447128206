<template>
  <cc-view :id="id">
    <cc-card
      class="mb-6"
      outer-title="Request User Location"
      variant="list"
      >
      <template #body>
        <div class="py-6">
          <div
            class="pb-3"
            @click="$store.dispatch('user/requestUserLocation', { timeout: 10 })"
            >
            Request User Location
          </div>
          PAYLOAD:
          <br>
          <div class="pre">
            {{ userState.userLocation }}
          </div>
        </div>
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtStatus"
      variant="list"
      >
      <template #body>
        <div
          v-for="(entry, i) in status"
          :key="i"
          >
          <cc-settings-list-item
            v-if="(typeof entry.value !== 'object' || entry.value == null)"
            :label="entry.label"
            :label-data="entry.value || '-'"
            :last="status.length === i + 1"
            />
          <template v-if="typeof entry.value === 'object'">
            <div
              v-for="(level1value, level1key, j) in entry.value"
              :key="j"
              >
              <cc-settings-list-item
                :label="`${key}.${level1key}`"
                :label-data="JSON.stringify(level1value) || '-'"
                />
              <div
                v-if="typeof level1value === 'object' && !isEmpty(level1value)"
                class="mt-3"
                >
                <cc-text
                  variant="body"
                  class="pre"
                  color="muted"
                  >
                  {{ level1value }}
                </cc-text>
                <cc-divider
                  class="mt-3"
                  color="blue-light"
                  />
              </div>
            </div>
          </template>
        </div>
      </template>
    </cc-card>

    <cc-card
      v-if="user"
      class="mb-6"
      outer-title="User"
      variant="list"
      >
      <template #body>
        <div
          v-for="(value, key, i) in user"
          :key="i"
          >
          <cc-settings-list-item
            v-if="(typeof value !== 'object')"
            :label="key"
            :label-data="value || '-'"
            />
          <template v-if="typeof value === 'object'">
            <div
              v-for="(level1value, level1key, j) in value"
              :key="j"
              >
              <cc-settings-list-item
                :label="`${key}.${level1key}`"
                :label-data="JSON.stringify(level1value) || '-'"
                />
              <div
                v-if="typeof level1value === 'object' && !isEmpty(level1value)"
                class="mt-3"
                >
                <cc-text
                  variant="body"
                  class="pre"
                  color="muted"
                  >
                  {{ level1value }}
                </cc-text>

                <cc-divider
                  class="mt-3"
                  color="blue-light"
                  />
              </div>
            </div>
          </template>
        </div>
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtHostnames"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          v-for="(entry, i) in hostNames"
          :key="i"
          :label="entry.label"
          :label-data="entry.value || '-'"
          :last="hostNames.length === i + 1"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title="Operations"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          label="Destroy webview"
          arrow
          @click.native="destroy"
          />

        <cc-settings-list-item
          :label="showCopySuccess ? 'Access token copied!' : 'Copy access token'"
          arrow
          @click.native="copyAccessToken(userState.accessToken)"
          />
        <cc-settings-list-item
          label="Dispatch Sentry exception"
          arrow
          @click.native="captureException"
          />
        <cc-settings-list-item
          label="Dispatch Sentry message"
          arrow
          last
          @click.native="sentryCaptureMessage('Testing from Developer Menu')"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtPermissions"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :label="$t('txtLocationPermission')"
          :label-data="appState.locationPermission"
          arrow
          @click.native="locationPermission"
          />
        <cc-settings-list-item
          v-if="
            appState.os === 'ios' ||
              appState.os === 'web' ||
              (hasAndroidQOrAbove && motionTagThreeMinimumSdkVersion)
          "
          :label="$t('txtMotionPermission')"
          :label-data="appState.motionPermission"
          arrow
          @click.native="motionPermission"
          />
        <cc-settings-list-item
          :label="$t('txtContactsPermission')"
          :label-data="appState.contactsPermission"
          arrow
          @click.native="contactsPermission"
          />
        <cc-settings-list-item
          :label="$t('txtPrecisePermission')"
          :label-data="appState.preciseLocation"
          arrow
          @click.native="goToSettings"
          />
        <cc-settings-list-item
          :label="$t('txtUploadContacts')"
          :label-data="appState.contactsUploaded"
          arrow
          @click.native="uploadContacts"
          />
        <cc-settings-list-item
          :label="$t('txtNotificationPermission')"
          :label-data="appState.notificationPermission"
          arrow
          last
          @click.native="notificationPermission"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtModalTriggers"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :label="$t('txtTriggerLocationModal')"
          arrow
          @click.native="
            (isLocationPermissionModalDisplayed = true),
            (locationPermissionModalType = 'AllowLocation')
          "
          />
        <cc-settings-list-item
          :label="$t('txtTriggerLocationSettingsModal')"
          arrow
          @click.native="
            (isLocationPermissionModalDisplayed = true),
            (locationPermissionModalType = 'LocationSettings')
          "
          />
        <cc-settings-list-item
          :label="$t('txtTriggerLocationUnavailableModal')"
          arrow
          @click.native="
            (isLocationPermissionModalDisplayed = true),
            (locationPermissionModalType = 'LocationUnavailable')
          "
          />
        <cc-settings-list-item
          :label="$t('txtTriggerMotionModal')"
          arrow
          @click.native="
            (isMotionPermissionModalDisplayed = true), (motionPermissionModalType = 'AllowMotion')
          "
          />
        <cc-settings-list-item
          :label="$t('txtTriggerMotionSettingsModal')"
          arrow
          @click.native="
            (isMotionPermissionModalDisplayed = true),
            (motionPermissionModalType = 'MotionSettings')
          "
          />
        <cc-settings-list-item
          :label="$t('txtTriggerVehicleModal')"
          arrow
          @click.native="isVehiclePreferenceModalDisplayed = true"
          />

        <cc-settings-list-item
          :label="$t('txtSocialFeatureModal')"
          arrow
          @click.native="isSocialFeatureModalDisplayed = true"
          />
        <cc-settings-list-item
          :label="$t('txtCantonFeatureModal')"
          arrow
          @click.native="isCantonFeatureModalDisplayed = true"
          />
        <cc-settings-list-item
          :label="$t('txtTriggerContactsModal')"
          arrow
          @click.native="
            (isContactsPermissionModalDisplayed = true),
            (contactsPermissionModalType = 'AllowContacts')
          "
          />
        <cc-settings-list-item
          :label="$t('txtTriggerPushNotificationsModal')"
          arrow
          @click.native="triggerPushNotificationModal"
          />
        <cc-settings-list-item
          :label="`${$t('txtTriggerPushNotificationsModal')} -  Android`"
          arrow
          @click.native="triggerPushNotificationModal"
          />
        <cc-settings-list-item
          :label="$t('txtTriggerStoreReview')"
          arrow
          last
          @click.native="triggerStoreReview"
          />
        <cc-settings-list-item
          label="Request ignore battery optimization"
          arrow
          last
          @click.native="triggerIgnoreBatteryOptimization"
          />
      </template>
    </cc-card>

    <cc-location-permission-modal
      v-if="isLocationPermissionModalDisplayed"
      :data="{ permissionModalType: locationPermissionModalType }"
      @close-modal="isLocationPermissionModalDisplayed = false"
      />

    <cc-motion-permission-modal
      v-if="isMotionPermissionModalDisplayed"
      :data="{ permissionModalType: motionPermissionModalType }"
      @close-modal="isMotionPermissionModalDisplayed = false"
      />

    <cc-vehicle-preference-modal
      v-if="isVehiclePreferenceModalDisplayed"
      @close-modal="isVehiclePreferenceModalDisplayed = false"
      />

    <cc-social-feature-modal
      v-if="isSocialFeatureModalDisplayed"
      @close-modal="isSocialFeatureModalDisplayed = false"
      />

    <cc-canton-feature-modal
      v-if="isCantonFeatureModalDisplayed"
      @close-modal="isCantonFeatureModalDisplayed = false"
      />

    <cc-contacts-permission-modal
      v-if="isContactsPermissionModalDisplayed"
      :data="{ permissionModalType: contactsPermissionModalType }"
      @close-modal="isContactsPermissionModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccText from '@/components/primitives/Text.vue';

import ccLocationPermissionModal from '@/components/modals/LocationPermissionModal.vue';
import ccMotionPermissionModal from '@/components/modals/MotionPermissionModal.vue';
import ccVehiclePreferenceModal from '@/components/modals/VehiclePreferenceModal.vue';
import ccSocialFeatureModal from '@/components/modals/SocialFeatureModal.vue';
import ccCantonFeatureModal from '@/components/modals/CantonFeatureModal.vue';
import ccContactsPermissionModal from '@/components/modals/ContactsPermissionModal.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccSettingsListItem,
    ccLocationPermissionModal,
    ccMotionPermissionModal,
    ccVehiclePreferenceModal,
    ccSocialFeatureModal,
    ccCantonFeatureModal,
    ccContactsPermissionModal,
    ccDivider,
    ccText,
  },
  data() {
    return {
      id: 'developer-menu',
      totalStorageUsage: '-',
      showCopySuccess: false,
      isLocationPermissionModalDisplayed: false,
      locationPermissionModalType: null,
      isMotionPermissionModalDisplayed: false,
      motionPermissionModalType: null,
      isVehiclePreferenceModalDisplayed: false,
      isSocialFeatureModalDisplayed: false,
      isCantonFeatureModalDisplayed: false,
      isContactsPermissionModalDisplayed: false,
      contactsPermissionModalType: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
    }),
    ...mapGetters({
      lastTransmission: 'user/lastTransmission',
      hasAndroidQOrAbove: 'common/hasAndroidQOrAbove',
      motionTagThreeMinimumSdkVersion: 'common/motionTagThreeMinimumSdkVersion',
      webAppVersion: 'common/webAppVersion',
    }),
    hostNames() {
      return [
        {
          label: this.$t('txtWebHost'),
          value: new URL(window.location.origin).host,
        },
        {
          label: this.$t('txtApiHost'),
          value: new URL(process.env.VUE_APP_API_URL).host,
        },
      ];
    },
    status() {
      const { appState } = this;

      return [
        {
          label: this.$t('txtAppId'),
          value: appState.appId,
        },
        {
          label: this.$t('WebAppVersion'),
          value: this.webAppVersion,
        },
        {
          label: this.$t('nativeAppVersion'),
          value: appState.nativeAppVersion,
        },
        {
          label: this.$t('SDKVersion'),
          value: appState.sdkVersion,
        },
        {
          label: this.$t('tripDataSdkVersion'),
          value: appState.tripDataSdkVersion,
        },
        {
          label: this.$t('txtLastDataUpload'),
          value: this.lastTransmission,
        },
        {
          label: this.$t('txtOs'),
          value: `${appState.os} ${appState.osVersion ? appState.osVersion : ''}`,
        },
        {
          label: this.$t('txtUserLang'),
          value: appState.userLang,
        },
        {
          label: this.$t('txtMomentLang'),
          value: this.$moment.locale(),
        },
        {
          label: this.$t('txtLocalStorageSize'),
          value: this.localStorageContents(),
        },
        {
          label: this.$t('txtWebAppStorageUsage'),
          value: this.totalStorageUsage,
        },
        {
          label: this.$t('txtBatteryOptimizationModes'),
          value: JSON.stringify(this.appState.batterySavingModes?.batteryOptimizationEnabled),
        },
        {
          label: this.$t('txtBatterySavingModes'),
          value: JSON.stringify(this.appState.batterySavingModes?.powerSavingEnabled),
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUserState: 'user/setState',
    }),
    isEmpty,
    destroy() {
      this.callSdk(`action=${this.actions.DESTROY}`);
    },
    captureException() {
      throw new Error('Testing exception from Developer Menu');
      // this.sentryCaptureException(new Error('Testing exception from Developer Menu'));
    },
    copyAccessToken(value) {
      const textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = value;
      document.body.appendChild(textArea);

      if (this.appState.os === 'ios') {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, textArea.value.length);
      } else {
        textArea.select();
      }

      try {
        const success = document.execCommand('copy');
        if (success) {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        }
      } catch (err) {
        this.$log.info('Something went wrong copying the UserID');
      }

      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
    localStorageContents() {
      let allContents = '';
      const { localStorage } = window;
      Object.keys(localStorage).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
          allContents += localStorage[key];
        }
      });

      if ('storage' in navigator && 'estimate' in navigator.storage) {
        navigator.storage.estimate().then(({ usage, quota }) => {
          const percentage = (usage / quota) * 100;
          this.totalStorageUsage = `${(usage / 1000000).toFixed(2)} MB / ${(
            quota / 1000000
          ).toFixed(2)} MB - ${percentage.toFixed(2)}%.`;
        });
      }
      // eslint-disable-next-line
      const contentsSum = 'allContents'
        ? `${3 + ((allContents.length * 16) / (8 * 1024)).toFixed(2)} KB`
        : 'Empty (0 KB)';
      return contentsSum;
    },
    locationPermission() {
      this.callSdk(`action=${this.actions.REQUEST_LOCATION_PERMISSION}`);
    },
    motionPermission() {
      this.callSdk(`action=${this.actions.REQUEST_MOTION_PERMISSION}`);
    },
    contactsPermission() {
      this.callSdk(`action=${this.actions.REQUEST_CONTACTS_PERMISSION}`);
    },
    notificationPermission() {
      if (this.appState.os === 'android') {
        this.callSdk(`action=${this.actions.GO_TO_NOTIFICATIONS_SETTINGS}`);
      } else {
        this.callSdk(`action=${this.actions.REQUEST_NOTIFICATION_PERMISSION}`);
      }
    },
    goToSettings() {
      this.callSdk(`action=${this.actions.GO_TO_APP_SETTINGS}`);
    },
    uploadContacts() {
      this.callSdk(`action=${this.actions.REQUEST_CONTACTS_UPLOAD}`);
    },
    triggerPushNotificationModal() {
      this.notificationPermission();
    },
    triggerStoreReview() {
      this.$log.info('requestStoreReview');
      this.callSdk(`action=${this.actions.REQUEST_STORE_REVIEW}`);
    },
    triggerIgnoreBatteryOptimization() {
      this.$log.info('requestIgnoringBatteryOptimization');
      this.callSdk(`action=${this.actions.REQUEST_IGNORE_BATTERY_OPTIMIZATION}`);
    },
  },
};
</script>
