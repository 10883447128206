<template>
  <cc-view
    :id="id"
    >
    <div
      class="d-flex flex-column justify-content-center pb-6
      h-100 flex-grow-1 safe-area-inset-bottom"
      >
      <cc-svg-base
        svg="illustrations/sad-earth-cloudy"
        class="align-self-center mb-4"
        />

      <cc-heading
        align="center"
        class="px-4"
        >
        {{ $t('deleteAccountTitle') }}
      </cc-heading>

      <cc-text
        color="muted"
        align="center"
        class="pb-2 pb-7 pt-4"
        >
        {{ $t('deleteAccountBody') }}
      </cc-text>

      <cc-button
        :data-ruid="`${id}__primary-action`"
        variant="danger"
        :text="$t('deleteAccountButton')"
        @click="isConfirmAccountDeletionModalDisplayed = true"
        />
    </div>

    <cc-confirm-account-deletion-modal
      v-if="isConfirmAccountDeletionModalDisplayed"
      @close-modal="isConfirmAccountDeletionModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccConfirmAccountDeletionModal from '@/components/modals/ConfirmAccountDeletionModal.vue';

export default {
  components: {
    ccView,
    ccHeading,
    ccSvgBase,
    ccText,
    ccButton,
    ccConfirmAccountDeletionModal,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'delete-account',
      isConfirmAccountDeletionModalDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),

  },
  methods: {
  },
};
</script>
