/* eslint-disable no-underscore-dangle */
import Chart from 'chart.js';

import { generateChart, mixins } from 'vue-chartjs';


const { reactiveProp } = mixins;
Chart.defaults.CustomBartChart = Chart.helpers.clone(Chart.defaults.bar);

Chart.controllers.CustomBartChart = Chart.controllers.bar.extend({
  draw(ease) {
    const { ctx } = this.chart.chart;
    const easingDecimal = ease || 1;
    Chart.helpers.each(this.getMeta().data, (bar) => {
      const vm = bar._view;
      const thickness = vm.width / 2;
      const angle = 0;
      ctx.save();
      ctx.fillStyle = vm.backgroundColor;
      ctx.translate(vm.x, vm.y);
      ctx.beginPath();
      ctx.arc(angle, angle, thickness, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fill();
      ctx.restore();
      bar.transition(easingDecimal).draw();
    });
  },
});

const CustomBartChart = generateChart('rounded-bar', 'CustomBartChart');

export default {
  extends: CustomBartChart,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  watch: {
    options() {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
