<template>
  <cc-wrapper
    direction="row"
    align="center"
    class="flex-wrap"
    :class="[!wasRead ? 'pr-5' : 'pl-5', hasArrow ? 'pr-4' : 'pr-5']"
    >
    <div
      v-if="!wasRead"
      class="bg-danger rounded mx-2"
      style="width:8px;height:8px;"
      />
    <div
      style="flex: 0 0 50px;"
      class="mr-4"
      >
      <cc-image
        class="border-radius-full align-self-start"
        style="width: 50px; height: 50px;"
        :src="icon"
        :has-placeholder="true"
        placeholder-class="bg-blue-darker"
        />
    </div>

    <div
      class="flex-grow-1"
      style="flex-basis: 50%;"
      >
      <cc-text
        variant="body-xs"
        color="muted"
        class="text-uppercase"
        >
        {{ date }}
      </cc-text>

      <cc-text
        variant="body-sm"
        class="flex-grow-1"
        >
        {{ text }}
      </cc-text>
    </div>

    <!-- Row arrow -->
    <div
      v-if="hasArrow"
      class="ml-4"
      >
      <cc-svg-base
        svg="icons/icon-arrow-right"
        color-class="svg-fill-white"
        />
    </div>
  </cc-wrapper>
</template>

<script>
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccWrapper,
    ccText,
    ccImage,
    ccSvgBase,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    hasArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    wasRead: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
