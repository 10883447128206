<template>
  <cc-card
    :id="id"
    :ruid="id"
    button-variant="small-muted"
    :padded="false"
    >
    <template #body>
      <div>
        <div class="d-flex flex-row flex-wrap pb-4 justify-content-start">
          <cc-heading
            variant="h5"
            class="pb-2 mr-1"
            >
            {{ $t('miaAverageEmployeeEmission') }}
          </cc-heading>
          <div class="d-flex flex-row">
            <cc-heading
              variant="h5"
              color="danger"
              class="pr-1"
              >
              {{ kgEmission }} kg
            </cc-heading>
            <cc-heading
              variant="h5"
              >
              CO2/{{ $t('selectorBarDay') }}
            </cc-heading>
          </div>
        </div>


        <div class="pb-5">
          <cc-employee-emission-bar-chart
            :avg-accumulators="avgAccumulators"
            :last-sevenday="lastSevenday"
            />
          <div class="text-center">
            <cc-text
              color="muted"
              variant="body-xs"
              >
              {{ bottomTitle }}
            </cc-text>
          </div>
        </div>

        <div>
          <cc-happy-earth-placeholder />
        </div>
      </div>
    </template>
  </cc-card>
</template>

<script>
import { co2Prefix, formatCo2 } from '@/mixins/utils';

import ccHeading from '@/components/primitives/Heading.vue';
import ccCard from '@/components/constructs/Card.vue';

import ccEmployeeEmissionBarChart from './EmployeeEmissionBarChart.vue';

import ccHappyEarthPlaceholder from './HappyEarthPlaceholder.vue';

export default {
  name: 'Co2AverageEmployeeEmission',
  components: {
    ccHeading,
    ccCard,
    ccHappyEarthPlaceholder,
    ccEmployeeEmissionBarChart,

  },
  props: {
    avgAccumulators: {
      type: Object,
      required: false,
      default: null,
    },
    lastSevenday: {
      type: Array,
      required: false,
      default: () => [],
    },
    kgEmission: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      id: 'co2-average-employee-emissions',
    };
  },
  computed: {
    bottomTitle() {
      return this.lastSevenday && this.lastSevenday.length
        ? `${this.$moment(this.lastSevenday[this.lastSevenday.length - 1], 'YYYY-MM-DD').subtract(6, 'days').format('DD MMM')} - ${this.$moment(
            this.lastSevenday[this.lastSevenday.length - 1],
            'YYYY-MM-DD',
          ).format('DD MMM YYYY')}`
        : '';
    },
  },
  methods: {
    formatCo2,
    co2Prefix,
  },
};
</script>
