<template>
  <cc-view
    :id="id"
    >
    <cc-charts-loading
      v-if="isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <div
      v-else
      class="d-flex flex-column justify-content-start h-100"
      >
      <!-- Current balance -->
      <cc-balance-card
        v-if="true"
        :amount="getWalletBalance"
        />

      <!-- Empty state -->
      <div
        v-if="isEmpty(companyState.processedTransactions)"
        class="d-flex flex-column flex-grow-1
        justify-content-center align-items-center"
        >
        <cc-svg-base
          svg="illustrations/wallet-empty-state"
          />

        <cc-text
          color="muted"
          align="center"
          class="pt-4 w-70"
          >
          {{ $t('walletNoTransactionsYet') }}
        </cc-text>
      </div>


      <!-- Transactions -->
      <template v-else>
        <cc-card
          v-for="(day, name) in companyState.processedTransactions"
          :key="name"
          class="py-5"
          :outer-title="dateFormatting($moment().subtract(name, 'day').endOf('day'))"
          variant="wallet"
          >
          <template #body>
            <cc-wrapper
              v-for="(transaction, index) in day"
              :key="transaction.id"
              class="flex-wrap"
              :class="index !== 0 ? 'pt-4' : ''"
              align="between"
              direction="row"
              >
              <cc-image
                class="border-radius-full mr-4 align-self-start bg-white object-fit-cover"
                style="width: 38px; height: 38px;"
                alt="image"
                :src="getTransactionResource(transaction).imageUrl
                  || getCompanyResourceFromTransaction(transaction).logo"
                :has-placeholder="true"
                placeholder-class="bg-blue-darkest"
                />
              <cc-wrapper
                align="between"
                class="flex-1"
                >
                <cc-wrapper
                  align="start"
                  direction="row"
                  justify="between"
                  >
                  <cc-wrapper align="start">
                    <cc-text
                      variant="body-bold"
                      class="mr-4"
                      >
                      {{ getTransactionResource(transaction).title || $t('walletRewardPurchase') }}
                    </cc-text>

                    <cc-text
                      class="text-capitalize"
                      color="muted"
                      variant="body-xs"
                      >
                      {{ $t('txtAt' ) }}
                      {{ $moment(new Date(transaction.createdAt).toISOString())
                        .format(`${appState.isProd ? 'LT' : 'LTS'}`) }}
                    </cc-text>
                  </cc-wrapper>


                  <cc-text
                    variant="body-bold"
                    :color="transaction.type === 'CREDIT' ? 'primary' : 'white'"
                    >
                    {{ getTransactionSign(transaction) }}{{ transaction.value }}
                  </cc-text>
                </cc-wrapper>

                <cc-tiny-receipt-card
                  v-if="hasTinyReceipt(transaction)"
                  :title="getVirtualGoodResource(transaction).title"
                  :company-name="getCompanyResourceFromTransaction(transaction).name"
                  :image="getReceiptImage(transaction)"
                  @open-transaction="openTransaction(transaction.id)"
                  />
              </cc-wrapper>

              <cc-divider
                v-if="index !== day.length - 1"
                class="pb-4"
                />
            </cc-wrapper>
          </template>
        </cc-card>
      </template>
    </div>

    <cc-good-receipt-modal
      v-if="isGoodReceiptModalDisplayed"
      :data="goodReceiptModalData"
      @close-modal="isGoodReceiptModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccBalanceCard from '@/components/achievements/BalanceCard.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccTinyReceiptCard from '@/components/achievements/TinyReceiptCard.vue';

import ccGoodReceiptModal from '@/components/modals/GoodReceiptModal.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccCard,
    ccText,
    ccSvgBase,
    ccDivider,
    ccImage,
    ccBalanceCard,
    ccChartsLoading,
    ccGoodReceiptModal,
    ccTinyReceiptCard,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'wallet',
      isLoading: true,
      isGoodReceiptModalDisplayed: false,
      goodReceiptModalData: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      companyState: state => state.company,
    }),
    ...mapGetters({
      getPlayerScore: 'company/getPlayerScore',
      getLocalizedResource: 'company/getLocalizedResource',
      getWalletId: 'user/getWalletId',
      getCompanyById: 'company/getCompanyById',
      getCompanyMetaById: 'company/getCompanyMetaById',
      getWalletBalance: 'user/getWalletBalance',
      getInventoryVoucherResource: 'company/getInventoryVoucherResource',
      getInventoryVoucher: 'company/getInventoryVoucher',
      dateFormatting: 'common/dateFormatting',
      getCompanyResourceFromTransaction: 'company/getCompanyResourceFromTransaction',
      getReceiptImage: 'company/getReceiptImage',
      getTransactionResource: 'company/getTransactionResource',
      getVirtualGoodResource: 'company/getVirtualGoodResource',
      getTransactionSign: 'company/getTransactionSign',
      hasTinyReceipt: 'company/hasTinyReceipt',
    }),
  },
  async created() {
    try {
      await this.$store.dispatch('company/getPlayer');

      if (!this.wallets?.length) {
        await this.$store.dispatch('user/getWallets');
      }

      if (this.getWalletId) {
        if (!this.companyState.transactions.length) {
          await this.$store.dispatch('company/getTransactions', this.getWalletId);
        } else {
          this.$store.dispatch('company/getTransactions', this.getWalletId);
        }
      }
    } catch (error) {
      this.$log.info('Wallet Error:', error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
      setAppState: 'common/setAppState',
    }),
    isEmpty,
    openTransaction(transactionId) {
      this.goodReceiptModalData = { transactionId };
      this.isGoodReceiptModalDisplayed = true;
    },
  },
};
</script>
