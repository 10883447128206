<template>
  <cc-view
    :id="id"
    class="pb-6"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-charts-loading
      v-if="appState.isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <template v-else>
      <cc-text
        class="p-4"
        variant="body-sm"
        color="muted"
        >
        {{ appState.leaderboardType === 'averageCo2'
          ? $t('leaderboardsDescriptionSingular')
          : null }}
        {{ appState.leaderboardType === 'co2PerKm'
          ? $t('leaderboardsDescriptionSingularCo2PerKm')
          : null }}
      </cc-text>

      <!-- Regular Canton leaderboard -->
      <div v-if="userGroups.length">
        <cc-leaderboard-list-item
          v-for="(group, index) in userGroups"
          :key="index"
          :has-row-title="index === 0"
          type="groups"
          :rank="group.curPosition"
          :rank-class="getRankClass(group.curPosition)"
          :prev-rank="group.prevPosition"
          :icon="getCupIcon(group.curPosition)"
          :name="group.name"
          :score="getEmissionText(group.score)"
          :is-clickable="group.userRole !== 'watcher'"
          @click.native="clickHandler(group)"
          />
      </div>

      <!-- Did not travel leaderboard -->
      <div
        v-if="hasDidNotTravelList"
        class="pt-6"
        >
        <h4 class="font-weight-bold text-white text-left px-4">
          {{ didNotTravelText }}
        </h4>

        <cc-text
          class="px-4 pb-4 pt-2"
          variant="body-sm"
          color="muted"
          >
          {{ $t('didNotTravelDescription') }}
        </cc-text>

        <cc-leaderboard-list-item
          v-for="(group, index) in sortedDidNotTravelList"
          :key="`did-not-travel-${index}`"
          :did-not-travel="true"
          type="groups"
          :rank="group.curPosition"
          :name="group.name"
          score=" "
          :is-clickable="group.userRole !== 'watcher'"
          @click.native="clickHandler(group)"
          />
      </div>

      <cc-card
        class="pt-6"
        svg="icons/icon-create-group"
        :pressable="true"
        :inner-text="$t('groupLeaderboardNewGroup')"
        :button-label="$t('txtCreate')"
        card-background="blue-dark"
        radius=""
        :has-shadow="false"
        @click.native="createGroupHandler"
        />
    </template>

    <cc-add-display-name-modal
      v-if="isAddDisplayNameModalDisplayed"
      :data="{ nextCreateGroupModal: true }"
      @after-leave="isCreateGroupModalDisplayed = $event === 'create-group-modal'"
      @close-modal="isAddDisplayNameModalDisplayed = false"
      />

    <cc-create-group-modal
      v-if="isCreateGroupModalDisplayed"
      @after-leave="handleCreateGroupAfterLeave"
      @close-modal="isCreateGroupModalDisplayed = false"
      />

    <cc-group-created-modal
      v-if="isGroupCreatedModalDisplayed"
      :data="groupCreatedModalData"
      @close-modal="
        (isGroupCreatedModalDisplayed = false),
        delete $route.params.groupCreatedModal
      "
      />

    <cc-group-deleted-confirmation-modal
      v-if="isGroupDeletedConfirmationModalDisplayed"
      :data="groupDeletedConfirmationModalData"
      @close-modal="
        (isGroupDeletedConfirmationModalDisplayed = false),
        delete $route.params.groupDeletedConfirmationModal
      "
      />

    <cc-group-exit-confirmation-modal
      v-if="isGroupExitConfirmationModalDisplayed"
      @close-modal="
        (isGroupExitConfirmationModalDisplayed = false),
        delete $route.params.groupExitConfirmation
      "
      />
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';

import ccAddDisplayNameModal from '@/components/modals/AddDisplayNameModal.vue';
import ccCreateGroupModal from '@/components/modals/CreateGroupModal.vue';
import ccGroupCreatedModal from '@/components/modals/GroupCreatedModal.vue';
import ccGroupDeletedConfirmationModal from '@/components/modals/GroupDeletedConfirmationModal.vue';
import ccGroupExitConfirmationModal from '@/components/modals/GroupExitConfirmationModal.vue';

export default {
  components: {
    ccView,
    ccText,
    ccCard,
    ccChartsLoading,
    ccLeaderboardListItem,
    ccAddDisplayNameModal,
    ccCreateGroupModal,
    ccGroupCreatedModal,
    ccGroupDeletedConfirmationModal,
    ccGroupExitConfirmationModal,
  },
  mixins: [viewScrollMemorizer],
  props: {
    createGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'group-leaderboard',
      type: 'leaderboard',
      userGroups: null,
      isAddDisplayNameModalDisplayed: false,
      isCreateGroupModalDisplayed: false,
      isGroupCreatedModalDisplayed: false,
      isGroupDeletedConfirmationModalDisplayed: false,
      groupCreatedModalData: null,
      isGroupExitConfirmationModalDisplayed: null,
      groupDeletedConfirmationModalData: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
      getUserGroups: 'social/getUserGroups',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    sortedDidNotTravelList() {
      return this.leaderboardState.groups.currentData.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    didNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelGroupsYet');
      }

      return this.$t('didNotTravelGroups');
    },
    hasDidNotTravelList() {
      return this.leaderboardState?.groups?.currentData?.didNotTravel?.length > 0;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    leaderboardType() {
      return this.appState.leaderboardType;
    },
    leaderboard() {
      return this.leaderboardState?.groups?.[this.appState?.leaderboardType];
    },
    // hasParticipatingGroups() {
    //   return !isEmpty(this.getUserGroups());
    // },
    contactNames() {
      return this.userState?.contacts?.contactNames;
    },
  },
  watch: {
    async queryDate() {
      await this.$store.dispatch('social/getGroupsLeaderboard');
      this.userGroups = this.getUserGroups();
    },
    leaderboardType() {
      this.userGroups = this.getUserGroups();
    },
    leaderboard() {
      this.userGroups = this.getUserGroups();
    },
  },
  async created() {
    this.setAppState(['leaderboardType', 'averageCo2']);

    const refreshTime = 900000;
    const weeksAgo = this.$moment().diff(this.appState.queryDate, 'weeks');

    if (
      !this.leaderboardState.groups
      || weeksAgo !== this.leaderboardState.groups.windowsAgo
      || Date.now() - refreshTime > this.leaderboardState.groups.timestamp
    ) {
      await this.$store.dispatch('social/getGroupsLeaderboard');
    }

    this.userGroups = this.getUserGroups();

    if (this.createGroup) {
      this.createGroupHandler();
    }
  },
  mounted() {
    // Expect modal components to pass params to launch the next modal
    if (this.$route.params?.groupCreatedModal) {
      this.handleCreateGroupAfterLeave(...Object.values(this.$route.params.groupCreatedModal));
    }

    if (this.$route.params?.groupExitConfirmation) {
      this.isGroupExitConfirmationModalDisplayed = true;
    }

    if (this.$route.params?.groupDeletedConfirmationModal) {
      this.isGroupDeletedConfirmationModalDisplayed = true;
      this.groupDeletedConfirmationModalData = {
        name: this.$route.params.groupDeletedConfirmationModal.name,
      };
    }

    // TODO: Check if this is necessary and causing unnecessary calls
    if (this.appState.contactsPermission === 'true') {
      this.$log.info('Will call social/getContacts');
      this.$store.dispatch('social/getContacts');
    }

    // TODO: Check if this is necessary and causing unnecessary calls
    this.$watch('appState.contactsPermission', (val) => {
      if (val === 'true') {
        this.$store.dispatch('social/getContacts');
      }
    });
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    handleCreateGroupAfterLeave(event, name) {
      this.isGroupCreatedModalDisplayed = true;
      this.groupCreatedModalData = { name };
    },
    createGroupHandler() {
      if (!this.user.name) {
        this.isAddDisplayNameModalDisplayed = true;
      } else {
        this.isCreateGroupModalDisplayed = true;
      }
    },
    clickHandler(group) {
      if (!this.user.name) {
        this.isAddDisplayNameModalDisplayed = true;
      } else if (group.userRole !== 'watcher') {
        this.$router.push({ name: 'group', params: { id: group.id, group } }).catch(() => {});
      }
    },
  },
};
</script>
