<template>
  <cc-heading
    v-if="block.data.text"
    ref="p"
    variant="h4"
    class="mb-4 pt-5 editor-header"
    :color="styles ? styles.header : undefined"
    >
    {{ getContent(block) }}
  </cc-heading>
  <div
    v-else
    class="w-100 bg-danger badge badge-pill mb-4"
    >
    Missing block heading
  </div>
</template>

<script>
import editorJSHelpers from '@/mixins/editorJSHelpers';
import ccHeading from '@/components/primitives/Heading.vue';

export default {
  components: {
    ccHeading,
  },
  mixins: [
    editorJSHelpers,
  ],
  props: {
    styles: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mounted() {
    // this.$log.info('Editor Content: Paragraph mounted', this.block);
  },
};
</script>

<style>
.editor-header:first-child {
  padding-top: 0px !important;
}
</style>
