<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <!-- Here we could insert controls such as segmented control as fixed element
    while the view below can be used to switch between children of this parent tab
    See FootprintTab or AchievementsTab for a working example, ie. time-frame group
    -->

    <!-- Children of a parent route would be rendered in the view below  -->
    <cc-view
      :id="id"
      :has-full-width="true"
      :is-sub-view="true"
      :has-router-view="true"
      :has-overflow-scroll="false"
      />
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';
// import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';

export default {
  name: 'ShopTab',
  components: {
    ccView,
  },
  // mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'shop-tab',
      hasShadow: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
    }),
  },
};
</script>
