<template>
  <cc-view
    v-if="challenge"
    :id="id"
    >
    <cc-challenge-header
      :image-url="getChallengeProperty(achievement, 'imageUrl')"
      :title="getChallengeProperty(achievement, 'title')"
      :duration="getDurationString"
      :time-left="getTimeLeft"
      :progress-value="challengeProgression(challenge)"
      :saturation-value="getCompletionPercentage(challenge)"
      :progress-message="evaluatedProgressMessage"
      :status="status"
      :starts-date="
        challenge.startedDate ? $moment(challenge.startedDate).toISOString(true) : null
      "
      />

    <cc-button
      v-if="status === 'available'"
      variant="small"
      :block="false"
      class="mx-auto"
      :class="errorMessage ? 'mb-2' : 'mb-6'"
      :disabled="isJoiningChallenge"
      :text="$t('challengesStartChallenge')"
      @click="joinChallenge"
      />

    <cc-error-message
      :message="errorMessage"
      alignment="center"
      class="mb-4"
      />

    <div class="bg-blue-dark shadow rounded-16 overflow-hidden p-4 mb-4">
      <span class="d-flex">
        <cc-text
          class="bg-cc-yellow px-2 d-table rounded-6 mb-2"
          color="cc-yellow-darkest"
          variant="body-xs-bold"
          >
          {{ $t('challengeLabel') }}
        </cc-text>

        <cc-text
          v-if="false"
          class="bg-cc-red px-2 d-table rounded-6 mb-2 ml-1 text-capitalize"
          color="white"
          variant="body-xs-bold"
          >
          Debug: {{ status }} {{ $t('challengeLabel') }}
        </cc-text>
      </span>

      <cc-heading
        variant="h4"
        class="pb-1 w-85"
        >
        {{ getChallengeProperty(achievement, 'title') }}
      </cc-heading>

      <cc-text
        variant="body-sm"
        class="text-break-word"
        color="muted"
        >
        {{ getChallengeProperty(achievement, 'description') }}
      </cc-text>

      <div class="d-flex flex-column align-items-start mt-4">
        <div
          v-for="(meta, i) in challengeMeta"
          :key="i"
          class="d-flex align-items-center py-1 rounded-6"
          >
          <cc-svg-base
            v-if="meta.icon"
            :svg="`icons/${meta.icon}`"
            class="mr-2 svg-fill-blue-muted flex-shrink-0"
            />

          <cc-text
            class="d-inline"
            variant="body-sm"
            color="muted"
            >
            {{ meta.text }}
          </cc-text>
        </div>
      </div>
    </div>

    <div class="bg-blue-dark shadow rounded-16 overflow-hidden p-4 mb-6">
      <cc-heading variant="h4">
        {{ $t('challengeRewards') }}
      </cc-heading>

      <div
        v-for="(reward, i) in rewards"
        :key="i"
        class="d-flex align-items-center mt-4"
        >
        <span
          style="flex: 0 0 48px"
          class="mr-3"
          >
          <cc-image :src="reward.image" />
        </span>

        <span class="d-flex align-items-center flex-grow-1 justify-content-between">
          <span class="d-flex flex-column w-85">
            <cc-heading variant="h5">
              {{ reward.text }}
            </cc-heading>

            <cc-text variant="body-sm">
              {{ reward.type }}
            </cc-text>
          </span>

          <cc-svg-base
            v-if="reward.iconInfo"
            :svg="reward.iconInfo"
            color-class="svg-fill-blue-muted flex-shrink-0"
            class="ml-4"
            />
        </span>
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccChallengeHeader from '@/components/achievements/ChallengeHeader.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccView,
    ccImage,
    ccSvgBase,
    ccChallengeHeader,
    ccErrorMessage,
  },
  props: {
    challengeId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      id: 'challenge',
      isJoiningChallenge: false,
      challenge: null,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      getPlayerOrActiveChallengeById: 'achievements/getPlayerOrActiveChallengeById',
      getActiveChallengeById: 'achievements/getActiveChallengeById',
      challengeProgression: 'achievements/challengeProgression',
      getCompletionPercentage: 'achievements/getCompletionPercentage',
      getChallengeStatus: 'achievements/getChallengeStatus',
      getLocalizedResource: 'company/getLocalizedResource',
      isCompanyUser: 'user/isCompanyUser',
      getEvaluatedProgressMessage: 'achievements/getEvaluatedProgressMessage',
    }),
    evaluatedProgressMessage() {
      return this.getEvaluatedProgressMessage(this.challenge);
    },
    /**
     * Mind that we get a running `player` challenge or an available `active` challenge
     * These objects are in part identical except active challenge is a property of player challenge
     */
    achievement() {
      return this?.challenge?.achievement || this?.challenge?.challenge?.achievement || {};
    },
    durationInMillis() {
      return (
        this?.challenge?.durationInMillis || this?.challenge?.challenge?.durationInMillis || null
      );
    },
    getDurationString() {
      const { durationInMillis, status, challenge } = this;

      switch (status) {
        case 'available':
        case 'upcoming':
          return `${this.$t('challengeDuration')}: ${this.$moment
            .duration(durationInMillis)
            .humanize()}`;

        case 'delayed':
          return `${this.$t('txtCompleted')} ${this.$moment(challenge.expiresDate).fromNow()}`;

        default:
          return null;
      }
    },
    getTimeLeft() {
      const { challenge } = this;

      return this.$t('txtLeftToComplete', {
        number: this.$moment(challenge.expiresDate).fromNow(true),
      });
    },
    status() {
      return this.getChallengeStatus(this.challenge);
    },
    challengeMeta() {
      const { challenge, status, appState } = this;

      const meta = [];

      if (status === 'available') {
        meta.push({
          icon: 'icon-calendar',
          text: this.$t('challengeAvailableUntil', {
            date: this.$moment(challenge.expireDate).format('LL'),
          }),
        });
      }

      if (status === 'upcoming') {
        meta.push({
          icon: 'icon-calendar',
          text: this.$t('challengeStartsOn', {
            date: this.$moment(challenge.startedDate).format('LL'),
          }),
        });
      }

      if (status === 'active' || status === 'delayed') {
        meta.push({
          icon: 'icon-calendar',
          text: this.$t('challengeJoinedOn', {
            date: this.$moment(challenge.startedDate).format(`${appState.isProd ? 'LL' : 'L LTS'}`),
          }),
        });
      }

      return meta;
    },
    rewards() {
      const {
        getChallengeProperty, //
        isCompanyUser,
        achievement,
      } = this;

      const { points, credits } = achievement?.reward || {};

      return [
        {
          isVisible: true,
          icon: '',
          type: 'Achievement',
          image: getChallengeProperty(achievement, 'imageUrl'),
          text: getChallengeProperty(achievement, 'title'),
        },
        ...((credits
          && isCompanyUser && [
            {
              // iconInfo: 'icons/icon-info',
              icon: 'icons/icon-green-coin',
              type: 'Wallet',
              image: 'illustrations/green-coin.png',
              text: `${credits} ${this.$t('txtGreenCoins')}`,
            },
          ])
          || []),
        ...((points && [
          {
            // iconInfo: 'icons/icon-info',
            icon: 'icons/icon-leaf',
            type: 'Points',
            image: 'illustrations/green-point.png',
            text: `${points} ${this.$t('txtGreenPoints')}`,
          },
        ])
          || []),
      ];
    },
  },
  beforeMount() {
    this.challenge = this.getPlayerOrActiveChallengeById(this.challengeId);

    /**
     * User might already be on individual challenge screen when a challenge was running
     * Changing the tab and coming back to Challenges again will try to display the challenge
     * that was once running but no longer anymore.
     * In such case there will not be any challenge to find in memory because we filter out
     * challenges with status FAILED and SUCCESS.
     *
     * If no challenge was found, push to main screen of this tab while fetching active challenges
     * available for the user to have the latest state in memory.
     */
    if (!this.challenge) {
      this.$store.dispatch('achievements/postActiveChallengesForPlayer');
      this.$router.push({ name: 'challenges' }).catch(() => {});
    }
  },
  methods: {
    getChallengeProperty(achievement, property) {
      const { getLocalizedResource } = this;
      const response = getLocalizedResource(achievement?.resources, achievement?.defaultLang)[
        property
      ];

      return response || '–';
    },
    joinChallenge() {
      this.isJoiningChallenge = true;
      this.errorMessage = null;

      this.$store
        .dispatch('achievements/joinChallenge', this.challenge.id)
        .then(async () => {
          await this.$store.dispatch('achievements/postActiveChallengesForPlayer');
          this.$router.push({ name: 'challenges' }).catch(() => {});
        })
        .catch(() => {
          this.errorMessage = this.$t('genericErrorTitle');
          this.isJoiningChallenge = false;
        });
    },
  },
};
</script>
