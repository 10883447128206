<template>
  <transition
    name="view-fade"
    mode="out-in"
    >
    <div>
      <div
        v-if="isLoading"
        key="loading"
        class="w-100"
        >
        <div class="d-flex flex-row justify-content-center w-100 h-100">
          <ccLottie
            id="activityIndicator"
            class="w-20 h-100 chart-loading-anim"
            :options="animData.currentAnim"
            />
        </div>
      </div>
      <slot v-else />
    </div>
  </transition>
</template>

<script>
import ccLottie from '@/components/Lottie.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  name: 'ChartLoading',
  components: {
    ccLottie,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      animData: {
        currentAnim: {
          animationData: LoadingLeaderboard,
        },
      },
    };
  },
};
</script>
