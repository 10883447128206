<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-leaderboards-control-group
      id="company-leaderboard"
      :has-type-segment="true"
      />
    <cc-view
      :id="id"
      :has-full-width="true"
      :is-sub-view="true"
      :has-overflow-scroll="true"
      :has-router-view="true"
      :has-ui-controls="true"
      />
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccLeaderboardsControlGroup from '@/components/social/CompanyLeaderboardsControlGroup.vue';

export default {
  components: {
    ccView,
    ccLeaderboardsControlGroup,
  },
  data() {
    return {
      id: 'company-leaderboard-parent',
    };
  },
};
</script>
