<template>
  <cc-modal
    data-ruid="modal__insufficient-coins"
    group-class="bg-blue-dark w-100"
    header-title=""
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('shopInsufficientCoinsTitle')"
          svg="illustrations/insufficient-coins"
          />
        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ $t('shopInsufficientCoinsDescription' ) }}
        </cc-text>
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('txtClose')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
  },
  data() {
    return {
      id: 'insufficient-coins',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  mounted() {
    const { offer } = this.kibanaEvents;

    this.$store.dispatch('common/insertEvent', {
      category: offer.category,
      action: offer.insufficientCoins.action,
      type: this.$route.params.id,
      metaPublic: {
        offerID: this.$route.params.id,
      },
    });
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
  },
};
</script>
