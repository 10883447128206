<template>
  <cc-modal
    data-ruid="modal__canton-selection"
    group-class="bg-blue-dark w-100"
    header-title=""
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        class="d-flex flex-column align-items-center px-5 w-100
          bg-blue-dark text-center"
        >
        <div class="pb-4">
          {{ $t('txtJoinCanton') }}
        </div>

        <div
          style="min-width: 100px; min-height: 100px;"
          class="d-flex justify-content-center align-items-center"
          >
          <cc-svg-base
            :key="`canton-icons/${cantonName}`"
            :svg="`canton-icons/${cantonName}`"
            color-class="canton-logo-3x"
            />
        </div>

        <div
          class="d-flex justify-content-center align-items-center
          font-weight-bold font-size-20 pt-3 w-50 text-capitalize"
          >
          {{ cantonName }}
          <div class="pl-2">
            <cc-svg-base
              v-if="confirmed"
              svg="green-round-checkmark"
              />
          </div>
        </div>

        <cc-error-message
          class="pt-4"
          :message="errorMessage"
          alignment="center"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <div
        class="d-flex flex-row flex-wrap-reverse
        justify-content-around w-100 pb-6 pt-4 px-5 bg-blue-dark"
        >
        <cc-button
          :is-modal-button="true"
          data-ruid="global__button-confirm"
          variant="secondary"
          :block="false"
          :disabled="confirmed || isAwaiting"
          :text="$t(`txtConfirm`)"
          class="flex-grow-1"
          @click="confirm(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState } from 'vuex';
import { cantons } from '@/mixins/cantons';

import ccModal from '@/components/modals/Modal.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccButton,
    ccModal,
    ccSvgBase,
    ccErrorMessage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cantons,
      confirmed: false,
      errorMessage: null,
      isAwaiting: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    cantonName() {
      return cantons?.[this.data.selectedCanton]?.name;
    },
  },
  methods: {
    confirm(closeModal) {
      this.errorMessage = null;
      this.isAwaiting = true;

      this.$store
        .dispatch('user/setCantonPreference', this.data.selectedCanton)
        .then(() => {
          this.confirmed = true;

          setTimeout(() => {
            closeModal(() => {
              this.$router.go(-1);
            });
            this.confirmed = false;
          }, 500);
        })
        .catch(() => {
          this.errorMessage = this.$t('generalErrorMessage');
        })
        .finally(() => {
          this.isAwaiting = false;
        });
    },
  },
};
</script>
