<template>
  <div class="barchart-size align-self-center">
    <bar-chart
      ref="chart"
      :chart-data="datacollection"
      :options="options"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BarChart from '../charts/StackedBarChart';

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      noDataValue: 25,
      emission: [],
      noData: [],
      labels: [],
      datacollection: {},
      options: {},
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      chartData: state => state.trips.chartData,
      timeline: state => state.trips.timeline,
    }),
    barThickness() {
      if (this.appState.filter === 'week') {
        return 14;
      }
      return 5;
    },
  },
  mounted() {
    this.initializeData();
    this.fillData();
    this.$watch('timeline', () => {
      if (this.appState.tripsProcessing === false) {
        this.initializeData();
        this.serializeTimelineData();
        this.fillData();
      }
    }, {
      immediate: true,
      deep: true,
    });
  },
  methods: {
    initializeData() {
      let amount;
      this.emission = [];
      this.labels = [];

      if (this.appState.filter === 'today') {
        amount = 24;
      } else if (this.appState.filter === 'week') {
        amount = 7;
      }

      if (this.appState.filter === 'week') {
        this.labels = [this.$t('dayMon'), this.$t('dayTue'), this.$t('dayWed'), this.$t('dayThu'), this.$t('dayFri'), this.$t('daySat'), this.$t('daySun')];
      } else {
        for (let i = 0; i < amount; i += 1) {
          this.emission.push(0);
          this.labels.push(i);
        }
      }
    },
    serializeTimelineData() {
      if (this.appState.filter === 'today') {
        const { timeline } = this;
        Object.keys(timeline[timeline.currentPeriod].trips).forEach((transitElement) => {
          const currentRawTransitElement = timeline[timeline.currentPeriod].trips[transitElement];
          const startTimestamp = this.$moment(currentRawTransitElement.startTime);
          const endTimestamp = this.$moment(currentRawTransitElement.endTime);
          const startHour = this.$moment(startTimestamp).hour();
          const endHour = this.$moment(endTimestamp).hour();
          const totalTravelTime = endTimestamp - startTimestamp;

          if (startHour === endHour) {
            this.emission[startHour] += currentRawTransitElement.meta.emissions.co2;
          } else {
            for (let hour = startHour; hour <= endHour; hour += 1) {
              if (hour === startHour) {
                const date = new Date(this.appState.queryDate);
                const endTimeframe = new Date(
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    hour + 1,
                  ) - 1,
                );
                const timeInTimeframe = endTimeframe - startTimestamp;
                const ratioTravelInTimeframe = timeInTimeframe / totalTravelTime;

                this.emission[hour] += (ratioTravelInTimeframe
                  * currentRawTransitElement.meta.emissions.co2);
              } else if (hour > startHour && hour < endHour) {
                const date = new Date(this.appState.queryDate);
                const startTimeframe = new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                  hour,
                );

                const endTimeframe = new Date(
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    hour + 1,
                  ) - 1,
                );
                const timeInTimeframe = endTimeframe - startTimeframe;
                const ratioTravelInTimeframe = timeInTimeframe / totalTravelTime;

                this.emission[hour] += (ratioTravelInTimeframe
                  * currentRawTransitElement.meta.emissions.co2);
              } else if (hour === endHour) {
                const date = new Date(this.appState.queryDate);
                const startTimeframe = new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                  hour,
                );

                const timeInTimeframe = endTimestamp - startTimeframe;
                const ratioTravelInTimeframe = timeInTimeframe / totalTravelTime;

                this.emission[hour] += (ratioTravelInTimeframe
                  * currentRawTransitElement.meta.emissions.co2);
              }
            }
          }
        });
      } else if (this.appState.filter === 'week') {
        this.emission = this.chartData.emission;
      }
      for (let i = 0; i < this.emission.length; i += 1) {
        if (this.emission[i] <= 0) {
          this.noData[i] = this.noDataValue;
        } else {
          this.noData[i] = 0;
        }
      }
    },
    fillData() {
      this.datacollection = {
        datasets: [
          {
            label: 'kg of CO₂',
            backgroundColor: '#ff8484',
            zeroColor: '#ffcc00',
            data: this.emission,
            barThickness: this.barThickness,
            minBarLength: 0,
          },
          {
            label: 'kg of CO₂',
            backgroundColor: '#4B97FF',
            zeroColor: '#ffcc00',
            data: this.noData,
            barThickness: this.barThickness,
            minBarLength: 0,
          },
        ],
        labels: this.labels,
      };
      this.options = {
        animation: {
          duration: 700,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: true,
            stacked: true,
            gridLines: false,
            ticks: {
              beginAtZero: false,
              fontColor: '#5d9cf4',
              padding: 16,
              fontFamily: 'TheSans',
              fontStyle: 'bold',
              maxTicksLimit: 9,
              maxRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: this.appState.filter === 'week' ? this.$t('txtDays') : this.$t('txtHours'),
              fontColor: '#5d9cf4',
              fontFamily: 'TheSans',
              fontStyle: 'bold',
              fontSize: 13,
            },
          }],
          yAxes: [{
            display: true,
            stacked: true,
            gridLines: false,
            ticks: {
              beginAtZero: true,
              fontColor: '#5d9cf4',
              fontFamily: 'TheSans',
              fontStyle: 'bold',
              padding: 10,
              suggestedMax: 150,
              maxTicksLimit: 6,
              callback(value) {
                return value / 1000;
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'kg CO₂',
              fontColor: '#5d9cf4',
              fontFamily: 'TheSans',
              fontStyle: 'bold',
              fontSize: 13,
              xOffset: 35,
              yOffset: 9,
            },
          }],
        },
      };
    },
  },
};
</script>
