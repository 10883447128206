<template>
  <cc-view
    :id="id"
    class="pb-6"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-charts-loading
      v-if="appState.isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />
    <template v-else>
      <cc-text
        class="p-4"
        variant="body-sm"
        color="muted"
        >
        {{ leaderboardType === 'averageCo2'
          ? $t('leaderboardsDescriptionSingular')
          : null }}
        {{ leaderboardType === 'publicChallenge'
          ? $t('leaderboardsDescriptionPublicChallenge')
          : null }}
      </cc-text>

      <!-- Regular Canton leaderboard -->
      <div v-if="companyGroups.length">
        <cc-leaderboard-list-item
          v-for="(group, index) in companyGroups"
          :key="index"
          :has-row-title="index === 0"
          type="companyGroups"
          :rank="group.curPosition"
          :rank-class="getRankClass(group.curPosition)"
          :prev-rank="group.prevPosition"
          :icon="getCupIcon(group.curPosition)"
          :name="group.name"
          :score="getEmissionText(group.score)"
          />
      </div>

      <!-- Did not travel leaderboard -->
      <div
        v-if="hasDidNotTravelList"
        class="pt-6"
        >
        <h4 class="font-weight-bold text-white text-left px-4">
          {{ didNotTravelText }}
        </h4>

        <cc-text
          class="px-4 pb-4 pt-2"
          variant="body-sm"
          color="muted"
          >
          {{ $t('didNotTravelDescription') }}
        </cc-text>

        <cc-leaderboard-list-item
          v-for="(group, index) in sortedDidNotTravelList"
          :key="`did-not-travel-${index}`"
          :did-not-travel="true"
          type="companyGroups"
          :rank="group.curPosition"
          :name="group.name"
          score=" "
          />
      </div>

      <!-- Empty state for public challenge-->
      <cc-empty-state-public-challenge
        v-if="!hasDidNotTravelList && !companyGroups.length"
        />
    </template>
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccEmptyStatePublicChallenge from '@/components/social/EmptyStatePublicChallenge.vue';


export default {
  components: {
    ccView,
    ccText,
    ccChartsLoading,
    ccLeaderboardListItem,
    ccEmptyStatePublicChallenge,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'group-leaderboard',
      type: 'leaderboard',
      companyGroups: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasCompanyGroupsLeaderboard: 'social/hasCompanyGroupsLeaderboard',
      getCompanyGroups: 'social/getCompanyGroups',
      getPublicChallengeCompanyGroup: 'social/getCompanyGroupPublicChallengeLeaderboard',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    sortedDidNotTravelList() {
      if (this.leaderboardType === 'publicChallenge') {
      return this.leaderboardState.publicChallengeCompanyGroup?.currentData?.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name))
        || [];
      }

      return this.leaderboardState.company_groups.currentData.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    didNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelGroupsYet');
      }

      return this.$t('didNotTravelGroups');
    },
    hasDidNotTravelList() {
      if (this.leaderboardType === 'publicChallenge') {
        return !!this.leaderboardState
        ?.publicChallengeCompanyGroup?.currentData?.didNotTravel?.length;
      }
      return !!this.leaderboardState?.company_groups?.currentData?.didNotTravel?.length;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    leaderboardType() {
      return this.appState.leaderboardType;
    },
    leaderboard() {
      return this.leaderboardState?.company_groups?.[this.appState?.leaderboardType];
    },
  },
  watch: {
    async queryDate() {
      await this.$store.dispatch('social/getCompanyGroupsLeaderboard');
      this.companyGroups = this.getCompanyGroups();
    },
    leaderboardType() {
      this.reloadLeaderboard();
    },
    leaderboard() {
      this.reloadLeaderboard();
    },
  },
  async created() {
    this.setAppState(['leaderboardType', 'averageCo2']);
    const groups = this.leaderboardState.company_groups;
    const publicChallenge = this.leaderboardState.publicChallengeCompanyGroup;
    const { refreshTime } = this.leaderboardState;

    if (!groups || Date.now() - refreshTime > groups.timestamp) {
      await this.$store.dispatch('social/getCompanyGroupsLeaderboard');
    }

    if (!publicChallenge || Date.now() - refreshTime > publicChallenge.timestamp) {
      await this.$store.dispatch('social/getPublicChallengeCompanyGroupLeaderboard');
    }

    this.companyGroups = this.getCompanyGroups();
  },
  destroyed() {
    this.setAppState(['leaderboardType', 'averageCo2']);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    reloadLeaderboard() {
      if (this.leaderboardType === 'averageCo2') {
        this.companyGroups = this.getCompanyGroups();
      } else {
        this.companyGroups = this.getPublicChallengeCompanyGroup() || [];
      }
    },
  },
  };
</script>
