<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    >
    <cc-wrapper
      align="center"
      justify="start"
      class="h-100 safe-area-inset-bottom"
      >
      <div class="mt-6 pt-6 pt-xxs-0 mt-xxs-0">
        <cc-lottie
          :options="animData"
          width="80%"
          height="initial"
          @animCreated="handleAnim"
          />

        <cc-heading align="center">
          {{ $t(isCompanySignUp ? 'accountCreatedCompanySignUpTitle' : 'challengeAccepted') }}
        </cc-heading>

        <div
          v-if="isCompanySignUp"
          class="px-4"
          >
          <cc-text
            color="muted"
            class="py-6 preline-formatted"
            align="center"
            >
            {{ $t('accountCreatedCompanySignUpDescription') }}
          </cc-text>

          <cc-button
            :data-ruid="`${id}__primary-action`"
            :text="$t('accountCreatedCompanySignUpButton')"
            @click="
              $router.push({ name: 'add-onboarding-code'}).catch(() => {})"
            />
        </div>
      </div>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import accountCreated from '@/components/lottie/account-created.json';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccButton,
    ccLottie,
    ccText,
  },
  props: {
    isCompanySignUp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'account-created',
      path: null,
      animData: {
        loop: false,
        autoplay: false,
        animationData: accountCreated,
      },
    };
  },
  mounted() {
  },
  methods: {
    handleAnim(anim) {
      setTimeout(() => anim.play(), 200);

      if (!this.isCompanySignUp) {
        anim.addEventListener('complete', () => this.$router.push({ name: 'footprint' }).catch(() => {}));
      }
    },
  },
};
</script>
