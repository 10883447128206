<template>
  <cc-modal
    data-ruid="modal__good-receipt"
    group-wrapper-class="safe-area-inset-top mh-100 mt-4"
    group-class="bg-blue-dark w-100 h-100"
    position="bottom"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <!-- consumeUponPurchase -->
      <div class="d-flex flex-column align-items-center flex-grow-1 px-5 pt-6">
        <cc-svg-base svg="illustrations/purchase-complete" />

        <cc-heading
          align="center"
          variant="h2"
          class="pb-2 pt-6"
          >
          {{ $t('shopGoodReceiptModalTitle') }}
        </cc-heading>

        <cc-text
          variant="body-sm"
          color="muted"
          align="center"
          >
          {{ $t('shopGoodReceiptModalDescription') }}
        </cc-text>

        <cc-divider class="pt-4" />
      </div>
    </template>

    <template #body>
      <div class="w-100 d-flex flex-column justify-content-center px-5">
        <div class="d-flex flex-row justify-content-between pb-5">
          <div class="d-flex flex-column align-items-start">
            <cc-text
              variant="body-xs"
              color="muted"
              class="text-uppercase"
              >
              {{ $t('txtDate') }}
            </cc-text>

            <cc-text
              variant="body-sm-bold"
              color="muted"
              >
              {{ getTransactionMoment(transaction, 'LL') }}
            </cc-text>
          </div>

          <div class="d-flex flex-column align-items-end">
            <cc-text
              variant="body-xs"
              color="muted"
              class="text-uppercase"
              >
              {{ $t('txtTime') }}
            </cc-text>

            <cc-text
              variant="body-sm-bold"
              color="muted"
              >
              {{ getTransactionMoment(transaction, 'LT') }}
            </cc-text>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center pb-2">
          <cc-image
            :alt="getCompanyResourceFromTransaction(transaction).name"
            style="width: 66px; height: 66px;"
            class="border-radius-full bg-white object-fit-cover"
            :src="getReceiptImage(transaction)"
            :has-placeholder="true"
            placeholder-class="bg-blue-darkest"
            />

          <div class="pl-4">
            <cc-text variant="body-bold">
              {{ getReceiptResource(transaction).title }}
            </cc-text>

            <cc-text
              variant="body-sm"
              color="muted"
              >
              {{ getCompanyResourceFromTransaction(transaction).name }}
            </cc-text>
          </div>
        </div>

        <div
          v-if="isInventoryVoucher(transaction)"
          class="bg-blue-darkest rounded-16 p-4 mt-3"
          >
          <cc-heading
            align="center"
            variant="h4"
            class="pb-2"
            >
            {{ $t('voucherContentTitle') }}
          </cc-heading>

          <cc-text
            variant="body-sm"
            color="muted"
            class="pb-2"
            align="center"
            >
            {{ $t('voucherContentDescription') }}
          </cc-text>

          <cc-divider class="mb-3 mt-1" />

          <div class="d-flex flex-column align-items-center justify-content-center">
            <div
              v-for="(item, key, i) in getInventoryVoucherContent(transaction)"
              :key="i"
              class="d-flex flex-column align-items-center justify-content-center pb-2"
              :class="key === 'code' ? 'bg-blue-dark py-2 px-4 mb-1 rounded-8' : null"
              >
              <span class="text-center">
                <cc-text
                  v-if="key !== 'code'"
                  variant="body-sm-bold"
                  color="muted"
                  class="d-inline pr-1 user-select-auto"
                  align="center"
                  >
                  {{ key }}:
                </cc-text>

                <!-- Instead of strictly looking for `code` look for any string includes `code` -->
                <!-- to increase flexibility on column header naming -->
                <cc-text
                  :variant="key === 'code' ? 'body-bold' : 'body-sm'"
                  :color="key === 'code' ? 'white' : 'muted'"
                  class="d-inline text-break-word user-select-auto"
                  align="center"
                  >
                  {{ item }}
                </cc-text>
              </span>

              <transition
                name="view-fade"
                mode="out-in"
                >
                <span :key="showCopySuccess">
                  <cc-text
                    v-if="key === 'code'"
                    variant="body-xs"
                    color="primary"
                    class="d-inline"
                    align="center"
                    @click.native.prevent="copyText(item)"
                    >
                    {{ showCopySuccess ? $t('txtCopied') : $t('txtCopy') }}
                  </cc-text>
                </span>
              </transition>
            </div>

            <cc-text
              v-if="inventoryVoucherUrl"
              variant="body-sm-bold"
              color="primary"
              class="d-inline my-2 user-select-auto"
              align="center"
              @click.native="$store.dispatch('common/openUrlInternal', inventoryVoucherUrl)"
              >
              {{ $t('shopStoreVisitWebsite') }}
            </cc-text>
          </div>
        </div>

        <cc-divider
          v-if="isInventoryVoucher(transaction)"
          class="pt-4"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          data-ruid="global__button-confirm"
          variant="primary"
          :is-modal-button="true"
          :text="$t('txtOk')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

// import QrcodeVue from 'qrcode.vue';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccImage from '@/components/primitives/Image.vue';

export default {
  name: 'GoodReceiptModal',
  components: {
    ccWrapper,
    ccButton,
    ccDivider,
    ccImage,
    ccHeading,
    ccText,
    ccModal,
    ccSvgBase,
    // QrcodeVue,
  },
  props: {
    data: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      redeemUrl: null,
      size: 130,
      showCopySuccess: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      getTransactionById: 'company/getTransactionById',
      getAchievementById: 'achievements/getAchievementById',
      getCompanyById: 'company/getCompanyById',
      getCompanyMetaById: 'company/getCompanyMetaById',
      getLocalizedResource: 'company/getLocalizedResource',
      getInventoryVoucherResource: 'company/getInventoryVoucherResource',
      getInventoryVoucher: 'company/getInventoryVoucher',
      getCompanyResourceFromTransaction: 'company/getCompanyResourceFromTransaction',
      getReceiptImage: 'company/getReceiptImage',
      getTransactionResource: 'company/getTransactionResource',
      isInventoryVoucher: 'company/isInventoryVoucher',
      getInventoryVoucherContent: 'company/getInventoryVoucherContent',
      getTransactionMoment: 'company/getTransactionMoment',
      getReceiptResource: 'company/getReceiptResource',
    }),
    inventoryVoucherUrl() {
      return this.getInventoryVoucher(this.transaction)?.metaPublic?.inventoryVoucherUrl;
    },
    transaction() {
      return (
        this.getTransactionById(this.transactionId)
        || this.getAchievementById(this.achievementId)
        || {}
      );
    },
    transactionId() {
      return this.data.transactionId;
    },
    achievementId() {
      return this.data.achievementId;
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    isEmpty,
    // @TODO Move all copy functions to utils
    copyText(text) {
      const textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);

      if (this.appState.os === 'ios') {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 100);
      } else {
        textArea.select();
      }

      try {
        const success = document.execCommand('copy');
        if (success) {
          this.showCopySuccess = true;
          setTimeout(() => {
            this.showCopySuccess = false;
          }, 3000);
        }
      } catch (err) {
        this.$log.info('Something went wrong copying the UserID');
      }

      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
  },
};
</script>
