<template>
  <div
    :style="getStyle"
    class="list-container h-100 w-100 overflow-scroll-y overflow-x-hidden"
    style="padding: 0 20px"
    >
    <div class="pb-6">
      <cc-svg-base svg="swiss-climate-challenge-mia" />
    </div>
    <cc-heading
      variant="h2"
      class="pb-4"
      >
      {{ $t('miaHeader') }}
    </cc-heading>
    <cc-text
      color="muted"
      class="pb-6"
      >
      {{ $t('miaDescription') }}
    </cc-text>
    <cc-heading
      variant="h3"
      class="pb-4"
      >
      {{ $t('miaTitle1') }}
    </cc-heading>
    <cc-text
      color="muted"
      class="pb-4"
      >
      {{ $t('miaSubtitle1') }}
    </cc-text>
    <cc-co2-average-employee-emission
      class="pb-6"
      :kg-emission="getKgEmission"
      :avg-accumulators="avgAccumulators"
      :last-sevenday="lastSevendays"
      />

    <cc-employee-distance-by-category
      class="pb-6"
      :avg-accumulators="avgAccumulators"
      :last-sevenday="lastSevendays"
      />
    <cc-heading
      variant="h3"
      class="pb-4"
      >
      {{ $t('miaTitle2') }}
    </cc-heading>
    <cc-text
      color="muted"
      class="pb-4"
      >
      {{ $t('miaSubtitle2') }}
    </cc-text>
    <cc-active-employees
      :total="activeUsers"
      class="pb-4"
      />
    <cc-text
      color="muted"
      class="pb-4"
      >
      {{ $t('miaSubtitle3') }}
    </cc-text>
    <div class="pb-4 d-flex gap-3">
      <a
        :href="getAppStoreUrl"
        target="_blank"
        >
        <cc-svg-base svg="logos/store-apple" />
      </a>
      <a
        :href="getGooglePlayUrl"
        target="_blank"
        >
        <cc-svg-base svg="logos/store-google" />
      </a>
    </div>
    <cc-text
      color="muted"
      class="pb-4"
      >
      {{ $t('miaSubtitle4') }}
      <a
        href="https://swissclimatechallenge.ch"
        target="_blank"
        >
        swissclimatechallenge.ch
      </a>
    </cc-text>
    <cc-text
      color="muted"
      class="pb-2"
      variant="body-xs"
      >
      {{ $t('miaSubtitle5') }}
      <a href="mailto:info@swissclimatechallenge.ch">info@swissclimatechallenge.ch</a>
    </cc-text>
    <cc-text
      color="muted"
      variant="body-xs"
      class="pb-4"
      >
      {{ $t('miaCopyright') }}
    </cc-text>
  </div>
</template>

<script>
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccCo2AverageEmployeeEmission from '@/components/mia/Co2EmployeeEmission.vue';
import ccEmployeeDistanceByCategory from '@/components/mia/EmployeeDistanceByCategory.vue';

import ccActiveEmployees from '@/components/mia/ActiveEmployees.vue';
import service from '@/services/index';
import { mapGetters } from 'vuex';

export default {
  components: {
    ccCo2AverageEmployeeEmission,
    ccEmployeeDistanceByCategory,
    ccActiveEmployees,
    ccSvgBase,
    ccHeading,
    ccText,
  },
  data() {
    return {
      publicGoalData: null,
      latestData: null,
    };
  },
  computed: {
    ...mapGetters({
      getAppStoreUrl: 'common/getAppStoreUrl',
      getGooglePlayUrl: 'common/getGooglePlayUrl',
    }),
    getStyle() {
      return {
        height: 'calc(100% + 44px) !important', // 44px is the height of the navbar
      };
    },
    getArrayAvgAccumulators() {
      return Object.keys(this.publicGoalData.avgAccumulators).slice(
        Object.keys(this.publicGoalData.avgAccumulators).length > 7 ? -7 : 0,
      );
    },
    lastSevendays() {
      if (!this.publicGoalData || !this.publicGoalData.avgAccumulators) return [];
      return this.getArrayAvgAccumulators;
    },
    isEmptyData() {
      return !this.publicGoalData || !Object.keys(this.publicGoalData?.avgAccumulators)?.length;
    },
    avgAccumulators() {
      if (!this.publicGoalData) return null;
      const avgAccumulators = {};
      this.getArrayAvgAccumulators.forEach((acc) => {
        avgAccumulators[acc] = this.publicGoalData.avgAccumulators[acc];
      });
      return this.isEmptyData ? {} : avgAccumulators;
    },
    getKgEmission() {
      if (this.isEmptyData) return 0;
      const average = this.getArrayAvgAccumulators.reduce(
          (acc, cur) => (typeof acc === 'string'
              ? this.avgAccumulators[acc]?.avgAccumulatorAllUsers.co2 || 0
              : acc + (this.avgAccumulators[cur]?.avgAccumulatorAllUsers.co2 || 0)),
          0,
        ) / 7;
      return (Math.round(average / 10) * 10) / 1000; // g->kg
    },
    activeUsers() {
      if (this.isEmptyData) return 0;
      return this.publicGoalData?.challengeActiveUsers ?? 0;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    filterTheToday(publicGoalData) {
      const newPublicGoalData = publicGoalData;
      Object.keys(publicGoalData.avgAccumulators).forEach((acc) => {
        if (this.$moment(acc, 'YYYY-MM-DD').isSame(this.$moment(), 'day')) {
          delete newPublicGoalData.avgAccumulators[acc];
        }
      });
      return newPublicGoalData;
    },
    async fetchData() {
      try {
        const response = await service.achievements.getMiaPublicGoalData();
        this.latestData = response.avgAccumulators[
          Object.keys(response.avgAccumulators)[Object.keys(response.avgAccumulators).length - 1]];
        this.publicGoalData = this.filterTheToday(response);
      } catch (error) {
        this.$log.error(error);
        this.publicGoalData = null;
      }
    },
  },
};
</script>
