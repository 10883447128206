<template>
  <div
    :id="`${id}__control-group`"
    class="bg-blue-dark px-3"
    :class="hasShadow ? 'ui-control-shadow' : null"
    :data-ui-control="true"
    >
    <cc-leaderboard-type-segment
      v-if="hasTypeSegment"
      :id="`${id}`"
      :items="items"
      />
    <cc-time-frame-stepper
      v-if="!isPublicChallengeType"
      :id="`${id}__time-frame-stepper`"
      />

    <div
      v-if="isPublicChallengeType"
      class="d-flex justify-content-between align-items-center py-3"
      >
      <cc-text
        variant="body-bold"
        class="flex-grow-1 text-center"
        >
        {{ getDateRange }}
      </cc-text>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import ccLeaderboardTypeSegment from '@/components/controllers/LeaderboardTypeSegmentController.vue';
import ccTimeFrameStepper from '@/components/controllers/TimeFrameStepperController.vue';
import moment from 'moment';

export default {
  components: {
    ccTimeFrameStepper,
    ccLeaderboardTypeSegment,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hasTypeSegment: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      hasShadow: false,
      items: [
        {
          locale: 'dailyAverageCo2',
          value: 'averageCo2',
        },
        {
          locale: 'txtPublicChallengeFilter',
          value: 'publicChallenge',
        },
      ],
    };
  },
  computed: {
    moment,
    ...mapState({
      appState: state => state.common.appState,
    }),
    isPublicChallengeType() {
      return this.appState.leaderboardType === 'publicChallenge';
    },

    getDateRange() {
      const { startDate } = this.appState.publicChallenge;
      const { endDate } = this.appState.publicChallenge;
      return `${this.getFormatDate(startDate)} - ${this.getFormatDate(endDate)}`;
    },
  },
  mounted() {
    this.setAppState(['filter', 'week']);
    /**
     * Segmented control group uses transform to transition
     * and since z-index doesn't work together with transform, we must render the shadow
     * after transition has been completed.
     * Duration can be adjusted as needed.
     */
    setTimeout(() => {
      this.hasShadow = true;
    }, 500);
  },
  async beforeDestroy() {
    this.setAppState(['filter', 'week']);
    await this.$store.dispatch('trips/getTimeline');
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getFormatDate(date) {
      return moment(date).format('Do MMMM');
    },
  },
};
</script>
