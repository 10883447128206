import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      isLocationPermissionModalDisplayed: false,
      isMotionPermissionModalDisplayed: false,
      isSocialFeatureModalDisplayed: false,
      isCantonFeatureModalDisplayed: false,
      permissionModalType: null,
      displayOneTimeNotAlwaysAlert: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
    ...mapGetters({
      hasAndroidQOrAbove: 'common/hasAndroidQOrAbove',
      motionTagThreeMinimumSdkVersion: 'common/motionTagThreeMinimumSdkVersion',
    }),
    canAskMotionPermission() {
      const { os } = this.appState;

      return os === 'ios' || (this.hasAndroidQOrAbove && this.motionTagThreeMinimumSdkVersion);
    },
    motionCheckRequired() {
      const { motionPermission, didFinishPermissionChecks } = this.appState;
      const { canAskMotionPermission } = this;
      const { isNewUser } = this.userState;


      return motionPermission !== 'true'
        && canAskMotionPermission
        && isNewUser
        && !didFinishPermissionChecks;
    },
    locationCheckRequired() {
      const {
        locationPermission,
        didFinishPermissionChecks,
      } = this.appState;

      const { isNewUser } = this.userState;

      return locationPermission !== 'always'
        && isNewUser
        && !didFinishPermissionChecks;
    },
    // featureModalCheckRequired() {
    //   const { didFinishFeatureModalChecks } = this.appState;
    //   const { isNewUser } = this.userState;

    //   return !isNewUser && !didFinishFeatureModalChecks;
    // },
  },
  mounted() {
    /**
     * Good to know:
     * Location & motion permission primers are only displayed after a new user is created.
     * Subsequent sessions would only display the yellow alert card.
     * For alert card to be displayed `os=ios&osVersion=14` or relevant should be added as query param.
     * Android >= 29 has no motion permission so motion primer is not displayed.
     * iOS > 13.0 sends `always` value for location permission when user choses `While Using the App`
     * we use `forceDisplayTrackingNotAlways` flag to remind user to explicitly choose `always` from settings.
     *
     */
    if (this.locationCheckRequired) {
      this.$log.warn('locationCheckRequired');

      this.checkLocationPermission();
    } else if (this.motionCheckRequired) {
      this.$log.warn('motionCheckRequired');

      this.checkMotionPermission();
    } else {
      this.setAppState(['didFinishPermissionChecks', true]);
    }

    // if (this.featureModalCheckRequired) {
    //   this.$log.warn('featureModalCheckRequired');

    //   this.checkFeatureModals();
    //   this.setAppState(['didFinishFeatureModalChecks', true]);

    //   // ! Not sure if this is still relevant.
    //   // At this point permission checks have finished when user created the account
    //   // so just toggle it here for clarity on subsequent sessions.
    //   // this.setAppState(['didFinishPermissionChecks', true]);
    // }
  },

  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    checkLocationPermission() {
      const { locationPermission } = this.appState;

      switch (locationPermission) {
        case 'inUse':
        case 'false':
          this.permissionModalType = 'LocationSettings';
          break;

        case 'unavailable':
          this.permissionModalType = 'LocationUnavailable';
          break;

        case 'undefined':
          this.setAppState(['forceDisplayTrackingNotAlways', true]);
          this.permissionModalType = 'AllowLocation';
          break;

        default:
          this.permissionModalType = 'AllowLocation';
          break;
      }

      this.$log.warn('checkLocationPermission:', locationPermission);
      this.isLocationPermissionModalDisplayed = true;
    },
    checkMotionPermission() {
      const { motionPermission } = this.appState;

      if (this.motionCheckRequired) {
        switch (motionPermission) {
          case 'false':
            this.permissionModalType = 'MotionSettings';
            break;

          case 'undefined':
            this.permissionModalType = 'AllowMotion';
            break;

          case 'true':
            return;

          default:
            break;
        }

        this.$log.warn('checkMotionPermission:', motionPermission);
        this.isMotionPermissionModalDisplayed = true;
      }
    },
    // checkFeatureModals() {
    //   if (!this.user?.metaPublic?.socialFeatureModalShown) {
    //     this.isSocialFeatureModalDisplayed = true;
    //   } else if (!this.user?.metaPublic?.cantonFeatureModalShown && !this.user?.city) {
    //     this.isCantonFeatureModalDisplayed = true;
    //   }
    // },
  },
};
