<template>
  <div class="pt-5 ">
    <div class="w-100 d-flex flex-row justify-content-between px-4">
      <cc-section-header
        :title="$t('challengeRewards')"
        align="start"
        heading-align="left"
        />
      <div class="my-auto pt-2">
        <cc-button
          variant="link-normal"
          @click.native="$router.push({name:'shop',params:{
            backTo:'public-goal-progress'
          }}).catch(() => {})"
          >
          {{ $t('txtMore') }}
        </cc-button>
      </div>
    </div>

    <cc-charts-loading
      :is-loading="isLoading"
      class="bg-blue-darkest"
      >
      <div
        class="overflow-x-scroll w-100 flex-horizontal-scroll"
        >
        <div
          class="d-flex flex-row"
          >
          <!-- <span class="px-4 text-muted mx-auto">
            {{ $t('publicGoalRewardEmptyState') }}
            </span> -->
          <cc-product-card
            v-for="(product, i)
              in shuffle(Object.values(offersByCategory).flat(), appState.sessionId)"
            :key="product.id"
            :product="getProduct(product)"
            :index="i"
            :prevent-click="true"
            :big-brand-image="true"
            style="max-width: 164px;"
            class="mr-3"
            :class="i === 0 ? 'ml-4': ''"
            />
          <div class="pr-3" />
        </div>
      </div>
    </cc-charts-loading>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import sandbox from '@/mixins/sandbox';

import { shuffle } from '@sjeffff/shuffle-seed';
import ccProductCard from '@/components/shop/ProductCard.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';

export default {
  name: 'PublicGoalProgressRewards',
  components: {
    ccProductCard,
    ccChartsLoading,
    ccSectionHeader,
  },
  mixins: [viewScrollMemorizer, sandbox],
  data() {
    return {
      id: 'public-goal-progress-rewards',
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      offersByCategory: st => st.company.offersByCategory,
      companyState: state => state.company,
      appState: state => state.common.appState,
    }),

  },

  async created() {
    await this.$store.dispatch('company/getCompaniesWithOffers');
    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
    }),
    shuffle,
    setProductFilter(category) {
      if (this.companyState.productCategoryFilter === category) {
        this.setCompanyState(['productCategoryFilter', null]);
      } else {
        this.setCompanyState(['productCategoryFilter', category]);
      }
    },
    getProduct(product) {
      return ({ ...product, stock: -1 });
    },
  },

};
</script>
