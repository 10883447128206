<template>
  <cc-modal
    :ref="id"
    data-ruid="modal__add-email-address"
    group-class="bg-blue-dark"
    :header-title="$t('addEmailAddressTitle')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-text v-if="!hasPendingAddress">
          {{ bodyText || $t('addEmailAddressDescription') }}
        </cc-text>

        <div v-if="hasPendingAddress">
          <cc-text>
            {{ $t('verifiedEmailAddress') }}:
          </cc-text>

          <cc-text
            variant="body-bold"
            class="text-break-word"
            >
            {{ getUserEmail }}
          </cc-text>

          <cc-divider
            class="my-5"
            />

          <cc-text
            variant="body-bold"
            class="pb-2"
            >
            {{ $t('pendingEmailChange') }}
          </cc-text>

          <cc-text>
            {{ $t('pendingEmailChangeDescription') }}:
          </cc-text>
          <cc-text
            variant="body-bold"
            class="text-break-word"
            >
            {{ getUserPendingEmail }}
          </cc-text>
        </div>


        <validation-observer
          v-if="!hasPendingAddress"
          ref="form"
          >
          <validation-provider
            v-slot="{ errors }"
            rules="email|required"
            :name="$t('email')"
            >
            <cc-input
              v-model.trim="email"
              v-ruid="`${id}__input__email-address`"
              type="email"
              class="pt-5"
              :label="$t('email')"
              :placeholder="$t('email')"
              :has-visible-label="0"
              @blur="setAppState(['errorMessage', null])"
              />
            <cc-error-message :message="errors[0]" />
          </validation-provider>
        </validation-observer>
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="column"
        class="px-5 py-5"
        >
        <cc-error-message
          v-if="hasPendingAddress"
          class="mt-3"
          />

        <cc-button
          v-if="!hasPendingAddress"
          :data-ruid="`${id}__save`"
          :is-modal-button="true"
          :text="$t('txtSave')"
          :has-loading-feedback="true"
          @click="save(close)"
          />

        <cc-button
          v-if="hasPendingAddressWithoutVerified"
          variant="transparent"
          :data-ruid="`${id}__resend`"
          :is-modal-button="true"
          :class="hasPendingAddress ? null : 'mt-2'"
          :text="$t('txtEmailVerificationLinkExpired')"
          @click="resend(close)"
          />

        <cc-button
          v-if="hasPendingAddress"
          variant="transparent"
          :data-ruid="`${id}__undo`"
          :is-modal-button="true"
          :text="$t('undoEmailChange')"
          @click="undo(close)"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import validator from '@/mixins/validator';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccText,
    ccButton,
    ccInput,
    ccErrorMessage,
    ccDivider,
  },
  mixins: [validator],
  props: {
    closeModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
    bodyText: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'add-email-address-modal',
      email: null,
      hasPendingAddress: false,
      hasPendingAddressWithoutVerified: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
    ...mapGetters({
      getUserEmail: 'user/getUserEmail',
      getUserPendingEmail: 'user/getUserPendingEmail',
    }),
  },
  mounted() {
    const { email } = this.userState.user;
    this.email = email?.address || email?.pendingAddress;

    if (this.getUserPendingEmail && this.getUserEmail) {
      this.hasPendingAddress = true;
    }

    if (this.getUserPendingEmail) {
      this.hasPendingAddressWithoutVerified = true;
    }
  },
  destroyed() {
    this.setAppState(['errorMessage', null]);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async resend(closeModal) {
      try {
        await this.$store.dispatch('user/requestVerifyEmail', this.getUserPendingEmail);

        closeModal(() => {
          this.$log.info('will emit after-leave reverify');
          this.$emit('after-leave', 'reverify');
        });
      } catch (error) {
        this.$log.info('AddEmailAddressModal: resend', error);
      }
    },
    async save(closeModal) {
      try {
        const { email } = this.userState.user;

        this.$refs.form.reset();

        const success = await this.$refs.form.validate();

        if (!success) {
          return;
        }

        if (this.email === (email.address || email.pendingAddress)) {
          closeModal();
          return;
        }

        await this.$store.dispatch('user/requestVerifyEmail', this.email);

        closeModal(() => {
          this.$log.info('will emit after-leave verify');
          this.$emit('after-leave', 'verify');
        });
      } catch (error) {
        this.$log.info('AddEmailAddressModal: save', error);
      }
    },
    async undo(closeModal) {
      try {
        await this.$store.dispatch('user/deleteRequestVerifyEmail');

        closeModal();
      } catch (error) {
        this.$log.info('AddEmailAddressModal: undo', error);
      }
    },
  },
};
</script>
