<template>
  <!-- eslint-disable max-len -->
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 241 166"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
    <title>Friends Leaderboard card</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      >
      <g
        id="Verify-account---details"
        transform="translate(-69.000000, -357.000000)"
        >
        <g
          id="Friends-Leaderboard-card"
          transform="translate(69.000000, 357.000000)"
          >
          <g id="Card">
            <rect
              id="Rectangle"
              fill="#0057DF"
              fill-rule="evenodd"
              x="0"
              y="0"
              width="240.1"
              height="165.2"
              rx="11.2"
              />
            <text
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.9"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="16.8"
                y="31.6"
                >
                {{ $t('friendsLeaderboardTitle') }}
              </tspan>
            </text>
          </g>
          <g
            id="3rd"
            transform="translate(21.000000, 128.800000)"
            >
            <path
              id="Icon/star-bronze-Copy-2"
              d="M44.6316106,4.83110852 L40.7937795,4.25549654 L39.0663006,0.588597423 C38.8953989,0.225600136 38.5440195,0 38.1495787,0 C37.7551379,0 37.4037655,0.225600136 37.2328568,0.588597423 L35.5306659,4.2425461 L31.6683343,4.830461 C31.2902914,4.8878857 30.9785744,5.15381945 30.8555295,5.52295944 C30.7290613,5.90237229 30.8220321,6.31389871 31.0995722,6.59691829 L33.8928063,9.45581506 L33.2331299,13.4856765 C33.1675035,13.8883301 33.3302027,14.2827759 33.6590189,14.5145191 C33.9748481,14.7387367 34.3815916,14.7606125 34.7192963,14.5746774 L38.1257432,12.6721877 L41.5806661,14.5746774 C41.9197358,14.7613037 42.3251144,14.7380612 42.6409435,14.5145191 C42.969758,14.2827759 43.1324589,13.8883301 43.0668325,13.4856765 L42.4057911,9.45021487 L45.2004251,6.59604326 C45.4779634,6.31438873 45.5709429,5.9028623 45.4444677,5.52344946 C45.3206824,5.15376695 45.0096361,4.88852797 44.6316106,4.83110852 Z"
              fill="#FEB085"
              />
            <text
              id="Evelyn-Allen"
              font-family="TheSansB5-Plain, TheSans"
              font-size="11.2"
              font-weight="400"
              fill="#FFFFFF"
              >
              <tspan
                x="65.8"
                y="11"
                >
                Evelyn Allen
              </tspan>
            </text>
            <text
              id="+-3.1-°C"
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.2"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="159.7624"
                y="11"
                >
                + 3.1 °C
              </tspan>
            </text>
            <text
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.2"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="0"
                y="11"
                >
                {{ toOrdinalNumberWrapper(3) }}
              </tspan>
            </text>
          </g>
          <g
            id="2nd"
            transform="translate(11.200000, 81.200000)"
            >
            <rect
              id="Rectangle"
              fill="#0A6EFA"
              x="0"
              y="0"
              width="217.7"
              height="38.5"
              rx="6.3"
              />
            <text
              id="Me"
              font-family="TheSansB5-Plain, TheSans"
              font-size="11.2"
              font-weight="400"
              fill="#FFFFFF"
              >
              <tspan
                x="75.6"
                y="22.9"
                >
                {{ $t('txtMe') }}
              </tspan>
            </text>
            <text
              id="+-2.2-°C"
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.2"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="169.5624"
                y="22.9"
                >
                + 2.2 °C
              </tspan>
            </text>
            <text
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.2"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="9.8"
                y="22.9"
                >
                {{ toOrdinalNumberWrapper(2) }}
              </tspan>
            </text>
            <path
              id="Icon/star-bronze-Copy"
              d="M54.4316106,16.7311085 L50.5937795,16.1554965 L48.8663006,12.4885974 C48.6953989,12.1256001 48.3440195,11.9 47.9495787,11.9 C47.5551379,11.9 47.2037655,12.1256001 47.0328568,12.4885974 L45.3306659,16.1425461 L41.4683343,16.730461 C41.0902914,16.7878857 40.7785744,17.0538194 40.6555295,17.4229594 C40.5290613,17.8023723 40.6220321,18.2138987 40.8995722,18.4969183 L43.6928063,21.3558151 L43.0331299,25.3856765 C42.9675035,25.7883301 43.1302027,26.1827759 43.4590189,26.4145191 C43.7748481,26.6387367 44.1815916,26.6606125 44.5192963,26.4746774 L47.9257432,24.5721877 L51.3806661,26.4746774 C51.7197358,26.6613037 52.1251144,26.6380612 52.4409435,26.4145191 C52.769758,26.1827759 52.9324589,25.7883301 52.8668325,25.3856765 L52.2057911,21.3502149 L55.0004251,18.4960433 C55.2779634,18.2143887 55.3709429,17.8028623 55.2444677,17.4234495 C55.1206824,17.0537669 54.8096361,16.788528 54.4316106,16.7311085 Z"
              fill="#CEDCFF"
              />
          </g>
          <g
            id="1st"
            transform="translate(21.000000, 56.700000)"
            >
            <text
              id="Stephan-Hug"
              font-family="TheSansB5-Plain, TheSans"
              font-size="11.2"
              font-weight="400"
              fill="#FFFFFF"
              >
              <tspan
                x="65.8"
                y="11"
                >
                Stephan Hug
              </tspan>
            </text>
            <text
              id="+-2.0-°C"
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.2"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="159.7624"
                y="11"
                >
                + 2.0 °C
              </tspan>
            </text>
            <path
              id="Icon/star-bronze"
              d="M44.6316106,4.83110852 L40.7937795,4.25549654 L39.0663006,0.588597423 C38.8953989,0.225600136 38.5440195,0 38.1495787,0 C37.7551379,0 37.4037655,0.225600136 37.2328568,0.588597423 L35.5306659,4.2425461 L31.6683343,4.830461 C31.2902914,4.8878857 30.9785744,5.15381945 30.8555295,5.52295944 C30.7290613,5.90237229 30.8220321,6.31389871 31.0995722,6.59691829 L33.8928063,9.45581506 L33.2331299,13.4856765 C33.1675035,13.8883301 33.3302027,14.2827759 33.6590189,14.5145191 C33.9748481,14.7387367 34.3815916,14.7606125 34.7192963,14.5746774 L38.1257432,12.6721877 L41.5806661,14.5746774 C41.9197358,14.7613037 42.3251144,14.7380612 42.6409435,14.5145191 C42.969758,14.2827759 43.1324589,13.8883301 43.0668325,13.4856765 L42.4057911,9.45021487 L45.2004251,6.59604326 C45.4779634,6.31438873 45.5709429,5.9028623 45.4444677,5.52344946 C45.3206824,5.15376695 45.0096361,4.88852797 44.6316106,4.83110852 Z"
              fill="#FFDA53"
              />
            <text
              font-family="TheSansB7-Bold, TheSans"
              font-size="11.2"
              font-weight="bold"
              fill="#FFFFFF"
              >
              <tspan
                x="0"
                y="11.7"
                >
                {{ toOrdinalNumberWrapper(1) }}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { mapState } from 'vuex';
import { toOrdinalNumber } from '@/mixins/utils';

export default {
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    toOrdinalNumberWrapper(value) {
      return toOrdinalNumber(value, this.appState.userLang);
    },
  },
};
</script>
