<template>
  <cc-modal
    :ref="id"
    data-ruid="modal__onboard-code-feedback-modal"
    group-class="bg-blue-dark p-5"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="d-flex flex-column w-100 align-items-center"
        >
        <div class="w-50">
          <cc-svg-base
            class="ml-5"
            svg="illustrations/earth-wow-hash-question-mark"
            />
        </div>


        <cc-heading
          variant="h4"
          class="py-4"
          >
          {{ header || $t('onboardCodeErrorMessage') }}
        </cc-heading>
        <cc-divider />
        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ text || $t('onboardCodeErrorMessageHelper' ) }}
        </cc-text>
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="pt-5"
        >
        <cc-button
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('txtBack')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';

import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccHeading,
    ccButton,
    ccText,
    ccSvgBase,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
    header: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      id: 'onboard-code-feedback-modal',
    };
  },
};
</script>
