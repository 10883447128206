<template>
  <cc-modal
    :ref="id"
    data-ruid="modal__add-display-name"
    group-class="bg-blue-dark"
    :header-title="$t('setDisplayNameTitle')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-text>
          {{ $t('setDisplayNameDescription') }}
        </cc-text>

        <validation-observer ref="form">
          <validation-provider
            v-slot="{ errors }"
            rules="required|minmax:3,30|alphaNumericWithSpaces"
            :name="$t('txtDisplayName')"
            >
            <cc-input
              v-model.trim="displayName"
              v-ruid="`${id}__input__display-name`"
              type="text"
              class="pt-3"
              :label="$t('txtDisplayName')"
              :placeholder="$t('txtDisplayName')"
              :has-visible-label="0"
              @blur="setAppState(['errorMessage', null])"
              />
            <cc-error-message :message="errors[0]" />
          </validation-provider>
        </validation-observer>
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('txtSave')"
          :has-loading-feedback="true"
          @click="primaryActionHandler(close)"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import validator from '@/mixins/validator';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccText,
    ccButton,
    ccInput,
    ccErrorMessage,
  },
  mixins: [validator],
  props: {
    closeModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'add-display-name-modal',
      displayName: '',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
  },
  mounted() {
    if (this.userState?.user?.name) {
      this.displayName = this.userState?.user?.name;
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async primaryActionHandler(closeModal) {
      try {
        this.$refs.form.reset();

        const success = await this.$refs.form.validate();

        if (!success) {
          return;
        }

        await this.$store.dispatch('user/setUserName', this.displayName);

        // this.$refs[this.id].close(() => {});

        closeModal(() => {
          if (this.data?.nextCreateGroupModal) {
            this.$emit('after-leave', 'create-group-modal');
          }
        });
      } catch (error) {
        this.$log.info('primaryActionHandler', error);
      }
    },
  },
};
</script>
