<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <!-- Children of a parent route would be rendered in the view below  -->
    <cc-view
      :id="`${id}`"
      :has-full-width="true"
      :is-sub-view="true"
      :has-router-view="true"
      :has-overflow-scroll="false"
      :is-loading="isLoading"
      :has-loading-view="$route.meta.hasLoadingView"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';

export default {
  name: 'ChallengesTab',
  components: {
    ccView,
  },
  data() {
    return {
      id: 'challenges-tab',
      hasShadow: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
      companyState: state => state.company,
    }),
    ...mapGetters({
      hasPlayerChallenges: 'achievements/hasPlayerChallenges',
      hasAchievements: 'achievements/hasAchievements',
      hasActiveChallenges: 'achievements/hasActiveChallenges',
    }),
  },
  async mounted() {
    // this.$log.info('ChallengesTab', this.id);
    /**
     * Segmented control group uses transform to transition
     * and since z-index doesn't work together with transform, we must render the shadow
     * after transition has been completed.
     * Duration can be adjusted as needed.
     */
    setTimeout(() => {
      this.hasShadow = true;
    }, 500);

      if (!this.companyState.player) {
        await this.$store.dispatch('company/getPlayer');
      }

      if (!this.hasPlayerChallenges) {
        await this.$store.dispatch('achievements/getPlayerChallenges');
      }

      if (!this.hasAchievements) {
        await this.$store.dispatch('achievements/getPlayerAchievements');
      }

      if (!this.hasActiveChallenges) {
        await this.$store.dispatch('achievements/postActiveChallengesForPlayer');
      }

      this.isLoading = false;
  },
  async created() {
    this.$log.info('ChallengesTab created');
  },
};
</script>

