<template>
  <div
    id="app"
    class="h-100 overflow-hidden"
    :style="{ 'background-color': backgroundColor }"
    >
    <!-- Top level modal portal. Portal helps to inject local modals to top level DOM area -->
    <portal-target name="modals" />

    <div
      v-hammer:swipe.right="swipeHandler"
      class="swipe-area"
      />

    <!-- Top level No Connection offline state, overlays & hijacks the view -->
    <cc-fatal-error v-if="appState.hasFatalError" />

    <cc-no-connection v-else-if="!appState.isOnline" />

    <!-- Top level Android/Chrome version not supported state, overlays & hijacks the view -->
    <cc-android-not-supported v-else-if="hasInvalidAndroidVersion || hasInvalidChromeVersion" />


    <cc-landscape-not-supported
      v-else-if="appState.screenOrientation === '90' || appState.screenOrientation === '270'"
      />

    <portal-target name="notifications" />

    <cc-notification-toaster />

    <template v-if="true">
      <cc-fps-meter v-if="false" />
      <cc-navigation-bar v-if="true" />

      <div
        id="view__router"
        class="position-relative w-100"
        :style="calcViewHeight($route)"
        >
        <transition :name="viewTransition">
          <router-view />
        </transition>
      </div>
      <cc-tab-bar v-if="true" />
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import vhCheck from 'vh-check';
import sdkListeners from '@/mixins/sdkListeners';
import analyticsEventWatcher from '@/mixins/analyticsEventWatcher';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import onLoad from '@/mixins/onLoad';
import visibilityChange from '@/mixins/visibilityChange';

import ccNoConnection from '@/components/NoConnection.vue';
import ccFatalError from '@/components/FatalError.vue';
import ccAndroidNotSupported from '@/components/AndroidNotSupported.vue';
import ccLandscapeNotSupported from '@/components/LandscapeNotSupported.vue';
import ccNavigationBar from '@/components/controllers/NavigationBarController.vue';
import ccTabBar from '@/components/controllers/TabBarController.vue';
import ccFpsMeter from '@/components/constructs/FpsMeter.vue';
import ccNotificationToaster from '@/components/NotificationToaster.vue';


import { browserSpecs, getBackRoute } from './mixins/utils';

export default {
  components: {
    ccNoConnection,
    ccFatalError,
    ccAndroidNotSupported,
    ccLandscapeNotSupported,
    ccNavigationBar,
    ccTabBar,
    ccFpsMeter,
    ccNotificationToaster,
  },
  mixins: [
    sdkListeners, //
    analyticsEventWatcher,
    viewScrollMemorizer,
    onLoad,
    visibilityChange,
  ],
  data() {
    return {
      viewTransition: '',
      vh: null,
      fps: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      historyState: state => state.common.historyState,
      eventsQueue: state => state.common.eventsQueue,
    }),
    ...mapGetters({
      hasInvalidAndroidVersion: 'common/hasInvalidAndroidVersion',
      hasInvalidChromeVersion: 'common/hasInvalidChromeVersion',
      calcViewHeight: 'common/calcViewHeight',
      isUserAuthenticated: 'user/isUserAuthenticated',
      hasTappedNotification: 'social/hasTappedNotification',
    }),
    backgroundColor() {
      const { meta } = this.$route;
      return (meta && meta.color) || '#003FC4';
    },
  },
  watch: {
    /**
     * This handles the notifications tapped outside of the app (Android/iOS Notification Center etc.)
     * There are two flows.
     * 1. Launching the app from scratch, thus passing through Home/Splash screen
     * 2. App is already launched and on the background.
     *
     * Watcher below is the handler for flow 2, watching the `hasTappedNotification` and if route
     * is not `home`
     *
     * Flow 1 handler is on `footprint` route. Because when app is being launched, we want to wait for
     * splash screen to be seen and after we land on footprint view then we handle the notification.
     * This behaviour can be changed but currently feels alright.
     */
    hasTappedNotification: {
      immediate: false,
      async handler(val) {
        if (val && this.$route.name !== 'home') {
          this.$store.dispatch('social/handleTappedNotification', {
            vm: this,
          });
        }
      },
    },
    $route(to, from) {
      const tab2tab = to.meta.tabBarPosition && from.meta.tabBarPosition;
      const toTabSubView = to.meta.tabBarPosition && from.meta.depth > 0;
      const fromTabSubView = from.meta.tabBarPosition && to.meta.depth > 0;
      // eslint-disable-next-line max-len
      const switchFromSubView = from.meta.tabBarPosition !== to.meta.tabBarPosition && to.meta.depth > 0;

      const instantRoutes = tab2tab || toTabSubView || fromTabSubView || switchFromSubView;
      const tabBarRoutes = ['footprint', 'challenges', 'achievements', 'leaderboards', 'shop', 'wallet', 'points'];

      // if route meta has transition key with a peferred transition name, prioritise it
      // const preferredByRoute = to.meta.transition;

      const messages = from.name === 'messages' && !tabBarRoutes.includes(to.name);

      const exceptionRoutes = from.name === 'home'
        || to.name === 'messages'
        || from.name === 'messages'
        || to.name === 'news-feed'
        || from.name === 'news-feed'
        || to.name === 'settings'
        || from.name === 'settings'
        || (from.name === 'footprint' && to.name === 'getting-started')
        || (to.name === 'footprint' && from.name === 'getting-started')
        || (from.name === 'footprint' && to.name === 'join-the-challenge')
        || (to.name === 'footprint' && from.name === 'join-the-challenge')
        || (from.name === 'footprint' && to.name === 'public-goal-progress')
        || (to.name === 'footprint' && from.name === 'public-goal-progress')
        || (from.name === 'footprint' && to.name === 'public-goal-welcome')
        || (to.name === 'footprint' && from.name === 'public-goal-welcome')
        || (from.name === 'footprint' && to.name === 'join-company-challenge')
        || (to.name === 'footprint' && from.name === 'join-company-challenge')
        || (from.name === 'footprint' && to.name === 'add-onboard-code')
        || (to.name === 'footprint' && from.name === 'add-onboard-code');

      // TODO: after introducing preferredByRoute above, this may become redundant, observe and remove
      const alwaysNext = to.name === 'footprint'
        && (from.name === 'account-created'
          || from.name === 'add-company-email'
          || from.name === 'account-verified'
          || from.name === 'phone-verification'
          || from.name === 'onboarding-code');

      if (from.name === null) {
        this.viewTransition = 'view-fade';
        // this.$log.warn('1', this.viewTransition, to?.meta?.depth, from?.meta?.depth);
      } else if (alwaysNext) {
        this.viewTransition = 'next';
        // this.$log.warn('2', this.viewTransition, to?.meta?.depth, from?.meta?.depth);
      } else if (messages) {
        this.viewTransition = to.meta.depth > from.meta.depth ? 'prev' : 'next';
        // this.$log.warn('3', this.viewTransition, to?.meta?.depth, from?.meta?.depth);
      } else if (exceptionRoutes) {
        this.viewTransition = to.meta.depth > from.meta.depth ? 'next' : 'prev';
        // this.$log.warn('4', this.viewTransition, to?.meta?.depth, from?.meta?.depth);
      } else if (instantRoutes) {
        this.viewTransition = '';
        // this.$log.warn('5', this.viewTransition, to?.meta?.depth, from?.meta?.depth);
      } else {
        this.viewTransition = to.meta.depth > from.meta.depth ? 'next' : 'prev';
        // this.$log.warn('6:', this.viewTransition, to?.meta?.depth, from?.meta?.depth);
      }
    },
  },
  async created() {
    this.$log.info('Created: App');

    // Extend navigator with browserSpecs
    navigator.browserSpecs = browserSpecs();

    // Listen for network status changes
    window.addEventListener('online', (e) => {
      if (navigator.onLine) {
        this.setAppState(['isOnline', true]);
      }
      this.$log.warn('Online', e, navigator.onLine);
      /**
       * Process Kibana events in case any occured while client was offline
       * `insertEvent` has a condition that requires client is online to
       * prevent request attempts while offline
       */
      if (this.eventsQueue?.length) {
        this.$store.dispatch('common/processKibanaEvents');
      }
      // this.$log.info('online listener', this.appState.isOnline);
    });

    window.addEventListener('offline', (e) => {
      this.$log.warn('Offline event received', navigator.onLine);
      /**
       * Use timeout to reduce sensitivity of offline event
       * This is an assumption based on sudden network outages with quick recovery may be
       * misleading us to trigger the fallback screen for no-connection
       */

      // TODO consider if document is visible to trigger offline event?
      setTimeout(() => {
        if (!navigator.onLine) {
          this.setAppState(['isOnline', false]);
          this.$log.warn('Offline resolving after timeout', e, navigator.onLine);
        }
      }, 10000);
    });

    window.addEventListener('load', () => {
      // Call native app and inform we're ready
      this.callSdk(`action=${this.actions.APP_IS_LOADED}`);

      this.$log.info('appIsLoaded');

      /**
       * If appId becomes 'web', call SDK to destroy the web view
       * due to iOS memory issues, so user can start fresh
       */
      this.$watch(
        () => this.appState.appId,
        (appId) => {
          // this.$log.info('Destroy action appId', appId);
          if (appId === 'web') {
            this.callSdk(`action=${this.actions.DESTROY}`);
          }
        },
        {
          immediate: true,
        },
      );
    });

    // init vhCheck, a fix for Safari VH miscalculation
    vhCheck();
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUserState: 'user/setState',
    }),
    // TODO: Remove the wrapper
    getBackRouteWrapper() {
      return getBackRoute(
        this.$route,
        this.appState.routingFrom.name,
        this.isUserAuthenticated,
        this.historyState,
      );
    },
    swipeHandler() {
      const backRoute = this.getBackRouteWrapper();

      this.$log.info('swipeHandler');

      // TODO: Check 'home' route behaviour set below as home is now footprint
      // Maybe we like to go one tab back on swipe until we hit footprint
      const hasBackRoute = (this.$route.name !== 'home' && this.$route.name !== 'start')
        || (this.$route.name === 'start' && this.$store.getters['user/isUserAuthenticated']);

      if (this.appState.os === 'ios' && backRoute !== '') {
        if (hasBackRoute) {
          this.$router.push({ name: backRoute });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import './scss/theme';

@media screen and (min-width: 440px) {
  #app {
    max-width: 440px;
    position: relative;
    margin: 0 auto;
    overflow-x: hidden;
    // -webkit-overflow-scrolling: touch;
  }
}
/*
  Prevent flickering on first frame when transition classes not added yet
  Child has to be the one that transitions out
*/
#view__router > :nth-child(1) {
  z-index: 3;
}

#view__router > * {
  grid-area: main;
}

body {
  margin: 0;
}

.swipe-area {
  background-color: transparent;
  position: fixed;
  height: 100%;
  width: 20px;
  z-index: 4;
}
</style>
