<template>
  <cc-view :id="`shop__category__${id}`">
    <div
      class="d-flex flex-column justify-content-center
    align-items-center pb-4 text-center"
      >
      <cc-image
        :alt="localizedCompany.name"
        style="width: 80px; height: 80px;"
        class="border-radius-full mb-3 bg-white object-fit-cover"
        :src="localizedCompany.logo"
        :has-placeholder="true"
        />

      <cc-text
        variant="body-xl"
        class="font-weight-bold mb-2"
        >
        {{ localizedCompany.name }}
      </cc-text>

      <cc-text
        v-if="false"
        color="muted"
        class="pb-4"
        >
        {{ localizedCompany.catchPhrase }}
      </cc-text>

      <cc-button
        variant="small-muted"
        :block="false"
        :text="$t('storeInformation')"
        @click.native="$router.push({ name: 'company-profile-via-company' })"
        />
    </div>

    <div class="pt-2 pb-4">
      <cc-heading
        variant="h4"
        class="pb-3"
        >
        {{ $t('ourProducts') }}
      </cc-heading>

      <cc-divider />
    </div>

    <div class="d-flex flex-wrap">
      <cc-product-card
        v-for="(product, i) in getCompanyById($route.params.companyId).offers"
        :key="product.id"
        layout="vertical"
        :product="product"
        :index="i"
        />
    </div>


    <cc-shop-category-filter-modal
      v-if="isShopCategoryFilterModalDisplayed"
      @close-modal="isShopCategoryFilterModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { isEmptyObject } from '@/mixins/utils';

import ccView from '@/components/constructs/View.vue';
import ccProductCard from '@/components/shop/ProductCard.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccButton from '@/components/primitives/Button.vue';

// import ccWrapper from '@/components/primitives/Wrapper.vue';
// import ccSvgBase from '@/components/SvgBase.vue';
// import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccShopCategoryFilterModal from '@/components/modals/ShopCategoryFilterModal.vue';
import ccHeading from '@/components/primitives/Heading.vue';

export default {
  components: {
    ccView,
    ccProductCard,
    ccImage,
    ccButton,
    ccHeading,
    // ccWrapper,
    // ccSvgBase,
    // ccHeading,
    ccText,
    ccDivider,
    ccShopCategoryFilterModal,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      // id: '',
      isShopCategoryFilterModalDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      offersByCategory: st => st.company.offersByCategory,
      companyState: state => state.company,
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      getCompanyById: 'company/getCompanyById',
      getLocalizedResource: 'company/getLocalizedResource',
      filterAvailableProductByCategory: 'company/filterAvailableProductByCategory',
    }),
    localizedCompany() {
      return this.getLocalizedResource(
        this.getCompanyById(this.$route.params.companyId)?.company?.metaPublic?.resources,
      ) || {};
    },
    filter() {
      return this.companyState.productCategoryFilter;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
    }),
    isEmptyObject,
  },
};
</script>
