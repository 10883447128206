<template>
  <transition
    name="slide-up"
    mode="out-in"
    >
    <cc-wrapper
      id="no-connection__header"
      class="w-100 vh-100 position-fixed bg-blue-darkest px-4 z-index-5"
      align="between"
      >
      <cc-wrapper
        class="w-100 py-2"
        direction="column"
        align="end"
        >
        <div style="flex: 0 0 24px;">
          <cc-button
            variant="icon"
            data-ruid="global__button-close"
            icon-color="svg-fill-white"
            icon="icons/icon-close"
            :block="false"
            on-close="closeWebView"
            />
        </div>
      </cc-wrapper>

      <cc-wrapper
        id="no-connection__body"
        class="w-100 h-100"
        >
        <ccLottie
          class="h-25"
          :options="animData.currentAnim"
          />

        <cc-text align="center">
          {{ $t('androidNotSupportedDescription') }}
        </cc-text>
      </cc-wrapper>
    </cc-wrapper>
  </transition>
</template>


<script>
import { mapState } from 'vuex';
import ccErrorAnimation from '@/components/lottie/error.json';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccLottie from '@/components/Lottie.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccWrapper,
    ccText,
    ccLottie,
    ccButton,
  },
  data() {
    return {
      path: null,
      animData: {
        currentAnim: {
          animationData: ccErrorAnimation,
        },
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    hasCloseAction() {
      const { appId } = this.appState;
      this.$log.info('appId', appId);
      if (appId === 'web') {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const { navigation } = this.kibanaEvents;

    this.sentryCaptureMessage('Fallback: Android not supported is shown', 'info');

    try {
      this.$store.dispatch('common/insertEvent', {
        category: navigation.category,
        action: navigation.pageView.action,
        type: 'error-android-not-supported',
      });
    } catch (error) {
      this.$log.info('common/insertEvent NOK');
    }
  },

};
</script>
