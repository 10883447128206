<template>
  <div class="mb-4">
    <template v-for="(segment, index) in segments">
      <template v-if="segment.isLink">
        <a
          :key="index"
          class="text-primary"
          :class="segment.rel === 'block' && 'd-block border-bottom'"
          @click="
            $store.dispatch(
              segment.target === 'external' ? 'common/openUrlExternal' : 'common/openUrlInternal',
              segment.url,
            )
          "
          >
          {{ segment.text }}
        </a>
      </template>

      <cc-text
        v-else-if="!segment.isLink"
        ref="p"
        :key="index"
        class="editor-paragraph d-inline"
        :color="styles ? styles.paragraph : undefined"
        >
        {{ segment.text }}
      </cc-text>

      <div
        v-else
        :key="index"
        class="w-100 bg-danger badge badge-pill mb-4"
        >
        Missing block text
      </div>
    </template>
  </div>
</template>

<script>
import * as cheerio from 'cheerio';

import editorJSHelpers from '@/mixins/editorJSHelpers';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccText,
  },
  mixins: [editorJSHelpers],
  props: {
    styles: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    segments() {
      const $ = cheerio.load(this.getContent(this.block));
      const segments = [];

      $('body')
        .contents()
        .each((i, elem) => {
          if (elem.type === 'text') {
            segments.push({ isLink: false, text: $(elem).text() });
          } else if (elem.type === 'tag' && elem.name === 'a') {
            segments.push({
              isLink: true,
              url: $(elem).attr('href'),
              text: $(elem).text(),
              target: $(elem).attr('target') || null,
              rel: $(elem).attr('rel') || null,
            });
          }
        });

      return segments;
    },
  },
  mounted() {
    // this.$log.info('Editor Content: Paragraph mounted', this.block);
  },
};
</script>

<style lang="scss">
// .editor-paragraph:first-child {
//   padding-top: 16px !important;
// }
</style>
