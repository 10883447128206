<template>
  <div
    :id="id"
    class="h-100 px-4"
    >
    <cc-wrapper
      align="reset"
      class="h-100"
      >
      <cc-card>
        <template
          #body
          >
          <cc-section-header
            :title="$t('emailCheckTitle')"
            svg="illustrations/email-success"
            />

          <cc-text
            align="center"
            color="muted"
            >
            {{ $t('emailCheckBody') }}
          </cc-text>

          <cc-button
            :text="$t('emailVerificationEmailButton')"
            class="mt-5"
            :disabled="!bermudaRightSide"
            @click="handleVerify"
            />
        </template>
      </cc-card>
    </cc-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccText from '@/components/primitives/Text.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccCard,
    ccSectionHeader,
    ccText,
    ccWrapper,
    ccButton,
  },
  data() {
    return {
      id: 'email-check',
      bermudaRightSide: null,
    };
  },
  computed: {
    ...mapGetters({
      getAppStoreUrl: 'common/getAppStoreUrl',
      getGooglePlayUrl: 'common/getGooglePlayUrl',
    }),
  },
  created() {
    this.bermudaRightSide = this.$route?.query?.x || null;
  },
  methods: {
    async handleVerify() {
      try {
        const bermudaUrl = process.env.VUE_APP_BERMUDA_URL;
        const { bermudaRightSide } = this;

        if (!bermudaRightSide) {
          return;
        }

        window.location.href = `${bermudaUrl}/${bermudaRightSide}`;
      } catch (error) {
        this.$log.error('Error: EmailCheck', error);
      }
    },
  },
};
</script>
