var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-view',{attrs:{"id":_vm.id}},[_c('cc-card',{staticClass:"mb-6",attrs:{"outer-title-key":"aboutSCC","variant":"list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__getting-started`,"label":_vm.$t('gettingStartedTitle'),"arrow":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'getting-started' })}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__partners`,"label":_vm.$t('txtPartners'),"arrow":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'partners' })}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__about`,"label":_vm.$t('aboutSCC'),"arrow":"","last":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'about-scc' })}}})]},proxy:true}])}),_c('cc-card',{staticClass:"mb-6",attrs:{"outer-title-key":"followSectionTitle","variant":"list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__social-facebook`,"icon":"follow-facebook-logo","label":"Facebook"},nativeOn:{"click":function($event){return _vm.$store.dispatch(
            'common/openUrlExternal',
            'https://www.facebook.com/SwissClimateChallenge/'
          )}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__social-instagram`,"icon":"follow-instagram-logo","label":"Instagram"},nativeOn:{"click":function($event){return _vm.$store.dispatch(
            'common/openUrlExternal',
            'https://www.instagram.com/swiss_climatechallenge/'
          )}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__social-tiktok`,"icon":"follow-tiktok-logo","label":"TikTok"},nativeOn:{"click":function($event){return _vm.$store.dispatch(
            'common/openUrlExternal',
            'https://www.tiktok.com/@swissclimatechallenge'
          )}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__social-youtube`,"icon":"follow-youtube-logo","label":"YouTube","last":""},nativeOn:{"click":function($event){return _vm.$store.dispatch(
            'common/openUrlExternal',
            'https://www.youtube.com/@swissclimatechallenge'
          )}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }