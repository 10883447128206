<template>
  <cc-modal
    data-ruid="modal__lead-to-groups"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('leadToGroupsModalTitle')"
          svg="illustrations/group-created"
          />

        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ $t('leadToGroupsModalDescription' ) }}
        </cc-text>
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('leadToGroupsModalButton')"
          @click="primaryActionHandler(close)"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'lead-to-groups-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      user: state => state.user.user,
    }),
    ...mapGetters({
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
    }),
  },
  mounted() {
    this.$store.dispatch('user/setHasSeenFirstGroup');
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async primaryActionHandler(closeModal) {
      try {
        closeModal(() => {
          if (!this.hasGroupLeaderboard) {
            this.$router.push({ name: 'join-group-leaderboard' }).catch(() => {});
          } else {
            this.$router.push({ name: 'group-leaderboard' }).catch(() => {});
          }
        });
      } catch (error) {
        this.$log.info('social/getGroupsLeaderboard', error);
      }
    },
  },
};
</script>
