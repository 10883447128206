<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="null"
    :pressable="true"
    class="pb-4 overflow-hidden"
    :has-button="false"
    card-background="blue-dark"
    card-class="p-4"
    @click.native="goToWelcome"
    >
    <template #body>
      <cc-wrapper
        direction="column"
        align="start"
        :gap="20"
        >
        <div class="position-absolute">
          <cc-svg-base
            width="100%"
            height="100%"
            svg="illustrations/public-goal-card-bg"
            />
        </div>

        <div class="flex-grow-1 w-100">
          <div class="d-flex flex-row flex-grow-1 justify-content-between align-items-center">
            <cc-svg-base
              svg="illustrations/public-goal"
              width="135px"
              height="40px"
              />

            <cc-button
              v-if="hasDetailsButton"
              variant="small"
              :text="$t('txtDetails')"
              :block="false"
              @click="$emit('button')"
              />
          </div>
          <cc-text
            v-if="hasDate"
            variant="body"
            class="flex-grow-1 pt-4"
            >
            {{ $t('publicGoalDate') }}
          </cc-text>
        </div>

        <div
          class="flex-grow-1"
          style="flex-basis: 50%;"
          >
          <cc-text
            v-if="hasTitle"
            variant="body-bold"
            class="flex-grow-1"
            >
            {{ $t('publicGoalCardTitle') }}
          </cc-text>

          <cc-text
            variant="body"
            class="flex-grow-1"
            >
            {{ $t(bodyTextKey) }}
          </cc-text>
        </div>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  name: 'PublicGoalCard',
  components: {
    ccCard,
    ccWrapper,
    ccSvgBase,
  },
  props: {
    variant: {
      type: String,
      default: 'welcome',
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    hasDate: {
      type: Boolean,
      default: true,
    },
    bodyTextKey: {
      type: String,
      default: 'publicGoalCardDescription',
    },
    hasDetailsButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: 'public-goal-card',
    };
  },
  methods: {
    goToWelcome() {
      this.$router
        .push({
          name: 'public-goal-welcome',
          params: {
            variant: this.variant,
          },
        }).catch(() => {});
    },
  },
};
</script>
