<template>
  <cc-card
    v-if="isShow"
    :id="id"
    :ruid="id"
    :inner-title="isCompanyUser ? $t('companyChallengeReminderCompanyUserSectionTitle')
      : $t('companyChallengeReminderSectionTitle')"
    button-variant="small"
    :pressable="true"
    :has-button="!isCompanyUser"
    :button-label="$t('txtRegister')"
    class="pb-4"
    @click.native="
      !isCompanyUser
        ? $router.push({ name: 'add-onboard-code',
                         params:{isInOnboarding:false} }).catch(() => {})
        : $router.push({ name: 'onboard-code',
                         params:{isInOnboarding:false}}).catch(() => {})
    "
    >
    <template #body>
      <cc-wrapper
        direction="row"
        justify="start"
        >
        <div
          class="mr-4"
          style="flex: 0 0 60px;"
          >
          <cc-image
            v-if="isCompanyUser && companyResource.logo"
            class="border-radius-full"
            :src="companyResource.logo"
            />
          <cc-svg-base
            v-else-if="isCompanyUser && !companyResource.logo"
            width="60px"
            height="auto"
            svg="illustrations/company-onboard"
            />
          <cc-svg-base
            v-else
            width="60px"
            height="auto"
            svg="illustrations/company-onboard"
            />
        </div>

        <cc-text variant="body">
          {{
            isCompanyUser
              ? companyResource.companyChallengeMessage ||
                $t('companyChallengeReminderSectionConfirmation')
              : $t('companyChallengeReminderSectionDescription')
          }}
        </cc-text>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapGetters } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccCard,
    ccImage,
    ccWrapper,
    ccText,
    ccSvgBase,
  },
  data() {
    return {
      id: 'cc-email-verification-card',
    };
  },

  computed: {
    ...mapGetters({
      isCompanyUser: 'user/isCompanyUser',
      getUserCompanyTagId: 'user/getUserCompanyTagId',
      getCompanyResourceByCompanyAlias: 'company/getCompanyResourceByCompanyAlias',
      hasChallengeReminderCard: 'user/hasFeatureChallengeReminderCard',
    }),
    companyResource() {
      return this.getCompanyResourceByCompanyAlias(this.getUserCompanyTagId);
    },
    isShow() {
      return this.isCompanyUser ? this.hasChallengeReminderCard : true;
    },
  },
};
</script>
