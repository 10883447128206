<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="$t('newsFeedSectionTitle')"
    :padded="false"
    :button-label="$t('txtMore')"
    button-variant="small-muted"
    :has-button="hasNewsFeed"
    :pressable="hasNewsFeed"
    class="pb-4 w-100"
    @click.native="$router.push({ name: 'news-feed' }).catch(() => {})"
    >
    <template
      #body
      >
      <cc-news-feed-timeline
        :has-date="false"
        :limit="2"
        card-variant="plain"
        :has-shadow="false"
        card-class="pb-0"
        />
    </template>
  </cc-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccNewsFeedTimeline from '@/components/footprint/NewsFeedTimeline.vue';

export default {
  components: {
    ccCard,
    ccNewsFeedTimeline,
  },
  data() {
    return {
      id: 'news-feed-card',
    };
  },
  computed: {
    ...mapState({
      processedNewsFeed: state => state.social.processedNewsFeed,
    }),
    ...mapGetters({
      hasNewsFeed: 'social/hasNewsFeed',
    }),
  },
};
</script>

<style>
</style>
