import axiosClient from '@/services/axiosClient';

const version = '/v1';
const resource = `${version}/elastic`;
const resourcePublic = `${version}/public/elastic`;

export default {
  async urlShortener(payload) {
    return new Promise((resolve, reject) => {
      axiosClient.post('https://bermuda.scapp.io/links/new', payload)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async insertEvent(event) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resource}/postEvent`, event)
        .then(response => resolve(response)).catch(error => reject(error));
    });
  },

  async insertPublicEvent(event, context) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resourcePublic}/postEvent?context=${context}`, event)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },
};
