<template>
  <cc-view :id="id">
    <cc-card
      :ruid="id"
      :inner-title="$t('shopConsumeGoodTitle')"
      :pressable="true"
      :has-button="false"
      class="pb-4"
      >
      <template #body>
        <div>
          <cc-text color="muted">
            {{ $t('shopConsumeGoodDescription') }}
          </cc-text>

          <cc-card
            card-background="blue-light"
            :has-shadow="false"
            class="py-4"
            >
            <template #body>
              <div class="d-flex flex-row justify-content-start">
                <img
                  alt="Image"
                  style="max-width:66px; height:66px;"
                  class="border-radius-full mr-5"
                  :src="image"
                  >

                <div class="line-height">
                  <cc-text
                    class="text-uppercase pb-1"
                    variant="body-xs"
                    color="muted"
                    >
                    {{ $t('shopValidUntil') }}
                    {{ $moment(validUntil, 'x').format("DD MMM YYYY") }}
                  </cc-text>

                  <cc-heading variant="h4">
                    {{ title }}
                  </cc-heading>
                </div>
              </div>

              <cc-divider
                color="blue-lighter"
                class="my-4"
                />

              <cc-text>
                {{ catchPhrase }}
              </cc-text>
            </template>
          </cc-card>

          <cc-button
            data-ruid="button__consume-good"
            :text="$t('shopValidConsumeVoucher')"
            :to="{ url: consumeUrl }"
            />
        </div>
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccHeading,
    ccText,
    ccButton,
    ccDivider,
  },
  data() {
    return {
      id: 'consume-good',
      userId: null,
      productId: null,
      title: null,
      image: null,
      catchPhrase: null,
      validUntil: null,
      errorUrl: `${window.location.origin}/consumption-failed`,
      successUrl: `${window.location.origin}/consumption-successful`,
      apiHostname: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    consumeUrl() {
      const url = `${this.apiHostname}public/consumeVirtualGood?errorUrl=${this.errorUrl}&successUrl=${this.successUrl}&virtualGoodId=${this.productId}&userId=${this.userId}`;
      this.$log.info('consumeUrl', url);
      return url;
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search.substring(1));

    params.forEach((value, key) => {
      this[key] = value;
    });
  },
  methods: {
    // consumeGood() {
    //   const { productId, userId } = this;
    //   const errorUrl = `${window.location.origin}/consumption-failed`;
    //   const successUrl = `${window.location.origin}/consumption-successful`;
    //   this.$store
    //     .dispatch('company/consumeGood', {
    //       productId,
    //       userId,
    //       errorUrl,
    //       successUrl,
    //     })
    //     .then(response => response);
    // },
  },
};
</script>
