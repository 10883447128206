import { retry } from '@/mixins/utils';
import axiosClient from '@/services/axiosClient';

const version = '/v1';
const resource = `${version}`;
const resourcePublic = `${version}/public`;

export default {
  async normalizePhoneNumber(phoneNumber, baseNumber) {
    return new Promise((resolve, reject) => {
      const paramObject = new URLSearchParams({
        number: phoneNumber,
        countryCode: 'CH',
      });

      if (baseNumber) {
        paramObject.append('baseNumber', baseNumber);
      }

      axiosClient.get(`${resourcePublic}/phonebook/normalize?${paramObject.toString()}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async createGuestAccount(context) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resourcePublic}/user/newAccount/${context}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async requestVerification(phoneNumber, context) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resource}/user/requestVerification/${encodeURIComponent(phoneNumber)}?context=${context}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async requestVerificationRecovery(phoneNumber, context) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resourcePublic}/user/requestVerificationRecovery/${encodeURIComponent(phoneNumber)}?context=${context}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async verifyToken(phoneNumber, token, context) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resource}/user/verifyToken/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(token)}?context=${context}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async verifyTokenRecovery(phoneNumber, token, context) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resourcePublic}/user/verifyTokenRecovery/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(token)}?context=${context}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async login(payload) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resourcePublic}/user/login`, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },

  async requestVerifyEmail(email) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resource}/user/requestVerifyEmail`, email)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async deleteRequestVerifyEmail() {
    return new Promise((resolve, reject) => {
      axiosClient.delete(`${resource}/user/requestVerifyEmail`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async setUser(userObject) {
    return new Promise((resolve, reject) => {
      const user = userObject;
      delete user.phone;
      axiosClient.post(`${resource}/user/profile`, user)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getUser() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resource}/user`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getUserTags() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resource}/user/tags`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getWallets() {
    return new Promise((resolve, reject) => {
      axiosClient.get(`${resource}/wallet`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async deleteUserAccount() {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resource}/user/deleteAccount`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async setOnboardCode(onboardCode) {
    return new Promise((resolve, reject) => {
      axiosClient.post(`${resource}/user/onboardCode?onboardCode=${encodeURIComponent(onboardCode)}`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },
  async removeCompany() {
    return new Promise((resolve, reject) => {
      axiosClient.delete(`${resource}/user/company`)
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },
};
