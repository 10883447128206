<template>
  <cc-card
    v-if="!userIsVerified && !hasGettingStartedCard"
    :id="id"
    :ruid="id"
    :inner-title="$t('verifyCardTitle')"
    :pressable="true"
    :button-label="$t('txtVerify')"
    class="pb-4"
    @click.native="$router.push({ name: 'join-the-challenge' })"
    >
    <template #body>
      <cc-wrapper direction="row">
        <div class="pr-3">
          <cc-svg-base
            svg="illustrations/leaderboard-trophy"
            />
        </div>

        <cc-text variant="body">
          {{ $t('verifyCardDescription') }}
        </cc-text>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccSvgBase,
    ccText,
  },
  data() {
    return {
      id: 'verify-card',
      hasGettingStartedCard: false,
    };
  },
  computed: {
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
      viewWillBeShown: 'common/viewWillBeShown',
    }),
  },
  watch: {
    viewWillBeShown: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch('common/hasGettingStartedCard')
            .then((res) => {
              this.$log.warn('hasGettingStartedCard', res);
              this.hasGettingStartedCard = true;
            })
            .catch((err) => {
              this.$log.warn('hasGettingStartedCard', err);
              this.hasGettingStartedCard = false;
            });
        }
      },
    },
  },
  mounted() {
    const { navigation } = this.kibanaEvents;

    this.$store.dispatch('common/insertEvent', {
      category: navigation.category,
      action: navigation.conditionalContentDisplayed.action,
      type: 'link-phone-number',
    });
    // this.hasGettingStartedCard = await this.$store
    //   .dispatch('common/hasGettingStartedCard')
    //   .catch((err) => this.$log.warn('hasGettingStartedCard in VerifyCard', err));
  },
};
</script>
