<template>
  <div
    v-if="hasNavigation"
    :id="id"
    :v-ruid="id"
    style="z-index: 4;max-width: 440px;left:0;right:0px;"
    class="m-auto position-relative transition-bg-color"
    :class="[hasNavShadow ? 'shadow' : '', $route.meta.navBackgroundColor]"
    >
    <div
      id="safe-area-inset-top"
      class="position-relative safe-area-inset-top transition-bg-color"
      style="z-index:1;"
      :class="$route.meta.navBackgroundColor"
      />

    <!-- Transition Groups responsible for the whole NavigationBar icon animations -->
    <!-- Following is the navigation bar with 3 slots, left, middle, right -->
    <div
      key="navigation"
      class="w-100 position-relative d-flex px-4 py-2 transition-bg-color"
      style="z-index:1;"
      :class="$route.meta.navBackgroundColor"
      >
      <div
        key="left"
        style="flex: 0 0 64px;min-height:28px;"
        class="d-flex align-items-center"
        :class="leftClass"
        >
        <transition-group
          name="navigation-fade"
          class="d-flex align-items-center"
          >
          <slot name="left" />
        </transition-group>
      </div>

      <div
        key="middle"
        style="flex: 1;"
        :class="middleClass"
        class="d-flex align-items-center justify-content-center
        transition-all overflow-hidden position-relative"
        >
        <slot name="middle">
          <transition :name="navTitleTransition">
            <h5
              v-if="title"
              :key="$route.name"
              class="position-absolute font-weight-bold text-center text-white text-truncate w-100"
              >
              {{ title }}
            </h5>
          </transition>
        </slot>
      </div>

      <div
        key="right"
        style="flex: 0 0 64px;min-height:28px;"
        class="d-flex align-items-center"
        :class="rightClass"
        >
        <transition-group
          name="navigation-fade"
          class="d-flex align-items-center flex-grow-1 justify-content-end"
          >
          <slot name="right" />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

// import ccButton from '@/components/primitives/Button.vue';
// import ccLeaderboardSelector from '@/components/social/LeaderboardSelector.vue';

export default {
  // components: {
  //   ccButton,
  // ccLeaderboardSelector,
  // },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    // settingsBack: {
    //   type: Boolean,
    //   required: false,
    // },
    // profile: {
    //   type: Boolean,
    //   required: false,
    // },
    close: {
      type: Boolean,
      required: false,
    },
    hasNavShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    // back: {
    //   type: Boolean,
    //   required: false,
    // },
    // closeShould: {
    //   type: [Function, String],
    //   required: false,
    //   default: undefined,
    // },
    // groupClass: {
    //   type: [Array, Function, String],
    //   default: null,
    //   required: false,
    // },
    // closeIconFill: {
    //   type: [Array, String],
    //   default: 'svg-fill-white',
    //   required: false,
    // },
    leftClass: {
      type: String,
      default: null,
      required: false,
    },
    middleClass: {
      type: String,
      default: null,
      required: false,
    },
    rightClass: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      scrollPos: null,
      easing: 'easeOutElastic(1, 1.2)',
      duration: 500,
      navTitleTransition: '',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
    ...mapGetters({
      hasInvalidAndroidVersion: 'common/hasInvalidAndroidVersion',
      hasInvalidChromeVersion: 'common/hasInvalidChromeVersion',
    }),
    // hasNavShadow() {
    //   const { name } = this.$route;
    //   const { isBlocker, appState } = this;

    //   const routes = [
    //     'home',
    //     'climate-change',
    //     'journeys',
    //     'getting-started',
    //     'settings',
    //     'canton-leaderboard',
    //     'friends-leaderboard',
    //     'group-leaderboard',
    //     'group-view',
    //     'group-settings',
    //     'design-system',
    //     'footprint',
    //     'leaderboards',
    //     'shop',
    //     'wallet',
    //   ];

    //   if (isBlocker || !appState.isOnline) {
    //     return false;
    //   }
    //   if (routes.includes(name)) {
    //     return 'home-nav-shadow';
    //   }
    //   return false;
    // },
    // hasNavBackground() {
    //   // To work around a transition issue, override the existing background color class to reset
    //   const { name } = this.$route;
    //   const { appState, isBlocker } = this;

    //   const routes = [
    //     'start',
    //     'signup',
    //     'join-the-challenge',
    //     'join-group-leaderboard',
    //     'phone-verification',
    //     'terms-overview',
    //     'onboarding',
    //     'page-not-found',
    //     'account-created',
    //     'account-verified',
    //   ];

    //   if (routes.includes(name) || isBlocker || !name) {
    //     return 'backgroundColor: initial !important;';
    //   }
    //   if (!appState.isOnline) {
    //     return 'backgroundColor: #003FC4 !important';
    //   }
    //   return false;
    // },
    hasNavigation() {
      const { name } = this.$route;
      // const { appState } = this;

      const routes = [
        'email-verification-success',
        'email-verification-expired',
        'email-verification-error',
        'mia',
      ];
      if (routes.includes(name)) {
        return false;
      }
      return true;
    },
    isBlocker() {
      const { screenOrientation } = this.appState;
      const { hasInvalidAndroidVersion, hasInvalidChromeVersion } = this;

      return (
        hasInvalidAndroidVersion
        || hasInvalidChromeVersion
        || screenOrientation === '90'
        || screenOrientation === '270'
      );
    },
  },
  watch: {
    $route(to, from) {
      // this.$log.warn('to', to.meta.tabBarPosition, to.meta.depth);
      // this.$log.warn('from', from.meta.tabBarPosition, from.meta.depth);

      if (to.meta.depth !== from.meta.depth) {
        const next = to.meta.depth;
        const prev = from.meta.depth;

        this.navTitleTransition = next > prev ? 'nav-title-slide-left' : 'nav-title-slide-right';

        // this.$log.warn('Nav 1', this.navTitleTransition);
      } else if (to.meta.tabBarPosition !== from.meta.tabBarPosition) {
        const next = to.meta.tabBarPosition;
        const prev = from.meta.tabBarPosition;

        this.navTitleTransition = next > prev ? 'nav-title-slide-left' : 'nav-title-slide-right';

        // this.$log.warn('Nav 2', this.navTitleTransition);
      } else {
        this.navTitleTransition = '';

        // this.$log.warn('Nav 3', this.navTitleTransition);
      }

      // if (to.meta.depth !== from.meta.depth) {
      //   const next = to.meta.depth;
      //   const prev = from.meta.depth;

      //   this.navTitleTransition = next > prev ? 'nav-title-slide-left' : 'nav-title-slide-right';

      //   // this.$log.warn('Nav 1', this.navTitleTransition);
      // } else if (to.meta.tabBarPosition !== from.meta.tabBarPosition) {
      //   const next = to.meta.tabBarPosition;
      //   const prev = from.meta.tabBarPosition;

      //   this.navTitleTransition = next > prev ? 'nav-title-slide-left' : 'nav-title-slide-right';

      //   // this.$log.warn('Nav 2', this.navTitleTransition);
      // } else {
      //   this.navTitleTransition = '';

      //   // this.$log.warn('Nav 3', this.navTitleTransition);
      // }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
</style>
