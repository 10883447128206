var render = function render(){var _vm=this,_c=_vm._self._c;return (['today', 'week'].includes(_vm.appState.filter) && _vm.isCurrentWeekOrDay)?_c('cc-card',{staticClass:"pb-4",attrs:{"inner-title":_vm.$t('recentTrips'),"has-button":_vm.hasRecentTrips,"button-variant":"small-muted","pressable":_vm.hasRecentTrips},nativeOn:{"click":function($event){_vm.hasRecentTrips &&
      _vm.$router
        .push({
          name: 'journeys',
          params: {
            timeframe: 'today',
            queryDate: _vm.recentTrips[0]['startTime'],
          },
        })
        .catch(() => {})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[(!_vm.isFetchingRecentTrips && _vm.hasRecentTrips)?_c('cc-recent-trips-disclosure'):_vm._e(),(_vm.isFetchingRecentTrips)?_c('cc-loading'):(_vm.hasRecentTrips)?_c('cc-day-timeline',{key:_vm.recentTrips[0] && _vm.recentTrips[0].startTime,attrs:{"trips":_vm.recentTrips,"is-journey":false,"has-trip-correction-action":false}}):_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"pr-4"},[_c('cc-svg-base',{staticClass:"px-1",attrs:{"svg":"illustrations/no-recent-trips"}})],1),_c('span',{staticClass:"d-flex flex-column"},[_c('cc-heading',{attrs:{"variant":"h5"}},[_vm._v(" "+_vm._s(_vm.isUserCreatedDaysAgo(7) ? _vm.$t('noRecentTrips') : _vm.$t('awaitingRecentTrips'))+" ")]),_c('cc-text',[_vm._v(" "+_vm._s(_vm.isUserCreatedDaysAgo(7) ? _vm.$t('noRecentTripsBody') : _vm.$t('awaitingRecentTripsBody'))+" ")])],1)])],1)]},proxy:true}],null,false,4128621816)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }