<template>
  <div class="d-flex flex-column">
    <div
      v-for="(day, name, index) in listAchievements"
      :key="name"
      :class="hasPadding(index)"
      >
      <cc-heading
        v-if="hasDate"
        variant="h4"
        class="pb-3"
        >
        {{ dateFormatting($moment().subtract(name, 'day').endOf('day')) }}
      </cc-heading>

      <div
        v-for="(achievement, i) in day"
        :key="`${achievement.achievement.grantedAt}${achievement.id}`"
        :data-ruid="`challenges-tab__achievement-${achievement.id}`"
        >
        <cc-achievements-list-item
          :icon="getIcon(achievement.achievement)"
          :date="
            $moment(achievement.achievement.grantedAt).format(`${appState.isProd ? 'L' : 'L LTS'}`)
          "
          :description="getCompletionMessage(achievement.achievement)"
          :border-bottom="hasBorderBottom(day, i, achievement.achievement)"
          :points="achievement.achievement.reward.points"
          :credits="achievement.achievement.reward.credits"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccHeading from '@/components/primitives/Heading.vue';
import ccAchievementsListItem from '@/components/constructs/AchievementsListItem.vue';

export default {
  components: {
    ccHeading,
    ccAchievementsListItem,
  },
  props: {
    limit: {
      type: Number,
      default: -1,
      required: false,
      validator: value => value > 0 || value === -1,
    },
    hasDate: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
      getLimitedAchievements: 'achievements/getLimitedAchievements',
      dateFormatting: 'common/dateFormatting',
    }),
    getAchievementsSize() {
      return Object.keys(this.achievementsState.processedAchievements).length;
    },
    limitedAchievements() {
      return this.getLimitedAchievements(this.limit);
    },
    listAchievements() {
      return this.limitedAchievements
      || this.achievementsState.processedAchievements;
    },
  },
  methods: {
    hasBorderBottom(day, i, achievement) {
      /**
       * @lastAchievementOfLastDay became quite complicatated compared to what it does.
       * But here's the deal; data structure used in achievements as well as in wallet
       * uses each day relative to today as the key of an array inside an object
       * Each day is an array containing one or many achievement objects
       * In order to find the last item to disable the bottom border, we find the last day of the
       * limited recent achievements list and from that last day we pick last achievement.
       */
      if (this.limit > 0) {
        const lastAchievementOfLastDay = this.limitedAchievements?.[
          Object.keys(this.limitedAchievements)[Object.keys(this.limitedAchievements).length - 1]
        ]?.slice(-1)[0];

        if (achievement.grantedAt === lastAchievementOfLastDay?.grantedAt) {
          return false;
        }
        return true;
      }
      return i !== day.length - 1;
    },
    hasPadding(index) {
      if (this.limit > 0) {
        return null;
      }
      return index !== this.getAchievementsSize - 1 ? 'pb-6' : null;
    },
    getIcon(achievement) {
      const { getLocalizedResource } = this;

      return getLocalizedResource(achievement?.resources, achievement?.defaultLang)?.imageUrl;
    },
    getCompletionMessage(achievement) {
      const { getLocalizedResource } = this;
      const { completionMessage } = getLocalizedResource(achievement.resources, achievement.defaultLang) || '-';

      return completionMessage;
    },

  },
};
</script>
