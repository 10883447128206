<template>
  <cc-view
    :id="id"
    :is-modal="isModal"
    >
    <cc-wrapper class="h-100 text-center safe-area-inset-bottom flex-grow-1">
      <cc-wrapper class="flex-grow-1">
        <form @submit.prevent="verifyPhoneNumber">
          <cc-heading class="mb-5">
            {{ $t('verificationTitle') }}
          </cc-heading>

          <cc-text
            color="muted"
            class="mb-5 mx-5"
            >
            {{ $t('verificationDescription') }}
          </cc-text>

          <div class="d-flex flex-column justify-content-center">
            <div>
              <input
                v-model="verificationCode"
                class="w-85 text-center text-white font-weight-slight bg-transparent border-0 h1"
                data-ruid="input-verify-phone"
                :maxlength="6"
                autocomplete="one-time-code"
                name="verification-code"
                @blur="scrollToTop"
                @input="inputCode => inputHandler(inputCode)"
                >
              <div class="px-6 mx-6">
                <cc-divider
                  :height="2"
                  :color="dividerColor"
                  class="mb-4"
                  />
              </div>
            </div>

            <div
              data-ruid="global__validation-message"
              class="is-invalid font-size-16"
              >
              {{ validationMessage }}
            </div>
          </div>
        </form>

        <cc-button
          data-ruid="button-verify-phone"
          :variant="isSubmitting ? 'secondary' : 'primary'"
          :disabled="verificationCode.length !== 6 || isSubmitting"
          :text="isSubmitting ? $t('txtVerifying') : $t('txtContinue')"
          class="mt-5"
          @click.native.prevent="verifyPhoneNumber"
          />
      </cc-wrapper>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccView,
    ccButton,
    ccWrapper,
    ccText,
    ccHeading,
    ccDivider,
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    query: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'phone-verification',
      verificationCode: '',
      isSubmitting: false,
      validationMessage: '',
      dividerColor: undefined,
      nextPage: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
  },
  watch: {
    verificationCode() {
      this.validationMessage = '';
      this.dividerColor = 'blue-light';
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUserState: 'user/setState',
    }),
    async verifyPhoneNumber() {
      const phoneNumber = this.$route.query.phoneNumber || this.query?.phoneNumber;
      const code = this.verificationCode;
      const recovery = this.$route.query.recoverySession || this.query?.recoverySession;

      this.isSubmitting = true;

      if (recovery) {
        this.$store
          .dispatch('user/verifyPhoneTokenRecovery', { phoneNumber, code })
          .then(() => {
            if (!recovery) {
              if (this.isModal) {
                this.$emit('route-object', {
                  name: 'AccountVerified',
                });
              } else {
                this.$router.push({ name: 'account-verified' });
              }
            } else {
              this.$router.push({ name: 'footprint' });
            }
          })
          .catch((error) => {
            this.$log.warn(`Phone verification error: ${error.message}`);
            this.isSubmitting = false;
            this.validationMessage = this.$t('generalErrorMessage');
            this.dividerColor = 'danger';
          });
      } else {
        this.$store
          .dispatch('user/verifyPhoneToken', { phoneNumber, code })
          .then(() => {
            if (!recovery) {
              if (this.isModal) {
                this.$emit('route-object', {
                  name: 'AccountVerified',
                });
              } else {
                this.$router.push({ name: 'account-verified' });
              }
            } else {
              this.$router.push({ name: 'footprint' });
            }
          })
          .catch((error) => {
            this.$log.warn(`Phone verification error: ${error.message}`);
            this.isSubmitting = false;
            this.validationMessage = this.$t('generalErrorMessage');
            this.dividerColor = 'danger';
          });
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    inputHandler(inputEvent) {
      const inputValue = inputEvent.target.value.replace(/\s/g, '');
      this.verificationCode = inputValue.toUpperCase();
    },
  },
};
</script>
