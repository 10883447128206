<template>
  <cc-view :id="id">
    <cc-wrapper
      class="h-100 text-center safe-area-inset-bottom flex-grow-1"
      align="center"
      >
      <cc-wrapper>
        <cc-svg-base
          v-if="companyLogo"
          class="mb-4 font-size-20"
          svg="illustrations/company-onboard"
          style="width: 90px; height: auto"
          />
        <cc-heading
          variant="h4"
          class="mb-3"
          >
          {{ $t('successOnboardCodeTitle') }}
        </cc-heading>

        <cc-text class="mb-4 mx-5">
          {{ $t('successOnboardCodeBody') }}
        </cc-text>
        <cc-divider />
        <cc-text class="my-4 mx-5">
          {{ $t('successOnboardCodeMessage') }}
        </cc-text>
      </cc-wrapper>

      <!-- <cc-svg-base
        svg="logos/company-postfinance"
        style="height: 64px; width: auto"
        /> -->


      <cc-image
        v-if="companyLogo"
        :src="companyLogo"
        class="border-radius-full align-self-center"
        style="width: 90px; height: auto;"
        :has-placeholder="true"
        />
      <cc-svg-base
        v-if="!companyLogo"
        class="font-size-24"
        svg="illustrations/company-onboard"
        style="width: 90px; height: auto"
        />

      <cc-text class="py-4">
        {{ $t('companyOfOnboardCode') }}
        <strong v-if="getUserCompanyTagId"> {{ getCompanyName(getUserCompanyTagId) }}</strong>
      </cc-text>


      <cc-text v-if="getUserCompanyDepartment">
        {{ $t('groupOfOnboardCode') }}
        <strong> {{ getUserCompanyDepartment }}</strong>
      </cc-text>

      <cc-button
        v-if="isInOnboarding"
        class="mt-6 mx-6 justify-self-center"
        :block="false"
        :data-ruid="`${id}__primary-action`"
        :text="$t('startTheChallenge')"
        variant="primary"
        :has-loading-feedback="true"
        @click="primaryActionHandler"
        />

      <cc-button
        v-if="!isInOnboarding"
        variant="secondary"
        class="mt-6 mx-6"
        :block="false"
        :text="$t('removeMeFromCompany')"
        :data-ruid="`${id}__remove-action`"
        @click="removeActionHandler"
        />
    </cc-wrapper>

    <cc-company-remove-confirmation-modal
      v-if="isRemoveConfirmationModalDisplayed"
      ref="verify-email-modal"
      @close-modal="isRemoveConfirmationModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import validator from '@/mixins/validator';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccCompanyRemoveConfirmationModal from '@/components/modals/CompanyRemoveConfirmationModal.vue';

export default {

  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccText,
    ccButton,
    ccSvgBase,
    ccCompanyRemoveConfirmationModal,
  },
  mixins: [validator],
  props: {
    isInOnboarding: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      id: 'onboard-code',
      validationMessage: '',
      companyLogo: '',
      isRemoveConfirmationModalDisplayed: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserCompanyTagId: 'user/getUserCompanyTagId',
      getUserCompanyDepartment: 'user/getUserCompanyDepartment',
      getCompanyResourceByCompanyAlias: 'company/getCompanyResourceByCompanyAlias',
    }),
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
    }),
  },
  created() {
    this.companyLogo = this.getCompanyResourceByCompanyAlias(this.getUserCompanyTagId)?.logo;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    primaryActionHandler() {
      this.$router
          .push({ name: 'footprint' })
          .catch(() => {});
    },
    removeActionHandler() {
     this.isRemoveConfirmationModalDisplayed = true;
    },
    getCompanyName(tagId) {
      return this.getCompanyResourceByCompanyAlias(tagId)?.name;
    },
  },
};
</script>

<style></style>
