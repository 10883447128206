import Vue from 'vue';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/vue';

import service from '@/services/index';
import Complitude from '@/mixins/ChallengeComplitude';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// State object
const initialState = () => ({
  player: null,
  playerChallenges: [],
  activeChallenges: [],
  processedAchievements: {},
  achievements: [],
  publicGoal: null,

  isLoading: false,
});

const state = initialState();

// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

// Actions
const actions = {
  getPlayer({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setState', ['isLoading', true]);
      service.company
        .getPlayer()
        .then((response) => {
          commit('setState', ['player', response]);
          commit('setState', ['isLoading', false]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getPlayer error: ${err}`);
          commit('setState', ['isLoading', false]);
          reject(err);
        });
    });
  },

  getPlayerChallenges({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setState', ['isLoading', true]);
      service.achievements
        .getPlayerChallenges()
        .then((response) => {
          // Keep only PENDING and EVALUATING in memory for performance sake.
          const playerChallenges = response
            ?.filter(challenge => [Complitude.PENDING, Complitude.EVALUATING]
              ?.includes(challenge.status));

          commit('setState', ['playerChallenges', playerChallenges]);
          commit('setState', ['isLoading', false]);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getPlayerChallenges error: ${err}`);
          commit('setState', ['isLoading', false]);
          reject(err);
        });
    });
  },
  postActiveChallengesForPlayer({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      commit('setState', ['isLoading', true]);

      const userLocation = (!isEmpty(rootState.user.userLocation) && rootState.user.userLocation)
        || undefined;

      Vue.$log.info('postActiveChallengesForPlayer: userLocation:', userLocation);

      // const amsterdam1053SH = {
      //   location: {
      //     latitude: '52.362192',
      //     longitude: '4.858396',
      //     accuracy: '9.0',
      //   },
      //   isFresh: true,
      //   elapsedAgeMs: '23555',
      // };

      service.achievements
        .postActiveChallengesForPlayer(userLocation)
        .then((response) => {
          commit('setState', ['activeChallenges', response]);
          commit('setState', ['isLoading', false]);
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`postActiveChallengesForPlayer error: ${err}`);
          commit('setState', ['isLoading', false]);
          reject(err);
        });
    });
  },

  getPlayerAchievements({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setState', ['isLoading', true]);

      service.achievements
        .getPlayerAchievements()
        .then(async (response) => {
          commit('setState', ['achievements', response]);

          await dispatch('processAchievements', response);

          commit('setState', ['isLoading', false]);
          // Vue.$log.info('company/getPlayerAchievements OK', response);

          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`getPlayerAchievements error: ${err}`);
          commit('setState', ['isLoading', false]);
          reject(err);
        });
    });
  },

  processAchievements({ commit }, achievements) {
    // Vue.$log.info('Starting processAchievements', achievements);

    const processedAchievements = {};
    commit('setState', ['isLoading', true]);

    const sortedAchievements = achievements
      .slice()
      .sort((a, b) => moment(b.grantedAt) - moment(a.grantedAt));

    sortedAchievements.forEach((achievement) => {
      if (achievement.grantedAt) {
        const endOfCurrentday = moment().endOf('day');
        const grantedAt = moment(achievement.grantedAt);
        const daysAgo = endOfCurrentday.diff(grantedAt, 'days');
        if (!achievement.challengeTags?.includes('achievement:hidden')) {
          if (processedAchievements?.[daysAgo]) {
            processedAchievements[daysAgo] = [...processedAchievements[daysAgo], achievement];
          } else {
            processedAchievements[daysAgo] = [achievement];
          }
        }
      }
    });

    commit('setState', ['isLoading', false]);
    // Vue.$log.info('processedAchievements', processedAchievements);
    commit('setState', ['processedAchievements', processedAchievements]);
  },

  joinChallenge({ dispatch }, challengeId) {
    return new Promise((resolve, reject) => {
      service.achievements
        .joinChallenge(challengeId)
        .then(async () => {
          await dispatch('getPlayerChallenges');
          resolve();
        })
        .catch((err) => {
          Sentry.captureMessage(`joinChallenge error: ${err}`);
          reject(err);
        });
    });
  },

  getChallengeDayTotalPoints(context, { endDate, startDate }) {
    return new Promise((resolve, reject) => {
      service.achievements
        .getChallengeDayTotalPoints(endDate, startDate)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          Sentry.captureMessage(`joinChallenge error: ${err}`);
          reject(err);
        });
    });
  },

  getPublicGoalData({ commit, state }) {
    // Cache period 15 minutes
    const cacheDuration = 900000;

    if (state.publicGoal
      && state.publicGoal.lastUpdated
      && state.publicGoal.data && Date.now() - state.publicGoal.lastUpdated <= cacheDuration) {
      return new Promise((resolve) => {
        resolve(state.publicGoal.data);
      });
    }
    return new Promise((resolve, reject) => {
      service.achievements
        .getPublicGoalProgress()
        .then((response) => {
          commit('setState', ['publicGoal', {
            data: response,
            lastUpdated: Date.now(),
          }]);

          resolve(response);
        })
        .catch((err) => {
          Sentry.captureMessage(`getPublicGoalData error: ${err}`);
          reject(err);
        });
    });
  },
};

// Getter functions
const getters = {
  activePlayerChallenges: (state, getters) => state.playerChallenges.filter(
    challenge => getters.isActiveChallenge(challenge),
  ),

  upcomingPlayerChallenges: (state, getters) => state.playerChallenges.filter(
    challenge => getters.isUpcomingChallenge(challenge),
  ),

  delayedPlayerChallenges: (state, getters) => state.playerChallenges.filter(
    challenge => getters.isDelayedChallenge(challenge),
  ),

  isActiveChallenge: () => challenge => challenge.opened
    && !challenge.challenge.hidden
    && challenge.startedDate
    && new Date().toISOString() >= new Date(challenge.startedDate).toISOString(),

  isUpcomingChallenge: () => challenge => challenge.opened
    && !challenge.challenge.hidden
    && challenge.startedDate
    && new Date().toISOString() < new Date(challenge.startedDate).toISOString(),

  isDelayedChallenge: () => challenge => challenge.status === 'EVALUATING'
    && !challenge.challenge.hidden,

  activeChallenges: state => state.activeChallenges.filter(challenge => !challenge.hidden),

  hasPlayerChallenges: ({ playerChallenges }) => !!playerChallenges?.length,

  hasActivePlayerChallenges: (state, getters) => !!getters.activePlayerChallenges?.length,

  hasActiveChallenges: ({ activeChallenges }) => !!activeChallenges?.length,

  getPlayerAchievements: state => state.processedAchievements || false,

  hasAchievements: state => !isEmpty(state.processedAchievements),

  /**
   * getLimitedAchievements returns the same structure as processedAchievements
   */
  getLimitedAchievements: (state, getters) => (limit) => {
    const limitedAchievements = {};
    const achievements = getters.getPlayerAchievements;

    Vue.$log.info('Getter achievements', achievements);

    let counter = 0;

    if (limit > 0) {
      Object.keys(achievements).forEach((day) => {
        achievements[day].filter((achievement) => {
          if (counter < limit) {
            if (!limitedAchievements[day]) {
              limitedAchievements[day] = [];
            }
            limitedAchievements[day].push(achievement);
            counter += 1;
          }
          return achievement;
        });
      });

      // Vue.$log.info('limitedAchievements', limitedAchievements);
      return limitedAchievements;
    }

    return false;
  },

  /**
   * TODO: We might as well just search the id in the processed receipts if makes sense.
   * Currently it feels like we only keep the original array just to get by id
   */
  getAchievementById: state => achievementId => state.achievements
    .find(achievement => achievement?.id === achievementId),

  getChallengeProgressMessage: (state, getters, rootState, rootGetters) => challenge => rootGetters['company/getLocalizedResource'](challenge.challenge?.achievement?.resources)?.progressMessage,

  getChallengeRules: () => challenge => challenge?.challenge?.params?.valueParams,

  getChallengeAccumulator: () => challenge => JSON.parse(challenge?.accumulator || null)
    ?.valuesAccumulated,

  getEvaluatedProgressMessage: (state, getters) => (challenge) => {
    const accumulator = getters.getChallengeAccumulator(challenge);
    const challengeRules = getters.getChallengeRules(challenge);
    const progressMessage = getters.getChallengeProgressMessage(challenge);
    const pattern = /{{\s*([^{}\s]+)\s*}}/g;

    if (!accumulator) {
      return null;
    }

    const result = progressMessage?.replace(pattern, (match, key) => {
      let rules = challengeRules;

      key
        .trim()
        .split('.')
        .forEach((rule) => {
          rules = rules?.[rule];
        });

      if (typeof accumulator?.[rules] === 'number') {
        return parseInt(accumulator?.[rules], 10).toLocaleString();
      }

      return '_';
    });

    // Vue.$log.info('getEvaluatedProgressMessage result:', result);
    return result;
  },

  getActiveChallengeById: state => challengeId => state.activeChallenges
    .find(challenge => challenge.id === challengeId),

  /**
   * by looking into both lists, we incidently enabled a new state where a finished challenge
   * is accessible via /challenges/:challengeId
   * But this is useful for when we want to show completed challenges with details eventually
   *
   * Remark: we are now filtering out challenges FAILED and SUCCESS for performance sake when
   * retrieving in `getPlayerChallenges` action
   */
  getPlayerOrActiveChallengeById: state => (challengeId) => {
    const challenge = state.activeChallenges
      .find(activeChallenge => activeChallenge.id === challengeId)
      || state.playerChallenges.find(playerChallenge => playerChallenge.id === challengeId);

    return challenge;
  },

  getChallengeStatus: (state, getters) => (challenge) => {
    if (getters.isActiveChallenge(challenge)) {
      return 'active';
    }
    if (getters.isDelayedChallenge(challenge)) {
      return 'delayed';
    }
    if (getters.isUpcomingChallenge(challenge)) {
      return 'upcoming';
    }

    return 'available';
  },

  getCompletionPercentage: () => (challenge) => {
    /**
     * Backend is not capping the value between 0.0 and 1.0 at the moment
     * and sometimes returns NaN. Adding a few checks here to handle different cases
     */
    if (typeof challenge?.completionPercentage !== 'number') {
      return 0;
    }
    if (challenge?.completionPercentage > 1) {
      return 1;
    }
    if (challenge?.completionPercentage <= 1) {
      return challenge?.completionPercentage;
    }
    return 0;
  },

  challengeProgression: () => (challenge) => {
    const durationInMillis = challenge?.challenge?.durationInMillis;
    const startedDate = challenge?.startedDate;

    const durationInChallenge = moment() - moment(startedDate);

    const progress = Number((durationInChallenge / durationInMillis).toFixed(2));
    // Vue.$log.info('durationInChallenge', progress);

    return progress;
  },
};

export default {
  namespaced: true,
  state: () => state,
  getters,
  actions,
  mutations,
};
