<template>
  <div class="w-100">
    <label
      v-if="label"
      :for="id"
      :class="[labelClasses]"
      >
      <slot>{{ label }}</slot>
    </label>

    <span
      v-if="svg"
      class="position-absolute inset-y-0 left-0 pl-3 d-flex align-items-center"
      >
      <cc-svg-base
        :svg="svg"
        color-class="svg-fill-white"
        />
    </span>

    <span
      v-if="(hasClearInputButton || hasPasswordToggle) && hasInput"
      class="position-absolute inset-y-0 right-0 pr-3 d-flex align-items-center"
      >
      <!-- <span class="bg-blue-darkest pr-3"> -->
      <cc-svg-base
        v-if="hasClearInputButton"
        svg="icons/icon-small-cross"
        color-class="svg-fill-white"
        @click="clearInput"
        />
      <cc-svg-base
        v-if="hasPasswordToggle"
        svg="icons/icon-eye"
        :color-class="isPasswordToggled ? 'svg-fill-opacity-100' : 'svg-fill-opacity-60'"
        @click.native="togglePasswordField"
        />
      <!-- </span> -->
    </span>

    <input
      :id="id"
      :type="getType"
      :class="[inputClasses]"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autocapitalize="autocapitalize"
      :readonly="readonly"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      :minlength="minlength"
      :disabled="disabled || appState.isAwaiting"
      :value="value"
      @focus="handleFocus"
      @input="handleInput"
      @blur="handleBlur"
      >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ccSvgBase from '@/components/SvgBase.vue';

// Valid supported input types
const alignProps = [
  'left', //
  'center',
  'right',
];

export default {
  components: {
    ccSvgBase,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    readonly: {
      type: Boolean,
      default: null,
    },
    min: {
      type: String,
      default: null,
      required: false,
    },
    max: {
      type: String,
      default: null,
      required: false,
    },
    maxlength: {
      type: String,
      default: null,
      required: false,
    },
    minlength: {
      type: String,
      default: null,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
      required: false,
    },
    autocapitalize: {
      type: String,
      default: null,
      required: false,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasClearInputButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasPasswordToggle: {
      type: Boolean,
      default: false,
      required: false,
    },
    state: {
      type: String,
      default: null,
      required: false,
    },
    label: {
      type: String,
      default: null,
      required: false,
    },
    labelClass: {
      type: [String, Array],
      default: null,
    },
    inputClass: {
      type: [String, Array],
      default: null,
    },
    svg: {
      type: [String, Object],
      required: false,
      default: null,
    },
    hasVisibleLabel: {
      type: Number,
      default: 2,
      required: false,
    },
    align: {
      type: String,
      default: 'left',
      validator: type => alignProps.includes(type),
    },
  },
  data() {
    return {
      isVisibleLabel: false,
      enteredValue: '',
      inputValue: '',
      isPasswordToggled: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    getType() {
      return this.isPasswordToggled ? 'text' : this.type;
    },
    hasInput() {
      return this.value?.length > 0;
    },
    labelClasses() {
      return [
        'font-size-14 font-weight-bold',
        'transition-opacity',
        this.handleLayerVisibility ? 'opacity-1' : 'opacity-0',
        'font-size-12',
        this.labelClass,
        this.align === 'center' ? ['w-100 text-center'] : '',
      ];
    },
    inputClasses() {
      return [
        this.svg ? 'pl-7' : null,
        this.hasClearInputButton && 'pr-7',
        this.hasPasswordToggle && 'pr-8',
        this.inputClass,
        this.state, //
        this.align === 'center' ? ['text-center'] : '',
      ];
    },
    handleLayerVisibility() {
      if (this.hasVisibleLabel === 2) {
        return false;
      }
      if (this.hasVisibleLabel === 1 || this.inputValue) {
        return true;
      }
      if (this.hasVisibleLabel === 0 && this.isVisibleLabel) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // this.$log.warn('input mounted');
  },
  methods: {
    togglePasswordField() {
      this.isPasswordToggled = !this.isPasswordToggled;
    },
    clearInput() {
      this.inputValue = '';
      this.$emit('input', '');
    },
    handleInput(event) {
      // this.$log.warn(event.target.value);
      this.$emit('input', event.target.value);
    },
    handleFocus(event) {
      // this.$log.info('handleFocus', e.target);
      // this.setAppState(['hasHiddenTabBar', true]);
      if (this.handleLayerVisibility || this.hasVisibleLabel === 0) {
        this.isVisibleLabel = true;
      }

      // this.enteredValue = e.target;
      this.$emit('focus', event.target);
    },
    handleBlur(event) {
      /**
       * BUG
       * viewport-fit=cover causes a bug when dismissing the keyboard.
       *
       * When an input that would require webview centering is clicked,
       * the viewport is repositioned to center that input, as iOS has traditionally done.
       * However, when dismissing the keyboard,
       * the viewport is not re-positioned properly back to its original position.
       *
       * Workaround is to scroll the window scroll to 0
       *
       * Bug has been addressed by Webkit but keeping the workaround here in case
       * we have users still using that version of the iOS.
       *
       * I hava an iPhone 6S device with iOS 12.3.1 where I'm able to reproduce it.
       *
       * http://www.openradar.me/44655885
       * https://bugs.webkit.org/show_bug.cgi?id=192564
       *
       */
      if (event.relatedTarget === null) {
        window.scrollBy(0, 0);
      }
      this.$emit('blur', event.target.value);

      if (this.handleLayerVisibility) {
        this.isVisibleLabel = false;
      }
      // this.$log.info('blur', event);
      this.inputValue = event.target.value;
    },
  },
};
</script>
