<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :is-modal="isModal"
    >
    <cc-wrapper class="h-100 safe-area-inset-bottom pb-6 flex-grow-1">
      <div class="pb-6">
        <cc-lottie
          :options="animData"
          width="150px"
          @animCreated="handleAnim"
          />

        <cc-heading align="center">
          {{ $t('accountVerified') }}
        </cc-heading>
      </div>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapMutations } from 'vuex';
import accountVerified from '@/components/lottie/account-verified.json';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccLottie from '@/components/Lottie.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccLottie,
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'account-verified',
      path: null,
      animData: {
        loop: false,
        autoplay: false,
        animationData: accountVerified,
      },
    };
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    handleAnim(anim) {
      setTimeout(() => anim.play(), 200);
      anim.setSpeed(1.5);
      anim.addEventListener('complete', () => {
        if (this.isModal) {
          this.$emit('close-modal', 'next');
        } else {
          this.$router.push({ name: 'footprint' }).catch(() => {});
        }
      });
    },
  },
};
</script>
