<template>
  <cc-modal
    data-ruid="modal__reverify-email-modal"
    group-class="bg-blue-dark p-5"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('reverifyEmailModalTitle')"
          svg="illustrations/group-created"
          />

        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ $t('reverifyEmailModalDescription' ) }}
        </cc-text>
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="pt-5"
        >
        <cc-button
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('txtOk')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'reverify-email-modal',
    };
  },
};
</script>
