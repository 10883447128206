<template>
  <cc-card
    :id="id"
    :ruid="id"
    button-variant="small-muted"
    variant="mia"
    >
    <template #body>
      <div>
        <cc-heading
          variant="h5"
          class="pb-2 text-center"
          >
          {{ $t('miaActiveEmployees') }}
        </cc-heading>
        <cc-heading
          variant="h2"
          class="pb-1 text-center"
          color="cc-yellow"
          >
          {{ formattedTotal }}
        </cc-heading>
        <div class="d-flex justify-content-center">
          <cc-happy-earth-placeholder />
        </div>
      </div>
    </template>
  </cc-card>
</template>

<script>
import ccHeading from '@/components/primitives/Heading.vue';
import ccCard from '@/components/constructs/Card.vue';
import { numberWithCommas } from '@/mixins/utils';
import ccHappyEarthPlaceholder from './HappyEarthPlaceholder.vue';

export default {
  name: 'ActiveEmployees',
  components: {
    ccHeading,
    ccCard,
    ccHappyEarthPlaceholder,
  },
  props: {
    total: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      id: 'co2-average-employee-emissions',
    };
  },
  computed: {
    formattedTotal() {
      return numberWithCommas(this.total);
    },
  },
};
</script>
