import Vue from 'vue';
import CryptoJS from 'crypto-js';

import { getDefaultEmissionType } from '@/mixins/transportTypes';
import { Coefficients } from '@/mixins/config';
import { cantons } from '@/mixins/cantons';
import moment from 'moment';
import i18n from '@/i18n';


export function toKebapCase(str) {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
}

export function encryptData(message, secret) {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(message), secret).toString();
  } catch (error) {
    return false;
  }
}

export function decryptData(message, secret) {
  try {
    const bytes = CryptoJS.AES.decrypt(message, secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return null;
  }
}

export function getPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isTouchDevice = 'ontouchstart' in window || window.TouchEvent || window.DocumentTouch;

  if (userAgent.match(/Mac\sOS\sX/i) && isTouchDevice) {
    return 'iOS';
  }

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS';
  }
  if (userAgent.match(/Android/i)) {
    return 'Android';
  }
  return 'Desktop';
}

export function maskUrlParam(fullUrl, param) {
  /*
   * Given a full url and valid param key is provided,
   * masks the value of given param key.
   *
   * @param {String} fullUrl - Expects full url
   * @param {String} param - Expects parameter name
   * @return {String} Full url
   */
  if (!fullUrl || !param) {
    return 'missing parameter';
  }

  const url = new URL(fullUrl);
  url.searchParams.set(param, '******');
  return url;
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return false;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isUpperCase(str) {
  return str === str.toUpperCase();
}

/**
 * Trying to infer whether value is an SMS code or not for Sentry logging
 */
export function isSmsCode(code) {
  if (code.length === 6 && isUpperCase(code)) {
    return true;
  }

  return false;
}

export function getEndpoint(fullUrl) {
  /*
   * Given a full url is provided, replaces numbers (current intention is phonenumbers)
   * possible SMS codes (only guess work/with good faith/could be narrowed down with an endpoint)
   *
   * @param {String} fullUrl - Expects full url
   * @return {String} url - Url without protocol
   */

  if (!fullUrl) {
    return 'missing parameter';
  }

  const url = new URL(fullUrl);

  const sanitizedPath = url.pathname
    .split('/')
    .map((path) => {
      const isNumber = path !== '' && !Number.isNaN(Number(decodeURIComponent(path)));

      if (isSmsCode(path)) {
        return '{{SMSCode}}';
      }

      if (isNumber) {
        return '{{number}}';
      }

      return path;
    })
    .join('/');

  Vue.$log.info('sanitizedPath', `${url.host}${sanitizedPath}`);
  return `${url.host}${sanitizedPath}`;
}

export function combineURLs(baseURL, relativeURL) {
  return relativeURL
    ? `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}`
    : baseURL;
}
export function getAllowedParameters(fullUrl) {
  /*
   * Given a full url is provided, loops through parameter set of the url
   * creates a new object to return with the allowed parameter names.
   * We should add parameters to `allowedParams` list as we need
   *
   * @param {String} fullUrl - Expects full url
   * @return {Object} params - An object with key/value pairs of params
   */

  if (!fullUrl) {
    return 'missing parameter';
  }

  const url = new URL(fullUrl);

  const allowedParams = [
    'userId',
    'context',
    'from',
    'to',
    'windowsAgo',
    'boardType',
    'appId',
    'os',
    'osVersion',
    'recoverySession',
  ];

  const params = {};

  url.searchParams.forEach((value, key) => {
    if (allowedParams.includes(key)) {
      params[key] = value;
    }
  });

  return params;
}

export function browserSpecs() {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  return { name: M[0], version: M[1] };
}

export function disableTouchMove() {
  document.addEventListener(
    'touchmove',
    (e) => {
      e.preventDefault();
    },
    { passive: false },
  );
}

export function callSdk(action) {
  // Dispatch payloads from web-app to SDK
  // More details @ https://wiki.swisscom.com/display/SCCD/Web+-+Native+Communication
  // Vue.$log.info(`calling sdk from utils ${action}`);

  try {
    if (getPlatform() === 'Android' && window.androidClient) {
      window.androidClient.callback(action);
    } else if (getPlatform() === 'iOS' && window.webkit) {
      window.webkit.messageHandlers.callback.postMessage(action);
    }
    return undefined;
  } catch (err) {
    // console.log(err);
    return err;
  }
}

export function getNumberOfWeek(timestamp) {
  const date = new Date(timestamp);
  const firstDayOfYear = new Date(timestamp.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function daysInCurrentMonth(date) {
  // +1 after the month because javascript uses a 0-indexed getMonth return
  // but takes in a 1-indexed parameter
  return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate();
}

export function seperateTransportTypeAndFuelType(transportTypeAndFuelType) {
  const str = transportTypeAndFuelType;
  const transportType = str.substring(0, str.lastIndexOf('_'));
  const fuelType = str.substring(str.lastIndexOf('_') + 1, str.length);

  return {
    transportType,
    fuelType,
  };
}

export function showIncrementDate(qDate, filter) {
  const queryDate = new Date(qDate);
  queryDate.setHours(0, 0, 0);
  switch (filter) {
    case 'today':
      if (queryDate.setDate(queryDate.getDate() + 1) > new Date()) {
        return false;
      }
      return true;
    case 'week':
      if (queryDate.setDate(queryDate.getDate() + 7) > new Date()) {
        return false;
      }
      return true;
    case 'month':
      if (queryDate.setMonth(queryDate.getMonth() + 1) > new Date()) {
        return false;
      }
      return true;
    case 'year':
      if (queryDate.setFullYear(queryDate.getFullYear() + 1) > new Date().getTime()) {
        return false;
      }
      return true;
    default:
      return true;
  }
}

function addOrReplace(transportModeSummaries, newTransitMode, attr) {
  const attribute = attr || 'transitMode';
  const data = transportModeSummaries;
  const transitModeCopy = newTransitMode;
  for (let i = 0; i < data.length; i += 1) {
    if (data[i][attribute] === newTransitMode[attribute]) {
      transitModeCopy.totalCo2 += data[i].totalCo2;
      transitModeCopy.totalDistance += data[i].totalDistance;
      data[i] = transitModeCopy;

      return transportModeSummaries;
    }
  }
  return transportModeSummaries.push(newTransitMode);
}

export function addToChartData(chartData, index, transitElement, ratio = 1) {
  const totalCo2 = (transitElement.meta.emissions?.co2 || 0) * ratio;
  const totalDistance = transitElement.distance * ratio;
  const totalCo2IfElectric = (transitElement.meta.emissions?.co2IfElectric || 0) * ratio;
  const totalKwhIfElectric = (transitElement.meta.emissions?.kwhIfElectric || 0) * ratio;
  const data = chartData;
  let displayTypeObject = transitElement.displayType;
  let { emissionType } = transitElement.meta;
  let transitMode = `${transitElement.transitMode}_${transitElement.meta.emissionType}`;

  if (transitElement.meta.emissionType === 'default') {
    const defaultType = getDefaultEmissionType(transitElement.transitMode);
    transitMode = `${transitElement.transitMode}_${defaultType}`;
    emissionType = defaultType;
    displayTypeObject = `${displayTypeObject.transitMode}_${defaultType}`;
  } else {
    displayTypeObject = `${displayTypeObject.transitMode}_${emissionType}`;
  }

  // TODO: don't look if included
  if (emissionType?.includes('fossil') || emissionType === 'motorbike') {
    data.fossil[index] += totalDistance;
    addOrReplace(
      data.transportModeSummaries,
      {
        transitMode,
        totalCo2,
        totalDistance,
        displayType: displayTypeObject,
        fuelType: 'FOSSIL',
      },
      'displayType',
    );
  } else if (emissionType?.includes('electric') || emissionType?.includes('hybrid')) {
    data.electric[index] += totalDistance;
    addOrReplace(
      data.transportModeSummaries,
      {
        transitMode,
        totalCo2,
        totalDistance,
        displayType: displayTypeObject,
        fuelType: 'ELECTRIC',
      },
      'displayType',
    );
  } else if (emissionType === 'human-powered') {
    data.humanPowered[index] += totalDistance;
    addOrReplace(
      data.transportModeSummaries,
      {
        transitMode,
        totalCo2,
        totalDistance,
        displayType: displayTypeObject,
        fuelType: 'HUMAN_POWERED',
      },
      'displayType',
    );
  }

  data.emission[index] += totalCo2;
  data.noData[index] = 0;
  data.summary.totalCo2IfElectric += totalCo2IfElectric;
  data.summary.totalKwhIfElectric += totalKwhIfElectric;
  data.summary.totalDistance += totalDistance;
  data.summary.totalCo2 += totalCo2;

  return data;
}

/**
 * Returns a route name based on user and navigation state where the back button action should lead
 * @param {Object} route Vue Router, route object
 * @param {String} prevRoute Previous route name
 * @param {Boolean} isAuthenticated User auth state
 * @param {Array} historyState Users navigation history
 * @returns {String} Route name
 */
export function getBackRoute(route, prevRoute, isAuthenticated, historyState) {
  const { name, params } = route;
  if (params?.backTo) {
    return params.backTo;
  }
  const prev = prevRoute || '';

  /**
   * JTC stands for JoinTheChallenge route 🤷🏻‍♂️
   * Use this approach when a generic page such as `join-the-challenge` have multiple entry points
   * and after going further in the flow, when coming back, you will need the route user jumped off
   * Following will look in the history records and return first entry matches the list
   */
  const validJTCEntries = ['settings', 'footprint', 'leaderboards', 'getting-started'];
  const hasJTCEntry = historyState?.find(entry => validJTCEntries.includes(entry));

  // Here define where back button should go based on where we are currently
  switch (name) {
    case 'points':
    case 'wallet':
    case 'shop':
    case 'challenges':
    case 'test-page':
    case 'leaderboards':
    case 'public-goal-progress':
    case 'join-company-challenge':
      return 'footprint';

    case 'public-goal-welcome':
      if (prev === 'shop') {
        return prev;
      }
      return 'footprint';

    case 'add-onboard-code':
    case 'onboard-code':
      if (prev === 'footprint') {
        return 'footprint';
      }
      return 'settings';
    case 'language-settings':
    case 'message-delivery-preferences':
    case 'about':
    case 'developer-menu':
    case 'support':
    case 'delivery-addresses':
    case 'push-notification-debugger':
      return 'settings';

    case 'delivery-address':
      return 'delivery-addresses';

    case 'delete-account':
    case 'credits':
      return 'support';

    case 'partners':
    case 'about-scc':
      return 'about';

    case 'getting-started':
      if (prev === 'about') {
        return prev;
      }
      return 'footprint';

    case 'news-feed':
      return 'footprint';

    case 'messages':
      if (prev === 'leaderboards') {
        return prev;
      }
      if (prev === 'achievements') {
        return prev;
      }
      if (prev === 'challenges') {
        return prev;
      }
      if (prev === 'shop') {
        return prev;
      }
      if (prev === 'wallet') {
        return prev;
      }
      if (prev === 'points') {
        return prev;
      }
      return 'footprint';

    case 'settings':
      if (prev === 'leaderboards') {
        return prev;
      }
      if (prev === 'challenges') {
        return prev;
      }
      if (prev === 'shop') {
        return prev;
      }
      if (prev === 'wallet') {
        return prev;
      }
      if (prev === 'points') {
        return prev;
      }
      return 'footprint';

    case 'journeys':
    case 'climate-change':
      return 'footprint';

    case 'friends-leaderboard':
    case 'group-leaderboard':
    case 'company-leaderboard':
    case 'company-group-leaderboard':
    case 'join-group-leaderboard':
    case 'canton-leaderboard':
      return 'leaderboards';

    case 'group':
      return 'group-leaderboard';

    case 'group-settings':
      return 'group';

    case 'add-group-members':
      return 'group';

    case 'group-external-invite':
      return 'group';

    case 'achievements':
      if (prev === 'messages') {
        return prev;
      }
      return 'challenges';

    case 'join-challenges':
    case 'challenge':
      return 'challenges';

    case 'company':
    case 'product-category':
      return 'shop';


    case 'company-profile-via-company':
    case 'product-via-company':
      if (prev === 'product-category') {
        return prev;
      }
      return 'company';

    case 'product':
      if (prev === 'product-category') {
        return prev;
      }
      if (prev === 'company') {
        return prev;
      }
      return 'shop';

    case 'company-profile-via-product-category':
      return 'product-via-category';

    case 'product-via-category':
      return 'product-category';

    case 'company-profile-via-product':
      return 'product-via-company';

    case 'company-profile':
      return 'product';

    case 'vehicle-settings':
      if (prev === 'journeys') {
        return prev;
      }
      return 'settings';

    case 'join-the-challenge':
      if (prev === 'settings') {
        return prev;
      }
      if (prev === 'getting-started') {
        return prev;
      }
      if (prev === 'leaderboards') {
        return prev;
      }

      if (hasJTCEntry) {
        return hasJTCEntry;
      }
      return 'leaderboards';

    case 'add-company-email':
      return 'start';

    case 'phone-verification':
      return 'signup';

    case 'login-by-email':
      return 'signup';

    case 'signup':
      /**
       * There is a another sign up flow in various areas of the app such as
       * through Friends Leaderboard card or Groups.
       * In the `sign-up` step of that flow the previous view is join the challenge.
       */
      if (isAuthenticated) {
        return 'join-the-challenge';
      }
      return 'start';

    // case 'onboarding':
    //   if (prev === 'settings') {
    //     return 'settings';
    //   }
    //   return '';

    // TODO: Maybe its a better idea to have these to in modal view,
    // becaue expected behaviour is different based on
    // where we are coming from and where to go next, its not very maintable like this.
    // also causes some other issues on view transition handling

    case 'canton-selection':
      if (prev === 'settings') {
        return 'settings';
      }
      if (prev === 'canton-leaderboard') {
        return 'canton-leaderboard';
      }
      return 'leaderboards';

    default:
      return '';
  }
}

export function toOrdinalNumber(value, locale) {
  const j = value % 10;
  const k = value % 100;

  switch (locale) {
    case 'en':
      if (j === 1 && k !== 11) {
        return `${value}st`;
      }
      if (j === 2 && k !== 12) {
        return `${value}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${value}rd`;
      }
      return `${value}th`;
    case 'de':
      return `${value}.`;
    case 'it':
      return `${value}°`;
    case 'fr':
      if (value === 1) {
        return `${value}er`;
      }
      return `${value}e`;
    default:
      return value;
  }
}

export function isElementInView(element, fullyInView) {
  const pageTop = window.scrollY;
  const pageBottom = pageTop + window.innerHeight;
  const elementTop = element.offsetTop;
  const elementBottom = elementTop + element.offsetHeight;

  if (fullyInView) {
    return pageTop < elementTop && pageBottom > elementBottom;
  }
  return elementTop <= pageBottom && elementBottom >= pageTop;
}

export function getPercentageChange(initialValue, finalValue) {
  const increase = initialValue - finalValue;

  const percentage = (increase / finalValue) * 100;

  return percentage;
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function scrollTo(id, elementOffset = 190) {
  const el = document.querySelector(id);
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  window.scroll({ top: rect.top + scrollTop - elementOffset, behavior: 'smooth' });
}

export function calcTemperatureIncrease(timeInSec, co2InGrams) {
  let temperatureIncrease = 0;
  const { coefficient, baseTemperature } = Coefficients.temperatureImpact;
  const ratioOfYear = (86400 * 365) / timeInSec;
  const co2InTons = (co2InGrams / 1000000) * ratioOfYear;

  temperatureIncrease = coefficient * co2InTons + baseTemperature;
  if (temperatureIncrease > 4) {
    temperatureIncrease = 4;
  }
  return temperatureIncrease;
}

export function getCantonName(cantonCode) {
  if (cantonCode in cantons) {
    return cantons[cantonCode].name;
  }
  return '-';
}

export async function retry(fn, retriesLeft = 2, interval = 1000, exponential = false) {
  try {
    const val = await fn();
    return val;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => { setTimeout(r, interval); });
      return retry(fn, retriesLeft - 1, exponential ? interval * 2 : interval, exponential);
    }

    return Promise.reject(error);
  }
}

export function decodeAccessToken(accessToken) {
  return JSON.parse(atob(accessToken.split('.')[1]));
}

export function isValidIssuer(accessToken) {
  return decodeAccessToken(accessToken).iss === 'rastro';
}

export function sentenceCase(string) {
  if (string) {
    return `${string?.[0].toUpperCase()}${string.slice(1)}`;
  }
  return null;
}

export function camelize(string) {
  return string?.replace(/-./g, x => x[1].toUpperCase());
}

export function FPSMeter(cb) {
  const times = [];

  const refreshLoop = () => {
    window.requestAnimationFrame(() => {
      const now = performance.now();
      while (times.length > 0 && times[0] <= now - 1000) {
        times.shift();
      }
      times.push(now);
      cb(times.length);
      // console.log('FPS', times.length);
      // this.fps = times.length;
      refreshLoop();
    });
  };

  refreshLoop();
}

export function formatCo2(co2) {
  if (co2 >= 1000) {
    return (co2 / 1000).toFixed(1);
  }
  return co2.toFixed(0);
}

export function co2Prefix(co2) {
  if (co2 >= 1000) {
    return 'kg';
  }
  return 'g';
}

export function distanceText(distance) {
  if (distance >= 1000) {
    const kilometers = (distance / 1000).toFixed(0);
    return `${kilometers} km`;
  }
  return `${distance.toFixed(0)} m`;
}

export function kebabize(str) {
  return str.split('').map((letter, idx) => (letter.toUpperCase() === letter
    ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
    : letter)).join('');
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


export function isDateGivenDaysAgo(dateString, daysAgo) {
  const date = new Date(dateString);
  const someDaysAgo = new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000);

  return date <= someDaysAgo;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export function dateOfWeekNumberToText(date) {
  switch (date) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      return null;
  }
}
// Object

export function parseSearchParams(url) {
  if (!url) return {};
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);
  const paramsObj = {};

  searchParams.forEach((value, key) => {
    paramsObj[key] = value;
  });

  if (Object.keys(paramsObj).length === 0) return null;

  return paramsObj;
}

export function parseProtocol(url) {
  if (!url) return null;
  return new URL(url).protocol.split(':')[0];
}

export function parsePathname(url) {
  if (!url) return null;
  return new URL(url).pathname;
}

export function parseHrefInfo(url) {
  if (!url) return null;
  const params = parseSearchParams(url);
  const protocal = parseProtocol(url);
  const pathname = parsePathname(url);
  const name = pathname.split('/')[0];
  const id = pathname.split('/')[1];
  return {
    name, id, params, protocal,
  };
}

export function handleImagePrefix(item) {
  if (!item) return '';
  /**
   * Change a Webflow CMS image asset URL to its resized asset by
   * inserting '-p-500' before the file name extension.
   *
   * Example:
   * https://uploads-ssl.webflow.com/5dd50ff99fa9b189f36d5578/63047dc3daf993454e7d923d_Windkraft.jpg
   * https://uploads-ssl.webflow.com/5dd50ff99fa9b189f36d5578/63047dc3daf993454e7d923d_Windkraft-p-500.jpg
   */

  let URLString = item?.['media:thumbnail']?.attributes?.url?.split('.');

  if (URLString) {
    URLString[URLString.length - 2] = `${URLString[URLString.length - 2]}-p-500`;
    URLString = URLString.join('.');

    return URLString;
  }

  return '';
}

export function getEmissionFromAvgAccumulators(avgAccumulators, key) {
  if (!avgAccumulators) {
    return [0, 0, 0, 0, 0, 0, 0];
  }
  const emission = Object.keys(avgAccumulators).map(
    date => avgAccumulators[date]?.[key]?.co2 || 0,
  );

  if (emission.length < 7) {
    const fillData = 7 - emission.length;
    for (let i = 0; i < fillData; i += 1) {
      emission.unshift(0);
    }
  }
  return emission;
}

export function getLabelFromLastSevenday(lastSevenday) {
  let newLastSevenday = lastSevenday;
  const hasSevenday = newLastSevenday && newLastSevenday.length;
  if (hasSevenday) {
    if (newLastSevenday.length < 7) {
      const fillData = 7 - newLastSevenday.length;
      for (let i = 0; i < fillData; i += 1) {
        newLastSevenday.unshift(
          moment(newLastSevenday[0], 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'),
        );
      }
    }
  } else {
    newLastSevenday = [1, 2, 3, 4, 5, 6, 0];
  }
  return newLastSevenday.map(date => i18n.t(
    `day${dateOfWeekNumberToText(
      hasSevenday ? moment(date, 'YYYY-MM-DD').day() : date,
    )}`,
  ));
}


/**
 * Generates the from and to periods for the given filter and queryDate
 * This should be eventually moved to a helper and used in the store
 * as well as in the components
 *
 * @todo move to helper or store
 * @param {Number} queryDate
 * @param {String} filter
 * @returns {Object}
 */
export function generateFromToPeriods(queryDate, filter) {
  const date = moment(queryDate);
  let from = null;
  let to = null;
  let previousFrom = null;
  let previousTo = null;

  switch (filter) {
    case 'today':
      from = date.startOf('day');
      to = date.clone().endOf('day');
      previousFrom = date.clone().subtract(1, 'days').startOf('day');
      previousTo = date.clone().subtract(1, 'days').endOf('day');
      break;
    case 'week':
      from = date.startOf('isoWeek');
      to = date.clone().endOf('isoWeek');
      previousFrom = date.clone().subtract(1, 'weeks').startOf('isoWeek');
      previousTo = date.clone().subtract(1, 'weeks').endOf('isoWeek');
      break;
    default:
      break;
  }

  return {
    from,
    to,
    previousFrom,
    previousTo,
    currentPeriod: `${from.toISOString()}-${to.toISOString()}`,
    previousPeriod: previousFrom
      ? `${previousFrom.toISOString()}-${previousTo.toISOString()}`
      : null,
  };
}
export function tripPurposeNameHandler(tripPurpose) {
  if (tripPurpose && tripPurpose.toString().toLowerCase() === 'commute') return 'commute';
  if (tripPurpose && (tripPurpose.toString().toLowerCase() === 'work' || tripPurpose.toLowerCase() === 'business')) return 'business';
  return 'private';
}
