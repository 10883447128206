<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-text="$t('shareDescription')"
    :padded="false"
    :pressable="true"
    :button-label="$t('shareButton')"
    class="pb-4"
    svg="share-icon"
    @click.native="shareClicked"
    />
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';

export default {
  components: {
    ccCard,
  },
  data() {
    return {
      id: 'share-card',
      share: {
        url: process.env.VUE_APP_WEBSITE,
        text: this.$t('shareMessage'),
      },
    };
  },
  methods: {
    shareClicked() {
      const { social } = this.kibanaEvents;

      try {
        this.callSdk(
          `action=${this.actions.SHARE}&url=${encodeURIComponent(
            this.share.url,
          )}&text=${encodeURIComponent(this.share.text)}`,
        );

        this.$store.dispatch('common/insertEvent', {
          category: social.category,
          action: social.shareSCC.action,
          type: 'website',
        });
      } catch (error) {
        this.$log.info('common/insertEvent NOK', error);
      }
    },
  },
};
</script>
