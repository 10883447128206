<template>
  <div class="d-flex flex-row justify-content-center">
    <div class="w-40 h-100">
      <div
        class="d-flex flex-row indicator-right position-relative line-height-17"
        :style="{ top: `${top}px`, 'transition': 'all 1s', }"
        >
        <div class="d-flex flex-column">
          <cc-text
            variant="body-xs-bold"
            color="muted"
            class="text-right"
            >
            {{ $t('txtYou') }}
          </cc-text>

          <cc-text variant="body-xs-bold">
            +{{ temperature && temperature.toFixed(2) }}°C
          </cc-text>
        </div>

        <cc-vertical-thermometer-earth
          class="mx-1 pr-1 mb-2"
          :style="{'width': '52 px', 'height': '33px'}"
          />
      </div>
    </div>

    <div class="position-relative d-flex flex-column">
      <div
        id="verticalThermometer"
        class="d-flex justify-content-around flex-column
        vertical-thermometer py-3 rounded-41 align-items-center"
        >
        <template v-for="(value, index) in thermometerValues(4)">
          <div
            :id="index"
            :key="index"
            class="text-black font-size-12"
            >
            <span
              :class="index % 2 === 0 ? 'opacity-40 font-weight-sbold'
                : 'opacity-20 font-weight-bold'"
              >
              {{ value }}
            </span>
            <!-- <cc-image
              v-if="index === 2"
              class="position-absolute pl-2 mt-2"
              src="temperature-line-2.png"
              />
            <cc-image
              v-if="index === 4"
              class="position-absolute pl-2 mt-2"
              src="temperature-line-1.png"
              /> -->
            <span
              v-if="index === 2"
              :style="{ top: `${calculateTop(2.88)}px`, left: '45px'}"
              class="position-absolute ml-2 mt-2 temperature-line line-1"
              />
            <span
              v-if="index === 4"
              :style="{ top: `${calculateTop(1.88)}px`, left: '45px'}"
              class="position-absolute ml-2 mt-2 temperature-line line-2"
              />
          </div>
        </template>
      </div>
    </div>


    <div class="w-40 h-100">
      <div
        class="d-flex flex-row indicator-left position-relative line-height-17 pl-3"
        :style="{ top: `${calculateTop(1)}px`}"
        >
        <cc-svg-base
          :key="'happy'"
          svg="illustrations/happy-earth"
          />
      </div>
      <div
        class="d-flex flex-row indicator-left position-relative line-height-17 pl-3"
        :style="{ top: `${calculateTop(3)}px`}"
        >
        <cc-svg-base
          :key="'sad'"
          svg="illustrations/sad-earth"
          />
      </div>
      <div
        class="d-flex flex-row indicator-left position-relative line-height-17 pl-3"
        :style="{ top: `${calculateTop(4.5)}px`}"
        >
        <cc-svg-base
          :key="'lifeless'"
          svg="illustrations/lifeless-earth"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
// import ccImage from '@/components/primitives/Image.vue';
import ccVerticalThermometerEarth from '@/components/VerticalThermometerEarth.vue';

export default {
  components: {
    ccText,
    ccVerticalThermometerEarth,
    ccSvgBase,
    // ccImage,
  },
  data() {
    return {
      offsetTop: 0,
      distanceBetweenFirstAndLast: 0,
    };
  },
  computed: {
    ...mapState({
      temperature: state => state.trips.temperature,
      appState: state => state.common.appState,
      cantonTemperature: state => state.trips.cantonTemperature,
      user: state => state.user.user,
    }),
    ...mapGetters({
      getCantonPreference: 'user/getCantonPreference',
    }),
    filter() {
      return this.appState.filter;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    top() {
      const ratio = (this.temperature / 4);
      return this.offsetTop - 3.75 + (ratio * this.distanceBetweenFirstAndLast) - 1;
    },
    lifelessTop() {
      const ratio = (3.5 / 4);
      return this.offsetTop + (ratio * this.distanceBetweenFirstAndLast) - 1;
    },
  },
  watch: {
    queryDate() {
      this.$store.dispatch('social/getCantonLeaderboard');
    },
  },
  async mounted() {
    this.calculatePosition();
    await this.$store.dispatch('trips/calculateCantonTemperature');
  },
  methods: {
    calculatePosition() {
      const first = document.getElementById('8');
      const last = document.getElementById('0');
      const verticalThermometer = document.getElementById('verticalThermometer');

      this.distanceBetweenFirstAndLast = last.offsetTop - first.offsetTop;
      this.offsetTop = first.offsetTop - verticalThermometer.offsetTop - 5;
    },
    thermometerValues(maxTemperature) {
      const result = [];
      for (let i = 4; i >= 0; i -= 1) {
        const temperature = (maxTemperature / 4) * i;
        if (i === 0) {
          result.push(`${temperature}°C`);
        } else {
          result.push(`+${temperature}°C`, '•');
        }
      }
      // this.$log.info(result);
      return result;
    },
    calculateTop(temperature) {
      const ratio = (temperature / 4);
      return this.offsetTop + (ratio * this.distanceBetweenFirstAndLast) - 4;
    },
  },
};
</script>
<style scoped>
.temperature-line {
  width: 45px;
  height: 1px;
}
.line-1 {
  background:linear-gradient(to right,rgba(253, 202, 153, 1), rgba(253, 202, 153, 0));
}
.line-2 {
  background:linear-gradient(to right,rgba(128, 255, 252, 1), rgba(128, 255, 252, 0));
}
.icon-container {
  width: 22px;
}
.icon-canton {
  width: 15px;
  height: 22px;
}

.indicator-left {
  display: block;
}
.indicator-right-small {
  display: none;
}
.indicator-right {
  display: block;
  float: right;
}

.canton-indicator-right-small {
  display: none;
}
.canton-indicator-right {
  display: flex;
}

@media screen and (max-width: 350px) {
  .indicator-left {
    display: none;
  }
  .indicator-right-small {
    display: block;
  }
  .indicator-right {
    display: none;
  }
  .canton-indicator-right-small {
    display: flex;
  }
  .canton-indicator-right {
    display: none;
  }
}
</style>
