<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    >
    <cc-text
      variant="body-sm"
      color="muted"
      class="pb-6 px-4 pt-4"
      >
      {{ $t('climateChangeBehaviour') }}
    </cc-text>

    <cc-vertical-thermometer />

    <cc-divider class="mx-4 my-6" />

    <cc-co2-overview
      :has-card-background="false"
      :has-shadow="false"
      variant="plain"
      :pressable="false"
      class="px-4"
      />

    <cc-divider class="mx-4 my-6" />

    <cc-climate-impact-cards class="pb-6" />

    <cc-co2-offset class="px-4" />
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';

import ccVerticalThermometer from '@/components/VerticalThermometer.vue';
import ccClimateImpactCards from '@/components/footprint/ClimateImpactCards.vue';
import ccCo2Overview from '@/components/footprint/Co2Overview.vue';
import ccCo2Offset from '@/components/footprint/Co2Offset.vue';

export default {
  components: {
    ccView,
    ccText,
    ccDivider,
    ccVerticalThermometer,
    ccClimateImpactCards,
    ccCo2Overview,
    ccCo2Offset,
  },
  data() {
    return {
      id: 'climate-change',
    };
  },
  watch: {
    filter() {
      this.$store.dispatch('trips/getTimeline');
    },
  },
  async created() {
    // this.$log.info('Created ClimateChange');
    await this.$store.dispatch('trips/getTimeline');
  },
};
</script>
