<template>
  <div class="h-100 d-flex flex-column justify-content-center align-items-center">
    <div
      v-if="variant === 'challenges'"
      class="mb-5"
      >
      <cc-section-header
        :title="$t('challengesLabel')"
        svg="illustrations/achievements-mountain"
        />
      <cc-text
        color="muted"
        align="center"
        >
        {{ $t('joinChallengesEmptyState') }}
      </cc-text>
    </div>

    <div
      v-if="variant === 'achievements'"
      class="mb-5 w-80"
      >
      <cc-section-header
        :title="$t('challengesAchievementsCardTitle')"
        svg="illustrations/achievements-small-medal"
        />

      <cc-text
        color="muted"
        align="center"
        >
        {{ $t('challengesAchievementsCardDescription') }}
      </cc-text>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';

export default {
  components: {
    ccSectionHeader,
  },
  props: {
    variant: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      hasActiveChallenges: 'achievements/hasActiveChallenges',
    }),
  },
};
</script>
