<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="$t('friendsLeaderboardTitle')"
    :button-variant="getButtonProps.variant"
    :button-label="getButtonProps.label"
    :has-button="!appState.friendsLeaderboardLoading"
    :padded="false"
    :pressable="true"
    class="pb-4"
    @click.native="clickHandler"
    >
    <!-- TODO: contactsUploadListener('403'); should have retry button -->
    <template #body>
      <!-- Debugging -->
      <code
        v-if="false"
        class="font-size-12"
        >
        lastContactUploadTimestamp: {{ user.metaPublic.lastContactUploadTimestamp }}
        <br>
        permissionGiven: {{ appState.contactsPermission === 'undefined'
          || (!user.metaPublic.lastContactUploadTimestamp
            && appState.contactsUploadConsent) }}
        <br>
        queryDate: {{ appState.queryDate }}
        <br>
        {{
          `filteredStateContent.text:
          ${filteredStateContent[0] && filteredStateContent[0].text}`
        }}
        <br>
        {{ `getButtonProps.label: ${getButtonProps.label}` }}
        <br>
        {{ `getButtonProps.variant: ${getButtonProps.variant}` }}
        <br>
        {{ `userIsVerified: ${userIsVerified}` }}
        <br>
        contactsPermission: {{ appState.contactsPermission }}
        <br>
        {{ `permissionGiven: ${permissionGiven}` }}
        <br>
        {{ `hasPermission: ${hasPermission}` }}
        <br>
        {{ `allowRetry: ${allowRetry}` }}
        <br>
        {{ `onlySelf: ${onlySelf}` }}
        <br>
        {{ `waitingOnLeaderboard: ${waitingOnLeaderboard}` }}
        <br>
        {{ `loading: ${appState.friendsLeaderboardLoading}` }}
        <div>––––––––––––––––––––––––––––––</div>
      </code>

      <div v-if="true">
        <div
          v-for="(content, i) in filteredStateContent"
          :key="i"
          >
          <cc-wrapper
            v-if="content && content.condition"
            direction="row"
            align="center"
            >
            <div
              v-if="content.component === 'svg'"
              class="pr-5"
              >
              <cc-svg-base :svg="content.value" />
            </div>

            <div
              v-if="content.component === 'calendar-counter'"
              class="pr-3"
              >
              <component :is="content.value" />
            </div>

            <cc-text
              v-if="content.text"
              color="muted"
              >
              {{ content.text }}
            </cc-text>

            <div
              v-if="content.component === 'leaderboard-list-item'"
              class="w-100"
              >
              <component
                :is="content.value"
                v-for="(friendObject, index) in selfLeaderboardSlice"
                :key="index"
                type="friends"
                :rank="friendObject.curPosition"
                :rank-class="getRankClass(friendObject.curPosition)"
                :prev-rank="friendObject.prevPosition"
                :icon="getCupIcon(friendObject.curPosition)"
                :self-rank="friendObject.self ? friendObject.curPosition : null"
                :name="friendObject.self ? $t('txtSelf') : friendObject.name"
                :score="getEmissionText(friendObject.score, true)"
                :widget="true"
                />
            </div>

            <div
              v-else-if="content.component === 'lottie'"
              class="w-100"
              >
              <component
                :is="content.value"
                id="activityIndicator"
                class="w-25"
                :options="lottieOptions"
                wrapper-class="p-5"
                />
            </div>
          </cc-wrapper>
        </div>
      </div>
    </template>
  </cc-card>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { toOrdinalNumber } from '@/mixins/utils';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccCalendarCounter from '@/components/social/CalendarCounter.vue';
import ccLottie from '@/components/Lottie.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccText,
    ccSvgBase,
    ccLottie,
    ccLeaderboardListItem,
    ccCalendarCounter,
  },
  data() {
    return {
      id: 'friends-leaderboard-card',
      lottieOptions: {
        animationData: LoadingLeaderboard,
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      leaderboardState: state => state.social.leaderboardState,
      user: state => state.user.user,
    }),
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
      getCantonPreference: 'user/getCantonPreference',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    filteredStateContent() {
      const content = this.stateContent.find(state => state.condition);
      return [content];
    },
    stateContent() {
      const {
        allowRetry,
        hasPermission,
        permissionGiven,
        userIsVerified,
        onlySelf,
        allDidNotTravel,
        showLeaderboard,
        waitingOnLeaderboard,
        allDidNotTravelText,
      } = this;

      return [
        {
          condition: !permissionGiven || !userIsVerified,
          text: this.$t('friendsLeaderboardWidgetDescription'),
          component: 'svg',
          value: 'friends-leaderboard-widget',
        },
        {
          condition: !hasPermission,
          text: this.$t('txtPhonebookSettings'),
          component: 'svg',
          value: 'phonebook',
        },
        {
          condition: allowRetry,
          text: this.$t('generalErrorMessage'),
          component: 'svg',
          value: 'error-icon',
        },
        {
          condition: waitingOnLeaderboard,
          text: this.$t('txtWaitingOnLeaderboard'),
          component: 'calendar-counter',
          value: 'cc-calendar-counter',
        },
        {
          condition: onlySelf,
          text: this.$t('txtInviteFriendsDescription'),
          component: 'svg',
          value: 'friends-leaderboard-invite-widget',
        },
        {
          condition: allDidNotTravel,
          text: allDidNotTravelText,
          component: 'svg',
          value: 'illustrations/hero-groups',
        },
        {
          condition: showLeaderboard,
          text: null,
          component: 'leaderboard-list-item',
          value: 'cc-leaderboard-list-item',
        },
        {
          condition: this.appState.friendsLeaderboardLoading,
          text: null,
          component: 'lottie',
          value: 'cc-lottie',
        },
      ];
    },
    // TODO: Double check button props
    getButtonProps() {
      const {
        // appState,
        userIsVerified,
        permissionGiven,
        hasPermission,
        allowRetry,
        onlySelf,
        allDidNotTravel,
      } = this;

      if (!userIsVerified) {
        return {
          label: this.$t('txtVerify'),
          variant: 'small',
        };
      }

      if (!permissionGiven) {
        return {
          label: this.$t('startButton'),
          variant: 'small',
        };
      }

      if (!hasPermission) {
        return {
          label: this.$t('settingsTitle'),
          variant: 'small-muted',
        };
      }

      if (allowRetry) {
        return {
          label: this.$t('txtRetry'),
          variant: 'small-muted',
        };
      }

      if (onlySelf) {
        return {
          label: this.$t('txtInvite'),
          variant: 'small',
        };
      }

      if (allDidNotTravel) {
        return {
          label: this.$t('txtView'),
          variant: 'small-muted',
        };
      }

      return {
        label: this.$t('txtView'),
        variant: 'small-muted',
      };
    },
    allDidNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelYetLeaderboards');
      }

      return this.$t('didNotTravelLeaderboards');
    },
    // TODO: Move to Store, use more clear naming?
    queryDate() {
      return this.appState.queryDate;
    },
    // TODO: Move to Store, use more clear naming?
    hasPermission() {
      if (this.appState.contactsPermission === 'false') {
        return false;
      }
      return true;
    },
    // TODO: Move to Store, use more clear naming?
    permissionGiven() {
      if (
        this.appState.contactsPermission === 'undefined'
        || (!this.user.metaPublic.lastContactUploadTimestamp
        && !this.appState.contactsUploadConsent)
      ) {
        return false;
      }
      return true;
    },
    // TODO: Move to Store
    waitingOnLeaderboard() {
      if (
        !this.appState.friendsLeaderboardLoading
        && this.leaderboardState?.friends?.[this.appState.leaderboardType]?.length === 0
        && this.leaderboardState?.friends?.currentData?.didNotTravel?.length === 0
      ) {
        return true;
      }
      return false;
    },
    // TODO: Move to Store
    onlySelf() {
      const friendsCurrentData = this.leaderboardState?.friends?.currentData?.data;

      const hasSinglePerson = friendsCurrentData?.length === 1;

      const isSelf = friendsCurrentData?.[0]?.id === this.user?.id;

      if (!this.appState.friendsLeaderboardLoading && hasSinglePerson && isSelf) {
        return true;
      }
      return false;
    },
    // TODO: Move to Store
    allowRetry() {
      if (this.appState.contactsUploaded === 'allowRetry') {
        return true;
      }
      return false;
    },
    allDidNotTravel() {
      if (
        !this.appState.friendsLeaderboardLoading
        && this.leaderboardState?.friends?.[this.appState.leaderboardType]?.length === 0
        && this.leaderboardState?.friends?.currentData?.didNotTravel?.length > 0
      ) {
        return true;
      }
      return false;
    },
    selfLeaderboardSlice() {
      if (this.leaderboardState?.friends?.[this.appState.leaderboardType]?.length) {
        const leaderboard = [...this.leaderboardState.friends[this.appState.leaderboardType]];
        const totalLength = leaderboard.length;
        const selfRank = leaderboard.findIndex(el => el.self);

        if (totalLength <= 3) {
          return leaderboard;
        }
        if (selfRank === totalLength - 1) {
          return leaderboard.slice(totalLength - 3, totalLength);
        }
        if (selfRank === 0) {
          return leaderboard.slice(0, 3);
        }

        if (selfRank === -1) {
          return leaderboard.slice(0, 3);
        }

        return leaderboard.slice(selfRank - 1, selfRank + 2);
      }
      return null;
    },
    // TODO: Move to Store
    showLeaderboard() {
      const { appState, leaderboardState } = this;
      if (
        !appState.friendsLeaderboardLoading
        && leaderboardState?.friends?.[appState.leaderboardType]?.length
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    queryDate() {
      this.$store.dispatch('social/getFriendsLeaderboard');
    },
  },
  async mounted() {
    const refreshTime = 900000;
    const shouldDispatch = !this.leaderboardState.friends
      || Date.now() - refreshTime > this.leaderboardState.friends.timestamp;

    if (shouldDispatch) {
      await this.$store.dispatch('social/getFriendsLeaderboard');
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    clickHandler() {
      const canSeeFriendsLeaderboard = !this.appState.friendsLeaderboardLoading
        && this.leaderboardState?.friends?.[this.appState.leaderboardType]?.length;

      if (!this.userIsVerified) {
        this.$log.info('Will push to join-the-challenge');

        return this.$router.push({ name: 'join-the-challenge' }).catch(() => {});
      }

      if (!this.permissionGiven) {
        this.$log.info('showContactsPermissionModal / AllowContacts');
        return this.$emit('request-contacts-permission', {
          permissionModalType: 'AllowContacts',
        });
      }

      if (!this.hasPermission) {
        this.$log.info('showContactsPermissionModal / ContactsSettings');
        return this.$emit('request-contacts-permission', {
          permissionModalType: 'ContactsSettings',
        });
      }

      if (this.onlySelf) {
        // Move to Store wrap it as shareApp or something?
        return this.callSdk(
          `action=${this.actions.SHARE}&url=${encodeURIComponent(
            'https://www.swissclimatechallenge.ch',
          )}&text=${encodeURIComponent(this.$t('shareMessage'))}`,
        );
      }

      if (this.allowRetry) {
        this.$log.info('allowRetry: user/uploadContacts');
        return this.$store.dispatch('user/uploadContacts');
      }

      if (this.allDidNotTravel) {
        this.$log.info('Will push to friends-leaderboard');
        return this.$router.push({ name: 'friends-leaderboard' }).catch(() => {});
      }

      if (canSeeFriendsLeaderboard) {
        this.$log.info('Will push to friends-leaderboard');
        return this.$router.push({ name: 'friends-leaderboard' }).catch(() => {});
      }

      return false;
    },
    toOrdinalNumberWrapper(value) {
      return toOrdinalNumber(value, this.appState.userLang);
    },
  },
};
</script>
