<template>
  <div class="position-fixed z-index-1050 bg-black safe-area-inset-top">
    <code class="font-size-12">
      {{ fps }}
    </code>
  </div>
</template>

<script>
import { FPSMeter } from '@/mixins/utils';

export default {
  data() {
    return {
      fps: null,
    };
  },
  mounted() {
    FPSMeter((e) => {
      this.fps = e;
    });
  },
};
</script>
