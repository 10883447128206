<template>
  <div class="overflow-x-scroll w-100 flex-horizontal-scroll">
    <div class="px-2 d-inline-flex flex-row">
      <div
        v-for="card in cardsData"
        :key="card.title"
        class="mx-2 rounded-16 bg-blue-dark p-5"
        >
        <div class="climate-card h-100">
          <div class="pb-2">
            <cc-svg-base
              :svg="card.icon"
              />
          </div>

          <cc-heading
            variant="h5"
            color="muted"
            weight="xbold"
            >
            {{ card.title }}
          </cc-heading>

          <cc-text
            variant="body-sm-bold"
            color="muted"
            >
            {{ card.description }}
          </cc-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';

export default {
  components: {
    ccSvgBase,
    ccText,
    ccHeading,
  },
  computed: {
    ...mapState({
      temperature: state => state.trips.temperature,
    }),
    seaLevelRise() {
      const temp = this.temperature; // shortened to comply with eslint
      const coefficient = 0.198347107438017; // coeficient
      const quadB = 2; // quadB
      const baselineRise = 0.4; // baselineRise
      const quadD = 0.16; // quadD
      const baseShift = 0.24; // baseShift

      return coefficient * ((temp * temp) - (quadB * temp * baselineRise) + quadD) + baseShift;
    },
    snowLineImpact() {
      // const baseline = 850; // baseline Snow
      const snowIncreaseStep = 150; // snowIncreaseStep
      // const z = 1; Can be removed if formula is simplified to X = 700 + 150X
      return (snowIncreaseStep * this.temperature);
    },
    cardsData() {
      return [
        {
          title: `+${this.seaLevelRise.toFixed(2)}m ${this.$t('seaLevelTitle')}`,
          icon: 'icons/icon-sea-line',
          description: `${this.$t('seaLevelDescription')}`,
        },
        {
          title: `+${this.snowLineImpact.toFixed(0)}m ${this.$t('snowLineTitle')}`,
          icon: 'icons/icon-snow-line',
          description: `${this.$t('snowLineDescription')}`,
        },
        {
          title: `+${this.temperature.toFixed(2)}°C ${this.$t('tempChangeTitle')}`,
          icon: 'icons/icon-thermometer',
          description: `${this.$t('tempChangeDescription')}`,
        },
      ];
    },
  },
};
</script>

<style>
.climate-card {
  width: 260px;
}
</style>
