<template>
  <div class="d-flex justify-content-center align-items-center">
    <cc-lottie
      class="w-25"
      :options="lottieOptions"
      wrapper-class="p-5"
      />
  </div>
</template>

<script>
import ccLottie from '@/components/Lottie.vue';

// import activityIndicator from '@/components/lottie/activity_indicator.json';
import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccLottie,
  },
  data() {
    return {
      path: null,
      lottieOptions: {
        animationData: LoadingLeaderboard,
      },
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
