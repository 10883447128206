<template>
  <cc-modal
    data-ruid="modal__vehicle-preference"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <ccLottie
        id="globeAnim"
        class="w-100"
        height="initial"
        :options="currentAnim"
        />
    </template>

    <template #body>
      <div class="px-5 w-100">
        <cc-heading
          variant="h5"
          class="pb-2"
          weight="xbold"
          >
          {{ $t(`vehiclePreferenceReminderTitle`) }}
        </cc-heading>
        <cc-text variant="body-sm">
          {{ $t(`vehiclePreferenceReminderDescription`) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex flex-row flex-wrap-reverse justify-content-around w-100 py-5 px-5">
        <cc-button
          data-ruid="global__button-later"
          :is-modal-button="true"
          variant="transparent"
          :block="false"
          :text="$t('txtAskLater')"
          class="flex-grow-1"
          @click="close"
          />
        <cc-button
          data-ruid="global__button-confirm"
          :is-modal-button="true"
          variant="secondary"
          :block="false"
          :text="$t(`btnVehiclePreferenceReminder`)"
          class="flex-grow-1"
          @click="specify(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ccModal from '@/components/modals/Modal.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';

import modalAnimVehicle from '@/components/lottie/modalAnimVehicle.json';

export default {
  name: 'VehiclePreferenceModal',
  components: {
    ccButton,
    ccHeading,
    ccText,
    ccModal,
    ccLottie,
  },
  data() {
    return {
      animData: {
        currentAnim: {
          animationData: modalAnimVehicle,
        },
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      appState: state => state.common.appState,
      chartData: state => state.trips.chartData,
    }),
    currentAnim() {
      return { animationData: modalAnimVehicle };
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    specify(closeModal) {
      this.$store.dispatch('user/vehiclePreferenceReminderShown');
      closeModal(() => {
        this.$router.push({ name: 'vehicle-settings' });
      });
    },
  },
};
</script>
