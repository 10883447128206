<template>
  <div class="d-flex flex-row">
    <cc-svg-base
      svg="illustrations/happy-earth"
      class="mr-1"
      style="height: 16; width: 16;;"
      />
    <cc-text
      variant="body-xs"
      color="muted"
      >
      Swiss Climate Challenge
    </cc-text>
  </div>
</template>


<script>
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  name: 'HappyEarthPlaceholder',
  components: {
    ccText,
    ccSvgBase,
  },
};
</script>
