var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-view',{attrs:{"id":_vm.id}},[_c('cc-card',{staticClass:"mb-6",attrs:{"variant":"list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__terms-and-conditions`,"label":_vm.$t('txtTermsAndConditions'),"arrow":""},nativeOn:{"click":function($event){return _vm.$store.dispatch('common/openUrlInternal', _vm.termsAndConditionsURL)}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__privacy-policy`,"label":_vm.$t('privacyPolicy'),"arrow":""},nativeOn:{"click":function($event){return _vm.$store.dispatch(
            'common/openUrlInternal',
            `${_vm.appState.webSiteURL}/${_vm.appState.userLang}/privacy-policy?internalBrowser=true`
          )}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__faq`,"label":_vm.$t('txtFAQ'),"arrow":""},nativeOn:{"click":function($event){return _vm.$store.dispatch(
            'common/openUrlInternal',
            `${_vm.appState.webSiteURL}/${_vm.appState.userLang}/faq?internalBrowser=true`
          )}}}),_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__credits`,"label":_vm.$t('txtCredits'),"arrow":"","last":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'credits' })}}})]},proxy:true}])}),_c('cc-card',{staticClass:"mb-6",attrs:{"variant":"list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('cc-settings-list-item',{attrs:{"ruid":`${_vm.id}__delete-account`,"label":_vm.$t('deleteAccountSectionTitle'),"arrow":"","last":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'delete-account' })}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }