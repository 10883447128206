<template>
  <cc-view
    :id="id"
    >
    <cc-wrapper
      class="h-100 text-center safe-area-inset-bottom flex-grow-1"
      align="between"
      >
      <cc-wrapper class="flex-grow-1">
        <cc-heading
          variant="h4"
          class="pb-3"
          >
          {{ $t('verifyCompanyEmailTitle') }}
        </cc-heading>

        <cc-text
          color="muted"
          class="mb-5 mx-5"
          >
          {{ $t('verifyCompanyEmailDescription') }}
        </cc-text>

        <validation-observer
          ref="form"
          class="w-100"
          >
          <validation-provider
            v-slot="{ errors }"
            rules="email|required"
            :name="$t('email')"
            >
            <cc-input
              v-model.trim="email"
              v-ruid="`${id}__input__email-address`"
              type="email"
              class="pt-2 px-6"
              :label="$t('email')"
              align="center"
              :placeholder="$t('email')"
              :has-visible-label="0"
              @blur="setAppState(['errorMessage', null])"
              />
            <cc-error-message
              :message="errors[0]"
              alignment="center"
              />
          </validation-provider>
        </validation-observer>

        <cc-error-message
          :message="validationMessage"
          alignment="center"
          />

        <cc-button
          class="mt-6"
          :data-ruid="`${id}__primary-action`"
          :text="$t('verifyCompanyEmail')"
          :disabled="!email"
          :variant="email ? 'primary' : 'secondary'"
          :has-loading-feedback="true"
          @click="primaryActionHandler"
          />
      </cc-wrapper>

      <div
        v-if="false"
        class="pb-6"
        >
        <cc-text
          variant="body-xs"
          class="text-uppercase pb-4"
          >
          {{ $t('companiesParticipating') }}
        </cc-text>
        <cc-svg-base
          svg="logos/company-postfinance"
          class="mr-2"
          style="height:28px;"
          />
        <cc-svg-base
          :svg="`partner-logo/swisscom-horizontal-${appState.userLang}`"
          class="ml-2"
          />
      </div>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import validator from '@/mixins/validator';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccText,
    ccInput,
    ccButton,
    ccSvgBase,
    ccErrorMessage,
  },
  mixins: [validator],
  data() {
    return {
      id: 'add-company-email',
      email: null,
      validationMessage: '',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      isCompanyUser: 'user/isCompanyUser',
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUserState: 'user/setState',
    }),
    async primaryActionHandler() {
      try {
        this.$refs.form.reset();

        const success = await this.$refs.form.validate();

        if (!success) {
          return;
        }

        this.setUserState(['isNewUser', true]);

        await this.$store.dispatch('user/createGuestAccount');

        await this.$store.dispatch('user/requestVerifyEmail', this.email);

        if (this.isCompanyUser) {
          this.$router
            .push({ name: 'footprint' })
            .catch(() => {});
        } else {
          this.$router
            .push({ name: 'account-created', params: { isCompanySignUp: true } })
            .catch(() => {});
        }
      } catch (error) {
        this.$log.info('primaryActionHandler', error);
        this.validationMessage = this.$t('generalErrorMessage');
      }
    },
  },
};
</script>

<style>
</style>
