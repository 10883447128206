<template>
  <cc-modal
    data-ruid="modal__group-exit-confirmation"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('groupLeaderboardLeaveGroupConfirmation')"
          svg="illustrations/group-created"
          />
      </div>
    </template>


    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :data-ruid="`${id}__primary-action`"
          :is-modal-button="true"
          :text="$t('txtDone')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'group-exit-confirmation-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
  },
};
</script>
