<template>
  <div
    ref="lottieContainer"
    :style="style"
    />
</template>

<script>
import lottie from 'lottie-web';

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '100%',
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    destroy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      style: {
        width: this.width ? this.width : '100%',
        height: this.height ? this.height : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      },
    };
  },
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lottieContainer,
      renderer: this.options.renderer || 'svg',
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
      rendererSettings: this.options.rendererSettings,
    });
    this.$emit('animCreated', this.anim);
  },
  beforeDestroy() {
    setTimeout(() => {
      this.anim.destroy();
      this.anim = null;
    }, 500);
  },
};
</script>
