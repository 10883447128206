<template>
  <div
    :id="getId"
    v-ruid="getId"
    class="w-100 h-100 overflow-x-hidden z-index-2"
    :class="getClass"
    :style="hasUiControls ? calcSubViewHeight(id) : null"
    >
    <!-- TODO: z-index-2 class above may not be necessary anymore/check it out -->
    <cc-charts-loading
      v-if="hasLoadingView && isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <transition
      v-else-if="hasRouterView"
      :name="subViewTransition"
      >
      <router-view
        :has-loading-view="hasLoadingView"
        :is-loading="isLoading"
        />
    </transition>


    <slot v-else>
      <cc-heading
        variant="h4"
        color="muted"
        class="pb-6"
        >
        Missing template
      </cc-heading>

      <div
        v-for="i of 50"
        :key="i"
        >
        <p>
          {{ i }} - Tab: {{ id }} / ScrollPos: {{ uiState.routePositions[id] }}
        </p>
      </div>
    </slot>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { mapState, mapGetters } from 'vuex';

import ccHeading from '@/components/primitives/Heading.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';

export default {
  components: {
    ccHeading,
    ccChartsLoading,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hasFullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasOverflowScroll: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSubView: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasUiControls: {
      type: Boolean,
      required: false,
      default: false,
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasLoadingView: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasRouterView: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      subViewTransition: '',
      index: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
    }),
    ...mapGetters({
      calcSubViewHeight: 'common/calcSubViewHeight',
    }),
    getId() {
      const { id } = this;
      return this.isSubView ? `app__view__${id}__sub-view` : `app__view__${id}`;
    },
    getClass() {
      return [
        this.hasBackground ? 'bg-blue-darkest' : null,
        this.hasFullWidth ? null : 'px-4 pt-4',
        this.isModal ? 'd-flex flex-column flex-grow-1' : 'position-absolute',
        this.hasOverflowScroll ? 'overflow-y-scroll' : null,
      ];
    },
  },
  watch: {
    $route(to, from) {
      // this.$log.info('View $route watcher to', to);
      // this.$log.info('View $route watcher from', from);
      this.subViewTransition = to.meta.depth > from.meta.depth ? 'next' : 'prev';
    },
  },
  mounted() {
    // this.$log.info('Mounted:', this.id);

    /**
     * If View instance has hasUiControls, trigger setUiControlsHeight action below
     * to dynamically calculate required height for subview to fit exactly on the remaining space.
     * `calcSubViewHeight` returns the computed height to the View `style`.
     */
    if (this.hasUiControls) {
      this.$store.dispatch('common/setUiControlsHeight', {
        id: this.id,
        name: 'uiControlsHeight',
      });
    }
  },
  created() {
    // this.$log.info('BodyScroll: Created: View:', this.getId);
    this.$nextTick(() => {
      disableBodyScroll(document.querySelector(`#${this.getId}`));
    });
  },
  destroyed() {
    // this.$log.info('BodyScroll: Destroyed: View:', this.getId);
    // enableBodyScroll(document.querySelector(`#app__view__${this.id}`));
    enableBodyScroll(document.querySelector(`#${this.getId}`));
  },
};
</script>

<style>
/* ::-webkit-scrollbar {
  display: none;
} */

.z-index-1 > :nth-child(1) {
  z-index: 1;
}
.z-index-2 > :nth-child(1) {
  z-index: 3;
}
.z-index-3 > :nth-child(1) {
  z-index: 3;
}
.z-index-4 > :nth-child(1) {
  z-index: 4;
}
.z-index-5 > :nth-child(1) {
  z-index: 5;
}
</style>
