<template>
  <component
    :is="importedSvg"
    :class="colorClass"
    />
</template>

<script>

export default {
  name: 'SvgBase',
  props: {
    svg: {
      type: String,
      required: true,
    },
    colorClass: {
      type: [String, Array],
      default: null,
      required: false,
    },
  },
  computed: {
    importedSvg() {
      const { svg } = this;

      return () => import(/* webpackMode: "lazy-once", webpackChunkName: "svg" */`@/assets/${svg}.svg`);
    },
  },
};
</script>
