<template>
  <cc-view
    :id="id"
    class="bg-blue-darkest"
    >
    <cc-wrapper class="mb-6">
      <cc-svg-base svg="profile-avatar" />

      <cc-svg-base
        v-if="getUserCompanyTagId"
        class="mt-4"
        style="height: 22px;"
        :svg="`logos/company-${getUserCompanyTagId}`"
        />

      <cc-heading
        tag="h3"
        variant="h3"
        align="center"
        class="pt-4"
        >
        {{ getUserDisplayName || $t('txtAnonymousAccount') }}
      </cc-heading>
    </cc-wrapper>

    <cc-card
      class="mb-6"
      outer-title-key="txtAccount"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__phone-number`"
          :label="$t('txtPhoneNumber')"
          :label-data="phoneNumber || $t('txtAdd')"
          :arrow="!phoneNumber"
          @click.native="!phoneNumber
            ? $router.push({ name: 'join-the-challenge' }).catch(() => {})
            : null"
          />

        <cc-settings-list-item
          :icon="getEmailStatusIcon"
          :ruid="`${id}__email`"
          :label="$t('email')"
          :label-data="getUserEmail || getUserPendingEmail || $t('txtAdd')"
          arrow

          @click.native="isEmailModalDisplayed = true"
          />
        <cc-settings-list-item
          v-if="!isCompanyUser"
          :ruid="`${id}__onboard-code`"
          :label="$t('onboardCodeTitle')"
          arrow
          :last="!hasFeatureTagShop"
          @click.native="$router.push({ name: 'add-onboard-code',
                                        params:{ isInOnboarding:false }
                                        ,hasBackIcon:true}
          ).catch(() => {})"
          />
        <cc-settings-list-item
          v-if="isCompanyUser"
          :ruid="`${id}__onboard-code`"
          :label="$t('onboardCodeTitle')"
          arrow
          :last="!hasFeatureTagShop"
          @click.native="$router.push({ name: 'onboard-code',
                                        params:{ isInOnboarding:false }}).catch(() => {})"
          />
        <cc-settings-list-item
          v-if="hasFeatureTagShop"
          :ruid="`${id}__delivery-addresses`"
          :label="$t('deliveryAddressesTitle')"
          arrow
          :last="hasFeatureTagShop"
          @click.native="$router.push({ name: 'delivery-addresses' }).catch(() => {})"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtProfile"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__display-name`"
          :label="$t('txtDisplayName')"
          :label-data="getUserDisplayName || $t('txtAdd')"
          :last="!userIsVerified"
          arrow
          @click.native="isAddDisplayNameModalDisplayed = true"
          />
        <cc-settings-list-item
          v-if="userIsVerified"
          :ruid="`${id}__my-canton`"
          :label="$t('txtMyCanton')"
          :label-data="capitalizeFirstLetter(getFullUserCity) || $t('txtChoose')"
          arrow
          last
          @click.native="$router.push({ name: 'canton-selection' })"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtTracking"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__tracking`"
          :label="$t('txtTracking')"
          :label-data="$t(isTrackingActive)"
          />
        <cc-settings-list-item
          :ruid="`${id}__last-transmission`"
          :label="$t('txtLastDataUpload')"
          :label-data="lastTransmission || '-'"
          />

        <cc-settings-list-item
          :ruid="`${id}__vehicle-settings`"
          :label="$t('manageVehicle')"
          arrow
          @click.native="$router.push({ name: 'vehicle-settings' })"
          />

        <cc-settings-list-item
          :ruid="`${id}__wifi-upload`"
          :label="$t('txtWiFiUpload')"
          last
          >
          <toggle-button
            class="pl-1"
            :value="isWiFiUploadOnly"
            :width="48"
            :height="24"
            :margin="2"
            :sync="true"
            :color="{ unchecked: '#4B97FF', checked: '#25C98B' }"
            @change="$store.dispatch('common/wifiToggle')"
            />
        </cc-settings-list-item>
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="txtGeneral"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__language–settings`"
          :label="$t('manageLanguage')"
          arrow
          @click.native="$router.push({ name: 'language-settings' })"
          />

        <cc-settings-list-item
          :ruid="`${id}__message-delivery-preferences`"
          :label="$t('notificationsTitle')"
          arrow
          @click.native="$router.push({ name: 'message-delivery-preferences' })"
          />

        <cc-settings-list-item
          :ruid="`${id}__about`"
          :label="$t('aboutSectionTitle')"
          arrow
          @click.native="$router.push({ name: 'about' })"
          />

        <cc-settings-list-item
          :ruid="`${id}__support`"
          :label="$t('supportSectionTitle')"
          arrow
          :last="appState.canSignOut === 'false' && (appState.isProd || appState.isStaging)"
          @click.native="$router.push({ name: 'support' })"
          />

        <cc-settings-list-item
          v-if="appState.isStaging || appState.isDev || appState.isDevelopment"
          :ruid="`${id}__developer-menu`"
          :label="$t('txtDeveloperMenu')"
          arrow
          @click.native="$router.push({ name: 'developer-menu' })"
          />

        <cc-settings-list-item
          v-if="appState.isStaging || appState.isDev || appState.isDevelopment"
          :ruid="`${id}__developer-menu`"
          label="Push Notification debugger"
          arrow
          @click.native="$router.push({ name: 'push-notification-debugger' })"
          />

        <cc-settings-list-item
          v-if="appState.canSignOut !== 'false'"
          :ruid="`${id}__logout`"
          :label="$t('txtLogout')"
          arrow
          last
          @click.native="isLogoutModalModalDisplayed = true"
          />
      </template>
    </cc-card>

    <div class="pb-4">
      <cc-svg-base
        color-class="svg-fill-white"
        class="mx-7"
        :svg="`partner-logo/main-partners-${appState.userLang}`"
        />
      <cc-svg-base
        svg="partner-logo/settings-powered-by"
        class="align-self-center my-7 mx-10"
        />
    </div>

    <cc-add-email-address-modal
      v-if="isEmailModalDisplayed"
      @after-leave="handleEmailModalLeave($event)"
      @close-modal="(isEmailModalDisplayed = false), delete $route.params.showAddEmailAddressModal"
      />

    <cc-verify-email-modal
      v-if="isVerifyEmailModalDisplayed"
      ref="verify-email-modal-instance"
      @close-modal="isVerifyEmailModalDisplayed = false"
      />

    <cc-reverify-email-modal
      v-if="isReverifyEmailModalDisplayed"
      @close-modal="isReverifyEmailModalDisplayed = false"
      />

    <cc-logout-modal
      v-if="isLogoutModalModalDisplayed"
      @close-modal="isLogoutModalModalDisplayed = false"
      />

    <cc-add-display-name-modal
      v-if="isAddDisplayNameModalDisplayed"
      @close-modal="isAddDisplayNameModalDisplayed = false"
      />

    <cc-lead-to-groups-modal
      v-if="isLeadToGroupsModalDisplayed"
      @close-modal="isLeadToGroupsModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import { capitalizeFirstLetter } from '@/mixins/utils';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccView from '@/components/constructs/View.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccHeading from '@/components/primitives/Heading.vue';

import ccAddEmailAddressModal from '@/components/modals/AddEmailAddressModal.vue';
import ccVerifyEmailModal from '@/components/modals/VerifyEmailModal.vue';
import ccReverifyEmailModal from '@/components/modals/ReverifyEmailModal.vue';
import ccLogoutModal from '@/components/modals/LogoutModal.vue';
import ccAddDisplayNameModal from '@/components/modals/AddDisplayNameModal.vue';
import ccLeadToGroupsModal from '@/components/modals/LeadToGroupsModal.vue';

export default {
  components: {
    ccWrapper,
    ccView,
    ccSvgBase,
    ccSettingsListItem,
    ccCard,
    ccHeading,
    ccAddEmailAddressModal,
    ccVerifyEmailModal,
    ccReverifyEmailModal,
    ccLogoutModal,
    ccAddDisplayNameModal,
    ccLeadToGroupsModal,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'settings',
      name: null,
      isEmailModalDisplayed: false,
      isVerifyEmailModalDisplayed: false,
      isReverifyEmailModalDisplayed: false,
      isLogoutModalModalDisplayed: false,
      isAddDisplayNameModalDisplayed: false,
      isLeadToGroupsModalDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
      user: state => state.user.user,
    }),
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
      getUserEmail: 'user/getUserEmail',
      getUserPendingEmail: 'user/getUserPendingEmail',
      getFullUserCity: 'user/getFullUserCity',
      getUserDisplayName: 'user/getUserDisplayName',
      lastTransmission: 'user/lastTransmission',
      phoneNumber: 'user/phoneNumber',
      isWiFiUploadOnly: 'common/isWiFiUploadOnly',
      isTrackingActive: 'common/isTrackingActive',
      isCompanyUser: 'user/isCompanyUser',
      getUserCompanyTagId: 'user/getUserCompanyTagId',
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
      hasFeatureTagShop: 'user/hasFeatureTagShop',
    }),
    getEmailStatusIcon() {
      const { user } = this;

      if (user?.email?.address) {
        return 'icons/icon-circle-check-18';
      }

      if (user?.email?.pendingAddress) {
        return 'icons/icon-time';
      }
      return '';
    },
  },
  mounted() {
    if (this.$route.params.showAddEmailAddressModal) {
      this.isEmailModalDisplayed = true;
    }

    // Listen for the modal event to be fired upon viewWillBeShown
    this.$root.$once('show-lead-to-groups-modal', () => {
      if (this.isVerifyEmailModalDisplayed) {
        // @todo We should close all possibly modals not just this one
        this.$refs['verify-email-modal-instance'].$refs['verify-email-modal'].close(() => {
          this.isLeadToGroupsModalDisplayed = true;
        });
      } else {
        this.isLeadToGroupsModalDisplayed = true;
      }
    });
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    handleEmailModalLeave(message) {
      if (message === 'verify') {
        this.isVerifyEmailModalDisplayed = true;
      }

      if (message === 'reverify') {
        this.isReverifyEmailModalDisplayed = true;
      }
    },
    capitalizeFirstLetter,
  },
};
</script>

<style>
.v-switch-input {
  min-width: 1px !important;
}

.vue-js-switch .v-switch-core .v-switch-button {
  z-index: initial !important;
}
</style>
