<template>
  <cc-modal
    data-ruid="modal__confirm-delivery-address"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('shopConfirmGoodPurchaseModalTitle')"
    @close-modal="$emit('close-modal')"
    >
    <template #body="{ close }">
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column"
        >
        <div class="mb-2 border-bottom">
          <cc-text>
            {{
              $t('shopConfirmGoodPurchaseModalDescription', {
                price: product && product.price,
              })
            }}
          </cc-text>
        </div>

        <div
          v-if="!hasAddress"
          class="border-bottom"
          >
          <cc-section-header
            :title="$t('deliveryAddressesEmptyStateTitle')"
            svg="illustrations/address-book"
            />

          <cc-text
            color="muted"
            align="center"
            >
            {{ $t('deliveryAddressesEmptyStateBody') }}
          </cc-text>
        </div>

        <div
          v-else
          class="mt-2 border-bottom"
          >
          <div class="d-flex align-items-center">
            <cc-heading
              tag="h3"
              variant="h4"
              class="mr-3"
              >
              {{ $t('deliveryAddressTitle') }}
            </cc-heading>
          </div>

          <div class="d-flex justify-content-between pt-2">
            <div>
              <cc-text>
                {{ selectedAddress.firstName }} {{ selectedAddress.lastName }}
              </cc-text>
              <cc-text>
                {{ selectedAddress.streetName }} {{ selectedAddress.houseNumber }}
              </cc-text>
              <cc-text>{{ selectedAddress.postalCode }} {{ selectedAddress.city }}</cc-text>
            </div>
            <div class="pl-4">
              <cc-button
                variant="small-muted"
                :block="false"
                :text="$t('txtChange')"
                @click="close(() => {
                  $emit('after-leave', {
                    name: 'change-delivery-address-modal',
                    selectedAddress
                  })
                })"
                />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex flex-column w-100 px-5 py-5">
        <cc-error-message
          :message="errors[0]"
          alignment="center"
          class="pb-3"
          />
        <cc-button
          v-if="!hasAddress"
          data-ruid="global__button__add-delivery-address"
          class="ml-1"
          :text="$t('addDeliveryAddress')"
          @click="close(() => {
            $emit('after-leave', 'add-delivery-address-modal')
          })"
          />
        <cc-button
          v-if="hasAddress"
          data-ruid="global__button__confirm-offer"
          class="ml-1"
          :text="$t('txtConfirm')"
          :disabled="loading || appState.isSandbox"
          @click="confirmPurchase(close, selectedAddress)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ccHeading from '@/components/primitives/Heading.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccHeading,
    ccSectionHeader,
    ccText,
    ccButton,
    ccModal,
    ccErrorMessage,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'confirm-delivery-purchase-modal',
      loading: false,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      user: state => state.user.user,
    }),
    ...mapGetters({
      hasAddress: 'user/hasAddress',
      getProductById: 'company/getProductById',
    }),
    product() {
      return { ...this.data.product };
    },
    selectedAddress() {
      return this.data.selectedAddress;
    },
  },
  methods: {
    confirmPurchase(closeModal, address) {
      this.loading = true;
      this.errors = [];
      const offer = this.product;
      const buyerEmailAddress = this.user.email.address;

      this.$store
        .dispatch('company/buyOffer', { offer, address, buyerEmailAddress })
        .then((response) => {
          /**
           * After the buyOffer call if current offer is not returned in getProducts
           * Push to shop route to prevent rendering errors as offer payload is not available anymore
           * (For example in case offer was deactivated while a user was browsing it)
           * Mind that an offer that is active: false can still be purchased.
           */
          closeModal(() => {
            if (!this.getProductById(this.$route.params.id)) {
              this.$router.push({ name: 'shop' });
            }
            this.loading = false;

            this.$emit('after-leave', {
              name: 'good-receipt',
              transactionId: response.extract.id,
            });
          });
        })
        .catch(async (err) => {
          this.$log.info('Error: confirmPurchase', err);
          this.errors.push(this.$t('genericErrorTitle'));

          // Same reason as above call in then().
          if (!this.getProductById(this.$route.params.id)) {
            closeModal();
            this.$router.push({ name: 'shop' });
          }

          this.loading = false;
        });
    },
  },
};
</script>
