<template>
  <div
    :id="`${id}__segmented-control`"
    :data-ruid="`${id}__segmented-control`"
    class="d-flex flex-grow-1 justify-content-around bg-blue-light border-radius-2"
    >
    <cc-button
      v-for="(item, i) in items"
      :key="i"
      :data-ruid="`${id}__segmented-control__${item.value}`"
      variant="segmented-control"
      class="w-100 text-truncate px-4"
      :button-class="activeClass(item)"
      :text="$t(item.locale)"
      @click="clickHandler(item)"
      />
  </div>
</template>

<script>
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    activeClass: {
      type: Function,
      required: false,
      default: null,
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit('click', event);
      this.activeClass(event);
    },
  },
};
</script>
