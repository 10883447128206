<template>
  <cc-modal
    data-ruid="modal__logout"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <ccLottie
        id="logoutPenguin"
        class="w-100"
        :options="animData.currentAnim"
        height="initial"
        />
    </template>

    <template #body>
      <div class="px-5 w-100">
        <cc-heading
          variant="h5"
          class="pb-2"
          weight="xbold"
          >
          {{ $t('logoutModalTitle') }}
        </cc-heading>

        <cc-text
          v-if="userIsVerified"
          variant="body-sm"
          >
          {{ $t('logoutModalDescription') }}
        </cc-text>

        <cc-text
          v-else
          variant="body-sm"
          >
          {{ $t('logoutModalUnverifiedDescription') }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex flex-row flex-wrap-reverse justify-content-around w-100 py-5 px-5">
        <cc-button
          data-ruid="global__cancel"
          :is-modal-button="true"
          variant="transparent"
          :block="false"
          :text="$t('txtCancel')"
          class="flex-grow-1"
          @click="close"
          />
        <cc-button
          data-ruid="global__button-confirm"
          :is-modal-button="true"
          variant="secondary"
          :block="false"
          :text="$t('txtLogout')"
          class="flex-grow-1"
          @click="signOut(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccLottie from '@/components/Lottie.vue';

import logoutPenguin from '@/components/lottie/logoutPenguin.json';

export default {
  name: 'LogoutModal',
  components: {
    ccButton,
    ccHeading,
    ccText,
    ccModal,
    ccLottie,
  },
  data() {
    return {
      animData: {
        currentAnim: {
          animationData: logoutPenguin,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
    }),
  },
  methods: {
    signOut(closeModal) {
      this.$store.dispatch('user/signOut', 'logout modal')
        .then(() => {
          closeModal(() => {
            this.$router.push({ name: 'start' });
          });
        }).catch(err => this.sentryCaptureMessage(`Sign out error: ${err.message}`));
    },
  },
};
</script>
