<template>
  <div
    class="card__wrapper"
    :class="getWrapperClass"
    :data-ruid="ruid && `card__wrapper__${ruid}`"
    >
    <!-- Outer heading -->
    <cc-heading
      v-if="outerTitleKey || outerTitle"
      tag="h2"
      variant="h3"
      class="pb-3 pl-4"
      >
      {{ $t(outerTitleKey) || outerTitle }}
    </cc-heading>

    <!-- Card itself -->
    <div
      class="card w-100 overflow-hidden"
      :class="getClass"
      >
      <div
        v-if="innerTitle || innerText || svg"
        class="d-flex flex-row justify-content-between align-items-center"
        >
        <!-- TODO: Some SVG files are too big causing performance issues, -->
        <!-- we need to start using PNG for some of them -->
        <div
          v-if="svg"
          class="pr-3"
          >
          <cc-svg-base :svg="svg" />
        </div>

        <div
          v-if="innerTitle || innerText"
          :class="variant === 'plain' ? 'pt-5 px-5' : null"
          style="min-height:32px;"
          class="d-flex flex-row flex-grow-1 justify-content-between align-items-center"
          >
          <cc-heading
            v-if="innerTitle"
            tag="h3"
            :variant="innerTitleVariant"
            :color="innerTitleColor"
            class="mr-3"
            >
            {{ innerTitle }}
          </cc-heading>

          <cc-text
            v-if="innerText"
            class="mr-3"
            variant="body-bold"
            >
            {{ innerText }}
          </cc-text>

          <cc-button
            v-if="hasButton"
            :variant="buttonVariant"
            :button-class="buttonClass"
            :block="false"
            :text="buttonLabel || $t('txtDetails')"
            @click="$emit('button')"
            />
        </div>
      </div>

      <div
        v-if="$slots.body"
        :class="getSlotClass"
        class="h-100"
        >
        <slot
          v-if="$slots.body"
          name="body"
          />
      </div>
    </div>
  </div>
</template>

<script>
import ccButton from '@/components/primitives/Button.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

// Valid variants
const variantProps = [
  'default', //
  'challenge',
  'list',
  'alert',
  'plain',
  'product',
  'wallet',
  'inline',
  'notification',
  'address',
  'mia',
];

const colorProps = [
  'primary', //
  'white',
  'warning',
  'danger',
  'cc-yellow-darkest',
  'cc-yellow',
  'muted',
  'transparent',
  'blue-lighter',
  'blue-light',
  'blue-dark',
  'blue-darkest',
];
export default {
  components: {
    ccButton,
    ccHeading,
    ccText,
    ccSvgBase,
  },
  props: {
    innerTitle: {
      type: String,
      required: false,
      default: '',
    },
    innerTitleVariant: {
      type: String,
      required: false,
      default: 'h4',
    },
    innerTitleColor: {
      type: String,
      required: false,
      default: null,
    },
    innerText: {
      type: String,
      required: false,
      default: '',
    },
    outerTitleKey: {
      type: String,
      required: false,
      default: '',
    },
    outerTitle: {
      type: String,
      required: false,
      default: '',
    },
    svg: {
      type: String,
      required: false,
      default: '',
    },
    cardClass: {
      type: String,
      required: false,
      default: '',
    },
    // In order button to appear, a title must be set, at least for now
    hasButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonClass: {
      type: String,
      required: false,
      default: '',
    },
    buttonVariant: {
      type: String,
      required: false,
      default: 'small',
    },
    buttonLabel: {
      type: String,
      required: false,
      default: null,
    },
    ruid: {
      type: String,
      required: false,
      default: '',
    },
    padded: {
      type: Boolean,
      required: false,
      default: false,
    },
    pressable: {
      type: Boolean,
      required: false,
      default: false,
    },
    radius: {
      type: String,
      required: false,
      default: 'rounded-16',
    },
    hasCardBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
    cardBackground: {
      type: String,
      required: false,
      default: 'blue-dark',
      validator: value => colorProps.includes(value),
    },
    variant: {
      type: String,
      required: false,
      default: 'default',
      validator: value => variantProps.includes(value),
    },
  },
  computed: {
    getSlotClass() {
      // return [this.variant === 'list' || this.variant === 'alert' ? null : 'pt-4'];
      return [
        this.variant === 'address' && this.innerTitle ? '' : null,
        this.variant !== 'address' && this.innerTitle ? 'pt-4' : null,
      ];
    },
    getClass() {
      return [
        this.variant === 'default' ? 'p-5' : null,
        this.variant === 'challenge' ? 'p-4' : null,
        this.variant === 'address' ? 'p-0' : null,
        this.variant === 'inline' ? 'p-0' : null,
        this.variant === 'plain' ? 'p-0' : null,
        this.variant === 'wallet' ? 'p-4' : null,
        this.variant === 'product' ? 'px-4' : null,
        this.variant === 'list' ? 'px-4' : null,
        this.variant === 'alert' ? 'p-4' : null,
        this.variant === 'mia' ? 'p-4' : null,
        this.variant === 'notification' ? 'py-4' : null,
        this.hasCardBackground && `bg-${this.cardBackground}`,
        this.radius,
        this.hasShadow ? 'shadow' : null,
        this.cardClass,
      ];
    },
    getWrapperClass() {
      return [
        this.padded ? 'px-3' : null,
        this.pressable ? 'card__pressable transition-all' : null,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
// .card__shadow {
//   box-shadow: $box-shadow;
// }

.card__pressable {
  transform: translateZ(0) scale(1);
}
.card__pressable:active {
  transform: translateZ(0) scale(0.96);
}
</style>
