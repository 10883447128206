<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-leaderboards-control-group id="friends-leaderboard" />

    <cc-view
      :id="id"
      :has-full-width="true"
      :is-sub-view="true"
      :has-ui-controls="true"
      class="pb-6"
      >
      <cc-lottie
        v-if="appState.friendsLeaderboardLoading"
        id="activityIndicator"
        class="w-15 h-15"
        :options="animData"
        wrapper-class="p-5"
        />


      <div
        v-else
        class="w-100 pb-6 mb-6"
        >
        <cc-text
          color="muted"
          variant="body-sm"
          class="px-4 pt-4 pb-2"
          >
          {{ $t('friendsLeaderboardDescription' ) }}
        </cc-text>

        <div
          v-if="false"
          id="debugger"
          >
          <div
            v-for="(n, i) in 10"
            :key="i + 'a'"
            >
            <div>testing {{ i }}</div>
          </div>
        </div>

        <cc-text
          v-if="leaderboardState.friends[appState.leaderboardType].length > 0"
          color="muted"
          variant="body-sm"
          class="px-4 pb-2"
          >
          {{ appState.leaderboardType === 'averageCo2'
            ? $t('leaderboardsDescriptionSingular')
            : null }}
          {{ appState.leaderboardType === 'co2PerKm'
            ? $t('leaderboardsDescriptionSingularCo2PerKm')
            : null }}
        </cc-text>

        <!-- Regular Friends leaderboard -->
        <div v-if="leaderboardState.friends[appState.leaderboardType].length > 0">
          <cc-leaderboard-list-item
            v-for="(friendObject, index) in leaderboardState.friends[appState.leaderboardType]"
            :key="index"
            type="friends"
            :has-row-title="index === 0"
            :rank="friendObject.curPosition"
            :rank-class="getRankClass(friendObject.curPosition)"
            :prev-rank="friendObject.prevPosition"
            :icon="getCupIcon(friendObject.curPosition)"
            :self-rank="selfObject && selfObject.curPosition"
            :name="friendObject.self ? $t('txtSelf') : friendObject.name"
            :score="getEmissionText(friendObject.score)"
            :last="index + 1 === leaderboardState.friends[appState.leaderboardType].length"
            />
        </div>

        <!-- Did not travel leaderboard -->
        <div
          v-if="sortedDidNotTravelList.length > 0"
          class="pt-6"
          >
          <h4 class="font-weight-bold text-white text-left px-4">
            {{ didNotTravelText }}
          </h4>
          <cc-text
            class="px-4 pb-4 pt-2"
            variant="body-sm"
            color="muted"
            >
            {{ $t('didNotTravelDescription') }}
          </cc-text>

          <cc-leaderboard-list-item
            v-for="(friendObject, index) in sortedDidNotTravelList"
            :key="`dnt${index}`"
            :data-index="index"
            :did-not-travel="true"
            :rank="friendObject.curPosition || 0"
            :show-rank="false"
            :self-rank="dntSelfRank"
            :name="friendObject.self ? $t('txtSelf') : friendObject.name"
            score=" "
            />
        </div>
      </div>

      <!-- Regular Canton self card -->
      <div
        id="selfCard"
        class="flex-column d-flex align-items-center fixed-bottom w-100"
        :style="`bottom: calc(${uiState.tabBarHeight}px)`"
        >
        <transition name="self-card-slide-up">
          <div
            v-if="showHoverCard && !appState.friendsLeaderboardLoading && dntSelfRank === 0"
            class="d-flex flex-column justify-content-end h-100 w-100 px-2 pb-3 transition-all"
            >
            <div
              data-ruid="card__self"
              class="d-flex w-100 bg-blue-light align-self-center rounded-8 overflow-hidden shadow"
              @click="scrollToSelf"
              >
              <cc-leaderboard-list-item
                :rank="selfObject.curPosition"
                :rank-class="getRankClass(selfObject.curPosition)"
                :prev-rank="selfObject.prevPosition"
                :icon="getCupIcon(selfObject.curPosition)"
                :self-rank="selfObject.curPosition"
                :name="$t('txtSelf')"
                :score="getEmissionText(selfObject.score)"
                :hover-card="true"
                />
            </div>
          </div>
        </transition>
      </div>

      <div
        id="selfCardDNT"
        class="flex-column d-flex align-items-center fixed-bottom w-100"
        :style="`bottom: calc(${uiState.tabBarHeight}px)`"
        >
        <transition name="self-card-slide-up">
          <div
            v-if="showHoverCard && !appState.friendsLeaderboardLoading && dntSelfRank !== 0"
            class="d-flex flex-column justify-content-end h-100 w-100 px-2 pb-3 transition-all"
            >
            <div
              data-ruid="card__self"
              class="d-flex w-100 bg-blue-light align-self-center
                rounded-8 overflow-hidden shadow"
              @click="scrollToSelf()"
              >
              <cc-leaderboard-list-item
                :key="selfObjectDnt.name"
                :rank="selfObjectDnt.curPosition"
                :rank-class="getRankClass(selfObjectDnt.curPosition)"
                :prev-rank="selfObjectDnt.prevPosition"
                :icon="getCupIcon(selfObjectDnt.curPosition)"
                :show-rank="false"
                :self-rank="selfObjectDnt.curPosition"
                :name="$t('txtSelf')"
                score=" "
                :hover-card="true"
                :did-not-travel="true"
                />
            </div>
          </div>
        </transition>
      </div>
    </cc-view>
  </cc-view>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccLeaderboardsControlGroup from '@/components/social/LeaderboardsControlGroup.vue';
import ccLottie from '@/components/Lottie.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccView,
    ccText,
    ccLeaderboardListItem,
    ccLeaderboardsControlGroup,
    ccLottie,
  },
  data() {
    return {
      id: 'friends-leaderboard',
      showHoverCard: false,
      observer: null,
      animData: {
        animationData: LoadingLeaderboard,
      },
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    sortedDidNotTravelList() {
      return this.leaderboardState.friends.currentData.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    didNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelFriendsYet');
      }

      return this.$t('didNotTravelFriends');
    },
    dntSelfRank() {
      for (let i = 0; i < this.leaderboardState.friends.currentData.didNotTravel.length; i += 1) {
        if (this.leaderboardState.friends.currentData.didNotTravel[i].self) {
          return i + 1;
        }
      }

      return 0;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    selfObjectDnt() {
     if (this.leaderboardState.friends[this.appState.leaderboardType] && this.dntSelfRank !== 0) {
        return this.leaderboardState.friends.currentData.didNotTravel.find(e => e.self);
      }
      return null;
    },
    selfObject() {
      if (this.leaderboardState.friends[this.appState.leaderboardType] && this.dntSelfRank === 0) {
        if (this.dntSelfRank === 0) {
          return this.leaderboardState.friends[this.appState.leaderboardType].find(e => e.self);
        }
      }
      return null;
    },
  },
  watch: {
    queryDate() {
      this.$store.dispatch('social/getFriendsLeaderboard');
      this.createObserver();
    },
  },
  async created() {
    await this.$store.dispatch('user/getUser');

    const refreshTime = 900000;
    const weeksAgo = this.$moment().diff(this.appState.queryDate, 'weeks');

    if (
      !this.leaderboardState.friends
      || weeksAgo !== this.leaderboardState.friends.windowsAgo
      || Date.now() - refreshTime > this.leaderboardState.friends.timestamp
    ) {
      await this.$store.dispatch('social/getFriendsLeaderboard');
    }

    this.setHoverCard();
  },
  destroyed() {
    this.setAppState(['leaderboardType', 'averageCo2']);

    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    setHoverCard() {
      // Once disconnectHoverCard event received from LeaderboardSelector
      // check the observer, if exists, disconnect and null it
      this.$root.$on('disconnectHoverCard', () => {
        this.$log.info('Received disconnectHoverCard', this.observer);

        if (this.observer) {
          this.observer.disconnect();
          this.observer = null;
        }
      });

      // Once createHoverCardObserver event received from sdkListener > contactNames
      // create an IntersectionObserver for HoverCard
      this.$root.$on('createHoverCardObserver', () => {
        this.$log.info('Received createHoverCardObserver');
        this.$log.info('createHoverCardObserver > this.observer', this.observer);

        this.showHoverCard = false;
        this.createObserver();
      });

      this.createObserver();
    },
    createObserver() {
      this.$nextTick(() => {
        // Give a little time for Hover Card to appear
        // So user doesn't see a lot of things happening when mounted
        setTimeout(() => {
          // rootMargin is for the IntersectionObservers margin area.
          // Here we include iOS safe area inset top to consider this in calculations
          const safeArea = parseInt(
              getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top'),
              10,
            ) + 125;
          const rootMargin = `-${safeArea}px 0px 0px 0px`;

          const el = document.querySelector('#selected');
          const self = this.rank === this.selfRank && !this.hoverCard;


          if (el && self) {
            this.$log.info('will createObserver Friends');

            this.observer = new IntersectionObserver(
              (entries) => {
                // this.$log.info('IntOberserver', entries);

                if (entries[0].isIntersecting === true) {
                  if (entries[0].intersectionRatio === 1) {
                    this.showHoverCard = false;
                  }
                } else {
                  this.$log.info('will show hover card, Friends');
                  this.showHoverCard = true;
                }
              },
              {
                rootMargin,
                threshold: [0, 0.5, 1],
              },
            );
            this.observer.observe(el);
          }
        }, 800);
      });
    },
    // scrollToSelf() {
    //   const el = document.querySelector('#selected');
    //   const elDistance = window.pageYOffset + el.getBoundingClientRect().top;
    //   const top = elDistance - (window.innerHeight - el.clientHeight) / 2;

    //   window.scroll({ top, behavior: 'smooth' });
    // },
    scrollToSelf() {
      this.$log.info('clicked');
      const el = document.querySelector('#selected');
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
};
</script>

<style lang="scss">
#target-message {
  position: fixed;
  color: black;
  background-color: #f1c40f;
  padding: 10px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-size: 14px;
}
</style>
