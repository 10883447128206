<template>
  <cc-view :id="id">
    <cc-wrapper
      class="h-100 text-center safe-area-inset-bottom flex-grow-1"
      align="between"
      >
      <cc-wrapper class="flex-grow-1">
        <div class="p-6 pb-8">
          <cc-svg-base svg="illustrations/join-company-challenge" />
        </div>

        <cc-heading
          variant="h4"
          class="pb-3"
          >
          {{ $t('joinCompanyChallengeTitle') }}
        </cc-heading>

        <cc-text
          color="muted"
          class="mb-7 mx-5"
          >
          {{ $t('joinCompanyChallengeBody') }}
        </cc-text>

        <div class="bg-white w-100 rounded-8 py-4 d-flex flex-row justify-content-center mt-1 mb-7">
          <cc-svg-base
            svg="companies/ittigen"
            class="m-auto"
            />
          <cc-svg-base
            svg="companies/bafu"
            class="m-auto"
            />
          <cc-svg-base
            svg="companies/gvb"
            class="m-auto"
            />
          <cc-svg-base
            svg="companies/rbs"
            class="m-auto"
            />
        </div>

        <cc-button
          class="mt-1"
          :text="$t('verifyCompanyEmail')"
          variant="secondary"
          @click="
            $router
              .push({
                name: 'settings',
                params: {
                  showAddEmailAddressModal: true,
                },
              })
              .catch(() => {})
          "
          />

        <cc-button
          class="mt-4"
          :text="$t('addOnboardCodeButton')"
          variant="secondary"
          @click="
            $router
              .push({ name: 'add-onboard-code', params: { isInOnboarding: false } })
              .catch(() => {})
          "
          />
      </cc-wrapper>

      <div
        v-if="false"
        class="pb-6"
        >
        <cc-text
          variant="body-xs"
          class="text-uppercase pb-4"
          >
          {{ $t('companiesParticipating') }}
        </cc-text>
        <cc-svg-base
          svg="logos/company-postfinance"
          class="mr-2"
          style="height: 28px;"
          />
        <cc-svg-base
          :svg="`partner-logo/swisscom-horizontal-${appState.userLang}`"
          class="ml-2"
          />
      </div>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';
import validator from '@/mixins/validator';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccText,
    ccButton,
    ccSvgBase,
  },
  mixins: [validator],
  data() {
    return {
      id: 'join-company-challenge',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
};
</script>

<style></style>
