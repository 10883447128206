<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    class="text-center"
    >
    <div
      v-for="(card) in cards"
      :key="card.title"
      >
      <div
        class="px-3 py-6"
        :class="card.backgroundColor"
        >
        <cc-wrapper direction="row">
          <div
            v-if="card.extraSvg"
            style="width: 82px;"
            />

          <cc-heading class="flex-grow-1 px-5">
            {{ $t(card.title) }}
          </cc-heading>

          <cc-svg-base
            v-if="card.extraSvg"
            :svg="card.svgExtraOne"
            />
        </cc-wrapper>

        <cc-text
          variant="body-muted"
          class="p-3"
          >
          {{ $t(card.descriptionOne) }}
        </cc-text>


        <cc-svg-base
          :svg="`illustrations/getting-started/${card.svg}`"
          class="getting-started my-3"
          />

        <cc-wrapper direction="row">
          <cc-svg-base
            v-if="card.extraSvg"
            tag="i"
            wrapper-class="line-height-0"
            :svg="card.svgExtraTwo"
            />
          <div
            v-if="card.extraSvg"
            style="width: 121px;"
            />
        </cc-wrapper>

        <cc-text
          variant="body-muted"
          class="p-3"
          >
          {{ $t(card.descriptionTwo) }}
        </cc-text>
      </div>
    </div>

    <div
      v-if="!userIsVerified"
      class="px-4 pb-6 bg-blue-darkest"
      >
      <cc-wrapper>
        <cc-svg-base
          svg="illustrations/getting-started/trophy"
          class="mb-3"
          />

        <cc-heading class="px-5 pt-3">
          {{ $t('joinTheChallengeTitle') }}
        </cc-heading>

        <cc-text
          variant="body-muted"
          class="px-5 py-3 pb-5"
          >
          {{ $t('verifyCardDescription') }}
        </cc-text>

        <cc-button
          :text="$t('txtVerifyPhoneNumber')"
          @click="$router.push({ name: 'join-the-challenge' })"
          />
      </cc-wrapper>
    </div>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccView,
    ccText,
    ccHeading,
    ccWrapper,
    ccButton,
    ccSvgBase,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'getting-started',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
    }),
    cards() {
      const { userLang } = this.appState;

      return [
        {
          title: 'gettingStartedOneTitle',
          descriptionOne: 'gettingStartedOneDescriptionOne',
          descriptionTwo: 'gettingStartedOneDescriptionTwo',
          backgroundColor: 'bg-blue-darkest',
          svg: 'temp-bar',
        },
        {
          title: 'gettingStartedTwoTitle',
          descriptionOne: 'gettingStartedTwoDescriptionOne',
          descriptionTwo: 'gettingStartedTwoDescriptionTwo',
          backgroundColor: 'bg-blue-dark',
          svg: 'trips-tracking',
        },
        {
          title: 'gettingStartedThreeTitle',
          descriptionOne: 'gettingStartedThreeDescriptionOne',
          descriptionTwo: 'gettingStartedThreeDescriptionTwo',
          backgroundColor: 'bg-blue-darkest',
          svg: 'paris-goal',
        },
        {
          title: 'gettingStartedFourTitle',
          descriptionOne: 'gettingStartedFourDescriptionOne',
          descriptionTwo: '',
          backgroundColor: 'bg-blue-dark',
          svg: 'benefits',
          extraSvg: true,
          backgroundSvg: 'clouds-bg',
          svgExtraOne: 'getting_started_clouds_top',
          svgExtraTwo: 'getting_started_clouds_bottom',
        },
        {
          title: 'gettingStartedFiveTitle',
          descriptionOne: 'gettingStartedFiveDescriptionOne',
          descriptionTwo: '',
          backgroundColor: 'bg-blue-darkest',
          svg: `partners-${userLang}`,
        },
      ];
    },
  },
};
</script>
