<template>
  <div
    v-if="activeChallenges.length"
    class="pt-5"
    >
    <div class="w-100 d-flex flex-row justify-content-between px-4">
      <cc-section-header
        :title="$t('publicGoalProgressChallengesTitle')"
        align="start"
        heading-align="left"
        />
      <div class="my-auto pt-2">
        <cc-button
          variant="link-normal"
          @click.native="$router.push({ name: 'challenges',params:{
            backTo:'public-goal-progress'
          } }).catch(() => {})"
          >
          {{ $t('txtMore') }}
        </cc-button>
      </div>
    </div>

    <cc-charts-loading
      :is-loading="isLoading"
      >
      <div
        class="overflow-x-scroll w-100 flex-horizontal-scroll"
        >
        <div class="d-flex flex-row">
          <cc-card
            v-for="(challenge, i) in activeChallenges"
            :key="i"
            class="d-flex mb-4 single-card pr-4"
            variant="challenge"
            :class="i === 0 ? 'pl-4' : ''"
            style="flex:1"
            >
            <template #body>
              <div
                class="d-flex flex-column justify-content-between align-items-start h-100"
                style="width: 218px; min-width: 218px;"
                >
                <div class="w-100">
                  <div class="d-flex justify-content-between align-items-start">
                    <cc-image
                      width="56px"
                      height="56px"
                      :src="getChallengeProperty(challenge.achievement, 'imageUrl')"
                      />
                  </div>

                  <div class="pt-4">
                    <cc-heading
                      variant="h5"
                      class="text-ellipsis"
                      >
                      {{ getChallengeProperty(challenge.achievement, 'title') }}
                    </cc-heading>

                    <cc-text
                      variant="body-sm"
                      color="blue-lightest"
                      class="text-ellipsis ellipsis-description"
                      style="line-height: 21px;"
                      >
                      {{ getChallengeProperty(challenge.achievement, 'description') }}
                    </cc-text>
                  </div>
                </div>
                <cc-text
                  class="mt-3 px-2 py-1 bg-blue-light d-table rounded-6"
                  variant="body-xs-bold"
                  >
                  {{ $moment.duration(challenge.durationInMillis).humanize() }}
                </cc-text>
              </div>
            </template>
          </cc-card>
        </div>
      </div>
    </cc-charts-loading>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import ccChartsLoading from '@/components/ChartsLoading.vue';

import ccText from '@/components/primitives/Text.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';

export default {
  components: {
    ccText,
    ccImage,
    ccCard,
    ccSectionHeader,
    ccChartsLoading,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'public-goal-progress-challenges',
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      companyState: state => state.company,
      achievementsState: state => state.achievements,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
      activePlayerChallenges: 'achievements/activePlayerChallenges',
      hasPlayerChallenges: 'achievements/hasPlayerChallenges',
      activeChallenges: 'achievements/activeChallenges',
      hasActiveChallenges: 'achievements/hasActiveChallenges',
      isCompanyUser: 'user/isCompanyUser',
    }),
  },
  async mounted() {
    setTimeout(() => {
      this.hasShadow = true;
    }, 500);

    if (!this.companyState.player) {
      await this.$store.dispatch('company/getPlayer');
    }

    if (!this.hasPlayerChallenges) {
      await this.$store.dispatch('achievements/getPlayerChallenges');
    }

    if (!this.hasAchievements) {
      await this.$store.dispatch('achievements/getPlayerAchievements');
    }

    if (!this.hasActiveChallenges) {
      await this.$store.dispatch('achievements/postActiveChallengesForPlayer');
    }

    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getChallengeProperty(achievement, property) {
      const { getLocalizedResource } = this;
      const response = getLocalizedResource(achievement.resources, achievement.defaultLang)[
        property
      ];

      return response || '–';
    },
  },
};
</script>

<style scoped>
.ellipsis-description {
  -webkit-line-clamp: 3 !important;
}
</style>
