<template>
  <div
    :id="id"
    v-ruid="id"
    class="position-fixed"
    style="z-index:4;right:0;left:0;bottom:0;"
    >
    <div
      :id="`${id}__wrapper`"
      style="margin-bottom: calc(-50px - var(--safe-area-inset-bottom));"
      >
      <div
        :id="`${id}__wrapper__links`"
        class="d-flex justify-content-around align-items-center bg-blue-light position-relative"
        >
        <router-link
          v-for="item in items"
          :key="item.pathName"
          v-slot="{ navigate, isActive }"
          :to="to(item)"
          class="bg-blue-light"
          custom
          >
          <a
            v-ruid="`${id}__${item.pathName}`"
            :class="[isActive && 'router-link-active']"
            class="d-flex flex-column flex-1 justify-content-center align-items-center"
            @click="navigate"
            >
            <cc-svg-base
              tag="div"
              :svg="item.icon"
              class="line-height-0"
              :color-class="isActive ? 'svg-fill-white' : 'svg-fill-blue-lightest'"
              />
            <p
              class="m-0 pt-1 app__tab-bar__label text-capitalize"
              :class="isActive ? 'text-white' : 'text-blue-lightest'"
              >
              {{ $t(item.locale) }}
            </p>
          </a>
        </router-link>
      </div>

      <div
        class="bg-blue-light"
        style="height: var(--safe-area-inset-bottom)"
        />
    </div>
  </div>
</template>

<script>
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccSvgBase,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  methods: {
    to(item) {
      const route = {};

      route.name = item.pathName;
      route.params = { ...item?.route?.params } || {};

      return route;
    },
  },
};
</script>

<style lang="scss" scoped>
.app__tab-bar__label {
  font-size: $font-size-10;
  line-height: 0.75rem;
}

#app__tab-bar__wrapper__links {
  height: 3.125rem;
}
</style>
