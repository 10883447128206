import Vue from 'vue';
import moment from 'moment-timezone';
import ToggleButton from 'vue-js-toggle-button';
import * as Sentry from '@sentry/vue';
import { VueHammer } from 'vue2-hammer';
import Chart from 'chart.js';
import smoothscroll from 'smoothscroll-polyfill';
import intersectionObserver from 'intersection-observer';
import infiniteScroll from 'vue-infinite-scroll';

import App from '@/App.vue';
import store from '@/store/index';
import router from '@/router';
import i18n from '@/i18n';

import Ruid from '@/directives/ruid';
import Truncate from '@/directives/truncate';
import './mixins/globalComponents';

import PortalVue from 'portal-vue';

import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/it';

import { VueLogger, options } from '@/logger';

import {
  callSdk,
  getNumberOfWeek,
  daysInCurrentMonth,
  seperateTransportTypeAndFuelType,
  retry,
} from '@/mixins/utils';

import { ActionEnum } from '@/mixins/enums';
import { transportTypes } from '@/mixins/transportTypes';

import kibanaEvents from '@/mixins/kibanaEvents';

smoothscroll.polyfill();

Vue.use(PortalVue);

Vue.use(require('vue-moment'), {
  moment,
});


Vue.use(Ruid);
Vue.use(Truncate);
Vue.use(VueHammer);
Vue.use(ToggleButton);
Vue.use(VueLogger, options);
Vue.use(intersectionObserver);
Vue.use(infiniteScroll);

Vue.prototype.$chart = Chart;

Vue.mixin({
  data() {
    return {
      actions: ActionEnum,
      kibanaEvents,
      transportTypes,
    };
  },
  methods: {
    callSdk,
    getNumberOfWeek,
    daysInCurrentMonth,
    retry,
    seperateTransportTypeAndFuelType,
    sentryCaptureMessage: Sentry.captureMessage,
    sentryCaptureException: Sentry.captureException,
    sentryScope: Sentry.Scope,
    sentrySetExtra: Sentry.setExtra,
    sentrySetTag: Sentry.setTag,
    sentryWithScope: Sentry.withScope,
    sentryConfigureScope: Sentry.configureScope,
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    store.dispatch('user/checkUserStatus');
  },
  render: h => h(App),
}).$mount('#app');
