<template>
  <div :id="`editor-js__${id}`">
    <component
      :is="`cc-${component.type}`"
      v-for="(component, i) in components"
      :key="`'component-'${i}`"
      v-ruid="`block-${i}`"
      :block="component"
      :styles="styles"
      />
  </div>
</template>

<script>
import ccParagraph from '@/components/constructs/EditorParagraph.vue';
import ccHeader from '@/components/constructs/EditorHeader.vue';

/* eslint-disable vue/no-unused-components */
export default {
  components: {
    ccParagraph,
    ccHeader,
  },
  props: {
    contents: {
      type: Array,
      required: true,
    },
    styles: {
      type: Object,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      components: [],
    };
  },
  created() {
    this.$watch(
      'contents',
      () => {
        this.components = [];
        this.processContents();
      },
      { immediate: true },
    );
  },
  methods: {
    processContents() {
      const { contents, components } = this;

      if (contents.length > 0) {
        contents.forEach((block) => {
          // this.$log.info('Each block =>', block);

          Object.keys(block).forEach((key) => {
            // this.$log.info('key', key);
            switch (key) {
              case 'data':
                this.$log.info('Block:editor', key);
                return block?.data?.blocks?.forEach((b) => {
                  const payload = {
                    ...b,
                    data: {
                      ...b.data,
                      text: b.data.text.replaceAll('&nbsp;', '').replaceAll('<br>', ' '),
                    },
                  };

                  components.push(payload);
                });

              default:
                return false;
            }
          });

          return false;
        });
      }
    },
  },
};
</script>
