<template>
  <div class="barchart-size align-self-center">
    <bar-chart
      :chart-data="datacollection"
      :options="options"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BarChart from '../charts/StackedBarChart';

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      datacollection: {},
      options: {},
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      chartData: state => state.trips.chartData,
    }),
  },
  mounted() {
    this.fillData();
    this.$watch(
      'chartData',
      () => {
        if (this.appState.tripsProcessing === false) {
          this.fillData();
        }
      },
      { deep: true },
    );
  },
  methods: {
    fillData() {
      this.datacollection = {
        datasets: [
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: 'Human powered',
            backgroundColor: '#7bff8e',
            data: this.chartData.humanPowered,
          },
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: 'Electric',
            backgroundColor: '#ffda53',
            data: this.chartData.electric,
          },
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: 'Fossil',
            backgroundColor: '#ff8484',
            data: this.chartData.fossil,
          },
          {
            categoryPercentage: 0.4,
            barPercentage: 0.7,
            label: this.$t('txtNoData'),
            backgroundColor: '#004fbe',
            data: this.chartData.noData,
          },
        ],
        labels: [
          this.$t('dayMon'),
          this.$t('dayTue'),
          this.$t('dayWed'),
          this.$t('dayThu'),
          this.$t('dayFri'),
          this.$t('daySat'),
          this.$t('daySun'),
        ],
      };
      this.options = {
        animation: {
          duration: 700,
        },
        events: [],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: false,
              ticks: {
                beginAtZero: false,
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                padding: 16,
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('txtDays'),
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                fontSize: 13,
                xOffset: 70,
                yOffset: 280,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: false,
              ticks: {
                beginAtZero: true,
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                suggestedMax: 5000,
                maxTicksLimit: 6,
                callback(value) {
                  return value / 1000;
                },
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('txtKilometers'),
                fontColor: '#5d9cf4',
                fontFamily: 'TheSans',
                fontStyle: 'bold',
                fontSize: 15,
                xOffset: 20,
                yOffset: 9,
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
/*  Barchart  */
.barchart-size {
  max-width: 100%;
  position: relative;
  margin: auto;
}

#rounded-bar {
  height: 28vh !important;
}

</style>
