<template>
  <cc-modal
    data-ruid="modal__create-group"
    group-wrapper-class="safe-area-inset-top mh-100"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('groupLeaderboardCreateGroup')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5"
        >
        <!-- class="px-5 w-100 overflow-scroll-y mt-4 flex-grow-1 d-flex flex-column" -->
        <validation-observer
          ref="form"
          >
          <validation-provider
            v-slot="{
              invalid,
              failed,
            }"
            rules="required|minmax:3,30|alphaNumericWithSpaces"
            :name="$t('email')"
            >
            <cc-input
              id="group-name"
              v-model.trim="name"
              class="pb-4"
              data-ruid="input-group-name"
              :state="failed && invalid ? 'is-invalid' : null"
              :label="$t('groupLeaderboardGroupNameInputLabel')"
              :placeholder="$t('groupLeaderboardGroupNameInputPlaceholder')"
              :has-visible-label="1"
              />
          </validation-provider>

          <validation-provider
            v-slot="{
              invalid,
              failed,
            }"
            rules="minmax:3,60|alphaNumericWithSpaces"
            :name="$t('groupLeaderboardGroupDescriptionInputLabel')"
            >
            <cc-input
              id="group-description"
              v-model.trim="description"
              class="pb-4"
              data-ruid="input-group-description"
              :state="failed && invalid ? 'is-invalid' : null"
              :label="$t('groupLeaderboardGroupDescriptionInputLabel')"
              :placeholder="$t('groupLeaderboardGroupDescriptionInputPlaceholder')"
              :has-visible-label="1"
              />
          </validation-provider>
        </validation-observer>


        <cc-section-header
          :title="$t('groupLeaderboardAddFriends')"
          svg="illustrations/add-members"
          />

        <cc-text
          v-if="contactList.length > 0"
          color="muted"
          class="flex-grow-1 text-center pb-4"
          >
          {{ $t('groupLeaderboardAddFriendsFullState') }}
        </cc-text>

        <!-- {{ userState.contacts }} -->

        <cc-card
          v-if="contactList.length > 0"
          card-background="blue-darkest"
          variant="list"
          class="pt-3 pb-6"
          >
          <template #body>
            <div
              v-for="(contact, index) in contactList"
              :key="index"
              :class="contact ? 'pt-4' : null"
              >
              <cc-wrapper
                direction="row"
                justify="start"
                align="center"
                class="w-100 pb-4"
                @click.native="selectContact(index)"
                >
                <cc-text class="flex-grow-1 text-capitalize">
                  {{ contact }}
                </cc-text>

                <cc-wrapper>
                  <div
                    v-if="selectedContacts.includes(index)"
                    class="mr-1"
                    >
                    <cc-svg-base
                      :key="contact"
                      color-class="svg-fill-primary"
                      svg="icons/icon-check"
                      />
                  </div>

                  <div
                    v-else
                    class="border-radius-full checkbox-unchecked"
                    />
                </cc-wrapper>
              </cc-wrapper>

              <cc-divider
                v-if="index !== contactList.length - 1"
                style="flex-basis: 100%;"
                />
            </div>
          </template>
        </cc-card>

        <cc-text
          v-else
          align="center"
          color="muted"
          class="py-2"
          >
          {{ $t('groupLeaderboardAddFriendsEmptyState') }}
        </cc-text>
      </div>
    </template>


    <template #footer="{ close }">
      <div class="pb-5 pt-2 px-5">
        <cc-error-message
          :message="errors[0]"
          alignment="center"
          class="pb-3"
          />

        <cc-button
          data-ruid="global__button-confirm"
          :is-modal-button="true"
          :text="$t('groupLeaderboardCreateGroup')"
          :has-loading-feedback="true"
          @click="createGroup(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { validate } from 'vee-validate';
import validator from '@/mixins/validator';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccText,
    ccButton,
    ccModal,
    ccSvgBase,
    ccInput,
    ccSectionHeader,
    ccDivider,
    ccErrorMessage,
  },
  mixins: [validator],
  data() {
    return {
      id: 'create-group-modal',
      name: '',
      description: '',
      // contactList: [],
      selectedContacts: [],
      errors: [],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
    contactList() {
      return [...(this.userState?.contacts?.contactNames || [])];
    },
  },
  mounted() {
    this.$log.info('Displaying: CreateGroupModal');

    // TOOD: Check if this is necessary.
    if (this.appState.contactsPermission === 'true' && !this.userState?.contacts?.id) {
      this.$log.info('Will call social/getContacts');
      this.$store.dispatch('social/getContacts');
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    selectContact(index) {
      const elementIndex = this.selectedContacts.indexOf(index);
      if (elementIndex > -1) {
        this.selectedContacts.splice(elementIndex, 1);
      } else {
        this.selectedContacts.push(index);
      }
    },
    async createGroup(closeModal) {
      try {
        const { description, name, $route } = this;

        this.errors = [];

        this.$log.info('createGroup');

        // Name validation
        const groupName = await validate(name, 'required|minmax:3,30|alphaNumericWithSpaces', {
          name: this.$t('groupLeaderboardGroupNameInputLabel'),
        });

        if (!groupName.valid) {
          this.$log.info('groupName NOK', groupName);
          throw groupName;
        }

        // Description validation
        const groupDescription = await validate(description, 'minmax:3,30|alphaNumericWithSpaces', {
          name: this.$t('groupLeaderboardGroupDescriptionInputLabel'),
        });

        if (!groupDescription.valid) {
          this.$log.info('groupDescription Name NOK', groupDescription);
          throw groupDescription;
        }

        // Payload preparation
        const metaPublic = {
          description,
        };

        const selfLessContactsList = this.userState?.contacts?.data?.filter(
          contact => !contact.self,
        );

        // TODO: Double check in general
        const members = this.selectedContacts.map(contact => ({
          // email:
          // phone:
          id: selfLessContactsList[contact].userId,
          role: 'participant',
        }));

        await this.$store.dispatch('social/createGroup', { name, metaPublic, members });
        await this.$store.dispatch('social/getGroupsLeaderboard', {
          forceUpdate: true,
        });

        closeModal(() => {
          if ($route.name === 'join-group-leaderboard') {
            this.$router.push({
              name: 'group-leaderboard',
              params: {
                groupCreatedModal: {
                  event: 'group-created-modal',
                  name,
                },
              },
            });
          }

          this.$emit('after-leave', 'group-created-modal', name);
        });
      } catch (error) {
        const validationError = error?.errors?.[0];

        if (validationError) {
          this.errors.push(error.errors[0]);
        }

        this.$log.warn('createGroup err', error);
      }
    },
  },
};
</script>
