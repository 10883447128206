<template>
  <div
    :id="rank === selfRank && !hoverCard ? 'selected' : ''"
    :data-ruid="`leaderboard-list-item-${rank}`"
    class="w-100"
    :class="getClass"
    >
    <div
      v-if="hasRowTitle"
      class="d-flex justify-content-end pb-2"
      :class="getArrowClass"
      >
      <cc-text
        :class="hasArrow ? 'pr-4' : null"
        color="muted"
        variant="body-bold"
        >
        {{ $t('txtCo2') }}
      </cc-text>
    </div>
    <cc-divider
      v-if="hasTopDivider"
      id="divider-top"
      :height="1"
      :class="!hoverCard && !widget ? 'ml-4' : null"
      />


    <div
      class="d-flex justify-content-around align-items-center"
      :class="getRowClass"
      >
      <div
        v-if="!widget"
        style="flex: 0 0 18px;"
        >
        <cc-svg-base
          v-if="!widget && leaderboardMovement(rank, prevRank) !== ''"
          :svg="leaderboardMovement(rank, prevRank) || null"
          />
      </div>

      <div
        v-if="hasRank"
        class="d-flex justify-content-center mr-2"
        style="flex: 0 0 42px;"
        >
        <cc-text
          :color="rankClass"
          variant="body-bold"
          >
          {{ didNotTravel ? '' : toOrdinalNumber(rank, appState.userLang) }}
        </cc-text>
      </div>

      <div
        v-if="icon"
        id="icon"
        :class="type !== 'canton' ? 'justify-content-center' : null"
        class="d-flex mr-2"
        style="flex: 0 0 42px;"
        >
        <cc-svg-base
          :svg="icon"
          />
      </div>

      <cc-text
        class="text-capitalize text-truncate flex-grow-1 pr-2"
        :class="nameText === $t('missingContactName') ? 'opacity-60' : ''"
        >
        {{ nameText }}
      </cc-text>

      <cc-text
        variant="body-bold"
        align="right"
        class="flex-shrink-0"
        :class="getScoreClass"
        >
        {{ didNotTravel ? null : score }}
      </cc-text>


      <div
        v-if="hasArrow"
        style="flex: 0 0 20px;"
        :class="getArrowClass"
        >
        <cc-svg-base
          v-if="isClickable"
          class="d-flex justify-content-start align-items-center"
          color-class="svg-fill-white"
          svg="icons/icon-arrow-right"
          />
      </div>
    </div>

    <!-- <cc-divider
      v-if="last && !widget"
      id="divider-bottom"
      :height="1"
      /> -->
  </div>
</template>

<script>
/**
 * Whole component requires a refactoring
 * Props should be used in a smarter way
 * Toggling states has become a pain here
 */
import { mapState } from 'vuex';
import { toOrdinalNumber } from '@/mixins/utils';

import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccText,
    ccDivider,
    ccSvgBase,
  },
  props: {
    rank: {
      type: Number,
      required: true,
    },
    prevRank: {
      type: Number,
      required: false,
      default: undefined,
    },
    rankClass: {
      type: String,
      required: false,
      default: undefined,
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    selfRank: {
      type: Number,
      required: false,
      default: undefined,
    },
    name: {
      type: String,
      default: '-',
      required: true,
    },
    score: {
      type: String,
      default: null,
    },
    last: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    hoverCard: {
      type: Boolean,
      default: false,
    },
    hasRowTitle: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Boolean,
      default: false,
    },
    didNotTravel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    hasRank() {
      const { rank, type, didNotTravel } = this;
      if (didNotTravel) {
        return true;
      }

      if (type !== 'canton') {
        return rank > 3;
      }

      return true;
    },
    hasTopDivider() {
      const {
        hasRowTitle, //
        rank,
        selfRank,
        widget,
        didNotTravel,
      } = this;

      if (didNotTravel) {
        return true;
      }
      if (!(rank === selfRank || rank === selfRank + 1) && !widget) {
        return true;
      }
      if (hasRowTitle) {
        return true;
      }
      return false;
    },
    getScoreClass() {
      const {
        hoverCard, //
        widget,
        type,
      } = this;

      return [
        hoverCard && !widget ? 'pr-4' : null,
        !hoverCard && !widget ? 'pr-1' : null,
        widget ? 'pr-2' : null,
        type === 'canton' ? 'pr-4' : null,
        type === 'friends' ? 'pr-4' : null,
        type === 'group' ? 'pr-4' : null,
      ];
    },
    hasArrow() {
      const {
        hoverCard, //
        widget,
        isClickable,
        type,
      } = this;

      if (type === 'groups') {
        return true;
      }

      if (widget || hoverCard) {
        return isClickable;
      }

      return false;
    },
    getArrowClass() {
      const {
        hoverCard, //
        widget,
      } = this;

      return {
        'pr-4': hoverCard && !widget,
        'mr-4': !hoverCard && !widget,
        'pr-2': widget,
      };
    },
    getClass() {
      const {
        hoverCard,
        widget,
      } = this;

      return [
        hoverCard && !widget ? 'pl-2' : null,
        !widget ? 'leaderboard-list-item' : null,
      ];
    },
    getRowClass() {
      const {
        rank, //
        hoverCard,
        widget,
        selfRank,
        type,
        isClickable,
      } = this;

      return [
        widget ? 'py-3' : 'py-5',
        widget && type === 'friends' ? 'pl-2' : null,
        rank === selfRank ? 'bg-blue-light' : null,
        widget && !hoverCard ? 'rounded-16 pl-2' : null,
        !hoverCard && !widget ? 'pl-4' : null,
        !isClickable && !hoverCard && !widget ? 'pr-4' : null,
      ];
    },
    nameText() {
      const emptyName = this.type === 'groups' ? '' : this.$t('missingContactName');
      return this.name.length ? this.name : emptyName;
    },
  },
  methods: {
    toOrdinalNumber,
    leaderboardMovement(currentRank, prevRank) {
      if (!prevRank) {
        return '';
      }
      if (currentRank > prevRank) {
        return 'down';
      }
      if (currentRank < prevRank) {
        return 'up';
      }
      return '';
    },
  },
};
</script>
