var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-view',{attrs:{"id":_vm.selectorId}},[(_vm.appState.isLoading)?_c('cc-charts-loading',{staticClass:"bg-blue-darkest position-absolute top-left-0 h-100"}):[_c('validation-observer',{ref:"form"},[_c('validation-provider',{attrs:{"rules":"required|minmax:3,30|alphaNumericWithSpaces","name":_vm.$t('groupLeaderboardGroupNameInputLabel')},scopedSlots:_vm._u([{key:"default",fn:function({
          invalid,
          failed,
        }){return [_c('cc-input',{staticClass:"pb-4",attrs:{"data-ruid":"input-group-name","label":_vm.$t('groupLeaderboardGroupNameInputLabel'),"state":failed && invalid ? 'is-invalid' : null,"has-visible-label":1,"placeholder":_vm.$t('groupLeaderboardGroupNameInputPlaceholder'),"disabled":!_vm.isAdminOrOwner},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"groupName"}})]}}])}),_c('validation-provider',{attrs:{"rules":"minmax:3,60|alphaNumericWithSpaces","name":_vm.$t('groupLeaderboardGroupDescriptionInputLabel')},scopedSlots:_vm._u([{key:"default",fn:function({
          invalid,
          failed,
        }){return [_c('cc-input',{staticClass:"pb-6",attrs:{"data-ruid":"input-group-description","label":_vm.$t('groupLeaderboardGroupDescriptionInputLabel'),"state":failed && invalid ? 'is-invalid' : null,"has-visible-label":1,"placeholder":"Enter group description","disabled":!_vm.isAdminOrOwner},model:{value:(_vm.groupDescription),callback:function ($$v) {_vm.groupDescription=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"groupDescription"}})]}}])})],1),_c('cc-error-message',{staticClass:"pb-6",attrs:{"message":_vm.errors[0]}}),_c('cc-card',{staticClass:"mb-6",attrs:{"variant":"list","pressable":true},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.hasExitGroupButton)?_c('cc-settings-list-item',{attrs:{"data-ruid":"button__group-settings__leave-group","icon":"icons/icon-exit","label":_vm.$t('groupLeaderboardLeaveGroup'),"arrow":"","last":_vm.userRole !== 'owner'},nativeOn:{"click":function($event){_vm.isConfirmGroupExitModalDisplayed = true}}}):_vm._e(),(_vm.hasDeleteGroupButton)?_c('cc-settings-list-item',{attrs:{"data-ruid":"button__group-settings__delete-group","icon":"icons/icon-close","label":_vm.$t('groupLeaderboardDeleteGroup'),"arrow":"","last":_vm.userRole === 'owner'},nativeOn:{"click":function($event){_vm.isConfirmGroupDeletionModalDisplayed = true}}}):_vm._e()]},proxy:true}])}),(_vm.isAdminOrOwner)?_c('cc-button',{staticClass:"mb-4",attrs:{"data-ruid":"button__group-settings__save","text":_vm.$t('txtSave'),"has-loading-feedback":true},on:{"click":_vm.updateGroupProfile}}):_vm._e()],(_vm.isConfirmGroupDeletionModalDisplayed)?_c('cc-confirm-group-deletion-modal',{attrs:{"data":_vm.group},on:{"close-modal":function($event){_vm.isConfirmGroupDeletionModalDisplayed = false}}}):_vm._e(),(_vm.isConfirmGroupExitModalDisplayed)?_c('cc-confirm-group-exit-modal',{attrs:{"data":_vm.group},on:{"close-modal":function($event){_vm.isConfirmGroupExitModalDisplayed = false}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }