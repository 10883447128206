import Vue from 'vue';
// import * as Sentry from '@sentry/vue';
import anime from 'animejs/lib/anime.min';
import uuidv4 from 'uuid/v4';
import semver from 'semver';
import i18n from '@/i18n';

import { ActionEnum } from '@/mixins/enums';
import { callSdk } from '@/mixins/utils';
import service from '@/services/index';
import moment from 'moment';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// State object
const state = {
  appState: {
    appId: 'web',
    os: 'web',
    osVersion: null,
    userLang: null,
    newsFeedLang: null,
    screenOrientation: '0',
    context: 'scc',
    locationPermission: 'undefined',
    motionPermission: 'undefined',
    notificationPermission: 'undefined',
    publicChallenge: {
      startDate: moment('02/09/2024', 'DD/MM/YYYY').toDate(),
      endDate: moment('31/10/2024', 'DD/MM/YYYY').toDate(),
    },
    batterySavingModes: {
      batteryOptimizationEnabled: false,
      powerSavingEnabled: false,
    },
    contactsPermission: 'undefined',
    contactsUploadConsent: false,
    cellularTripDataUpload: 'undefined',
    preciseLocation: 'undefined',
    routingFrom: null,
    routingTo: [],
    isRouting: false,
    isLoading: false,
    isEventsQueueProcessing: false,
    isOnline: true,
    isDocumentVisible: true,
    filter: 'week',
    queryDate: new Date().getTime(),
    tripsProcessing: false,
    cancelToken: null,
    hasTabBarToggler: false,
    selectedTimelineError: null,
    didFinishPermissionChecks: false,
    didFinishFeatureModalChecks: false,
    lastTransmission: null,
    isTesting: false,
    hasFatalError: false,
    forceDisplayTrackingNotAlways: false,
    dialogHeight: 0,
    displayAlert: false,
    sessionStarted: false,
    contactsUploaded: null,
    friendsLeaderboardLoading: false,
    cantonLeaderboardLoading: false,
    groupLeaderboardLoading: false,
    groupsLeaderboardLoading: false,
    companyLeaderboardLoading: false,
    companyGroupsLeaderboardLoading: false,
    selectedCanton: '',
    leaderboardType: 'averageCo2',
    isAwaiting: false,
    isProd: process.env.VUE_APP_ENV === 'production',
    isStaging: process.env.VUE_APP_ENV === 'staging',
    isDev: process.env.VUE_APP_ENV === 'dev',
    isDevelopment: process.env.VUE_APP_ENV === 'development',
    isSandbox: window.parent !== window,
    sdkVersion: null,
    nativeAppVersion: null,
    tripDataSdkVersion: null,
    viewWillBeShown: false,
    webAppVersion: process.env.VERSION,
    webAppEnv: process.env.VUE_APP_ENV,
    webSiteURL: process.env.VUE_APP_WEBSITE,
  },
  featureFlags: {
    wallet: process.env.VUE_APP_FEATURE_WALLET === 'true',
    shop: process.env.VUE_APP_FEATURE_SHOP === 'true',
  },
  uiState: {
    tabBarHeight: null,
    navigationBarHeight: null,
    routePositions: {},
    exitRoute: {},
  },
  eventsQueue: [],
  historyState: [],
  supportedLangs: ['de', 'en', 'fr', 'it'],
};

// Mutations
const mutations = {
  setAppState(state, [prop, value]) {
    Vue.set(state.appState, prop, value);
  },
  setUiState(state, [prop, value]) {
    Vue.set(state.uiState, prop, value);
  },
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  setRoutePosition(state, [prop, value]) {
    Vue.set(state.uiState.routePositions, prop, value);
  },
  setExitRouteOfTab(state, [prop, value]) {
    Vue.set(state.uiState.exitRoute, prop, value);
  },
  pushPageHistory(state, entry) {
    state.historyState.unshift(entry);
  },
  pushToEventsQueue(state, payload) {
    state.eventsQueue.push(payload);
  },
  shiftEventsQueue(state) {
    state.eventsQueue.shift();
  },
};

// Actions
const actions = {
  openUrlInternal(context, url) {
    const { OPEN_INTERNAL } = ActionEnum;

    Vue.$log.info('Will openUrlInternal', url);

    if (context.state.appState.isSandbox) {
      window.open(encodeURI(url), '_blank').focus();
    } else {
      callSdk(`action=${OPEN_INTERNAL}&url=${encodeURI(url)}`);
    }
  },

  openUrlExternal(context, url) {
    const { OPEN_EXTERNAL } = ActionEnum;

    Vue.$log.info('Will openUrlExternal', url);

    callSdk(`action=${OPEN_EXTERNAL}&url=${encodeURI(url)}`);
  },

  setBackgroundColor(context, color) {
    callSdk(`action=${ActionEnum.SET_BACKGROUND_COLOR}&color=${encodeURI(color)}`, this);
  },


  urlShortener(ctx, payload) {
    return new Promise((resolve, reject) => {
      service.common
        .urlShortener(payload)
        .then(response => resolve(response))
        .catch(err => reject(err));
    });
  },

  wifiToggle({ commit, state }) {
    const { SET_CELLULAR_TRIP_DATA_UPLOAD } = ActionEnum;
    const { cellularTripDataUpload } = state.appState;

    if (cellularTripDataUpload === 'true') {
      callSdk(`action=${SET_CELLULAR_TRIP_DATA_UPLOAD}&enable=${encodeURI('false')}`);
      commit('setAppState', ['cellularTripDataUpload', 'false']);
    } else {
      callSdk(`action=${SET_CELLULAR_TRIP_DATA_UPLOAD}&enable=${encodeURI('true')}`);
      commit('setAppState', ['cellularTripDataUpload', 'true']);
    }
  },

  // TODO: Refactor this
  hasGettingStartedCard(context) {
    const { commit, dispatch } = context;
    const { appState } = context.state;
    const { user } = context.rootState.user;

    return new Promise((resolve, reject) => {
      if (appState.sessionStarted) {
        if (user !== null) {
          if (user.metaPublic?.gettingStartedCardImpressions === undefined) {
            /**
             * TODO: this flag name sessionStarted is too generic for this use case
             * It doesn't make sense to have this name and then toggle the flag for something specific
             * like getting started card
             */
            commit('setAppState', ['sessionStarted', false]);
            dispatch('user/setGettingStartedCardImpressions', 1, { root: true });
          } else if (user.metaPublic.gettingStartedCardImpressions <= 5) {
            const updated = user.metaPublic.gettingStartedCardImpressions + 1;

            commit('setAppState', ['sessionStarted', false]);

            dispatch('user/setGettingStartedCardImpressions', updated, { root: true });
          }
        }
      }

      const { locationPermission, gps } = appState;

      const isTrackingActive = gps !== 'off' && locationPermission === 'always';

      const d = new Date();
      d.setDate(d.getDate() - 15);
      // const daysAgo = Date.parse(new Date()) - 3600000; // 1 hour ago for testing

      // const creationTime = Date.parse(user.createdAt);

      let impressions = null;
      let cardAllowed = false;

      if (user !== null) {
        if (user?.metaPublic?.gettingStartedCardImpressions !== undefined) {
          cardAllowed = user.metaPublic?.gettingStartedCardImpressions > 5;
          impressions = user.metaPublic?.gettingStartedCardImpressions;
        }
      }

      if ((!isTrackingActive || !cardAllowed) && impressions !== null) {
        // const daysAgo = Date.parse(d);

        // Vue.$log.info('visible: true -',
        //   `15daysAgo: ${daysAgo}`,
        //   // `creationTime: ${creationTime}`,
        //   // `old enough? ${!(daysAgo <= creationTime)}`,
        //   `isTrackingActive: ${isTrackingActive} -`,
        //   `impressions: ${impressions}`);
        resolve(true);
      }

      // Vue.$log.info('visible: false -',
      //   `15daysAgo: ${daysAgo}`,
      //   `creationTime: ${creationTime}`,
      //   `old enough? ${!(daysAgo <= creationTime)}`,
      //   `isTrackingActive: ${isTrackingActive}`,
      //   `impressions: ${impressions}`);
      reject();
    });
  },

  setTimeout(ctx, duration) {
    /**
     * Use this to simulate network delays
     * by calling it within async calls in debug time
     * ie. await ctx.dispatch('setTimeout', 3000);
     */
    return new Promise((resolve) => {
      setTimeout(() => {
        // Vue.$log.info('Timeout is resolving after', duration);
        resolve(duration);
      }, duration);
    });
  },

  /**
   * Given an element id and name provided
   * it sets the offset height of the element in uiState.
   * Later these values are used to calculate heights dynamically
   *
   * @param {Object} obj - Payload.
   * @param {string} obj.id - DOM element id
   * @param {string} obj.name - Name of the key
   */
  setUiElementHeight({ commit }, { element, id, name }) {
    return new Promise((resolve, reject) => {
      try {
        /**
         * TODO: getElementById runs as many times as setUiElementHeight is called
         * try to reduce it to be picked up only once.
         */
        let el;
        if (!element) {
          el = document.getElementById(id);
        } else {
          el = element;
        }

        if (el) {
          commit('setUiState', [name, el.offsetHeight]);
          // Vue.$log.info('Did setUiElementHeight', route.name, name, el.offsetHeight);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  setUiControlsHeight({ commit }, { id, name }) {
    // Vue.$log.info('setUiControlsHeight', id);
    const view = document.getElementById(`app__view__${id}`);

    if (view) {
      const totalHeight = Array.from(view?.children)
        ?.filter(child => child?.dataset?.uiControl)
        ?.reduce((acc, item) => (item?.offsetHeight || 0) + acc, 0);

      // Vue.$log.info('Action: uiControlsTotalHeight', totalHeight);

      commit('setUiState', [name, totalHeight]);
    }
  },

  showTabBar({ getters, dispatch }, { route }) {
    const { duration, easing } = getters.getAnimeJSOptions;
    const tabBarWrapperEl = getters.getTabBarWrapperEl();

    // doing stupid stuff. necessary for initial transition.
    const bottom = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--safe-area-inset-bottom');
    const bottomNumber = parseInt(bottom?.split('px')[0], 10);

    // Vue.$log.info('marginBottom', tabBarWrapperEl.style.marginBottom);
    // Vue.$log.info('bottomNumber', bottomNumber);
    const hasCalc = tabBarWrapperEl.style.marginBottom.includes('calc');

    if (hasCalc) {
      tabBarWrapperEl.style.marginBottom = `${-bottomNumber + -50}px`;
    }

    const element = document.getElementById('app__tab-bar');

    anime({
      targets: tabBarWrapperEl,
      marginBottom: 0,
      duration,
      easing,
      begin: () => {
        // Vue.$log.info('showTabBar begin');
      },
      update: () => {
        // Vue.$log.warn('showTabBar Update:', getters.isTabBarVisible());
        dispatch('setUiElementHeight', {
          element,
          id: 'app__tab-bar',
          name: 'tabBarHeight',
          route,
        });
      },
    });
  },

  hideTabBar: ({ getters, dispatch }, { route }) => {
    const { duration, easing } = getters.getAnimeJSOptions;
    const tabBarEl = getters.getTabBarEl();
    const tabBarWrapperEl = getters.getTabBarWrapperEl();
    const element = document.getElementById('app__tab-bar');

    anime({
      targets: tabBarWrapperEl,
      marginBottom: [0, tabBarEl && -tabBarEl.offsetHeight],
      duration,
      easing,
      begin: () => {
        // Vue.$log.warn('hideTabBar begin');
      },
      update: () => {
        // Vue.$log.warn('showTabBar Update:', getters.isTabBarVisible());
        dispatch('setUiElementHeight', {
          element,
          id: 'app__tab-bar',
          name: 'tabBarHeight',
          route,
        });
      },
    });
  },

  prepareKibanaEvent: ({ state, rootGetters }, payload) => {
    const {
      category, //
      action,
      type,
      metaPublic,
    } = payload;

    // Vue.$log.info('prepareKibanaEvent', state.appState.sessionId);

    const {
      appId, //
      sessionId,
      os,
      osVersion,
      userLang,
      sdkVersion,
      webAppVersion,
      nativeAppVersion,
      tripDataSdkVersion,
    } = state.appState;

    const event = {
      category,
      action,
      type,
      metaPublic: {
        src: 'web',
        appId,
        sessionId,
        os,
        osVersion,
        userLang,
        domain: rootGetters['user/getCompanyUserEmailDomain'],
        nativeAppVersion,
        tripDataSdkVersion,
        sdkVersion,
        webAppVersion,
        ...metaPublic,
      },
    };

    return event;
  },

  insertEvent: async ({ state, commit, dispatch }, payload) => {
    const { isEventsQueueProcessing, isOnline, isDocumentVisible } = state.appState;

    // Vue.$log.info('Will insertEvent', payload);

    const event = await dispatch('prepareKibanaEvent', payload);

    commit('pushToEventsQueue', event);

    // Process Kibana events if client is online and web view is visible to prevent timeouts
    if (!isEventsQueueProcessing && isOnline && isDocumentVisible) {
      // Vue.$log.info('Will processKibanaEvents', state.eventsQueue.length);
      await dispatch('processKibanaEvents');
    }
  },

  async processKibanaEvents({
    state, //
    commit,
    dispatch,
    rootState,
  }) {
    const { accessToken } = rootState.user || {};
    const { eventsQueue } = state;

    commit('setAppState', ['isEventsQueueProcessing', true]);

    while (eventsQueue.length > 0) {
      /**
       * While looping client might go offline or put the app on the background
       * exit the loop if that is the case to prevent timeouts
       * Online event in App.vue has a call to `processKibanaEvents` if there are any remaning events
       * when client comes back online or bring the app to the foreground, process should resume
       */
      if (!state.appState.isDocumentVisible) {
        break;
      }

      if (!state.appState.isOnline) {
        break;
      }

      const eventPayload = eventsQueue.slice().shift();

      commit('shiftEventsQueue');

      // Vue.$log.info('isDocumentVisible', state.appState.isDocumentVisible);
      // Vue.$log.info('processKibanaEvents', eventPayload.type);

      // eslint-disable-next-line no-await-in-loop
      await dispatch('setTimeout', 250);

      // Vue.$log.info(
      //   'Will dispatch event',
      //   eventPayload.action,
      //   eventPayload.type,
      //   eventsQueue.length,
      // );

      if (accessToken) {
        service.common
          .insertEvent(eventPayload)
          .then(async response => response)
          .catch((error) => {
            Vue.$log.info('insertEvent NOK', error);
          });
      }

      // Some events occur when access token is not available, so dispatch those to public endpoint
      if (!accessToken) {
        if (eventPayload.action === 'view-displayed' || eventPayload.action === 'app-opened') {
          service.common
            .insertPublicEvent(eventPayload, state.appState.context)
            .then(async response => response)
            .catch((error) => {
              Vue.$log.info('insertPublicEvent NOK', error);
            });
        }
      }
    }

    // Vue.$log.info('Finished while, eventsQueue length:', eventsQueue.length);

    commit('setAppState', ['isEventsQueueProcessing', false]);

    return false;
  },

  setSessionId({ commit }) {
    commit('setAppState', ['sessionId', uuidv4()]);
  },
};

// Getter functions
const getters = {
  // TODO: Remove socialFeatureMinimumSdkVersion as it's not relevent anymore?
  socialFeatureMinimumSdkVersion(state) {
    let sdkVersionString = state.appState.sdkVersion || '1.3 - GitHash';
    sdkVersionString = sdkVersionString.substring(0, sdkVersionString.indexOf('-'));

    const sdkVersionFloat = parseFloat(sdkVersionString);

    if (
      sdkVersionFloat >= 1.4
      || state.appState.os === 'web'
      || process.env.VUE_APP_ENV === 'beta'
      || process.env.VUE_APP_ENV === 'development'
    ) {
      return true;
    }
    return false;
  },

  getNotificationGateway({ appState }) {
    const { os } = appState;

    switch (os) {
      case 'ios':
        return 'apns';
      case 'android':
        return 'fcm';
      default:
        return 'web';
    }
  },

  /**
   *
   * @param {string} androidVersion – SemVer compatible version
   * @param {string} iosVersion – SemVer compatible version
   * @returns {boolean} Truthy if version is satisfied
   */
  hasSDKVersion: state => (androidVersion, iosVersion) => {
    const { sdkVersion, os } = state.appState;

    let sdkVersionString = sdkVersion || '1.5.0 - GitHash';

    sdkVersionString = sdkVersionString.substring(0, sdkVersionString.indexOf('-'));

    if (iosVersion && semver.gte(sdkVersionString, iosVersion) && os === 'ios') {
      return true;
    }
    if (androidVersion && semver.gte(sdkVersionString, androidVersion) && os === 'android') {
      return true;
    }
    if (os === 'web') {
      return true;
    }
    return false;
  },

  hasAndroidAPIVersion: state => (androidVersion) => {
    const { osVersion } = state.appState;

    if (
      osVersion
      && semver.gte(semver.coerce(osVersion).version, semver.coerce(androidVersion).version)
    ) {
      return true;
    }
    return false;
  },

  hasNotificationReminder({ appState }, getters) {
    const { notificationPermission } = appState;

    return getters.hasSDKVersion('1.9.6', '1.18.2') && notificationPermission !== 'true';
  },

  /*
    TODO: When we know only MT SDK 3.0 is being used for android
    on the new platform this getter and it's references can be removed
    */
  motionTagThreeMinimumSdkVersion(state) {
    let sdkVersionString = state.appState.sdkVersion || '1.5.0 - GitHash';

    sdkVersionString = sdkVersionString.substring(0, sdkVersionString.indexOf('-'));

    if (semver.gte(sdkVersionString, '1.6.0')) {
      return true;
    }
    return false;
  },

  hasInvalidAndroidVersion: (state) => {
    const { osVersion, os } = state.appState;
    return os === 'android' && parseInt(osVersion, 10) < 21;
  },

  hasAndroidQOrAbove: (state) => {
    const { osVersion, os } = state.appState;
    return os === 'android' && parseInt(osVersion, 10) >= 29;
  },

  hasInvalidChromeVersion: state => navigator.browserSpecs.name === 'Chrome'
    && navigator.browserSpecs.version < (state.appState.browserVersion || 44),

  webAppVersion: ({ appState }) => `${appState.webAppVersion} - ${appState.webAppEnv}`,

  areTripsFetching(state) {
    return state.appState.tripsProcessing;
  },

  // TODO: Why don't we receive active true/false from SDK?
  isTrackingActive({ appState }, getters) {
    const { locationPermission, motionPermission, gps } = appState;
    const { hasAndroidQOrAbove, motionTagThreeMinimumSdkVersion } = getters;

    if (gps === 'off') {
      return 'txtInactive';
    }
    if (hasAndroidQOrAbove && motionTagThreeMinimumSdkVersion && motionPermission !== 'true') {
      return 'txtInactive';
    }
    if (locationPermission === 'inUse' || locationPermission === 'always') {
      return 'txtActive';
    }
    return 'txtInactive';
  },

  viewWillBeShown({ appState }) {
    return appState.viewWillBeShown;
  },

  getOS({ appState }) {
    const { os } = appState;

    if (os === 'android' || os === 'ios') {
      return os;
    }
    return '';
  },

  isWiFiUploadOnly({ appState }) {
    if (appState.cellularTripDataUpload === 'true') {
      return false;
    }
    return true;
  },

  getEmissionText: () => (emission, hasUnit) => {
    if (emission >= 1000) {
      const co2 = (emission / 1000).toFixed(1);
      return `${co2} kg ${hasUnit ? 'CO₂' : ''}`;
    }
    const fixedEmission = emission?.toFixed(0);
    return `${fixedEmission} g ${hasUnit ? 'CO₂' : ''}`;
  },

  getTabBarEl: () => () => {
    const tabBarEl = document.getElementById('app__tab-bar');
    // Vue.$log.info('Vuex: getTabBarEl:', !!tabBarEl);
    return tabBarEl;
  },

  getTabBarWrapperEl: () => () => {
    const tabBarWrapperEl = document.getElementById('app__tab-bar__wrapper');
    // Vue.$log.info('Vuex: getTabBarWrapperEl:', !!tabBarWrapperEl);
    return tabBarWrapperEl;
  },

  calcSubViewHeight: state => () => {
    const { uiState } = state;
    const totalHeight = uiState.uiControlsHeight ? `${uiState.uiControlsHeight}px` : '0px';

    // Vue.$log.info('uiControlGroupHeight', totalHeight);

    return `height:calc(100% - ${totalHeight}) !important;`;
  },

  calcViewHeight: state => () => {
    const { uiState } = state;
    const top = 'var(--safe-area-inset-top, 0px)';
    // const bottom = 'var(--safe-area-inset-bottom, 0px)';

    // TODO: Refactor uiState height storage to be an object to accumulate all values at once?
    // const navigationBarHeight = uiState.navigationBarHeight ? `${uiState.navigationBarHeight}px` : '0px';
    const tabBarHeight = uiState.tabBarHeight ? `${uiState.tabBarHeight}px` : '0px';

    // Vue.$log.info('calcViewHeight: uiState', uiState.tabBarHe ight);

    return `height:calc(100% - ${tabBarHeight} - 44px - ${top});`;
  },

  getAnimeJSOptions: () => ({
    // easing: 'linear',
    easing: 'cubicBezier(0.165, 0.84, 0.44, 1)',
    // easing: 'easeOutElastic(1, 0.8)',
    duration: 500,
  }),

  isTabBarVisible: (state, getters) => () => {
    const tabBarWrapperEl = getters.getTabBarWrapperEl();

    const marginBottom = window.getComputedStyle(tabBarWrapperEl).marginBottom.split('px')[0];

    const isVisible = parseInt(marginBottom, 10) >= 0;

    // Vue.$log.warn('Store: isTabBarVisible', isVisible);
    return isVisible;
  },

  dateFormatting: () => momentObejct => momentObejct.calendar(null, {
    sameDay: `[${i18n.t('Today')}]`,
    nextDay: `[${i18n.t('Tomorrow')}]`,
    nextWeek: 'dddd',
    lastDay: `[${i18n.t('Yesterday')}]`,
    lastWeek: `${i18n.t('LastDDDD')}`,
    sameElse: () => {
      if (momentObejct.year() === Vue.moment().year()) {
        return 'D MMMM';
      }
      return 'D MMMM, YYYY';
    },
  }),

  getAppStoreUrl: () => process.env.VUE_APP_APP_STORE_URL,

  getGooglePlayUrl: () => process.env.VUE_APP_GOOGLE_PLAY_URL,
};

export default {
  namespaced: true,
  state: () => state,
  getters,
  actions,
  mutations,
};
