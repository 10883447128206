<template>
  <!-- <cc-card
    svg="icons/icon-green-coin"
    :pressable="true"
    :inner-text="`${getWalletBalance} ${$t('shopGreenCoins')}`"
    button-label="17"
    button-variant="heading"
    button-class="text-primary"
    class="pb-5 px-4 pt-4"
    variant="wallet"
    @click.native="$router.push({ name: 'wallet' })"
    /> -->
  <cc-card
    variant="wallet"
    :pressable="pressable"
    >
    <template #body>
      <cc-wrapper
        direction="row"
        align="between"
        justify="between"
        >
        <cc-wrapper
          direction="row"
          align="center"
          style="padding: 2px 0;"
          >
          <cc-svg-base
            tag="i"
            :svg="`icons/${variants[variant].icon}`"
            wrapper-class="line-height-0"
            />
          <cc-text
            v-if="['coins', 'points'].includes(variant)"
            variant="body-bold"
            class="pl-3"
            >
            {{ variants[variant].label }}
          </cc-text>

          <cc-text
            v-if="['shop'].includes(variant)"
            variant="body-bold"
            class="pl-3"
            >
            {{ balance.amount }} {{ variants[variant].label }}
          </cc-text>
        </cc-wrapper>

        <cc-button
          v-if="['shop'].includes(variant)"
          variant="small"
          :block="false"
          :text="$t('txtDetails')"
          @click="$emit('button')"
          />

        <cc-heading
          v-if="['coins', 'points'].includes(variant)"
          id="balance"
          color="primary"
          class="line-height"
          >
          {{ balance.amount }}
        </cc-heading>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import anime from 'animejs/lib/anime.min';

import ccCard from '@/components/constructs/Card.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccCard,
    ccSvgBase,
    ccWrapper,
    ccText,
    ccHeading,
    ccButton,
  },
  props: {
    amount: {
      type: [String, Number],
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'coins',
    },
    pressable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      balance: {
        amount: 0,
      },
    };
  },
  computed: {
    variants() {
      return {
        coins: {
          icon: 'icon-green-coin',
          label: this.$t('walletCoinBalance'),
        },
        points: {
          icon: 'icon-leaf',
          label: this.$t('walletPointBalance'),
        },
        shop: {
          icon: 'icon-green-coin',
          label: this.$t('walletCoinBalance'),
        },
      };
    },
  },
  mounted() {
    this.countAmount();
  },
  updated() {
    this.countAmount();
  },
  methods: {
    countAmount() {
      this.$nextTick(() => {
        anime({
          targets: this.balance,
          amount: this.amount,
          round: 1,
          easing: 'easeOutQuart',
          complete: (anim) => {
            this.$log.info(`completed : ${anim.completed}`);
          },
        });
      });
    },
  },
};
</script>

<style>
</style>

