import axiosClient from '@/services/axiosClient';
import { retry } from '@/mixins/utils';

const version = '/v1';
const resource = `${version}/juoco`;
const resourcePublic = `${version}/public`;
const resourceUser = `${version}/user`;

export default {
  /**
   * User challenges that have failed, succeeded, pending or evaluating
   */
  async getPlayerChallenges() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resource}/getPlayerChallenges`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  /**
   * User achievements (only challenges that were succeeded)
   */
  async getPlayerAchievements() {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resource}/getPlayerAchievements`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  /**
   * Active/available challenges for the user
   */
  async postActiveChallengesForPlayer(userLocation) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}/getActiveChallengesForPlayer`, userLocation))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },


  /**
   * Join a challenge
   */
  async joinChallenge(challengeId) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}/joinPlayerOnChallenge/${challengeId}`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },


  /**
   * Results of the public Climate Day Challenge (CDC)
   */
  async getChallengeDayTotalPoints(endDate, startDate) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.get(`${resourcePublic}/stats/getChallengeDayTotalPoints?endDate=${endDate}&startDate=${startDate}`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async getMiaPublicGoalData() {
    return this.getPublicGoalData(resourcePublic, true);
  },

  async getPublicGoalProgress() {
    return this.getPublicGoalData(resourceUser);
  },
  async getPublicGoalData(res, isMia = false) {
    return new Promise((resolve, reject) => {
      if (!process.env.VUE_APP_PUBLIC_GOAL_CHALLENGE_ID) reject(Error('No challenge id set'));
      axiosClient.get(`${res}/getPublicGoalDataDb?challengeId=${isMia ? process.env.VUE_APP_MIA_CHALLENGE_ID : process.env.VUE_APP_PUBLIC_GOAL_CHALLENGE_ID}&includeCarDistanceEmissionType=true`)
        .then((response) => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
};
