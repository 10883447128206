<template>
  <cc-wrapper
    v-if="message || appState.errorMessage"
    direction="row"
    align="start"
    :justify="alignment === 'left' ? 'start' : 'center'"
    data-ruid="global__error-message"
    class="py-2"
    >
    <span
      v-if="alignment === 'left'"
      class="py-1 pr-2 shake d-flex flex-colum justify-content-center"
      >
      <cc-svg-base svg="icons/icon-exclamation" />
    </span>

    <cc-text
      color="white"
      :align="alignment === 'left' ? 'left' : 'center'"
      >
      <span
        v-if="alignment === 'center'"
        class="pr-2 shake d-inline-block"
        >
        <cc-svg-base svg="icons/icon-exclamation" />
      </span>

      {{ message || appState.errorMessage }}
    </cc-text>
  </cc-wrapper>
</template>

<script>
import { mapState } from 'vuex';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccWrapper,
    ccText,
    ccSvgBase,
  },
  props: {
    message: {
      type: String,
      default: null,
      required: false,
    },
    alignment: {
      type: String,
      default: 'left',
      required: false,
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
};
</script>

<style>
.shake{
  animation: shake linear 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: shake linear 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: shake linear 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: shake linear 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: shake linear 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes shake{
  0% {
    transform:  translate(0px,0px)  rotate(0deg) ;
  }
  15% {
    transform:  translate(-20px,0px)  rotate(-5deg) ;
  }
  30% {
    transform:  translate(15px,0px)  rotate(3deg) ;
  }
  45% {
    transform:  translate(-10px,0px)  rotate(-3deg) ;
  }
  60% {
    transform:  translate(5px,0px)  rotate(2deg) ;
  }
  75% {
    transform:  translate(-2px,0px)  rotate(-1deg) ;
  }
  100% {
    transform:  translate(0px,0px)  rotate(0deg) ;
  }
}

@-moz-keyframes shake{
  0% {
    -moz-transform:  translate(0px,0px)  rotate(0deg) ;
  }
  15% {
    -moz-transform:  translate(-20px,0px)  rotate(-5deg) ;
  }
  30% {
    -moz-transform:  translate(15px,0px)  rotate(3deg) ;
  }
  45% {
    -moz-transform:  translate(-10px,0px)  rotate(-3deg) ;
  }
  60% {
    -moz-transform:  translate(5px,0px)  rotate(2deg) ;
  }
  75% {
    -moz-transform:  translate(-2px,0px)  rotate(-1deg) ;
  }
  100% {
    -moz-transform:  translate(0px,0px)  rotate(0deg) ;
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  }
  15% {
    -webkit-transform:  translate(-20px,0px)  rotate(-5deg) ;
  }
  30% {
    -webkit-transform:  translate(15px,0px)  rotate(3deg) ;
  }
  45% {
    -webkit-transform:  translate(-10px,0px)  rotate(-3deg) ;
  }
  60% {
    -webkit-transform:  translate(5px,0px)  rotate(2deg) ;
  }
  75% {
    -webkit-transform:  translate(-2px,0px)  rotate(-1deg) ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  }
}

@-o-keyframes shake {
  0% {
    -o-transform:  translate(0px,0px)  rotate(0deg) ;
  }
  15% {
    -o-transform:  translate(-20px,0px)  rotate(-5deg) ;
  }
  30% {
    -o-transform:  translate(15px,0px)  rotate(3deg) ;
  }
  45% {
    -o-transform:  translate(-10px,0px)  rotate(-3deg) ;
  }
  60% {
    -o-transform:  translate(5px,0px)  rotate(2deg) ;
  }
  75% {
    -o-transform:  translate(-2px,0px)  rotate(-1deg) ;
  }
  100% {
    -o-transform:  translate(0px,0px)  rotate(0deg) ;
  }
}

@-ms-keyframes shake {
  0% {
    -ms-transform:  translate(0px,0px)  rotate(0deg) ;
  }
  15% {
    -ms-transform:  translate(-20px,0px)  rotate(-5deg) ;
  }
  30% {
    -ms-transform:  translate(15px,0px)  rotate(3deg) ;
  }
  45% {
    -ms-transform:  translate(-10px,0px)  rotate(-3deg) ;
  }
  60% {
    -ms-transform:  translate(5px,0px)  rotate(2deg) ;
  }
  75% {
    -ms-transform:  translate(-2px,0px)  rotate(-1deg) ;
  }
  100% {
    -ms-transform:  translate(0px,0px)  rotate(0deg) ;
  }
}
</style>
