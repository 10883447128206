<template>
  <cc-view
    :id="id"
    >
    <cc-card
      class="mb-6"
      outer-title-key="aboutSCC"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__getting-started`"
          :label="$t('gettingStartedTitle')"
          arrow
          @click.native="$router.push({ name: 'getting-started' })"
          />

        <cc-settings-list-item
          :ruid="`${id}__partners`"
          :label="$t('txtPartners')"
          arrow
          @click.native="$router.push({ name: 'partners' })"
          />

        <cc-settings-list-item
          :ruid="`${id}__about`"
          :label="$t('aboutSCC')"
          arrow
          last
          @click.native="$router.push({ name: 'about-scc' })"
          />
      </template>
    </cc-card>

    <cc-card
      class="mb-6"
      outer-title-key="followSectionTitle"
      variant="list"
      >
      <template #body>
        <cc-settings-list-item
          :ruid="`${id}__social-facebook`"
          icon="follow-facebook-logo"
          label="Facebook"
          @click.native="
            $store.dispatch(
              'common/openUrlExternal',
              'https://www.facebook.com/SwissClimateChallenge/'
            )"
          />

        <cc-settings-list-item
          :ruid="`${id}__social-instagram`"
          icon="follow-instagram-logo"
          label="Instagram"
          @click.native="
            $store.dispatch(
              'common/openUrlExternal',
              'https://www.instagram.com/swiss_climatechallenge/'
            )"
          />

        <cc-settings-list-item
          :ruid="`${id}__social-tiktok`"
          icon="follow-tiktok-logo"
          label="TikTok"
          @click.native="
            $store.dispatch(
              'common/openUrlExternal',
              'https://www.tiktok.com/@swissclimatechallenge'
            )"
          />

        <cc-settings-list-item
          :ruid="`${id}__social-youtube`"
          icon="follow-youtube-logo"
          label="YouTube"
          last
          @click.native="
            $store.dispatch(
              'common/openUrlExternal',
              'https://www.youtube.com/@swissclimatechallenge'
            )"
          />
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import { mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccSettingsListItem,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'about',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),

  },
  methods: {
    openEmailClient() {
      this.callSdk(`action=${this.actions.OPEN_EXTERNAL}&url=${encodeURI('mailto:info@swissclimatechallenge.ch')}`);
    },
  },
};
</script>
