<template>
  <cc-modal
    data-ruid="modal__phone-verification"
    group-wrapper-class="h-100 mt-4"
    group-class="bg-blue-darkest w-100 h-100"
    position="bottom"
    header-title=""
    @close-modal="$emit('close-modal')"
    >
    <template #body="{ close }">
      <div
        id="scrollableComponent"
        class="d-flex flex-column w-100 h-100 overflow-hidden flex-grow-1"
        >
        <transition
          name="fade-slide"
          mode="out-in"
          >
          <component
            :is="name"
            :query="query"
            :is-modal="true"
            @close-modal="$event => close(() => $event === 'next'
              ? $emit('after-leave')
              : null )"
            @route-object="getRouteObject($event)"
            />
        </transition>
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import JoinTheChallenge from '@/views/onboarding/JoinTheChallenge.vue';
import SignUp from '@/views/onboarding/SignUp.vue';
import PhoneVerification from '@/views/onboarding/PhoneVerification.vue';
import AccountVerified from '@/views/onboarding/AccountVerified.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccModal,
    JoinTheChallenge,
    SignUp,
    PhoneVerification,
    AccountVerified,
    ccButton,
  },
  data() {
    return {
      id: 'phone-verification-modal',
      name: 'JoinTheChallenge',
      query: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
    }),
  },
  destroyed() {
    this.$log.warn('Destroyed: phone-verification-modal');
  },

  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getRouteObject(event) {
      this.name = event.name;
      this.query = event.query;
    },
  },
};
</script>
