// export const cantons = ['aargau', 'appenzell-ausserrhoden', 'appenzell-innerrhoden',
// 'basel-landschaft', 'basel-stadt', 'bern', 'fribourg', 'geneva', 'glarus', 'graubünden', 'jura',
// 'luzern', 'neuchâtel', 'nidwalden', 'obwalden', 'schaffhausen', 'schwyz', 'solothurn',
// 'st. gallen', 'switzerland', 'thurgau', 'ticino', 'uri', 'valais', 'vaud', 'zug', 'zürich'];
// eslint-disable-next-line import/prefer-default-export
export const cantons = {
  ZH: { name: 'zürich', co2PerYear: 4720.4 },
  BE: { name: 'bern', co2PerYear: 3201.2 },
  LU: { name: 'luzern', co2PerYear: 3409.6 },
  UR: { name: 'uri', co2PerYear: 3056.9 },
  SZ: { name: 'schwyz', co2PerYear: 4104.4 },
  OW: { name: 'obwalden', co2PerYear: 3182.6 },
  NW: { name: 'nidwalden', co2PerYear: 3558.4 },
  GL: { name: 'glarus', co2PerYear: 3294.8 },
  ZG: { name: 'zug', co2PerYear: 4560.1 },
  FR: { name: 'fribourg', co2PerYear: 3812.6 },
  SO: { name: 'solothurn', co2PerYear: 3225.4 },
  BS: { name: 'basel-stadt', co2PerYear: 2153.1 },
  BL: { name: 'basel-landschaft', co2PerYear: 2716.0 },
  SH: { name: 'schaffhausen', co2PerYear: 3427.5 },
  AR: { name: 'appenzell ausserrhoden', co2PerYear: 3296.7 },
  AI: { name: 'appenzell innerrhoden', co2PerYear: 3025.4 },
  SG: { name: 'st. gallen', co2PerYear: 3381.7 },
  GR: { name: 'graubünden', co2PerYear: 3019.2 },
  AG: { name: 'aargau', co2PerYear: 3609.7 },
  TG: { name: 'thurgau', co2PerYear: 3614.7 },
  TI: { name: 'ticino', co2PerYear: 3043.8 },
  VD: { name: 'vaud', co2PerYear: 4276.2 },
  VS: { name: 'valais', co2PerYear: 3286.8 },
  NE: { name: 'neuchâtel', co2PerYear: 3593.8 },
  GE: { name: 'geneva', co2PerYear: 5835.4 },
  JU: { name: 'jura', co2PerYear: 2913.6 },
};
