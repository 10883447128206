<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="$t('climateDayChallengeSectionTitle')"
    button-variant="small-muted"
    :pressable="true"
    :button-label="$t('txtMore')"
    class="pb-4"
    @click.native="$store.dispatch('common/openUrlInternal', climateDayChallengeURL)"
    >
    <template #body>
      <cc-wrapper
        direction="row"
        justify="start"
        >
        <div class="pr-3">
          <cc-svg-base
            svg="illustrations/cdc"
            />
        </div>

        <cc-wrapper
          align="start"
          justify="start"
          >
          <cc-text
            variant="body-bold"
            >
            {{ $t('climateDayChallengeTarget', { target: formattedRange }) }}
          </cc-text>

          <div
            class="bg-blue-lighter rounded-16 my-2"
            style="height:8px;"
            :style="`width: ${bar.width}%`"
            >
            <div
              class="rounded-16 bg-cc-green"
              style="height:8px;min-width:8px;"
              :style="`width: ${progressBarPercentage}%;`"
              />
          </div>

          <cc-wrapper
            direction="row"
            align="center"
            >
            <cc-svg-base
              class="mr-1"
              svg="icons/icon-cdc"
              />
            <cc-text
              variant="body-sm"
              >
              {{ formattedPoints }} {{ $t('shopGreenPoints') }}
            </cc-text>
          </cc-wrapper>
        </cc-wrapper>
      </cc-wrapper>

      <cc-text
        class="pt-4"
        variant="body"
        >
        {{ $t('climateDayChallengeTargetCompletion', { target: formattedRange }) }}
      </cc-text>
    </template>
  </cc-card>
</template>


<script>
import { mapState } from 'vuex';
import anime from 'animejs/lib/anime.min';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
// import ccProgressBar from '@/components/achievements/ProgressBar.vue';


export default {
  components: {
    ccCard,
    ccWrapper,
    ccSvgBase,
    ccText,
    // ccProgressBar,
  },
  data() {
    return {
      id: 'climate-day-challenge-card',
      progress: {
        points: 0,
        range: 100000,
      },
      bar: {
        width: 0,
      },
      result: null,
    };
  },
  computed: {
    ...mapState({
      appState: st => st.common.appState,
    }),
    climateDayChallengeURL() {
      return `${process.env.VUE_APP_CDC_URL}/?userLang=${this.appState.userLang}`;
    },
    progressBarPercentage() {
      return this.clamp((this.progress.points * 100) / this.progress.range, 0, 100);
    },
    formattedRange() {
      return new Intl.NumberFormat(this.getLanguageWithCountryCode).format(this.progress.range);
    },
    formattedPoints() {
      return new Intl.NumberFormat(this.getLanguageWithCountryCode).format(this.progress.points);
    },
    getLanguageWithCountryCode() {
      const { userLang } = this.appState;

      if (userLang === 'en') {
        return 'en-US';
      }
      if (userLang === 'de') {
        return 'de-CH';
      }
      if (userLang === 'fr') {
        return 'fr-CH';
      }
      if (userLang === 'it') {
        return 'it-CH';
      }
      return 'en-US';
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    clamp(value, min, max) {
      return Math.min(Math.max(value, min), max);
    },
    animatePoints() {
      this.$nextTick(() => {
        anime({
          targets: this.progress,
          points: this.result?.points || 0,
          round: 1,
          easing: 'easeOutQuart',
        });
      });
    },
    animateBar() {
      this.$nextTick(() => {
        anime({
          targets: this.bar,
          width: 100,
          round: 1,
          easing: 'easeOutQuart',
        });
      });
    },
    async fetchData() {
      const endDate = process.env.VUE_APP_CDC_END_DATE;
      const startDate = process.env.VUE_APP_CDC_START_DATE;

      const response = await this.$store.dispatch('achievements/getChallengeDayTotalPoints', { endDate, startDate });

      this.result = response.result;

      this.$log.info('response', response, response.result.points, response.updatedAt);

      await this.animateBar();
      await this.animatePoints();
    },
  },
};
</script>

<style>

</style>
