<template>
  <cc-segmented-control
    :id="id"
    :items="items"
    :active-class="getActiveClass"
    @click="clickHandler"
    />
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccSegmentedControl from '@/components/constructs/SegmentedControl.vue';

export default {
  components: {
    ccSegmentedControl,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          locale: 'selectorBarDay',
          value: 'today',
        },
        {
          locale: 'selectorBarWeek',
          value: 'week',
        },
        {
          locale: 'selectorBarMonth',
          value: 'month',
        },
        {
          locale: 'selectorBarYear',
          value: 'year',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getActiveClass(filter) {
      return filter.value === this.appState.filter ? 'active' : '';
    },
    clickHandler(filter) {
      this.filter = filter;

      if (filter !== this.appState.filter.value) {
        this.setAppState(['queryDate', Date.now()]);
        this.setAppState(['filter', filter.value]);
        this.$store.dispatch('trips/getTimeline');
      }
      // TODO: Commented out as it seems to be unnecessary, observe and remove
      // else if (showIncrementDate(appState.queryDate, appState.filter.value)) {
      //   this.setFilter(filter);
      // }
    },
  },
};
</script>
