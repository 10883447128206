<template>
  <div class="pt-5 px-4">
    <div class="w-100 d-flex flex-row justify-content-between">
      <cc-section-header
        :title="$t('publicGoalProgressMyEmission')"
        align="start"
        heading-align="left"
        />
      <div class="my-auto pt-2">
        <cc-button
          variant="link-normal"
          @click.native="
            $router
              .push({
                name: 'climate-change',
                params: {
                  backTo: 'public-goal-progress',
                },
              })
              .catch(() => {})
          "
          >
          {{ $t('txtMore') }}
        </cc-button>
      </div>
    </div>

    <cc-card
      :id="id"
      :ruid="id"
      button-variant="small-muted"
      :variant="null"
      card-class="p-4"
      >
      <template #body>
        <cc-charts-loading :is-loading="isLoading">
          <div>
            <div class="d-flex flex-row flex-wrap pb-4 justify-content-between">
              <cc-heading
                variant="h5"
                class="pb-2 mr-1"
                >
                {{ $t('publicGoalProgressCo2EmissionTitle') }}
              </cc-heading>

              <div class="text-center">
                <cc-text
                  color="muted"
                  variant="body-sm"
                  >
                  {{ dateTitle }}
                </cc-text>
              </div>
            </div>

            <div>
              <cc-public-goal-emission-bar-chart
                :avg-accumulators="avgAccumulators"
                :last-sevenday="lastSevendays"
                />

              <cc-public-goal-emission-legend class="pt-2" />
            </div>
          </div>
        </cc-charts-loading>
      </template>
    </cc-card>
  </div>
</template>

<script>
import ccHeading from '@/components/primitives/Heading.vue';
import ccCard from '@/components/constructs/Card.vue';

import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import { mapState } from 'vuex';
import ccPublicGoalEmissionBarChart from './PublicGoalEmissionBarChart.vue';
import ccPublicGoalEmissionLegend from './PublicGoalEmissionLegend.vue';

export default {
  name: 'PublicGoalProgressCo2Emission',
  components: {
    ccHeading,
    ccCard,
    ccSectionHeader,
    ccPublicGoalEmissionBarChart,
    ccPublicGoalEmissionLegend,
    ccChartsLoading,
  },
  data() {
    return {
      id: 'public-goal-progress-co2-emission',
      publicGoalData: null,
      latestData: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      publicGoal: state => state.achievements.publicGoal,
    }),
    getArrayAvgAccumulators() {
      return Object.keys(this.publicGoalData.avgAccumulators).slice(
        Object.keys(this.publicGoalData.avgAccumulators).length > 7 ? -7 : 0,
      );
    },
    isEmptyData() {
      return !this.publicGoalData || !Object.keys(this.publicGoalData?.avgAccumulators)?.length;
    },
    avgAccumulators() {
      if (!this.publicGoalData) return null;
      const avgAccumulators = {};
      this.getArrayAvgAccumulators.forEach((acc) => {
        avgAccumulators[acc] = this.publicGoalData.avgAccumulators[acc];
      });
      return this.isEmptyData ? {} : avgAccumulators;
    },
    lastSevendays() {
      if (!this.publicGoalData || !this.publicGoalData.avgAccumulators) return [];
      return this.getArrayAvgAccumulators;
    },
    dateTitle() {
      return this.lastSevendays && this.lastSevendays.length
        ? `${this.$moment(this.lastSevendays[this.lastSevendays.length - 1], 'YYYY-MM-DD')
            .subtract(6, 'days')
            .format('DD MMM')} - ${this.$moment(
            this.lastSevendays[this.lastSevendays.length - 1],
            'YYYY-MM-DD',
          ).format('DD MMM')}`
        : '';
    },
  },
  watch: {
    publicGoal() {
      this.processData();
    },
  },
  async created() {
      await this.$store.dispatch('achievements/getPublicGoalData');
      this.processData();
      this.$log.info('PublicGoalProgressCo2Emission loaded');
  },
  methods: {
    filterTheToday(publicGoalData) {
      const newPublicGoalData = publicGoalData;
      Object.keys(publicGoalData.avgAccumulators).forEach((acc) => {
        if (this.$moment(acc, 'YYYY-MM-DD').isSame(this.$moment(), 'day')) {
          delete newPublicGoalData.avgAccumulators[acc];
        }
      });
      return newPublicGoalData;
    },
    processData() {
        this.latestData = this.publicGoal.data.avgAccumulators[
            Object.keys(this.publicGoal.data.avgAccumulators)[
              Object.keys(this.publicGoal.data.avgAccumulators).length - 1
            ]
          ];
        this.publicGoalData = this.filterTheToday(this.publicGoal.data);
        this.isLoading = false;
    },
  },
};
</script>
