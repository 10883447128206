import { mapMutations, mapState, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { isValidIssuer, getBackRoute } from '@/mixins/utils';

import axiosInstance from '@/services/axiosClient';

export default {
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      historyState: state => state.common.historyState,
      leaderboardState: state => state.social.leaderboardState,
      user: state => state.user.user,
      userState: state => state.user,
    }),
    ...mapGetters({
      getLanguagePreference: 'user/getLanguagePreference',
      isUserAuthenticated: 'user/isUserAuthenticated',
    }),
  },
  methods: {
    ...mapMutations({
      setUiState: 'common/setUiState',
      pushToNotificationsQueue: 'social/pushToNotificationsQueue',
      setSocialState: 'social/setState',
      setAppState: 'common/setAppState',
      setUserState: 'user/setState',
    }),
    sdkInfoListener(payload) {
      if (this.isSafe(payload)) {
        this.setAppState(['sdkVersion', payload]);
        // this.$log.info('SDK: sdkInfoListener', payload);
      }
    },
    appInfoListener(payload) {
      if (this.isSafe(payload)) {
        this.setAppState(['nativeAppVersion', payload]);
        // this.$log.info('SDK: appInfoListener', payload);
      }
    },
    tripDataInfoListener(payload) {
      if (this.isSafe(payload)) {
        this.setAppState(['tripDataSdkVersion', payload]);
        // this.$log.info('SDK: tripDataInfoListener', payload);
      }
    },
    sdkActivatedListener(payload) {
      if (this.isSafe(payload)) {
        const isActive = payload === 'true';

        this.setAppState(['hasSdkActivated', isActive]);
        // this.$log.info('SDK: sdkActivatedListener', payload);
      }
    },
    async languageListener(lang) {
      if (this.isSafe(lang)) {
        await this.$store.dispatch('user/handleLanguagePreference', lang);
      }
    },
    batterySavingModesListener(payload) {
      this.$log.info('SDK: batterySavingModesListener', payload);
      if (this.isSafe(payload)) {
        try {
          const parsedPayload = JSON.parse(payload);

          if (parsedPayload.error) {
            throw new Error(parsedPayload.error);
          }

          this.$log.info('SDK: batterySavingModesListener parsed', parsedPayload);

          this.setAppState(['batterySavingModes', parsedPayload]);
        } catch (error) {
          this.sentryCaptureException(error);
        }
      }
    },
    userLocationListener(payload) {
      this.$log.info('SDK: userLocationListener', payload);

      if (this.isSafe(payload)) {
        try {
          const parsedPayload = JSON.parse(payload);

          if (parsedPayload.error) {
            throw new Error(parsedPayload.error);
          }

          this.$log.info('SDK: userLocationListener parsed', parsedPayload);

          this.setUserState(['userLocation', parsedPayload]);
        } catch (error) {
          switch (error.message || error) {
            case 'permission_required':
              /**
               * since permission is required, do not take any action
               * @todo
               * perhaps once the permission is given dispatch `requestUserLocation`
               * to have necessary information when requesting location based challenges
               */
              // this.$log.info('SDK: userLocationListener permission_required');
              this.sentryCaptureException(error);
              break;

            case 'general_error':
              /**
               * @todo
               * Discuss a retry policy
               */
              // this.$log.info('SDK: userLocationListener general_error');
              this.sentryCaptureException(error);
              break;

            default:
              // this.$log.info('SDK: userLocationListener default');
              this.sentryCaptureException(error);
              break;
          }
        }
      }
    },
    locationPermissionListener(payload) {
      if (this.isSafe(payload)) {
        this.$log.info('SDK: location Permission:', payload);
        this.setAppState(['locationPermission', payload]);
      }
    },
    motionPermissionListener(payload) {
      if (this.isSafe(payload)) {
        this.$log.info('SDK: motion Permission:', payload);
        this.setAppState(['motionPermission', payload]);
      }
    },
    notificationPermissionListener(payload) {
      if (this.isSafe(payload)) {
        this.$log.info('SDK: notification Permission:', payload);
        this.setAppState(['notificationPermission', payload]);
      }
    },
    async notificationListener(payload) {
      if (this.isSafe(payload)) {
        this.$log.info('SDK: notificationListener:', payload);

        try {
          const notification = JSON.parse(payload);
          this.pushToNotificationsQueue(notification);

          /**
           * Fetch fresh messages and achievements because a push notification was received by the SDK.
           *
           * Mark the notification message as read & delivered in case it exists in the response.
           * Mind that /getMessages only returns the inbox messages, and notification may not have
           * a message id. Meaning there might be no message to mark when only a push is broadcasted
           * without any inbox message.
           */

          await this.$store.dispatch('achievements/getPlayerAchievements');

          if (notification.meta.messageId) {
            /**
             * @todo Perhaps we could skip the first request to getMessages?
             */
            await this.$store.dispatch('social/getMessages');
            await this.$store.dispatch('social/markMessage', { id: notification?.meta?.messageId });
            await this.$store.dispatch('social/getMessages');
          }
        } catch (error) {
          this.sentryCaptureException(error);
        }
      }
    },
    contactsPermissionListener(payload) {
      if (this.isSafe(payload)) {
        this.$log.info('SDK: contacts Permission:', payload);
        this.setAppState(['contactsPermission', payload]);

        /** Check existance of user to supress potential errors
         * getFriendsLeaderboard action below needs User to be present
         * When web-app launched in native apps, sometimes getUser call is not finished yet
         * and contactsPermissionListener called by the SDK
         * which results in getFriendsLeaderboard failure
         */

        // TODO: why contactsUploadConsent is required?!
        // https://ngti.atlassian.net/browse/SCC-566?focusedCommentId=111709
        if (
          payload === 'true'
          && this.user
          && (this.user.metaPublic.lastContactUploadTimestamp
            || this.appState.contactsUploadConsent)
        ) {
          this.$log.info('Contacts Permission Listener: will call Upload & getFriendsLeaderboard');
          this.$store.dispatch('user/uploadContacts');
          this.$store.dispatch('social/getFriendsLeaderboard');
        }

        // this.$log.info('Contacts Permission Listener: will call social/getContactsIfNecessary');
        // this.$store.dispatch('social/getContacts');
      }
    },
    cellularTripDataUploadListener(payload) {
      if (this.isSafe(payload)) {
        this.setAppState(['cellularTripDataUpload', payload]);
        // this.$log.info('SDK: cellularTripDataUpload ---->', payload);
      }
    },
    tripDataRegisteredListener(payload) {
      if (this.isSafe(payload)) {
        // TODO: Add Sentry log here to track if user was successfuly registered with
        // trip data provider aka motion tag

        // this.setUserState(['isRegisteredInTripDataProvider', payload]);
        this.$log.info('SDK: isRegisteredInTripDataProvider:', payload);
      }
    },
    reportNativeErrorListener(message, level, sdkData) {
      this.$log.info('SDK: reportNativeErrorListener received:', message, level, sdkData);

      if (this.isSafe([message, level, sdkData])) {
        this.sentryConfigureScope((scope) => {
          scope.setExtra('sdkData', sdkData);

          scope.setTag('sdk_data', sdkData);

          this.sentryCaptureMessage(`SDK: ${message}`, level);

          this.$log.info('SDK: reportNativeErrorListener dispatched:', message);
        });
      }
    },
    viewWillBeShown(appId, userLanguage, os, osVersion) {
      const { appState } = this;

      this.$log.info('SDK: viewWillBeShown:', appState.appId);
      this.setAppState(['viewWillBeShown', true]);

      if (this.user) {
        // @todo this could be moved into settings
        this.$store.dispatch('user/shouldCompanyUserBeLedToGroups').then((response) => {
          this.$log.info('shouldCompanyUserBeLedToGroups', response);

          if (response) {
            this.$root.$emit('show-lead-to-groups-modal');
          }
        });

        // Following calls are necessary keep certain screen updated
        this.$store.dispatch('achievements/getPlayerChallenges');
        this.$store.dispatch('achievements/getPlayerAchievements');
        this.$store.dispatch('social/getGroupsLeaderboard');
      }

      this.setAppState(['sessionStarted', true]);

      if (this.isSafe([appId, userLanguage, os, osVersion])) {
        this.setAppState(['appId', appId]);
        this.setAppState(['os', os]);
        this.setAppState(['osVersion', osVersion]);
        // this.$store.dispatch('user/handleLanguagePreference', userLanguage);

        axiosInstance.defaults.params = {};
        axiosInstance.defaults.params.appId = appId;
      }
    },
    viewWillBeHidden() {
      this.setAppState(['viewWillBeShown', false]);
      this.setAppState(['sessionStarted', false]);
      this.$log.info('SDK: viewWillBeHidden');
    },
    gpsListener(payload) {
      if (this.isSafe(payload)) {
        this.setAppState(['gps', payload]);
      }
    },
    preciseLocationListener(payload) {
      if (this.isSafe(payload)) {
        this.setAppState(['preciseLocation', payload]);
      }
    },
    screenOrientation(orientation) {
      if (this.isSafe(orientation)) {
        this.setAppState(['screenOrientation', orientation]);
      }
    },
    /**
     * @todo Verify the behaviour on Android
     */
    onBackPressed() {
      this.$log.info('SDK: onBackPressed');
      this.$root.$emit('on-back-pressed');

      const { name } = this.$route;

      const { appId, routingFrom } = this.appState;

      const nextRoute = getBackRoute(
        this.$route,
        routingFrom.name,
        this.isUserAuthenticated,
        this.historyState,
      );

      if (nextRoute) {
        // close local modals if any visible
        // this.setAppState(['isModalVisible', false]);
        // Handle possible routes in `getBackRoute` from utils
        return this.$router.push({ name: nextRoute });
      }
      // Fallback
      switch (name) {
        case 'start':
        case 'footprint':
        case 'home':
          if (appId !== 'web') {
            this.callSdk('action=close');
          }
          break;
        default:
          this.$router.back();
      }

      return false;
    },
    contactsUploadListener(payload) {
      if (this.isSafe(payload)) {
        this.$log.info('SDK: contactsUploadListener ---->', payload);

        const { phonebook } = this.kibanaEvents;
        let status = payload;

        if (status[0] === '2') {
          status = '2XX';
        } else if (status[0] === '3') {
          status = '3XX';
        } else if (status[0] === '4' && status !== '403') {
          status = '4XX';
        } else if (status[0] === '5') {
          status = '5XX';
        }

        // TODO: I think there is an issue here, the event is sent on a fresh app start
        if (status[0] !== '2') {
          this.sentryCaptureMessage(`SDK: contactUpload with status code: ${status}`, 'error');
        }

        switch (status) {
          case '2XX':
            // Success
            this.$store.dispatch('user/setContactUploadTimestamp');
            this.setAppState(['contactsUploaded', true]);

            this.$store.dispatch('common/insertEvent', {
              category: phonebook.category,
              action: phonebook.uploaded.action,
            });
            break;
          case '3XX':
            // Allow Retry
            this.setAppState(['contactsUploaded', 'allowRetry']);
            break;
          case '403':
            // Refresh Token and Allow Retry
            this.setAppState(['contactsUploaded', 'allowRetry']);
            break;
          case '4XX':
            // Eventual retry
            break;
          case '5XX':
            // Eventual retry
            break;
          case 'timeout':
            // Allow Retry
            this.setAppState(['contactsUploaded', 'allowRetry']);
            break;
          case 'permission_required':
            // Suggest Permission and Allow Retry
            this.setAppState(['contactsPermission', 'false']);
            this.setAppState(['contactsUploaded', false]);
            break;
          case 'network_error':
            // Allow Retry
            this.setAppState(['contactsUploaded', 'allowRetry']);
            break;
          case 'general_error':
            // Eventual retry
            break;
          default:
            break;
        }
      }
    },
    contactNames(requestId, payload) {
      if (this.isSafe([requestId, payload])) {
        // TODO: Talk with Ronnie or Mark on why we receive this data every time
        // Like when pulling notification center down, triggers it
        // If not needed, there is no need to use resources for it.

        const contactNames = payload.split(',').filter(name => name?.length > 0);
        this.contactNamesHandler(requestId, contactNames);
      }
    },
    contactNamesJson(requestId, payload) {
      if (this.isSafe([requestId, payload])) {
        // TODO: Talk with Ronnie or Mark on why we receive this data every time
        // Like when pulling notification center down, triggers it
        // If not needed, there is no need to use resources for it.

        const contactNames = JSON.parse(payload).filter(name => name?.length > 0);
        this.contactNamesHandler(requestId, contactNames);
      }
    },
    async accessTokenListener(accessToken) {
      try {
        if (this.isSafe(accessToken)) {
          // this.$log.info('SDK: accessTokenListener ---->', accessToken);
          if (accessToken && isValidIssuer(accessToken)) {
            this.$log.info('SDK: accessTokenListener');

            await this.$store.dispatch('user/authenticate', accessToken);

            this.setAppState(['queryDate', new Date().getTime()]);
          } else {
            this.$log.info('No accessToken was provided, will call user/signOut');
            this.$store.dispatch('user/signOut');
          }
        }
      } catch (err) {
        this.$log.warn('SDK: accessTokenListener error', err);
      }
    },
    signOut() {
      this.$log.info('SDK: signOut: Unnecessary native to web call');
    },
    lastTransmission(payload) {
      if (this.isSafe(payload)) {
        // eslint-disable-next-line radix
        this.setAppState(['lastTransmission', parseInt(payload)]);
      }
    },
    lastFailedTransmission() {
      this.$log.info('SDK: lastFailedTransmission: Unnecessary native to web call');
    },
    contacts(responseId, payload) {
      this.$log.info('SDK: Contacts: incoming payload', payload);

      if (this.isSafe([responseId, payload])) {
        const { isDev, isDevelopment, isTesting } = this.appState;
        const requestId = this.userState?.phonebook?.id;

        const isAllowed = (isDev && requestId)
          || (isTesting && requestId)
          || (isDevelopment && requestId)
          || responseId === requestId;

        if (isAllowed) {
          // const mockData = '{"contacts":[{"id":"2C355353-1596-4ED2-9B04-F35C38ACF5B5","name":"Okke van't Verlaat","uris":["tel:+31625008933"]}]}';

          const { contacts } = JSON.parse(payload);
          this.$log.info('SDK: Contacts: incoming payload', payload);

          const phonebookObject = { ...this.userState.phonebook, contacts };

          this.$log.info('SDK: setUserState/phonebook', phonebookObject);

          this.setUserState(['phonebook', phonebookObject]);
        }
      }
    },
    contactNamesHandler(requestId, contactNames) {
      const { isDev, isDevelopment, isTesting } = this.appState;

      if (
        (isDev && this.leaderboardState?.friends?.id)
        || (isDevelopment && this.leaderboardState?.friends?.id)
        || (isTesting && this.leaderboardState?.friends?.id)
        || requestId === this.leaderboardState?.friends?.id
      ) {
        this.$log.info('SDK: contactNames, will setContactsLeaderboardState:', contactNames);

        const leaderboard = cloneDeep(this.leaderboardState);

        const { currentData } = leaderboard.friends;

        currentData.data = currentData.data.map((element, index) => {
          const object = element;
          if (!object.self) {
            object.contactName = contactNames[index];
          }
          return object;
        });

        this.setSocialState(['leaderboardState', leaderboard]);
        this.$store.dispatch('social/serializeLeaderboardState', {
          type: leaderboard.friends.currentData.name,
        });

        // Since we received a payload from the SDK, we're ready to build the DOM elements
        // So we emit an event to indicate the request
        // TODO: Re consider the necessity of this, this should go away
        this.$root.$emit('createHoverCardObserver');
      }

      if (
        (isDev && this.userState?.contacts?.id)
        || (isDevelopment && this.userState?.contacts?.id)
        || (isTesting && this.userState?.contacts?.id)
        || requestId === this.userState?.contacts?.id
      ) {
        // this.$log.info('SDK: contactNames, will setContactsUserState:', contactNames);

        const data = this.userState.contacts.data.map((element, index) => ({
          ...element,
          name: contactNames[index],
        }));

        const contactsObject = { ...this.userState.contacts, contactNames, data };

        this.$log.info('SDK: setUserState/contacts:', contactsObject);

        this.setUserState(['contacts', contactsObject]);
      }
    },
    isSafe(payload) {
      // We should always check if incoming payload isSafe
      // only accept string to avoid any unexpected injections
      if (Array.isArray(payload)) {
        return payload.every(element => typeof element === 'string' || element instanceof String);
      }
      return typeof payload === 'string' || payload instanceof String;
    },
  },
  mounted() {
    // these methods are exposed, so SDK can send messages to the app
    // More details @ https://wiki.swisscom.com/display/SCCD/Web+-+Native+Communication
    window.sdkInfoListener = this.sdkInfoListener;
    window.appInfoListener = this.appInfoListener;
    window.tripDataInfoListener = this.tripDataInfoListener;
    window.languageListener = this.languageListener;
    window.sdkActivatedListener = this.sdkActivatedListener;
    window.userLocationListener = this.userLocationListener;
    window.locationPermissionListener = this.locationPermissionListener;
    window.motionPermissionListener = this.motionPermissionListener;
    window.contactsPermissionListener = this.contactsPermissionListener;
    window.notificationPermissionListener = this.notificationPermissionListener;
    window.notificationListener = this.notificationListener;
    window.tripDataRegisteredListener = this.tripDataRegisteredListener;
    window.cellularTripDataUploadListener = this.cellularTripDataUploadListener;
    window.reportNativeErrorListener = this.reportNativeErrorListener;
    window.viewWillBeShown = this.viewWillBeShown;
    window.viewWillBeHidden = this.viewWillBeHidden;
    window.gpsListener = this.gpsListener;
    window.preciseLocationListener = this.preciseLocationListener;
    window.screenOrientation = this.screenOrientation;
    window.onBackPressed = this.onBackPressed;
    window.lastTransmission = this.lastTransmission;
    window.contactsUploadListener = this.contactsUploadListener;
    window.contactNames = this.contactNames;
    window.contactNamesJson = this.contactNamesJson;
    window.signOut = this.signOut;
    window.lastFailedTransmission = this.lastFailedTransmission;
    window.accessTokenListener = this.accessTokenListener;
    window.contacts = this.contacts;
    window.batterySavingModesListener = this.batterySavingModesListener;
  },
};
