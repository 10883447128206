<template>
  <cc-view
    :id="id"
    >
    <cc-wrapper class="h-100 text-center">
      <cc-heading class="pb-6">
        Page not found
      </cc-heading>
      <cc-button @click="$router.push({ name: 'footprint' }).catch(() => {})">
        Go back Home
      </cc-button>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccButton from '@/components/primitives/Button.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccButton,
  },
  data() {
    return {
      id: 'page-not-found',
    };
  },
};
</script>
