<template>
  <cc-view
    :id="id"
    class="bg-blue-dark"
    >
    <cc-wrapper class="h-100">
      <cc-heading style="padding-bottom: 200px;">
        Swiss Climate Challenge
      </cc-heading>

      <code
        v-if="false"
        class="font-size-12 text-center"
        >
        isLoggedIn: {{ userState.isLoggedIn }}
        <br>
        accessToken: {{ !!userState.accessToken }}
        <br>
        user.id: {{ userState.user.id }}
        <br>
        viewWillBeShown: {{ viewWillBeShown }}
        <br>
        <button @click="$router.push({ name: 'footprint' })">
          manual push
        </button>
      </code>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import sandbox from '@/mixins/sandbox';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
  },
  mixins: [sandbox],
  data() {
    return {
      id: 'home',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      uiState: state => state.common.uiState,
    }),
    ...mapGetters({
      calcViewHeight: 'common/calcViewHeight',
      getTabBarEl: 'common/getTabBarEl',
      viewWillBeShown: 'common/viewWillBeShown',
      hasTappedNotification: 'social/hasTappedNotification',
    }),
  },
  watch: {
    viewWillBeShown: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$log.info('Starting the app through SDK', val);
          this.pushWithDelay();
        }
      },
    },
  },
  mounted() {
    // this.$log.info('Mounted:', this.id);

    this.sendMessageToHost('SCC:auth');

    if (this.appState.appId === 'web') {
      this.pushWithDelay();
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUiState: 'common/setUiState',
    }),
    pushWithDelay() {
      // if (!this.hasTappedNotification) {
        setTimeout(() => {
          this.$log.info('will push to footprint');
          this.$router.push({ name: 'footprint' }).catch(() => {});
        }, 1500);
      },
    // },
  },
};
</script>
