<template>
  <cc-card
    v-if="isShown"
    :id="id"
    :ruid="id"
    :inner-title="$t('emailVerificationReminderTitle')"
    button-variant="small"
    :pressable="true"
    :button-label="$t('ittigenCardAction')"
    class="pb-4"
    @click.native="onVerify"
    >
    <template #body>
      <cc-wrapper
        direction="row"
        justify="start"
        >
        <div
          style="flex: 0 0 60px;"
          >
          <cc-svg-base
            svg="illustrations/people-with-exclamation-mark"
            />
        </div>

        <cc-text
          variant="body"
          class="pl-4"
          >
          {{
            $t('emailVerificationReminderBody')
          }}
        </cc-text>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import { mapGetters } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';

import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccCard,
    ccSvgBase,
    ccWrapper,
    ccText,
  },
  data() {
    return {
      id: 'email-verification-reminder-card',
    };
  },
  computed: {
    ...mapGetters({
      getUserEmail: 'user/getUserEmail',
      getUserCompanyTagId: 'user/getUserCompanyTagId',
    }),
    isShown() {
      return !this.getUserEmail;
    },
  },
  methods: {
    onVerify() {
      this.$router
        .push({
          name: 'settings',
          params: {
            showAddEmailAddressModal: true,
          },
        })
        .catch(() => {});
    },
  },
};
</script>
