
import auth from '@/services/auth';
import trips from '@/services/trips';
import social from '@/services/social';
import groups from '@/services/groups';
import company from '@/services/company';
import common from '@/services/common';
import achievements from '@/services/achievements';


export default {
  auth,
  trips,
  social,
  groups,
  company,
  common,
  achievements,
};
