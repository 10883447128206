<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="$t('groupLeaderboardCardTitle')"
    :button-variant="getButtonProps.variant"
    :button-label="getButtonProps.label"
    :has-button="hasGroupLeaderboard"
    :padded="false"
    :pressable="true"
    class="pb-4"
    @click.native="clickHandler"
    >
    <template
      #body
      >
      <div
        v-if="!hasGroupLeaderboard && isLoading"
        class="d-flex justify-content-center align-items-center"
        >
        <ccLottie
          id="activityIndicator"
          class="w-25"
          :options="lottieOptions"
          wrapper-class="p-5"
          />
      </div>

      <cc-wrapper
        v-else-if="hasGroupLeaderboard && (userGroups && userGroups.length)"
        direction="column"
        align="start"
        >
        <cc-leaderboard-list-item
          v-for="(group, index) in userGroups"
          :key="index"
          type="widget"
          :rank="group.curPosition"
          :rank-class="getRankClass(group.curPosition)"
          :prev-rank="group.prevPosition"
          :icon="getCupIcon(group.curPosition)"
          :name="group.name"
          :score="getEmissionText(group.score, true)"
          :widget="true"
          @click.native="clickHandler(group)"
          />
      </cc-wrapper>

      <cc-wrapper
        v-else-if="allDidNotTravel"
        direction="row"
        align="center"
        class="flex-wrap"
        >
        <cc-wrapper direction="row">
          <div class="pr-5">
            <cc-svg-base svg="illustrations/hero-groups" />
          </div>

          <cc-text color="muted">
            {{ allDidNotTravelText }}
          </cc-text>
        </cc-wrapper>
      </cc-wrapper>

      <cc-wrapper
        v-else
        direction="row"
        align="start"
        class="flex-wrap"
        >
        <cc-wrapper direction="row">
          <div class="pr-5">
            <cc-svg-base svg="illustrations/hero-groups" />
          </div>

          <cc-text color="muted">
            {{ $t('groupLeaderboardCardDescription') }}
          </cc-text>
        </cc-wrapper>
      </cc-wrapper>

      <template v-if="!isLoading">
        <cc-divider
          class="my-4"
          style="flex-basis: 100%;"
          />
        <cc-card
          class="w-100"
          :class="hasGroupLeaderboard ? 'pl-4' : false"
          svg="icons/icon-create-group"
          :inner-text="$t('groupLeaderboardStartAGroup')"
          :button-label="$t('txtCreate')"
          :has-button="true"
          :has-shadow="false"
          variant="inline"
          @click.native="createGroupHandler"
          />
      </template>
    </template>
  </cc-card>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccLottie from '@/components/Lottie.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccDivider from '@/components/primitives/Divider.vue';

import LoadingLeaderboard from '@/components/lottie/loading-leaderboards.json';

export default {
  components: {
    ccCard,
    ccWrapper,
    ccText,
    ccDivider,
    ccSvgBase,
    ccLottie,
    ccLeaderboardListItem,
  },
  data() {
    return {
      id: 'group-leaderboard-card',
      userGroups: null,
      lottieOptions: {
        animationData: LoadingLeaderboard,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
      getUserGroups: 'social/getUserGroups',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    allDidNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelYetLeaderboards');
      }

      return this.$t('didNotTravelLeaderboards');
    },
    allDidNotTravel() {
      const didNotTravelLeaderboard = this.leaderboardState?.groups?.currentData?.didNotTravel;
      const didTravelLeaderboard = this.leaderboardState?.groups?.currentData?.didTravel;

      if (
        didNotTravelLeaderboard?.length > 0
        && (!didTravelLeaderboard || didTravelLeaderboard?.length === 0)
      ) {
        return true;
      }

      return false;
    },
    getButtonProps() {
      const { hasGroupLeaderboard } = this;

      if (hasGroupLeaderboard) {
        return {
          label: this.$t('txtView'),
          variant: 'small-muted',
        };
      }

      return {
        label: this.$t('txtStart'),
        variant: 'small',
      };
    },
  },
  async mounted() {
    const { groups } = this.leaderboardState;
    const refreshTime = 900000;
    const shouldDispatch = !groups || Date.now() - refreshTime > groups.timestamp;

    if (shouldDispatch) {
      await this.$store.dispatch('social/getGroupsLeaderboard');
    }

    this.userGroups = this.getUserGroups().slice(0, 3);

    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async clickHandler() {
      if (!this.appState.isLoading) {
        if (!this.hasGroupLeaderboard) {
          this.$router
            .push({ name: 'join-group-leaderboard', params: { createGroup: true } })
            .catch(() => {});
        } else {
          this.$router.push({ name: 'group-leaderboard' }).catch(() => {});
        }
      }
    },
    async createGroupHandler() {
      if (!this.appState.isLoading) {
        if (!this.hasGroupLeaderboard) {
          this.$router
            .push({ name: 'join-group-leaderboard', params: { createGroup: true } })
            .catch(() => {});
        } else {
          this.$router
            .push({ name: 'group-leaderboard', params: { createGroup: true } })
            .catch(() => {});
        }
      }
    },
  },
};
</script>
