<template>
  <div>
    <div
      id="horizontalThermometer"
      ref="horizontalThermometer"
      class="d-flex justify-content-around horizontal-thermometer py-3 rounded-24"
      >
      <div
        id="first"
        class="text-black font-size-12 font-weight-bold opacity-30"
        >
        0°C
      </div>
      <div class="text-black font-size-12 font-weight-bold opacity-30">
        +1°C
      </div>
      <div class="text-black font-size-12 font-weight-bold opacity-30">
        +2°C
      </div>
      <div class="text-black font-size-12 font-weight-bold opacity-30">
        +3°C
      </div>
      <div
        id="last"
        class="text-black font-size-12 font-weight-bold opacity-30"
        >
        +4°C
      </div>
    </div>
    <div class="h-temperature-container">
      <div
        id="htempcontainer"
        ref="htempcontainer"
        >
        <div
          style="transition: 1s all"
          :style="{'clip-path' : (`circle(5px at ${(6 + left)}px)`),
                   '-webkit-clip-path' : (`circle(5px at ${(6 + left)}px)`)
          }"
          class="h-temperature-ball"
          >
          <div class="h-temperature-ball-background" />
        </div>
        <div
          class="h-temperature-indicator d-flex flex-column align-items-center"
          >
          <div
            :style="{ 'left': `${left}px`, 'transition': 'left 1s', }"
            class="h-temperature-line"
            />
          <div
            :style="{ 'left': `${left}px`, 'transition': 'left 1s', }"
            class="h-temperature-text font-size-18 font-weight-xbold"
            >
            +{{ temperature.toFixed(2) }}°C
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      offsetLeft: 0,
      distanceBetweenFirstAndLast: 0,
    };
  },
  computed: {
    ...mapState({
      temperature: state => state.trips.temperature,
    }),
    left() {
      const ratio = 0 + this.temperature / 4;
      const response = this.offsetLeft + ratio * this.distanceBetweenFirstAndLast;

      return response;
    },
  },
  mounted() {
    window.addEventListener('resize', this.calculatePosition);

    this.calculatePosition();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculatePosition);
  },
  methods: {
    calculatePosition() {
      const last = document.getElementById('last');
      const first = document.getElementById('first');
      const horizontalThermometer = document.getElementById(
        'horizontalThermometer',
      );

      this.distanceBetweenFirstAndLast = last.offsetLeft - first.offsetLeft + first.offsetWidth / 2;

      this.offsetLeft = first.offsetLeft - horizontalThermometer.offsetLeft;
    },
  },
};
</script>
