<template>
  <cc-wrapper
    :justify="justify"
    :align="align"
    class="pb-2 pt-4"
    >
    <div>
      <cc-svg-base
        v-if="svg"
        :svg="svg"
        />
    </div>
    <cc-heading
      v-if="title"
      weight="bold"
      class="py-2"
      :align="headingAlign"
      >
      {{ title }}
    </cc-heading>
  </cc-wrapper>
</template>

<script>
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccWrapper,
    ccHeading,
    ccSvgBase,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    svg: {
      type: String,
      required: false,
      default: null,
    },
    justify: {
      type: String,
      required: false,
      default: 'center',
    },
    align: {
      type: String,
      required: false,
      default: 'center',
    },
    headingAlign: {
      type: String,
      required: false,
      default: 'center',
    },
  },
};
</script>
