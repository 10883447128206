<template>
  <div>
    <transition-group name="transport-card-slide-left">
      <div
        v-for="(timeline, index) in splittedTimeline"
        :key="index + timeline[0] && timeline[0].startTime"
        >
        <div
          v-if="!isJourney && timeline[0]?.startTime"
          :class="hasHeader(index) ? 'pb-2': ''"
          >
          <span
            v-if="hasHeader(index)"
            class="font-size-14 text-white font-weight-bold text-capitalize"
            >
            {{
              $moment(timeline[0]?.startTime).calendar(null, {
                sameDay: `[${$t('Today')}]`,
                lastDay: `[${$t('Yesterday')}]`,
                lastWeek: `${$t('LastDDDD')}`,
                sameElse: 'DD/MM/YYYY',
              })
            }}
          </span>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column flex-grow-1">
            <div
              v-for="(trip, tripIndex) in timeline"
              :key="`card-wrapper-${tripIndex}`"
              :data-ruid="`trip__card-${tripIndex}`"
              class="d-flex"
              >
              <div
                v-if="isJourney"
                :id="`timestamps-${tripIndex}`"
                class="d-flex justify-content-between flex-wrap first-column text-blue-lightest
                font-size-14 line-height"
                >
                <div
                  v-if="tripIndex === 0"
                  :key="`endTime-${tripIndex}`"
                  data-ruid="trip__endTime"
                  style="margin-top: -5px"
                  :class="firstOrLastTimestamp(tripIndex, timeline, true)"
                  >
                  {{ startTime(trip.endTime) }}
                </div>

                <div
                  :key="`startTime-${tripIndex}`"
                  data-ruid="trip__startTime"
                  class="align-self-end"
                  style="margin-bottom: -5px"
                  :class="firstOrLastTimestamp(tripIndex, timeline, false)"
                  >
                  {{ startTime(trip.startTime) }}
                </div>
              </div>

              <div
                :id="`stops-${tripIndex}`"
                class="d-flex flex-column justify-content-between align-items-center second-column"
                >
                <div
                  v-if="tripIndex === 0"
                  :key="`dot-${tripIndex}`"
                  :class="indicatorColor(trip.meta.emissionType)"
                  class="timeline-dot"
                  />
                <div
                  :key="`line-${tripIndex}`"
                  :class="indicatorColor(trip.meta.emissionType)"
                  class="timeline-line"
                  />
                <div
                  :key="`dot1-${tripIndex}`"
                  :class="indicatorColor(trip.meta.emissionType)"
                  class="timeline-dot"
                  />
              </div>

              <div
                id="trip-card"
                :key="`card-${tripIndex}`"
                class="d-flex flex-row flex-grow-1 bg-blue-lighter-30 pl-4 align-items-center
                justify-content-between"
                :class="firstOrLast(tripIndex, timeline)"
                >
                <div>
                  <div
                    class="d-flex flex-column align-items-center"
                    style="width: 30px; transform: scale(0.85)"
                    >
                    <cc-svg-base
                      :key="trip.transitMode"
                      :color-class="indicatorColorOfIcon(trip.meta.emissionType)"
                      :svg="`transport-icons/${
                        clearOptionTransitMode(displayString(trip.displayType))}`"
                      />
                  </div>
                </div>

                <div class="d-flex flex-column justify-content-center w-100 pl-4 py-2">
                  <h5 class="font-weight-bold timeline-trip-title">
                    {{ $t(`${displayString(trip.displayType)}Txt`) }}
                  </h5>

                  <span
                    v-if="false"
                    class="font-size-14 text-white timeline-trip-description line-height pt-1"
                    >
                    {{ $moment(trip.endTime).format('LL') }}
                  </span>

                  <span
                    v-if="!isJourney"
                    class="font-size-14 text-blue-lightest timeline-trip-description line-height
                    pt-1"
                    >
                    {{ startTime(trip.startTime) }} - {{ startTime(trip.endTime) }}
                  </span>
                  <span
                    v-else
                    class="font-size-14 text-blue-lightest timeline-trip-description line-height
                    pt-1"
                    >
                    {{ elapsedTime(trip.startTime, trip.endTime) }}
                    , {{ distanceText(trip.distance) }}
                  </span>
                </div>

                <div
                  v-if="!hasTripCorrectionAction"
                  class="d-flex flex-column mr-5 font-size-14 text-nowrap"
                  >
                  {{ distanceText(trip.distance) }}
                </div>

                <div
                  v-if="isJourney && isCompanyUser"
                  class="d-flex flex-column pl-2"
                  data-ruid="trip__purpose_button-edit"
                  @click="
                    (isTripPurposeModalDisplayed = true),
                    (tripPurposeModalData = trip) "
                  >
                  <cc-svg-base
                    data-ruid="trip_purpose"
                    :svg="`trip-purpose/${ tripPurposeNameHandler(trip.purpose)}`"
                    :class="tripPurposeColor(tripPurposeNameHandler(trip.purpose))"
                    />
                </div>

                <div
                  v-if="hasTripCorrectionAction"
                  class="d-flex flex-column px-5"
                  data-ruid="trip__button-edit"
                  @click="
                    (isReportTripCorrectionModalDisplayed = true),
                    (reportTripCorrectionModalData = trip)
                  "
                  >
                  <cc-svg-base
                    :key="trip.transitMode"
                    svg="icons/icon-more"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="splittedTimeline.length - 1 !== index"
          class="d-flex"
          >
          <div
            v-if="isJourney"
            class="first-column"
            />
          <div class="d-flex flex-column align-items-center second-column">
            <div
              v-for="seperatorIndex in 7"
              :key="seperatorIndex"
              class="timeline-seperator bg-blue-lighter"
              :class="seperatorIndex !== 7 ? 'mb-1' : ''"
              />
          </div>
        </div>
      </div>
    </transition-group>

    <cc-report-trip-correction-modal
      v-if="isReportTripCorrectionModalDisplayed"
      :data="reportTripCorrectionModalData"
      @after-leave="($event) => $emit($event)"
      @close-modal="isReportTripCorrectionModalDisplayed = false"
      />
    <cc-report-trip-purpose-modal
      v-if="isTripPurposeModalDisplayed"
      :data="tripPurposeModalData"
      @after-leave="($event) => $emit($event)"
      @close-modal="isTripPurposeModalDisplayed = false"
      />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { getDefaultEmissionType } from '@/mixins/transportTypes';

import ccSvgBase from '@/components/SvgBase.vue';

import ccReportTripCorrectionModal from '@/components/modals/ReportTripCorrectionModal.vue';
import ccReportTripPurposeModal from '@/components/modals/ReportTripPurposeModal.vue';
import { tripPurposeNameHandler } from '@/mixins/utils';

export default {
  components: {
    ccSvgBase,
    ccReportTripCorrectionModal,
    ccReportTripPurposeModal,
  },
  props: {
    isJourney: {
      type: Boolean,
      required: false,
      default: false,
    },
    trips: {
      type: Array,
      required: false,
      default: null,
    },
    hasTripCorrectionAction: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      timelineObjects: [],
      isReportTripCorrectionModalDisplayed: false,
      isTripPurposeModalDisplayed: false,
      reportTripCorrectionModalData: null,
      tripPurposeModalData: null,
    };
  },
  computed: {
    ...mapState({
      timeline: state => state.trips.timeline,
      tripCorrections: state => state.trips.tripCorrections,
    }),
    ...mapGetters({
      isCompanyUser: 'user/isCompanyUser',
      hasFeatureTagCommutePurpose: 'user/hasFeatureTagCommutePurpose',
    }),
    splittedTimeline() {
      const sortedList = cloneDeep(this.sortedTimelineObjects());
      if (sortedList) {
        const sortedListCopy = sortedList.slice(0);
        const splittedTimeline = [];
        const splittingThreshold = 15 * 60000;
        const sortedListLength = sortedList.length;
        let splitIndex = 1;

        if (sortedListCopy.length > 1) {
          sortedListCopy.forEach((timelineObject, index) => {
            if (index > 0) {
              if (index === sortedListLength - 1) {
                if (
                  this.$moment(sortedListCopy[index - 1].startTime)
                    - this.$moment(timelineObject.endTime)
                  <= splittingThreshold
                ) {
                  splitIndex += 1;
                }
                splittedTimeline.push(sortedList.splice(0, splitIndex));
                if (sortedList.length !== 0) {
                  splittedTimeline.push(sortedList);
                }
              } else if (
                this.$moment(sortedListCopy[index - 1].startTime)
                  - this.$moment(timelineObject.endTime)
                <= splittingThreshold
              ) {
                splitIndex += 1;
              } else {
                splittedTimeline.push(sortedList.splice(0, splitIndex));
                splitIndex = 1;
              }
            }
          });

          // this.$log.info(splittedTimeline);
          return splittedTimeline;
        }
        splittedTimeline.push(sortedListCopy);
        return splittedTimeline;
      }
      return null;
    },
  },
  mounted() {
    this.$watch(
      'timeline',
      (val) => {
        this.$log.warn('Watcher: Day Timeline');
        if (val !== undefined) {
          const { timeline } = this;
          this.timelineObjects = cloneDeep(
            this.trips || timeline?.[timeline?.currentPeriod]?.trips,
          );
        }
      },
      { immediate: true },
    );
  },
  created() {
    // this.$log.info('Created DayTimeline');
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    tripPurposeNameHandler,
    clearOptionTransitMode(text) {
      return text?.split('Medium')?.join('').split('Compact')?.join('');
    },
    tripPurposeColor(type) {
      if (type === 'private') return 'text-blue-lighter';
      if (type === 'commute') return 'text-blue-light';
      return 'text-blue-darkest';
    },
    displayString(displayType) {
      const str = `${displayType.transitMode}_${displayType.emissionType}`;
      return str;
    },
    sortedTimelineObjects() {
      const sortedList = this.timelineObjects;

      if (sortedList) {
        sortedList.sort((a, b) => this.$moment(a.startTime) - this.$moment(b.startTime));

        for (let i = sortedList.length - 1; i >= 0; i -= 1) {
          if (sortedList[i].distance === 0 && sortedList[i].transitMode === 'stay') {
            sortedList.splice(i, 1);
          } else if (sortedList[i].meta.emissionType === 'default') {
            const element = { ...sortedList[i] };
            element.meta.emissionType = getDefaultEmissionType(element.transitMode);
            sortedList[i] = element;
          }
        }
        return sortedList.reverse();
      }
      return null;
    },
    startTime(timestamp) {
      const date = this.$moment(timestamp);
      // Hours part from the timestamp
      const hours = `0${date.hours()}`;
      // Minutes part from the timestamp
      const minutes = `0${date.minutes()}`;
      // Seconds part from the timestamp
      // Will display time in 10:30 format
      const formattedTime = `${hours.substr(-2)}:${minutes.substr(-2)}`;
      return formattedTime;
    },
    elapsedTime(startTime, endTime) {
      const endTimeDate = this.$moment(endTime);
      const startTimeDate = this.$moment(startTime);
      const diffMs = endTimeDate - startTimeDate; // milliseconds between startTime and endTime
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

      if (diffMins === 60) {
        diffHrs += 1;
        return `${diffHrs} ${this.$t('txtHr')}`;
      }
      if (diffHrs !== 0) {
        return `${diffHrs} ${this.$t('txtHr')} ${diffMins} min`;
      }
      return `${diffMins} min`;
    },
    firstOrLast(index, array) {
      if (array.length === 1) {
        return 'only';
      }
      if (index === 0) {
        return 'first';
      }
      if (index === array.length - 1) {
        return 'last';
      }
      return 'middle';
    },
    firstOrLastTimestamp(index, array, first) {
      if (array.length === 1) {
        return 'only-timestamp';
      }
      if (index === 0 && first) {
        return 'first-timestamp';
      }
      if (index === array.length - 1) {
        return 'last-timestamp';
      }
      return 'middle-timestamp';
    },
    indicatorColor(emissionType) {
      const eType = this.clearOptionTransitMode(emissionType);
      if (eType === 'fossil' || eType === 'motorbike') {
        return 'bg-danger';
      }
      if (eType === 'electric' || eType === 'hybrid') {
        return 'bg-cc-yellow';
      }
      if (eType === 'human-powered') {
        return 'bg-success';
      }
      return false;
    },
    indicatorColorOfIcon(emissionType) {
      const eType = this.clearOptionTransitMode(emissionType);
      if (eType === 'fossil' || eType === 'motorbike') {
        return 'svg-fill-danger';
      }
      if (eType === 'electric' || eType === 'hybrid') {
        return 'svg-fill-cc-yellow';
      }
      if (eType === 'human-powered') {
        return 'svg-fill-success';
      }
      return false;
    },
    distanceText(distance) {
      if (distance >= 1000) {
        const kilometers = (distance / 1000).toFixed(1);
        return `${kilometers} km`;
      }
      return `${distance.toFixed(0)} m`;
    },
    hasHeader(index) {
      return index > 0
        ? this.$moment(this.splittedTimeline[index][0].startTime).isSame(
            this.$moment(this.splittedTimeline[index > 0 ? index - 1 : 0][0].endTime),
          )
        : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.first {
  margin-bottom: 6px;
  border-radius: 16px 16px 4px 4px;
}

.last {
  margin-top: -4px;
  border-radius: 4px 4px 16px 16px;
}

.only {
  border-radius: 16px 16px 16px 16px;
}

.middle {
  margin-top: -4px;
  margin-bottom: 6px;
  border-radius: 4px 4px 4px 4px;
}

.first-timestamp,
.last-timestamp,
.only-timestamp {
  color: white;
  font-weight: bold;
}

.first-column {
  flex: 0 0 45px;
}

.second-column {
  flex: 0 0 24px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .first-column {
    flex: 0 0 35px;
  }

  .timeline-trip-description {
    font-size: 12px;
  }

  .timeline-trip-title {
    font-size: 14px;
  }

  #trip-card {
    padding-right: 0px !important;
  }
}
</style>
