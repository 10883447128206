/* eslint-disable no-underscore-dangle */
import Chart from 'chart.js';

import { generateChart, mixins } from 'vue-chartjs';
import { mapState } from 'vuex';

const { reactiveProp } = mixins;

Chart.defaults.RoundedBarChart = Chart.helpers.clone(Chart.defaults.bar);

Chart.controllers.RoundedBarChart = Chart.controllers.bar.extend({
  draw(ease) {
    const { ctx } = this.chart.chart;
    const easingDecimal = ease || 1;

    Chart.helpers.each(this.getMeta().data, (bar) => {
      const vm = bar._view;
      const thickness = vm.width / 2;
      const angle = 0;
      if (bar._chart.data.datasets[bar._datasetIndex].data[bar._index] !== 0) {
        ctx.save();
        ctx.fillStyle = vm.backgroundColor;
        ctx.translate(vm.x, vm.y);
        ctx.beginPath();
        ctx.arc(angle, angle, thickness, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }

      bar.transition(easingDecimal).draw();

      if (bar._chart.data.datasets[bar._datasetIndex].data[bar._index] !== 0) {
        ctx.save();
        ctx.fillStyle = vm.backgroundColor;
        ctx.translate(vm.x, bar._yScale.bottom);
        ctx.beginPath();
        ctx.arc(angle, angle, thickness, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
    });
  },
});

const RoundedBarChart = generateChart('rounded-bar', 'RoundedBarChart');

export default {
  extends: RoundedBarChart,
  mixins: [reactiveProp],
  props: ['options'],
  computed: {
    ...mapState({}),
  },
  watch: {
    options() {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
