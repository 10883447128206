<template>
  <div class="d-flex justify-content-between align-items-center py-3">
    <div
      style="flex: 0 0 24px;min-height:24px;"
      class="d-flex"
      >
      <cc-button
        data-ruid="global__button-decrement-date"
        variant="icon"
        icon="icons/icon-arrow-left"
        @click="decrementDate"
        />
    </div>

    <cc-text
      variant="body-bold"
      class="flex-grow-1 text-center"
      >
      {{ formatting }}
    </cc-text>

    <div
      style="flex: 0 0 24px;min-height:24px;"
      class="d-flex"
      >
      <cc-button
        v-if="showIncrementDateWrapper"
        data-ruid="global__button-increment-date"
        variant="icon"
        icon="icons/icon-arrow-right"
        @click="incrementDate"
        />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { showIncrementDate } from '@/mixins/utils';

import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccButton,
    ccText,
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
    }),
    showIncrementDateWrapper() {
      return showIncrementDate(this.appState.queryDate, this.appState.filter);
    },
    formatting() {
      switch (this.appState.filter) {
        case 'today':
          return this.$moment(this.appState.queryDate).calendar(null, {
            sameDay: `[${this.$t('Today')}]`,
            nextDay: `[${this.$t('Tomorrow')}]`,
            nextWeek: 'dddd',
            lastDay: `[${this.$t('Yesterday')}]`,
            lastWeek: `${this.$t('LastDDDD')}`,
            sameElse: 'DD/MM/YYYY',
          });
        case 'week':
          return `${this.$moment(this.appState.queryDate).startOf('isoWeek').format('D')} - ${this.$moment(this.appState.queryDate).endOf('isoWeek').format('D MMM YYYY')}`;
        case 'month':
          return this.$moment(this.appState.queryDate).format('MMMM YYYY');
        case 'year':
          return this.$moment(this.appState.queryDate).format('YYYY');
        default:
          return '';
      }
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    decrementDate() {
      const queryDate = new Date(this.appState.queryDate);

      switch (this.appState.filter) {
        case 'today':
          queryDate.setDate(queryDate.getDate() - 1);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        case 'week':
          queryDate.setDate(queryDate.getDate() - 7);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        case 'month':
          queryDate.setMonth(queryDate.getMonth() - 1);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        case 'year':
          queryDate.setFullYear(queryDate.getFullYear() - 1);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        default:
          break;
      }
    },
    incrementDate() {
      const queryDate = new Date(this.appState.queryDate);

      switch (this.appState.filter) {
        case 'today':
          queryDate.setDate(queryDate.getDate() + 1);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        case 'week':
          queryDate.setDate(queryDate.getDate() + 7);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        case 'month':
          queryDate.setMonth(queryDate.getMonth() + 1);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        case 'year':
          queryDate.setFullYear(queryDate.getFullYear() + 1);
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.$store.dispatch('trips/getTimeline');
          break;
        default:
          break;
      }
    },
  },
};
</script>
