<template>
  <div
    class="shop__product-card d-flex"
    :class="[getCardPadding]"
    :style="getCardWidth"
    @click="!preventClick && displayProduct(product)"
    >
    <div
      class="d-flex flex-column flex-grow-1 bg-white rounded-8"
      >
      <div
        v-if="true"
        class="shop__product-card__top-side d-flex flex-column justify-content-start
        position-relative w-100 rounded-top"
        style="
          padding-top: 75%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          -webkit-font-smoothing: subpixel-antialiased;
        "
        :style="productImage ? `background-image: url(${productImage});` : null"
        >
        <span
          v-if="true"
          class="d-flex mr-2 position-absolute"
          :style="`margin-top: 2px; height: ${bigBrandImage ? 36 :24}px; flex: 0 0
          ${bigBrandImage ? 36 :24}px; bottom: 8px; left: 8px;`"
          >
          <cc-image
            :alt="localizedCompany.name"
            :style="`width: ${bigBrandImage ? 36 :24}px; min-height: ${bigBrandImage ? 36 : 24}px;`"
            class="border-radius-full bg-white object-fit-cover"
            :src="localizedCompany.logo"
            :has-placeholder="true"
            />
        </span>
      </div>

      <div
        v-if="true"
        class="d-flex flex-column px-2 pt-2 pb-3 flex-grow-1 justify-content-between"
        >
        <cc-text
          v-truncate:35
          variant="body-sm"
          color="grey-dark"
          class="pb-1 text-break-all"
          >
          {{ localizedProduct.title }}
        </cc-text>

        <cc-text
          v-if="product.stock !== -1"
          variant="body-sm-bold"
          color="grey-light"
          class="d-flex align-items-center"
          >
          <cc-svg-base
            tag="i"
            color-class="svg-fill-grey-light"
            svg="icons/icon-green-coins-12"
            class="mr-1"
            />
          {{ product.price }}
          {{ $t('shopGreenCoins') }}
        </cc-text>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ccText from '@/components/primitives/Text.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccText,
    ccImage,
    ccSvgBase,
  },
  props: {
    bigBrandImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    offers: {
      type: Array,
      required: false,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    layout: {
      type: String,
      required: false,
      default: 'horizontal',
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      companyState: state => state.company,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
      getCompanyById: 'company/getCompanyById',
    }),
    getCardPadding() {
      switch (this.layout) {
        case 'horizontal':
          return ['mr-2'];
        case 'vertical':
          if (this.index % 2 === 0) {
            return 'pr-2 pb-4';
          }
          return 'pl-2 pb-4';
        default:
          return '';
      }
    },
    getCardWidth() {
      switch (this.layout) {
        case 'horizontal':
          return 'flex: 1 0 46%;';
        case 'vertical':
          return 'flex: 0 0 50%;';
        default:
          return '';
      }
    },
    localizedCompany() {
      const { getLocalizedResource, getCompanyById, product } = this;
      return getLocalizedResource(getCompanyById(product.storeId)?.company?.metaPublic?.resources);
    },
    localizedProduct() {
      const { getLocalizedResource, product } = this;
      return getLocalizedResource(product.virtualGood.resources) || {};
    },
    productImage() {
      return this.localizedProduct?.images?.[0];
    },
  },
  methods: {
    displayProduct(product) {
      const { id } = product;

      if (this.$route.name === 'company') {
        return this.$router.push({ name: 'product-via-company', params: { id } });
      }
      if (this.$route.name === 'product-category') {
        return this.$router.push({ name: 'product-via-category', params: { id } });
      }
      return this.$router.push({ name: 'product', params: { id } });
    },
  },
};
</script>

<style lang="scss">
.product-card-horizontal {
  width: 160px;
}
.product-card-vertical {
  flex: 50%;
  max-width: 50%;
}
</style>
