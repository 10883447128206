var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-view',{attrs:{"id":_vm.id}},[_c('cc-wrapper',{staticClass:"h-100 text-center safe-area-inset-bottom flex-grow-1",attrs:{"align":"between"}},[_c('cc-wrapper',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"p-6 pb-8"},[_c('cc-svg-base',{attrs:{"svg":"illustrations/join-company-challenge"}})],1),_c('cc-heading',{staticClass:"pb-3",attrs:{"variant":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('joinCompanyChallengeTitle'))+" ")]),_c('cc-text',{staticClass:"mb-7 mx-5",attrs:{"color":"muted"}},[_vm._v(" "+_vm._s(_vm.$t('joinCompanyChallengeBody'))+" ")]),_c('div',{staticClass:"bg-white w-100 rounded-8 py-4 d-flex flex-row justify-content-center mt-1 mb-7"},[_c('cc-svg-base',{staticClass:"m-auto",attrs:{"svg":"companies/ittigen"}}),_c('cc-svg-base',{staticClass:"m-auto",attrs:{"svg":"companies/bafu"}}),_c('cc-svg-base',{staticClass:"m-auto",attrs:{"svg":"companies/gvb"}}),_c('cc-svg-base',{staticClass:"m-auto",attrs:{"svg":"companies/rbs"}})],1),_c('cc-button',{staticClass:"mt-1",attrs:{"text":_vm.$t('verifyCompanyEmail'),"variant":"secondary"},on:{"click":function($event){_vm.$router
            .push({
              name: 'settings',
              params: {
                showAddEmailAddressModal: true,
              },
            })
            .catch(() => {})}}}),_c('cc-button',{staticClass:"mt-4",attrs:{"text":_vm.$t('addOnboardCodeButton'),"variant":"secondary"},on:{"click":function($event){_vm.$router
            .push({ name: 'add-onboard-code', params: { isInOnboarding: false } })
            .catch(() => {})}}})],1),(false)?_c('div',{staticClass:"pb-6"},[_c('cc-text',{staticClass:"text-uppercase pb-4",attrs:{"variant":"body-xs"}},[_vm._v(" "+_vm._s(_vm.$t('companiesParticipating'))+" ")]),_c('cc-svg-base',{staticClass:"mr-2",staticStyle:{"height":"28px"},attrs:{"svg":"logos/company-postfinance"}}),_c('cc-svg-base',{staticClass:"ml-2",attrs:{"svg":`partner-logo/swisscom-horizontal-${_vm.appState.userLang}`}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }