import axiosClient from '@/services/axiosClient';
import { retry } from '@/mixins/utils';

const version = '/v1';
const resource = `${version}/group`;

export default {
  async createGroup(name, metaPublic, members) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}`, {
        name,
        members,
        metaPublic,
        metaPrivate: {},
      }))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async addMembers(id, members) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}/addMembers`, {
        id,
        members,
      }))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async removeMember(groupId, memberId) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}/${groupId}/removeMember/${memberId}`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async exitGroup(groupId) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}/${groupId}/exit`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  async deleteGroup(groupId) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.delete(`${resource}/${groupId}`))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },

  // async getMemberGroupIds(userId) {
  //   return new Promise((resolve, reject) => {
  //     retry(() => axiosClient.get(`${resource}/fromMember/${userId}`))
  //       .then((response) => {
  //         resolve(response);
  //       }).catch(error => reject(error));
  //   });
  // },

  async updateMembersRole(payload) {
    try {
      const { groupId, members } = payload;

      const response = await axiosClient.post(`${resource}/updateMembersRole`, {
        groupId,
        members: [...members],
      });

      return response;
    } catch (error) {
      return error;
    }
  },

  async updateGroupProfile(payload) {
    try {
      const {
        id, name, metaPublic, metaPrivate,
      } = payload;

      const response = await axiosClient.put(`${resource}/${id}/profile`, {
        name,
        metaPublic,
        metaPrivate,
      });

      return response;
    } catch (error) {
      return error;
    }
  },

  // async getGroupById(groupId) {
  //   try {
  //     const response = await axiosClient.get(`${resource}/${groupId}`);
  //     return response;
  //   } catch (error) {
  //     return error;
  //   }
  // },

  async addExternalMember(groupId, userIdentifier, role, lang, isGlobalInvite = false) {
    return new Promise((resolve, reject) => {
      retry(() => axiosClient.post(`${resource}/invitationLink`, {
        groupId,
        role,
        userIdentifier,
        isGlobalInvite,
        lang,
      }))
        .then((response) => {
          resolve(response);
        }).catch(error => reject(error));
    });
  },
};
