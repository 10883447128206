<template>
  <div
    v-ruid="`${ruid}__list-item`"
    class=""
    >
    <cc-divider
      v-if="first"
      :color="dividerColor"
      />

    <div class="d-flex justify-content-between align-items-center py-3">
      <cc-svg-base
        v-if="icon"
        style="min-width: 20px;"
        class="line-height-0 mr-3"
        :class="iconClass"
        :svg="icon"
        />

      <cc-text
        v-if="label"
        class="flex-1 text-truncate pr-2 py-2"
        :color="labelColor"
        >
        {{ label }}
      </cc-text>

      <slot v-if="!arrow" />

      <cc-text
        v-if="labelData"
        variant="body-muted"
        class="text-right text-truncate flex-1 pl-2"
        :class="arrow ? 'pr-3' : null"
        :color="labelDataColor"
        >
        {{ labelData }}
      </cc-text>

      <label
        v-if="inputType === 'radio'"
        :for="label"
        class="radio-container"
        >
        <input
          :id="label"
          type="radio"
          :name="label"
          :checked="checked"
          disabled
          >
        <span class="radio-checkmark" />
      </label>

      <cc-svg-base
        v-if="arrow"
        :color-class="`svg-fill-${arrowColor}`"
        svg="icons/icon-arrow-right"
        />
    </div>

    <cc-divider
      v-if="!last"
      :color="dividerColor"
      />
  </div>
</template>

<script>
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccDivider from '@/components/primitives/Divider.vue';

const colorProps = [
  'white',
  'warning',
  'primary',
  'danger',
  'cc-yellow-darkest',
  'cc-yellow-darker',
  'blue-light',
  'muted',
  'grey-dark',
  'grey-light',
  'grey-lighter',
];

export default {
  components: {
    ccSvgBase,
    ccText,
    ccDivider,
  },
  props: {
    ruid: {
      type: String,
      required: false,
      default: 'list',
    },
    checked: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    labelColor: {
      type: String,
      required: false,
      default: 'white',
      validator: value => colorProps.includes(value),
    },
    labelDataColor: {
      type: String,
      required: false,
      default: 'muted',
      validator: value => colorProps.includes(value),
    },
    labelData: {
      type: [String, Boolean],
      default: null,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconClass: {
      type: String,
      required: false,
      default: null,
    },
    inputType: {
      type: String,
      required: false,
      default: null,
      validator: value => ['radio'].includes(value),
    },
    arrow: {
      type: Boolean,
      default: false,
      required: false,
    },
    arrowColor: {
      type: String,
      required: false,
      default: 'white',
      validator: value => colorProps.includes(value),
    },
    dividerColor: {
      type: String,
      required: false,
      default: 'blue-light',
      validator: value => colorProps.includes(value),
    },
    first: {
      type: Boolean,
      default: false,
      required: false,
    },
    last: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
