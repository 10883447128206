import Chart from 'chart.js';
import { generateChart, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
Chart.defaults.roundedDoughnut = Chart.helpers.clone(Chart.defaults.doughnut);
Chart.controllers.roundedDoughnut = Chart.controllers.doughnut.extend({
  draw(ease) {
    const { ctx } = this.chart.chart;
    const easingDecimal = ease || 1;

    // Drawing endAngle rounded later to avoid overlapping isues
    Chart.helpers.each(this.getMeta().data, (arc) => {
      arc.transition(easingDecimal).draw();
    });
    Chart.helpers.each(this.getMeta().data, (arc) => {
    // eslint-disable-next-line no-underscore-dangle
      if (arc._chart.data.datasets[0].data[arc._index] !== 0) {
        // eslint-disable-next-line
        const vm = arc._view;
        const radius = (vm.outerRadius + vm.innerRadius) / 2;
        const thickness = (vm.outerRadius - vm.innerRadius) / 2;
        const angle = Math.PI - vm.startAngle - Math.PI / 2;
        ctx.save();
        ctx.fillStyle = vm.backgroundColor;
        ctx.translate(vm.x, vm.y);
        ctx.beginPath();
        ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
    });
    // Drawing circle with startAngle rounded
    // Drawing endAngle rounded later to avoid overlapping isues
    Chart.helpers.each(this.getMeta().data, (arc) => {
      // eslint-disable-next-line no-underscore-dangle
      if (arc._chart.data.datasets[0].data[arc._index] !== 0) {
        // eslint-disable-next-line
        const vm = arc._view;
        const radius = (vm.outerRadius + vm.innerRadius) / 2;
        const thickness = (vm.outerRadius - vm.innerRadius) / 2;
        const angle = Math.PI - vm.endAngle - Math.PI / 2;
        ctx.save();
        ctx.fillStyle = vm.backgroundColor;
        ctx.translate(vm.x, vm.y);
        ctx.beginPath();
        ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
    });
  },
});

const roundedDoughnut = generateChart('doughnut-chart', 'roundedDoughnut');

const drawDonutPlaceholder = (chart) => {
  if (chart.options.donutPlaceHolder) {
    // console.log('chart', chart.chart.canvas.clientHeight);
    const x = chart.chart.canvas.clientWidth / 2;
    const y = chart.chart.canvas.clientHeight / 2;
    const { ctx } = chart.chart;

    ctx.beginPath();
    ctx.arc(x, y, chart.outerRadius - (chart.radiusLength / 2), 0, 2 * Math.PI);
    ctx.lineWidth = chart.radiusLength;
    ctx.strokeStyle = chart.options.donutPlaceHolder.color || '#1F4DBC';
    ctx.stroke();
  }
};

Chart.plugins.register({
  id: 'donut-placeholder',
  beforeDatasetsDraw: drawDonutPlaceholder,
  onResize: drawDonutPlaceholder,
});

export default {
  extends: roundedDoughnut,
  mixins: [reactiveProp],
  props: ['options'],
  methods: {
    renderChartOnResize() {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    window.addEventListener('resize', this.renderChartOnResize);
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
    // this.$on('chart:update', () => {
    // });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderChartOnResize);
  },
};
