<template>
  <cc-view
    :id="id"
    class="pb-6"
    >
    <cc-wrapper
      v-if="!getAddresses.length"
      class="h-100 px-4"
      >
      <cc-section-header
        :title="$t('deliveryAddressesEmptyStateTitle')"
        svg="illustrations/address-book"
        />

      <cc-text
        color="muted"
        align="center"
        >
        {{ $t('deliveryAddressesEmptyStateBody') }}
      </cc-text>

      <cc-button
        variant="small"
        :text="$t('addDeliveryAddress')"
        class="mt-5"
        :block="false"
        @click="$router.push({ name: 'delivery-address', params: { type: 'new' } }).catch(() => {})"
        />
    </cc-wrapper>

    <cc-wrapper
      v-else
      align="between"
      >
      <div
        v-for="address in getAddresses.sort((a,b) => b.isDefault - a.isDefault)"
        :key="address.id"
        >
        <cc-wrapper
          direction="column"
          align="between"
          justify="start"
          @click.native=" $router
            .push({
              name: 'delivery-address',
              params: { type: 'address', addressId: address.id },
            })
            .catch(() => {})"
          >
          <div class="d-flex align-items-center">
            <cc-heading
              tag="h3"
              variant="h4"
              class="mr-3"
              >
              {{ address.firstName }} {{ address.lastName }}
            </cc-heading>

            <cc-text
              v-if="address.isDefault"
              color="cc-yellow-darker"
              class="bg-cc-yellow border-radius-2 px-2"
              variant="body-sm-bold"
              >
              {{ $t('default') }}
            </cc-text>
          </div>

          <div class="d-flex justify-content-between pt-2">
            <div>
              <cc-text>
                {{ address.streetName }} {{ address.houseNumber }}
              </cc-text>
              <cc-text>{{ address.postalCode }} {{ address.city }}</cc-text>
            </div>
            <div>
              <cc-button
                variant="small-muted"
                :block="false"
                :text="$t('txtEdit')"
                />
            </div>
          </div>
        </cc-wrapper>

        <cc-divider
          color="blue-lighter"
          class="my-4"
          />
      </div>


      <cc-button
        variant="small"
        :text="$t('addDeliveryAddress')"
        class="mt-5 mx-10"
        :block="false"
        @click="
          $router
            .push({
              name: 'delivery-address',
              params: {
                type: 'new',
              },
            })
            .catch(() => {})
        "
        />
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccDivider from '@/components/primitives/Divider.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccSectionHeader,
    ccText,
    ccHeading,
    ccButton,
    ccDivider,
  },
  data() {
    return {
      id: 'delivery-addresses',
    };
  },
  computed: {
    ...mapState({
      accessToken: state => state.user.accessToken,
      user: state => state.user.user,
    }),
    ...mapGetters({
      getAddresses: 'user/getAddresses',
    }),
  },
  methods: {
    handler(address) {
      this.$router
        .push({ name: 'delivery-address', params: { addressId: address.id } })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
