<template>
  <cc-view :id="id">
    <cc-card>
      <template #body>
        <cc-wrapper>
          <cc-svg-base svg="illustrations/consumption-succeeded" />

          <cc-wrapper
            direction="row"
            class="pt-4"
            >
            <cc-heading class="pr-2">
              {{ $t('shopConsumptionSuccessfulTitle') }}
            </cc-heading>

            <cc-svg-base svg="icons/icon-circle-check" />
          </cc-wrapper>

          <cc-text
            variant="body-xs"
            color="muted"
            class="text-uppercase py-3"
            >
            {{ $moment().format('LLL') }}
          </cc-text>

          <cc-text align="center">
            {{ $t('shopConsumptionSuccessfulDescription') }}
          </cc-text>
        </cc-wrapper>
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccCard,
    ccHeading,
    ccText,
    ccSvgBase,
  },
  data() {
    return {
      id: 'consumption-successful',
    };
  },
};
</script>
