<template>
  <cc-view
    :id="id"
    :has-full-width="false"
    >
    <cc-wrapper
      direction="row"
      align="center"
      class="mx-4 pb-4"
      >
      <cc-svg-base svg="illustrations/cup" />
    </cc-wrapper>

    <h3
      v-if="isAllowCompanyLeaderboard"
      class="pb-3 h3 text-upper font-weight-bold"
      >
      {{ $t('txtCompany') }} <cc-svg-base svg="icons/icon-company" />
    </h3>
    <cc-company-leaderboard-card />
    <cc-company-group-leaderboard-card />

    <cc-divider
      v-if="isAllowCompanyLeaderboard"
      class="my-4"
      style="flex-basis: 100%;"
      />

    <h3 class="py-3 h3 text-upper font-weight-bold">
      {{ $t('txtPersonal') }} <cc-svg-base svg="icons/icon-account-2" />
    </h3>
    <cc-friends-leaderboard-card
      @request-contacts-permission="$event => {
        permissionModalType = $event.permissionModalType;
        isContactsPermissionModalDisplayed = true;
      }"
      />

    <cc-group-leaderboard-card />

    <cc-canton-leaderboard-card v-if="false" />

    <cc-text
      class="pt-4 pb-6"
      variant="body-sm"
      color="muted"
      >
      {{ $t('leaderboardsDescriptionPlural') }}
    </cc-text>

    <cc-lead-to-groups-modal
      v-if="isLeadToGroupsModalDisplayed"
      @close-modal="isLeadToGroupsModalDisplayed = false"
      />

    <cc-contacts-permission-modal
      v-if="isContactsPermissionModalDisplayed"
      :data="{ permissionModalType }"
      @close-modal="isContactsPermissionModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';

import ccText from '@/components/primitives/Text.vue';
import ccFriendsLeaderboardCard from '@/components/social/FriendsLeaderboardCard.vue';
import ccCantonLeaderboardCard from '@/components/social/CantonLeaderboardCard.vue';
import ccGroupLeaderboardCard from '@/components/social/GroupLeaderboardCard.vue';
import ccCompanyLeaderboardCard from '@/components/social/CompanyLeaderboardCard.vue';
import ccCompanyGroupLeaderboardCard from '@/components/social/CompanyGroupLeaderboardCard.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccLeadToGroupsModal from '@/components/modals/LeadToGroupsModal.vue';
import ccContactsPermissionModal from '@/components/modals/ContactsPermissionModal.vue';

export default {
  name: 'Leaderboards',
  components: {
    ccView,
    ccText,
    ccFriendsLeaderboardCard,
    ccCantonLeaderboardCard,
    ccGroupLeaderboardCard,
    ccCompanyLeaderboardCard,
    ccCompanyGroupLeaderboardCard,
    ccSvgBase,
    ccWrapper,
    ccLeadToGroupsModal,
    ccContactsPermissionModal,
  },
  mixins: [viewScrollMemorizer],
  data() {
    return {
      id: 'leaderboards',
      isLeadToGroupsModalDisplayed: false,
      isContactsPermissionModalDisplayed: false,
      permissionModalType: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      uiState: state => state.common.uiState,
    }),
    ...mapGetters({
      getCompanyTagId: 'user/getUserCompanyTagId',
      getAllowCompanyLeaderboardByAlias: 'company/getAllowCompanyLeaderboardByAlias',
    }),
    isAllowCompanyLeaderboard() {
      return this.getAllowCompanyLeaderboardByAlias(this.getCompanyTagId);
    },
  },
  created() {
    // this.$log.info('Created: Leaderboards');

    // Listen for the modal event to be fired upon viewWillBeShown
    // TODO: Instead of viewWillBeShown we will need to use visiblity API?
    this.$root.$once('show-lead-to-groups-modal', () => {
      this.isLeadToGroupsModalDisplayed = true;
    });
  },
  destroyed() {
    // this.$log.info('Destroyed: Leaderboards');
  },
  methods: {},
};
</script>
