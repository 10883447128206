<template>
  <cc-card
    v-if="isShow()"
    :id="id"
    :ruid="id"
    :inner-title="null"
    :pressable="true"
    class="pb-4"
    :has-button="false"
    variant="address"
    card-class="p-5 border-1 border-blue-light"
    @click.native="clickHandler"
    >
    <template #body>
      <cc-list-item
        :date="$t('newHighlightItemTitle').toUpperCase()"
        :no-padding="true"
        :image="handleImagePrefix(recentNews)"
        :title="recentNews?.title.text"
        :description-truncate-amount="50"
        :border-bottom="false"
        :square-image="true"
        @click.native="clickHandler"
        />
    </template>
  </cc-card>
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';
import ccListItem from '@/components/constructs/ListItem.vue';

import { handleImagePrefix } from '@/mixins/utils';
import { mapState } from 'vuex';

export default {
  name: 'NewsItemHightlightCard',
  components: {
    ccListItem,
    ccCard,
  },
  data() {
    return {
      id: 'new-item-highlight-card',
    };
  },
  computed: {
    ...mapState({
      processedNewsFeed: state => state.social.processedNewsFeed,
    }),
    recentNews() {
      if (!this.processedNewsFeed?.[Object.keys(this.processedNewsFeed)[0]]?.[0]) return null;
      return this.processedNewsFeed[Object.keys(this.processedNewsFeed)[0]][0];
    },
  },
  methods: {
    handleImagePrefix,
    clickHandler() {
      if (this.recentNews.link.text) {
        this.$store.dispatch(
          'common/openUrlInternal',
          `${this.recentNews.link.text}/?internalBrowser=true`,
        );
      }
    },
    getRecentDate() {
      return new Date(this.recentNews?.pubDate.text);
    },
    isShow() {
      return this.$moment().diff(this.$moment(this.getRecentDate()), 'days') < 8;
    },
  },
};
</script>
