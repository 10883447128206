<template>
  <cc-view
    :id="id"
    :has-full-width="true"
    >
    <cc-charts-loading
      v-if="isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <div
      v-else
      class="pb-4"
      >
      <cc-balance-card
        v-if="false"
        :amount="getWalletBalance"
        variant="shop"
        :pressable="true"
        class="pb-5 px-4 pt-4"
        @click.native="$router.push({ name: 'wallet' })"
        />

      <cc-company-carousel class="mb-6 mt-6" />

      <div
        v-if="false"
        class="overflow-x-scroll w-100 bg-black flex-horizontal-scroll"
        >
        <div
          class=""
          style="
            display: grid;
            grid-auto-flow: column;
            grid-gap: 10px;
            padding: 20px;
            overflow: auto;
          "
          >
          <div
            v-for="(item, i) in 10"
            :key="i"
            class="px-3"
            >
            <div
              class="w-100"
              :class="i % 2 === 0 ? 'bg-danger' : 'bg-yellow'"
              style="height: 50px"
              >
              {{ i }}
            </div>
          </div>
        </div>
      </div>

      <cc-public-goal-card
        variant="shop"
        :has-date="false"
        :has-title="false"
        :has-details-button="false"
        body-text-key="publicChallengeBannerText"
        class="px-4"
        />


      <cc-product-category-row
        v-for="(category, key, i) in offersByCategory"
        :key="key + i"
        :offers-by-category="{ [key]: shuffle(category, appState.sessionId).slice(0, 8) }"
        />

      <div class="px-4 pb-6">
        <cc-divider class="mb-5" />
        <cc-button
          variant="secondary"
          :text="$t('seeAllProducts')"
          @click.native="$router.push({ name: 'product-category', params: { category: 'all' } })"
          />
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { shuffle } from '@sjeffff/shuffle-seed';

import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import sandbox from '@/mixins/sandbox';

import ccView from '@/components/constructs/View.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccProductCategoryRow from '@/components/shop/ProductCategoryRow.vue';
import ccCompanyCarousel from '@/components/shop/CompanyCarousel.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccBalanceCard from '@/components/achievements/BalanceCard.vue';
import ccPublicGoalCard from '@/components/footprint/PublicGoalCard.vue';

export default {
  components: {
    ccView,
    ccButton,
    ccChartsLoading,
    ccProductCategoryRow,
    ccCompanyCarousel,
    ccDivider,
    ccBalanceCard,
    ccPublicGoalCard,
  },
  mixins: [viewScrollMemorizer, sandbox],
  data() {
    return {
      id: 'shop',
      isLoading: true,
      isShopCategoryFilterModalDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      offersByCategory: state => state.company.offersByCategory,
      companyState: state => state.company,
      wallets: state => state.user.wallets,
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      filterAvailableProductByCategory: 'company/filterAvailableProductByCategory',
      getWalletBalance: 'user/getWalletBalance',
      getLocalizedResource: 'company/getLocalizedResource',
    }),
    filter() {
      return this.companyState.productCategoryFilter;
    },
  },
  async created() {
    this.sendMessageToHost('SCC:company');

    this.$store.dispatch('company/getPlayer');

    if (!this.wallets?.length) {
      await this.$store.dispatch('user/getWallets');
    }

    this.isLoading = false;
  },
  methods: {
    shuffle,
  },
};
</script>
