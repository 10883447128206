<template>
  <div
    class="rounded overflow-hidden position-relative"
    :style="`width:${width}`"
    >
    <div
      v-if="hasMinimum || progressValue"
      class="rounded position-relative z-index-1"
      :style="barStyle"
      />
    <div
      class="rounded position-absolute top-left-0"
      :style="`height: ${height}px; background-color: ${bgColor};`"
      />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progressValue: {
      type: Number,
      required: false,
      default: 0,
    },
    hasMinimum: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: String,
      required: false,
      default: '100',
    },
    height: {
      type: Number,
      required: false,
      default: 9,
    },
    backgroundOpacity: {
      type: Number,
      required: false,
      default: 1,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#91BDFE',
    },
    saturationValue: {
      type: Number,
      required: false,
      default: 0,
    },
    progressColor: {
      type: String,
      required: false,
      default: '#85FF97',
    },
  },
  data() {
    return {
      update: 0,
    };
  },
  computed: {
    bgColor() {
      const { percentageToHexOpacity, backgroundColor, backgroundOpacity } = this;
      return `${backgroundColor}${percentageToHexOpacity(backgroundOpacity)}`;
    },
    progressBarPercentage() {
      return this.clamp(this.progressValue * 100, 0, 100) || 0;
    },
    barStyle() {
      const {
        progressBarPercentage, //
        height,
        saturationValue,
        progressColor,
      } = this;

      const bgColor = saturationValue || saturationValue === 0
          ? this.percentageToColor(saturationValue)
          : progressColor;

      this.$log.info('progressBarPercentage', progressBarPercentage);

      return `
          width: ${progressBarPercentage < 3 ? '10px' : `${progressBarPercentage}%`};
          height: ${height}px;
          background-color: ${bgColor}
        `;
    },
  },
  methods: {
    clamp(value, min, max) {
      return Math.min(Math.max(value, min), max);
    },
    percentageToColor(percentage, maxHue = 120, minHue = 0) {
      const hue = percentage * (maxHue - minHue) + minHue;
      return `hsl(${hue}, 100%, 75%)`;
    },
    percentageToHexOpacity(percentage) {
      // Convert percentage to a value between 0 and 255
      const opacityValue = Math.round((percentage / 100) * 255);

      // Convert value to a two-digit hexadecimal string
      const opacityHex = opacityValue.toString(16).padStart(2, '0');

      return opacityHex;
    },
  },
};
</script>
