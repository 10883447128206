<template>
  <cc-modal
    data-ruid="modal__group-exit"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('groupLeaderboardLeaveGroupTitle', { groupName: group.group.name })"
          svg="illustrations/group-exit"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :is-modal-button="true"
          class="mr-1"
          variant="danger"
          data-ruid="global__button-remove"
          :text="$t('groupLeaderboardLeaveGroup')"
          @click="exitGroup(close)"
          />
        <cc-button
          :is-modal-button="true"
          class="ml-1"
          variant="secondary"
          data-ruid="global__button-confirm"
          :text="$t('txtCancel')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccButton,
    ccSectionHeader,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'confirm-group-exit-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
    }),
    ...mapGetters({
      hasGroupLeaderboard: 'social/hasGroupLeaderboard',
    }),
    group() {
      return this.data;
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async exitGroup(closeModal) {
      // TODO: Add error message component to ui and bind here
      try {
        await this.$store.dispatch('social/exitGroup', this.$route.params.id);
        await this.$store.dispatch('social/getGroupsLeaderboard', true);

        closeModal(() => {
          if (this.hasGroupLeaderboard) {
            this.$router.push({ name: 'group-leaderboard', params: { groupExitConfirmation: true } });
          } else {
            this.$router.push({ name: 'join-group-leaderboard', params: { groupExitConfirmation: true } }).catch(() => {});
          }
        });
      } catch (error) {
        this.$log.warn('exitGroup error', error);
      }
    },
  },
};
</script>
