<template>
  <cc-view :id="id">
    <ccDialogWrapper>
      <template #dialog>
        <cc-alert id="alert" />
      </template>
    </ccDialogWrapper>
    <div class="pt-5">
      <cc-charts-loading
        v-if="areTripsFetching && appState.filter !== 'today'"
        class="chart-loading-container"
        />
      <cc-doughnut-chart v-if="appState.filter === 'today'" />
      <cc-stacked-bar-chart
        v-else-if="appState.filter === 'week'"
        :style="`opacity: ${chartsOpacity}`"
        />
      <cc-line-chart
        v-else-if="appState.filter === 'year' || appState.filter === 'month'"
        :style="`opacity: ${chartsOpacity}`"
        />
      <cc-chart-legend
        class="pt-6 pb-3"
        :style="`opacity: ${chartsOpacity}`"
        />
    </div>

    <div class="pb-3">
      <cc-heading
        variant="h5"
        weight="xbold"
        >
        {{ $t('meansOfTransport') }}
      </cc-heading>

      <cc-text
        variant="body-sm-bold"
        color="muted"
        >
        {{ $t('meansOfTransportDescription') }}
      </cc-text>
    </div>

    <div
      v-if="false && noData"
      class="d-flex flex-column align-items-center py-6"
      >
      <cc-svg-base svg="illustrations/pinguin-question" />

      <cc-heading>
        {{ $t('noDataTitle') }}
      </cc-heading>

      <cc-text
        variant="body-bold"
        color="muted"
        >
        {{ $t('noDataSubtitle') }}
      </cc-text>
    </div>

    <cc-transport-cards
      class="mt-5"
      :is-padded="false"
      />

    <cc-recent-trips-disclosure
      v-if="['today', 'week'].includes(appState.filter) && isCurrentWeekOrDay"
      class="mb-6"
      :body-text-key="
        appState.filter === 'today' ? 'recentTripsDisclosure' : 'noRecentTripsWeekBody'
      "
      />

    <cc-day-timeline
      v-if="appState.filter === 'today'"
      class="pb-5"
      is-journey
      @vehicle-preference-modal="isVehiclePreferenceModalDisplayed = true"
      />

    <cc-vehicle-preference-modal
      v-if="isVehiclePreferenceModalDisplayed"
      @close-modal="isVehiclePreferenceModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { isEqual } from 'lodash';
import { generateFromToPeriods } from '@/mixins/utils';

import ccView from '@/components/constructs/View.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';

import ccDoughnutChart from '@/components/DoughnutChart.vue';
import ccStackedBarChart from '@/components/StackedBarChart.vue';
import ccLineChart from '@/components/LineChart.vue';
import ccChartLegend from '@/components/ChartLegend.vue';
import ccTransportCards from '@/components/TransportCards.vue';
import ccAlert from '@/components/Alert.vue';
import ccDayTimeline from '@/components/DayTimeline.vue';
import ccDialogWrapper from '@/components/DialogWrapper.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccVehiclePreferenceModal from '@/components/modals/VehiclePreferenceModal.vue';
import ccRecentTripsDisclosure from '@/components/constructs/RecentTripsDisclosure.vue';

export default {
  name: 'Journeys',
  components: {
    ccView,
    ccSvgBase,
    ccText,
    ccHeading,
    ccDoughnutChart,
    ccStackedBarChart,
    ccLineChart,
    ccChartLegend,
    ccTransportCards,
    ccAlert,
    ccDayTimeline,
    ccDialogWrapper,
    ccChartsLoading,
    ccVehiclePreferenceModal,
    ccRecentTripsDisclosure,
  },
  data() {
    return {
      id: 'journeys',
      isVehiclePreferenceModalDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      timeline: state => state.trips.timeline,
      chartData: state => state.trips.chartData,
    }),
    ...mapGetters({
      areTripsFetching: 'common/areTripsFetching',
    }),
    chartsOpacity() {
      if (this.areTripsFetching) {
        return '40%';
      }
      return '100%';
    },
    filter() {
      return this.appState.filter;
    },
    noData() {
      if (
        this.appState.tripsProcessing === false
        && this.timeline?.[this.timeline?.currentPeriod]?.trips?.length === 0
      ) {
        return true;
      }
      return false;
    },
    isCurrentWeekOrDay() {
      const { appState } = this;

      return isEqual(
        generateFromToPeriods(appState.queryDate, appState.filter).currentPeriod,
        generateFromToPeriods(new Date().getTime(), appState.filter).currentPeriod,
      );
    },
  },
  watch: {
    chartData() {
      this.askForVehiclePreference();
    },
  },
  beforeMount() {
    this.$log.info(
      'Route params: timeframe',
      this.$route.params.timeframe,
      this.$route.params.queryDate,
    );

    if (this.$route.params.timeframe) {
      const queryDate = new Date(this.$route.params.queryDate ?? null);

      switch (this.$route.params.timeframe) {
        case 'today':
          this.setAppState(['queryDate', queryDate.getTime()]);
          this.setAppState(['filter', 'today']);
          this.$store.dispatch('trips/getTimeline');
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.askForVehiclePreference();
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    askForVehiclePreference() {
      if (!this.user?.metaPublic?.vehiclePreferenceReminderShown) {
        this.chartData.transportModeSummaries.forEach((element) => {
          if (this.transportTypes.interchangableTransportTypes.includes(element.transitMode)) {
            this.$log.info('Will display VehiclePreferenceModal');

            setTimeout(() => {
              this.isVehiclePreferenceModalDisplayed = true;
            }, 1000);
          }
        });
      }
    },
  },
};
</script>
