import Vue from 'vue';
import moment from 'moment-timezone';
import uuidv4 from 'uuid/v4';
import * as Sentry from '@sentry/vue';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import convert from 'xml-js';
import i18n from '@/i18n';

import service from '@/services/index';
import { callSdk, getCantonName, getRandomInt } from '@/mixins/utils';
import { ActionEnum } from '@/mixins/enums';
import kibanaEvents from '@/mixins/kibanaEvents';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// State object
const initialState = () => ({
  leaderboardState: {
    cities: null,
    friends: null,
    groups: null,
    company: null,
    publicChallengeCompany: null,
    publicChallengeCompanyGroup: null,
    timestamp: null,
    refreshTime: 30 * 60 * 1000, // 30 minutes
  },

  messages: [],
  notificationsQueue: [],
  processedNewsFeed: {},
  notificationCurrentStyle: 1,
});

const state = initialState();

// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  setLeaderboardState(state, [prop, value]) {
    Vue.set(state.leaderboardState, prop, value);
  },
  pushToNotificationsQueue(state, payload) {
    state.notificationsQueue.push(payload);
  },
  spliceFromNotificationsQueue(state, index) {
    state.notificationsQueue.splice(index, 1);
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

// Actions
const actions = {
  getFriendsLeaderboard({ commit, dispatch, rootState }) {
    const { appState } = rootState.common;
    return new Promise((resolve, reject) => {
      if (appState.contactsPermission === 'true') {
        commit('common/setAppState', ['friendsLeaderboardLoading', true], { root: true });
        Vue.$log.info('Store > Will call getFriendsleaderboardState');

        const weeksAgo = moment().diff(appState.queryDate, 'weeks');

        service.social.getLeaderboard('friends', weeksAgo).then((currentLeaderboard) => {
          dispatch('markTimestamp');
          service.social.getLeaderboard('friends', weeksAgo + 1).then((previousLeaderboard) => {
            const leaderboard = {
              id: uuidv4(),
              windowsAgo: weeksAgo,
              currentData: currentLeaderboard.scoreboard,
              previousData: previousLeaderboard.scoreboard,
            };

            if (leaderboard.currentData.name === 'friends') {
              commit('setLeaderboardState', [leaderboard.currentData.name, leaderboard]);

              if (leaderboard.currentData.data.length > 1) {
                const contactIds = leaderboard.currentData.data.map(e => e.id);
                // Vue.$log.info('Store > getFriendsLeaderboard > contactIds', contactIds);

                contactIds.forEach((element, index) => {
                  // Vue.$log.info('getFriendsLeaderboard > contactIds forEach', element, index);
                  if (element === '') {
                    contactIds[index] = 'empty';
                  }
                });
                callSdk(`action=${ActionEnum.REQUEST_CONTACT_NAMES}&requestID=${encodeURIComponent(leaderboard.id)}&contactIDs=${encodeURIComponent(contactIds)}`, this);
              } else {
                dispatch('serializeLeaderboardState', { type: leaderboard.currentData.name });
              }
            }

            resolve();
          });
        })
          .catch((err) => {
            Vue.$log.error('getFriendsLeaderboard error', err);
            Sentry.captureMessage(`getFriendsLeaderboard error: ${err}`);
            reject(err);
          });
      }
    });
  },

  getCantonLeaderboard({ commit, dispatch, rootState }) {
    const { appState } = rootState.common;
    return new Promise((resolve, reject) => {
      commit('common/setAppState', ['cantonLeaderboardLoading', true], { root: true });

      const weeksAgo = moment().diff(appState.queryDate, 'weeks');

      service.social.getLeaderboard('cities', weeksAgo).then((currentLeaderboard) => {
        service.social.getLeaderboard('cities', weeksAgo + 1).then((previousLeaderboard) => {
          const leaderboard = {
            id: uuidv4(),
            windowsAgo: weeksAgo,
            currentData: currentLeaderboard.scoreboard,
            previousData: previousLeaderboard.scoreboard,
          };

          if (leaderboard.currentData.name === 'cities') {
            commit('setLeaderboardState', [leaderboard.currentData.name, leaderboard]);
            dispatch('serializeLeaderboardState', { type: leaderboard.currentData.name });
          }
        });
        resolve();
      })
        .catch((err) => {
          Sentry.captureMessage(`getCantonLeaderboard error: ${err}`);
          reject(err);
        });
    });
  },

  serializeLeaderboardState({
    commit, dispatch, state, rootState,
  }, { type }) {
    const { appState } = rootState.common;

    return new Promise((resolve) => {
      const leaderboard = cloneDeep(state.leaderboardState[type]);
      leaderboard.timestamp = Date.now();
      leaderboard.averageCo2 = [];
      leaderboard.co2PerKm = [];

      const currDidTravel = [];
      const prevDidTravel = [];

      leaderboard.currentData.didNotTravel = [];

      if (type === 'group') {
        leaderboard.currentData.data[0].members.forEach((entry) => {
          if (entry.tripTotals.distanceInMeters < 2000) {
            leaderboard.currentData.didNotTravel.push({
              ...entry,
              curPosition: leaderboard.currentData.didNotTravel.length + 1,
            });
          } else {
            currDidTravel.push(entry);
          }
        });
      } if (type === 'company' || type === 'company_groups') {
        leaderboard.currentData.data.forEach((entry) => {
          const { totalParticipants } = entry;
          if (entry.tripTotals.distanceInMeters < 2000 || totalParticipants === 0) {
            leaderboard.currentData.didNotTravel.push({
              ...entry,
              curPosition: leaderboard.currentData.didNotTravel.length + 1,
            });
          } else {
            currDidTravel.push({
              ...entry,
              tripTotals: {
                ...entry.tripTotals,
                co2: entry.tripTotals.co2 / totalParticipants,
                co2IfElectric: entry.tripTotals.co2IfElectric / totalParticipants,
                kwh: entry.tripTotals.co2IfElectric / totalParticipants,
                kwhIfElectric: entry.tripTotals.kwhIfElectric / totalParticipants,
              },
            });
          }
        });
      } else {
        leaderboard.currentData.data.forEach((entry) => {
          if (entry.tripTotals.distanceInMeters < 2000) {
            leaderboard.currentData.didNotTravel.push({
              ...entry,
              curPosition: leaderboard.currentData.didNotTravel.length + 1,
            });
          } else {
            currDidTravel.push(entry);
          }
        });
      }

      leaderboard.previousData.data.forEach((entry) => {
        if (entry.tripTotals.distanceInMeters !== 0) {
          prevDidTravel.push(entry);
        }
      });

      leaderboard.currentData.didTravel = currDidTravel;
      leaderboard.previousData.didTravel = prevDidTravel;

      let currentData = leaderboard.currentData.didTravel;
      let previousData = leaderboard.previousData.didTravel;


      let groupInfoCopy = null;
      let { windowInSecs } = leaderboard.currentData;

      if (type === 'group') {
        groupInfoCopy = { ...leaderboard.currentData };

        // currentData = leaderboard.currentData.data[0].members;
        // previousData = leaderboard.previousData.data[0].members;

        ({ windowInSecs } = groupInfoCopy);
      }

      // calculate scores leaderboard
      currentData = currentData.map((element) => {
        const object = { ...element };

        let name = '';

        object.id = object.id.split('_').pop();

        if (type === 'friends') {
          if (object.contactName) {
            name = object.contactName;
          }
        } else if (type === 'cities') {
          name = getCantonName(object.id);
        } else if (type === 'groups') {
          ({ name } = object.group);
        } else if (type === 'group') {
          ({ name } = object);
        } else if (type === 'company'
          || type === 'company_groups'
          || type === 'publicChallengeCompany'
          || type === 'publicChallengeCompanyGroup') {
          name = object.id;
        }
        if (type !== 'publicChallengeCompany' && type !== 'publicChallengeCompanyGroup') {
          const weeksAgo = moment().diff(appState.queryDate, 'weeks');
          const weekStart = weeksAgo > 0 ? null : moment().clone().startOf('week').add(1, 'day');
          let daysOccur = !weekStart
            ? 7 : (moment(state.leaderboardState.timestamp).diff(weekStart, 'seconds') / 86400);
          if (daysOccur <= 0) {
            daysOccur = 7;
          } else if (daysOccur < 1) {
            daysOccur = 1;
          }


          object.scoreAvgCo2 = (object.tripTotals.co2 / daysOccur);

          object.scoreCo2PerKm = object.tripTotals.co2 / (object.tripTotals.distanceInMeters / 1000)
            || 0;
        } else {
          object.scoreAvgCo2 = object.tripTotals.co2;
          object.scoreCo2PerKm = object.tripTotals.co2 / (object.tripTotals.distanceInMeters / 1000)
            || 0;
        }
        object.name = name;
        return object;
      });

      // eslint-disable-next-line max-len
      leaderboard.currentData.didNotTravel = leaderboard.currentData?.didNotTravel?.map((element) => {
        const object = { ...element };

        let name = '';

        object.id = object.id.split('_').pop();

        if (type === 'friends') {
          if (object.contactName) {
            name = object.contactName;
          }
        } else if (type === 'cities') {
          name = getCantonName(object.id);
        } else if (type === 'groups') {
          ({ name } = object.group);
        } else if (type === 'group') {
          ({ name } = object);
        } else if (type === 'company'
          || type === 'company_groups'
          || type === 'publicChallengeCompany'
          || type === 'publicChallengeCompanyGroup') {
          name = object.id;
        }

        object.name = name;

        return object;
      });


      previousData = previousData.map((element) => {
        const object = { ...element };

        object.scoreAvgCo2 = (object.tripTotals.co2 / windowInSecs)
          * 86400 || 0;
        object.scoreCo2PerKm = object.tripTotals.co2 / (object.tripTotals.distanceInMeters / 1000)
          || 0;

        return object;
      });


      // sort co2
      currentData.sort((a, b) => a.scoreAvgCo2 - b.scoreAvgCo2);
      previousData.sort((a, b) => a.scoreAvgCo2 - b.scoreAvgCo2);


      // Sorting friends with 0 Co2 depending on who traveled the most distance
      if (type === 'friends') {
        const currentNoEmission = [];
        const currentWithEmission = [];
        const previousNoEmission = [];
        const previousWithEmission = [];

        currentData.forEach((element) => {
          if (element.scoreAvgCo2 === 0) {
            currentNoEmission.push(element);
          } else {
            currentWithEmission.push(element);
          }
        });

        previousData.forEach((element) => {
          if (element.scoreAvgCo2 === 0) {
            previousNoEmission.push(element);
          } else {
            previousWithEmission.push(element);
          }
        });

        currentNoEmission
          .sort((a, b) => b.tripTotals.distanceInMeters - a.tripTotals.distanceInMeters);
        previousNoEmission
          .sort((a, b) => b.tripTotals.distanceInMeters - a.tripTotals.distanceInMeters);

        currentData = currentNoEmission.concat(currentWithEmission);
        previousData = previousNoEmission.concat(previousWithEmission);
      }


      // insert positions co2
      currentData.forEach((element, index) => {
        const object = {
          ...element,
          score: element.scoreAvgCo2,
          curPosition: index + 1,
          prevPosition: previousData.findIndex(x => x.id === element.id) + 1
            || currentData.length + 1,
        };
        leaderboard.averageCo2.push(object);
      });


      // sort temperature
      currentData.sort((a, b) => a.scoreCo2PerKm - b.scoreCo2PerKm);
      previousData.sort((a, b) => a.scoreCo2PerKm - b.scoreCo2PerKm);

      // insert positions temperature
      currentData.forEach((element, index) => {
        const object = {
          ...element,
          score: element.scoreCo2PerKm,
          curPosition: index + 1,
          prevPosition: previousData.findIndex(x => x.id === element.id) + 1
            || currentData.length + 1,
        };
        leaderboard.co2PerKm.push(object);
      });

      if (groupInfoCopy) {
        leaderboard.currentData.data = groupInfoCopy.data;
      }

      commit('setLeaderboardState', [type, leaderboard]);

      if (type === 'friends') {
        commit('common/setAppState', ['friendsLeaderboardLoading', false], { root: true });
      }

      if (type === 'group') {
        commit('common/setAppState', ['groupLeaderboardLoading', false], { root: true });
      }

      if (type === 'groups') {
        commit('common/setAppState', ['groupsLeaderboardLoading', false], { root: true });
      }

      if (type === 'company') {
        commit('common/setAppState', ['companyLeaderboardLoading', false], { root: true });
      }

      if (type === 'publicChallengeCompany') {
        commit('common/setAppState', ['companyLeaderboardLoading', false], { root: true });
      }

      if (type === 'publicChallengeCompanyGroup') {
        commit('common/setAppState', ['companyGroupsLeaderboardLoading', false], { root: true });
      }

      if (type === 'company_groups') {
        commit('common/setAppState', ['companyGroupsLeaderboardLoading', false], { root: true });
      }

      if (type === 'cities') {
        dispatch('trips/calculateCantonTemperature', null, { root: true });
        commit('common/setAppState', ['cantonLeaderboardLoading', false], { root: true });
      }

      resolve();
    });
  },

  /**
   * TODO: REQUEST_CONTACT_NAMES is requested twice when granting contacts permission
   * One is in Friends Leaderboard card the other is in a Group View before adding friends.
   */
  getContacts({ commit }) {
    return new Promise((resolve) => {
      service.social.getContacts()
        .then((response) => {
          Vue.$log.info('getContacts Store', response);

          if (response?.length > 0) {
            const contactIds = response
              .map(contact => !contact.self && contact.phonebookId)
              .filter(contact => contact);

            const contactsObject = {
              id: uuidv4(),
              contactIds,
              data: response.filter(contact => !contact.self),
            };
            commit('user/setState', ['contacts', contactsObject], { root: true });

            Vue.$log.info('setUserState Store', contactsObject);
            Vue.$log.info('Will REQUEST_CONTACT_NAMES', contactsObject);

            callSdk(`action=${ActionEnum.REQUEST_CONTACT_NAMES}&requestID=${encodeURIComponent(contactsObject.id)}&contactIDs=${encodeURIComponent(contactIds)}`, this);
          }
          resolve();
        })
        .catch(err => Vue.$log.warn('getContacts error:', err));
    });
  },


  // getContactsIfNecessary({ rootState, dispatch }) {
  //   return new Promise((resolve) => {
  //     const { appState } = rootState.common;
  //     const { accessToken, contacts } = rootState.user || {};

  //     if (appState.contactsPermission === 'true' && accessToken && contacts?.id) {
  //       Vue.$log.info('Will call getContactsIfNecessary');
  //       dispatch('getContacts')
  //       .then(() => resolve())
  //       .catch(err => Vue.$log.warn('getContactsIfNecessary error:', err));
  //     }
  //   });
  // },


  createGroup({ commit }, { name, metaPublic, members }) {
    commit('common/setAppState', ['isAwaiting', true], { root: true });
    commit('common/setAppState', ['errorMessage', null], { root: true });

    return new Promise((resolve, reject) => {
      service.groups.createGroup(name, metaPublic, members)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          Vue.$log.warn('createGroup error:', err);
          commit('common/setAppState', ['errorMessage', i18n.t('generalErrorMessage')], { root: true });
          reject(err);
        })
        .finally(() => commit('common/setAppState', ['isAwaiting', false], { root: true }));
    });
  },

  addMembers(context, { groupId, members }) {
    // Vue.$log.info('addMembers did start');

    return new Promise((resolve) => {
      service.groups.addMembers(groupId, members)
        .then(() => {
          resolve();
        })
        .catch(err => Vue.$log.warn('addMembers error:', err));
    });
  },

  async addExternalMembers({ rootState }, { groupId, userIdentifier, role }) {
    Vue.$log.info('addExternalMembers did start');
    const { number } = rootState.user.user.phone;

    const lang = rootState.user.metaPublic?.language || rootState.common.appState.userLang || 'de';

    const phoneNumber = userIdentifier.split(':')[1];
    const normalizedPhoneNumber = await service.auth.normalizePhoneNumber(phoneNumber, number);

    return new Promise((resolve, reject) => {
      service.groups.addExternalMember(groupId, `tel:${normalizedPhoneNumber.normalized}`, role, lang)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          Vue.$log.warn('addExternalMembers error:', err);
          reject(err);
        });
    });
  },

  removeMember(context, { groupId, memberId }) {
    // Vue.$log.info('removeMember did start');

    return new Promise((resolve) => {
      service.groups.removeMember(groupId, memberId)
        .then(() => {
          resolve();
        })
        .catch(err => Vue.$log.warn('removeMember error:', err));
    });
  },

  updateMembersRole(context, { groupId, members }) {
    Vue.$log.info('updateMembersRole did start', groupId, members);

    return new Promise((resolve) => {
      service.groups.updateMembersRole({ groupId, members })
        .then(() => {
          resolve();
        })
        .catch(err => Vue.$log.warn('updateMembersRole error:', err));
    });
  },

  getGroupsLeaderboard({ commit, dispatch, rootState }, forceUpdate = false) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      commit('common/setAppState', ['isLoading', true], { root: true });
      commit('common/setAppState', ['groupsLeaderboardLoading', true], { root: true });

      const weeksAgo = moment().diff(appState.queryDate, 'weeks');
      service.social.getLeaderboard('groups', weeksAgo, forceUpdate).then(async (currentLeaderboard) => {
        dispatch('markTimestamp');
        await service.social.getLeaderboard('groups', weeksAgo + 1, forceUpdate).then(async (previousLeaderboard) => {
          const leaderboard = {
            id: uuidv4(),
            windowsAgo: weeksAgo,
            currentData: currentLeaderboard.scoreboard,
            previousData: previousLeaderboard.scoreboard,
          };

          if (leaderboard.currentData.name === 'groups') {
            commit('setLeaderboardState', [leaderboard.currentData.name, leaderboard]);
            await dispatch('serializeLeaderboardState', { type: leaderboard.currentData.name });
          }
        });
        commit('common/setAppState', ['isLoading', false], { root: true });

        resolve();
      })
        .catch((err) => {
          Sentry.captureMessage(`getGroupsLeaderboard error: ${err}`);
          reject(err);
          commit('common/setAppState', ['isLoading', false], { root: true });
        });
    });
  },

  getGroupLeaderboard({ commit, dispatch, rootState }, { groupId, forceUpdate = false }) {
    const { appState } = rootState.common;
    return new Promise((resolve, reject) => {
      commit('common/setAppState', ['isLoading', true], { root: true });
      commit('common/setAppState', ['groupLeaderboardLoading', true], { root: true });

      const weeksAgo = moment().diff(appState.queryDate, 'weeks');
      service.social.getLeaderboard('group', weeksAgo, forceUpdate, groupId)
        .then((currentLeaderboard) => {
          dispatch('markTimestamp');
          service.social.getLeaderboard('group', weeksAgo + 1, forceUpdate, groupId)
            .then(async (previousLeaderboard) => {
              const leaderboard = {
                id: uuidv4(),
                windowsAgo: weeksAgo,
                currentData: currentLeaderboard.scoreboard,
                previousData: previousLeaderboard.scoreboard,
              };

              if (leaderboard.currentData.name === 'group') {
                commit('setLeaderboardState', [leaderboard.currentData.name, leaderboard]);
                await dispatch('serializeLeaderboardState', { type: leaderboard.currentData.name });
              }

              commit('common/setAppState', ['isLoading', false], { root: true });
              resolve();
            });
        })
        .catch((err) => {
          Sentry.captureMessage(`getGroupLeaderboard error: ${err}`);
          reject(err);
          commit('common/setAppState', ['isLoading', false], { root: true });
        });
    });
  },

  exitGroup(context, groupId) {
    Vue.$log.info('exitGroup', groupId);

    return new Promise((resolve) => {
      service.groups.exitGroup(groupId)
        .then(async () => {
          // await dispatch('common/setTimeout', 5000, { root: true });
          resolve();
        })
        .catch(err => Vue.$log.warn('exitGroup error:', err));
    });
  },

  deleteGroup(context, groupId) {
    Vue.$log.info('deleteGroup', groupId);

    return new Promise((resolve) => {
      service.groups.deleteGroup(groupId)
        .then(async () => {
          // await dispatch('common/setTimeout', 5000, { root: true});
          resolve();
        })
        .catch(err => Vue.$log.warn('deleteGroup error:', err));
    });
  },

  updateGroupProfile(context, payload) {
    Vue.$log.info('updateGroupProfile Store', payload);
    return service.groups.updateGroupProfile(payload);
  },
  markTimestamp({ commit }) {
    commit('setLeaderboardState', ['timestamp', new Date().getTime()]);
  },
  getCompanyLeaderboard({ commit, dispatch, rootState }, forceUpdate = false) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      commit('common/setAppState', ['isLoading', true], { root: true });
      commit('common/setAppState', ['companyLeaderboardLoading', true], { root: true });

      const weeksAgo = moment().diff(appState.queryDate, 'weeks');
      service.social.getLeaderboard('company', weeksAgo, forceUpdate).then(async (currentLeaderboard) => {
        dispatch('markTimestamp');
        await service.social.getLeaderboard('company', weeksAgo + 1, forceUpdate).then(async (previousLeaderboard) => {
          const leaderboard = {
            id: uuidv4(),
            windowsAgo: weeksAgo,
            currentData: currentLeaderboard.scoreboard,
            previousData: previousLeaderboard.scoreboard,
          };
          if (leaderboard.currentData.name === 'company') {
            commit('setLeaderboardState', [leaderboard.currentData.name, leaderboard]);
            await dispatch('serializeLeaderboardState', { type: leaderboard.currentData.name });
          }
        });
        commit('common/setAppState', ['isLoading', false], { root: true });

        resolve();
      })
        .catch((err) => {
          Sentry.captureMessage(`getCompanyLeaderboard error: ${err}`);
          reject(err);
          commit('common/setAppState', ['isLoading', false], { root: true });
        });
    });
  },
  getCompanyGroupsLeaderboard({ commit, dispatch, rootState }, forceUpdate = false) {
    const { appState } = rootState.common;

    return new Promise((resolve, reject) => {
      commit('common/setAppState', ['isLoading', true], { root: true });
      commit('common/setAppState', ['companyGroupsLeaderboardLoading', true], { root: true });

      const weeksAgo = moment().diff(appState.queryDate, 'weeks');
      service.social.getLeaderboard('company_groups', weeksAgo, forceUpdate).then(async (currentLeaderboard) => {
        dispatch('markTimestamp');
        await service.social.getLeaderboard('company_groups', weeksAgo + 1, forceUpdate).then(async (previousLeaderboard) => {
          const leaderboard = {
            id: uuidv4(),
            windowsAgo: weeksAgo,
            currentData: currentLeaderboard.scoreboard,
            previousData: previousLeaderboard.scoreboard,
          };

          if (leaderboard.currentData.name === 'company_groups') {
            commit('setLeaderboardState', [leaderboard.currentData.name, leaderboard]);
            await dispatch('serializeLeaderboardState', { type: leaderboard.currentData.name });
          }
        });
        commit('common/setAppState', ['isLoading', false], { root: true });

        resolve();
      })
        .catch((err) => {
          Sentry.captureMessage(`getCompanyGroupsLeaderboard error: ${err}`);
          reject(err);
          commit('common/setAppState', ['isLoading', false], { root: true });
        });
    });
  },
  getPublicChallengeLeaderboard({ commit, dispatch, rootState }, { type }, forceUpdate = false) {
    const { appState } = rootState.common;

    const stateKey = (type === 'company' && 'publicChallengeCompany')
      || (type === 'company_groups' && 'publicChallengeCompanyGroup') || '';

    if (moment().isBefore(appState.publicChallenge.startDate)) {
      return new Promise((resolve) => {
        commit('setLeaderboardState', [stateKey, null]);
        resolve();
      });
    }

    // check the time if it in range then fetch from the public challenge startdate to today
    const weeksAgoStart = moment().diff(appState.publicChallenge.startDate, 'weeks');
    let weeksAgoEnd = moment().diff(appState.publicChallenge.endDate, 'weeks');
    const dayAgoEnd = moment().diff(appState.publicChallenge.endDate, 'days');
    const dayAfter = dayAgoEnd - (weeksAgoEnd * 7);
    if (dayAfter <= 0) {
      weeksAgoEnd = 0;
    } else {
      weeksAgoEnd += 1;
    }
    const weekRangeCount = weeksAgoStart - weeksAgoEnd + 1;
    // Fetch all the week belong to the time range
    const promises = [];
    for (let i = weeksAgoEnd; i <= weeksAgoStart; i += 1) {
      promises.push(service.social.getLeaderboard(type, i, forceUpdate));
    }

    return new Promise((resolve, reject) => {
      commit('common/setAppState', ['isLoading', true], { root: true });
      commit('common/setAppState', ['companyLeaderboardLoading', true], { root: true });

      // Proceed the promish here
      Promise.all(promises).then(async (leaderboardData) => {
        const leaderboard = {
          id: uuidv4(),
          windowsAgo: weeksAgoStart,
          startDate: appState.publicChallenge.startDate,
          endDate: appState.publicChallenge.endDate,
          currentData: leaderboardData[0].scoreboard,
          previousData: leaderboardData[0].scoreboard,
        };

        const consolidatedData = {};

        // Itterator all the data from scoreboard
        leaderboardData.forEach((item, index) => {
          const weekIndex = index + weeksAgoEnd;
          item.scoreboard.data.forEach((companyOrGroup) => {
            if (!consolidatedData[companyOrGroup.id]) {
              // Create space
              consolidatedData[companyOrGroup.id] = {
                tripTotals: {
                  co2: 0,
                  kwh: 0,
                  kwhIfElectric: 0,
                  co2IfElectric: 0,
                  distanceInMeters: 0,
                },
                transitModeCount: {},
                transitModeDistance: {},
              };
            }
            const { tripTotals, totalParticipants } = companyOrGroup;


            const weekStart = (weekIndex > 0
              && moment().isBefore(moment(appState.publicChallenge.endDate), []))
              || moment().isAfter(moment(appState.publicChallenge.endDate), [])
              ? null : moment().clone().startOf('week').add(1, 'day');
            let daysOccur = !weekStart
              ? 7 : (moment(state.leaderboardState.timestamp).diff(weekStart, 'seconds') / 86400);
            if (daysOccur <= 0) {
              daysOccur = 7;
            } else if (daysOccur < 1) {
              daysOccur = 1;
            }
            // console.log(
            //   companyOrGroup.id,
            //   weekIndex,
            //   moment().isBefore(moment(appState.publicChallenge.endDate), []),
            //   tripTotals.co2,
            //   '/',
            //   totalParticipants,
            //   '/',
            //   daysOccur,
            //   '=',
            //   (tripTotals.co2 / (totalParticipants === 0 ? 1 : totalParticipants) / daysOccur),
            // );

            // Accumulator the data
            // Sum up for the total
            consolidatedData[companyOrGroup.id].tripTotals.co2
              += (tripTotals.co2 / (totalParticipants === 0 ? 1 : totalParticipants) / daysOccur);
            consolidatedData[companyOrGroup.id].tripTotals.kwh += tripTotals.kwh;
            consolidatedData[companyOrGroup.id]
              .tripTotals.kwhIfElectric += tripTotals.kwhIfElectric;
            consolidatedData[companyOrGroup.id]
              .tripTotals.co2IfElectric += tripTotals.co2IfElectric;
            consolidatedData[companyOrGroup.id]
              .tripTotals.distanceInMeters += tripTotals.distanceInMeters;
            Object.entries(tripTotals.transitModeCount).forEach(([mode, count]) => {
              if (!consolidatedData[companyOrGroup.id].transitModeCount[mode]) {
                consolidatedData[companyOrGroup.id].transitModeCount[mode] = 0;
              }
              consolidatedData[companyOrGroup.id].transitModeCount[mode] += count;
            });

            Object.entries(tripTotals.transitModeDistance).forEach(([mode, distance]) => {
              if (!consolidatedData[companyOrGroup.id].transitModeDistance[mode]) {
                consolidatedData[companyOrGroup.id].transitModeDistance[mode] = 0;
              }
              consolidatedData[companyOrGroup.id].transitModeDistance[mode] += distance;
            });
          });
        });
        // Data devided by weekRangeCount for taking the average of week
        leaderboard.currentData.data = Object.keys(consolidatedData).map(id => ({
          id,
          ...consolidatedData[id],
          tripTotals: {
            co2: consolidatedData[id].tripTotals.co2 / weekRangeCount,
            kwh: consolidatedData[id].tripTotals.kwh / weekRangeCount,
            kwhIfElectric: consolidatedData[id].tripTotals.kwhIfElectric / weekRangeCount,
            co2IfElectric: consolidatedData[id].tripTotals.co2IfElectric / weekRangeCount,
            distanceInMeters: consolidatedData[id].tripTotals.distanceInMeters / weekRangeCount,
          },
        }));

        // Public challenge dont have prev data so just leave it the same with current data
        leaderboard.previousData = leaderboard.currentData;
        if (leaderboard.currentData) {
          commit('setLeaderboardState', [stateKey, leaderboard]);
          await dispatch('serializeLeaderboardState', { type: stateKey });
        }
        commit('common/setAppState', ['isLoading', false], { root: true });
        resolve();
      })
        .catch((err) => {
          Sentry.captureMessage(`getPublicChallengeLeaderboard ${stateKey} error: ${err}`);
          reject(err);
          commit('common/setAppState', ['isLoading', false], { root: true });
        });
    });
  },
  getPublicChallengeCompanyLeaderboard({ dispatch }, forceUpdate = false) {
    dispatch('getPublicChallengeLeaderboard', { type: 'company', forceUpdate });
  },
  getPublicChallengeCompanyGroupLeaderboard({ dispatch }, forceUpdate = false) {
    dispatch('getPublicChallengeLeaderboard', { type: 'company_groups', forceUpdate });
  },

  requestPhonebook({ commit }) {
    const id = uuidv4();
    const phonebookObject = {
      id,
    };

    commit('user/setState', ['phonebook', phonebookObject], { root: true });
    callSdk(`action=${ActionEnum.REQUEST_CONTACTS}&requestID=${encodeURIComponent(id)}`, this);
  },

  async getMessages({ commit }) {
    try {
      const response = await service.social.getMessages();
      // await dispatch('common/setTimeout', 3000, { root: true });
      // Vue.$log.info('response', response);
      if (!this.messages || !isEqual(this.messages, response.slice(-10))) {
        commit('setState', ['messages', response.slice(-10)]);
      }

      return response;
    } catch (error) {
      return error;
    }
  },

  async markMessage(context, payload) {
    Vue.$log.info('markMessage', payload);

    try {
      Vue.$log.info('markMessage', payload.id);
      if (!payload.wasRead) {
        await service.social.markMessage('readMessage', payload.id);
      }

      if (!payload.deliveredAt) {
        await service.social.markMessage('deliveryMessage', payload.id);
      }

      // await dispatch('common/setTimeout', 50, { root: true });
      // Vue.$log.info('response', response);

      return true;
    } catch (error) {
      return error;
    }
  },

  async getNewsFeed({ commit, dispatch, rootState }) {
    try {
      // Vue.$log.info('getNewsFeed');

      const { userLang } = rootState.common.appState;

      commit('setState', ['processedNewsFeed', {}]);

      const webflowURLs = {
        en: 'https://d39yuzuzue363b.cloudfront.net/news/rss.xml',
        de: 'https://d39yuzuzue363b.cloudfront.net/de-news/rss.xml',
        fr: 'https://d39yuzuzue363b.cloudfront.net/fr-news/rss.xml',
        it: 'https://d39yuzuzue363b.cloudfront.net/it-news/rss.xml',
      };

      const response = await service.social.getNewsFeed(webflowURLs.de);

      let rssPayload = convert.xml2js(response.data, {
        compact: true,
        spaces: 2,
        textKey: 'text',
        attributesKey: 'attributes',
      })?.rss?.channel?.item;

      if (!Array.isArray(rssPayload)) {
        const temp = rssPayload;
        rssPayload = [];
        rssPayload.push(temp);
      }

      // rssPayload.[0].pubDate.text = 'Wed, 16 Dec 2020 08:54:48 GMT';
      // rssPayload.[1].pubDate.text = 'Wed, 6 Jan 2021 08:54:48 GMT';
      // rssPayload.[2].pubDate.text = 'Wed, 24 Mar 2021 08:54:48 GMT';
      // rssPayload.[3].pubDate.text = 'Wed, 17 Mar 2021 08:54:48 GMT';
      // rssPayload.[4].pubDate.text = 'Wed, 7 Apr 2021 08:54:48 GMT';

      await dispatch('processNewsFeed', rssPayload);
      commit('common/setAppState', ['newsFeedLang', userLang], { root: true });

      // Vue.$log.info('rssPayload', rssPayload);

      return response;
    } catch (error) {
      return error;
    }
  },

  processNewsFeed({ commit }, newsFeed) {
    // Vue.$log.info('Starting processNewsFeed', newsFeed);
    // console.time('processNewsFeed');

    const processedNewsFeed = {};
    const flattenedNewsFeed = [];

    newsFeed.forEach(record => flattenedNewsFeed.push(record));

    const sortedNewsFeed = flattenedNewsFeed
      .slice()
      .sort((a, b) => moment(b.pubDate.text) - moment(a.pubDate.text));

    sortedNewsFeed.forEach((news) => {
      if (news.pubDate.text) {
        const endOfCurrentMonth = moment().endOf('month');

        const pubDate = moment(news.pubDate.text);

        const monthsAgo = endOfCurrentMonth.diff(pubDate, 'months');

        if (processedNewsFeed?.[monthsAgo]) {
          processedNewsFeed[monthsAgo] = [...processedNewsFeed[monthsAgo], news];
        } else {
          processedNewsFeed[monthsAgo] = [news];
        }
      }
    });

    // Vue.$log.info('processedNewsFeed', processedNewsFeed);
    // console.timeEnd('processNewsFeed');
    commit('setState', ['processedNewsFeed', processedNewsFeed]);
  },

  async handleTappedNotification({
    commit, //
    getters,
    dispatch,
    rootGetters,
  }, { vm }) {
    const { notification } = kibanaEvents;
    const { messageId, chatId, name } = getters.getTappedNotification?.meta || {};

    Vue.$log.info('Will handleTappedNotification');

    dispatch('common/insertEvent', {
      category: notification.category,
      action: notification.userEngaged.action,
      type: name,
      metaPublic: {
        messageId,
        chatId,
        name,
        notificationType: 'push',
        notificationGateway: rootGetters['common/getNotificationGateway'],
      },
    }, {
      root: true,
    });

    vm.$root.$emit('close-all-modals');

    const routeToGo = getters.getNotificationHref(getters.getTappedNotification);

    Vue.$log.info('handleTappedNotification: route', routeToGo);

    // If we are already on the same route, prevent navigation
    if (vm.$router.currentRoute.name !== routeToGo.name) {
      vm.$router
        .push(routeToGo)
        .catch((error) => {
          Vue.$log.info('Router: Push Notification routing errored', error);
        })
        .finally(() => {
          Vue.$log.info('Router: Push Notification routing did finish');
        });
    }

    Vue.$log.info('Will remove tapped notification', getters.hasTappedNotification);
    commit('spliceFromNotificationsQueue', getters.getTappedNotificationIndex);
    Vue.$log.info('Did remove tapped notification', getters.hasTappedNotification);
  },

  pushNotification({ commit }, payload) {
    let notification = payload;

    const sampleNotifications = [{
      meta: {
        href: 'internal:join-challenges',
        imageUrl: 'https://d375st87gcum0p.cloudfront.net/images/badges/happy-earth.png',
        messageId: uuidv4(),
      },
      notification: {
        title: 'Congratulations!',
        body: 'You have completed the Happy earth Challenge. You have completed the Happy earth Challenge.',
      },
    },
    {
      meta: {
        href: 'internal:settings',
        imageUrl: 'https://d375st87gcum0p.cloudfront.net/images/badges/car-free.png',
        messageId: uuidv4(),
      },
      notification: {
        title: 'Car-Free Week',
        body: 'You earned 5 Green Points for successfully traveling one week without using a car.',
      },
    },
    {
      meta: {
        href: 'internal:challenges',
        imageUrl: 'https://d375st87gcum0p.cloudfront.net/images/badges/walking.png',
        messageId: uuidv4(),
      },
      notification: {
        title: 'Daily Happy Earth',
        body: 'Congratulations! You made the earth happy for a whole day – and earned 1 Green Point!',
      },
    },
    {
      meta: {
        href: 'internal:push-notification-debugger',
        imageUrl: 'https://d375st87gcum0p.cloudfront.net/images/badges/cdc-gold.png',
        messageId: uuidv4(),
      },
      notification: {
        title: 'Weekly Happy Earth',
        body: 'Congratulations! You made the earth happy for a whole week – and earned 3 Green Points!',
      },
    },
    {
      meta: {
        // href: 'internal:points',
        imageUrl: 'https://d375st87gcum0p.cloudfront.net/images/badges/cdc-silver.png',
        messageId: uuidv4(),
      },
      notification: {
        title: 'Monthly Happy Earth',
        body: 'Congratulations! You made the earth happy for a whole month – and earned 10 Green Points!',
      },
    }];

    if (isEmpty(notification)) {
      notification = sampleNotifications[getRandomInt(sampleNotifications.length)];
    }

    commit('pushToNotificationsQueue', notification);
  },
};

// Getter functions
const getters = {
  getRankClass: () => (rank) => {
    switch (rank) {
      case 1:
        return 'cc-yellow';
      case 2:
        return 'cc-light-silver';
      case 3:
        return 'cc-light-bronze';
      default:
        return 'white';
    }
  },

  getCupIcon: () => (rank) => {
    switch (rank) {
      case 1:
        return 'icons/icon-cup-gold';
      case 2:
        return 'icons/icon-cup-silver';
      case 3:
        return 'icons/icon-cup-bronze';
      default:
        return '';
    }
  },

  /**
   *
   * Filters through groups in leaderboardState
   * if param provided returns user groups with given role
   * if not returns all user groups
   *
   * @param {String} role - One of 'owner', 'admin', 'participant', 'hidden', 'watcher'
   */
  getUserGroups: ({ leaderboardState }, getters, rootState) => (role) => {
    const { appState } = rootState.common;
    const { leaderboardType } = appState;

    const roles = ['owner', 'admin', 'participant', 'hidden', 'watcher'];

    if (role && !roles.includes(role)) {
      throw new Error('Provide a valid role');
    }

    return leaderboardState?.groups?.[leaderboardType]?.filter((group) => {
      if (role) {
        if (group.userRole === role) {
          return group;
        }
        return false;
      }

      return group;
    });
  },

  getCompanyGroups: ({ leaderboardState }, getters, rootState) => (role) => {
    const { appState } = rootState.common;
    const { leaderboardType } = appState;

    const roles = ['owner', 'admin', 'participant', 'hidden', 'watcher'];

    if (role && !roles.includes(role)) {
      throw new Error('Provide a valid role');
    }
    return leaderboardState?.company_groups?.[leaderboardType]?.filter((group) => {
      if (role) {
        if (group.userRole === role) {
          return group;
        }
        return false;
      }

      return group;
    });
  },

  /**
   *
   * Returns user role in a given group
   *
   * @param {Object} groupObj - Regular group object
   * @param {String} role - One of 'owner', 'admin', 'participant', 'hidden', 'watcher'
   * @return {String} role name
   */
  getUserRoleByGroupId: ({ leaderboardState }) => (groupId) => {
    const { groups } = leaderboardState;

    // eslint-disable-next-line max-len
    const didNotTravelGroup = groups?.currentData?.didNotTravel?.find(groupObject => groupObject.id === groupId);
    // eslint-disable-next-line max-len
    const didTravelGroup = groups?.currentData?.didTravel?.find(groupObject => groupObject.id === groupId);

    // Vue.$log.info('didNotTravelGroup', didNotTravelGroup);
    // Vue.$log.info('didTravelGroup', didTravelGroup);

    return didNotTravelGroup?.userRole || didTravelGroup?.userRole;
  },

  /**
   *
   * Returns member role in a given group
   *
   * @param {Object} groupObj - Regular group object
   * @param {String} memberId - Member's user id
   * @return {String} role name
   */
  getMemberRoleInGroup: () => (groupObj, memberId) => {
    if (!groupObj && !memberId) {
      return 'Provide group object and/or memberId';
    }

    const member = groupObj.members.find(m => m.id === memberId);

    if (!member) {
      return false;
    }

    return member?.role;
  },


  /**
   *
   * Returns user role in a given groupId
   *
   * @param {Object} groupId
   * @return {String} role name
   */
  getUserRoleInGroup: (context, getters, rootState) => (groupObj, role) => {
    if (!groupObj && !role) {
      return 'Provide group object and/or role';
    }

    const participant = groupObj.members.find(
      p => p.id.split('_').pop() === rootState.user.user.id && p.role === role,
    );

    return participant?.role;
  },

  /**
   *
   * Returns the group object of a given group id
   *
   * @param {String} groupId
   * @return {Object} group
   */
  getGroupById: ({ leaderboardState }) => (groupId) => {
    const { groups } = leaderboardState;
    // const { leaderboardType } = rootState.common.appState;

    // eslint-disable-next-line max-len
    const didNotTravel = groups?.currentData?.didNotTravel?.find(groupObject => groupObject.id === groupId);
    // eslint-disable-next-line max-len
    const didTravel = groups?.currentData?.didTravel?.find(groupObject => groupObject.id === groupId);

    Vue.$log.info('didNotTravel', didNotTravel);
    Vue.$log.info('didTravel', didTravel);
    return didNotTravel || didTravel;
  },

  hasGroupLeaderboard: ({ leaderboardState }) => leaderboardState?.groups?.currentData
    ?.data?.length > 0,

  /**
   *
   * Filters through groups in leaderboardState
   * if param provided returns user groups with given role
   * if not returns all user groups
   *
   * @param {String} role - One of 'owner', 'admin', 'participant', 'hidden', 'watcher'
   */
  getCompaniesLeaderboard: ({ leaderboardState }, getters, rootState) => (role) => {
    const { appState } = rootState.common;
    const { leaderboardType } = appState;
    const roles = ['owner', 'admin', 'participant', 'hidden', 'watcher'];
    if (role && !roles.includes(role)) {
      throw new Error('Provide a valid role');
    }
    return leaderboardState?.company?.[leaderboardType];
  },

  getCompanyPublicChallengeLeaderboard: ({ leaderboardState }) => (role) => {
    const roles = ['owner', 'admin', 'participant', 'hidden', 'watcher'];
    if (role && !roles.includes(role)) {
      throw new Error('Provide a valid role');
    }
    return leaderboardState?.publicChallengeCompany?.averageCo2 || [];
  },
  getCompanyGroupPublicChallengeLeaderboard: ({ leaderboardState }) => (role) => {
    const roles = ['owner', 'admin', 'participant', 'hidden', 'watcher'];
    if (role && !roles.includes(role)) {
      throw new Error('Provide a valid role');
    }
    return leaderboardState?.publicChallengeCompanyGroup?.averageCo2 || [];
  },

  hasCompanyLeaderboard: ({ leaderboardState }) => leaderboardState?.company?.currentData
    ?.data?.length > 0,

  hasCompanyGroupsLeaderboard: ({ leaderboardState }) => leaderboardState
    ?.company_groups?.currentData?.data?.length > 0,

  isSelfInGroup: (state, getters, rootState) => (memberId) => {
    const { id } = rootState.user.user;
    return memberId === id;
  },

  getUnreadMessages: ({ messages }) => {
    const unreadMessages = messages.filter(message => !message.wasRead);
    // Vue.$log.info('unreadMessages', unreadMessages);
    return unreadMessages;
  },

  hasNewsFeed: state => !isEmpty(state.processedNewsFeed),

  getNewsFeed: state => state.processedNewsFeed || false,

  /**
   * getLimitedNewsFeed returns the same structure as processedNewsFeed
   */
  getLimitedNewsFeed: (state, getters) => (limit) => {
    const limitedNewsFeed = {};
    const newsFeed = getters.getNewsFeed;

    // Vue.$log.info('Getter newsFeed', newsFeed);

    let counter = 0;

    if (limit > 0) {
      Object.keys(newsFeed).forEach((day) => {
        newsFeed[day].filter((news) => {
          if (counter < limit) {
            if (!limitedNewsFeed[day]) {
              limitedNewsFeed[day] = [];
            }
            limitedNewsFeed[day].push(news);
            counter += 1;
          }
          return news;
        });
      });

      // Vue.$log.info('limitedNewsFeed', limitedNewsFeed);
      return limitedNewsFeed;
    }

    return false;
  },

  /**
   * WIP
   * Meta object of a message consist a property called `href`
   * `"href":"internal:achievements/6177f74696c3b20475bb1239"`
   * As of now there is just a weak contract agreed on January 2021
   *
   * Current agreements:
   * Assume internal/external respectively for internal/external routing inference.
   * After colon `:` expect first segment to be a route name that must
   * correspond to a route name in `router.js`
   * Expect second segment to be a query/prop to be passed onto the next route
   * This is not very scaleable hence weak contract.
   * Maybe rest of the string after `:` can be used as `path` in $router instead of named route
   *
   * @param {Object} message - Message payload
   * @returns {Object|Boolean} Vue-Router Object or false
   */
  getNotificationHref: () => (message) => {
    const { href } = message?.meta || {};

    if (href) {
      const route = {
        // name: 'journeys',
        name: href?.split(':')[1].split('/')[0],
        id: href?.split(':')[1].split('/')[1],
        isInternal: href?.split(':')[0] === 'internal',
      };

      return route;
    }

    return false;
  },

  /**
   * @param {Array} notificationsQueue - List of notification objects as sent by the SDK
   * @returns {Number} Index + 1 of the tapped notification
   */
  hasTappedNotification({ notificationsQueue }) {
    const isTappedNotification = notificationsQueue.findIndex(el => el.isTapped);
    return !!(isTappedNotification + 1);
  },

  /**
   * @param {Array} notificationsQueue - Notification objects as sent by the SDK
   * @returns {Number} Index of the tapped notification
   */
  getTappedNotificationIndex({ notificationsQueue }) {
    const tappedNotificationIndex = notificationsQueue.findIndex(el => el.isTapped);
    return tappedNotificationIndex;
  },

  /**
   *
   * @param {Array} notificationsQueue - Notification objects as sent by the SDK
   * @returns {Object} Tapped notification object as sent by the SDK
   */
  getTappedNotification({ notificationsQueue }) {
    const tappedNotification = notificationsQueue.find(el => el.isTapped);
    return tappedNotification;
  },

  inAppNotificationsQueue({ notificationsQueue }) {
    const inAppNotifications = notificationsQueue.filter(el => !el.isTapped);
    return inAppNotifications;
  },
};


export default {
  namespaced: true,
  state: () => (state),
  getters,
  actions,
  mutations,
};
