import { Line, mixins } from 'vue-chartjs';
// import Vue from 'vue';

const { reactiveProp } = mixins;


function calculateLinearGradient(chart, datapoints) {
  const heighestPoint = Math.max(...datapoints.data);
  const totalYPixels = chart.scales['y-axis-0'].height;
  const heighestTick = chart.scales['y-axis-0'].end;

  // Vue.$log.info('chart.scales', chart.scales);
  // Vue.$log.info('heighestPoint', heighestPoint);
  // Vue.$log.info('totalYPixels', totalYPixels);
  // Vue.$log.info('heighestTick', heighestTick);

  const fourthItem = ((heighestPoint / heighestTick) * totalYPixels) || 0;
  return chart.ctx.createLinearGradient(0, 0, 0, fourthItem);
}


export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options'],
  watch: {
    options() {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.addPlugin({
      id: 'responsive-gradient',
      beforeRender(chart) {
        const { datasets } = chart.data;
        if (chart && datasets.length > 0) {
          let gradientYellow;
          let gradientRed;

          const gradientGreen = calculateLinearGradient(chart, datasets[0]);
          gradientGreen.addColorStop(1, 'rgba(255, 132, 132, 0.05)');
          gradientGreen.addColorStop(0, 'rgba(255, 132, 132, 1)');

          if (datasets.length === 3) {
            gradientYellow = calculateLinearGradient(chart, datasets[1]);
            gradientYellow.addColorStop(1, 'rgba(255, 230, 38, 0.1)');
            gradientYellow.addColorStop(0, 'rgba(255, 219, 65, 1)');

            gradientRed = calculateLinearGradient(chart, datasets[2]);
            gradientRed.addColorStop(1, 'rgba(159, 0, 14, 0.2)');
            gradientRed.addColorStop(0, 'rgba(159, 0, 14, 1)');
          }

          datasets.forEach((dataset) => {
            // eslint-disable-next-line no-underscore-dangle
            const model = dataset._meta[Object.keys(dataset._meta)[0]].$filler.el._model;
            if (dataset.label === 'Human-Powered' || dataset.label === 'Emission') {
              model.backgroundColor = gradientGreen;
            } else if (dataset.label === 'Electric') {
              model.backgroundColor = gradientYellow;
            } else if (dataset.label === 'Fossil') {
              model.backgroundColor = gradientRed;
            }
          });
        }
      },
    });
    this.renderChart(this.chartData, this.options);
  },
};
