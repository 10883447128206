<template>
  <div>
    <div
      id="shop__company-carousel"
      class="w-100 px-4 flex-horizontal-scroll"
      style="overflow-x: scroll; overflow-y: hidden;"
      >
      <div
        class="d-inline-flex flex-row"
        >
        <div
          v-for="company in sortedCompaniesWithOffers"
          :key="company.company.id"
          class="d-inline-flex flex-column text-center align-items-center"
          style="width: 80px; margin: 0 2px;"
          @click="$router.push({ name: 'company', params: { companyId: company.company.id }})"
          >
          <div class="image">
            <cc-image
              :src="getLocalizedResource(company.company.metaPublic.resources).logo"
              class="border-radius-full bg-white object-fit-cover"
              style="width: 80px; height: 80px;"
              />
          </div>

          <cc-text
            v-if="true"
            v-truncate:22
            variant="body-xs"
            class="pt-2"
            >
            {{ getLocalizedResource(company.company.metaPublic.resources).name }}
          </cc-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ccImage from '@/components/primitives/Image.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccImage,
    ccText,
  },
  computed: {
    ...mapState({
      companiesWithOffers: state => state.company.companiesWithOffers,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
    }),
    carouselData() {
      return this.companiesWithOffers.map((company) => {
        const resource = this.getLocalizedResource(company.company.metaPublic.resources);

        return {
          id: company.id,
          name: resource.name,
          logo: resource.logo,
        };
      });
    },
    sortedCompaniesWithOffers() {
      const sortedCompaniesWithOffers = this.companiesWithOffers
        .slice()
        .sort((a, b) => a.company.name.localeCompare(b.company.name))
        .filter(c => c?.offers?.length);

      return sortedCompaniesWithOffers;
    },
  },
};
</script>

<style>
.image {
  width: 64px !important;
  height: 64px !important;
}
</style>
