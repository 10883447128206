<template>
  <div
    :id="id"
    class="io-item"
    >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    threshold: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      io: null,
    };
  },
  computed: {
    ioOptions() {
      return {
        rootMargin: '0px 0px 0px 0px',
        threshold: this.threshold,
      };
    },
    getElement() {
      return document.getElementById(this.id);
    },
  },
  mounted() {
    setTimeout(() => {
      this.initIntersectionObserver();
    }, 250);
  },
  beforeDestroy() {
    if (this.io) {
      this.io.disconnect();
    }
  },
  methods: {
    initIntersectionObserver() {
      const { ioCallback, ioOptions } = this;
      this.io = new IntersectionObserver(ioCallback, ioOptions);
      this.io.observe(this.getElement);
    },
    ioCallback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.$emit('io-intersecting-callback', this.item);
        }
        this.$emit('io-callback', entry);
      });
    },
  },
};
</script>

<style>
</style>
