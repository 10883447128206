<template>
  <div
    id="canton-list-selector"
    class="list-container w-100 bg-blue-darkest d-flex flex-wrap justify-content-start
    overflow-scroll-y overflow-x-hidden canton-selection-container p-4"
    >
    <div
      v-for="canton in Object.keys(cantons)"
      :key="cantons[canton].name"
      class="list-item d-flex flex-column align-items-center text-capitalize
      font-weight-sbold font-size-14"
      :data-ruid="`canton-${cantons[canton].name}`"
      @click="selectCanton(canton)"
      >
      <div
        style="width: 64px; height: 64px;"
        class="d-flex justify-content-center align-items-center"
        :class="canton === selectedCanton ? 'selected-canton-logo' : ''"
        >
        <cc-svg-base
          color-class="canton-logo-1-5x"
          :svg="`canton-icons/${cantons[canton].name}`"
          />
      </div>
      {{ cantons[canton].name }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cantons } from '@/mixins/cantons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccSvgBase,
  },
  data() {
    return {
      cantons,
      selectedCanton: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  mounted() {
    this.selectedCanton = this.user.city;
  },
  created() {
    this.$nextTick(() => {
      disableBodyScroll(document.querySelector('#canton-list-selector'));
    });
  },
  destroyed() {
    enableBodyScroll(document.querySelector('#canton-list-selector'));
  },
  methods: {
    selectCanton(canton) {
      this.$emit('selected-canton', canton);
      this.selectedCanton = canton;
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-canton-logo {
  background: $blue-light;
  border-radius: $border-radius-lg;
}

.list-container {
  border-radius: $border-radius-lg;
  width: 100%;
}

.list-item {
  flex: 0 33.3%;
  min-width: 33.3%;
  margin-bottom: 2%;
  word-wrap: break-word;
}
</style>
