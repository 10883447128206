<template>
  <cc-view
    :id="id"
    >
    <cc-wrapper
      class="h-100 text-center safe-area-inset-bottom flex-grow-1"
      align="between"
      >
      <cc-wrapper>
        <cc-svg-base
          class="mb-4"
          svg="illustrations/company-onboard"
          />
        <cc-heading
          variant="h4"
          class="mb-4"
          >
          {{ $t('addOnboardCodeTitle') }}
        </cc-heading>

        <cc-text
          v-if="!isInOnboarding"
          color="muted"
          class="mb-5 mx-5"
          >
          {{ $t('addOnboardCodeBody') }}
        </cc-text>
        <cc-text
          v-if="isInOnboarding"
          color="muted"
          class="mb-5 mx-5"
          >
          {{ $t('addOnboardCodeBodyWithSkip') }}
        </cc-text>

        <validation-observer
          ref="form"
          class="w-100"
          >
          <validation-provider
            v-slot="{ errors }"

            rules="required|mOrNDigits:4,6|alpha_num"
            :name="$t('onboardCode')"
            >
            <cc-input
              v-model.trim="code"
              v-ruid="`${id}__input__onboard-code`"
              :name="$t('onboardCode')"
              type="text"
              class="pt-2 px-6"
              :label="$t('companyOrGroupCode')"
              align="center"
              :placeholder="$t('enterCode')"
              :has-visible-label="0"
              @blur="setAppState(['errorMessage', null])"
              @keyup.enter.native="primaryActionHandler"
              />
            <cc-error-message
              :message="errors[0]"
              alignment="center"
              />
          </validation-provider>
        </validation-observer>

        <cc-error-message
          :message="validationMessage"
          alignment="center"
          />

        <cc-button
          class="mt-4"
          :data-ruid="`${id}__primary-action`"
          :text="$t('submitCode')"
          :disabled="!code"
          variant="primary"
          :has-loading-feedback="true"
          @click="primaryActionHandler"
          />
        <span
          v-if="isInOnboarding"
          class="pt-4 text-blue-lightest font-size-16"
          @click="skip"
          >
          {{ $t('txtSkip') }}
        </span>
        <span
          v-if="!isInOnboarding"
          class="pt-4 text-blue-lightest font-size-16"
          @click="cancel"
          >
          {{ $t('txtCancel') }}
        </span>
      </cc-wrapper>

      <div
        v-if="false"
        class="pb-6"
        >
        <cc-text
          variant="body-xs"
          class="text-uppercase pb-4"
          >
          {{ $t('companiesParticipating') }}
        </cc-text>
        <cc-svg-base
          svg="logos/company-postfinance"
          class="mr-2"
          style="height:28px;"
          />
        <cc-svg-base
          :svg="`partner-logo/swisscom-horizontal-${appState.userLang}`"
          class="ml-2"
          />
      </div>
    </cc-wrapper>

    <cc-onboard-code-feedback-modal
      v-if="isOnboardCodeFeedbackModalDisplayed"
      ref="verify-email-modal"
      :text="errorMessage"
      :header="errorHeader"
      @close-modal="isOnboardCodeFeedbackModalDisplayed = false"
      />
  </cc-view>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import validator from '@/mixins/validator';

import ccView from '@/components/constructs/View.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccInput from '@/components/primitives/Input.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccErrorMessage from '@/components/constructs/ErrorMessage.vue';
import ccOnboardCodeFeedbackModal from '@/components/modals/OnboardCodeFeedbackModal.vue';

export default {
  components: {
    ccView,
    ccWrapper,
    ccHeading,
    ccText,
    ccInput,
    ccButton,
    ccSvgBase,
    ccErrorMessage,
    ccOnboardCodeFeedbackModal,
  },
  mixins: [validator],
  props: {
    isInOnboarding: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      id: 'add-onboard-code',
      code: '',
      validationMessage: '',
      isOnboardCodeFeedbackModalDisplayed: false,
      errorHeader: '',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
    }),
    capitalizedInput() {
      return this.code.toUpperCase();
    },
  },
  watch: {
    capitalizedInput(value) {
      // Update the value of userInput with the capitalized input
      this.code = value;
    },
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async primaryActionHandler() {
      try {
        this.$refs.form.reset();

        const success = await this.$refs.form.validate();

        if (!success) {
          return;
        }

        await this.$store.dispatch('user/setOnboardCode', this.code);

        await this.$store.dispatch('user/getUserTags');
        if (!this.isInOnboarding) {
          this.$router
            .push({ name: 'onboard-code', params: { isInOnboarding: this.isInOnboarding } })
            .catch(() => {});
        } else {
          this.$router
          .push({ name: 'onboarding-code', params: { isInOnboarding: this.isInOnboarding } })
          .catch(() => {});
        }
      } catch (error) {
        this.$log.info('primaryActionHandler', error.response);
        if (error?.response?.status === 429) {
          this.errorHeader = this.$t('tooManyOnboardCodeRequestsErrorHeader');
          this.errorMessage = this.$t('tooManyOnboardCodeRequestsErrorMessage');
          this.isOnboardCodeFeedbackModalDisplayed = true;
          this.validationMessage = '';
        } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
          this.isOnboardCodeFeedbackModalDisplayed = true;
          this.validationMessage = '';
        } else {
          this.validationMessage = this.$t('generalErrorMessage');
        }
      }
    },
    skip() {
      this.$router
          .push({ name: 'footprint' })
          .catch(() => {});
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>
