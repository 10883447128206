<template>
  <cc-view :id="id">
    <cc-wrapper class="h-100 text-center safe-area-inset-bottom flex-grow-1">
      <form
        novalidate
        @submit.prevent="loginByEmail"
        >
        <cc-heading class="mb-5">
          {{ $t('loginByEmailAddress') }}
        </cc-heading>

        <cc-text
          color="muted"
          class="mb-5 mx-5"
          >
          {{ $t('loginByEmailAddressDescription') }}
        </cc-text>

        <div class="px-4 pb-5 text-left">
          <validation-observer
            ref="form"
            >
            <validation-provider
              v-slot="{
                invalid,
                failed,
              }"
              rules="email"
              name="email"
              >
              <cc-input
                id="email"
                v-model.trim="email"
                class="pb-4"
                :state="failed && invalid ? 'is-invalid' : null"
                type="email"
                autocomplete="email"
                :label="$t('emailAddressLabel')"
                :placeholder="$t('emailAddressLabel')"
                :has-visible-label="0"
                />
            </validation-provider>

            <validation-provider
              name="password"
              class="position-relative"
              >
              <cc-input
                id="password"
                v-model.trim="password"
                type="password"
                class="pb-4"
                :label="$t('passwordLabel')"
                :placeholder="$t('passwordLabel')"
                autocomplete="off"
                spellcheck="false"
                autocapitalize="off"
                :has-visible-label="0"
                :has-password-toggle="true"
                />
            </validation-provider>
          </validation-observer>

          <cc-error-message
            v-if="true"
            :message="errors[0]"
            alignment="center"
            class="pb-3"
            />
          <cc-button
            type="submit"
            class="mt-4"
            :variant="(isSubmitting || !isEmailAndPasswordFilled)
              ? 'secondary'
              : 'primary'"
            :disabled="(isSubmitting || !isEmailAndPasswordFilled)"
            :text="isSubmitting ? $t('txtSending') : $t('txtLogin')"
            />
        </div>
      </form>
    </cc-wrapper>
  </cc-view>
</template>

<script>
// import { validate } from 'vee-validate';
import validator from '@/mixins/validator';
import { mapState } from 'vuex';

export default {
  mixins: [validator],
  data() {
    return {
      id: 'login-by-email',
      isLoading: false,
      email: null,
      password: null,
      errors: [],
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
    isEmailAndPasswordFilled() {
      return !!this.email && !!this.password;
    },
  },
  methods: {
    async loginByEmail() {
      try {
        this.$log.info('loginByEmail');
        this.$refs.form.reset();

        this.errors = [];
        const { isValid, errors } = await this.$refs.form.validateWithInfo();

        this.$log.info('errors', errors);

        if (!isValid) {
          this.errors.push(errors.email?.[0]);
          this.$log.info('Form !isValid', isValid);
          return;
        }
        this.isSubmitting = true;

        await this.$store.dispatch('user/login', {
          email: this.email,
          password: this.password,
          context: this.appState.context,
        });

        this.$router.push({ name: 'account-verified' });
      } catch (error) {
        this.$log.error('loginByEmail', error);
        this.errors.push(this.$t('genericErrorTitle'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
