<template>
  <cc-view :id="`company__${companyId}`">
    <cc-charts-loading
      v-if="loading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <div v-else>
      <cc-card
        ruid="store-detail"
        card-background="white"
        class="pb-5"
        variant="plain"
        >
        <template #body>
          <div class="position-relative">
            <cc-image
              :alt="localizedCompany.name"
              style="width: 100%; height: auto; min-height: 150px;"
              :src="storeImage"
              :has-placeholder="true"
              />

            <cc-image
              :alt="localizedCompany.name"
              style="width: 80px; height: 80px; right: 0px; bottom: -40px;"
              class="border-radius-full mr-4 position-absolute shadow bg-white object-fit-cover"
              :src="localizedCompany.logo"
              :has-placeholder="true"
              />
          </div>

          <div class="px-4 pt-3">
            <cc-heading
              variant="h2"
              class="pb-2 w-75"
              color="grey-dark"
              >
              {{ localizedCompany.name }}
            </cc-heading>

            <cc-text
              color="grey-light"
              class="pb-4"
              >
              {{ localizedCompany.catchPhrase }}
            </cc-text>

            <cc-editor-renderer
              v-if="localizedCompany.details"
              id="store-details"
              :contents="localizedCompany.details"
              :styles="{ paragraph: 'grey-light', header: 'grey-dark' }"
              />

            <div
              v-for="(social, key, i) in getCompanyById(companyId).company.metaPublic.links"
              :key="i"
              >
              <cc-settings-list-item
                v-if="social.target"
                :ruid="`${companyId}__${social.label}`"
                :label="social.label"
                label-color="grey-light"
                arrow-color="grey-light"
                divider-color="grey-lighter"
                arrow
                :last="
                  i + 1 === Object.keys(getCompanyById(companyId).company.metaPublic.links).length
                "
                @click.native="socialsClickHandler(social)"
                />
            </div>
          </div>
        </template>
      </cc-card>
    </div>
  </cc-view>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccView from '@/components/constructs/View.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccEditorRenderer from '@/components/constructs/EditorRenderer.vue';
import sandbox from '@/mixins/sandbox';

export default {
  components: {
    ccCard,
    ccView,
    ccChartsLoading,
    ccHeading,
    ccText,
    ccSettingsListItem,
    ccImage,
    ccEditorRenderer,
  },
  mixins: [sandbox],
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      companyState: state => state.company,
      appState: state => state.common.appState,
    }),
    ...mapGetters({
      getProductById: 'company/getProductById',
      getCompanyById: 'company/getCompanyById',
      getLocalizedResource: 'company/getLocalizedResource',
    }),
    localizedCompany() {
      const { getLocalizedResource, getCompanyById, companyId } = this;
      return getLocalizedResource(getCompanyById(companyId)?.company?.metaPublic?.resources);
    },
    productInfo() {
      return this.getProductById(this.companyId);
    },
    storeImage() {
      return this.localizedCompany?.images?.[0];
    },
  },
  async created() {
    const { offer } = this.kibanaEvents;

    if (!this.companyState.player) {
      await this.$store.dispatch('company/getPlayer');
    }

    this.$store.dispatch('common/insertEvent', {
      category: offer.category,
      action: offer.storeViewed.action,
      type: this.companyId,
      metaPublic: {
        storeId: this.companyId,
      },
    });

    this.loading = false;
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
      setAppState: 'common/setAppState',
    }),
    socialsClickHandler(social) {
      this.$store.dispatch('common/openUrlExternal', social.target);
    },
  },
};
</script>
