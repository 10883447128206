const kibanaEvents = {
  navigation: {
    category: 'navigation',
    appOpen: {
      action: 'app-opened',
    },
    pageView: {
      action: 'view-displayed',
    },
    modalDisplayed: {
      action: 'modal-displayed',
    },
    conditionalContentDisplayed: {
      action: 'conditional-content-displayed',
    },
  },
  notification: {
    category: 'notification',
    userEngaged: {
      action: 'user-engaged',
    },
  },
  social: {
    category: 'social',
    shareSCC: {
      action: 'url-shared',
    },
  },
  group: {
    category: 'group',
    invitationSent: {
      action: 'invitation-sent',
    },
  },
  appReview: {
    category: 'app-review',
    requested: {
      action: 'requested',
    },
  },
  phonebook: {
    category: 'phonebook',
    uploadIntended: {
      action: 'upload-intended',
    },
    uploaded: {
      action: 'uploaded',
    },
  },
  permission: {
    category: 'permission',
    contacts: {
      action: 'contacts',
    },
    location: {
      action: 'location',
    },
    motion: {
      action: 'motion',
    },
    notification: {
      action: 'notification',
    },
    gps: {
      action: 'gps',
    },
  },
  offer: {
    category: 'offer',
    offerViewed: {
      action: 'offer-viewed',
    },
    storeViewed: {
      action: 'store-viewed',
    },
    insufficientCoins: {
      action: 'insufficient-coins',
    },
    purchaseIntended: {
      action: 'purchase-intended',
    },
    purchaseFailed: {
      action: 'purchase-failed',
    },
    purchased: {
      action: 'purchased',
    },
  },
};

export default kibanaEvents;
