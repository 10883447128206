var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cc-modal',{attrs:{"data-ruid":"modal__confirm-delivery-address","group-class":"bg-blue-dark w-100","position":"bottom","header-title":_vm.$t('shopConfirmGoodPurchaseModalTitle')},on:{"close-modal":function($event){return _vm.$emit('close-modal')}},scopedSlots:_vm._u([{key:"body",fn:function({ close }){return [_c('div',{staticClass:"px-5 d-flex flex-column",attrs:{"id":"scrollableComponent"}},[_c('div',{staticClass:"mb-2 border-bottom"},[_c('cc-text',[_vm._v(" "+_vm._s(_vm.$t('shopConfirmGoodPurchaseModalDescription', { price: _vm.product && _vm.product.price, }))+" ")])],1),(!_vm.hasAddress)?_c('div',{staticClass:"border-bottom"},[_c('cc-section-header',{attrs:{"title":_vm.$t('deliveryAddressesEmptyStateTitle'),"svg":"illustrations/address-book"}}),_c('cc-text',{attrs:{"color":"muted","align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('deliveryAddressesEmptyStateBody'))+" ")])],1):_c('div',{staticClass:"mt-2 border-bottom"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('cc-heading',{staticClass:"mr-3",attrs:{"tag":"h3","variant":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('deliveryAddressTitle'))+" ")])],1),_c('div',{staticClass:"d-flex justify-content-between pt-2"},[_c('div',[_c('cc-text',[_vm._v(" "+_vm._s(_vm.selectedAddress.firstName)+" "+_vm._s(_vm.selectedAddress.lastName)+" ")]),_c('cc-text',[_vm._v(" "+_vm._s(_vm.selectedAddress.streetName)+" "+_vm._s(_vm.selectedAddress.houseNumber)+" ")]),_c('cc-text',[_vm._v(_vm._s(_vm.selectedAddress.postalCode)+" "+_vm._s(_vm.selectedAddress.city))])],1),_c('div',{staticClass:"pl-4"},[_c('cc-button',{attrs:{"variant":"small-muted","block":false,"text":_vm.$t('txtChange')},on:{"click":function($event){close(() => {
                _vm.$emit('after-leave', {
                  name: 'change-delivery-address-modal',
                  selectedAddress: _vm.selectedAddress
                })
              })}}})],1)])])])]}},{key:"footer",fn:function({ close }){return [_c('div',{staticClass:"d-flex flex-column w-100 px-5 py-5"},[_c('cc-error-message',{staticClass:"pb-3",attrs:{"message":_vm.errors[0],"alignment":"center"}}),(!_vm.hasAddress)?_c('cc-button',{staticClass:"ml-1",attrs:{"data-ruid":"global__button__add-delivery-address","text":_vm.$t('addDeliveryAddress')},on:{"click":function($event){close(() => {
          _vm.$emit('after-leave', 'add-delivery-address-modal')
        })}}}):_vm._e(),(_vm.hasAddress)?_c('cc-button',{staticClass:"ml-1",attrs:{"data-ruid":"global__button__confirm-offer","text":_vm.$t('txtConfirm'),"disabled":_vm.loading || _vm.appState.isSandbox},on:{"click":function($event){return _vm.confirmPurchase(close, _vm.selectedAddress)}}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }