<template>
  <div>
    <portal
      v-if="enabled"
      to="notifications"
      >
      <div
        class="fixed-top safe-area-inset-top mt-1"
        style="margin-top: 0px;"
        >
        <transition
          name="toast"
          @before-enter="beforeEnter"
          @after-enter="afterEnter"
          @after-leave="afterLeave"
          >
          <div v-if="Object.keys(notificationToDisplay).length">
            <cc-notification
              id="in-app-notification"
              :payload="notificationToDisplay"
              :styles="styles[notificationCurrentStyle]"
              @click.native="isDismissed ? null : dismiss()"
              />
          </div>
        </transition>
      </div>
    </portal>

    <div
      v-if="debug"
      style="font-size:14px; line-height: 28px;padding-top:30px;"
      >
      touchPosY: {{ touchPosY }}
      <br>
      touchStartPosY: {{ touchStartPosY }}
      <br>
      delta: {{ delta }}
      <br>
      elapsedUntilPaused: {{ elapsedUntilPaused }}
      <br>
      isDismissed: {{ isDismissed }}
      <br>
      isRunning: {{ isRunning }}
      <br>
      elapsed: {{ elapsed }}
      <br>
      rafId: {{ rafId }}
      <br>

      <cc-wrapper
        direction="row"
        align="center"
        class="pt-8 pb-4"
        >
        <cc-button
          variant="secondary"
          class="mr-2"
          @click="setSocialState(['notificationCurrentStyle', changeCurrentStyle()])"
          >
          Change style
        </cc-button>
        <cc-button
          variant="secondary"
          class="ml-2"
          @click="$store.dispatch('social/pushNotification')"
          >
          Push
        </cc-button>
      </cc-wrapper>
      notificationsQueueLength: {{ notificationsQueueLength }}
      <br>
      notificationsQueue:
      <div
        v-for="item in notificationsQueue"
        :key="item.meta.messageId"
        >
        {{ item.meta.messageId }}
      </div>
      <!-- notificationsQueue: {{ notificationsQueue }} -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import anime from 'animejs/lib/anime.min';

import ccNotification from '@/components/Notification.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';

export default {
  components: {
    ccNotification,
    ccButton,
    ccWrapper,
  },
  props: {
    debug: {
      type: Boolean,
      required: false,
      default: false,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      notificationToDisplay: {},
      displayDuration: 6000,
      easing: 'easeOutElastic(1, 1.2)',
      duration: 500,
      draggable: false,
      touchStartPosY: 0,
      touchPosY: 0,
      delta: 0,
      el: null,
      elapsedUntilPaused: 0,
      elapsed: 0,
      isDismissed: false,
      isRunning: false,
      rafId: null,
      styles: [
        {
          background: 'bg-blue-darkest',
          text: 'white',
          border: 'border: #4b97fe7d 1px solid;',
        },
        {
          background: 'bg-blue-shadow',
          text: 'white',
          border: 'border: #0057DF 1px solid;',
        },
        {
          background: 'bg-white',
          text: 'grey-dark',
          border: 'border: none;',
        },
        {
          background: 'bg-warning',
          text: 'cc-yellow-darkest',
          border: 'border: none;',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      notificationCurrentStyle: state => state.social.notificationCurrentStyle,
    }),
    ...mapGetters({
      inAppNotificationsQueue: 'social/inAppNotificationsQueue',
    }),
    notificationsQueueLength() {
      return this.notificationsQueue?.length;
    },
    notificationState() {
      return {
        touchPosY: this.touchPosY,
        touchStartPosY: this.touchStartPosY,
        delta: this.delta,
        draggable: this.draggable,
        elapsedUntilPaused: this.elapsedUntilPaused,
        isDismissed: this.isDismissed,
        isRunning: this.isRunning,
        elapsed: this.elapsed,
        rafId: this.rafId,
        currentStyle: this.notificationCurrentStyle,
        notificationsQueueLength: this.notificationsQueueLength,
      };
    },
  },
  watch: {
    inAppNotificationsQueue(payload) {
      if (payload.length !== 0 && !Object.keys(this.notificationToDisplay).length) {
        this.$log.info('Watcher will assign the notification');
        [this.notificationToDisplay] = payload;
      }
    },
  },
  methods: {
    ...mapMutations({
      spliceFromNotificationsQueue: 'social/spliceFromNotificationsQueue',
      setSocialState: 'social/setState',
    }),
    /**
     * Disable emitters for debugging before deployment
     */
    changeCurrentStyle() {
      switch (this.notificationCurrentStyle) {
        case 0:
          return 1;
        case 1:
          return 2;
        case 2:
          return 3;
        case 3:
          return 0;
        default:
          return 0;
      }
    },
    kickTimer() {
      this.$log.info('Starting kickTimer');
      this.isRunning = true;

      const time = {
        start: performance.now(),
      };

      const tick = (now) => {
        if (this.isDismissed) {
          this.$log.info('Dismissed');
          return;
        }

        if (!this.isRunning) {
          this.elapsedUntilPaused = Math.round(time.elapsed);
          this.$log.info('Paused: elapsed time until now', this.elapsedUntilPaused);
          return;
        }

        time.elapsed = now - time.start + this.elapsedUntilPaused;

        // this.$log.info('Timer:', Math.round(time.elapsed));

        this.elapsed = Math.round(time.elapsed);

        if (time.elapsed < this.displayDuration) {
          cancelAnimationFrame(this.rafId);
          this.rafId = requestAnimationFrame(tick);
          // this.$root.$emit('notificationState', this.notificationState);
        } else if (!this.isDismissed) {
          this.$log.info('Will dismiss as result of timeout');
          this.dismiss();
        }
      };
      requestAnimationFrame(tick);
    },
    beforeEnter(el) {
      this.$log.info('beforeEnter', this.elapsedUntilPaused);
      [this.el] = el.children;

      // Add listeners to be able to drag the notification
      this.el.addEventListener('touchstart', this.onTouchStart, { passive: true });
      this.el.addEventListener('touchmove', this.onTouchMove, { passive: true });
      this.el.addEventListener('touchend', this.onTouchEnd, { passive: true });

      // Clean previous state and kick off the timer
      this.isDismissed = false;
      this.kickTimer();
    },
    afterEnter() {
      this.$log.info('afterEnter');
    },
    afterLeave() {
      this.$log.info('afterLeave');
      this.spliceFromNotificationsQueue(0);
      // this.$root.$emit('notificationState', this.notificationState);
    },
    onTouchStart(event) {
      this.touchStartPosY = parseInt(event.changedTouches[0].clientY, 10);
      // this.touchPosY = parseInt(event.changedTouches[0].clientY, 10);

      this.draggable = true;
      this.isRunning = false;
      this.$log.info('onTouchStart: event', event.changedTouches[0], this.touchStartPosY);
      // this.$root.$emit('notificationState', this.notificationState);
    },
    onTouchMove(event) {
      this.touchPosY = parseInt(event.changedTouches[0].clientY, 10);

      if (this.draggable) {
        this.delta = this.touchPosY - this.touchStartPosY;
        // this.$log.info('onTouchMove: event delta', this.delta);
        this.el.style.transform = `translateY(${this.delta}px)`;

        // this.$root.$emit('notificationState', this.notificationState);

        // Reset position if slided down too much?
        // if (this.touchPosY > 500) {
        //   this.resetPosition();
        // }
      }
    },
    onTouchEnd() {
      const transformStyle = this.el?.style?.transform;
      const translateY = transformStyle?.replace(/[^-?\d.]/g, '');
      const translateYVal = +translateY;

      this.$log.info('onTouchEnd', translateYVal);
      this.isRunning = true;

      if (this.draggable && translateYVal < 0 && !this.isDismissed) {
        return this.dismissBySlide();
      }

      if (this.draggable) {
        this.resetPosition();
        return this.kickTimer();
      }

      return false;
    },
    resetPosition() {
      const { duration, easing } = this;

      this.draggable = false;

      anime({
        targets: this.el,
        duration,
        easing,
        translateY: 0,
        begin: () => {
          this.$log.info('Begin: resetPosition');
        },
      });
    },
    dismissBySlide() {
      const { easing } = this;

      anime({
        targets: this.el,
        duration: 1000,
        easing,
        translateY: -200,
        begin: () => {
          this.$log.info('Begin: dismiss');
        },
      });

      this.$log.info('Will dismiss as result of slide up');
      this.dismiss();
    },
    dismiss() {
      this.$log.warn('Dismissing notification with id', this.notificationToDisplay.meta.messageId);

      this.draggable = false;
      this.notificationToDisplay = {};
      this.elapsedUntilPaused = 0;
      this.isDismissed = true;
      this.isRunning = false;
      this.el.removeEventListener('touchstart', this.onTouchStart, { passive: true });
      this.el.removeEventListener('touchmove', this.onTouchMove, { passive: true });
      this.el.removeEventListener('touchend', this.onTouchEnd, { passive: true });
      // this.$root.$emit('notificationState', this.notificationState);
    },
  },
};
</script>

<style>
.toast-move {
  transition: transform 1000ms;
}

.toast-enter {
  position: relative;
  opacity: 1;
  transform: translateY(-400px);
}

.toast-enter-active {
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1); /* easeOutExpo */
}

.toast-leave-active {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--safe-area-inset-top);
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1); /* easeOutExpo */
}

.toast-leave-to {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--safe-area-inset-top);
  opacity: 0;
  /* transform: translateY(-400px); */
  /* top: -200px; */
  transform: scale(1.2);
  pointer-events: none;
}

/* .card__pressable {
  transform: scale(1);
}
.card__pressable:active {
  transform: scale(0.96);
} */
</style>
