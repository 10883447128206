<template>
  <cc-modal
    data-ruid="modal__confirm-address-deletion"
    group-class="bg-blue-dark"
    @close-modal="$emit('close-modal')"
    >
    <template #header>
      <div />
    </template>

    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column w-100"
        >
        <cc-section-header
          :title="$t('deleteAddressBody')"
          svg="illustrations/address-deletion"
          />

        <cc-text
          align="center"
          color="muted"
          class="py-4"
          >
          {{ $t('groupLeaderboardDeleteGroupDescription' ) }}
        </cc-text>
      </div>
    </template>

    <template #footer="{ close }">
      <cc-wrapper
        direction="row"
        class="px-5 py-5"
        >
        <cc-button
          :is-modal-button="true"
          class="mr-1"
          data-ruid="global__button-remove"
          variant="danger"
          :text="$t('txtDelete')"
          @click="deleteAddress(close, data)"
          />
        <cc-button
          :is-modal-button="true"
          variant="secondary"
          class="ml-1"
          data-ruid="global__button-confirm"
          :text="$t('txtCancel')"
          @click="close"
          />
      </cc-wrapper>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccModal from '@/components/modals/Modal.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';

export default {
  components: {
    ccModal,
    ccWrapper,
    ccSectionHeader,
    ccButton,
    ccText,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: 'confirm-address-deletion-modal',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async deleteAddress(closeModal, address) {
      await this.$store.dispatch('user/deleteAddress', address);
      closeModal(() => {
        this.$router.push({ name: 'delivery-addresses' });
      });
    },
  },
};
</script>
