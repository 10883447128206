import { mapMutations } from 'vuex';

export default {
  beforeMount() {
    if (this.$store.state.common.appState.errorMessage) {
      this.$log.info('Clearing error messages');
      this.setAppState(['errorMessage', null]);
    }
  },
  beforeDestroy() {
    if (this.$store.state.common.appState.errorMessage) {
      this.$log.info('Clearing error messages');
      this.setAppState(['errorMessage', null]);
    }
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    clearErrorMessage() {
      this.setAppState(['errorMessage', null]);
    },
  },
};
