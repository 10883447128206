<template>
  <cc-card
    :id="id"
    :ruid="id"
    :inner-title="null"
    :pressable="true"
    class="pb-4 overflow-hidden"
    :has-button="false"
    card-background="blue-dark"
    card-class="p-1"
    variant="inline"
    @click.native="goToWelcome"
    >
    <template #body>
      <cc-wrapper
        direction="column"
        align="start"
        class="p-2"
        >
        <cc-heading
          variant="h3"
          class="flex-grow-1 pb-2"
          >
          {{ $t('shutdownAnnouncementCardTitle') }} ⚠️
        </cc-heading>
        <cc-text
          v-if="false"
          variant="body-bold"
          class="flex-grow-1"
          >
          {{ $t('shutdownAnnouncementTitle') }}
        </cc-text>
        <cc-text
          variant="body"
          class="flex-grow-1"
          style="white-space: pre-line;"
          >
          {{ $t('shutdownAnnouncementBody') }}
        </cc-text>
      </cc-wrapper>
    </template>
  </cc-card>
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';

export default {
  name: 'ShutdownAnnouncement',
  components: {
    ccCard,
    ccWrapper,
  },
  data() {
    return {
      id: 'shutdown-announcement',
    };
  },
  methods: {

  },
};
</script>
