<template>
  <div
    class="w-100"
    :style="{ borderBottom: `${height}px solid`, borderColor: `var(--${color})` }"
    />
</template>

<script>
const colorProps = [
  'white',
  'warning',
  'primary',
  'danger',
  'cc-yellow-darkest',
  'cc-yellow-darker',
  'muted',
  'blue-light',
  'blue-lighter',
  'grey-dark',
  'grey-light',
  'grey-lighter',
];

export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'blue-light',
      validator: value => colorProps.includes(value),
    },
    height: {
      type: Number,
      required: false,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.divider {
  border-bottom: 1px solid;
  border-color: var(--blue-light);
}
</style>
