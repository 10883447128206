<template>
  <cc-tab-bar
    :id="id"
    :items="tabBarItems"
    />
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

import ccTabBar from '@/components/constructs/TabBar.vue';

export default {
  components: {
    ccTabBar,
  },
  data() {
    return {
      id: 'app__tab-bar',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      uiState: state => state.common.uiState,
    }),
    ...mapGetters({
      getTabBarWrapperEl: 'common/getTabBarWrapperEl',
      isTabBarVisible: 'common/isTabBarVisible',
    }),
    /**
     * Populate tab bar items. Assigning `hasTabBar: {fn} and tabBarPosition: {order}` on the route
     * level is enough to make a route appear as a tab bar item
     * pathName will take exitRoute object into considaration to check if tapping on the icon should
     * lead the user to the last/exit route of this tab
     */
    tabBarItems() {
      const items = this.$router.options.routes
        .filter(route => route?.meta?.tabBarPosition)
        .filter(route => route?.meta?.hasTabBar())
        .sort((a, b) => a.meta.tabBarPosition - b.meta.tabBarPosition)
        .map((route) => {
          const r = this.uiState.exitRoute[route.path.split('/')[1]];

          return {
            pathName: r?.name || route?.path?.split('/')[1],
            route: r || null,
            locale: route.meta.label,
            icon: `icons/tabbar-${route?.path?.split('/')[1]}`,
          };
        });
      return items;
    },
  },
  watch: {
    $route(to) {
      this.toggleTabBar(to);
    },
  },
  mounted() {
    // this.$log.info('Mounted: TabBar Controller');
    // this.$log.info('Mounted: isTabBarVisible', this.isTabBarVisible());

    this.$store.dispatch('common/setUiElementHeight', {
      id: this.id,
      name: 'tabBarHeight',
      route: this.$route,
    });

    this.toggleTabBar(this.$route);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
      setUiState: 'common/setUiState',
    }),
    toggleTabBar(route) {
      if (!route.meta.hasTabBar && this.isTabBarVisible()) {
        // this.$log.warn('App: hideTabBar', !!this.isTabBarVisible);
        this.$store.dispatch('common/hideTabBar', { route: this.$route });
      } else if (route.meta.hasTabBar && !this.isTabBarVisible()) {
        // this.$log.warn('App: showTabBar');
        this.$store.dispatch('common/showTabBar', { route: this.$route });
      }
    },
  },
};
</script>
