<template>
  <cc-modal
    data-ruid="modal__group-member"
    group-class="bg-blue-dark w-100"
    position="bottom"
    :header-title="$t('groupLeaderboardUserSettings')"
    @close-modal="$emit('close-modal')"
    >
    <template #body>
      <div
        id="scrollableComponent"
        class="px-5 d-flex flex-column"
        >
        <div
          v-if="!isSelf"
          class="mb-6 border-bottom"
          >
          <cc-heading
            variant="h4"
            class="pb-3"
            >
            {{ $t('txtDisplayName') }}
          </cc-heading>
          <cc-text>{{ member && member.name || $t('missingContactName') }} </cc-text>
        </div>

        <div v-if="hasAdminOrOwnerActions">
          <cc-heading>
            {{ $t('groupLeaderboardRole') }}
          </cc-heading>

          <div
            v-for="(role, i) in roles"
            :key="i"
            >
            <div
              class="d-flex flex-row justify-content-around align-items-center
              pl-1 py-4 border-bottom"
              @click="member.role = role"
              >
              <cc-text class="flex-grow-1 text-capitalize">
                {{ $t(`${role}Role`) }}
              </cc-text>

              <cc-svg-base
                v-if="member.role === role"
                color-class="svg-fill-primary"
                svg="icons/icon-check"
                />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <div class="d-flex w-100 px-5 py-5">
        <!-- TODO: Disable buttons when a request is in progress -->
        <cc-button
          v-if="hasAdminOrOwnerActions"
          :is-modal-button="true"
          data-ruid="global__button-remove"
          class="mr-1"
          variant="danger"
          :text="$t('txtRemove')"
          @click="displayMemberRemoval(close)"
          />
        <cc-button
          v-if="hasAdminOrOwnerActions"
          :is-modal-button="true"
          data-ruid="global__button-confirm"
          class="ml-1"
          :text="$t('txtSave')"
          @click="updateMember(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccModal from '@/components/modals/Modal.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccHeading,
    ccText,
    ccButton,
    ccModal,
    ccSvgBase,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      roles: ['admin', 'participant'],
      selectedRole: [],
      member: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      userState: state => state.user,
      user: state => state.user.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      getUserRoleInGroup: 'social/getUserRoleInGroup',
      getMemberRoleInGroup: 'social/getMemberRoleInGroup',
    }),
    userRole() {
      return this.leaderboardState?.group?.currentData?.data?.[0].userRole;
    },
    isSelf() {
      return this.member?.id === this.user?.id;
    },
    isMemberOwner() {
      const { member } = this;
      return member?.role === 'owner';
    },
    isMemberParticipant() {
      const { member } = this;
      return member?.role === 'participant';
    },
    hasAdminOrOwnerActions() {
      const {
        isSelf, isOwner, isAdmin, isMemberOwner,
      } = this;
      return (!isSelf && isOwner) || (!isSelf && isAdmin && !isMemberOwner);
    },
    group() {
      return { ...this.data.group };
    },
    isOwner() {
      return this.userRole === 'owner';
    },
    isAdmin() {
      return this.userRole === 'admin';
    },
  },
  created() {
    this.member = { ...this.data.member };
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    async updateMember(closeModal) {
      // TODO: Add error message component to ui and bind here
      try {
        await this.$store.dispatch('social/updateMembersRole', { groupId: this.group.id, members: [this.member] });
        await this.$store.dispatch('social/getGroupLeaderboard', { groupId: this.group.id, forceUpdate: true });

        closeModal();
      } catch (error) {
        this.$log.info('getGroupLeaderboard', error);
      }
    },
    displayMemberRemoval(closeModal) {
      closeModal(() => {
        this.$emit('after-leave', { group: this.group, member: this.member });
      });
    },
  },
};
</script>
