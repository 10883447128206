import Vue from 'vue';

import * as primitiveComponents from '@/components/primitives';
import * as constructComponents from '@/components/constructs';
import * as utils from '@/mixins/utils';

Object.entries(primitiveComponents).forEach(([name, component]) => {
  // Vue.$log.info('Registering component:', name);
  Vue.component(`cc-${utils.toKebapCase(name)}`, component);
});

Object.entries(constructComponents).forEach(([name, component]) => {
  // Vue.$log.info('Registering component:', name);
  Vue.component(`cc-${utils.toKebapCase(name)}`, component);
});
