export const transportTypes = {
  electric: [
    'subway_electric', //
    'light_rail_electric',
    'bicycle_electric',
    'car_electric',
    'car_electricMedium',
    'car_electricCompact',
    'car_hybrid',
    'car_hybridMedium',
    'car_hybridCompact',
    'tram_electric',
    'regional_train_electric',
    'train_electric',
    'bus_electric',
    'chairlift_electric',
    'funicular_electric',
    'cablecar_electric',
  ],
  fossil: [
    'car_fossil', //
    'car_fossilMedium',
    'car_fossilCompact',
    'car_motorbike',
    'boat_fossil',
    'ferry_fossil',
    'bus_fossil',
    'airplane_fossil',
    'coach_fossil',
  ],
  humanPowered: [
    'walk_human-powered', //
    'bicycle_human-powered',
  ],
  interchangableTransportTypes: [
    'car_fossil', //
    'bicycle_human-powered',
    'bus_fossil',
  ],
};

export const defaultEmissionType = {
  car: 'fossil',
  boat: 'fossil',
  ferry: 'fossil',
  bus: 'fossil',
  airplane: 'fossil',
  coach: 'fossil',
  subway: 'electric',
  light_rail: 'electric',
  tram: 'electric',
  regional_train: 'electric',
  train: 'electric',
  walk: 'human-powered',
  bicycle: 'human-powered',
  funicular: 'electric',
  cablecar: 'electric',
  chairlift: 'electric',
};

export const displayTransportTypes = [
  'walk_human-powered',
  'bicycle_human-powered',
  'bicycle_electric',
  'car_motorbike',
  'car_electric',
  'car_electricMedium',
  'car_electricCompact',
  'car_hybrid',
  'car_hybridMedium',
  'car_hybridCompact',
  'car_fossil',
  'car_fossilMedium',
  'car_fossilCompact',
  'tram_electric',
  'subway_electric',
  'train_electric',
  'bus_fossil',
  'bus_electric',
  'coach_fossil',
  'boat_fossil',
  'ferry_fossil',
  'airplane_fossil',
  'funicular_electric',
  'chairlift_electric',
  'cablecar_electric',
];

const unifiedTransportTypes = {
  train_electric: [
    'light_rail_electric',
    'regional_train_electric',
  ],
};

export function displayType(transportType) {
  let result = transportType;
  Object.keys(unifiedTransportTypes).forEach((key) => {
    for (let i = 0; i < unifiedTransportTypes[key].length; i += 1) {
      if (unifiedTransportTypes[key][i] === transportType) {
        result = key;
      }
    }
  });
  return result;
}

function unifiedType(transportType) {
  let result = false;
  Object.keys(unifiedTransportTypes).forEach((key) => {
    for (let i = 0; i < unifiedTransportTypes[key].length; i += 1) {
      if (unifiedTransportTypes[key][i] === transportType) {
        result = true;
      }
    }
  });
  return result;
}

export function getDefaultEmissionType(transportType) {
  return defaultEmissionType[transportType];
}

export function filterDisplayTypes(array) {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (unifiedType(array[i])) {
      array.splice(i, 1);
    }
  }
  return array;
}
