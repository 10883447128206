<template>
  <cc-segmented-control
    :id="id"
    :items="items"
    :active-class="getActiveClass"
    @click="clickHandler"
    />
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccSegmentedControl from '@/components/constructs/SegmentedControl.vue';

export default {
  components: {
    ccSegmentedControl,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default: () => [
        {
          locale: 'challengesTabLabel',
          value: 'challenges',
        },
        {
          locale: 'achievementsTabLabel',
          value: 'achievements',
        },
      ],
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getActiveClass(type) {
      return type.value === this.$route.name ? 'active' : '';
    },
    clickHandler(type) {
      this.$router.push({ name: type.value }).catch(() => {});
    },
  },
};
</script>
