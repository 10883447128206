<template>
  <div
    :id="id"
    class="h-100 px-4"
    >
    <cc-wrapper
      align="reset"
      class="h-100"
      >
      <cc-card>
        <template
          v-if="type === null"
          #body
          >
          <cc-section-header
            :title="$t('genericExpiredTitle')"
            svg="illustrations/email-error"
            />
        </template>

        <template
          v-else-if="type === 'groupInvite'"
          #body
          >
          <cc-section-header
            :title="$t('groupInviteExpiredTitle')"
            svg="illustrations/email-error"
            />
          <cc-text
            align="center"
            color="muted"
            >
            {{ $t('groupInviteExpiredDescription') }}
          </cc-text>
        </template>

        <template
          v-else-if="type === 'emailVerification'"
          #body
          >
          <cc-section-header
            :title="$t('emailVerificationExpiredTitle')"
            svg="illustrations/email-error"
            />
          <cc-text
            align="center"
            color="muted"
            >
            {{ $t('emailVerificationExpiredDescription') }}
          </cc-text>
        </template>
      </cc-card>
    </cc-wrapper>
  </div>
</template>

<script>
import ccCard from '@/components/constructs/Card.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccText from '@/components/primitives/Text.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';

export default {
  components: {
    ccCard,
    ccSectionHeader,
    ccText,
    ccWrapper,
  },
  data() {
    return {
      id: 'email-expired',
      type: null,
    };
  },
  created() {
    this.type = this.$route?.query?.type || null;
  },
};
</script>
