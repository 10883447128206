<template>
  <cc-view :id="id">
    <div class="px-2 pb-7 d-flex flex-column justify-content-between h-100">
      <div
        class="d-flex flex-column"
        style="gap: 80px"
        >
        <div>
          <cc-heading
            variant="h4"
            color="muted"
            >
            {{ $t('publicChallengeSubtitle') }}
          </cc-heading>
          <cc-heading variant="h2">
            {{ $t('publicChallengeTitle') }}
          </cc-heading>
        </div>
        <div
          class="d-flex flex-column"
          style="gap: 40px"
          >
          <cc-list-item
            svg="illustrations/public-challenge-welcome-item-1"
            :title="$t('publicChallengeItemTitle1')"
            :description="$t('publicChallengeItemDescription1')"
            />
          <cc-list-item
            svg="illustrations/public-challenge-welcome-item-2"
            :title="$t('publicChallengeItemTitle2')"
            :description="$t('publicChallengeItemDescription2')"
            />
          <cc-list-item
            svg="illustrations/public-challenge-welcome-item-3"
            :title="$t('publicChallengeItemTitle3')"
            :description="$t('publicChallengeItemDescription3')"
            />
        </div>
      </div>
      <div class="pt-6 pb-4">
        <cc-button
          :text="variant === 'welcome' ? $t('txtContinue') : $t('txtBack')"
          @click.native="goToProgress"
          />
      </div>
    </div>
  </cc-view>
</template>

<script>
import ccView from '@/components/constructs/View.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccListItem from '@/components/public-goal/PublicGoalListItem.vue';

export default {
  components: {
    ccView,
    ccHeading,
    ccButton,
    ccListItem,
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'welcome',
    },
  },

  data() {
    return {
      id: 'public-goal-welcome',
    };
  },

  methods: {
    goToProgress() {
      if (this.variant === 'shop') {
        this.$router
          .push({
            name: 'shop',
          })
          .catch(() => {});
        return;
      }

      this.$router.push({ name: 'public-goal-progress' }).catch(() => {});
    },
  },
};
</script>
