<template>
  <cc-wrapper
    :key="challenge.id"
    direction="row"
    align="center"
    justify="start"
    class="py-4"
    @click.native="$emit('click', challenge)"
    >
    <!-- Challenge icon -->
    <div
      class="mr-4"
      style="flex: 0 0 48px;"
      >
      <cc-image
        style="width: 48px;"
        :src="getChallengeProperty(challenge.challenge.achievement, 'imageUrl')"
        />
    </div>


    <!-- Challenge info -->
    <div class="flex-grow-1">
      <cc-text
        variant="body-bold"
        class="flex-grow-1"
        >
        {{ getChallengeProperty(challenge.challenge.achievement, 'title') }}
      </cc-text>

      <cc-wrapper
        direction="row"
        align="baseline"
        justify="start"
        >
        <span class="mr-1">
          <cc-svg-base svg="icons/icon-time" />
        </span>

        <cc-text
          v-if="hasProgressBar"
          color="muted"
          variant="body-sm"
          >
          {{
            $t('txtLeftToComplete', {
              number: $moment(challenge.expiresDate).fromNow(true)
            })
          }}
        </cc-text>

        <cc-text
          v-if="isDelayedChallenge"
          color="muted"
          variant="body-sm"
          >
          {{ $t('txtCompleted') }}
          <br>
          {{ $t('txtWaitingForResults') }}
        </cc-text>

        <cc-text
          v-if="isUpcomingChallenge"
          color="muted"
          variant="body-sm"
          >
          {{
            $t('txtLeftToStart', {
              number: $moment(challenge.startedDate).fromNow(true)
            })
          }}
        </cc-text>
      </cc-wrapper>

      <cc-progress-bar
        v-if="hasProgressBar"
        :background-opacity="70"
        width="150px"
        class="mt-3"
        :progress-value="challengeProgression(challenge)"
        :saturation-value="getCompletionPercentage(challenge)"
        />
    </div>


    <!-- Row arrow -->
    <div class="ml-4">
      <cc-svg-base
        svg="icons/icon-arrow-right"
        color-class="svg-fill-white"
        />
    </div>
  </cc-wrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';
import ccImage from '@/components/primitives/Image.vue';
import ccProgressBar from '@/components/achievements/ProgressBar.vue';


export default {
  components: {
    ccWrapper,
    ccText,
    ccSvgBase,
    ccImage,
    ccProgressBar,
  },
  props: {
    challenge: {
      type: Object,
      requried: true,
      default: null,
    },
    hasProgressBar: {
      type: Boolean,
      requried: false,
      default: true,
    },
    isDelayedChallenge: {
      type: Boolean,
      requried: false,
      default: false,
    },
    isUpcomingChallenge: {
      type: Boolean,
      requried: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'challenges-list-item',
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    ...mapGetters({
      getLocalizedResource: 'company/getLocalizedResource',
      challengeProgression: 'achievements/challengeProgression',
      getCompletionPercentage: 'achievements/getCompletionPercentage',
    }),
  },
  methods: {
    getChallengeProperty(achievement, property) {
      const { getLocalizedResource } = this;
      const response = getLocalizedResource(achievement.resources, achievement.defaultLang)[
        property
      ];

      return response || '–';
    },
  },
};
</script>
