<template>
  <div :id="`shop__product-category-row__${heading}`">
    <div class="d-flex flex-grow w-100 align-items-center justify-content-between px-4 pb-3">
      <div class="d-flex align-items-center">
        <cc-heading variant="h4">
          {{ $t(`category${sentenceCase(heading)}`) }}
        </cc-heading>

        <cc-text
          v-if="false"
          variant="body-sm"
          class="pl-1"
          color="muted"
          >
          ({{ offers.length }})
        </cc-text>
      </div>
      <cc-text
        variant="body-sm"
        @click.native="
          setCompanyState(['productCategoryFilter', heading]),
          $router.push({ name: 'product-category', params: { category: heading } })
        "
        >
        {{ $t('txtShowAll') }}
      </cc-text>
    </div>

    <div class="overflow-x-scroll w-100 pb-6 pl-4 flex-horizontal-scroll">
      <div class="d-flex flex-row">
        <cc-product-card
          v-for="(product, i) in offers"
          :key="product.id"
          :product="product"
          :offers="offers"
          :index="i"
          />

        <div
          class="d-flex justify-content-center align-items-center px-4"
          style="flex: 1 0 47%;"
          @click="$router.push({ name: 'product-category', params: { category: heading } })"
          >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <cc-text
              variant="body-sm-bold"
              color="white"
              >
              {{ $t('txtShowAll') }}
            </cc-text>
            <cc-svg-base
              tag="i"
              color-class="svg-fill-white"
              svg="icons/icon-arrow-right-tail"
              class="my-2"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { sentenceCase } from '@/mixins/utils';

import ccProductCard from '@/components/shop/ProductCard.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccProductCard,
    ccHeading,
    ccText,
    ccSvgBase,
  },
  props: {
    offersByCategory: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    heading() {
      return Object.keys(this.offersByCategory)[0];
    },
    offers() {
      return this.offersByCategory[this.heading];
    },
  },
  methods: {
    ...mapMutations({
      setCompanyState: 'company/setState',
    }),
    sentenceCase,
  },
};
</script>

<style></style>
