<template>
  <cc-segmented-control
    :id="id"
    :items="items"
    :active-class="getActiveClass"
    @click="clickHandler"
    />
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ccSegmentedControl from '@/components/constructs/SegmentedControl.vue';

export default {
  components: {
    ccSegmentedControl,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default: () => [
        {
          locale: 'dailyAverageCo2',
          value: 'averageCo2',
        },
        {
          locale: 'co2PerKm',
          value: 'co2PerKm',
        },
      ],
    },
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
    }),
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    getActiveClass(type) {
      return type.value === this.appState.leaderboardType ? 'active' : '';
    },
    clickHandler(type) {
      // Because following operation will change the content in the DOM
      // emit an event to disconnect the IntersectionObserver for the Hover card
      // so we don't leak memory
      // this.$root.$emit('disconnectHoverCard');

      if (type !== this.appState.leaderboardType) {
        this.setAppState(['leaderboardType', type.value]);
      }
    },
  },
};
</script>
