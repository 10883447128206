<template>
  <transition
    name="slide-down"
    @enter="enableWrapper"
    @after-enter="enableWrapper"
    @leave="disableWrapper"
    >
    <cc-card
      v-if="displayAlert"
      :id="id"
      :ruid="id"
      :padded="false"
      variant="alert"
      :has-button="false"
      card-background="cc-yellow"
      class="pt-3"
      >
      <template #body>
        <div class="d-flex">
          <div>
            <cc-svg-base :svg="icon" />
          </div>

          <div class="pl-3">
            <cc-heading
              color="cc-yellow-darkest"
              tag="h3"
              variant="h4"
              class="pt-2 mr-3"
              >
              {{ $t(`${alertTypeHandler}AlertTitle`) }}
            </cc-heading>

            <cc-text
              class="py-3"
              variant="body-sm"
              color="cc-yellow-darker"
              >
              {{ $t(`${alertTypeHandler}AlertBody`) }}
            </cc-text>

            <cc-button
              variant="warning"
              :block="false"
              :text="$t(`${alertTypeHandler}AlertButton`)"
              @click="clickHandler"
              />
          </div>
        </div>
      </template>
    </cc-card>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import semver from 'semver';

import ccCard from '@/components/constructs/Card.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccCard,
    ccButton,
    ccText,
    ccHeading,
    ccSvgBase,
  },
  data() {
    return {
      id: 'permission-alert',
      disabled: false,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      displayAlertState: state => state.common.appState.displayAlert,
    }),
    ...mapGetters({
      hasAndroidQOrAbove: 'common/hasAndroidQOrAbove',
      motionTagThreeMinimumSdkVersion: 'common/motionTagThreeMinimumSdkVersion',
    }),
    alertTypeChanged() {
      return this.alertType;
    },
    alertTypeHandler() {
      return this.alertType();
    },
    displayAlert() {
      const { os, didFinishPermissionChecks } = this.appState;
      const alertType = this.alertType();

      // this.$log.log('didFinishPermissionChecks', didFinishPermissionChecks);

      if (!didFinishPermissionChecks) {
        return false;
      }

      if (os === 'web') {
        return false;
      }

      // TODO: refactor to use arr.includes
      if (this.appState.displayAlert) {
        if (
          alertType === 'trackingNotAlways'
          || alertType === 'trackingUnavailable'
          || alertType === 'trackingDisabled'
          || alertType === 'noLocationPermission'
          || alertType === 'noMotionPermission'
          || alertType === 'motionPermissionDenied'
          || alertType === 'gpsDisabled'
          || alertType === 'noPreciseLocation'
          || alertType === 'powerSavingModeEnabled'
          || alertType === 'batteryOptimizationEnabled'
        ) {
          return true;
        }
      }

      return false;
    },
    icon() {
      const alertType = this.alertType();

      // TODO: refactor to use arr.includes
      if (
        alertType === 'trackingNotAlways'
        || alertType === 'trackingUnavailable'
        || alertType === 'trackingDisabled'
        || alertType === 'noLocationPermission'
        || alertType === 'noPreciseLocation'
      ) {
        return 'icon-alert-location';
      }

      if (alertType === 'noMotionPermission' || alertType === 'motionPermissionDenied') {
        return 'icon-alert-motion';
      }

      if (alertType === 'gpsDisabled') {
        return 'icon-alert-gps';
      }

      if (alertType === 'powerSavingModeEnabled') {
        return 'icon-alert-powersavingmode';
      }

      if (alertType === 'batteryOptimizationEnabled') {
        return 'icon-alert-batteryoptimization';
      }

      return null;
    },
    shouldForceDisplayTrackingNotAlways() {
      const { os, osVersion, forceDisplayTrackingNotAlways } = this.appState;

      return (
        os === 'ios'
        && semver.gte(semver.coerce(osVersion).version, '13.0.0')
        && forceDisplayTrackingNotAlways
      );
    },
  },
  watch: {
    alertTypeChanged() {
      if (this.alertDisplayed) {
        setTimeout(() => {
          this.enableWrapper();
        }, 150);
      }
    },
    displayAlertState() {
      if (!this.alertType()) {
        this.setAppState(['displayAlert', false]);
      }
    },
    alertTypeHandler: {
      immediate: true,
      async handler(val) {
        const { navigation } = this.kibanaEvents;

        switch (val) {
          case 'trackingNotAlways':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'tracking-not-always',
            });
            break;

          case 'trackingUnavailable':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'tracking-unavailable',
            });
            break;

          case 'trackingDisabled':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'tracking-disabled',
            });
            break;

          case 'noLocationPermission':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'no-location-permission',
            });
            break;

          case 'motionPermissionDenied':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'motion-permission-denied',
            });
            break;

          case 'noMotionPermission':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'no-motion-permission',
            });
            break;

          case 'noPreciseLocation':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'no-precise-location',
            });
            break;
          case 'powerSavingModeEnabled':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'power-saving-mode-enabled',
            });
            break;
          case 'batteryOptimizationEnabled':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'battery-optimization-mode-enabled',
            });
            break;
          case 'gpsDisabled':
            this.$store.dispatch('common/insertEvent', {
              category: navigation.category,
              action: navigation.conditionalContentDisplayed.action,
              type: 'gps-disabled',
            });
            break;

          default:
            break;
        }
      },
    },
  },
  mounted() {
    // this.$log.log('Alert Mounted');

    setTimeout(() => {
      this.setAppState(['displayAlert', true]);
    }, 1000);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    alertType() {
      const {
        locationPermission, //
        motionPermission,
        os,
        gps,
        preciseLocation,
        batterySavingModes,

      } = this.appState;

      if (os === 'web') {
        return false;
      }

      const { hasAndroidQOrAbove, motionTagThreeMinimumSdkVersion } = this;

      const trackingNotAlways = locationPermission === 'always' && this.shouldForceDisplayTrackingNotAlways;

      const trackingUnavailable = locationPermission === 'unavailable';

      const trackingDisabled = locationPermission === 'false' || locationPermission === 'inUse';

      const noLocationPermission = locationPermission === 'undefined';

      const motionPermissionDenied = (os === 'ios' || (hasAndroidQOrAbove && motionTagThreeMinimumSdkVersion))
        && motionPermission === 'false';

      const noMotionPermission = (os === 'ios' || (hasAndroidQOrAbove && motionTagThreeMinimumSdkVersion))
        && motionPermission === 'undefined';

      const noPreciseLocation = preciseLocation === 'false';

      const powerSavingModeEnabled = batterySavingModes?.powerSavingEnabled === true;

      const batteryOptimizationEnabled = batterySavingModes?.batteryOptimizationEnabled === true;

      const gpsDisabled = gps === 'off';

      if (trackingNotAlways) {
        // this.$log.warn('trackingNotAlways');
        return 'trackingNotAlways';
      }

      if (trackingUnavailable) {
        // this.$log.warn('trackingUnavailable');
        return 'trackingUnavailable';
      }

      if (trackingDisabled) {
        // this.$log.warn('trackingDisabled');
        return 'trackingDisabled';
      }

      if (noLocationPermission) {
        // this.$log.warn('noLocationPermission');
        return 'noLocationPermission';
      }

      if (motionPermissionDenied) {
        // this.$log.warn('motionPermissionDenied');
        return 'motionPermissionDenied';
      }

      if (noMotionPermission) {
        // this.$log.warn('noMotionPermission');
        return 'noMotionPermission';
      }

      if (noPreciseLocation) {
        // this.$log.warn('noPreciseLocation');
        return 'noPreciseLocation';
      }

      if (gpsDisabled) {
        // this.$log.warn('gpsDisabled');
        return 'gpsDisabled';
      }

      if (powerSavingModeEnabled) {
        // this.$log.warn('powerSavingModeEnabled');
        return 'powerSavingModeEnabled';
      }

      if (batteryOptimizationEnabled) {
        // this.$log.warn('batteryOptimizationEnabled');
        return 'batteryOptimizationEnabled';
      }

      return '';
    },
    clickHandler() {
      const { os, osVersion } = this.appState;
      const alertType = this.alertType();

      // User will be send to settings and the alert will go away
      if (alertType === 'trackingNotAlways') {
        this.setAppState(['forceDisplayTrackingNotAlways', false]);
        return this.callSdk(`action=${this.actions.GO_TO_APP_SETTINGS}`);
      }

      if (alertType === 'powerSavingModeEnabled') {
        return this.callSdk(`action=${this.actions.GO_TO_POWER_SAVE_SETTINGS}`);
      }

      if (alertType === 'batteryOptimizationEnabled') {
        return this.callSdk(`action=${this.actions.GO_TO_BATTERY_OPTIMIZATION_SETTINGS}`);
      }

      // Go to app settings
      if (
        alertType === 'trackingUnavailable'
        || alertType === 'trackingDisabled'
        || alertType === 'motionPermissionDenied'
        || alertType === 'noPreciseLocation'
      ) {
        return this.callSdk(`action=${this.actions.GO_TO_APP_SETTINGS}`);
      }

      // Request location permission
      if (alertType === 'noLocationPermission') {
        if (os === 'ios' && semver.gte(semver.coerce(osVersion).version, '13.0.0')) {
          this.setAppState(['forceDisplayTrackingNotAlways', true]);
        }
        return this.callSdk(`action=${this.actions.REQUEST_LOCATION_PERMISSION}`);
      }

      // Request motion permission
      if (alertType === 'noMotionPermission') {
        return this.callSdk(`action=${this.actions.REQUEST_MOTION_PERMISSION}`);
      }

      // Go to GPS settings
      if (alertType === 'gpsDisabled') {
        return this.callSdk(`action=${this.actions.GO_TO_GPS_SETTINGS}`);
      }
      return null;
    },
    enableWrapper() {
      const alertHeight = document.getElementById('alert')?.offsetHeight;
      if (alertHeight) {
        this.setAppState(['dialogHeight', alertHeight]);
      }
    },
    disableWrapper() {
      this.setAppState(['alertHeight', 0]);
      this.setAppState(['dialogHeight', 0]);
    },
  },
};
</script>
