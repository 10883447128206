<template>
  <cc-view :id="id">
    <cc-text
      variant="body-muted"
      class="mb-6"
      >
      {{ $t('vehicleSettingsDescription') }}
    </cc-text>

    <cc-card
      v-for="(vehicleVariant, index) in transportTypes.interchangableTransportTypes"
      :key="index"
      class="mb-6"
      :outer-title-key="`${vehicleVariant}Type`"
      variant="list"
      :ruid="vehicleVariant"
      >
      <template #body>
        <cc-settings-list-item
          :icon="`transport-icons/${vehicleVariant}`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${vehicleVariant}Txt`)"
          :checked="isChecked(vehicleVariant)"
          :data-ruid="isChecked(vehicleVariant) ? 'selected' : 'unselected'"
          @click.native="clickHandler(vehicleVariant)"
          />
        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${vehicleVariant}`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${vehicleVariant}MediumTxt`)"
          :data-ruid="isChecked(vehicleVariant+'Medium') ? 'selected' : 'unselected'"
          :checked="isChecked(vehicleVariant+'Medium')"
          @click.native="clickHandler(vehicleVariant+'Medium')"
          />
        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${vehicleVariant}`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${vehicleVariant}CompactTxt`)"
          :data-ruid="isChecked(vehicleVariant+'Compact') ? 'selected' : 'unselected'"
          :checked="isChecked(vehicleVariant+'Compact')"
          @click.native="clickHandler(vehicleVariant+'Compact')"
          />

        <cc-settings-list-item
          :icon="`transport-icons/${transportType(vehicleVariant)}_electric`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_electricTxt`)"
          :checked="isChecked(vehicleVariant, 'electric')"
          :data-ruid="isChecked(vehicleVariant, 'electric') ? 'selected' : 'unselected'"
          :last="transportType(vehicleVariant) !== 'car'"
          @click.native="clickHandler(vehicleVariant, 'electric')"
          />

        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${transportType(vehicleVariant)}_electric`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_electricMediumTxt`)"
          :checked="isChecked(vehicleVariant, 'electricMedium')"
          :data-ruid="isChecked(vehicleVariant, 'electricMedium') ? 'selected' : 'unselected'"
          :last="transportType(vehicleVariant) !== 'car'"
          @click.native="clickHandler(vehicleVariant, 'electricMedium')"
          />

        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${transportType(vehicleVariant)}_electric`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_electricCompactTxt`)"
          :checked="isChecked(vehicleVariant, 'electricCompact')"
          :data-ruid="isChecked(vehicleVariant, 'electricCompact') ? 'selected' : 'unselected'"
          :last="transportType(vehicleVariant) !== 'car'"
          @click.native="clickHandler(vehicleVariant, 'electricCompact')"
          />


        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${transportType(vehicleVariant)}_hybrid`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_hybridTxt`)"
          :data-ruid="isChecked(vehicleVariant, 'hybrid') ? 'selected' : 'unselected'"
          :checked="isChecked(vehicleVariant, 'hybrid')"
          :last="transportType(vehicleVariant) === 'car' ? false : true"
          @click.native="clickHandler(vehicleVariant, 'hybrid')"
          />

        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${transportType(vehicleVariant)}_hybrid`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_hybridMediumTxt`)"
          :data-ruid="isChecked(vehicleVariant, 'hybridMedium') ? 'selected' : 'unselected'"
          :checked="isChecked(vehicleVariant, 'hybridMedium')"
          :last="transportType(vehicleVariant) === 'car' ? false : true"
          @click.native="clickHandler(vehicleVariant, 'hybridMedium')"
          />

        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${transportType(vehicleVariant)}_hybrid`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_hybridCompactTxt`)"
          :data-ruid="isChecked(vehicleVariant, 'hybridCompact') ? 'selected' : 'unselected'"
          :checked="isChecked(vehicleVariant, 'hybridCompact')"
          :last="transportType(vehicleVariant) === 'car' ? false : true"
          @click.native="clickHandler(vehicleVariant, 'hybridCompact')"
          />

        <cc-settings-list-item
          v-if="transportType(vehicleVariant) === 'car'"
          :icon="`transport-icons/${transportType(vehicleVariant)}_motorbike`"
          icon-class="transport-icon-width"
          input-type="radio"
          :label="$t(`${transportType(vehicleVariant)}_motorbikeTxt`)"
          :data-ruid="isChecked(vehicleVariant, 'motorbike') ? 'selected' : 'unselected'"
          :checked="isChecked(vehicleVariant, 'motorbike')"
          last
          @click.native="clickHandler(vehicleVariant, 'motorbike')"
          />
      </template>
    </cc-card>
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccText from '@/components/primitives/Text.vue';
import ccSettingsListItem from '@/components/constructs/SettingsListItem.vue';

export default {
  components: {
    ccView,
    ccCard,
    ccText,
    ccSettingsListItem,
  },
  data() {
    return {
      id: 'vehicle-settings',
      vehicleVariants: ['fossil', 'electric', 'hybrid'],
      transitModePreferences: {
        bicycle: {
          emissionType: '',
        },
        car: {
          emissionType: '',
        },
        bus: {
          emissionType: '',
        },
      },
      selected: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
    }),
    ...mapGetters({
      getTransitModePreferences: 'user/getTransitModePreferences',
    }),
  },
  mounted() {
    this.transitModePreferences = this.getTransitModePreferences;
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    clickHandler(vehicleVariant, customVariant) {
      const { updateTransitModePreference, transportType, fuelType } = this;

      return updateTransitModePreference(
        transportType(vehicleVariant),
        customVariant || fuelType(vehicleVariant),
      );
    },
    transportType(vehicleVariant) {
      return this.seperateTransportTypeAndFuelType(vehicleVariant)
        .transportType;
    },
    fuelType(vehicleVariant) {
      return this.seperateTransportTypeAndFuelType(vehicleVariant).fuelType;
    },
    isChecked(vehicleVariant, customVariant) {
      const { transitModePreferences, transportType, fuelType } = this;

      const variant = customVariant || fuelType(vehicleVariant);
      return transitModePreferences[transportType(vehicleVariant)].emissionType === variant;
    },
    async updateTransitModePreference(transitMode, fuelType) {
      this.$log.info(`${transitMode} ${fuelType}`);


      this.$store.dispatch('user/setTransitModePreference', {
        transitMode,
        fuelType,
      })
      .then(() => {
        this.$store.dispatch('user/getUser');
      })
      .then(() => {
        this.transitModePreferences = this.getTransitModePreferences;
      });
    },
  },
};
</script>
