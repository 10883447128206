<template>
  <cc-view
    :id="id"
    :is-modal="isModal"
    >
    <cc-wrapper class="h-100 text-center safe-area-inset-bottom flex-grow-1">
      <cc-wrapper class="flex-grow-1">
        <form @submit.prevent="signUpWithPhone">
          <cc-heading class="mb-5">
            {{ userState.isLoggedIn ? $t('verifyTitle') : $t('loginTitle') }}
          </cc-heading>

          <cc-text
            color="muted"
            class="mb-5 mx-5"
            >
            {{ userState.isLoggedIn ? $t('verifyDescription') : $t('loginDescription') }}
          </cc-text>

          <div>
            <div>
              <input
                ref="tel"
                v-model="tel"
                aria-label="input-phone-number"
                data-ruid="input-phone-number"
                class="w-85 text-center text-white bg-transparent
              border-0 font-size-24 placeholder-text"
                type="tel"
                name="tel"
                autocomplete="tel"
                placeholder="Phone number"
                @blur="scrollToTop"
                >
              <div class="px-6 mx-6">
                <cc-divider
                  :height="2"
                  :color="dividerColor"
                  class="mb-4"
                  />
              </div>
            </div>

            <div
              data-ruid="global__validation-message"
              class="is-invalid"
              >
              {{ validationMessage }}
            </div>

            <cc-text
              v-if="userState.isLoggedIn"
              variant="body-sm"
              class="py-4 px-5"
              >
              {{ userState.isLoggedIn ? $t('verifyWarning') : null }}
            </cc-text>
          </div>
        </form>

        <div class="w-100 pt-5">
          <cc-button
            v-if="userState.isLoggedIn"
            id="sign-up-button"
            data-ruid="global__button-verify"
            :variant="(isSubmitting || !validPhoneNumber) ? 'secondary' : 'primary'"
            :disabled="isSubmitting || !validPhoneNumber"
            :text="isSubmitting ? $t('txtSending') : $t('txtContinue')"
            @click.native.prevent="signUpWithPhone(false)"
            />

          <cc-button
            v-else
            id="login-up-button"
            data-ruid="global__button-login"
            :variant="(isSubmitting || !validPhoneNumber) ? 'secondary' : 'primary'"
            :disabled="isSubmitting || !validPhoneNumber"
            :text="isSubmitting ? $t('txtSending') : $t('txtLogin')"
            @click.native.prevent="signUpWithPhone(true)"
            />
        </div>
      </cc-wrapper>

      <div class="safe-area-inset-bottom">
        <cc-button
          :text="$t('loginByEmailAddress')"
          variant="transparent-muted"
          @click.native="$router.push({ name: 'login-by-email'}).catch(() => {})"
          />
        <cc-legal-component
          v-if="showLegalComponent"
          :button-name="`&quot;${$t('txtLogin')}&quot;`"
          class="px-6"
          />
      </div>
    </cc-wrapper>
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import auth from '@/services/auth';

import ccView from '@/components/constructs/View.vue';
import ccButton from '@/components/primitives/Button.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccText from '@/components/primitives/Text.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccDivider from '@/components/primitives/Divider.vue';
import ccLegalComponent from '@/components/LegalComponent.vue';

export default {
  name: 'Signup',
  components: {
    ccView,
    ccButton,
    ccWrapper,
    ccText,
    ccHeading,
    ccDivider,
    ccLegalComponent,
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'sign-up',
      tel: '+417',
      isSubmitting: false,
      validPhoneNumber: false,
      normalizedPhoneNumber: '',
      validationMessage: '',
      dividerColor: undefined,
      showLegalComponent: false,
    };
  },
  computed: {
    ...mapState({
      userState: state => state.user,
    }),
    ...mapGetters({
      // isLegacyUser: 'user/isLegacyUser',
    }),
  },
  watch: {
    tel(val) {
      /**
       * Support both number formats with +41... and without
       */
      if (val.charAt(0) === '+' && val.length >= 8) {
        this.$log.info('val', val);
        this.normalizePhoneNumber(val);
      } else if (val.length >= 6 && val.charAt(0) !== '+') {
        this.normalizePhoneNumber(val);
      }

      this.validationMessage = '';
      this.dividerColor = 'blue-light';
    },
  },
  methods: {
    ...mapMutations({
      setUserState: 'user/setState',
    }),
    signUpWithPhone(recovery) {
      const tel = this.normalizedPhoneNumber;

      if (tel) {
        this.isSubmitting = true;

        if (recovery) {
          this.$store
            .dispatch('user/requestPhoneVerificationRecovery', { tel })
            .then(() => {
              this.$router.push({
                name: 'phone-verification',
                query: { recoverySession: recovery, phoneNumber: tel },
              });
            })
            .catch((err) => {
              this.$log.warn('user/signUpWithPhone', err);
              this.isSubmitting = false;
              this.dividerColor = 'danger';
              this.validationMessage = this.$t('generalErrorMessage');
            });
        } else {
          this.$store
            .dispatch('user/requestPhoneVerification', { tel })
            .then(() => {
              if (this.isModal) {
                this.$emit('route-object', {
                  name: 'PhoneVerification',
                  query: { recoverySession: recovery, phoneNumber: tel },
                });
              } else {
                this.$router.push({
                  name: 'phone-verification',
                  query: { recoverySession: recovery, phoneNumber: tel },
                });
              }
            })
            .catch((err) => {
              this.$log.warn('user/requestVerification', err);
              this.isSubmitting = false;
              this.dividerColor = 'danger';
              this.validationMessage = this.$t('generalErrorMessage');
            });
        }
      } else {
        this.validationMessage = this.$t('emptyPhoneNumberError');
      }
    },
    inputFocus() {
      // Not working on iOS https://medium.com/@brunn/autofocus-in-ios-safari-458215514a5f
      setTimeout(() => this.$refs.tel.focus(), 600);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    normalizePhoneNumber(phoneNumber) {
      auth
        .normalizePhoneNumber(phoneNumber)
        .then((response) => {
          if (response.error) {
            this.validPhoneNumber = false;
          } else {
            this.$log.info('Normalizing phone number succeeded');
            this.normalizedPhoneNumber = response.normalized;
            this.validPhoneNumber = true;
          }
        })
        .catch((err) => {
          this.$log.warn('normalizePhoneNumber', err);
          this.normalizedPhoneNumber = this.tel;
          this.validPhoneNumber = true;
        });
    },
  },
};
</script>
