<template>
  <div
    :id="id"
    class="h-100 px-4"
    >
    <cc-wrapper
      v-if="type"
      align="reset"
      class="h-100"
      >
      <cc-card>
        <template
          v-if="type === 'groupInvite'"
          #body
          >
          <cc-section-header
            :title="$t('groupInviteSuccessTitle')"
            svg="illustrations/email-success"
            />
          <cc-text
            align="center"
            color="muted"
            >
            {{ $t('groupInviteSuccessDescription') }}
          </cc-text>
          <div class="d-flex justify-content-center pt-5">
            <a
              :href="getAppStoreUrl"
              target="_blank"
              class="px-2"
              >
              <cc-svg-base
                svg="logos/store-apple"
                />
            </a>


            <a
              :href="getGooglePlayUrl"
              target="_blank"
              class="px-2"
              >
              <cc-svg-base
                svg="logos/store-google"
                />
            </a>
          </div>
        </template>

        <template
          v-else-if="type === 'emailVerification'"
          #body
          >
          <cc-section-header
            :title="$t('emailVerificationSuccessTitle')"
            svg="illustrations/email-success"
            />
          <cc-text
            align="center"
            color="muted"
            >
            {{ $t('emailVerificationSuccessDescription') }}
          </cc-text>
        </template>
      </cc-card>
    </cc-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ccCard from '@/components/constructs/Card.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccText from '@/components/primitives/Text.vue';
import ccWrapper from '@/components/primitives/Wrapper.vue';
import ccSvgBase from '@/components/SvgBase.vue';

export default {
  components: {
    ccCard,
    ccSectionHeader,
    ccText,
    ccWrapper,
    ccSvgBase,
  },
  data() {
    return {
      id: 'email-success',
      type: null,
    };
  },
  computed: {
    ...mapGetters({
      getAppStoreUrl: 'common/getAppStoreUrl',
      getGooglePlayUrl: 'common/getGooglePlayUrl',
    }),
  },
  created() {
    this.type = this.$route?.query?.type || null;
  },
};
</script>
